import {resolveColor} from '#core/utils/color-utils.js';

import {registerTileSchema} from '#core/application/schema-registry.js';

import {BasicPalette} from '../../basic-palette.js';

import POPOVER from './popover.palette-schema.yaml';

registerTileSchema(POPOVER);

export function generatePopover(TILE) {
    const {FONT} = TILE;

    const BORDER_STYLE =
        TILE.POPOVER_BORDER_COLOR !== 'transparent'
            ? {
                  borderStyle: 'solid',
                  borderWidth: 1,
                  borderColor: TILE.POPOVER_BORDER_COLOR,
              }
            : undefined;

    return {
        container: {
            style: {
                zIndex: 599, // set z-index here, as mojito by default sets it in wrong place (not working)
                // need to duplicate border radius and shadow here. Because that is how mojito works
                borderRadius: TILE.POPOVER_BORDER_RADIUS,
                fontFamily: FONT.fontFamily,
                ...BORDER_STYLE,
            },
        },
        content: {
            style: {
                base: {
                    boxShadow: TILE.POPOVER_SHADOW,
                    backgroundColor: resolveColor(TILE.POPOVER_BG), // Mojito supports only bg-color, so no any gradients here
                    borderRadius: TILE.POPOVER_BORDER_RADIUS,
                },
            },
        },
    };
}

export function generateSimplePopover(background, borderRadius, shadow, borderColor = 'transparent') {
    const BORDER_STYLE =
        borderColor !== 'transparent'
            ? {
                  borderStyle: 'solid',
                  borderWidth: 1,
                  borderColor: borderColor,
              }
            : undefined;

    return {
        container: {
            style: {
                zIndex: 599, // set z-index here, as mojito by default sets it in wrong place (not working)
                // need to duplicate border radius and shadow here. Because that is how mojito works
                borderRadius: borderRadius,
                fontFamily: BasicPalette.palette.fontFamily,
            },
        },
        content: {
            style: {
                base: {
                    boxShadow: shadow,
                    backgroundColor: resolveColor(background), // Mojito supports only bg-color, so no any gradients here
                    borderRadius: borderRadius,
                    ...BORDER_STYLE,
                },
            },
        },
    };
}
