import BaseCashoutService from './base-cashout-service.js';
import BetsTypes from 'services/bets/types.js';

const { PUSH } = BetsTypes.CASHOUT_PROTOCOLS;
const voidMapping = logger => () => logger.error(`Wrong configuration. dataMapper is missing.`);

const NULL_DATA_MAPPER = logger => {
    return {
        mapRequest: voidMapping(logger),
        mapResponse: voidMapping(logger),
    };
};

/**
 * Cashout service config.
 *
 * @typedef {Mojito.Services.Bets.cashoutService.CashoutServiceConfig} PushCashoutServiceConfig
 * @property {Mojito.Services.Bets.types.ICashoutOffersMapper} dataMapper - Cashout offers data mapper implementation.
 * Will be called to map messages to/from API specific structures.
 *
 * @memberof Mojito.Services.Bets.pushCashoutService
 */

/**
 * Class offering the possibility to interact with the mojito bets cash out API using
 * {@link Mojito.Services.Bets.types.CASHOUT_PROTOCOLS.PUSH|PUSH} transport to retrieve cash out offers.
 *
 * @class PushCashoutService
 * @name pushCashoutService
 * @extends Mojito.Services.Bets.BaseCashoutService
 * @memberof Mojito.Services.Bets
 */
export class PushCashoutService extends BaseCashoutService {
    constructor() {
        super(PUSH);
        this.socket = undefined;
    }

    /**
     * Configure service.
     *
     * @param {Mojito.Services.Bets.pushCashoutService.PushCashoutServiceConfig} config - Service configuration object.
     *
     * @function Mojito.Services.Bets.pushCashoutService#configure
     */
    configure({ serviceUrl, dataMapper }) {
        super.configure({ serviceUrl });
        this.dataMapper = dataMapper || NULL_DATA_MAPPER(this.logger);
        this.getSubscriptionOptions = this.getSubscriptionOptions.bind(this);
    }

    createWebSocket(url) {
        return new WebSocket(url);
    }

    /**
     * Requests cashout offers for bets.
     *
     * @param {Array<Mojito.Services.Bets.types.BetInfo>} betInfos - List of bet info objects.
     * @param {Mojito.Services.Bets.types.cashoutOffersSuccess} success - Callback executed once cashout offers successfully fetched.
     * @param {Mojito.Services.Bets.types.cashoutValuesFail} fail - Callback executed once cashout offers failed to fetch.
     *
     * @returns {Promise} Promise on cashout offers request.
     *
     * @function Mojito.Services.Bets.pushCashoutService#requestCashoutOffers
     */
    requestCashoutOffers(betInfos, success, fail) {
        if (this.isRequested) {
            return Promise.resolve();
        }
        this.isRequested = true;
        return this.validateCashoutTransport()
            .then(this.getSubscriptionOptions)
            .then(this.subscribe(success, betInfos))
            .catch(error => {
                this.isRequested = false;
                fail(error);
            });
    }

    /**
     * Discard cash out values request made by {@link Mojito.Services.Bets.PushCashoutService#requestCashoutOffers|requestCashoutOffers}.
     *
     * @function Mojito.Services.Bets.pushCashoutService#discardCashoutOffers
     */
    discardCashoutOffers() {
        this.isRequested = false;
        this.invalidateCashoutTransport();
        if (!this.socket) {
            return;
        }
        this.socket.onopen = () => this.closeSocket();
        if (this.socket.readyState === WebSocket.OPEN) {
            this.closeSocket();
        }
    }

    closeSocket() {
        this.socket.onclose = undefined; // By adding this line we're not trying to reconnect in cases when we're doing the close on our own
        this.socket.close();
        this.socket = undefined;
    }

    getSubscriptionOptions() {
        if (!this.validateServiceUrl() || !this.isRequested) {
            return Promise.reject();
        }
        return new Promise((success, reject) => {
            this.requestFactory
                .post(`${this.apiUrl}/cashout-push-subscribe`)
                .withDescription('get subscription options')
                .withCredentials()
                .send()
                .then(success)
                .catch(reject);
        });
    }

    subscribe(onMessage, betInfos) {
        const connectToWS = ({ token, url }) => {
            if (!this.isRequested) {
                return;
            }
            this.socket = this.createWebSocket(url);
            const offersRequest = this.dataMapper.mapRequest({ betInfos, token });

            this.socket.onopen = () => offersRequest && this.socket.send(offersRequest);
            this.socket.onmessage = msg => {
                const cashoutInfos = this.dataMapper.mapResponse(msg.data);
                cashoutInfos && onMessage({ cashoutInfos });
            };
            this.socket.onclose = event => {
                if (!event.wasClean) {
                    this.logger.warn(
                        'WebSocket is closed abnormally. Trying to reconnect. ',
                        event
                    );
                    connectToWS({ token, url });
                }
            };
            this.socket.onerror = event => this.logger.error('WebSocket error. ', event);
        };
        return connectToWS;
    }
}

export default new PushCashoutService();
