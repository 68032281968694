import MojitoCore from 'mojito/core';
import MojitoServices from 'mojito/services';
import RoutingView from './view/index.jsx';
import { connect } from 'react-redux';
import { omit } from 'mojito/utils';

const { UIViewImplementation, UIViewUtils } = MojitoCore.Presentation;
const { selectLoginState } = MojitoServices.Authentication.selectors;
const { dispatch } = MojitoCore.Services.redux.store;
const intentActions = MojitoCore.Intents.actions;
const { LOGGED_IN, LOGGED_OUT, UNKNOWN } = MojitoServices.Authentication.types.STATES;
const { isShallowEqual } = MojitoCore.Base.objUtils;
const { RequestDataHelper } = MojitoCore.Services;

class RoutingController extends UIViewImplementation {
    constructor(reactComponent) {
        super(reactComponent);

        this.requestDataHelper = new RequestDataHelper(this.instanceId);
    }

    componentWillUnmount() {
        this.requestDataHelper.unsubscribeAll();
    }

    shouldComponentUpdate(nextProps) {
        const loggedInChanged = this.isLoggedIn(this.props) ^ this.isLoggedIn(nextProps);
        return (
            !isShallowEqual(omit(this.props, 'loginState'), omit(nextProps, 'loginState')) ||
            Boolean(loggedInChanged)
        );
    }

    componentDidUpdate(prevProps) {
        // If user did logout
        if (this.isNotLoggedIn(this.props) && prevProps.loginState === LOGGED_IN) {
            queueMicrotask(() => {
                const intent = this.config.logoutIntent;
                if (intent.type) {
                    dispatch(intentActions.publishIntent(intent.type, intent.data));
                }
            });
        }
    }

    isLoggedIn(state) {
        return (state.loginState === LOGGED_IN) | 0;
    }

    isNotLoggedIn(state) {
        return state.loginState === LOGGED_OUT || state.loginState === UNKNOWN;
    }

    render() {
        return (
            <RoutingView
                {...UIViewUtils.omitComponentOwnProps(this.props, 'dispatch', 'loginState')}
            />
        );
    }
}

const mapStateToProps = state => ({
    loginState: selectLoginState(state),
});
export default connect(mapStateToProps)(RoutingController);
