import MojitoServices from 'mojito/services';
import MojitoPresentation from 'mojito/presentation';

import {isNative} from '#core/utils/context-utils.js';
import {mergeToHierarchy} from '#core/utils/config-utils.js';

import {ApplicationConfig} from '#core/config/application-config.js';

import {AbstractVisualFeature, allVisuals} from '#core/application/modules/visual-feature.js';
import {Cashout} from '#core/application/modules/cashout/index.js';

import {DEFAULT_RANGE_FILTERS} from './bet-history.range-filters';
import {betHistoryFactory} from './bet-history.factory';

import PALETTE_SCHEMA from './bet-history.palette-schema.yaml';
import SCHEMA from './bet-history.schema.yaml';

const {BET_STATUS, FILTER_CRITERIA_TYPE} = MojitoServices.Bets.types;
const {DATE_TIME_FORMAT} = MojitoPresentation.Utils.DateTime.DateTimeTypes;

class BetHistoryFeature extends AbstractVisualFeature {
    get name() {
        return 'BetHistory';
    }

    get configSchema() {
        return SCHEMA;
    }

    get paletteSchema() {
        return PALETTE_SCHEMA;
    }

    get widgetDesktopLayout() {
        return this.config.widgetDesktopLayout;
    }

    beforeMojitoConfigBuild(mojitoConfig) {
        mergeToHierarchy(mojitoConfig, 'services.configurations', {
            BetActionPaneView: {
                hide: true,
            },
            BetHistoryView: {
                filters: {
                    rangeFilterOptions: DEFAULT_RANGE_FILTERS,
                    defaultStatusFilter: BET_STATUS.ALL,
                    defaultRangeFilter: {
                        options: {
                            value: 'last-week',
                            duration: 1,
                            timeSpan: 'week',
                        },
                        type: FILTER_CRITERIA_TYPE.TIME_FRAME,
                    },
                    excludedStatusTypes: [BET_STATUS.VOID, BET_STATUS.PLACED].concat(
                        !Cashout.enabled ? [BET_STATUS.CASHED_OUT] : []
                    ),
                },
                tableColNames: ['DATE', 'TYPE', 'SELECTION', 'STAKE', 'ODDS', 'RETURNS', 'STATUS'],
            },
            BetHistoryDetails: {
                hidePdfButton: isNative() || ApplicationConfig.hideDownloadPdfButton,
                legDateTimeFormat: {
                    format: DATE_TIME_FORMAT.NUMERIC_DATE_TIME,
                },
            },
        });

        return super.beforeMojitoConfigBuild(mojitoConfig);
    }

    getVisualConfig(PALETTE) {
        return betHistoryFactory(PALETTE);
    }
}

export const BetHistory = new BetHistoryFeature(allVisuals);
