import {PALETTE_IDS} from '#core/application/modules/ids.js';

import {COLOR, FONT} from '../theme.js';

export function getMarketsConfig() {
    return {
        palette: {
            [PALETTE_IDS.MARKETS]: {
                MARKETS_CONTAINER_BG: COLOR.BRAND.BACKGROUND4,
                MARKETS_CONTAINER_HEADER_TEXT: {
                    color: COLOR.BRAND.BACKGROUND2,
                    fontWeight: FONT.WEIGHT_BOLD,
                },
                MARKETS_CONTAINER_HEADER_BET_BUILDER_ICON_COLOR: COLOR.BRAND.ACCENT1,
                MARKETS_SELECTION_HEADER_LABEL_TEXT: COLOR.BRAND.FOREGROUND3,
                MARKET_SELECTION_EACH_WAY_TERMS_LABEL_TEXT: {
                    color: COLOR.BRAND.BACKGROUND2,
                    fontSize: FONT.SIZE_SMALL,
                },
                MARKETS_TEAM_NAME_TEXT: COLOR.BRAND.FOREGROUND3,
                MARKETS_SHOW_HIDE_ALL_BUTTON_TEXT: {
                    fontWeight: FONT.WEIGHT_BOLD,
                    textTransform: 'none',
                    fontSize: FONT.SIZE_MEDIUM,
                    color: COLOR.BRAND.FOREGROUND1,
                },
                MARKETS_SHOW_HIDE_BUTTON_ICON_COLOR: COLOR.BRAND.ACCENT1,
                MARKETS_PRICE_BOOST_ITEM: {
                    PBI_PREVIOUS_PRICE_CVI: {
                        CVI_PREVIOUS_VALUE_TEXT: COLOR.BRAND.FOREGROUND3,
                    },
                },
                MARKETS_CONTAINER_HEADER_EARLY_PAYOUT_INDICATOR: {
                    EARLY_PAYOUT_INDICATOR_LABEL: {
                        BET_BONUS_LABEL_TEXT: COLOR.BRAND.FOREGROUND1,
                        BET_BONUS_LABEL_BG: COLOR.BRAND.CTA1,
                        BET_BONUS_LABEL_BORDER_COLOR: COLOR.RESERVED.TRANSPARENT,
                    },
                },
                MARKETS_HANDICAP_RANGE_INPUT: {
                    RANGE_TRACK_FILLED_COLOR: COLOR.BRAND.FOREGROUND4,
                    RANGE_TRACK_COLOR: COLOR.BRAND.FOREGROUND4,
                    RANGE_THUMB_COLOR: COLOR.BRAND.FOREGROUND1,
                    RANGE_LABEL_TEXT: COLOR.BRAND.FOREGROUND5,
                },
            },
        },
    };
}
