import {ForwardGaService} from '#core/features/analytics/forward-ga-service.js';

export default class GaToGtmService extends ForwardGaService {
    constructor(config) {
        super('GaToGtm');
        this._gtmId = config.id;
        this._enableGaToGtm = config.enableGaToGtm;
    }

    activate() {
        //Script for adding GTM, should be in separate script node, to provide OneTrust possibility to block it
        //till cookies are accepted by user.
        let GTMScript = `(function (w, d, s, l, i) {
            w[l] = w[l] || [];
            w[l].push({
                'gtm.start': new Date().getTime(),
                event: 'gtm.js',
            });
            let f = d.getElementsByTagName(s)[0];
            let j = d.createElement(s);
            let dl = l != 'dataLayer' ? '&l=' + l : '';
            j.async = true;
            j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
            f.parentNode.insertBefore(j, f);
        })(window, document, 'script', 'dataLayer', '${this._gtmId}')`;
        let script = document.createElement('script');
        script.type = 'text/javascript';
        script.text = GTMScript;
        document.head.appendChild(script);
    }

    _sendEvent(category, action, label) {
        if (this._enableGaToGtm) {
            window.dataLayer &&
                window.dataLayer.push({
                    event: 'ga-event',
                    ec: category,
                    ea: action,
                    el: label,
                });
        }
    }
}
