import MojitoPresentation from 'mojito/presentation';

const { FlexPane, SkeletonPart } = MojitoPresentation.Components;

const EventCardSkeleton = ({ mojitoTools: { config }, elementRef }) => (
    <FlexPane config={config.container} class="ta-MatchCardsSkeleton" elementRef={elementRef}>
        <FlexPane config={config.eventContainer}>
            <MatchHeader config={config} />
            <MatchParticipants config={config} />
        </FlexPane>
        <FlexPane config={config.marketContainer}>
            <SkeletonPart config={config.marketTitleSkeleton} />
            <MatchSelections config={config} />
        </FlexPane>
    </FlexPane>
);

const MatchHeader = ({ config }) => (
    <FlexPane config={config.headerContainer}>
        <SkeletonPart config={config.headerTitleSkeleton} />
    </FlexPane>
);

const MatchParticipants = ({ config }) => (
    <FlexPane config={config.participantNameContainer}>
        <SkeletonPart config={config.participantNameSkeleton} />
        <SkeletonPart config={config.participantNameSkeleton} />
    </FlexPane>
);

const MatchSelections = ({ config }) => (
    <FlexPane config={config.matchSelectionsContainer}>
        <SkeletonPart config={config.matchSelectionSkeleton} />
        <SkeletonPart config={config.matchSelectionSkeleton} />
        <SkeletonPart config={config.matchSelectionSkeleton} />
    </FlexPane>
);

export default EventCardSkeleton;
