import { useCallback, useContext, useEffect, useMemo, useReducer, useRef } from 'react';
import { useSelector } from 'react-redux';
import MojitoCore from 'mojito/core';
import MojitoServices from 'mojito/services';
import { curry, invert, isEmpty, keyBy, mapValues } from 'mojito/utils';

const CoreHooks = MojitoCore.Presentation.Hooks;
const ContentPreloaderContext = MojitoCore.Presentation.ContentPreloaderContext;
const { NULL_CONTEXT: NULL_PRELOADER_CONTEXT } = ContentPreloaderContext;
const { useControllerHelper } = MojitoCore.Presentation.Hooks;
const { arraysAreEqual } = MojitoCore.Base.objUtils;
const { descriptors: EventsDescriptors } = MojitoServices.SportsContent.Events;
const { selectors: itemListSelectors, descriptor: ItemListDataDescriptor } =
    MojitoServices.ItemList;
const { descriptor: MenusDescriptor, selectors: menusSelectors } =
    MojitoServices.SportsContent.Menus;
const { selectMenuContentInfo, selectMenuState } = menusSelectors;
const { selectEventItem, selectEventState, makeSelectMarketsByIds, selectMarketState } =
    MojitoServices.SportsContent.Events.selectors;
const { CONTENT_STATE } = MojitoServices.Common.types;
const ServicesUtils = MojitoServices.Common.utils;
const {
    selectors: { selectMarketGroupState },
} = MojitoServices.SportsContent.MarketGroups;
const { createEventsDescriptor, createEventMarketsDescriptor } = EventsDescriptors;
const {
    descriptor: { createEventGroupsDescriptor },
    selectors: { makeSelectEventGroupsByIds, selectEventGroupState },
} = MojitoServices.EventGroups;
const { selectItems, selectItemsState } = itemListSelectors;
const {
    descriptor: ContainerDataDescriptor,
    selectors: { selectItems: selectContainerItems },
} = MojitoServices.SportsContent.ContainerItems;
const {
    selectors: sportMetaInformationSelectors,
    descriptor: { createSportMetaInformationDescriptor },
} = MojitoServices.SportsContent.SportMetaInformation;
const { selectSportMetaInformation, selectSportMetaInformationState } =
    sportMetaInformationSelectors;
const {
    descriptor: LottoDescriptor,
    selectors: { selectLottery, selectLotteryState },
} = MojitoServices.Lottery;

/**
 * Contains custom hooks for general module functionality.
 *
 * @class Hooks
 * @memberof Mojito.Modules.Common
 */

/**
 * Hook listens for SportsContent events store slice changes and returns event item object by eventId.
 * On first render hook requests data.
 *
 * @function useEventItem
 *
 * @param {string} eventId - Event Id.
 * @param {boolean} [shouldRequest = true] - Flag indicating if hook should perform event request.
 * @returns {object} The output of {@link Mojito.Services.SportsContent.Events.selectors#selectEventItem|selectEventItem} function.
 * @memberof Mojito.Modules.Common.Hooks
 */
export const useEventItem = (eventId, shouldRequest = true) => {
    useEventRequest(shouldRequest ? eventId : undefined);
    return useSelector(state => selectEventItem(eventId, state));
};

/**
 * Hook listens to the events slice of the Redux store. Returns true if the event item is unavailable or ended, otherwise returns false.
 *
 * @function useEventItemUnavailability
 *
 * @param {string} eventId - Event Id.
 * @returns {boolean} Returns true when the event is unavailable, ended or has deletedTime value, otherwise - false.
 * @memberof Mojito.Modules.Common.Hooks
 */
export const useEventItemUnavailability = eventId => {
    const eventItem = useEventItem(eventId);
    const eventState = useSelector(state => selectEventState(eventId, state));
    return eventState === CONTENT_STATE.UNAVAILABLE || eventItem?.ended || !!eventItem?.deletedTime;
};

/**
 * Hook listens for SportsContent events store slice changes and returns market item objects by marketIds.
 * On first render hook requests data.
 *
 * @function useMarketItems
 *
 * @param {string} eventId - The id of the event to request data for.
 * @param {Array<string>} marketIds - List of market ids.
 * @param {boolean} [shouldRequest = true] - Flag indicating if hook should perform markets request.
 *
 * @returns {Array<object>} The output of {@link Mojito.Services.SportsContent.Events.selectors#selectMarkets|selectMarkets} function.
 * @memberof Mojito.Modules.Common.Hooks
 */
export const useMarketItems = (eventId, marketIds, shouldRequest = true) => {
    useMarketsRequest(eventId, shouldRequest ? marketIds : undefined);
    const selectMarketsByIds = useMemo(makeSelectMarketsByIds, []);
    return useSelector(state => selectMarketsByIds(marketIds, state));
};

/**
 * Hook performs event data request for specified <code>eventId</code>.
 * The difference between this hook and {@link Mojito.Modules.Common.Hooks.useEventItem|useEventItem} is that this one only performs subscription
 * and doesn't return actual event item from the store.
 *
 * @function useEventRequest
 *
 * @param {string} eventId - Event id.
 *
 * @memberof Mojito.Modules.Common.Hooks
 */
export const useEventRequest = eventId => {
    const eventIds = useMemo(() => (eventId ? [eventId] : undefined), [eventId]);
    useEventsRequest(eventIds);
};

/**
 * Hook performs events data request for specified <code>eventIds</code>.
 * It will result in a set of subscriptions per each event item.
 *
 * @function useEventsRequest
 *
 * @param {Array<string>} eventIds - Event ids.
 *
 * @memberof Mojito.Modules.Common.Hooks
 */
export const useEventsRequest = eventIds => {
    const descriptors = useMemo(() => {
        return eventIds ? [createEventsDescriptor(eventIds)] : [];
    }, [eventIds]);
    useRequest(descriptors);
};

/**
 * Hook performs markets data request for specified <code>eventId</code> and <code>marketIds</code>.
 *
 * @function useMarketsRequest
 *
 * @param {string} eventId - The id of the event to request data for.
 * @param {Array} marketIds - The list of market ids to request data for.
 * @memberof Mojito.Modules.Common.Hooks
 */
export const useMarketsRequest = (eventId, marketIds) => {
    const descriptor = useMemo(
        () => (marketIds ? createEventMarketsDescriptor(marketIds, eventId) : undefined),
        [marketIds, eventId]
    );
    useRequest([descriptor]);
};

/**
 * Hook allows to preload content on component mount or on resetHash change.
 * The request will be done only once for all requested client types.
 *
 * @function useContentPreload
 *
 * @param {string} instanceId - Component instance id. No data will be requested if instanceId is not provided.
 * @param {string} clientType - The type of the client used for tracking.
 * @param {string} dataType - The type of the requested data.
 * @param {Array<string>} itemIds - Content item ids list to request.
 * @param {Array} [deps = []] - Reset dependecies list used to reset content preload state and allow the same component to preload different content.
 * Can be useful when switching between sport tabs on a top sports coupon.
 *
 * @returns {Array} First item is a boolean which defines if preload request has been made, the second item is a discard request function which removes preload request registration for a component.
 *
 * @memberof Mojito.Modules.Common.Hooks
 */
export const useContentPreload = (instanceId, clientType, dataType, itemIds, deps = []) => {
    const contentPreloader = useContext(ContentPreloaderContext) || NULL_PRELOADER_CONTEXT;
    const requestDoneRef = useRef(false);
    const [, forceUpdate] = useReducer(x => x + 1, 0);
    // We are aware that rendering should not cause any side effect, but this function suppose to be executed only once
    // on component creation and is needed to allow subscription to an items of the same data type across sibling components on a page.
    // Considering it as an induced hack needed for performance.
    useMemo(() => {
        instanceId && contentPreloader.registerRequest(clientType, instanceId, dataType);
        requestDoneRef.current = false;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [instanceId, clientType, dataType, contentPreloader, ...deps]);

    const doRequest = useCallback(() => {
        const ids = itemIds?.filter(Boolean);
        const isValidRequest =
            !isEmpty(ids) && contentPreloader.hasAwaitingRequest(clientType, instanceId);
        if (isValidRequest) {
            contentPreloader.requestContent(clientType, instanceId, ids, () => {
                requestDoneRef.current = true;
                forceUpdate();
            });
        }
    }, [contentPreloader, instanceId, clientType, itemIds]);

    useEffect(() => {
        doRequest();
    }, [doRequest]);

    const discardRequest = useCallback(
        (shouldRerender = false) => {
            contentPreloader.removeRequest(clientType, instanceId);
            requestDoneRef.current = true;
            shouldRerender && forceUpdate();
        },
        [contentPreloader, clientType, instanceId]
    );

    useEffect(() => {
        return () => {
            discardRequest();
            contentPreloader.discardContent(instanceId);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [discardRequest]);

    return [requestDoneRef.current, discardRequest];
};

/**
 * Hook performs data request for specified <code>eventId</code> and <code>marketId</code>.
 * Difference between this hook and {@link Mojito.Modules.Common.Hooks.useMarketsRequest|useMarketsRequest} is that this one requests single market.
 *
 * @function useMarketRequest
 *
 * @param {string} eventId - The id of the event to request data for.
 * @param {string} marketId - The id of the market to request data for.
 * @memberof Mojito.Modules.Common.Hooks
 */
export const useMarketRequest = (eventId, marketId) => {
    const marketIds = useMemo(() => [marketId], [marketId]);
    useMarketsRequest(eventId, marketIds);
};

/**
 * Hook performs data request for items list with specified <code>listId</code>.
 * It will use {@link Mojito.Services.ItemList.store#selectItems|selectItems} function of item list store to fetch the data.
 * If data is not in the store it will be requested from backend.
 *
 * @function useListItems
 *
 * @param {string} listId - The id of the list to request data for.
 * @returns {Array} List of items. The output of {@link Mojito.Services.ItemList.store#selectItems|selectItems} function.
 * @memberof Mojito.Modules.Common.Hooks
 */
export const useListItems = listId => {
    const descriptor = useMemo(() => listId && ItemListDataDescriptor.create(listId), [listId]);

    useRequest([descriptor]);
    return useSelector(state => selectItems(listId, state));
};

/**
 * Hook performs data request for each event group id from <code>groupIds</code> list and returns event groups from state.
 *
 * @function useEventGroups
 *
 * @param {Array} [groupIds = []] - The list of desired event group ids.
 * @param {boolean} [shouldRequest = true] - Flag indicating if hook should perform event groups request.
 *
 * @returns {object} Object with event groups selected by groupIds from state. The key is group id and value is actual group item.
 * @memberof Mojito.Modules.Common.Hooks
 */
export const useEventGroups = (groupIds = [], shouldRequest = true) => {
    const descriptors = useMemo(
        () => (shouldRequest ? [createEventGroupsDescriptor(groupIds.filter(Boolean))] : []),
        [groupIds, shouldRequest]
    );
    useRequest(descriptors);
    const selectEventGroupsByIds = useMemo(makeSelectEventGroupsByIds, []);
    return useSelector(state => selectEventGroupsByIds(groupIds, state));
};

export const useSportMetaInfo = (sportListName, shouldRequest) => {
    const descriptors = useMemo(
        () => (shouldRequest ? [createSportMetaInformationDescriptor(sportListName)] : []),
        [sportListName, shouldRequest]
    );
    useRequest(descriptors);
    return useSelector(state => selectSportMetaInformation(sportListName, state));
};

/**
 * Hook returns true once content items loading has been finalized
 * (the state of all items is either {@link Mojito.Services.Common.types.CONTENT_STATE|AVAILABLE} or {@link Mojito.Services.Common.types.CONTENT_STATE|UNAVAILABLE})
 * which means that the items have been requested, and we got response from a server with or without content data for each of it.
 *
 * @function useItemsLoadDone
 *
 * @param {Function} itemStateSelector - Content item selector function.
 * @param {Array} itemIds - Item ids list.
 *
 * @returns {boolean} True if all items with provided ids have finalized their loading.
 * @memberof Mojito.Modules.Common.Hooks
 */
export const useItemsLoadDone = (itemStateSelector, itemIds) => {
    const loadingStates = useSelector(
        state => (itemIds || []).filter(Boolean).map(itemId => itemStateSelector(itemId, state)),
        arraysAreEqual
    );
    return !isEmpty(loadingStates) && loadingStates.every(ServicesUtils.isContentLoadDone);
};

/**
 * Hook returns true once events loading has been finalized
 * (the state of all events is either {@link Mojito.Services.Common.types.CONTENT_STATE|AVAILABLE} or {@link Mojito.Services.Common.types.CONTENT_STATE|UNAVAILABLE})
 * which means that the events have been requested and we got response from a server with or without event data for each of it.
 *
 * @function useEventsLoadDone
 *
 * @param {Array} eventIds - Event ids.
 *
 * @returns {boolean} True if all events with provided ids have finalised their loading.
 * @memberof Mojito.Modules.Common.Hooks
 */
export const useEventsLoadDone = curry(useItemsLoadDone)(selectEventState);

/**
 * Hook returns true once market groups loading has been finalized
 * (the state of all groups is either {@link Mojito.Services.Common.types.CONTENT_STATE|AVAILABLE} or {@link Mojito.Services.Common.types.CONTENT_STATE|UNAVAILABLE})
 * which means that the groups have been requested and we got response from a server with or without group data for each of it.
 *
 * @function useMarketGroupsLoadDone
 *
 * @param {Array} marketGroupIds - The id of the market group.
 *
 * @returns {boolean} True if all market groups with provided ids have finalised their loading.
 * @memberof Mojito.Modules.Common.Hooks
 */
export const useMarketGroupsLoadDone = marketGroupIds => {
    return useItemsLoadDone(selectMarketGroupState, marketGroupIds) || !marketGroupIds;
};

/**
 * Hook returns true once markets loading has been finalized
 * (the state of all markets is either {@link Mojito.Services.Common.types.CONTENT_STATE|AVAILABLE} or {@link Mojito.Services.Common.types.CONTENT_STATE|UNAVAILABLE})
 * which means that markets have been requested and we got response from a server with or without market data for each of it.
 *
 * @function useMarketsLoadDone
 *
 * @param {Array} marketIds - The list of market ids.
 *
 * @returns {boolean} True if all markets with provided ids have finalized their loading.
 * @memberof Mojito.Modules.Common.Hooks
 */
export const useMarketsLoadDone = curry(useItemsLoadDone)(selectMarketState);

/**
 * Hook returns true once markets loading has been finalized
 * (the state of all markets is either {@link Mojito.Services.Common.types.CONTENT_STATE|AVAILABLE} or {@link Mojito.Services.Common.types.CONTENT_STATE|UNAVAILABLE})
 * which means that markets have been requested and we got response from a server with or without market data for each of it.
 *
 * @function useSportMetasLoadDone
 *
 * @param {Array} marketIds - The list of market ids.
 *
 * @returns {boolean} True if all markets with provided ids have finalized their loading.
 * @memberof Mojito.Modules.Common.Hooks
 */
export const useSportMetasLoadDone = curry(useItemsLoadDone)(selectSportMetaInformationState);

/**
 * Hook returns true once event groups loading has been finalized
 * (the state of all markets is either {@link Mojito.Services.Common.types.CONTENT_STATE|AVAILABLE} or {@link Mojito.Services.Common.types.CONTENT_STATE|UNAVAILABLE})
 * which means that groups have been requested and we got response from a server with or without group data for each of it.
 *
 * @function useEventGroupsLoadDone
 *
 * @param {Array} eventGroupIds - The list of event group ids.
 *
 * @returns {boolean} True if all groups with provided ids have finalized their loading.
 * @memberof Mojito.Modules.Common.Hooks
 */
export const useEventGroupsLoadDone = curry(useItemsLoadDone)(selectEventGroupState);

/**
 * Hook performs data request for container items by <code>containerId</code>.
 * If data is not in the store it will be requested from backend.
 *
 * @function useContainerItems
 *
 * @param {string} containerId - Desired container id.
 * @returns {Array} List of container items.
 * @memberof Mojito.Modules.Common.Hooks
 */
export const useContainerItems = containerId => {
    const descriptor = useMemo(
        () => containerId && ContainerDataDescriptor.create(containerId),
        [containerId]
    );
    useRequest([descriptor]);
    return useSelector(state => selectContainerItems(containerId, state));
};

/**
 * Hook performs data request using specified <code>descriptors</code>. The request will be performed in a loop for each descriptor in the list.
 * Every time when descriptors change, the hook will re-requests data.
 *
 * @function useRequest
 *
 * @param {Array<object>} descriptors - List of descriptor objects for correct data request. See {@link Mojito.Core.Services.RequestDataHelper#requestData|requestData} function.
 *
 * @memberof Mojito.Modules.Common.Hooks
 */
export const useRequest = descriptors => {
    const requestHelper = useControllerHelper();
    useEffect(() => {
        descriptors.filter(Boolean).forEach(requestHelper.requestData.bind(requestHelper));
    }, [descriptors, requestHelper]);
};

/**
 * @typedef InPlaySportsData
 * @type {object}
 * @property {boolean} isPending - Loading status.
 * @property {object} eventGroups  - Event groups selected by groupIds from state, containing only groups with IDs in format {sportName}-live-and-upcoming-match-and-outright-events.
 * @property {Array<string>} sportIds - List of InPlay sports ids.
 * @memberof Mojito.Modules.Common.Hooks
 */

/**
 * The hook will load sport ids that are currently in-play for provided sportListName and resolve events group id for each of the loaded sport in order to load live events per sport.
 * The event group ids are generated using -live-and-upcoming-match-and-outright-events prefix.
 * Example:if sportListName is set to "sports-with-live-and-upcoming-events" then we will load sport ids list from sports-with-live-and-upcoming-events list item.
 * E.g., we got [soccer, tennis] sports. This means that those sports have live events.
 * Then we will load event groups for each sport using prefix which will lead to requests to soccer-live-and-upcoming-match-and-outright-events and tennis-live-and-upcoming-match-and-outright-events.
 * The result will contain the map of event groups per sport, the list of live sport ids and isPending flag useful for loading animations.
 *
 * @param {string} sportListName - Name of list containing in-play sports.
 * @returns {InPlaySportsData} InPlay sports data.
 * @memberof Mojito.Modules.Common.Hooks
 */
export const useInPlaySportsData = sportListName => {
    if (!sportListName) {
        throw new Error('sportListName param must be passed to the useInplayData hook');
    }

    const { subscriptionResolver } = CoreHooks.useAppContext();

    const createSportIdToGroupIdMap = useCallback(
        sportIds =>
            invert(
                keyBy(sportIds, sportId =>
                    subscriptionResolver.liveAndUpcomingMatchAndOutrightEvents(sportId)
                )
            ),
        [subscriptionResolver]
    );

    const sportIds = useListItems(sportListName);
    const listLoadingState = useSelector(state => selectItemsState(sportListName, state));
    const isSportListLoaded = ServicesUtils.isContentLoadDone(listLoadingState);
    const sportGroupIds = (sportIds || []).map(sportId =>
        subscriptionResolver.liveAndUpcomingMatchAndOutrightEvents(sportId)
    );
    let eventGroups = useEventGroups(sportGroupIds);
    const existingGroupIds = Object.keys(eventGroups);
    const isPending =
        !isSportListLoaded || sportGroupIds.some(groupId => !existingGroupIds.includes(groupId));
    const sportIdToGroupIdMap = useMemo(
        () => createSportIdToGroupIdMap(sportIds),
        [sportIds, createSportIdToGroupIdMap]
    );
    eventGroups = useMemo(
        () => mapValues(sportIdToGroupIdMap, groupId => eventGroups[groupId]),
        [sportIdToGroupIdMap, eventGroups]
    );

    return { isPending, eventGroups, sportIds };
};

/**
 * Hook listens for SportsContent menus store changes and returns sport menu content based on time zone and sport id.
 * On first render hook requests data.
 *
 * @function useMenuContent
 *
 * @param {string} sportId - Sport id.
 * @param {string} timeZone - Time zone.
 * @returns {Mojito.Services.SportsContent.Menus.types.MenuConent} Menu content info.
 * @memberof Mojito.Modules.Common.Hooks
 */

export const useMenuContent = (sportId, timeZone) => {
    const descriptors = useMemo(() => [MenusDescriptor.create(timeZone)], [timeZone]);
    useRequest(descriptors);

    return useSelector(state => selectMenuContentInfo(sportId, timeZone, state));
};

/**
 * Hook listens to the menus slice of the Redux store. Returns true if the menu is loading, otherwise returns false.
 *
 * @function useMenuLoading
 *
 * @param {string} timeZone - Time zone.
 * @returns {boolean} Returns true when the menu is loading, otherwise - false.
 * @memberof Mojito.Modules.Common.Hooks
 */
export const useMenuLoading = timeZone => {
    const menuState = useSelector(state => selectMenuState(timeZone, state));
    const { UNKNOWN, PENDING } = CONTENT_STATE;

    return menuState === UNKNOWN || menuState === PENDING;
};

/**
 * Hook returns previous prop value.
 *
 * @function usePreviousValue
 *
 * @param {*} value - Prop which we want to know previous value.
 * @returns {*} Actual state of the store.
 * @memberof Mojito.Modules.Common.Hooks
 */
export const usePreviousValue = value => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    }, [value]);
    return ref.current;
};

/**
 * Hook performs data request for lottery by <code>id</code>.
 * If data is not in the store it will be requested from backend.
 *
 * @function useLottery
 *
 * @param {string} id - Lottery id.
 * @param {boolean} [shouldRequest = true] - Flag indicating if hook should perform lottery request.
 * @returns {object|undefined} Lottery if it exists, else undefined.
 * @memberof Mojito.Modules.Common.Hooks
 */
export const useLottery = (id, shouldRequest = true) => {
    const descriptors = useMemo(
        () => (id && shouldRequest ? [LottoDescriptor.createLotteryDescriptor(id)] : []),
        [id, shouldRequest]
    );
    useRequest(descriptors);
    return useSelector(state => selectLottery(id, state));
};

/**
 * Hook returns true once lotteries loading has been finalized
 * (the state of all lotteries is either {@link Mojito.Services.Common.types.CONTENT_STATE|AVAILABLE} or {@link Mojito.Services.Common.types.CONTENT_STATE|UNAVAILABLE})
 * which means that lotteries have been requested and we got response from a server with or without lotteries data for each of it.
 *
 * @function useLotteriesLoadDone
 *
 * @param {Array} lotteryIds - The list of lottery ids.
 *
 * @returns {boolean} True if all lotteries with provided ids have finalized their loading.
 * @memberof Mojito.Modules.Common.Hooks
 */
export const useLotteriesLoadDone = curry(useItemsLoadDone)(selectLotteryState);
