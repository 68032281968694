import PropTypes from 'prop-types';
import MojitoCore from 'mojito/core';
import MojitoModules from 'mojito/modules';
const { UIPage } = MojitoCore.Presentation;
import SingleLeaguePageImpl from './single-league-page.jsx';

const { COUPONS } = MojitoModules.SingleLeagueCoupon.SingleLeagueCouponTypes;

/**
 * @namespace SingleLeaguePage
 * @memberof Mojito.Applications.Sports.Pages
 */

/**
 * Page that displays match sport competitions/outrights for a specific location/league (sport class/type).
 *
 * @class SingleLeaguePage
 * @extends Mojito.Core.Presentation.UIPage
 * @memberof Mojito.Applications.Sports.Pages.SingleLeaguePage
 */

const propTypes = {
    pageType: PropTypes.oneOf(Object.values(COUPONS)).isRequired,
};

export default UIPage('SingleLeaguePage', SingleLeaguePageImpl, propTypes);
