import PropTypes from 'prop-types';
import CardLoaderImpl from './card-loader.jsx';
import MojitoCore from 'mojito/core';

const UIView = MojitoCore.Presentation.UIView;

/**
 * The cards are lazy loadable. Once user is swiping the cards the swiper will inform us that card is approaching the view port.
 * Swiper will send readyToLoad:true prop in this case. Once it is true we will render a card.
 * Each card is a self-sufficient module. It fetches needed content and presents it.
 * Until content fetch is pending the card might show card skeleton.
 * The aim is not to fetch content until user is intended to see the card.
 *
 * @function CardLoader
 * @name module
 *
 * @memberof Mojito.Presentation.Components.EventCards
 */

/**
 * `CardLoader` component prop types.
 *
 * @property {object} [card] - Card data object which is passed into the renderer function as a parameter.
 * @property {boolean} [readyToLoad] - Flag indicating whether component is ready to trigger the data loading.
 * @property {Function} [renderer] - The function that renders card.
 * @memberof Mojito.Presentation.Components.EventCards.CardLoader.module
 */
const propTypes = {
    card: PropTypes.object,
    readyToLoad: PropTypes.bool,
    renderer: PropTypes.func,
};

export default UIView('CardLoader', CardLoaderImpl, propTypes);
