import MojitoServices from 'mojito/services';

const BetsTypes = MojitoServices.Bets.types;
const { FILTER_CRITERIA_TYPE } = BetsTypes;

/**
 * Utility functions associated with bet history.
 *
 * @class BetHistoryModuleUtils
 * @name utils
 * @memberof Mojito.Services.BetHistory
 */
class BetHistoryModuleUtils {
    /**
     * Picks existing range filter object by value.
     *
     * @param {string} value - Value of range filter.
     * @param {Mojito.Services.Bets.types.BetsTimeFrameOptions | Mojito.Services.Bets.types.BetsLastBetsOptions} rangeFilterOptions - Range filter.
     *
     * @returns {Array<Mojito.Services.Bets.types.BetsTimeFrameOptions | Mojito.Services.Bets.types.BetsLastBetsOptions>} Selected range filter.
     * @function  Mojito.Services.BetHistory.utils#getExistingRangeFilter
     */
    static getExistingRangeFilter(value, rangeFilterOptions) {
        const rangeFilter = rangeFilterOptions.find(({ options }) => options.value === value);

        if (!rangeFilter) {
            throw Error(`Range filter does not exist - ${value}`);
        }

        return rangeFilter;
    }

    /**
     * Check if passed filter has a type of Mojito.Services.Bets.types.BetsLastBetsOptions.
     *
     * @param {object} rangeFilter - Selected range filter.
     *
     * @returns {boolean} Returns true if rangeFilter has type of Mojito.Services.Bets.types.BetsLastBetsOptions.
     * @function  Mojito.Services.BetHistory.utils#isLastBetsAmountFilter
     */
    static isLastBetsAmountFilter(rangeFilter) {
        return rangeFilter.type === FILTER_CRITERIA_TYPE.LAST_BETS_AMOUNT;
    }

    /**
     * Builds pagination object.
     *
     * @param {object} options - Options object.
     * @param {number} [options.page] - The current page number.
     * @param {object} options.rangeFilter - The selected range filter.
     * @param {number} options.itemsPerPage - The number of items to display per page.
     *
     * @returns {object} An object containing pagination information.
     * @function  Mojito.Services.BetHistory.utils#buildPaginationObject
     */
    static buildPaginationObject(options) {
        const { page, rangeFilter, itemsPerPage } = options;
        if (BetHistoryModuleUtils.isLastBetsAmountFilter(rangeFilter)) {
            return {};
        }

        return {
            itemsPerPage,
            page: page || 1,
        };
    }
}

export default BetHistoryModuleUtils;
