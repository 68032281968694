import MojitoNGen from 'mojito/ngen';

const log = MojitoNGen.logger.get('TransporterFactory');

const voidResponder = () => log.warn('Transporter instance is not set');
const NULL_TRANSPORTER = {
    init: voidResponder,
    registerCollection: voidResponder,
    subscribe: () => {
        voidResponder();
        return {
            dispose: () => {},
        };
    },
    subscribeMultiple: () => {
        voidResponder();
        return {
            dispose: () => {},
        };
    },
    search: voidResponder,
    dispose: voidResponder,
};

/**
 *
 * Class holds transporter instance.
 *
 * @name transporterFactory
 * @class TransporterFactory
 * @memberof Mojito.Core.Services
 */
class TransporterFactory {
    constructor() {
        this.transporter = NULL_TRANSPORTER;
    }

    /**
     * Set content transporter.
     *
     * @param {Mojito.Core.Services.transporterFactory.ContentTransporter} transporter - Content transporter.
     * @function Mojito.Core.Services.transporterFactory#setTransporter
     */
    setTransporter(transporter) {
        this.transporter = transporter || NULL_TRANSPORTER;
    }

    /**
     * Get content transporter.
     *
     * @returns {Mojito.Core.Services.transporterFactory.ContentTransporter} Content transporter.
     * @function Mojito.Core.Services.transporterFactory#getTransporter
     */
    getTransporter() {
        return this.transporter;
    }
}

export default new TransporterFactory();

/**
 *
 * Content transporter definiton.
 *
 * @typedef ContentTransporter
 * @type {object}
 *
 * @memberof Mojito.Core.Services.transporterFactory
 */

/**
 *
 * Initialize transporter.
 *
 * This method is called by the frameworks life-cycle manager.
 * Normally, you should not have to call this method yourself.
 *
 * @name init
 * @function
 * @param {object} [config] - Configuration properties.
 * @memberof Mojito.Core.Services.transporterFactory.ContentTransporter
 */

/**
 * Registers and configures a collection.
 *
 * @name registerCollection
 * @function
 * @param {string} collection - Collection name.
 * @param {string} locale - Locale.
 *
 * @memberof Mojito.Core.Services.transporterFactory.ContentTransporter
 */

/**
 * Subscribe to updates on a data entity.
 *
 * @name subscribe
 * @function
 * @param {string} collection - Collection that entity belongs to.
 * @param {string} id - Identifier of entity.
 * @param {Function} callback - Callback handler for updates ``function (data) {}``. An update where data is null indicates that the entity has been removed from the repository.
 * @returns {object} Subscription that must be disposed when no longer needed.
 *
 * @memberof Mojito.Core.Services.transporterFactory.ContentTransporter
 */

/**
 * Subscribe to updates on a list of data entities.
 *
 * @name subscribeMultiple
 * @function
 * @param {string} collection - Collection that entities belong to.
 * @param {Array<string>} ids - Identifiers of entities to subscribe.
 * @param {Function} callback - Callback handler for updates ``function (data) {}``. An update where data is null indicates that the entity has been removed from the repository.
 *
 * @returns {object} Subscription that must be disposed when no longer needed.
 *
 * @memberof Mojito.Core.Services.transporterFactory.ContentTransporter
 */

/**
 * Execute a search query.
 *
 * @name search
 * @function
 * @param {string} collections - The collection name, for example 'event'.
 * @param {object} options - Map containing options. Valid options are 'locale', 'limit' and 'filter'.
 * @param {string} query - Search query.
 * @param {Function} callback - Callback handler for the results function (error, result) {}, where result is an array of search results.
 *
 * @memberof Mojito.Core.Services.transporterFactory.ContentTransporter
 */

/**
 * Dispose a transporter. Stops all background processing, clear configuration, etc.
 *
 * @name dispose
 * @function
 *
 * @memberof Mojito.Core.Services.transporterFactory.ContentTransporter
 */
