import {AbstractFeature, allFeatures} from '#core/application/abstract-feature.js';

import SCHEMA from './facebook-pixel.schema.yaml';

class FacebookPixelFeature extends AbstractFeature {
    constructor(parent) {
        super(parent);

        this.initialized = false;
    }

    get name() {
        return 'FacebookPixel';
    }

    get ready() {
        return this.initialized && this.config.enabled;
    }

    get configSchema() {
        return SCHEMA;
    }

    get isThirdParty() {
        return true;
    }

    get isTracker() {
        return true;
    }

    cookieConsentGiven() {
        const {fbqInit, fbqTrack} = this.config;

        (function (f, b, e, v, n, t, s) {
            if (f.fbq) return;
            n = f.fbq = function () {
                n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
            };
            if (!f._fbq) f._fbq = n;
            n.push = n;
            n.loaded = !0;
            n.version = '2.0';
            n.queue = [];
            t = b.createElement(e);
            t.async = !0;
            t.src = v;
            s = b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t, s);
        })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');

        window.fbq('init', fbqInit);
        window.fbq('track', fbqTrack);
    }
}

export const FacebookPixel = new FacebookPixelFeature(allFeatures);
