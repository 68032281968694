import MojitoPresentation from 'mojito/presentation';

import {Logger} from '#core/utils/logger.js';

const {NavigationPathBuilder} = MojitoPresentation.Base.Navigation;
const log = Logger('Intent Utils');

export const openViewIntent = path => {
    const intent = {
        type: MojitoPresentation.Base.Intent.Types.VIEW,
        data: NavigationPathBuilder.toCustom(path),
    };
    return intent;
};

/**
 * Full list of available method could be found at:
 * https://stash.playtech.corp/projects/DGT/repos/digital-mojito/browse/client/mojito/presentation/base/navigation/navigation-path-builder.js
 *
 * @param method {string} - Method name
 * @param params {string[]} - Parameters for NavigationPathBuilder method (optional)
 * @returns {object} Intent
 */
export const openInternalIntent = (method, params) => {
    if (!NavigationPathBuilder[method]) {
        log.warn(`Invalid system intent: "${method}.`);
        return {};
    }
    const methodParams = params || [];
    return {
        type: MojitoPresentation.Base.Intent.Types.VIEW,
        data: NavigationPathBuilder[method](...methodParams),
    };
};

export const openAccountPopupIntent = selectedMenuId => {
    return {
        type: MojitoPresentation.Base.Intent.Types.SHOW_ACCOUNT_POPUP,
        data: {selectedMenuId},
    };
};

export const isValidIntent = intent => {
    return intent && intent.hasOwnProperty('type');
};
