import MojitoApplications from 'mojito/applications';

const {RouteResolver} = MojitoApplications.Common;

RouteResolver.prototype.enrichWithRoot = function (path) {
    let root = this.getRoot() || '/';
    if (path === '') {
        return root; // do not add extra slash if path is empty (typically homepage)
    }
    if (root !== '/') {
        root = `${root}/`;
    }
    // Prevent slashes duplication
    return `${root}${path}`.replace(/\/+/g, '/');
};
