import { LOCATION } from './types';
import { uniqBy } from 'mojito/utils';

/**
 * Promotion service utilities.
 *
 * @class PromotionsUtils
 * @name utils
 * @memberof Mojito.Services.Promotions
 */
export default class PromotionsUtils {
    /**
     * Combine promotions and remove those with channel.
     *
     * @param {object} state - Redux state.
     * @param {string} channel - Channel name.
     *
     * @returns {Array<object>} List of promotion locations.
     *
     * @function Mojito.Services.Promotions.utils.combinePromotionsWithoutChannel
     */
    static combinePromotionsWithoutChannel(state, channel) {
        const clearLocations = collectionMap => {
            const result = {};
            const promotionsToRemove = state.promotionsIdToChannelMap[channel];

            if (!promotionsToRemove) {
                return { ...collectionMap };
            }

            Object.keys(collectionMap).forEach(key => {
                const location = collectionMap[key];

                if (!promotionsToRemove[key]) {
                    return;
                }

                result[key] = location.filter(({ id }) => {
                    return !promotionsToRemove[key][id];
                });
            });

            return result;
        };

        state.defaultLocations = clearLocations(state.defaultLocations);
        state.userSpecificLocations = clearLocations(state.userSpecificLocations);
        delete state.promotionsIdToChannelMap[channel];

        return PromotionsUtils.mergeLocations(state);
    }

    /**
     * Merge user specific and default locations.
     *
     * @param {object} state - Redux state.
     *
     * @returns {object} Object of promotion locations.
     *
     * @function Mojito.Services.Promotions.utils.mergeLocations
     */
    static mergeLocations(state) {
        const result = {};
        Object.values(LOCATION).forEach(type => {
            const defaultLocations = state.defaultLocations[type] || [];
            const userSpecificLocations = state.userSpecificLocations[type] || [];
            const locations = defaultLocations.concat(userSpecificLocations);

            if (!locations.length) {
                return;
            }
            result[type] = uniqBy(locations, 'id');
        });

        return result;
    }

    /**
     * Mapped promotions ids to channels.
     *
     * @param {string} channel - Channel name.
     * @param {object} [locations = {}] - Promotion locations.
     *
     * @returns {object} Promotions ids mapped with channels.
     *
     * @function Mojito.Services.Promotions.utils.getPromotionsIdsToChannelMap
     */
    static getPromotionsIdsToChannelMap(channel, locations = {}) {
        const result = { [channel]: {} };

        Object.keys(locations).forEach(key => {
            const ids = {};
            (locations[key] || []).forEach(({ id }) => {
                ids[id] = id;
            });

            result[channel][key] = ids;
        });
        return result;
    }
}
