export const COLOR = {
    BASE: {
        PAGE: '#011c38',
        DIVIDER: '#0e2b48',
        SCRIM_DARK: 'rgba(0, 0, 0, 0.8)',
        SCRIM_LIGHT: 'rgba(0, 0, 0, 0.6)',
        SHADOW_SHORT: 'rgba(0, 0, 0, 0.3)',
        SHADOW_LONG: 'rgba(0, 0, 0, 0.5)',
    },
    BRAND: {
        BACKGROUND1: 'rgba(30, 25, 33, 0.6)',
        BACKGROUND2: '#ffffff',
        BACKGROUND3: '#3c5978',
        BACKGROUND4: '#10263f',
        BACKGROUND5: '#1b334d',
        BACKGROUND6: '#f7f7f7',
        BACKGROUND7: '#00162b',
        FOREGROUND1: '#ffffff',
        FOREGROUND2: '#1e1921',
        FOREGROUND3: '#c9ccd1',
        FOREGROUND4: '#3c5168',
        FOREGROUND5: '#a4a3a5',
        FOREGROUND6: '#eceff2',
        FOREGROUND7: '#050f19',
        ACCENT1: '#fa5f6b',
        ACCENT2: '#fa5f6b',
        ACCENT3: '#3c5168',
        CTA1: 'linear-gradient(to right, #ff3892 0%, #fe9d52 100%)',
        CTA2: '#fa5f6b',
    },
    SEMANTIC: {
        ERROR1: '#fb4444',
        ERROR2: '#ffd8d8',
        WARNING1: '#fbe044',
        WARNING2: '#fdf3b7',
        SUCCESS1: '#00ba91',
        SUCCESS2: '#bfebe1',
    },
    RESERVED: {
        BLACK: '#2d2d2e',
        WHITE: '#f9f9f9',
        TRANSPARENT: 'rgba(249, 249, 249, 0)',
        ACCENT: '#5990e2',
    },
};

export const FONT = {
    FAMILY: 'Cadiz, sans-serif',

    WEIGHT_NORMAL: 400,
    WEIGHT_SEMI_BOLD: 700,
    WEIGHT_BOLD: 700,

    SIZE_XXSMALL: 12,
    SIZE_XSMALL: 12,
    SIZE_SMALL: 13,
    SIZE_MEDIUM: 14,
    SIZE_LARGE: 16,
    SIZE_XLARGE: 18,
};

export const BASIC_PALETTE = {
    FONT_FAMILY: 'Cadiz, sans-serif',

    FONT_HEADER4: {
        fontSize: FONT.SIZE_SMALL,
        fontWeight: FONT.WEIGHT_BOLD,
        textTransform: 'capitalize',
    },
    FONT_BODY_MEDIUM: {
        fontSize: 14,
        fontWeight: 700,
    },

    DEFAULT_PAGE_BG: COLOR.BASE.PAGE,
    DEFAULT_CONTAINER_BG: COLOR.BRAND.BACKGROUND2,
    DEFAULT_HEADER_BG: COLOR.BASE.PAGE,
    DEFAULT_ODDS_BG: COLOR.BRAND.BACKGROUND5,
    DEFAULT_ODDS_SELECTED_BG: COLOR.BRAND.CTA1,
    DEFAULT_POPOVER_BG: COLOR.BRAND.BACKGROUND5,
    DEFAULT_SELECTION_BG: COLOR.BRAND.CTA1,
    DEFAULT_PROMO_CARD_BG: COLOR.BRAND.BACKGROUND3,
    DEFAULT_SHEET_BG: COLOR.BRAND.BACKGROUND2,
    DEFAULT_STYLIZED_BG: COLOR.BRAND.BACKGROUND7,

    DEFAULT_PRIMARY_TEXT: `${COLOR.BRAND.FOREGROUND2}|${COLOR.BRAND.BACKGROUND2}`,
    DEFAULT_SECONDARY_TEXT: `${COLOR.BRAND.BACKGROUND1}|${COLOR.BRAND.FOREGROUND4}|${COLOR.BRAND.FOREGROUND5}`,

    DEFAULT_LIVE_COLOR: COLOR.BRAND.CTA2,
    DEFAULT_PRIMARY_BUTTON_COLOR: COLOR.BRAND.CTA2,
    DEFAULT_GENERIC_ACCENT_COLOR: COLOR.BRAND.CTA2,

    DEFAULT_CONTROLS_BORDER_COLOR: `${COLOR.BRAND.FOREGROUND2}|${COLOR.BRAND.BACKGROUND2}`,
    DEFAULT_DIVIDER: COLOR.BASE.DIVIDER,

    DEFAULT_BUTTON_BORDER_RADIUS: 4,
    DEFAULT_NOTIFICATION_BORDER_RADIUS: 4,

    DEFAULT_LEAGUE_HEADER_BG: 'transparent',
};
