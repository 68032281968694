import {icon} from '#core/utils/style-utils.js';

import {registerTileSchema} from '#core/application/schema-registry.js';

import BET_STATUS_ICON from './bet-status-icon.palette-schema.yaml';

registerTileSchema(BET_STATUS_ICON);

export function generateBetStatusIcon(TILE) {
    return {
        open: icon(TILE.OPEN_BET_ICON_COLOR),
        lost: icon(TILE.LOST_BET_ICON_COLOR),
        void: icon(TILE.VOID_BET_ICON_COLOR),
        won: icon(TILE.WON_BET_ICON_COLOR),
        cashedOut: icon(TILE.CASHED_OUT_BET_ICON_COLOR),
    };
}
