import MojitoCore from 'mojito/core';
export default class AbsolutePane extends MojitoCore.Presentation.UIViewImplementation {
    render() {
        return (
            <div
                ref={this.props.elementRef}
                style={this.style}
                className={`ta-AbsolutePane ${this.props.class || ''}`}
            >
                {this.props.children}
            </div>
        );
    }
}

AbsolutePane.getStyle = (config, mode, merge) =>
    merge(
        {
            position: config.fixed ? 'fixed' : 'absolute',
        },
        config.style
    );
