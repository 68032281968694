import PropTypes from 'prop-types';
import MojitoCore from 'mojito/core';
import BonusHistoryImpl from './bonus-history.jsx';

/**
 * Component responsible for bonus history view presentation.
 *
 * @name View
 * @class BonusHistory
 * @memberof Mojito.Modules.BonusHistory
 */

/**
 * BonusHistory prop types.
 *
 * @property {Mojito.Services.Bonus.type.Campaign[]} campaigns - List of campaigns.
 * @property {Mojito.Services.Bonus.type.FreeBet[]} [freeBets=[]] - List of free bets.
 * @property {Mojito.Services.Bonus.type.OddsBoost[]} [oddsBoosts=[]] - List of odds boosts.
 * @property {string} [currencyCode] - User currency code.
 * @property {boolean} bonusHistoryLoading - Loading state of campaigns.
 *
 * @memberof Mojito.Modules.BonusHistory.View
 */
const propTypes = {
    campaigns: PropTypes.array.isRequired,
    freeBets: PropTypes.array,
    oddsBoosts: PropTypes.array,
    currencyCode: PropTypes.string,
    bonusHistoryLoading: PropTypes.bool.isRequired,
};

const defaultTypes = {
    freeBets: [],
    oddsBoosts: [],
};

export default MojitoCore.Presentation.UIView(
    'BonusHistory',
    BonusHistoryImpl,
    propTypes,
    defaultTypes
);
