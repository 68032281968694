/**
 * Event group types.
 *
 * @class EventGroupTypes
 * @name types
 * @memberof Mojito.Services.EventGroups
 */
export default class EventGroupTypes {}

/**
 * PRICE_BOOST_EVENT_GROUP_NAME ITEMS enum, used for indicating which price boost group should be fetched.
 *
 * @typedef PRICE_BOOST_EVENT_GROUP_NAME
 * @property {string} ALL_SPORTS - Event group including all sports with price boosted events.
 * @property {string} SPECIFIC_SPORT - Sport specific event group with price boosted events.
 * @memberof Mojito.Services.EventGroups.types
 */
EventGroupTypes.PRICE_BOOST_EVENT_GROUP_NAME = {
    ALL_SPORTS: 'all-price-boosted-events-grouped-by-sport',
    SPECIFIC_SPORT: '-price-boosted-events',
};

/**
 * Event group collection name.
 *
 * @typedef COLLECTION_NAME
 * @type {string}
 * @memberof Mojito.Services.EventGroups.types
 */
EventGroupTypes.COLLECTION_NAME = 'eventgroups';

/**
 * @typedef EventGroup
 *
 * @type {object}
 * @property {string} id - Group id.
 * @property {string} classId - Group class id.
 * @property {string} typeId - Group class type id.
 * @property {string} className - Group class name.
 * @property {string} typeName - Group type name.
 * @property {Array<Mojito.Services.EventGroups.types.EventInfo>} events - Events infos list.
 *
 * @memberof Mojito.Services.EventGroups.types
 */

/**
 * @typedef EventInfo
 *
 * @type {object}
 * @property {string} id - Id of the event.
 * @property {string} eventType - Type of event, MATCH, RACE or OUTRIGHT.
 * @property {string} [startTime] - Time when the event will start, eg; "2019-02-21T17:00Z".
 * @memberof Mojito.Services.EventGroups.types
 */

/**
 * @typedef MarketOption
 *
 * @type {object}
 * @property {string} name - Market option name. Typically, localized string that might come from CMS and will be rendered as is if it exists.
 * @property {string} label - Market option label. Can be used as a l10n key to map translation on it.
 * @property {Array<string>} headers - List of selection headers, usually holds selection types, e.g. ['1', 'X', '2']. For game line this field is not defined.
 * @property {string} marketLineType - Market line type. This is dynamic value generated on a backend from coupons config. Used to associate selected market option with the market that should be presented on a coupon. For game line this field is not defined.
 * @property {Array<Mojito.Services.EventGroups.types.MarketOption>} options - The list of nested market options. This property will be populated for game line with the list of markets which game line consists of. For simple markets it is not defined.
 * @memberof Mojito.Services.EventGroups.types
 */
