import {pickProperties} from '#core/utils/config-utils.js';
import {ensureStartingSlash} from '#core/utils/url-utils.js';

export function pickInitParams(params) {
    const result = pickProperties(params, [
        'APIVersion',
        'containerId',
        'routingPrefix',
        'language',
        'applicationPlaneZIndex',
        'overlayPlaneZIndex',
        'overlayContainerId',
        'cookieConsentGiven',
        'currencyCode',
        'uiContext',
        'authMethod',
        'configNameToLoad',
        'postInitConfig',
        'standalone',
    ]);
    if (!IS_LOTTO) {
        if (params.sportsbookPlaneZIndex) {
            params.applicationPlaneZIndex = params.sportsbookPlaneZIndex;
        }
    }

    switch (result.APIVersion) {
        case 5:
        case 4:
        case 3:
            break;
        case 2:
        case 1:
            throw Error('API version < 3 is not supported anymore');
        default:
            throw Error(
                'APIVersion is not specified or invalid. Probably you are calling .init without APIVersion parameter specified'
            );
    }

    result.routingPrefix = ensureStartingSlash(result.routingPrefix);
    return result;
}
