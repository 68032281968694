import MojitoCore from 'mojito/core';
import FlexPaneSeparator from './separator/index.jsx';

const classUtils = MojitoCore.Base.classUtils;

export default class FlexPane extends MojitoCore.Presentation.UIViewImplementation {
    constructor(...args) {
        super(...args);
        this.renderSeparator = this.getDefaultSeparatorRenderer();
        this.childrenReducer = this.childrenReducer.bind(this);
    }

    static flattenChildren(children) {
        // The "children" prop, coming from React, can be a quite complex hierarchy of different data types, even if
        // the JSX code that renders the children looks "flat". For example, "children" can be:
        // - An array of items that are also "children"
        // - A child node
        // - Null
        // - Undefined

        if (!children) {
            return [];
        }

        if (!Array.isArray(children)) {
            return [children];
        }

        return children.reduce((flattenedChildren, child) => {
            return flattenedChildren.concat(FlexPane.flattenChildren(child));
        }, []);
    }

    isSeparatorConfigured(separatorConfig) {
        const { flexGrow, flexBasis, size } = separatorConfig.style;
        return Boolean(size || flexGrow || flexBasis);
    }

    getDefaultSeparatorRenderer() {
        const hasNthSeparator =
            this.config.nthSeparator && Object.keys(this.config.nthSeparator).length;
        return this.isSeparatorConfigured(this.config.separator) || hasNthSeparator
            ? this.renderDefaultSeparator.bind(this)
            : undefined;
    }

    renderDefaultSeparator(key, index) {
        const specificConfig =
            this.config.nthSeparator &&
            (this.config.nthSeparator[index] ||
                this.config.nthSeparator[index % 2 === 0 ? 'even' : 'odd']);
        if (specificConfig || this.isSeparatorConfigured(this.config.separator)) {
            const config = specificConfig || this.config.separator;
            return <FlexPaneSeparator key={key} config={config} direction={this.style.direction} />;
        }
        return null;
    }

    shouldRenderSeparators() {
        return Boolean(this.getSeparatorRenderer());
    }

    getSeparatorRenderer() {
        return this.props.separator || this.renderSeparator;
    }

    shouldRenderSpacers() {
        return Boolean(this.config.itemSpacing) && Boolean(this.config.useSpacingSeparator);
    }

    renderChildren() {
        const { children } = this.props;

        // Optimization for when we know that no separators or spacers are needed
        if (!(this.shouldRenderSeparators() || this.shouldRenderSpacers())) {
            return children;
        }

        return (
            FlexPane.flattenChildren(children)
                .filter(child => !!child)
                .reduce(this.childrenReducer, []) || null
        );
    }

    childrenReducer(childrenToRender, child, i) {
        const newChildren = [];
        const key = child.key || i;

        if (i !== 0) {
            if (this.shouldRenderSeparators()) {
                if (this.shouldRenderSpacers()) {
                    newChildren.push(this.renderSpacer(`spacer1-${key}`));
                }
                const renderSeparator = this.getSeparatorRenderer();
                const separator = renderSeparator(`separator-${key}`, i - 1);
                if (separator) {
                    newChildren.push(separator);
                }
            }
            if (this.shouldRenderSpacers()) {
                newChildren.push(this.renderSpacer(`spacer2-${key}`));
            }
        }
        newChildren.push(child);

        return childrenToRender.concat(newChildren);
    }

    renderSpacer(key) {
        return (
            <FlexPaneSeparator
                config={this.style.itemSpacer}
                direction={this.style.direction}
                key={key}
            />
        );
    }

    render() {
        return (
            <div
                id={this.props.id}
                style={this.style}
                ref={this.props.elementRef}
                className={classUtils.classNames('ta-FlexPane', this.props.class)}
            >
                {this.renderChildren()}
            </div>
        );
    }
}

FlexPane.getStyle = config => {
    const spacingStyle = config.useSpacingSeparator ? {} : { gap: config.itemSpacing };
    return {
        ...config.style,
        ...spacingStyle,
        position: config.createStackingContext ? 'relative' : undefined,
        direction: config.style.flexDirection || 'row',
        itemSpacer: {
            style: {
                size: config.itemSpacing,
            },
        },
    };
};
