import MojitoCore from 'mojito/core';

const log = MojitoCore.logger.get('notificationEvent');

/**
 * @class AbstractNotificationEventResolverService
 * @abstract
 * @memberof Mojito.Services.SportsContent.NotificationEvent
 */
export default class AbstractNotificationEventResolverService {
    /**
     * Configure the service.
     * The configuration properties are specific to the concrete implementations.
     *
     * @param {object} config - Config object.
     * @returns {Mojito.Services.SportsContent.NotificationEvent.AbstractNotificationsEventResolverService} Instance of the service.
     *
     * @function Mojito.Services.SportsContent.NotificationEvent.AbstractNotificationEventResolverService#configure
     */
    // eslint-disable-next-line no-unused-vars
    configure(config) {
        return this;
    }

    /**
     * Get event ID by notifications ID.
     *
     * @param {string} notificationsId - Notifications ID.
     *
     * @returns {Promise} Event promise.
     * @function Mojito.Services.SportsContent.NotificationEvent.AbstractNotificationEventResolverService#getEventId
     */
    // eslint-disable-next-line no-unused-vars
    getEventId(notificationsId) {
        log.error('getEventId must be implemented by concrete subclass');
        return Promise.reject();
    }
}
