import MojitoCore from 'mojito/core';
import { noop, omit } from 'mojito/utils';

import BetsUtils from 'services/bets/utils';
import TaskRunner from 'services/common/task-runner/task-runner.js';
import PollOpenBetCountTask from 'services/bets/task/poll-open-bet-count-task';
import RefreshOpenBetsTask from 'services/bets/task/refresh-open-bets-task';
import BetsTypes from 'services/bets/types.js';
import { actions } from './open-bets-slice.js';

const { dispatch } = MojitoCore.Services.redux.store;

const NULL_SERVICE = {
    configure: noop,
    getBet: BetsUtils.voidResponder('cashoutService'),
};

const NULL_CASHOUT_SERVICE = {
    configure: noop,
    requestCashoutOffers: BetsUtils.voidResponder('cashoutService'),
    discardCashoutOffers: noop,
    cashout: BetsUtils.voidResponder('cashoutService'),
    addAutoCashoutRule: BetsUtils.voidResponder('cashoutService'),
    removeAutoCashoutRule: BetsUtils.voidResponder('cashoutService'),
};

const SECOND = 1000;
const MINUTE = 60 * SECOND;

/**
 * Do not request OpenBets more than every ten seconds.
 *
 * @typedef OPEN_BETS_REQUESTS_MIN_INTERVAL
 * @type {number}
 * @memberof Mojito.Services.Bets.openBetsDataRetriever
 */
export const OPEN_BETS_REQUESTS_MIN_INTERVAL = 10 * SECOND;

const DEFAULT_POLL_OPEN_BETS_INTERVAL = 2.5 * MINUTE;
const DEFAULT_GET_OPEN_BETS_SINCE_YEARS = 1;
const DEFAULT_GET_OPEN_BETS_DELAY_AFTER_CASHOUT = 5 * SECOND;

/**
 * Open bets data retriever config.
 *
 * @typedef OpenBetsDataRetrieverConfig
 * @type {object}
 * @property {Mojito.Services.Bets.AbstractBetsService} service - Instance of concrete bets service implementation.
 * @property {string} serviceUrl - URL that will be used by <code>service<code/> instance.
 * @property {Mojito.Services.Bets.openBetsDataRetriever.OpenBetsDataRetrieverOptionsConfig} openBetsConfig - Open bets setting config.
 * @property {Mojito.Services.Bets.openBetsDataRetriever.CashoutConfig} cashoutConfig - Cashout settings.
 *
 * @memberof Mojito.Services.Bets.openBetsDataRetriever
 */

/**
 * Config that holds options to retrieve open bets.
 *
 * @typedef OpenBetsDataRetrieverOptionsConfig
 * @property {number} [pollIntervalSec = 150] - Interval in seconds to poll open bets. Polling will be disabled if set to 0.
 * @property {number} [timePeriodInYears = 1] - Max age of open bets.
 * @property {number} [pollDelayAfterCashoutSec = 5] - Time in seconds to wait after a cashout has been made before updating open bets. Polling will restart after this delay. Default value is 5 seconds.
 * @memberof Mojito.Services.Bets.openBetsDataRetriever
 */

/**
 * Cash out data retriever config. Contains configuration for concrete cash out service implementation.
 * Note: depending on <code>service</code> implementation this config object can implement different interfaces.
 *
 * @typedef {Mojito.Services.Bets.cashoutService.CashoutServiceConfig} CashoutConfig
 * @property {Mojito.Services.Bets.AbstractCashoutService} service - Instance of concrete cash out service implementation.
 * @memberof Mojito.Services.Bets.openBetsDataRetriever
 */

/**
 * Open bets data retriever used to delegate calls to bets service abstraction.
 *
 * @class OpenBetsDataRetriever
 * @name openBetsDataRetriever
 * @memberof Mojito.Services.Bets
 */
class OpenBetsDataRetriever {
    constructor() {
        this.service = NULL_SERVICE;
        this.cashoutService = NULL_CASHOUT_SERVICE;
    }

    /**
     * Init bets data retriever.
     *
     * @param {Mojito.Services.Bets.openBetsDataRetriever.OpenBetsDataRetrieverConfig} config - Config object.
     *
     * @function Mojito.Services.Bets.openBetsDataRetriever#init
     */
    init({ service, serviceUrl, openBetsConfig = {}, cashoutConfig = {} }) {
        this.service = service || NULL_SERVICE;
        this.service.configure({ serviceUrl });

        const { service: cashoutService } = cashoutConfig;
        this.cashoutService = cashoutService || NULL_CASHOUT_SERVICE;
        this.cashoutService.configure(omit(cashoutConfig, 'service'));
        const { pollIntervalSec, timePeriodInYears } = openBetsConfig;
        this.getOpenBetsSinceYears = timePeriodInYears || DEFAULT_GET_OPEN_BETS_SINCE_YEARS;
        this.openBetsPollingDisabled = pollIntervalSec === 0;
        this.setUpPollingTasks(openBetsConfig);
    }

    /**
     * Check if open bets polling is allowed (not disabled on config level).
     *
     * @returns {boolean} True is open bets polling is allowed.
     */
    allowOpenBetsPolling() {
        return !this.openBetsPollingDisabled;
    }

    /**
     * Set up open bets polling.
     *
     * @param {Mojito.Services.Bets.openBetsDataRetriever.OpenBetsConfig} config - Open bets config.
     * @private
     */
    setUpPollingTasks(config) {
        const { pollIntervalSec, pollDelayAfterCashoutSec } = config;
        this.pollOpenBetsDelay =
            pollDelayAfterCashoutSec > 0
                ? pollDelayAfterCashoutSec * SECOND
                : DEFAULT_GET_OPEN_BETS_DELAY_AFTER_CASHOUT;

        // Set up open bets poll task if pollIntervalSec is not 0. If pollIntervalSec is not a number, use default value.
        if (this.allowOpenBetsPolling()) {
            this.pollOpenBetsInterval = isNaN(pollIntervalSec)
                ? DEFAULT_POLL_OPEN_BETS_INTERVAL
                : pollIntervalSec * SECOND;
            this.refreshOpenBetsRunner = new TaskRunner(this.pollOpenBetsInterval);
            this.numberOfOpenBetsPoller = new TaskRunner(this.pollOpenBetsInterval);
        }

        this.pollNumberOfOpenBetsTask = new PollOpenBetCountTask(
            this.service,
            this.getOpenBetsSinceYears
        );
        this.refreshOpenBetsTask = new RefreshOpenBetsTask(
            this.service,
            this.getOpenBetsSinceYears
        );
    }

    /**
     * Fetch open bets without using polling.
     *
     * @param {Mojito.Services.UserSettings.types.ODDS_FORMAT} [oddsFormat] - Odds format. Only required if {@link Mojito.Services.Bets.openBetsDataRetriever.OpenBetsConfig.pollNumberOfOpenBets|pollNumberOfOpenBets} is set to true.
     * @function Mojito.Services.Bets.openBetsDataRetriever#fetchOpenBets
     */
    fetchOpenBets(oddsFormat) {
        const filterCriteria = this.getOpenBetsFilterCriteria();
        this.service
            .getBets({ filterCriteria, oddsFormat })
            .then(data => dispatch(actions.fetchOpenBetsSuccess(data)))
            .catch(error => dispatch(actions.fetchOpenBetsFailed(error)));
    }

    /**
     * Get filter criteria for getting open bets.
     *
     * @returns {Mojito.Services.Bets.types.FilterCriteria} Filter criteria.
     * @private
     */
    getOpenBetsFilterCriteria() {
        const dateSince = new Date();
        dateSince.setFullYear(dateSince.getFullYear() - this.getOpenBetsSinceYears);
        return {
            filters: [BetsTypes.BET_STATUS.OPEN],
            from: dateSince.toISOString(),
        };
    }

    /**
     * Start refreshing open bets.
     *
     * @param {Function} [dataHashRetriever] - Function that returns data integrity hash, typically arrives from server and used for client/backend bets cache validation.
     *
     * @function Mojito.Services.Bets.openBetsDataRetriever#refreshOpenBets
     */
    refreshOpenBets(dataHashRetriever) {
        if (this.canExecute(this.refreshOpenBetsRunner)) {
            this.refreshOpenBetsTask.setDataHashRetriever(dataHashRetriever);
            this.refreshOpenBetsRunner.run(this.refreshOpenBetsTask);
        }
    }

    /**
     * Get number of open bets.
     *
     * @function Mojito.Services.Bets.openBetsDataRetriever#getNumberOfOpenBets
     */
    getNumberOfOpenBets() {
        if (!this.pollNumberOfOpenBetsTask) {
            return;
        }
        this.pollNumberOfOpenBetsTask.execute();
    }

    /**
     * Start polling number of open bets.
     *
     * @function Mojito.Services.Bets.openBetsDataRetriever#pollNumberOfOpenBets
     */
    pollNumberOfOpenBets() {
        if (this.canExecute(this.numberOfOpenBetsPoller)) {
            this.numberOfOpenBetsPoller.run(this.pollNumberOfOpenBetsTask);
        }
    }

    canExecute(taskRunner) {
        if (this.openBetsPollingDisabled || !taskRunner || taskRunner.isExecuting) {
            return false;
        }

        const currentTime = new Date().getTime();
        const lastExecutedTime = taskRunner.lastExecutedTime;
        return lastExecutedTime + OPEN_BETS_REQUESTS_MIN_INTERVAL < currentTime;
    }

    /**
     * Stop refreshing open bets.
     *
     * @function Mojito.Services.Bets.openBetsDataRetriever#stopRefreshingOpenBets
     */
    stopRefreshingOpenBets() {
        if (this.refreshOpenBetsRunner) {
            this.refreshOpenBetsRunner.reset();
        }
    }

    /**
     * Stop polling number of open bets.
     *
     * @function Mojito.Services.Bets.openBetsDataRetriever#stopPollingNumberOfOpenBets
     */
    stopPollingNumberOfOpenBets() {
        if (this.numberOfOpenBetsPoller) {
            this.numberOfOpenBetsPoller.reset();
        }
    }

    /**
     * Requests cashout offers for bets.
     *
     * @param {Array<Mojito.Services.Bets.types.BetInfo>} betInfos - List of bet info objects.
     *
     * @function Mojito.Services.Bets.openBetsDataRetriever#requestCashoutOffers
     */
    requestCashoutOffers(betInfos) {
        this.cashoutService.requestCashoutOffers(
            betInfos,
            data => dispatch(actions.cashoutOffersSuccess(data)),
            error => dispatch(actions.cashoutOffersFailed(error))
        );
    }

    /**
     * Discard cashout offers request made by
     * {@link Mojito.Services.Bets.openBetsDataRetriever#requestCashoutOffers|requestCashoutOffers}.
     *
     * @function Mojito.Services.Bets.openBetsDataRetriever#discardCashoutOffers
     */
    discardCashoutOffers() {
        this.cashoutService.discardCashoutOffers();
    }

    /**
     * Cash out bet.
     *
     * @param {Mojito.Services.Bets.types.BetInfo} betInfo - Bet info objects. Describes particular bet to be early cashed out.
     * @param {string} amount - Amount to cashout.
     * @function Mojito.Services.Bets.openBetsDataRetriever#cashout
     */
    cashout(betInfo, amount) {
        const cashoutSuccessPromise = cashoutInfo => {
            dispatch(actions.cashoutSuccess(cashoutInfo));
            this.refreshOpenBetsRunner &&
                this.refreshOpenBetsRunner.executeWithDelay(
                    this.refreshOpenBetsTask,
                    this.pollOpenBetsDelay
                );
            return Promise.resolve();
        };

        this.cashoutService
            .cashout(betInfo, amount)
            .then(cashoutSuccessPromise)
            .catch(() => dispatch(actions.cashoutFailed(betInfo.betId, amount)));
    }

    /**
     * Add auto cashout rule.
     *
     * @param {Mojito.Services.Bets.types.BetInfo} betInfo - Bet info objects. Describes particular bet.
     * @param {string} amount - Auto cashout amount.
     * @function Mojito.Services.Bets.openBetsDataRetriever#addAutoCashoutRule
     */
    addAutoCashoutRule(betInfo, amount) {
        this.cashoutService
            .addAutoCashoutRule(betInfo, amount)
            .then(() =>
                dispatch(actions.addAutoCashoutRuleSuccess({ betId: betInfo.betId, amount }))
            )
            .catch(error =>
                dispatch(actions.addAutoCashoutRuleFailed({ betId: betInfo.betId, error }))
            );
    }

    /**
     * Remove auto cashout rule.
     *
     * @param {Mojito.Services.Bets.types.BetInfo} betInfo - Bet info objects. Describes particular bet.
     * @function Mojito.Services.Bets.openBetsDataRetriever#removeAutoCashoutRule
     */
    removeAutoCashoutRule(betInfo) {
        this.cashoutService
            .removeAutoCashoutRule(betInfo)
            .then(() => dispatch(actions.removeAutoCashoutRuleSuccess(betInfo.betId)))
            .catch(error =>
                dispatch(actions.removeAutoCashoutRuleFailed({ betId: betInfo.betId, error }))
            );
    }
}

export default new OpenBetsDataRetriever();
