import MojitoCore from 'mojito/core';
import ServicesTypes from 'services/common/types.js';
import { STORE_KEY, INITIAL_STATE } from './slice.js';

const reduxInstance = MojitoCore.Services.redux;
const { UNKNOWN } = ServicesTypes.CONTENT_STATE;

/**
 * Lottery store selectors.
 *
 * @class LotterySelectors
 * @name selectors
 * @memberof Mojito.Services.Lottery
 */

/**
 * Selects lottery state.
 *
 * @function selectState
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {Mojito.Services.Lottery.LotteryState} The state of the lottery store.
 * @memberof Mojito.Services.Lottery.selectors
 */
export const selectState = state => {
    // Should be no need to fallback to global state once fully migrated to Redux.
    const appState = state || reduxInstance.store?.getState();
    return appState?.[STORE_KEY] || INITIAL_STATE;
};

/**
 * Selects a lottery.
 *
 * @function selectLottery
 *
 * @param {string} lotteryId - Lottery id.
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {object} Lottery object.
 * @memberof Mojito.Services.Lottery.selectors
 */
export const selectLottery = (lotteryId, state) => selectState(state).lotteries[lotteryId];

/**
 * Selects lottery lifetime state in a store.
 *
 * @function selectLotteryState
 *
 * @param {string} lotteryId - Lottery id.
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {Mojito.Services.Common.types.CONTENT_STATE} Lifetime state of specific event.
 * @memberof Mojito.Services.Lottery.selectors
 */
export const selectLotteryState = (lotteryId, state) => {
    const { lotteriesState } = selectState(state);
    return lotteriesState[lotteryId] || UNKNOWN;
};
