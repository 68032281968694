import {resolveBackground} from '#core/utils/color-utils.js';

import {registerTileSchema} from '#core/application/schema-registry.js';

import {BASE_BACKGROUND_RESETER, getButtonSidesIndent} from '../utils.js';

import SECONDARY_BUTTON from './secondary-button.palette-schema.yaml';

registerTileSchema(SECONDARY_BUTTON);

export function generateSecondaryButton(TILE) {
    const {FONT} = TILE;

    const ACTIVE_BUTTON = {
        ...BASE_BACKGROUND_RESETER,
        ...resolveBackground(TILE.SECONDARY_BUTTON_SELECTED_BG),
        borderWidth: `${TILE.SECONDARY_BUTTON_BORDER_WIDTH}px`,
        borderStyle: 'solid',
        borderColor: TILE.SECONDARY_BUTTON_SELECTED_BG,
        ...TILE.SECONDARY_BUTTON_SELECTED_TEXT,
    };

    return {
        style: {
            base: {
                fontSize: FONT.SIZE_MEDIUM,
                fontWeight: FONT.WEIGHT_SEMI_BOLD,
                borderRadius: TILE.SECONDARY_BUTTON_BORDER_RADIUS,
                alignItems: 'center',
                height: 40,
                backgroundColor: 'transparent',
                backgroundImage: 'none',
                padding: `0 ${getButtonSidesIndent(TILE.SECONDARY_BUTTON_BORDER_RADIUS)}px`,
                borderWidth: `${TILE.SECONDARY_BUTTON_BORDER_WIDTH}px`,
                borderStyle: 'solid',
                borderColor: TILE.SECONDARY_BUTTON_BORDER_COLOR,
                ...TILE.SECONDARY_BUTTON_TEXT,
            },
            active: ACTIVE_BUTTON,
            hover: ACTIVE_BUTTON,
            disabled: {
                ...BASE_BACKGROUND_RESETER,
                borderWidth: `${TILE.SECONDARY_BUTTON_BORDER_WIDTH}px`,
                borderStyle: 'solid',
                borderColor: TILE.SECONDARY_BUTTON_DISABLED_BORDER_COLOR,
                ...TILE.SECONDARY_BUTTON_DISABLED_TEXT,
            },
        },
    };
}
