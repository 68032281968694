import { Suspense } from 'react';
import MojitoNGen from 'mojito/ngen';
import Hooks from 'core/presentation/hooks';
import { isFunction } from 'mojito/utils';
import UIViewUtils from 'core/presentation/ui-view/utils';
import { ErrorBoundary } from 'react-error-boundary';

const log = MojitoNGen.logger.get('ComponentRenderer');

/**
 * Component renderer will detect the mojito component implementation (see {@link Mojito.Core.Presentation.Hooks.useImplementation|useImplementation} hook)
 * or will use defaultImpl from props. If implementation is {@link Mojito.Core.Presentation.UIViewUtils.isLazy|isLazy component} then it will be wrapped with {@link https://react.dev/reference/react/Suspense|React.Suspense}.
 * The suspense fallback will be retrieved from application context (see {@link Mojito.Core.Presentation.AppContext.ContextDefinition#suspenseFallback|suspenseFallback}) unless it is overridden
 * for specific view with help of {@link Mojito.Services.Config|services config viewImplementations}.
 * Component renderer accepts renderer function as a single child. This function will get component implementation reference as a parameter on render.
 *
 * @function ComponentRenderer
 * @memberof Mojito.Core.Presentation
 *
 * @param {object} props - Component renderer props.
 * @param {string} props.name - Component name.
 * @param {Function} props.defaultImpl - Component default implementation class or function.
 * @param {Function} props.loadingView - Loading view component will be shown for suspense implementations.
 * @param {Function} props.children - Render function. Will be called on render with component implementation reference as a parameter.
 *
 * @returns {React.ReactElement} React element.
 */
export default function ComponentRenderer(props) {
    const { name, defaultImpl, children: renderFunction, loadingView } = props;
    const appContext = Hooks.useAppContext();
    const componentImpl = Hooks.useImplementation(name, appContext.uiContextPath) || defaultImpl;
    if (!isFunction(renderFunction)) {
        log.error('component expects function renderer as a single child.');
        return null;
    }
    if (!componentImpl) {
        return null;
    }
    return UIViewUtils.isLazy(componentImpl) ? (
        <ErrorBoundary fallbackRender={appContext.errorFallback || emptyErrorComponent()}>
            <Suspense fallback={createLoadingComponent(name, loadingView, appContext)}>
                {renderFunction(componentImpl)}
            </Suspense>
        </ErrorBoundary>
    ) : (
        renderFunction(componentImpl)
    );
}

const createLoadingComponent = (name, loaderElement, appContext) => {
    const Loader = loaderElement || appContext.suspenseFallback;
    if (!Loader) {
        return null;
    }
    const acceptsName = Loader?.propTypes?.hasOwnProperty('name');
    return acceptsName ? <Loader name={name} /> : <Loader />;
};

const emptyErrorComponent = () => {
    return <div className={'ta-ComponentErrorRenderer'} />;
};
