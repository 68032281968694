import MojitoCore from 'mojito/core';
import { STORE_KEY, initialState } from './slice.js';

const reduxInstance = MojitoCore.Services.redux;

/**
 * UserBonuses store selectors.
 *
 * @class UserBonusesSelectors
 * @name selectors
 * @memberof Mojito.Services.UserBonuses
 */

/**
 * Selects UserBonuses state.
 *
 * @function selectState
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {object} The state of the UserBonuses store.
 * @memberof Mojito.Services.UserBonuses.selectors
 */
export const selectState = state => {
    // Should be no need to fallback to global state once fully migrated to Redux.
    const appState = state || reduxInstance.store?.getState();
    return appState?.[STORE_KEY] || initialState;
};

/**
 * Selects free bet voucher code.
 *
 * @function selectFreeBetCode
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {string} Free bet code.
 *
 * @memberof Mojito.Services.UserBonuses.selectors
 */
export const selectFreeBetCode = state => selectState(state).freeBetCode;

/**
 * Selects stored active free bets.
 *
 * @function selectActiveFreeBets
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {Mojito.Services.UserBonuses.types.FreeBet[]} Stored free bets.
 *
 * @memberof Mojito.Services.UserBonuses.selectors
 */
export const selectActiveFreeBets = state => selectState(state).activeFreeBets;

/**
 * Selects stored active odds boosts.
 *
 * @function selectActiveOddsBoosts
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {Mojito.Services.UserBonuses.types.OddsBoost[]} Stored odds boosts.
 *
 * @memberof Mojito.Services.UserBonuses.selectors
 */
export const selectActiveOddsBoosts = state => selectState(state).activeOddsBoosts;

/**
 * Selects stored historic free bets.
 *
 * @function selectHistoricFreeBets
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {Mojito.Services.UserBonuses.types.FreeBet[]} Stored free bets.
 *
 * @memberof Mojito.Services.UserBonuses.selectors
 */
export const selectHistoricFreeBets = state => selectState(state).historicFreeBets;

/**
 * Selects stored historic free bets.
 *
 * @function selectHistoricOddsBoosts
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {Mojito.Services.UserBonuses.types.OddsBoost[]} Stored odds boosts.
 *
 * @memberof Mojito.Services.UserBonuses.selectors
 */
export const selectHistoricOddsBoosts = state => selectState(state).historicOddsBoosts;

/**
 * Selects current free bet code input state.
 *
 * @function selectFreeBetCodeState
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {Mojito.Services.UserBonuses.types.FREE_BETS_CODE_STATE} Free bets code state.
 *
 * @memberof Mojito.Services.UserBonuses.selectors
 */
export const selectFreeBetCodeState = state => selectState(state).freeBetCodeState;

/**
 * Selects free bet code error. Returns undefined if no error.
 *
 * @function selectFreeBetCodeError
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {Mojito.Services.Bonus.types.FreeBetError|undefined} Free bet code error or undefined.
 *
 * @memberof Mojito.Services.UserBonuses.selectors
 */
export const selectFreeBetCodeError = state => selectState(state).freeBetCodeError;

/**
 * Check if store is currently fetching any historic user bonuses.
 *
 * @function selectIsFetchingHistoric
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {boolean} True if fetching is ongoing, else false.
 *
 * @memberof Mojito.Services.UserBonuses.selectors
 */
export const selectIsFetchingHistoric = state => selectState(state).isFetchingHistoricUserBonuses;
