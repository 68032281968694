import SportGroupsListViewImpl from './sport-groups-list-view.jsx';
import MojitoCore from 'mojito/core';
import PropTypes from 'prop-types';
import SingleLeagueCouponTypes from 'modules/single-league-coupon/types.js';

const UIView = MojitoCore.Presentation.UIView;
const { COUPONS } = SingleLeagueCouponTypes;

/**
 * View component displaying sport groups list.
 *
 * @class SportGroupsListView
 * @memberof Mojito.Modules.SportGroupsList
 */

/**
 * SportGroupsListView prop types.
 *
 * @property {string} sportId - The sport ID of the currently selected sport.
 * @property {Array<object>} [sportGroups = []] - List of fetched sport groups.
 * @property {string} type - Type of sport groups to be rendered, one of Mojito.Modules.LeaguesTypes.
 * @property {Mojito.Modules.SingleLeagueCoupon.SingleLeagueCouponTypes.COUPONS} pageType - Tells which type of sport page to display content for.
 * @property {string} groupId - ID to populate data with (top leagues or all leagues).
 * @property {Function} [onDataLoad] - Callback to get state of child element loading.
 * @memberof Mojito.Modules.SportGroupsListView
 */

const propTypes = {
    sportId: PropTypes.string.isRequired,
    sportGroups: PropTypes.array,
    type: PropTypes.string.isRequired,
    pageType: PropTypes.oneOf(Object.values(COUPONS)).isRequired,
    groupId: PropTypes.string.isRequired,
    onDataLoad: PropTypes.func,
};

const defaultProps = {
    sportGroups: [],
};

export default UIView('SportGroupsListView', SportGroupsListViewImpl, propTypes, defaultProps);
