import { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import MojitoModules from 'mojito/modules';
import MojitoServices from 'mojito/services';

import HomePage from 'applications/sports/pages/home-page/index.jsx';
import { navigateToBetEvents } from './deep-linking-routing.jsx';

const { utils: BetslipUtils, selectors } = MojitoServices.Betslip;
const { selectDeepLinkingBets } = selectors;
const { useEventItem } = MojitoModules.Common.Hooks;

export default memo(function DeepLinkingBetsResolveController() {
    const deepLinkingBets = useSelector(state => selectDeepLinkingBets(state));
    return Array.isArray(deepLinkingBets) ? <DeepLinkingRoutingController /> : <HomePage />;
});

function DeepLinkingRoutingController() {
    // the number of deep linking bets should be always the same (from render to render)
    const deepLinkingBets = useSelector(state => selectDeepLinkingBets(state));
    const events = deepLinkingBets
        .map(bet => {
            const { eventId } = BetslipUtils.getFirstBetPart(bet).partInfo || {};
            // eslint-disable-next-line react-hooks/rules-of-hooks
            return useEventItem(eventId); // NOSONAR
        })
        .filter(Boolean);

    useEffect(() => {
        if (deepLinkingBets.length === events.length) {
            navigateToBetEvents(events);
        }
    }, [deepLinkingBets, events]);

    return <HomePage />;
}
