import MojitoCore from 'mojito/core';

const DataProvider = MojitoCore.Services.DataProvider;

const COLLECTION_NAME = 'lottery';

/**
 *
 * Class offering the possibility to link to specific lottery.
 *
 * @class LotteryProvider
 * @extends Mojito.Core.Services.DataProvider
 * @memberof Mojito.Services.Lottery
 */
class LotteryProvider extends DataProvider {
    init(languageCode) {
        super.addSimpleCollection(COLLECTION_NAME, languageCode);
    }

    subscribeToEntity(lotteryId, onUpdate) {
        return super.subscribeToEntity(lotteryId, onUpdate, COLLECTION_NAME);
    }

    subscribeToEntities(lotteryIds, onInit, onUpdate) {
        return super.subscribeToEntities(lotteryIds, onInit, onUpdate, COLLECTION_NAME);
    }
}

export default new LotteryProvider();
