import { useOutletContext } from 'react-router';
import MojitoModules from 'mojito/modules';

const RaceCoupons = MojitoModules.RaceCoupons.module;
export default function MeetingsCoupon() {
    const { selectedFilterKey, sportId, raceCouponsConfig } = useOutletContext();

    return (
        <RaceCoupons
            sportId={sportId}
            couponSelectedFilter={selectedFilterKey}
            config={raceCouponsConfig}
        />
    );
}
