import MojitoCore from 'mojito/core';
import MojitoModules from 'mojito/modules';
import MojitoPresentation from 'mojito/presentation';
import MojitoServices from 'mojito/services';
import Search from 'applications/sports/components/search/index.jsx';
import { connect } from 'react-redux';

const { SettingsMenuPopup } = MojitoModules;
const UIViewImplementation = MojitoCore.Presentation.UIViewImplementation;
const {
    Clock,
    SessionTime,
    Button,
    Image,
    FlexPane,
    SelectableButtonGroup,
    Text,
    SelectableButton,
} = MojitoPresentation.Components;
const { dispatch } = MojitoCore.Services.redux.store;
const intentActions = MojitoCore.Intents.actions;
const IntentTypes = MojitoPresentation.Base.Intent.Types;
const { selectLoginTime } = MojitoServices.Authentication.selectors;
const isShallowEqual = MojitoCore.Base.objUtils.isShallowEqual;

const POSITION = Object.freeze({
    LEFT: 'left',
    RIGHT: 'right',
});

class MainNavigationBar extends UIViewImplementation {
    constructor(props) {
        super(props);

        this.menuItems = this.createMenuBarItems();

        this.onHelpClicked = this.onHelpClicked.bind(this);
        this.onLinkClicked = this.onLinkClicked.bind(this);
    }

    shouldComponentUpdate(nextProps, nextState) {
        return !isShallowEqual(this.props, nextProps) || !isShallowEqual(this.state, nextState);
    }

    resolveSelectedItemKey() {
        if (!this.config.items) {
            return;
        }

        const selectedItem = this.config.items.find(item => {
            const { intent } = item;
            return (
                intent &&
                intent.type === IntentTypes.VIEW &&
                this.appContext().isContentShown(intent.data)
            );
        });

        return selectedItem && `item-${selectedItem.label}`;
    }

    onHelpClicked() {
        if (this.config.helpIntent) {
            dispatch(
                intentActions.publishIntent(
                    this.config.helpIntent.type,
                    this.config.helpIntent.data
                )
            );
        }
    }

    onLinkClicked(key) {
        const selectedMenuItem = this.menuItems.find(item => item.key === key);

        if (selectedMenuItem.intent) {
            dispatch(
                intentActions.publishIntent(
                    selectedMenuItem.intent.type,
                    selectedMenuItem.intent.data
                )
            );
        }
    }

    createMenuBarItems() {
        const { items } = this.config;

        if (!Array.isArray(items)) {
            return [];
        }

        return items.map(item => {
            return {
                label: this.resolveString(item.label),
                key: `item-${item.label}`,
                intent: item.intent,
                hrefLink: item.hrefLink,
                analyticsData: this.deriveLabelNamePrefix(item.label),
            };
        });
    }

    renderCurrentTime() {
        if (this.config.isCurrentTimeVisible) {
            const { timeOffset } = this.appContext().userSettings();
            return <Clock timeOffset={timeOffset} config={this.config.currentTime} />;
        }
    }

    renderSessionTime() {
        const { loginTime } = this.props;
        const showSessionTime = this.config.showSessionTime && !!loginTime;

        if (!showSessionTime) {
            return null;
        }

        return <SessionTime loginTime={loginTime} config={this.config.sessionTime} />;
    }

    renderHelp() {
        if (!this.config.showHelpButton) {
            return null;
        }

        return (
            <Button
                config={this.config.helpButton}
                onClick={this.onHelpClicked}
                class="ta-MainNavigationBar-HelpButton"
            >
                <Image config={this.config.helpIcon} />
            </Button>
        );
    }

    renderSettings() {
        if (!this.config.showSettingsButton) {
            return null;
        }

        return <SettingsMenuPopup config={this.config.settingsMenu} />;
    }

    renderMenuItemButtons() {
        const routeResolver = this.appContext().routeResolver;
        const selectableButtons = this.menuItems.map((item, index) => (
            <SelectableButton
                key={item.key}
                selectableKey={item.key}
                class={`ta-item-${index}`}
                hrefLink={
                    item.intent.type === IntentTypes.VIEW
                        ? routeResolver.getRoute(item.intent.data)
                        : item.hrefLink
                }
            >
                <FlexPane config={this.config.linkTextContainer}>
                    <Text config={this.config.linkText}>{item.label}</Text>
                </FlexPane>
            </SelectableButton>
        ));

        return (
            <SelectableButtonGroup
                selectedKey={this.resolveSelectedItemKey()}
                onSelectionChange={this.onLinkClicked}
                config={this.config.linkButtons}
            >
                {selectableButtons}
            </SelectableButtonGroup>
        );
    }

    renderSearch() {
        return <Search config={this.config.searchControllerView} />;
    }

    renderButtons() {
        return (
            <>
                {this.renderSettings()}
                {this.renderHelp()}
            </>
        );
    }

    renderMenuBar() {
        const { buttonPosition, searchPosition } = this.config;

        return (
            <>
                {searchPosition === POSITION.LEFT && this.renderSearch()}
                {this.renderMenuItemButtons()}
                <FlexPane config={this.config.rightContainer}>
                    {searchPosition === POSITION.RIGHT && this.renderSearch()}
                    {buttonPosition === POSITION.LEFT ? this.renderButtons() : null}
                    <FlexPane config={this.config.timeBlocksContainer}>
                        {this.renderCurrentTime()}
                        {this.renderSessionTime()}
                    </FlexPane>
                    {buttonPosition === POSITION.RIGHT ? this.renderButtons() : null}
                </FlexPane>
            </>
        );
    }

    render() {
        return (
            <div style={this.style.container} className="MainNavigationBar">
                <div style={this.style.innerContainer}>{this.renderMenuBar()}</div>
            </div>
        );
    }
}

MainNavigationBar.getStyle = function (config) {
    const { rootContainer, innerContainer } = config.style;
    return {
        container: {
            justifyContent: 'center',
            display: 'flex',
            ...rootContainer,
        },
        innerContainer: {
            flexGrow: 1,
            flexShrink: 1,
            flexBasis: '100%',
            maxWidth: innerContainer.maxWidth,
            display: 'flex',
            alignItems: 'center',
        },
    };
};

const mapStateToProps = state => ({ loginTime: selectLoginTime(state) });
export default connect(mapStateToProps)(MainNavigationBar);
