import PropTypes from 'prop-types';
import SportsMenuViewImpl from './sports-menu-view.jsx';
import MojitoCore from 'mojito/core';
import SportsMenuViewTypes from '../types.js';
import { noop } from 'mojito/utils';

const UIView = MojitoCore.Presentation.UIView;

/**
 * This is a view that displays the sports menu.
 *
 * @class SportsMenuView
 * @name view
 * @memberof Mojito.Modules.SportsMenu
 */

const availableSportType = PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.oneOf(Object.values(SportsMenuViewTypes.SPORT_TYPES)),
});

const highlightedSportInfo = PropTypes.shape({
    id: PropTypes.string.isRequired,
    isFavoriteSport: PropTypes.bool,
    isTopSport: PropTypes.bool,
});

/**
 * `SportsMenuView` prop types.
 *
 * @property {string} [selectedSportId] - The id of the currently selected sport.
 * @property {Array} availableSports - An array containing all currently available sports.
 * @property {Array} [highlightedSports = []] - An array containing all currently defined highlighted sports.
 * @property {boolean} [showHighlightedSports = false] - A flag which should be set to true if highlighted sports should be displayed.
 * @property {Mojito.Modules.SportsMenu.types.VIEW_TYPES} [viewType = DEFAULT] - The type of visual representation of the component. It can be used to display the component in different modes. Possible types are derived from {@link Mojito.Modules.SportsMenu.types.VIEW_TYPES|VIEW_TYPES}.
 * @property {Function} [cbSportClicked = ()=>{}] - A callback function triggered when a sport is clicked.
 * @property {boolean} [isExpanded = false] - A flag that should be set to true if the view should initially be expanded.
 *
 * @memberof Mojito.Modules.SportsMenu.view
 */
const propTypes = {
    selectedSportId: PropTypes.string,
    availableSports: PropTypes.arrayOf(availableSportType).isRequired,
    highlightedSports: PropTypes.arrayOf(highlightedSportInfo),
    showHighlightedSports: PropTypes.bool,
    cbSportClicked: PropTypes.func,
    viewType: PropTypes.oneOf(Object.values(SportsMenuViewTypes.VIEW_TYPES)),
    isExpanded: PropTypes.bool,
};

const defaultProps = {
    highlightedSports: [],
    showHighlightedSports: false,
    cbSportClicked: noop,
    viewType: SportsMenuViewTypes.VIEW_TYPES.DEFAULT,
    isExpanded: false,
};

export default UIView('SportsMenuView', SportsMenuViewImpl, propTypes, defaultProps);
