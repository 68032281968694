import MojitoServices from 'mojito/services';
import MojitoCore from 'mojito/core';
import { isEmpty, curry } from 'mojito/utils';
import { useRequest } from 'modules/common/hooks/index.js';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useContentPreload, useItemsLoadDone } from 'modules/common/hooks';

const {
    descriptor: PromotionsDescriptor,
    selectors: { makeSelectPromotions, selectPromotionState },
} = MojitoServices.Promotions;
const EventDataDescriptors = MojitoServices.SportsContent.Events.descriptors;
const { createPromotionLocationDescriptor } = PromotionsDescriptor;
const { PROMOTION_EVENTS, PROMOTION_MARKETS } =
    MojitoServices.SportsContent.Events.types.CONTENT_ORIGIN;
const { EVENT_CHUNK, EVENT_MARKET_CHUNK } = EventDataDescriptors.DATA_TYPES;
const { arraysAreEqual } = MojitoCore.Base.objUtils;

/**
 * Contains custom hooks for promotions functionality.
 *
 * @class PromotionHooks
 * @name hooks
 * @memberof Mojito.Modules.Promotions
 */

/**
 * Fetch promotions from promotions store.
 *
 * @function usePromotions
 *
 * @param {Mojito.Services.Promotions.types.LOCATION} locationId - Promotion's location id.
 * @param {string} page - Applicable page where promotion can be shown.
 *
 * @returns {Mojito.Services.Promotions.types.Promotion[]} Promotions list.
 * @memberof Mojito.Modules.Promotions.hooks
 */
const usePromotions = (locationId, page) => {
    const descriptors = useMemo(
        () => [createPromotionLocationDescriptor(locationId)],
        [locationId]
    );
    useRequest(descriptors);
    const selectPromotionsByLocationId = useMemo(makeSelectPromotions, []);
    return useSelector(state => selectPromotionsByLocationId(locationId, page, state));
};

/**
 * Hook returns true once promotion locations loading has been finalized
 * (the state of promotion locations is either {@link Mojito.Services.Common.types.CONTENT_STATE|AVAILABLE} or {@link Mojito.Services.Common.types.CONTENT_STATE|UNAVAILABLE})
 * which means that the promotions have been requested, and we got response from a server with or without promotion data for each of it.
 *
 * @function usePromotionsLoadDone
 *
 * @param {Array} locationIds - Location ids.
 *
 * @returns {boolean} True if all promotions items with provided ids have finalized their loading.
 * @memberof Mojito.Modules.Promotions.hooks
 */
const usePromotionsLoadDone = curry(useItemsLoadDone)(selectPromotionState);

const getEventIds = promotions => promotions.map(promotion => promotion.event?.id).filter(Boolean);
const getMarketIds = promotions =>
    promotions
        .flatMap(promotion => {
            return (
                promotion.event?.market?.marketId ||
                Object.values(promotion.event?.marketLines || []).map(line => line.id)
            );
        })
        .filter(Boolean);

/**
 * Preload events and markets which are part of promotions on first render in one chunk.
 *
 * @function usePromotionsPreload
 *
 * @param {Array<Mojito.Services.Promotions.types.Promotion>} promotions - Promotions list.
 * @param {string} instanceId - The id of a component instance.
 * @param {boolean} promotionsLoadDone - Flag indicating that promotions loading is done.
 * It is used to dispose any events and markets preload intentions once promotions loading is done and there are no available promotions.
 *
 * @returns {Array} First item in array is a flag indication that preload request has been done,
 * the second is a callback function to be triggered to evaluate items to preload. It accepts the list of visible promotion indexes.
 * The third and forth item is the ids of the events and markets preloaded.
 * @memberof Mojito.Modules.Promotions.hooks
 */
const usePromotionsPreload = (promotions, instanceId, promotionsLoadDone) => {
    const [preloadEventIds, setPreloadEventIds] = useState();
    const [preloadMarketIds, setPreloadMarketIds] = useState();

    const [preloadEventsDone, resetEventsPreload] = useContentPreload(
        instanceId,
        PROMOTION_EVENTS,
        EVENT_CHUNK,
        preloadEventIds
    );
    const [preloadMarketsDone, resetMarketsPreload] = useContentPreload(
        instanceId,
        PROMOTION_MARKETS,
        EVENT_MARKET_CHUNK,
        preloadMarketIds
    );

    const evaluateItemsToPreload = useCallback(
        slideIndexes => {
            if (!slideIndexes) {
                return;
            }
            const promotionToPreload = slideIndexes.map(index => promotions[index]).filter(Boolean);
            const eventIds = getEventIds(promotionToPreload);
            const marketIds = getMarketIds(promotionToPreload);
            const eventsDidChange = !arraysAreEqual(preloadEventIds, eventIds);
            const marketsDidChange = !arraysAreEqual(preloadMarketIds, marketIds);
            eventIds.length && eventsDidChange && setPreloadEventIds(eventIds);
            marketIds.length && marketsDidChange && setPreloadMarketIds(marketIds);
            !eventIds.length && resetEventsPreload(true);
            !marketIds.length && resetMarketsPreload(true);
        },
        [promotions, resetEventsPreload, resetMarketsPreload, preloadEventIds, preloadMarketIds]
    );

    const noContent = promotionsLoadDone && isEmpty(promotions);
    useEffect(() => {
        if (noContent) {
            resetEventsPreload();
            resetMarketsPreload();
        }
    }, [noContent, resetEventsPreload, resetMarketsPreload]);

    const preloadDone = preloadEventsDone && preloadMarketsDone;
    return [preloadDone, evaluateItemsToPreload, preloadEventIds, preloadMarketIds];
};

export { usePromotions, usePromotionsPreload, usePromotionsLoadDone };
