import { useEffect } from 'react';

/**
 * Custom hook to trigger a callback at the next midnight relative to the given date.
 *
 * @param {Date | string} date - The initial date to calculate the time until midnight.
 * @param {Function} callback - The callback function to execute at midnight.
 */
const useMidnightEffect = (date, callback) => {
    useEffect(() => {
        const targetDate = new Date(date);
        const midnight = new Date(targetDate);
        midnight.setUTCHours(24, 0, 0, 0);

        let timeToMidnight = midnight - targetDate;

        if (timeToMidnight <= 0) {
            callback();
            timeToMidnight += 24 * 60 * 60 * 1000; // Next midnight
        }

        const timeoutId = setTimeout(() => {
            callback();
        }, timeToMidnight);

        return () => clearTimeout(timeoutId);
    }, [date, callback]);
};

export { useMidnightEffect };
