import MojitoCore from 'mojito/core';
import GameLineSkeletonImpl from './game-line-skeleton.jsx';

const UIView = MojitoCore.Presentation.UIView;

/**
 * Module that displays skeleton for game line if it is downloaded lazily.
 *
 * @class GameLineSkeleton
 * @memberof Mojito.Modules.AggregatedMarketPane
 */

export default UIView('GameLineSkeleton', GameLineSkeletonImpl);
