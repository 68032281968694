import MojitoServices from 'mojito/services';
import { isEmpty } from 'mojito/utils';
const { CASHOUT_AVAILABILITY } = MojitoServices.Bets.types;
const { AVAILABLE, UNAVAILABLE } = CASHOUT_AVAILABILITY;

/**
 * Maps request and response payloads
 * during communication with GenBet API in order to retrieve cashout offers.
 *
 * @class CashoutOffersMapper
 * @implements {Mojito.Services.Bets.types.ICashoutOffersMapper}
 * @name cashoutOffersMapper
 * @memberof Mojito.GenBetIntegration.Cashout
 */
class CashoutOffersMapper {
    /**
     * Maps {@link Mojito.Services.Bets.types.CashoutOffersRequest|CashoutOffersRequest} into GenBet API specific message format.
     *
     * @param {Mojito.Services.Bets.types.CashoutOffersRequest} request - Offers request.
     * @returns {object} API specific message object.
     * @function Mojito.GenBetIntegration.Cashout.cashoutOffersMapper#mapRequest
     */
    mapRequest(request) {
        const { token } = request;
        return JSON.stringify({
            token,
            send_ack: false,
            send_current_offers: true,
        });
    }

    /**
     * Maps GenBet cashout offer response to the list of {@link Mojito.Services.Bets.types.CashoutInfo|CashoutInfo} objects.
     *
     * @param {object} response - Cashout offer response object to be mapped into the list of CashoutInfo's.
     *
     * @returns {Array<Mojito.Services.Bets.types.CashoutInfo>} Cashout info objects list built from <code>response</code> param.
     * @function Mojito.GenBetIntegration.Cashout.cashoutOffersMapper#mapResponse
     */
    mapResponse(response) {
        const genBetMessage = JSON.parse(response);
        const { bet_id, cashout_offer } = genBetMessage;
        const { amount, amount_net, reason } = cashout_offer;

        if (reason === 'CASH_OUT_BET_STATUS_INVALID') {
            return [];
        }

        const offer = {
            betId: bet_id,
            amount,
            amountNet: amount_net,
            // If no reason then cashout is allowed, otherwise it indicates
            // that no cashout possible at all no matter what reason code it is.
            availability: isEmpty(reason) ? AVAILABLE : UNAVAILABLE,
        };

        return [offer];
    }
}

export default new CashoutOffersMapper();
