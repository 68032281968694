import MojitoCore from 'mojito/core';
import BetsTypes from 'services/bets/types.js';
import BetslipTypes from 'services/betslip/types.js';
import EventUtils from 'services/sports-content/events/utils.js';
import { isNil, pickBy, uniqBy, isEmpty } from 'mojito/utils';

const {
    PLACEMENT_STATUS,
    BETSLIP_STATUS,
    ERROR_CODE,
    PRICE_CHANGE_TYPE,
    TEASER_TYPE,
    STAKE_GROUP_NAME,
} = BetslipTypes;
const MathUtils = MojitoCore.Base.MathUtils;
const { FAILED, PENDING_PLACE, REJECTED, UPDATING, OPEN } = BETSLIP_STATUS;
const RANKED_BET_MAP = BetsTypes.RANKED_BY_INDEX.reduce((map, betType, index) => {
    map[betType] = index;
    return map;
}, {});

/**
 * Utility functions associated with the betslip.
 *
 * @class BetslipUtils
 * @name utils
 * @memberof Mojito.Services.Betslip
 */
class BetslipUtils {
    /**
     * Get all single bets in betslip.
     * Has possibility to filter output with defined <code>legSort<code/> parameter.
     *
     * @param {Mojito.Services.Betslip.types.Betslip} betslip - Betslip.
     * @param {Function} [legSortComparator = () => true] - Compare function that will be used to filter output results by leg sort.
     * If not provided, all single bets will be returned.
     *
     * @returns {Array<Mojito.Services.Betslip.types.Bet>} List of single bets in betslip.
     *
     * @function Mojito.Services.Betslip.utils.getSingleBets
     */
    static getSingleBets(betslip, legSortComparator = () => true) {
        if (!betslip || !betslip.bets) {
            return [];
        }

        return betslip.bets.filter(
            bet => bet.betType === BetsTypes.BET_TYPE.SINGLE && legSortComparator(bet.legSort)
        );
    }

    /**
     * Get number of single bets in betslip.
     *
     * @param {object} betslip - Betslip.
     * @param {Function} [legSortComparator] - Compare function that will be used to filter output results by leg sort. We should filter out BetBuilder bet by default because it's a fake bet. (legSort) => legSort !== Mojito.Services.Bets.types.LEG_SORT.BET_BUILDER.
     *
     * @returns {number} Number of signle bets in betslip.
     *
     * @function Mojito.Services.Betslip.utils.getSingleBetsCount
     */
    static getSingleBetsCount(
        betslip,
        legSortComparator = legSort => legSort !== BetsTypes.LEG_SORT.BET_BUILDER
    ) {
        return BetslipUtils.getSingleBets(betslip, legSortComparator).length;
    }

    /**
     * Gets the event ID from a provided bet.
     *
     * @param {Mojito.Services.Betslip.types.Bet} bet - The bet from which to get the event ID.
     *
     * @returns {string} Returns the event ID associated with the bet.
     *
     * @function Mojito.Services.Betslip.utils.getEventIdFromBet
     */
    static getEventIdFromBet(bet) {
        return BetslipUtils.getFirstBetPart(bet).partInfo.eventId;
    }

    /**
     * Check if bet is a match acca bet builder bet.
     *
     * @param {Mojito.Services.Betslip.types.Bet} bet - Bet.
     *
     * @returns {boolean} True if bet is a match acca bet builder, else false.
     *
     * @function Mojito.Services.Betslip.utils.isBetBuilderBet
     */
    static isBetBuilderBet(bet) {
        return bet.legSort === BetsTypes.LEG_SORT.BET_BUILDER;
    }

    /**
     * Check if bet is a match acca bet.
     *
     * @param {Mojito.Services.Betslip.types.Bet} bet - Bet.
     *
     * @returns {boolean} True if bet is a match acca, else false.
     *
     * @function Mojito.Services.Betslip.utils.isMatchAccaBet
     */
    static isMatchAccaBet(bet) {
        return bet.legSort === BetsTypes.LEG_SORT.MATCH_ACCA;
    }

    /**
     * Get bets that are considered to be match acca.
     *
     * @param {Mojito.Services.Betslip.types.Betslip} betslip - Betslip.
     *
     * @returns {Array<Mojito.Services.Betslip.types.Bet>} List of match acca bets.
     *
     * @function Mojito.Services.Betslip.utils.getMatchAccaBets
     */
    static getMatchAccaBets(betslip) {
        if (!betslip || !betslip.bets) {
            return [];
        }
        const matchAccaSorts = [BetsTypes.LEG_SORT.BET_BUILDER, BetsTypes.LEG_SORT.MATCH_ACCA];
        return BetslipUtils.getSingleBets(betslip, legSort => matchAccaSorts.includes(legSort));
    }

    /**
     * Check if bet is a grouped single bet.
     *
     * @param {Mojito.Services.Betslip.types.Bet} bet - Bet.
     *
     * @returns {boolean} True if bet is a grouped single bet, else false.
     *
     * @function Mojito.Services.Betslip.utils.isGroupedSingleBet
     */
    static isGroupedSingleBet(bet) {
        return bet.betType === BetsTypes.BET_TYPE.GROUPED_SINGLES;
    }

    /**
     * Check if the betslip is empty.
     *
     * @param {Mojito.Services.Betslip.types.Betslip} betslip - Betslip.
     *
     * @returns {boolean} True if betslip is empty, else false.
     *
     * @function Mojito.Services.Betslip.utils#isBetslipEmpty
     */
    static isBetslipEmpty(betslip) {
        return !betslip || BetslipUtils.getSingleBetsCount(betslip) === 0;
    }

    /**
     * Get grouped single bet.
     *
     * @param {Mojito.Services.Betslip.types.Betslip} betslip - Betslip.
     *
     * @returns {Mojito.Services.Betslip.types.Bet|undefined} Bet if it exists, else undefined.
     *
     * @function Mojito.Services.Betslip.utils.getGroupedSinglesBet
     */
    static getGroupedSinglesBet(betslip) {
        return BetslipUtils.getBetsByType(betslip, BetsTypes.BET_TYPE.GROUPED_SINGLES)[0];
    }

    /**
     * Get multi bet.
     *
     * @param {Mojito.Services.Betslip.types.Betslip} betslip - Betslip.
     *
     * @returns {Mojito.Services.Betslip.types.Bet|undefined} Bet if it exists, else undefined.
     *
     * @function Mojito.Services.Betslip.utils.getMultiBet
     */
    static getMultiBet(betslip) {
        const singleBetCount = BetslipUtils.getSingleBets(betslip).length;
        if (singleBetCount < 2) {
            return undefined;
        }

        const betBuilderBetFound = betslip.bets?.find(bet => BetslipUtils.isBetBuilderBet(bet));
        if (betBuilderBetFound) {
            return betBuilderBetFound;
        }

        const acc = BetsTypes.RANKED_BY_INDEX[singleBetCount];
        return BetslipUtils.getBetsByType(betslip, acc)[0];
    }

    /**
     * Get system bets.
     *
     * @param {Mojito.Services.Betslip.types.Betslip} betslip - Betslip.
     * @param {boolean} [includeGroupedSingleBet = false] - True if grouped single should be included.
     *
     * @returns {Array<Mojito.Services.Betslip.types.Bet>} List of system bets.
     *
     * @function Mojito.Services.Betslip.utils.getSystemBets
     */
    static getSystemBets(betslip, includeGroupedSingleBet = false) {
        const filter = ({ betType }) =>
            betType !== BetsTypes.BET_TYPE.SINGLE &&
            (includeGroupedSingleBet || betType !== BetsTypes.BET_TYPE.GROUPED_SINGLES);
        return (betslip.bets || []).filter(filter);
    }

    /**
     * Returns a list of bets of a specific type from the given betslip.
     *
     * @param {Mojito.Services.Betslip.types.Betslip} betslip - The betslip.
     * @param {Mojito.Services.Bets.types.BET_TYPE} type - The specific type of bet to find.
     *
     * @returns {Array<Mojito.Services.Betslip.types.Bet>} Returns the list of bets that match the specified type.
     *
     * @function Mojito.Services.Betslip.utils.getBetsByType
     */
    static getBetsByType(betslip, type) {
        if (!betslip.bets) {
            return [];
        }
        return betslip.bets.filter(bet => bet.betType === type);
    }

    /**
     * Check if betslip contains MAX_ACC_FOLD_BREACH error.
     *
     * @param {object} betslip - The betslip.
     *
     * @returns {boolean} True if betslip contains error about accumulator fold breach, false otherwise.
     *
     * @function Mojito.Services.Betslip.utils.maxAccumulatorFoldExceeded
     */
    static maxAccumulatorFoldExceeded(betslip) {
        if (betslip && isEmpty(betslip.errors)) {
            return false;
        }
        return betslip.errors.some(error => error.code === ERROR_CODE.MAX_ACC_FOLD_BREACH);
    }

    /**
     * Check if betslip allows for singles bets.
     *
     * @param {object} betslip - The betslip.
     *
     * @returns {boolean} True if betslip allows for single bets, false otherwise.
     * @function Mojito.Services.Betslip.utils.hasMinCombinationBreachError
     */
    static hasMinCombinationBreachError(betslip) {
        if (betslip.bets?.length === 0) {
            return false;
        }
        return betslip.errors?.some(error => error.code === ERROR_CODE.MIN_COMBINATION_BREACH);
    }

    /**
     * Check if betslip bet contains available bet ways.
     *
     * @param {object} bet - Betslip bet.
     *
     * @returns {boolean} True if betslip contains available bet ways.
     * @function Mojito.Services.Betslip.utils.hasAvailableBetWays
     */
    static hasAvailableBetWays(bet) {
        return bet.availableBetWays.length > 0;
    }

    /**
     * Get bet by id.
     *
     * @param {Mojito.Services.Betslip.types.Betslip} betslip - Betslip.
     * @param {string} id - Bet id.
     *
     * @returns {Mojito.Services.Betslip.types.Bet} Bet.
     *
     * @function Mojito.Services.Betslip.utils.getBetById
     */
    static getBetById(betslip, id) {
        return betslip && betslip.bets && betslip.bets.find(bet => bet.id === id);
    }

    /**
     * Get odds from the bet.
     *
     * @param {Mojito.Services.Betslip.types.Betslip} betslip - Betslip.
     * @param {string} id - Bet id.
     *
     * @returns {string} Bet odds.
     *
     * @function Mojito.Services.Betslip.utils.getBetOdds
     */
    static getBetOdds(betslip, id) {
        const bet = BetslipUtils.getBetById(betslip, id) || {};
        return bet.odds;
    }

    /**
     * Get single bet that contains part with specified selection id.
     *
     * @param {object} betslip - Betslip.
     * @param {string} selectionId - Selection id.
     * @param {Function} [legSortComparator] - Compare function that will be used to filter output results by leg sort.
     *
     * @returns {Mojito.Services.Betslip.types.Bet} Bet object.
     *
     * @function Mojito.Services.Betslip.utils.getSingleBetBySelectionId
     */
    static getSingleBetBySelectionId(betslip, selectionId, legSortComparator) {
        const bets = BetslipUtils.getSingleBets(betslip, legSortComparator);
        return bets.find(bet => !!bet.parts.find(part => part.selectionId === selectionId));
    }

    /**
     * Get single bets that contains part with specified selection id.
     *
     * @param {object} betslip - Betslip.
     * @param {string} selectionId - Selection id.
     *
     * @returns {Mojito.Services.Betslip.types.Bet[]} Bets.
     *
     * @function Mojito.Services.Betslip.utils.getSingleBetsBySelectionId
     */
    static getSingleBetsBySelectionId(betslip, selectionId) {
        const bets = BetslipUtils.getSingleBets(betslip);
        return bets.filter(bet => !!bet.parts.find(part => part.selectionId === selectionId));
    }

    /**
     * Get single bets that contains part info with specified market id.
     *
     * @param {object} betslip - Betslip.
     * @param {string} marketId - Market id.
     *
     * @returns {Array<Mojito.Services.Betslip.types.Bet>} List of bet objects.
     *
     * @function Mojito.Services.Betslip.utils.getSingleBetsByMarketId
     */
    static getSingleBetsByMarketId(betslip, marketId) {
        const bets = BetslipUtils.getSingleBets(betslip);
        return BetslipUtils.filterBetsByPartInfo(bets, partInfo => partInfo.marketId === marketId);
    }

    /**
     * Get single bets that contains part info with specified event id.
     *
     * @param {object} betslip - Betslip.
     * @param {string} eventId - Event id.
     *
     * @returns {Array<Mojito.Services.Betslip.types.Bet>} List of bet objects.
     *
     * @function Mojito.Services.Betslip.utils.getSingleBetsByEventId
     */
    static getSingleBetsByEventId(betslip, eventId) {
        const bets = BetslipUtils.getSingleBets(betslip);
        return BetslipUtils.filterBetsByPartInfo(bets, partInfo => partInfo.eventId === eventId);
    }

    /**
     * Get single bets which are included in Match Acca/Bet Builder bet with specified selectionId.
     *
     * @param {Mojito.Services.Betslip.types.Betslip} betslip - Betslip.
     *
     * @returns {Object<string, Mojito.Services.Betslip.types.Bet[]>} Map of single bets in specific matchAcca bet. Key is matchAcca bet id. Value is list of single bets.
     *
     * @function Mojito.Services.Betslip.utils.getMatchAccaSingleBetsById
     */
    static getMatchAccaSingleBetsById(betslip) {
        const matchAccaSelectionsById = BetslipUtils.getMatchAccaBets(betslip).reduce(
            (acc, bet) => {
                acc[bet.id] = BetslipUtils.getSelectionIdsFromBet(bet);
                return acc;
            },
            {}
        );

        const matchAccaSingleBetsById = Object.entries(matchAccaSelectionsById).reduce(
            (acc, [betId, selectionIds]) => {
                selectionIds.forEach(selectionId => {
                    const singleBet = BetslipUtils.getSingleBetBySelectionId(
                        betslip,
                        selectionId,
                        legSort => legSort !== BetsTypes.LEG_SORT.BET_BUILDER
                    );

                    acc[betId] = acc[betId] || [];
                    acc[betId].push(singleBet);
                });

                return acc;
            },
            {}
        );

        return matchAccaSingleBetsById;
    }

    /**
     * Get bets filtered by specified <code>predicate<code/> function.
     *
     * @param {Array<Mojito.Services.Betslip.types.Bet>} bets - List of bets.
     * @param {Function} predicate - Filter function predicate will receive {@link Mojito.Services.Betslip.types.PartInfo|partInfo} object as input parameter.
     * Should return <code>true<code/> to include found bet in output result.
     *
     * @returns {Array<Mojito.Services.Betslip.types.Bet>} List of bet objects that satisfy function <code>predicate<code/>.
     *
     * @function Mojito.Services.Betslip.utils.filterBetsByPartInfo
     */
    static filterBetsByPartInfo(bets, predicate) {
        return bets.filter(bet => {
            return bet.parts.some(part => predicate(part.partInfo));
        });
    }

    /**
     * Returns first part from the bet.
     * Note: Typically should be used on single bets with <code>legSort</code> {@link Mojito.Services.Bets.types.LEG_SORT.DEFAULT|DEFAULT}.
     *
     * @param {Mojito.Services.Betslip.types.Bet} bet - Betslip bet.
     *
     * @returns {Mojito.Services.Betslip.types.Bet} Bet part object.
     *
     * @function Mojito.Services.Betslip.utils.getFirstBetPart
     */
    static getFirstBetPart(bet) {
        return bet.parts[0] || {};
    }

    /**
     * True if bet <code>legSort<code/> is {@link Mojito.Services.Bets.types.LEG_SORT.DEFAULT|DEFAULT}.
     *
     * @param {Mojito.Services.Betslip.types.Bet} bet - Betslip bet.
     *
     * @returns {boolean} True if default bet.
     *
     * @function Mojito.Services.Betslip.utils.isDefault
     */
    static isDefault(bet) {
        return bet.legSort === BetsTypes.LEG_SORT.DEFAULT;
    }

    /**
     * True if stake group is {@link Mojito.Services.Betslip.types.STAKE_GROUP_NAME.TEASERS|TEASERS}.
     *
     * @param {Mojito.Services.Betslip.types.STAKE_GROUP_NAME} stakeGroup - Stake group name.
     *
     * @returns {boolean} True if provided stakeGroup is teasers, false otherwise.
     *
     * @function Mojito.Services.Betslip.utils.isTeaser
     */
    static isTeaser(stakeGroup) {
        return stakeGroup === STAKE_GROUP_NAME.TEASERS;
    }

    /**
     * True if stake group is {@link Mojito.Services.Betslip.types.STAKE_GROUP_NAME.BANKERS|BANKERS}.
     *
     * @param {Mojito.Services.Betslip.types.STAKE_GROUP_NAME} stakeGroup - Stake group name.
     *
     * @returns {boolean} True if provided stakeGroup is bankers, false otherwise.
     *
     * @function Mojito.Services.Betslip.utils.isBanker
     */
    static isBanker(stakeGroup) {
        return stakeGroup === STAKE_GROUP_NAME.BANKERS;
    }

    /**
     * Get number of banker bets in stake group.
     *
     * @param {Mojito.Services.Betslip.types.Betslip} betslip - Betslip.
     * @param {Mojito.Services.Betslip.types.STAKE_GROUP_NAME} stakeGroup - Stake group name.
     *
     * @returns {number} Number of selected bankers.
     *
     * @function Mojito.Services.Betslip.utils.getBankerBetsCount
     */
    static getBankerBetsCount(betslip, stakeGroup) {
        if (!BetslipUtils.isBanker(stakeGroup)) {
            return 0;
        }
        const bets = BetslipUtils.getSingleBets(betslip);
        return bets.filter(({ isBanker }) => isBanker).length;
    }

    /**
     * True if <code>bet.odds</code> contains the same
     * price values as <code>selection</code> or if both uses SP.
     * Also compares <code>baseOdds<code/>.
     *
     * @param {Mojito.Services.Betslip.types.Bet} bet - Betslip bet.
     * @param {object} selection - Content selection object.
     * @param {string} oddsFormat - Current odds format.
     *
     * @returns {boolean} True if odds are equal, false otherwise.
     *
     * @function Mojito.Services.Betslip.utils.isOddsEqual
     */
    static isOddsEqual(bet, selection, oddsFormat) {
        const price = EventUtils.getPrice(selection.prices, bet.priceType);
        const selectionOdds = EventUtils.getSelectionOdds(selection, oddsFormat);
        const selectionBaseOdds = EventUtils.getSelectionOdds(selection, oddsFormat, true);
        const isStartingPrice =
            bet.priceType === BetsTypes.PRICE_TYPE.SP && price?.type === BetsTypes.PRICE_TYPE.SP;
        const oddsEqual = bet.odds === selectionOdds || isStartingPrice;
        const baseOddsEqual = bet.baseOdds === selectionBaseOdds;
        return oddsEqual && baseOddsEqual;
    }

    /**
     * True if part handicap is the same as selection handicap.
     *
     * @param {Mojito.Services.Betslip.types.Part} part - Betslip bet part.
     * @param {object} selection - Content selection object.
     *
     * @returns {boolean} True if handicap is equal, false otherwise.
     *
     * @function Mojito.Services.Betslip.utils.isHandicapEqual
     */
    static isHandicapEqual(part, selection) {
        if (isEmpty(part.hcap) && isEmpty(selection.handicapLabel)) {
            return true;
        }
        return parseFloat(part.hcap) === parseFloat(selection.handicapLabel);
    }

    /**
     * Get list of bet parts from single bets.
     * Note: only bets with {@link Mojito.Services.Bets.types.LEG_SORT.DEFAULT|DEFAULT} <code>legSort</code> are taken into consideration.
     * Hence parts from match accas, forecasts and other multicasts will not be returned.
     *
     * @param {object} betslip - Betslip.
     *
     * @returns {Array} List of bet parts.
     *
     * @function Mojito.Services.Betslip.utils.getPartsFromSingles
     */
    static getPartsFromSingles(betslip) {
        const bets = BetslipUtils.getSingleBets(
            betslip,
            legSort => legSort === BetsTypes.LEG_SORT.DEFAULT
        );
        return bets.map(bet => BetslipUtils.getFirstBetPart(bet));
    }

    /**
     * Get event ids to which bet belongs to.
     *
     * @param {Mojito.Services.Betslip.types.Bet} bet - Betslip bet.
     *
     * @returns {Array} List of event ids.
     *
     * @function Mojito.Services.Betslip.utils.getEventIdsFromBet
     */
    static getEventIdsFromBet(bet) {
        return bet.parts.map(part => part.partInfo.eventId);
    }

    /**
     * Get market ids to which bet belongs to.
     *
     * @param {Mojito.Services.Betslip.types.Bet} bet - Betslip bet.
     *
     * @returns {Array} List of market ids.
     *
     * @function Mojito.Services.Betslip.utils.getMarketIdsFromBet
     */
    static getMarketIdsFromBet(bet) {
        return bet.parts.map(part => part.partInfo.marketId);
    }

    /**
     * Get selection ids to which bet belongs to.
     *
     * @param {Mojito.Services.Betslip.types.Bet} bet - Betslip bet.
     *
     * @returns {Array} List of selection ids.
     *
     * @function Mojito.Services.Betslip.utils.getSelectionIdsFromBet
     */
    static getSelectionIdsFromBet(bet) {
        return bet.parts.map(part => part.selectionId);
    }

    /**
     * Resolves suspended selection ids from a betslip.
     * It will use <code>betsState</code> to find out which single bets are currently marked as suspended
     * and return selection ids for these singles.
     *
     * @param {Mojito.Services.Betslip.types.Betslip} betslip - The betslip.
     * @param {Map<string, Mojito.Services.Betslip.types.BetState>} betsState - The state of bets in betslip.
     *
     * @returns {Array<string>} List of suspended selection ids.
     * @function Mojito.Services.Betslip.utils.getSuspendedSelectionIds
     */
    static getSuspendedSelectionIds(betslip, betsState) {
        const suspendedBetIds = Object.keys(pickBy(betsState, betState => betState.isSuspended));
        const singleBets = BetslipUtils.getSingleBets(
            betslip,
            legSort => legSort === BetsTypes.LEG_SORT.DEFAULT
        );
        const suspendedSingleBets = singleBets.filter(bet => suspendedBetIds.includes(bet.id));
        return suspendedSingleBets.flatMap(BetslipUtils.getSelectionIdsFromBet);
    }

    /**
     * Check if bet is each way.
     *
     * @param {Mojito.Services.Betslip.types.Betslip} betslip - Betslip.
     * @param {string} betId - Id of bet.
     * @param {Mojito.Services.Betslip.types.STAKE_GROUP_NAME} stakeGroupName - Name of stake group.
     *
     * @returns {boolean} True if bet is each way, else false.
     *
     * @function Mojito.Services.Betslip.utils.isBetEachWay
     */
    static isBetEachWay(betslip, betId, stakeGroupName) {
        const stakeGroup = BetslipUtils.getStakeGroup(betslip, stakeGroupName);
        const betStake = stakeGroup.stakes && stakeGroup.stakes[betId];
        return betStake ? betStake.betWay === BetsTypes.BET_WAY.EACH_WAY : false;
    }

    /**
     * Get acca boost bonus of bet.
     *
     * @param {Mojito.Services.Betslip.types.Bet} bet - Bet.
     *
     * @returns {object|undefined} Bonus if found, else undefined.
     *
     * @function Mojito.Services.Betslip.utils.getBetAccaBoost
     */
    static getBetAccaBoost(bet) {
        const { bonuses = [] } = bet;
        return bonuses.find(bonus => bonus.type === BetsTypes.BONUS_TYPE.ACCA_BOOST);
    }

    /**
     * Check if bet has acca boost bonus.
     *
     * @param {Mojito.Services.Betslip.types.Bet} bet - Bet.
     *
     * @returns {boolean} True if acca boost bonus is found, else false.
     *
     * @function Mojito.Services.Betslip.utils.betHasAccaBoost
     */
    static betHasAccaBoost(bet) {
        return !!BetslipUtils.getBetAccaBoost(bet);
    }

    /**
     * Get bet each way availability.
     *
     * @param {Mojito.Services.Betslip.types.Bet} bet - Betslip bet.
     *
     * @returns {boolean} True if each way is available for bet, else false.
     *
     * @function Mojito.Services.Betslip.utils.isEachWayAvailableForBet
     */
    static isEachWayAvailableForBet(bet) {
        const { availableBetWays = [] } = bet;
        return availableBetWays.includes(BetsTypes.BET_WAY.EACH_WAY);
    }

    /**
     * Get number of lines of a bet.
     *
     * @param {Mojito.Services.Betslip.types.Bet} bet - Bet.
     * @param {boolean} [isEachWay = false] - Flag if bet is each way or not.
     *
     * @returns {number} Number of lines in a bet.
     *
     * @function Mojito.Services.Betslip.utils.getNumberOfLines
     */
    static getNumberOfLines(bet, isEachWay = false) {
        return isEachWay ? bet.numberOfEWLines : bet.numberOfLines;
    }

    /**
     * Check if stake group has acca boost bonus.
     *
     * @param {Mojito.Services.Betslip.types.Betslip} betslip - Betslip.
     * @param {Mojito.Services.Betslip.types.STAKE_GROUP_NAME} stakeGroupName - Name of stake group.
     *
     * @returns {boolean} True if stake group has acca boost bonus, else false.
     *
     * @function Mojito.Services.Betslip.utils.stakeGroupHasAccaBoost
     */
    static stakeGroupHasAccaBoost(betslip, stakeGroupName) {
        if (!betslip || !stakeGroupName) {
            return false;
        }
        const stakeGroup = BetslipUtils.getStakeGroup(betslip, stakeGroupName);
        const { stakes = {} } = stakeGroup;
        const stakeGroupBetIds = Object.keys(stakes);
        return stakeGroupBetIds
            .map(id => BetslipUtils.getBetById(betslip, id))
            .some(BetslipUtils.betHasAccaBoost);
    }

    /**
     * Get bet stakes errors within specified <code>stakeGroupName</code>.
     *
     * @param {Mojito.Services.Betslip.types.Betslip} betslip - Betslip.
     * @param {Mojito.Services.Betslip.types.STAKE_GROUP_NAME} stakeGroupName - Stake group name.
     *
     * @returns {Array<Mojito.Services.Betslip.types.Error>} List of errors.
     *
     * @function Mojito.Services.Betslip.utils.getErrorsOnStakeGroup
     */
    static getErrorsOnStakeGroup(betslip, stakeGroupName) {
        const stakeGroup = BetslipUtils.getStakeGroup(betslip, stakeGroupName);
        const { stakes = {} } = stakeGroup;
        return Object.values(stakes).flatMap(betStake => betStake.errors || []);
    }

    /**
     * Check if there are any errors in stake group for bet id.
     *
     * @param {Mojito.Services.Betslip.types.Betslip} betslip - Betslip.
     * @param {string} betId - Bet id.
     * @param {Mojito.Services.Betslip.types.STAKE_GROUP_NAME} stakeGroupName - Stake group.
     *
     * @returns {boolean} True if there are any errors for bet, else false.
     *
     * @function Mojito.Services.Betslip.utils.hasBetSpecificError
     */
    static hasBetSpecificError(betslip, betId, stakeGroupName) {
        const stakeGroup = BetslipUtils.getStakeGroup(betslip, stakeGroupName);
        const betStake = (stakeGroup.stakes && stakeGroup.stakes[betId]) || {};
        const { errors = [] } = betStake;
        return errors.length > 0;
    }

    /**
     * Find error in <code>betStake<code/> by specific <code>errorCode</code>.
     *
     * @param {Mojito.Services.Betslip.types.BetStake} betStake - Bet stake object.
     * @param {Mojito.Services.Betslip.types.ERROR_CODE} errorCode - Error code.
     *
     * @returns {Mojito.Services.Betslip.types.Error|undefined} Error object if found, else undefined.
     *
     * @function Mojito.Services.Betslip.utils.findErrorInBetStake
     */
    static findErrorInBetStake(betStake, errorCode) {
        const { errors = [] } = betStake;
        return errors.find(error => error.code === errorCode);
    }

    /**
     * Find all bet ids that has errors on a bet stake level with specific <code>errorCode</code>.
     *
     * @param {Mojito.Services.Betslip.types.Betslip} betslip - Betslip.
     * @param {Mojito.Services.Betslip.types.STAKE_GROUP_NAME} stakeGroupName - Stake group name.
     * @param {Mojito.Services.Betslip.types.ERROR_CODE} errorCode - Error code.
     *
     * @returns {Array} List of bet ids.
     *
     * @function Mojito.Services.Betslip.utils.findBetIdsWithError
     */
    static findBetIdsWithError(betslip, stakeGroupName, errorCode) {
        const { stakes: betStakes = {} } = BetslipUtils.getStakeGroup(betslip, stakeGroupName);
        return Object.keys(betStakes).filter(
            betId => !!BetslipUtils.findErrorInBetStake(betStakes[betId], errorCode)
        );
    }

    /**
     * Checks if a price change meets the acceptance criteria specified in settings.
     * The criteria could be "higher" or "lower".
     *
     * @param {string} newOdds - The updated odds. Can be in any format that odds can take.
     * @param {string} oldOdds - The previous odds. Can be in any format that odds can take.
     * @param {Mojito.Services.Betslip.types.PriceChangeAcceptanceSettings} changeSettings - The settings that specify acceptance criteria for price changes.
     *
     * @returns {boolean} Returns true if the price change meets the criteria specified in settings, otherwise false.
     *
     * @function Mojito.Services.Betslip.utils.isPriceChangeAccepted
     */
    static isPriceChangeAccepted(newOdds, oldOdds, changeSettings) {
        const toFloatFormat = odds => {
            // Typically it is not safe to perform odds conversion on a client as it can give not exact same result as it is in content.
            // But in current scenario we convert both newOdds and oldOdds with the same algorithm which suppose to be more or less reliable in context of simple comparison between each other.
            // In theory we can have a bug here if for example oldOdds: 3343/10000, newOdds: 209/625 in decimal oldOdds: 1.3343, newOdds: 1.3344.
            // In that case with fractionDigit: 3 toDecimal function will give us oldOdds: 1.334, newOdds: 1.334 which gives us a trouble with detecting change direction (higher|lower).
            // But we assume that such odds change will never come from trader on a practice.
            const isFractional = odds?.includes('/');
            return isFractional ? MathUtils.toDecimal(odds, 3) : parseFloat(odds);
        };

        const acceptsHigher = !!changeSettings[PRICE_CHANGE_TYPE.HIGHER];
        const acceptsLower = !!changeSettings[PRICE_CHANGE_TYPE.LOWER];
        if (toFloatFormat(newOdds) > toFloatFormat(oldOdds)) {
            return acceptsHigher;
        }
        if (toFloatFormat(newOdds) < toFloatFormat(oldOdds)) {
            return acceptsLower;
        }
        return true;
    }

    /**
     * Get bet part infos.
     *
     * @param {Mojito.Services.Betslip.types.Bet} bet - Bet.
     *
     * @returns {Array<Mojito.Services.Betslip.types.PartInfo>} List of part infos.
     *
     * @function Mojito.Services.Betslip.utils.getBetPartInfos
     */
    static getBetPartInfos(bet) {
        if (!bet || !bet.parts) {
            return [];
        }

        return bet.parts.map(part => part.partInfo);
    }

    /**
     * Get all bet parts in betslip.
     *
     * @param {Mojito.Services.Betslip.types.Betslip} betslip - Betslip.
     * @returns {Array<Mojito.Services.Betslip.types.BetPart>} List of bet parts.
     * @function Mojito.Services.Betslip.utils.getParts
     */
    static getParts(betslip) {
        const { bets = [] } = betslip;
        return bets.reduce((parts, bet) => {
            parts = parts.concat(bet.parts);
            return parts;
        }, []);
    }

    /**
     * Get first free bet from betslip.
     * Note: currently only one free bet per betslip is supported.
     *
     * @param {Mojito.Services.Betslip.types.Betslip} betslip - Betslip.
     * @returns {string|undefined} Free bet token if exists, else undefined.
     * @function Mojito.Services.Betslip.utils.getFreeBet
     */
    static getFreeBet(betslip) {
        const { freebets = [] } = betslip;
        return freebets[0];
    }

    /**
     * Returns true if free bet token exists on betslip, false otherwise.
     *
     * @param {Mojito.Services.Betslip.types.Betslip} betslip - Betslip.
     * @param {string} token - Free bet token.
     * @returns {boolean} True if free bet token has been found, false otherwise.
     * @function Mojito.Services.Betslip.utils.hasFreeBet
     */
    static hasFreeBet(betslip, token) {
        const { freebets = [] } = betslip;
        return freebets.includes(token);
    }

    /**
     * Get errors that match provided error <code>code</code> and error <code>sourceType</code>.
     *
     * @param {Mojito.Services.Betslip.types.Betslip} betslip - Betslip.
     * @param {Mojito.Services.Betslip.types.ERROR_CODE} code - Error code.
     * @param {Mojito.Services.Betslip.types.ERROR_SOURCE_TYPE} [sourceType] - Error source type. If not specified all errors that math error <code>code</code> will be retrieved.
     *
     * @returns {Array<Mojito.Services.Betslip.types.Error>} List of errors.
     *
     * @function Mojito.Services.Betslip.utils.getErrors
     */
    static getErrors(betslip, code, sourceType) {
        const { errors = [] } = betslip || {};
        return errors.filter(error => {
            const { code: errorCode, source = {} } = error;
            const hasSourceType = !isNil(sourceType) ? source.type === sourceType : true;
            return errorCode === code && hasSourceType;
        });
    }

    /**
     * Get bet errors list, filtered by betId.
     *
     * @param {Mojito.Services.Betslip.types.Betslip} betslip - Betslip.
     * @param {string} betId - Id of a bet, by which the errors are filtered.
     * @returns {Array<Mojito.Services.Betslip.types.Error>} Accumulative list of bet errors.
     * @function Mojito.Modules.Betslip.utils.getErrorsByBetId
     */

    static getErrorsByBetId(betslip, betId) {
        const { errors = [] } = betslip || {};
        const { BET } = BetslipTypes.ERROR_SOURCE_TYPE;

        return errors.filter(error => {
            const { type, id } = error.source || {};
            return type === BET && id === betId;
        });
    }

    /**
     * Get bet stake.
     *
     * @param {Mojito.Services.Betslip.types.Betslip} betslip - Betslip.
     * @param {string} betId - Bet id.
     * @param {Mojito.Services.Betslip.types.STAKE_GROUP_NAME} stakeGroupName - Stake group name.
     * @returns {Mojito.Services.Betslip.types.BetStake} Bet stake if exists, else empty object.
     *
     * @function Mojito.Services.Betslip.utils.getBetStake
     */
    static getBetStake(betslip, betId, stakeGroupName) {
        const stakeGroup = BetslipUtils.getStakeGroup(betslip, stakeGroupName);
        return (stakeGroup.stakes && stakeGroup.stakes[betId]) || {};
    }

    /**
     * Get stake group calculation.
     *
     * @param {Mojito.Services.Betslip.types.Betslip} betslip - Betslip.
     * @param {Mojito.Services.Betslip.types.STAKE_GROUP_NAME} stakeGroupName - Stake group name.
     * @returns {Mojito.Services.Betslip.types.Calculation} Calculation if exists, else empty object.
     *
     * @function Mojito.Services.Betslip.utils.getCalculation
     */
    static getCalculation(betslip, stakeGroupName) {
        const stakeGroup = BetslipUtils.getStakeGroup(betslip, stakeGroupName);
        const { calculation = {} } = stakeGroup;
        return calculation;
    }

    /**
     * Get stake group.
     *
     * @param {Mojito.Services.Betslip.types.Betslip} betslip - Betslip.
     * @param {Mojito.Services.Betslip.types.STAKE_GROUP_NAME} stakeGroupName - Stake group name.
     * @returns {Mojito.Services.Betslip.types.StakeGroup} Stake group if exists, else empty object.
     *
     * @function Mojito.Services.Betslip.utils.getStakeGroup
     */
    static getStakeGroup(betslip, stakeGroupName) {
        return (betslip.stakeGroups && betslip.stakeGroups[stakeGroupName]) || {};
    }

    /**
     * Check if betslip contains bets that cannot be combined.
     *
     * @param {Mojito.Services.Betslip.types.Betslip} betslip - Betslip.
     * @returns {boolean} True if any not combinable bets exists, else false.
     *
     * @function Mojito.Services.Betslip.utils.hasNotCombinableBets
     */
    static hasNotCombinableBets(betslip) {
        if (!betslip.bets) {
            return false;
        }

        return betslip.bets.some(bet => bet.hasRelatedBet || bet.notCombinable);
    }

    /**
     * Check if betslip status is open.
     *
     * @param {Mojito.Services.Betslip.types.BETSLIP_STATUS} betslipStatus - Betslip status.
     * @returns {boolean} True if betslip status is open, else false.
     *
     * @function Mojito.Services.Betslip.utils.isOpen
     */
    static isOpen(betslipStatus) {
        return betslipStatus === OPEN;
    }

    /**
     * Check if betslip status is pending initiated by either betslip place or save action.
     *
     * @param {Mojito.Services.Betslip.types.BETSLIP_STATUS} betslipStatus - Betslip status.
     * @returns {boolean} True if betslip status is pending, else false.
     *
     * @function Mojito.Services.Betslip.utils.isPending
     */
    static isPending(betslipStatus) {
        return BetslipUtils.isPendingPlace(betslipStatus);
    }

    /**
     * Check if betslip status is pending a placement.
     * Typically {@link Mojito.Services.Betslip.types.BETSLIP_STATUS.PENDING_PLACE|PENDING_PLACE} state is applied
     * during betslip place action.
     *
     * @param {Mojito.Services.Betslip.types.BETSLIP_STATUS} betslipStatus - Betslip status.
     * @returns {boolean} True if betslip status is pending, else false.
     *
     * @function Mojito.Services.Betslip.utils.isPendingPlace
     */
    static isPendingPlace(betslipStatus) {
        return betslipStatus === PENDING_PLACE;
    }

    /**
     * Check if betslip status is pending a placement or overask.
     *
     * @param {Mojito.Services.Betslip.types.BETSLIP_STATUS} betslipStatus - Betslip status.
     * @returns {boolean} True if betslip status is pending on placement or overask, else false.
     * @function Mojito.Services.Betslip.utils.isPendingPlaceOrOverask
     */
    static isPendingPlaceOrOverask(betslipStatus) {
        return BetslipUtils.isPendingPlace(betslipStatus) || BetslipUtils.isOverask(betslipStatus);
    }

    /**
     * Checks whether the betslip status is being updated.
     * Typically {@link Mojito.Services.Betslip.types.BETSLIP_STATUS.UPDATING|UPDATING} state is applied
     * during user interactions with betslip, e.g., set stake, add part etc.
     *
     * @param {Mojito.Services.Betslip.types.BETSLIP_STATUS} betslipStatus - Betslip status.
     * @returns {boolean} True if betslip status is updating, else false.
     * @function Mojito.Services.Betslip.utils.isUpdating
     */
    static isUpdating(betslipStatus) {
        return betslipStatus === UPDATING;
    }

    /**
     * Check if betslip status is failed.
     *
     * @param {Mojito.Services.Betslip.types.BETSLIP_STATUS} betslipStatus - Betslip status.
     * @returns {boolean} True if betslip status is failed, else false.
     *
     * @function Mojito.Services.Betslip.utils.isFailed
     */
    static isFailed(betslipStatus) {
        return betslipStatus === FAILED;
    }

    /**
     * Check if betslip status is rejected.
     *
     * @param {Mojito.Services.Betslip.types.BETSLIP_STATUS} betslipStatus - Betslip status.
     * @returns {boolean} True if betslip status is rejected by overask, else false.
     *
     * @function Mojito.Services.Betslip.utils.isRejected
     */
    static isRejected(betslipStatus) {
        return betslipStatus === REJECTED;
    }

    /**
     * Check if betslip status is overask.
     *
     * @param {Mojito.Services.Betslip.types.BETSLIP_STATUS} betslipStatus - Betslip status.
     * @returns {boolean} True if status is related to overask, else false.
     *
     * @function Mojito.Services.Betslip.utils.isOverask
     */
    static isOverask(betslipStatus) {
        return (
            betslipStatus === PLACEMENT_STATUS.OFFERED ||
            betslipStatus === PLACEMENT_STATUS.REFERRED ||
            betslipStatus === PLACEMENT_STATUS.REFERRED_AUTO
        );
    }

    /**
     * Get freebet constraints.
     *
     * @param {Mojito.Services.Betslip.types.Betslip} betslip - Betslip.
     * @param {Mojito.Services.Betslip.types.STAKE_GROUP_NAME} stakeGroupName - Stake group name.
     * @returns {Mojito.Services.Betslip.types.FreeBetConstraints|undefined} Free bet constraints if it exists else undefined.
     *
     * @function Mojito.Services.Betslip.utils.getFreeBetConstraints
     */
    static getFreeBetConstraints(betslip, stakeGroupName) {
        const stakeGroup = BetslipUtils.getStakeGroup(betslip, stakeGroupName);
        return stakeGroup.freeBetConstraints;
    }

    /**
     * Get unique errors based on error code.
     *
     * @param {Array<Mojito.Services.Betslip.types.Error>} errors - List of errors.
     * @returns {Array<Mojito.Services.Betslip.types.Error>} List of unique errors.
     *
     * @function Mojito.Services.Betslip.utils.getUniqueErrors
     */
    static getUniqueErrors(errors = []) {
        return uniqBy(errors, e => [e.code, e.factor].join());
    }

    /**
     * Checks if there is a MatchAcca bet with exactly these selections for this event.
     *
     * @param {Mojito.Services.Betslip.types.Betslip} betslip - Betslip.
     * @param {Array} selections - Selections to match.
     *
     * @returns {boolean} True if betslip has an identical MatchAcca bet.
     * @function Mojito.Services.Betslip.utils.hasMatchAccaWithIdenticalSelections
     */
    static hasMatchAccaWithIdenticalSelections(betslip, selections) {
        if (!betslip || !selections) {
            return false;
        }

        const singleBets = BetslipUtils.getSingleBets(betslip);
        const matchAccaBets = singleBets.filter(BetslipUtils.isMatchAccaBet);

        return matchAccaBets.some(matchAccaBet => {
            if (matchAccaBet.parts.length === selections.length) {
                return !selections.some(
                    selection => !BetslipUtils.betIncludesSelection(matchAccaBet, selection.id)
                );
            }
            return false;
        });
    }

    /**
     * Checks if bet has part with provided selectionId.
     *
     * @param {Mojito.Services.Betslip.types.Bet} bet - Bet.
     * @param {string} selectionId - Selection id.
     *
     * @returns {boolean} True if corresponding part is found, false otherwise.
     * @function Mojito.Services.Betslip.utils.betIncludesSelection
     */
    static betIncludesSelection(bet, selectionId) {
        return bet.parts.some(part => part.selectionId === selectionId);
    }

    /**
     * Get teaser type that is currently active on a betslip.
     * Will return undefined if no teaser bets available.
     *
     * @param {Mojito.Services.Betslip.types.Betslip} betslip - Betslip.
     *
     * @returns {Mojito.Services.Betslip.types.TEASER_TYPE|undefined} Active teaser type or undefined.
     * @function Mojito.Services.Betslip.utils.getActiveTeaserType
     */
    static getActiveTeaserType(betslip) {
        const { bets = [] } = betslip;
        const isValidType = bet => !!TEASER_TYPE[bet.teaserType];
        return (bets.find(isValidType) || {}).teaserType;
    }

    /**
     * @typedef DeepLinkingActions
     *
     * @property {Mojito.Services.Betslip.types.Action|undefined} clearAction - Clear betslip action.
     * @property {Array<Mojito.Services.Betslip.types.Action>} addPartActions - Add part to betslip action.
     * @property {Mojito.Services.Betslip.types.Action|undefined} setStakeAction - Set stake to betslip action.
     */

    /**
     * Generates map with deeplinking actions based on bet references, stake value and clear betslip flag.
     * Actions can be used as an input parameters for Mojito.Services.Betslip.dataRetriever#executeActions.
     *
     * @param {object} obj - Input params object.
     * @param {Array<string>} [obj.refs=[]] - Bet references (BASE64 encoded).
     * @param {number|undefined} obj.stake - Selections to match.
     * @param {boolean} [obj.clear=false] - True if betslip state should be flushed before any change from deeplinking. If false - keep current state.
     *
     * @returns {DeepLinkingActions} True if betslip has an identical MatchAcca bet.
     * @function Mojito.Services.Betslip.utils.generateDeeplinkingActions
     */
    static generateDeeplinkingActions({ refs = [], stake, clear = false }) {
        return {
            clearAction: clear
                ? {
                      type: BetslipTypes.ACTIONS.CLEAR_BETSLIP,
                      request: {
                          retainParts: [BetslipTypes.BETSLIP_PART.SETTINGS],
                      },
                  }
                : undefined,
            addPartActions: refs.map(betRef => ({
                type: BetslipTypes.ACTIONS.ADD_PART,
                request: { betRef },
            })),
            setStakeAction: stake
                ? {
                      type: BetslipTypes.ACTIONS.SET_STAKE,
                      request: {
                          betId: stake.betId,
                          stake: stake.value,
                      },
                  }
                : undefined,
        };
    }

    /**
     * Returns the rank relationship of bet types.
     *
     * @param {Mojito.Services.Betslip.types.Bet} bet1 - First bet to compare.
     * @param {Mojito.Services.Betslip.types.Bet} bet2 - Second bet to compare.
     *
     * @returns {number} 0: equal bet type, >0: bet1 has higher bet type, <0: bet2 has higher bet type.
     * @function Mojito.Services.Betslip.utils.betRankComparator
     */
    static betRankComparator(bet1, bet2) {
        return RANKED_BET_MAP[bet1.betType] - RANKED_BET_MAP[bet2.betType];
    }

    /**
     * Returns one line relationship of bets.
     *
     * @param {Mojito.Services.Betslip.types.Bet} bet1 - First bet to compare.
     * @param {Mojito.Services.Betslip.types.Bet} bet2 - Second bet to compare.
     *
     * @returns {number} 0: no bet has only one line, -1: bet1 has one line or only two eachway lines, +1 otherwise.
     * @function Mojito.Services.Betslip.utils.oneLineComparator
     */
    static oneLineComparator(bet1, bet2) {
        if (bet1.numberOfLines === 1 || bet1.numberOfEWLines === 2) {
            return -1;
        } else if (bet2.numberOfLines === 1 || bet2.numberOfEWLines === 2) {
            return 1;
        }
        return 0;
    }

    /**
     * Checks if single bets count is equal for provided betslip objects.
     *
     * @param {Mojito.Services.Betslip.types.Betslip} betslipA - First betslip.
     * @param {Mojito.Services.Betslip.types.Betslip} betslipB - Second betslip.
     *
     * @returns {boolean} True if single bets count is equal.
     * @function Mojito.Services.Betslip.utils.isBetsNumberEqual
     */
    static isBetsNumberEqual(betslipA, betslipB) {
        return (
            BetslipUtils.getSingleBetsCount(betslipA) === BetslipUtils.getSingleBetsCount(betslipB)
        );
    }
}

export default BetslipUtils;
