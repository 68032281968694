import { memo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import MojitoCore from 'mojito/core';
import MojitoServices from 'mojito/services';
import MojitoPresentation from 'mojito/presentation';
import { useMenuContent } from 'modules/common/hooks';

const { MenuRow } = MojitoPresentation.Components;
const { NavigationPathBuilder } = MojitoPresentation.Base.Navigation;
const IntentTypes = MojitoPresentation.Base.Intent.Types;
const intentActions = MojitoCore.Intents.actions;
const { selectTimeZoneGmt } = MojitoServices.UserSettings.selectors;

const SportTabSelector = ({ sportId, mojitoTools }) => {
    const { config, appContext, stringResolver } = mojitoTools;
    const { routeResolver, analyticsEmitter } = appContext;
    const dispatch = useDispatch();

    const timeZone = useSelector(state => selectTimeZoneGmt(state));
    const contentInfo = useMenuContent(sportId, timeZone);

    const items = getItems(sportId, routeResolver, stringResolver, contentInfo);
    const activeIndex = items.findIndex(item => appContext.isContentShown(item.intent.data));

    const { emitAnalytics } = analyticsEmitter;
    const onClick = useCallback(
        itemIndex => {
            const { intent, analyticsData } = items[itemIndex];
            const { type, ...restAnalyticsData } = analyticsData;
            emitAnalytics(type, restAnalyticsData);
            dispatch(intentActions.publishIntent(intent.type, intent.data));
        },
        [items, emitAnalytics, dispatch]
    );

    if (items.length === 0) {
        return null;
    }

    return (
        <TabSelector
            config={config.tabs}
            items={items}
            onItemClicked={onClick}
            activeIndex={activeIndex}
        />
    );
};

const getSportBasedTranslation = (sportId, type, stringResolver) => {
    return (
        stringResolver.resolveString(`$SPORT_TAB_SELECTOR.SPORT.${type}.${sportId}`, true) ||
        stringResolver.resolveString(`$SPORT_TAB_SELECTOR.SPORT.${type}.DEFAULT`)
    );
};

const pagesData = [
    {
        name: 'competitions',
        analyticsData: {
            type: 'matchSportMainNavigationClicked',
            linkName: 'Competitions',
        },
        routeFn: NavigationPathBuilder.toSportCompetitions,
        className: 'ta-competitions',
    },
    {
        name: 'matches',
        analyticsData: {
            type: 'matchSportMainNavigationClicked',
            linkName: 'Matches',
        },
        routeFn: NavigationPathBuilder.toSportMatches,
        className: 'ta-matches',
    },
    {
        name: 'outrights',
        analyticsData: {
            type: 'sportMainNavigationClicked',
            linkName: 'Outrights',
        },
        routeFn: NavigationPathBuilder.toSportOutright,
        className: 'ta-outrights',
    },
    {
        name: 'highlights',
        analyticsData: {
            type: 'racingMainNavigationClicked',
            linkName: 'Highlights',
        },
        routeFn: NavigationPathBuilder.toSportHighlights,
        className: 'ta-highlights',
    },
    {
        name: 'meetings',
        analyticsData: {
            type: 'racingMainNavigationClicked',
            linkName: 'Meetings',
        },
        routeFn: NavigationPathBuilder.toSportMeetings,
        className: 'ta-meetings',
    },
    {
        name: 'price_boosts',
        analyticsData: {
            type: 'sportMainNavigationClicked',
            linkName: 'PriceBoosts',
        },
        routeFn: NavigationPathBuilder.toSportPriceBoosts,
        className: 'ta-price-boosts',
    },
    {
        name: 'future',
        analyticsData: {
            type: 'sportMainNavigationClicked',
            linkName: 'Future',
        },
        routeFn: NavigationPathBuilder.toSportFuture,
        className: 'ta-future',
    },
];

const getItems = (sportId, routeResolver, stringResolver, contentInfo) =>
    pagesData
        .map(({ routeFn, name, analyticsData, className }) => {
            const data = routeFn(sportId);

            if (!contentInfo.hasOwnProperty(name)) {
                return null;
            }

            return {
                label: getSportBasedTranslation(sportId, name.toUpperCase(), stringResolver),
                intent: {
                    type: IntentTypes.VIEW,
                    data,
                },
                analyticsData: {
                    ...analyticsData,
                    sportId,
                },
                hrefLink: routeResolver.getRoute(data),
                className: className,
            };
        })
        .filter(Boolean);

// eslint-disable-next-line react/display-name
const TabSelector = memo(({ items, activeIndex, onItemClicked, config }) => {
    const menuItems = items.map((item, index) => ({
        id: index,
        data: item.label,
        className: item.className,
        hrefLink: item.hrefLink,
    }));

    return (
        <MenuRow
            config={config}
            menuItems={menuItems}
            selectedItemId={activeIndex}
            cbItemClicked={onItemClicked}
        />
    );
});

export default SportTabSelector;
