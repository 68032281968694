import {Logger} from '#core/utils/logger.js';

import {DBXAbstractPerformanceLogger} from '#core/services/performance-reporting-service/abstract-performance-logger.js';
import {DBXPerformanceService, METRIC} from '#core/services/performance-reporting-service/index.js';
import {getCollectedTime} from '#core/services/performance-reporting-service/utils.js';
import {ApplicationConfig} from '#core/config/application-config.js';

const logger = Logger('Lifecycle');

function isHomePage() {
    // TODO: consider that homepage could be overriden in routes feature
    return window.location.pathname === ApplicationConfig.initParams.routingPrefix;
}

export class AppLoadedNotifier extends DBXAbstractPerformanceLogger {
    constructor(handler) {
        super();
        this.triggered = false;
        this.notifySBLoaded = this.notifySBLoaded.bind(this);
        this.SBLoadingNotificationTimer = setTimeout(this.notifySBLoaded, 30 * 1000);
        this.handler = handler;
    }

    reportTiming(name) {
        if (
            isHomePage() &&
            (name === METRIC.INITIAL_PAGE_READY || name === METRIC.INITIAL_PAGE_INTERACTED_BEFORE_READY)
        ) {
            setTimeout(this.notifySBLoaded, 0);
        }
    }

    wsActivityMostlyFinished() {
        // Called when websocket activity is mostly finished
        // We treat it as lotto loaded
        DBXPerformanceService.reportDuration(METRIC.MOJ_TIMINGS_GET_CONFIG_DURATION, getCollectedTime('getConfig'));

        if (isHomePage()) return; // ignore wsActivityMostlyFinished for home page (rather wait for initial page ready)
        setTimeout(this.notifySBLoaded, 0);
    }

    notifySBLoaded() {
        if (this.triggered) return;
        try {
            this.triggered = true;
            logger.debug(`${PRODUCT_NAME} loaded.`);
            performance.mark(`dbx:${PRODUCT_NAME}:loaded`);
            clearTimeout(this.SBLoadingNotificationTimer);
            DBXPerformanceService.removeLogger(this); // don't like idea it unregisters itself, but it is much easier and less code

            if (this.handler) this.handler();
        } catch (e) {
            logger.warn(`Failed to to notify about application loaded": ${e.message}`);
        }
    }
}
