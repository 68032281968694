import RouteResolver from './route-resolver.js';

/**
 * Singleton object that keeps track of {@link Mojito.Applications.Common.RouteResolver| route resolver instance}.
 *
 * @class RouteResolverFactory
 * @name routeResolverFactory
 * @memberof Mojito.Applications.Common.Router
 */
class RouteResolverFactory {
    constructor() {
        this.resolver = new RouteResolver();
    }
    /**
     * Route resolver setter.
     *
     * @param {Mojito.Applications.Common.AbstractRouteResolver} resolver - Route resolver object.
     *
     * @function setRouteResolver
     *
     * @memberof Mojito.Applications.Common.Router.routeResolverFactory
     */
    setRouteResolver(resolver) {
        if (resolver) {
            this.resolver = resolver;
        }
    }

    /**
     * Route resolver getter.
     *
     * @function getRouteResolver
     *
     * @returns {Mojito.Applications.Common.AbstractRouteResolver} Route resolver instance.
     *
     * @memberof Mojito.Applications.Common.Router.routeResolverFactory
     */
    getRouteResolver() {
        return this.resolver;
    }
}

export default new RouteResolverFactory();
