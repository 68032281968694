import PropTypes from 'prop-types';
import MojitoCore from 'mojito/core';
import SportTabSelectorImpl from './sport-tab-selector.jsx';

const UIView = MojitoCore.Presentation.UIView;

/**
 * Tab selector for sport. Racing tabs: Highlights, Meetings & Future. Other sports tabs: Home, Competitions & Outrights.
 *
 * @class SportTabSelector
 * @memberof Mojito.Modules
 */

/**
 * SportTabSelector prop types.
 *
 * @property {string} sportId - The sport id.
 *
 * @memberof Mojito.Modules.SportTabSelector
 */

const propTypes = {
    sportId: PropTypes.string.isRequired,
};

export default UIView('SportTabSelector', SportTabSelectorImpl, propTypes);
