import MojitoCore from 'mojito/core';
const UIPage = MojitoCore.Presentation.UIPage;
import SportDefaultPageImpl from './sport-default-page.jsx';

/**
 * @namespace SportDefaultPage
 * @memberof Mojito.Applications.Sports.Pages
 */

/**
 * Page that responsible for presenting default page. Normaly redirects to one of the pages with contenr: Highlights, Outrigts, Competitions.
 *
 * @class SportDefaultPage
 * @extends Mojito.Core.Presentation.UIPage
 * @memberof Mojito.Applications.Sports.Pages.SportDefaultPage
 */

export default UIPage('SportDefaultPage', SportDefaultPageImpl);
