import MojitoCore from 'mojito/core';

import AbstractBonusService from './abstract-bonus-service';
import ApiRequestProvider from 'services/common/api-request/request-provider.js';

const BASE_URL = '/bonus';
const logger = MojitoCore.logger.get('BonusService');

const addFreeBetErrorMap = {
    INVALID: 'INVALID',
    REDEEMED: 'REDEEMED',
    USED: 'USED',
};

/**
 * Bonus Service config.
 *
 * @typedef BonusServiceConfig
 * @type {object}
 * @property {string} serviceUrl - Bonus Service endpoint URL.
 *
 * @memberof Mojito.Services.Bonus
 */

/**
 *
 * Class offering the possibility to interact with the Bonus API.
 *
 * @class BonusService
 * @name service
 * @extends Mojito.Services.Bonus.AbstractBonusService
 * @memberof Mojito.Services.Bonus
 */
class BonusService extends AbstractBonusService {
    /**
     * Configure bonus service.
     *
     * @param {Mojito.Services.Bonus.BonusServiceConfig} config - Bonus service config.
     * @function Mojito.Services.Bonus.BonusService#configure
     */
    configure(config) {
        this.serverUrl = config.serviceUrl;
        this.apiUrl = `${this.serverUrl}${BASE_URL}`;
        this.requestFactory = new ApiRequestProvider(logger);
    }

    getCampaigns(filter) {
        return this.requestFactory
            .post(`${this.apiUrl}/get-campaigns`)
            .withCredentials()
            .send({ filterCriteria: { filters: [filter] } });
    }

    getUserBonuses(currency, filter) {
        return this.requestFactory
            .post(`${this.apiUrl}/get-bonuses`)
            .withCredentials()
            .send({
                currency,
                filterCriteria: { filters: [filter] },
            });
    }

    getFreeBets(currency, filter) {
        return this.requestFactory
            .post(`${this.apiUrl}/get-freebets`)
            .withCredentials()
            .send({
                currency,
                filterCriteria: { filters: [filter] },
            });
    }

    addFreeBet(code) {
        return this.requestFactory
            .post(`${this.apiUrl}/register-code`)
            .withCredentials()
            .withErrorsMap(addFreeBetErrorMap)
            .send({
                code,
            });
    }
}

export default new BonusService();
