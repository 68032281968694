import MojitoCore from 'mojito/core';

import {LOG_LEVEL, Logger, setLogLevel, setLogsLevelFiltering} from '#core/utils/logger.js';
import {assignToHierarchy} from '#core/utils/config-utils.js';

import {AbstractFeature, allFeatures} from '#core/application/abstract-feature.js';
import {ApplicationConfig} from '#core/config/application-config.js';

import {getDebugFlagsFromUrlParams, setDebugFlagsFromConfiguration} from './debugFlags.js';

import SCHEMA from './debug.schema.yaml';

// This feature supposed to receive configs only from session storage
class DebugFlagsFeature extends AbstractFeature {
    constructor(...params) {
        super(...params);
        const configFromParams = getDebugFlagsFromUrlParams();
        this.config = Object.assign(
            {},
            this.getConfiguration(ApplicationConfig.getFromSessionStorage()),
            configFromParams
        );
        setDebugFlagsFromConfiguration(this.config); // The only purpose of doing that is just re-validate config by schema

        if (Object.keys(configFromParams).length > 0) {
            ApplicationConfig.updateInSessionStorage({debug: configFromParams});
        }

        if (this.config.no3rdParties) {
            this.logger.warn('%cAll third party scripts are ignored', Logger.STYLE_DEBUG);
        }
        if (this.config.wrapPhrases) {
            this.logger.warn('%cAll translation phrases are wrapped', Logger.STYLE_DEBUG);
        }
        if (this.config.keysAsPhrases) {
            this.logger.warn('%cAll translation phrases are showing keys', Logger.STYLE_DEBUG);
        }
        if (this.config.allLogs) {
            this.logger.warn('%cEnabled extensive logging', Logger.STYLE_DEBUG);
            setLogsLevelFiltering(false); // set it asap
        }
    }
    get configSchema() {
        return SCHEMA;
    }

    setupConfigs() {
        // Do nothing
    }

    async beforeMojito() {
        if (this.config.allLogs) {
            setLogsLevelFiltering(false);
            MojitoCore.logger.setGlobalLogLevel(MojitoCore.logger.DEBUG);
        }
        return super.beforeMojito(); // This Promise will auto-unwrap in this async function
    }

    beforeMojitoConfigBuild(mojitoConfig) {
        // Adjust log level additionally. Only string literals are works (mojito bug)
        assignToHierarchy(mojitoConfig, 'core', {
            logLevel: this.config.allLogs ? MojitoCore.logger.DEBUG : MojitoCore.logger.INFO,
        });
        return super.beforeMojitoConfigBuild(mojitoConfig);
    }

    afterMojito() {
        if (this.config.allLogs) {
            // Disable spam loggers, to prevent flood
            MojitoCore.logger.configureLogger('stompjs', {level: MojitoCore.logger.NONE});
            MojitoCore.logger.configureLogger('execution-engine', {level: MojitoCore.logger.NONE});
            MojitoCore.logger.configureLogger('execution-engine:job-queue', {level: MojitoCore.logger.NONE});
            MojitoCore.logger.configureLogger('socket-enabler:client', {level: MojitoCore.logger.NONE});
        }

        if (this.config.logNamespaces) {
            const namespaces = this.config.logNamespaces.split(',').map(ns => ns.trim());
            namespaces.forEach(ns => {
                setLogLevel(ns, LOG_LEVEL.EVERYTHING);
                MojitoCore.logger.configureLogger(ns, {level: MojitoCore.logger.ALL});
            });
        }
        return super.afterMojito();
    }
}

new DebugFlagsFeature(allFeatures);
