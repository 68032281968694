import { isEmpty } from 'mojito/utils';
import PrebuiltBets from 'modules/prebuilt-bets/index.jsx';
import { usePreBuiltBetCardsPromotions } from 'modules/event-cards-carousel/hooks';
import {
    usePromotions,
    usePromotionsPreload,
    usePromotionsLoadDone,
} from 'modules/promotions/hooks';
import { useMarketsLoadDone, useEventsLoadDone } from 'modules/common/hooks/index.js';
import { useLoadingStatusMetric } from 'modules/performance/hooks';
import { memo, useMemo } from 'react';

// eslint-disable-next-line react/display-name
const PrebuiltPromotionCards = memo(props => {
    const {
        applicablePage,
        mojitoTools: { config, instanceId },
    } = props;

    const { locationId, reportLoadingStatus } = config;

    const cardPromotions = usePromotions(locationId, applicablePage);
    const cards = usePreBuiltBetCardsPromotions(cardPromotions);

    const promotionsLoaded = usePromotionsLoadDone([locationId]);

    const [, onPrepareCardsToRender, viewableEventIds, viewableMarketIds] = usePromotionsPreload(
        cardPromotions,
        instanceId,
        promotionsLoaded
    );
    const betItems = useMemo(() => {
        return cards.map(card => ({
            eventId: card.content.eventId,
            marketId: card.content.marketId,
        }));
    }, [cards]);

    const allEventIds = betItems.map(card => card.eventId);
    const allMarketIds = betItems.map(card => card.marketId);
    // In case of the prefetch (when the store is filled up with the data even before mojito first render) we need to take into consideration
    // all events and markets available in cards and check their availability in store. If all are available - no need to show skeleton at all.
    const eventIds = viewableEventIds || allEventIds;
    const marketIds = viewableMarketIds || allMarketIds;
    const eventsLoaded = useEventsLoadDone(eventIds);
    const marketsLoaded = useMarketsLoadDone(marketIds);
    const isContentLoaded = promotionsLoaded && eventsLoaded && marketsLoaded;

    const noContent = promotionsLoaded && isEmpty(betItems);
    const elementRef = useLoadingStatusMetric(
        isContentLoaded || noContent,
        reportLoadingStatus ? instanceId : undefined,
        'PrebuiltPromotionCards'
    );
    if (noContent) {
        return null;
    }

    return (
        <PrebuiltBets
            betItems={betItems}
            onPrepareCardsToRender={onPrepareCardsToRender}
            isContentLoaded={isContentLoaded}
            elementRef={elementRef}
        />
    );
});

export default PrebuiltPromotionCards;
