import MojitoPresentation from 'mojito/presentation';
import { useState } from 'react';
import { isBoolean } from 'mojito/utils';
import EventListMarketsSection from 'modules/event-list/event-list-markets-section.jsx';
import EventListUtils from 'modules/event-list/utils';
import EarlyPayoutOffers from 'modules/event-list/early-payout-offers/index.jsx';

const { Button, Text, FlexPane, RotatableImage, HideablePane } = MojitoPresentation.Components;
const isExpanded = (expanded, initiallyExpanded) =>
    isBoolean(expanded) ? expanded : initiallyExpanded;

export default function EventListGroup(props) {
    const {
        mojitoTools: { config, appContext },
        children,
        eventGroup,
        sportId,
        initiallyExpanded,
        onExpandChange,
        showEarlyPayoutOffers,
    } = props;
    const { emitAnalytics } = appContext.analyticsEmitter;
    const [expanded, setExpanded] = useState();

    const onHeaderClick = () => {
        setExpanded(!isExpanded(expanded, initiallyExpanded));
        onExpandChange(!expanded, eventGroup.id);
        emitAnalytics('competitionToggleExpandable', {
            competitionName: eventGroup.canonicalTypeName,
            sport: sportId,
        });
    };

    return (
        <FlexPane class="ta-EventListGroup" config={config.container}>
            {config.showOffersAboveHeader && showEarlyPayoutOffers && (
                <EarlyPayoutOffers config={config.earlyPayoutOffers} eventGroup={eventGroup} />
            )}
            <Header
                {...props}
                expanded={isExpanded(expanded, initiallyExpanded)}
                onClick={onHeaderClick}
            />
            <HideablePane
                hidden={!isExpanded(expanded, initiallyExpanded)}
                config={config.contentHideablePane}
            >
                {!config.showOffersAboveHeader && showEarlyPayoutOffers && (
                    <EarlyPayoutOffers config={config.earlyPayoutOffers} eventGroup={eventGroup} />
                )}
                {children}
            </HideablePane>
        </FlexPane>
    );
}

const Header = props => {
    const {
        onClick,
        eventGroup,
        headerTypes,
        expanded,
        sportId,
        isTimeSorting,
        mojitoTools: { config, stringResolver },
    } = props;
    const { showDuoLineHeader } = config;
    const { primaryText, secondaryText } = config.showHeaderLabels
        ? resolveHeaderLabels(showDuoLineHeader, eventGroup, stringResolver)
        : {};

    const hideHeader = !config.showHeaderLabels && !headerTypes.length;

    if (hideHeader) {
        return;
    }

    return (
        <Button
            class={'ta-GroupHeader'}
            onClick={onClick}
            config={config.headerButton}
            disabled={!config.expandable || isTimeSorting}
        >
            {!isTimeSorting && (primaryText || secondaryText) && (
                <FlexPane config={config.headerInfoContainer}>
                    {config.expandable && (
                        <RotatableImage
                            config={config.rotation}
                            angle={expanded ? '0deg' : '180deg'}
                        />
                    )}
                    <FlexPane
                        class={'ta-headerTypeAndClassContainer'}
                        config={config.headerTypeAndClassContainer}
                    >
                        {primaryText && (
                            <Text config={config.headerPrimaryLabel}>{primaryText}</Text>
                        )}
                        {secondaryText && (
                            <Text config={config.headerSecondaryLabel}>{secondaryText}</Text>
                        )}
                    </FlexPane>
                </FlexPane>
            )}
            {expanded && (
                <EventListMarketsSection config={config.headerSegmentsContainer} sportId={sportId}>
                    {headerTypes.map((headerSegment, index) => (
                        <HeaderSegment
                            /* eslint-disable-next-line react/no-array-index-key */
                            key={index} // NOSONAR - Index is used as a simple and static key for rendering
                            config={config}
                            headerTypes={headerSegment}
                            sportId={sportId}
                            stringResolver={stringResolver}
                        />
                    ))}
                </EventListMarketsSection>
            )}
        </Button>
    );
};

const HeaderSegment = ({ headerTypes = [], sportId, config, stringResolver }) => {
    return (
        <FlexPane config={config.headerSegment}>
            {headerTypes.map(headerType => (
                <Text
                    key={headerType}
                    class={`ta-ColumnItem ta-HeaderType-${headerType}`}
                    config={config.headerSegmentLabel}
                >
                    {resolveHeaderTypeString(headerType, sportId, stringResolver)}
                </Text>
            ))}
        </FlexPane>
    );
};

const resolveHeaderLabels = (showDuoLineHeader, eventGroup, stringResolver) => {
    const { className, typeName } = eventGroup;
    return showDuoLineHeader
        ? {
              primaryText: stringResolver.resolveAndFormatString(
                  '$EVENT_LIST_GROUP.TYPE_HEADER_FORMAT',
                  typeName
              ),
              secondaryText: stringResolver.resolveAndFormatString(
                  '$EVENT_LIST_GROUP.CLASS_HEADER_FORMAT',
                  className
              ),
          }
        : {
              primaryText: stringResolver.resolveAndFormatString(
                  '$EVENT_LIST_GROUP.HEADER_FORMAT',
                  className,
                  typeName
              ),
          };
};

const resolveHeaderTypeString = (headerType, sportId, stringResolver) => {
    // Each headerType might have overrides per sport so the valid keys could be e.g.
    // EVENT_LIST_GROUP.HEADER_TYPES.Over or EVENT_LIST_GROUP.HEADER_TYPES.Over.tennis or EVENT_LIST_GROUP.HEADER_TYPES.DEFAULT.
    const l10nBaseKey = `$EVENT_LIST_GROUP.HEADER_TYPES.${headerType}`;
    return (
        EventListUtils.resolveComplexTranslationString(l10nBaseKey, sportId, stringResolver) ||
        headerType
    );
};
