/*eslint no-undef: "off"*/
import MojitoCore from 'mojito/core';
import {settingsStorage} from 'mojito/core/services/system-settings/slice';

import {createTagWithAttributes, fetchInternalResource, isJsonContentType} from '#core/utils/utils.js';
import {assignRecursively} from '#core/utils/config-utils.js';

import {DebugFlags} from '#core/application/debug/debugFlags.js';
//Set en-GB locale for 'us' language as a workaround to prevent an issue with time like 24:30.
//This workaround could be removed after this bug is fixed in chrome:
//https://support.google.com/chrome/thread/29828561/chrome-80-shows-timestamp-24-xx-instead-of-00-00?hl=en
//https://github.com/moment/luxon/issues/726
const bcp47LanguageTagsMap = {
    en: 'en-GB',
    us: 'en-GB',
    'en-GB': 'en-GB',
    'en-US': 'en-GB',
};

function recursivelyMapPhrases(obj, fn) {
    for (const key in obj) {
        if (!Object.prototype.hasOwnProperty.call(obj, key)) continue;
        if (typeof obj[key] === 'string') {
            obj[key] = fn(key, obj[key]);
        } else if (typeof obj[key] === 'object') {
            recursivelyMapPhrases(obj[key], fn);
        }
    }
}

class LocalizationClass {
    init(forcedLanguage) {
        this.availableLanguages = OPERATIONAL_LANGUAGES;

        /*
        Hacking Mojito.

        We need `enableUrlLanguage` to be `true` to let `settingsStorage.getAppLanguage` to properly work.
        In particular, to parse the language flag from URL in `SystemSettingsStorage.getLanguageFromUrl()`.

        Mojito set `enableUrlLanguage` themselves, but they do it too late.
        We run `Localization.init()` before Mojito run `SystemSettingsStorage.configure`.
         */
        settingsStorage.enableUrlLanguage = true;

        let appLanguage = forcedLanguage || settingsStorage.getAppLanguage() || this.availableLanguages[0];
        // Current language is not obligated to be the same as appLanguage.
        // It can be different if we don't have translations for the appLanguage,
        // or backend format is different from specified in the appLanguage.

        // If host application wants language that we don't have translations for
        // it will look for the best candidate using the first two characters of the 4-letter language code.
        // For example, the host application wants en-US, while we have en-GB only, so it will fall back to en-GB.
        // When there is no match for the first two characters, it will fall back to the first available language.
        // Current language is 4-letter here for now.
        // Later it will be adjusted to format that is matching backend.
        this.currentLanguage = findMatchingFullLanguage(appLanguage, this.availableLanguages);

        this.translations = this.availableLanguages.reduce((res, lang) => {
            res[lang] = {};
            return res;
        }, {});

        const fileName = this.currentLanguage; // it is always 4-letter for now

        return fetchTranslationsFile(fileName).then(translations => {
            this.translations[this.currentLanguage] = translations;

            if (DebugFlags.wrapPhrases || DebugFlags.keysAsPhrases) {
                const modifyPhrase = DebugFlags.wrapPhrases ? (key, phrase) => `*${phrase}*` : key => key;

                for (const langKey in this.translations) {
                    if (Object.prototype.hasOwnProperty.call(this.translations, langKey)) {
                        const langDict = this.translations[langKey];
                        recursivelyMapPhrases(langDict, modifyPhrase);
                    }
                }
            }
        });
    }

    configure(contentLocales, translations) {
        contentLocales = contentLocales ? contentLocales.replaceAll('_', '-').replaceAll(' ', '').split(',') : [];
        this.updateTranslations(translations);
        if (contentLocales.length && contentLocales[0].length < this.currentLanguage.length) {
            // Backend language is 2-letter, but we have 4-letter language in the app.
            // So let's modify the current language and dictionary to match the backend language.
            this.availableLanguages = this.availableLanguages.map(lang => lang.split('-')[0]);

            // Transformation of this.translations will remove duplicates also, thus we may lose our current language.
            // To avoid this we will save it and restore after the transformation.
            const savedDictionary = this.translations[this.currentLanguage];

            this.translations = Object.fromEntries(
                Object.entries(this.translations).map(([lang, value]) => [lang.split('-')[0], value])
            );

            this.currentLanguage = this.currentLanguage.split('-')[0];
            this.translations[this.currentLanguage] = savedDictionary;
        }
    }

    updateTranslations(translations) {
        assignRecursively(this.translations, translations);
    }

    getMojitoConfig() {
        return {
            languages: this.translations,
        };
    }

    getAvailableLanguages() {
        return this.availableLanguages;
    }

    getCurrentLanguage() {
        return this.currentLanguage;
    }

    getCurrentLanguageCode() {
        return this.currentLanguage.split('-')[0];
    }

    getCurrentLocale() {
        return bcp47LanguageTagsMap[this.currentLanguage];
    }

    getTranslation(key) {
        return MojitoCore.Base.StringUtils.resolveString(key, this.translations[this.currentLanguage]);
    }

    // TODO if all clients will need this metaTag or Mojito will add this type of metatag, delete this solution
    setRobotsMetaTag(pathname) {
        if (window.location.pathname !== pathname) {
            const tag = createTagWithAttributes('meta', {
                name: 'robots',
                content: 'noindex, nofollow',
            });
            document.getElementsByTagName('head')[0].appendChild(tag);
        }
    }
}

function findMatchingFullLanguage(langToFind, languages) {
    if (langToFind.length === 2) {
        return languages.find(lang => lang.split('-')[0] === langToFind) || languages[0];
    } else {
        return (
            languages.find(lang => lang === langToFind) ||
            languages.find(lang => lang.split('-')[0] === langToFind.split('-')[0]) || // try to find the best candidate
            languages[0]
        );
    }
}

export async function fetchTranslationsFile(lang) {
    const response = await fetchInternalResource(`translations/${lang}?${GIT_HASH}`, {credentials: 'include'});
    const isJson = isJsonContentType(response);

    if (!response.ok || !isJson) {
        const message = `Translations file loading failed (${lang}).`;

        throw new Error(
            isJson
                ? `${message} Response status: ${response.status} ${response.statusText}`
                : `${message} Content-type is not 'application/json'`
        );
    }

    return await response.json();
}

export const Localization = new LocalizationClass();
