// Just global store of token descriptions
// No need to overweight with redux here

import {fetchInternalResource, isJsonContentType} from '#core/utils/utils.js';
import {assignRecursively} from '#core/utils/config-utils.js';

export const DescriptionsStore = {
    //
};

function parseResponse(response) {
    if (response.ok && isJsonContentType(response)) {
        return response.json();
    }

    return Promise.reject(
        `Failed to load token descriptions. Response status: ${response.status} ${response.statusText}`
    );
}

export function loadDescriptions() {
    return Promise.all([
        fetchInternalResource('mozaic/token-descriptions.json'),
        fetchInternalResource('mozaic/translation-descriptions.json'),
    ])
        .then(([tokensResponse, translationsResponse]) => {
            return Promise.all([parseResponse(tokensResponse), parseResponse(translationsResponse)]);
        })
        .then(([tokensData, translationsData]) => {
            assignRecursively(DescriptionsStore, tokensData, translationsData);
        });
}
