import { useMemo } from 'react';
import { omitBy } from 'mojito/utils';
import MojitoServices from 'mojito/services';
import MojitoPresentation from 'mojito/presentation';

const { types: BetsTypes } = MojitoServices.Bets;
const { BET_STATUS, FILTER_CRITERIA_TYPE } = BetsTypes;
const { Text, FlexPane, Dropdown } = MojitoPresentation.Components;

const Filters = props => {
    const {
        statusTitle,
        rangeTitle,
        onStatusFilterChange,
        onBetsRangeFilterChange,
        selectedRangeFilter,
        selectedStatusFilter,
        mojitoTools: { config, stringResolver },
    } = props;

    const statusOptions = useMemo(() => {
        const filter = config.excludedStatusTypes.length
            ? omitBy(BET_STATUS, value => config.excludedStatusTypes.includes(value))
            : BET_STATUS;

        return Object.keys(filter).map(key => ({
            value: filter[key],
            label: stringResolver.resolveString(`$FILTERS.TYPE_SELECTION.OPTIONS.${key}`),
        }));
    }, [config.excludedStatusTypes, stringResolver]);

    const rangeOptions = useMemo(() => {
        return config.rangeFilterOptions.map(({ options, type }) => {
            const { translationKey, value } = options;

            const label = stringResolver.resolveAndFormatString(
                translationKey,
                type === FILTER_CRITERIA_TYPE.LAST_BETS_AMOUNT ? value : undefined
            );

            return {
                label,
                value,
            };
        });
    }, [stringResolver, config.rangeFilterOptions]);

    const renderStatusTitle = () =>
        statusTitle && <Text config={config.filterStatusTitleContainer}>{statusTitle}</Text>;

    const renderRangeTitle = () =>
        rangeTitle && <Text config={config.filterRangeTitleContainer}>{rangeTitle}</Text>;

    return (
        <FlexPane config={config.filterContainer}>
            <FlexPane config={config.filterRangeRowContainer}>
                {renderRangeTitle()}
                <Dropdown
                    items={rangeOptions}
                    config={config.dropdown}
                    class="ta-rangeFilter"
                    triggerOverlayInteraction={false}
                    cbOnChange={onBetsRangeFilterChange}
                    selectedItemValue={selectedRangeFilter}
                />
            </FlexPane>
            <FlexPane config={config.filterStatusRowContainer}>
                {renderStatusTitle()}
                <Dropdown
                    class="ta-statusFilter"
                    config={config.dropdown}
                    items={statusOptions}
                    cbOnChange={onStatusFilterChange}
                    triggerOverlayInteraction={false}
                    selectedItemValue={selectedStatusFilter}
                />
            </FlexPane>
        </FlexPane>
    );
};

export default Filters;
