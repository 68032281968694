import { partial } from 'mojito/utils';
import MojitoNGen from 'mojito/ngen';

const log = MojitoNGen.logger.get('Analytics Service');

/**
 * Abstract analytics service to be implemented by analytics integrations.
 *
 * @class AbstractAnalyticsService
 * @abstract
 * @param {string} name - The concrete service name used for debugging purposes.
 * @memberof Mojito.Services.Analytics
 */
export default class AbstractAnalyticsService {
    constructor(name) {
        this.name = name;
        this.username = undefined;
        this.currency = undefined;
    }

    /**
     * Set the logged user name.
     *
     * @param {string} username - The user name.
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#setUsername
     */
    setUsername(username) {
        this.username = username;
    }

    /**
     * Set currency.
     *
     * @param {string} currency - The currency.
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#setCurrency
     */
    setCurrency(currency) {
        this.currency = currency;
    }

    /**
     * Activate telemetry.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#activate
     */
    activate(/* url */) {
        // To be implemented by subclasses
    }
}

export const EVENT_NAMES = [
    /**
     * User logged in.
     *
     * @param {string} userName - Name of user logged in.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#loggedIn
     */
    'loggedIn',

    /**
     * Login failed.
     *
     * @param {string} error - Error string.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#loginFailed
     */
    'loginFailed',

    /**
     * User logged out.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#loggedOut
     */
    'loggedOut',

    /**
     * Selection was added to betslip.
     *
     * @param {object} data - Object containing analytics data.
     * @param {string} data.selectionId - Selection id.
     * @param {string} data.selectionLabel - Selection button label.
     * @param {number} data.selectionPrice - Selection price.
     * @param {string} data.selectionSource - Source of selection, typically from where in the application the selection was added.
     * @param {string} data.selectionType - Selection type.
     * @param {string} data.eventName - Name of event to which the selection belongs.
     * @param {string} data.eventId - Id of event to which the selection belongs.
     * @param {string} data.marketId - Id of market.
     * @param {string} data.marketName - Name of market.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#selectionAddedToBetslip
     */
    'selectionAddedToBetslip',

    /**
     * Custom numeric keypad was shown.
     *
     * @param {boolean} isShown - True if shown and false if hidden.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#customKeypadShown
     */
    'customKeypadShown',

    /**
     * Key was clicked on custom numeric keypad.
     *
     * @param {string} keyCode - Type of key. Could be one of ['Enter', 'Increment'].
     * @param {string} keyName - Key name. Last char should be number(identifier of position in row).
     * @param {number} value - Numeric value of key.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#customKeypadKeyClicked
     */
    'customKeypadKeyClicked',

    /**
     * Selection was removed from betslip.
     *
     * @param {object} data - Object containing analytics data.
     * @param {string} data.selectionId - Selection id.
     * @param {number} data.selectionPrice - Selection price.
     * @param {string} data.selectionLabel - Selection label.
     * @param {string} data.selectionSource - Source of selection, typically from where in the application the selection was removed.
     * @param {Mojito.Services.Analytics.types.RACING_MARKET_TYPES} data.racingMarket - Racing market.
     * @param {string} data.marketName - Name of market.
     * @param {string} data.eventName - Name of event to which the selection belongs.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#selectionRemovedFromBetslip
     */
    'selectionRemovedFromBetslip',

    /**
     * An attempt to place a bet was made.
     *
     * @param {object} data - Object containing analytics data.
     * @param {number} data.totalStake - Total stake.
     * @param {number} data.betslipSelectionCount - Number of selections on betslip.
     * @param {number} data.userBalance - User balance when placing the bet.
     * @param {string} data.betslipType - Betslip type.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#placingBet
     */
    'placingBet',

    /**
     * Bet placement ended with status success.
     *
     * @param {object} data - Object containing analytics data.
     * @param {number} data.totalStake - Total stake.
     * @param {string} data.betType - Bet type.
     * @param {number} data.selectionCount - Number of selections in receipt.
     * @param {number} data.userBalance - User balance.
     * @param {number} data.odds - Odds for bet.
     * @param {number} data.freebetStake - Value of free bet if used with bet.
     * @param {string} data.betslipType - Betslip type.
     * @param {number} data.accaBoost - AccaBoost value.
     * @param {number} data.baseOdds - BaseOdds, indicating priceBoost.
     * @param {string} data.buildType - BuildType, set if bet is of build type MATCH_ACCA or PREBUILT_MATCH_ACCA.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#betPlaced
     */
    'betPlaced',

    /**
     * Betslip request failed.
     *
     * @param {string} betslipType - Betslip type.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#betslipRequestFailed
     */
    'betslipRequestFailed',

    /**
     * "Remove all" was clicked in betslip.
     *
     * @param {number} betslipSelectionCount - Number of selections on betslip.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#removeAllInBetslip
     */
    'removeAllInBetslip',

    /**
     * Betslip settings change accept all price changes.
     *
     * @param {string} isAcceptable - 'Yes' if all price changes are acceptable, 'No' otherwise.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#betslipChangeAcceptAllPrices
     */
    'betslipChangeAcceptAllPrices',

    /**
     * Betslip settings change accept higher price changes.
     *
     * @param {string} isAcceptable - 'Yes' if higher price changes are acceptable, 'No' otherwise.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#betslipChangeAcceptHigherPrices
     */
    'betslipChangeAcceptHigherPrices',

    /**
     * Betslip settings change accept lower price changes.
     *
     * @param {string} isAcceptable - 'Yes' if lower price changes are acceptable, 'No' otherwise.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#betslipChangeAcceptLowerPrices
     */
    'betslipChangeAcceptLowerPrices',

    /**
     * Betslip settings popup is opened.
     *
     * @param {boolean} isQuickBetslip - True if settings was opened in quick betslip.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#betslipSettingsOpened
     */
    'betslipSettingsOpened',

    /**
     * Selected betslip tab changed.
     *
     * @param {string} selectedTab - New selected tab.
     * @param {number} openBetsCount - Number of open bets.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#betslipTabChanged
     */
    'betslipTabChanged',

    /**
     * Open betslip.
     *
     * @param {string} betslipType - Betslip type.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#openBetslip
     */
    'openBetslip',

    /**
     * Close betslip.
     *
     * @param {string} betslipType - Betslip type.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#closeBetslip
     */
    'closeBetslip',

    /**
     * Betslip stake group changed (single, multi, system, teaser, bankers).
     *
     * @param {Mojito.Services.Betslip.types.STAKE_GROUP_NAME} stakeGroupName - Stake group name.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#betslipStakeGroupChanged
     */
    'betslipStakeGroupChanged',

    /**
     * Betslip teaser type changed.
     *
     * @param {Mojito.Services.Betslip.types.TEASER_TYPE} teaserType - Teaser type.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#betslipTeaserTypeChanged
     */
    'betslipTeaserTypeChanged',

    /**
     * Betslip changes are accepted.
     *
     * @param {string} betslipType - Betslip type.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#acceptChanges
     */
    'acceptChanges',

    /**
     * Toggle "Show stakes per single" button.
     *
     * @param {boolean} showStake - Show or hide stake input per single.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#toggleShowStakePerBetForSingles
     */
    'toggleShowStakePerBetForSingles',

    /**
     * The cash out process was initialized.
     *
     * @param {object} data - Object containing analytics data.
     * @param {string} data.betId - Bet id of bet cashed out.
     * @param {number} data.numberSelections - Number of selections in bet.
     * @param {string} data.betType - Bet type.
     * @param {string} data.betStatus - Status on bet.
     * @param {string} data.payoutValue - Payout amount.
     * @param {number} data.cashoutValue - Offered cash out value.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#initCashOut
     */
    'initCashOut',

    /**
     * Cash out was confirmed.
     *
     * @param {number} cashoutValue - Offered cash out value.
     * @param {string} betId - Id of bet cashed out.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#cashoutConfirmed
     */
    'cashoutConfirmed',

    /**
     * Cash out operation ended.
     *
     * @param {string} status - Cashout status.
     * @param {number} cashoutValue - Offered cash out value.
     * @param {string} betId - Id of bet cashed out.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#cashedOut
     */
    'cashedOut',

    /**
     * Navigation occurred.
     * Note: The landing page will be reported here on application launch.
     *
     * @param {string} url - New url.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#navigation
     */
    'navigation',

    /**
     * Language was changed.
     *
     * @param {string} oldLanguage - Old language.
     * @param {string} newLanguage - New language.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#languageChanged
     */
    'languageChanged',

    /**
     * Odds format was changed.
     *
     * @param {string} oldOddsFormat - Old odds format.
     * @param {string} newOddsFormat - New odds format.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#oddsFormatChanged
     */
    'oddsFormatChanged',

    /**
     * Event list market selector value changed.
     *
     * @param {object} data - Object containing analytics data.
     * @param {string} data.sportId - Sport id.
     * @param {string} data.marketId - Market id.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#eventListMarketSelectorValueChanged
     */
    'eventListMarketSelectorValueChanged',

    /**
     * Event list market selector value changed on home page only.
     *
     * @param {object} data - Object containing analytics data.
     * @param {string} data.listName - Sport list name.
     * @param {string} data.marketId - Market id.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#homePageEventListMarketSelectorValueChanged
     */
    'homePageEventListMarketSelectorValueChanged',

    /**
     * Event list selected period was changed by user.
     *
     * @param {object} data - Object containing analytics data.
     * @param {string} data.periodName - Selected period name.
     * @param {string} data.sportId - Sport id.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#eventListSelectedPeriodChanged
     */
    'eventListSelectedPeriodChanged',

    /**
     * Market group selector value changed.
     *
     * @param {object} data - Object containing analytics data.
     * @param {string} data.sportId - Sport id.
     * @param {string} data.marketGroupId - Market group id.
     * @param {string} data.marketGroupName - Market group name.
     * @param {string} data.eventName - Event name.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#marketGroupChanged
     */
    'marketGroupChanged',

    /**
     * Handicap market menu selector value changed.
     *
     * @param {string} id - Menu id.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#handicapMarketMenuChanged
     */
    'handicapMarketMenuChanged',

    /**
     * Market groups show/hide toggle clicked.
     *
     * @param {boolean} isSHown - Is market groups container shown.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#marketGroupsShowHideToggleClicked
     */
    'marketGroupsShowHideToggleClicked',

    /**
     * Market group category clicked.
     *
     * @param {string} marketGroupCategoryId - Market group category id.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#marketGroupCategoryClicked
     */
    'marketGroupCategoryClicked',

    /**
     * Promotion clicked.
     *
     * @param {object} data - Object containing analytics data.
     * @param {string} data.locationId - Location ID.
     * @param {string} data.bannerName - Banner name.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#promotionClicked
     */
    'promotionClicked',

    /**
     * Selection added to the match acca slip.
     *
     * @param {string} selectionSource - Source of selection, typically from where in the application the selection was added.
     * @param {string} selectionId - Selection id.
     * @param {string} selectionPrice - Odds with added selection.
     * @param {string} eventName - Name of event.
     * @param {string} selectionName - Name of selection.
     * @param {string} marketGroupName - Market group name.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#matchAccaAddSelection
     */
    'matchAccaAddSelection',

    /**
     * Selection removed from the match acca slip.
     *
     * @param {string} selectionId - Selection id.
     * @param {string} selectionPrice - Odds of selection.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#removeMatchAccaSelection
     */
    'removeMatchAccaSelection',

    /**
     * Cleared the entire match acca slip for the specified event.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#clearMatchAccaSlip
     */
    'clearMatchAccaSlip',

    /**
     * Match acca slip opened.
     *
     * @param {string} matchAccaSlipSelectionCount - Number of selections in the slip.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#matchAccaSlipOpen
     */
    'matchAccaSlipOpen',

    /**
     * Match acca slip closed.
     *
     * @param {object} data - Object containing analytics data.
     * @param {string} data.matchAccaSlipSelectionCount - Number of selections in the slip.
     * @param {string} data.cta - Method for closing the slip (call to action).
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#matchAccaSlipClose
     */
    'matchAccaSlipClose',

    /**
     * Match acca selections added to betslip.
     *
     * @param {string} selectionPrice - Selection price.
     * @param {number} numberOfSelections - Number of selection in the slip.
     * @param {string} balance - User balance when selection was added.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#addMatchAccaToBetslip
     */
    'addMatchAccaToBetslip',

    /**
     * QuickLink clicked.
     *
     * @param {string} label - Label of the quick link.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#quickLinkClicked
     */
    'quickLinkClicked',

    /**
     * Sports side menu opened or closed.
     *
     * @param {boolean} isOpened - Whether the Sports side menu is opened or closed.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#sportsSideMenuOpenStateChanged
     */
    'sportsSideMenuOpenStateChanged',

    /**
     * SportsMenu expanded state changed to either expanded or collapsed.
     *
     * @param {boolean} isExpanded - Whether the sports menu is expanded or collapsed.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#sportsMenuExpandedStateChanged
     */
    'sportsMenuExpandedStateChanged',

    /**
     * Sport clicked in the Sports Menu.
     *
     * @param {object} data - Object containing analytics data.
     * @param {string} data.sportType - Clicked sport type.
     * @param {string} data.applicationMode - Application Mode, "desktop" or "mobile".
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#sportsMenuSportClicked
     */
    'sportsMenuSportClicked',

    /**
     * Virtual stream started by user interaction for event id.
     *
     * @param {string} eventName - Name of the virtual event.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#virtualStreamingWatchButtonClicked
     */
    'virtualStreamingWatchButtonClicked',

    /**
     * Virtual selected event was changed.
     *
     * @param {string} eventName - Name of the virtual event.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#virtualSportsSelectedEventChanged
     */
    'virtualSportsSelectedEventChanged',

    /**
     * Virtual selected sport was changed.
     *
     * @param {string} sportName - Name of the virtual sport.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#virtualSportsSelectedSportChanged
     */
    'virtualSportsSelectedSportChanged',

    /**
     * Type filter clicked for a sport class.
     *
     * @param {object} data - Object containing analytics data.
     * @param {string} data.pageType - Page from where the click event originated.
     * @param {string} data.typeName - Name of selected filter.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#sportClassTypeClicked
     */
    'sportClassTypeClicked',

    /**
     * In play side menu coupon selected.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#inPlaySideMenuItemClicked
     */
    'inPlaySideMenuItemClicked',

    /**
     * Events quick navigation opened.
     *
     * @param {object} data - Object containing analytics data.
     * @param {string} data.eventName - Event name.
     * @param {string} data.eventType - Event type.
     * @param {string} data.sportId - Sport id.
     * @param {boolean} data.isInPlay - True is navigation is triggered across live events.
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#eventsQuickNavigationOpened
     */
    'eventsQuickNavigationOpened',

    /**
     * Events quick navigation closed.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#eventsQuickNavigationClosed
     */
    'eventsQuickNavigationClosed',

    /**
     * Leagues quick navigation opened.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#leaguesQuickNavigationOpened
     */
    'leaguesQuickNavigationOpened',

    /**
     * Leagues quick navigation closed.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#leaguesQuickNavigationClosed
     */
    'leaguesQuickNavigationClosed',

    /**
     * Top sport clicked.
     *
     * @param {object} data - Object containing analytics data.
     * @param {string} data.sportListName - Sport list name.
     * @param {string} data.sportType - Clicked sport type.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#topSportClicked
     */
    'topSportClicked',

    /**
     * Top sport show all clicked (inplay or highlight).
     *
     * @param {object} data - Object containing analytics data.
     * @param {string} data.sportType - Clicked sport type.
     * @param {string} data.isInplay - Flag that indicates whether or not the top sports are inplay or not.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#topSportShowAllClicked
     */
    'topSportShowAllClicked',

    /**
     * CMS link clicked.
     *
     * @param {string} linkName - Name of clicked link.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#cmsQuickLinksClicked
     */
    'cmsQuickLinksClicked',

    /**
     * Sports Carousel link clicked.
     *
     * @param {string} sportId - Clicked sport id.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#sportsCarouselLinkClicked
     */
    'sportsCarouselLinkClicked',

    /**
     * Video player full-screen state changed.
     *
     * @param {boolean} isFullScreen - Full-screen flag.
     * @param {string} eventName - Name of the betting event.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#streamFullScreenChanged
     */
    'streamFullScreenChanged',

    /**
     * User indicated the intent to start playing a video stream.
     *
     * @param {string} eventName - Name of the betting event.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#playStream
     */
    'playStream',

    /**
     * Video player stopped and closed its current stream.
     *
     * @param {string} eventName - Name of the betting event.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#closeStream
     */
    'closeStream',

    /**
     * Video player started playing a stream.
     *
     * @param {string} eventName - Name of the betting event.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#streamPlaying
     */
    'streamPlaying',

    /**
     * Video player emitted some error.
     *
     * @param {string} eventName - Name of the betting event.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#streamFailToPlay
     */
    'streamFailToPlay',

    /**
     * Video player was muted.
     *
     * @param {string} eventName - Name of the betting event.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#streamMute
     */
    'streamMute',

    /**
     * Video player was unmuted.
     *
     * @param {string} eventName - Name of the betting event.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#streamUnmute
     */
    'streamUnmute',

    /**
     * Video player sound volume was changed.
     *
     * @param {string} eventName - Name of the betting event.
     * @param {number} volume - Sound volume (0 - 100).
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#streamVolumeChanged
     */
    'streamVolumeChanged',

    /**
     * Racing main navigation link clicked.
     *
     * @param {string} linkName - Name of clicked link.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#racingMainNavigationClicked
     */
    'racingMainNavigationClicked',

    /**
     * Match sport main navigation link clicked.
     *
     * @param {string} linkName - Name of clicked link.
     * @param {string} sportId - Sport id.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#matchSportMainNavigationClicked
     */
    'matchSportMainNavigationClicked',

    /**
     * CMS coupon clicked.
     *
     * @param {string} linkName - Name of clicked coupon.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#cmsCouponsClicked
     */
    'cmsCouponsClicked',

    /**
     * Logo clicked.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#logoClicked
     */
    'logoClicked',

    /**
     * Login opened.
     *
     * @param {string} loginButtonLocation - Which login button was clicked.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#openLogin
     */
    'openLogin',

    /**
     * Back button clicked.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#backClicked
     */
    'backClicked',

    /**
     * Breadcrumbs clicked.
     *
     * @param {number} linkLevel - The depth of the clicked breadcrumb.
     * @param {string} linkName - The label of the clicked breadcrumb.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#breadcrumbsClicked
     */
    'breadcrumbsClicked',

    /** Your bets sub tab clicked.
     *
     * @param {string} tab - Name of clicked tab.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#yourBetsSubTabClicked
     */
    'yourBetsSubTabClicked',

    /**
     * Future race selected.
     *
     * @param {string} raceName - Selected race name.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#onSelectFutureRace
     */
    'selectRaceOnFutureCoupon',

    /**
     * Race card selected on next races.
     *
     * @param {string} raceName - Selected race name.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#changeRaceCard
     */
    'changeRaceCard',

    /**
     * Event card clicked.
     *
     * @param eventName - The event name.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#eventCardClicked
     */
    'eventCardClicked',

    /**
     * View full race details clicked.
     *
     * @param {string} raceName - Clicked race name.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#viewFullRace
     */
    'viewFullRace',

    /**
     * Race selected in top navigation on race card.
     *
     * @param {string} raceName - Clicked race name.
     * @param {string} status - Clicked race status.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#selectRace
     */
    'selectRace',

    /**
     * Meeting selected in dropdown on race card.
     *
     * @param {string} meetingName - Selected meeting name.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#selectMeeting
     */
    'selectMeeting',

    /**
     * Runners sort changed on race card.
     *
     * @param {string} sortType - Type of selected sorting.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#sortRunners
     */
    'sortRunners',

    /**
     * Location selected on highlight coupon (desktop only).
     *
     * @param {string} location - Location name.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#selectLocation
     */
    'selectLocation',

    /**
     * Race selected on highlight coupon.
     *
     * @param {string} raceName - Race name.
     * @param {string} status - Race status.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#selectRaceOnHighlightsCoupon
     */
    'selectRaceOnHighlightsCoupon',

    /**
     * Meeting selected on highlight coupon (desktop only).
     *
     * @param {string} meetingName - Meeting name.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#selectMeetingOnHighlightsCoupon
     */
    'selectMeetingOnHighlightsCoupon',

    /**
     * Time period filter changed on meetings coupon.
     *
     * @param {string} day - Selected filter value.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#changeMeetingsDay
     */
    'changeMeetingsDay',

    /**
     * Race selected on meetings coupon.
     *
     * @param {string} raceName - Race name.
     * @param {string} status - Race status.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#selectRaceOnMeetingsCoupon
     */
    'selectRaceOnMeetingsCoupon',

    /**
     * Meeting selected on meetings coupon (desktop only).
     *
     * @param {string} meetingName - Meeting name.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#selectMeetingOnMeetingsCoupon
     */
    'selectMeetingOnMeetingsCoupon',

    /**
     * Each Way toggled on/off in betslip.
     *
     * @param {boolean} isEachWay - Flag indicating if Each Way is toggled 'on' or 'off'.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#toggleEachWay
     */
    'toggleEachWay',

    /**
     * Bet history range filter change.
     *
     * @param {string} value - Range filter value.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#betHistoryRangeFilterChange
     */
    'betHistoryRangeFilterChange',

    /**
     * Bet history bet status change.
     *
     * @param {string} status - Selected bet status.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#betHistoryStatusChange
     */
    'betHistoryStatusChange',

    /**
     * Bet history on details click.
     *
     * @param {object} data - Object containing analytics data.
     * @param {string} data.action - Action of the click.
     * @param {string} data.betStatus - Status of selected bet.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#betHistoryOnDetailsClick
     */
    'betHistoryOnDetailsClick',

    /**
     * Bet history print.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#betHistoryPrint
     */
    'betHistoryPrint',

    /**
     * My account view change.
     *
     * @param {string} tab - The tab name.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#myAccountViewChange
     */
    'myAccountViewChange',

    /**
     * Success adding freebet voucher.
     *
     * @param {string} voucherCode - Code to identify voucher.
     * @param {string} voucherName - Voucher name.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#addFreebetVoucherSuccess
     */
    'addFreebetVoucherSuccess',

    /**
     * Fail adding freebet voucher.
     *
     * @param {object} data - Object containing analytics data.
     * @param {string} data.code - Free bet code.
     * @param {string} data.error - Cause of failure.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#addFreebetVoucherFailure
     */
    'addFreebetVoucherFailure',

    /**
     * Success removing freebet voucher.
     *
     * @param {string} voucherCode - Code to identify voucher.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#removeFreebetVoucherSuccess
     */
    'removeFreebetVoucherSuccess',

    /**
     * Change market in race card.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#changeMarketRaceCard
     */
    'changeMarketRaceCard',

    /**
     * Handles the event of changing tabs in the bonus section on a desktop device in the My Account modal.
     *
     * @param {string} tab - The name of the tab.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#bonusesTabChanged
     */
    'bonusesTabChanged',

    /**
     * Add sport to favourites.
     *
     * @param {string} sportId - The sport ID.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#addSportToFavourites
     */
    'addSportToFavourites',

    /**
     * Remove sport from favourites.
     *
     * @param {string} sportId - The sport ID.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#removeSportFromFavourites
     */
    'removeSportFromFavourites',

    /**
     * On money deposit by user.
     *
     * @param {number} amount - Deposited amount.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#onDeposit
     */
    'onDeposit',

    /**
     * On money withdraw by user.
     *
     * @param {number} amount - Withdrawn amount.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#onWithdraw
     */
    'onWithdraw',

    /**
     * On first time money deposit by user. Available for manual trigger but not implemented.
     *
     * @param {number} amount - Deposited amount.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#onFirstTimeDeposit
     */
    'onFirstTimeDeposit',

    /**
     * On user withdrawable (not including bonuses) balance update.
     * Does not trigger when on user login/logout.
     *
     * @param {number} balance - New user balance.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#onBalanceUpdate
     */
    'onBalanceUpdate',

    /**
     * When the cashier for user transactions is opened. Available for manual trigger but not implemented.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#onCashierOpen
     */
    'onCashierOpen',

    /**
     * Open user info in header.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#openUserInfo
     */
    'openUserInfo',

    /**
     * Close user info in header.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#closeUserInfo
     */
    'closeUserInfo',

    /**
     * Click link in user info panel.
     *
     * @param {string} linkLabel - Label of link.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#userInfoItemClicked
     */
    'userInfoItemClicked',

    /**
     * Navigation to specific event.
     *
     * @param {object} data - Object containing analytics data.
     * @param {string} data.name - Event name.
     * @param {string} data.type - Event type.
     * @param {string} data.sport - Event sport.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#navigationEvent
     */
    'navigationEvent',

    /**
     * Toggle competition header.
     *
     * @param {string} competitionName - Competition name.
     * @param {string} sport - Sport type.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#competitionToggleExpandable
     */
    'competitionToggleExpandable',

    /**
     * Toggle market header.
     *
     * @param {string} marketName - Market name.
     * @param {string} eventName - Event name.
     * @param {string} competitionName - Competition name.
     * @param {string} sport - Sport type.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#marketToggleExpandable
     */
    'marketToggleExpandable',

    /**
     * New Time Zone Settings.
     *
     * @param {string} NewTimeZoneValue - New time zone value.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#settingsTimeZone
     */
    'settingsTimeZone',

    /**
     * Retain selection button pressed.
     *
     * @param {number} numberSelections - Number of selections in receipt.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#retainSelection
     */
    'retainSelection',

    /**
     * Clear receipt button clicked.
     *
     * @param {number} numberSelections - Number of selections in receipt.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#clearSelection
     */
    'clearSelection',

    /**
     * When Bet Receipt is open.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#betReceiptShown
     */
    'betReceiptShown',

    /**
     * Search input focused.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#searchInputFocused
     */
    'searchInputFocused',

    /**
     * Perform search.
     *
     * @param {string} searchString - Search string to be tracked.
     * @param {number} numberOfResults - Number of search result items.
     * @param {boolean} hasMoreResults - Flag that there are more results. Needed since pagination is supported.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#searchPerformed
     */
    'searchPerformed',

    /**
     * Search results item clicked.
     *
     * @param {object} data - Object containing search result item data.
     * @param {string} data.sportId - Search result sport category.
     * @param {string} data.name - Search result item name.
     * @param {string} data.parentName - Search result item parentName.
     * @param {Mojito.Services.Search.types.SearchTypes} data.resultType - Search result type.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#searchResultsItemClicked
     */
    'searchResultsItemClicked',

    /**
     * Search input has been cleared.
     *
     * @param {string} searchString - The search term to be cleared.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#searchInputCleared
     */
    'searchInputCleared',

    /**
     * Search history item has been removed.
     *
     * @param {string} searchString - Search term removed from history.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#searchHistoryItemRemoved
     */
    'searchHistoryItemRemoved',

    /**
     * Search history item clicked.
     *
     * @param {string} searchString - Search term clicked.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#searchHistoryItemClicked
     */
    'searchHistoryItemClicked',

    /**
     * Quick Betslip stake box interaction.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#quickBetslipStakeBoxInteraction
     */
    'quickBetslipStakeBoxInteraction',

    /**
     * Quick Betslip expanded to full betslip.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#quickBetslipToFull
     */
    'quickBetslipToFull',

    /**
     * Event cards carousel swiped.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#eventCardsCarouselSwiped
     */
    'eventCardsCarouselSwiped',

    /**
     * Bankers toggled on/off.
     *
     * @param {boolean} active - True if bankers is toggled on, false if toggled off.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#bankersSwitchToggled
     */
    'bankersSwitchToggled',

    /**
     * Bet set as bankers on/off.
     *
     * @param {string} betId - Id of bet.
     * @param {boolean} active - True if bet is toggled on, false if toggled off.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#bankersBetToggled
     */
    'bankersBetToggled',

    /**
     * Click on event notification subscriber. Typically presented as bell icon.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#notificationsSubscriberClick
     */
    'notificationsSubscriberClick',

    /**
     * Subscribe/unsubscribe to content notifications on event details page.
     *
     * @param {string} source - Source where notifications toggle originates from.
     * @param {boolean} value - True if subscribed, false if unsubscribed.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#toggleNotifications
     */
    'toggleNotifications',

    /**
     * Click on a top league.
     *
     * @param {string} typeId - League type id.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#topLeagueClick
     */
    'topLeagueClick',

    /**
     * Click on a match filter.
     *
     * @param {string} selectedFilterKey - Key of selected filter.
     * @param {string} sport - Sport id the filter is clicked from.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#matchesFilterClicked
     */
    'matchesFilterClicked',

    /**
     * Opened Quick Start Guide from banner.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#quickStartGuideOpenedFromBanner
     */
    'quickStartGuideOpenedFromBanner',

    /**
     * Closed Quick Start Guide from banner close button.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#quickStartGuideBannerClosed
     */
    'quickStartGuideBannerClosed',

    /**
     * User session was lost.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#unexpectedSessionLost
     */
    'unexpectedSessionLost',

    /**
     * Change price type in betslip odds dropdown.
     *
     * @param {string} sportId - Sport id.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#betslipPriceTypeDropdownChange
     */
    'betslipPriceTypeDropdownChange',

    /**
     * Click on time sorting.
     *
     * @param {string} sportId - Sport id.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#timeSortingClicked
     */
    'timeSortingClicked',

    /**
     * Click on type sorting.
     *
     * @param {string} sportId - Sport id.
     *
     * @function Mojito.Services.Analytics.AbstractAnalyticsService#typeSortingClicked
     */
    'typeSortingClicked',
];

function defaultAnalyticsHandler(eventName) {
    log.debug(`The "${eventName}" event is not handled by ${this.name}`);
}

for (const eventName of EVENT_NAMES) {
    AbstractAnalyticsService.prototype[eventName] = partial(defaultAnalyticsHandler, eventName);
}

AbstractAnalyticsService.BETSLIP = {
    TAB: {
        BETSLIP: 'betslip',
        OPEN_BETS: 'your-bets',
    },
    RETAINED_SELECTIONS: {
        YES: 'yes',
        NO: 'no',
    },
};

AbstractAnalyticsService.CASHOUT = {
    STATUS: {
        OK: 'ok',
        FAILED: 'failed',
    },
};
