import {resolveBackground} from '#core/utils/color-utils.js';
import {icon} from '#core/utils/style-utils.js';

import {registerTileSchema} from '#core/application/schema-registry.js';

import PIVOT from './pivot.palette-schema.yaml';

registerTileSchema(PIVOT);

export function generatePivotButton(TILE) {
    const {FONT} = TILE;

    const BUTTON_BASE = {
        padding: '0 8px',
        height: 40, // important to have height here since height 100% doesn't work on some elements (such as betslip tabs)
        alignItems: 'center',
        _mobile_flexDirection: 'row',
        fontSize: FONT.SIZE_MEDIUM,
        fontWeight: FONT.WEIGHT_NORMAL,
    };

    return {
        selected: {
            style: {
                base: {
                    ...BUTTON_BASE,
                    ...TILE.PIVOT_SELECTED_TEXT,
                },
            },
        },
        unselected: {
            style: {
                base: {
                    ...BUTTON_BASE,
                    ...TILE.PIVOT_TEXT,
                },
            },
        },
        indicator: {
            style: {
                base: {
                    ...resolveBackground(TILE.PIVOT_INDICATOR_BG),
                    height: TILE.PIVOT_INDICATOR_HEIGHT,
                    bottom: TILE.PIVOT_INDICATOR_BOTTOM,
                    left: TILE.PIVOT_INDICATOR_LEFT,
                    right: TILE.PIVOT_INDICATOR_RIGHT,
                    width: 'initial',
                },
            },
        },
    };
}

export function generatePivotsRow(TILE) {
    const SCROLL_BUTTON_STYLE = {
        ...resolveBackground(TILE.PIVOT_SCROLL_BUTTON_BG),
        width: 32,
        height: 32,
        alignSelf: 'center',
        borderRadius:
            TILE.PIVOT_SCROLL_BUTTON_RADIUS < 0
                ? `${-TILE.PIVOT_SCROLL_BUTTON_RADIUS}%`
                : TILE.PIVOT_SCROLL_BUTTON_RADIUS,
    };
    const SCROLL_BUTTON_ICON_STYLE = icon(TILE.PIVOT_SCROLL_BUTTON_ICON_COLOR);

    return {
        style: {
            container: {
                ...resolveBackground(TILE.PIVOT_ROW_BG),
                height: 40,
                padding: 0, // override mojito default
            },
        },
        item: generatePivotButton(TILE),
        itemIcon: icon(TILE.PIVOT_ICON_COLOR),
        selectedItemIcon: icon(TILE.PIVOT_SELECTED_ICON_COLOR),
        buttonScrollPane: {
            rightScrollButton: {
                style: {
                    base: SCROLL_BUTTON_STYLE,
                },
            },
            leftScrollButton: {
                style: {
                    base: SCROLL_BUTTON_STYLE,
                },
            },
            rightScrollButtonIcon: SCROLL_BUTTON_ICON_STYLE,
            leftScrollButtonIcon: SCROLL_BUTTON_ICON_STYLE,
        },
    };
}
