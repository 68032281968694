import ApplicationHeaderSkeletonImpl from './application-header-skeleton.jsx';
import MojitoCore from 'mojito/core';

const UIView = MojitoCore.Presentation.UIView;

/**
 * Shows header view skeleton while application header is loading.
 *
 * @function ApplicationHeaderSkeleton
 * @memberof Mojito.Applications.Sports
 */

export default UIView('ApplicationHeaderSkeleton', ApplicationHeaderSkeletonImpl);
