import {default as AbstractAnalyticsService} from 'mojito-client/services/analytics/service/abstractanalyticsservice';

import {ensureHierarchy} from '#core/utils/config-utils.js';

import {AbstractFeature, allFeatures} from '#core/application/abstract-feature.js';

export const EVENTS = {
    A_Z_MORE: 'A-Z Sport Menu More Interaction',
    A_Z_SPORT: 'A-Z Sport Menu Sport Interaction',
    A_Z_OPENED: 'A-Z Menu Opened',
    A_Z_CLOSED: 'A-Z Menu Closed',
    BACK_BUTTON_INTERACTION: 'Back Button Interaction',
    BANKERS_SWITCH_TOGGLED: 'Bankers Switch Toggled',
    BANKERS_BET_TOGGLED: 'Bankers Bet Toggled',
    BANNER_INTERACTION: 'Banner Interaction',
    BET_HISTORY_BET_DETAIL_INTERACTION: 'Bet History - Bet Detail Interaction',
    BET_HISTORY_BET_TIME_PERIOD_INTERACTION: 'Bet History Bet Time Period Interaction',
    BET_HISTORY_BET_FILTER_INTERACTION: 'Bet History Bet Filter Interaction',
    BET_HISTORY_PRINT: 'Bet History Print',
    BET_RECEIPT_RETAIN_SELECTIONS: 'Bet Receipt Retain Selections',
    BET_RECEIPT_CLEAR_SELECTIONS: 'Bet Receipt Clear Selections',
    BET_RECEIPT_SHOWN: 'Bet Receipt Is Shown',
    BETSLIP_AUTOMATIC_PRICE_CHANGE_ALL: 'Bet Slip Automatic Price Change All Interaction',
    BETSLIP_AUTOMATIC_PRICE_CHANGE_HIGHER: 'Bet Slip Automatic Price Change Higher Interaction',
    BETSLIP_AUTOMATIC_PRICE_CHANGE_LOWER: 'Bet Slip Automatic Price Change Lower Interaction',
    BETSLIP_CLOSE: 'Bet Slip Close',
    BETSLIP_EACH_WAY: 'Bet Slip Each Way Interaction',
    BETSLIP_FREEBET_ADD: 'Bet Slip Free Bet Add',
    BETSLIP_FREEBET_REMOVE: 'Bet Slip Free Bet Remove',
    BETSLIP_KEYPAD_SHOWN: 'Bet Slip Custom Keypad Shown',
    BETSLIP_KEYPAD_HIDDEN: 'Bet Slip Custom Keypad Hidden',
    BETSLIP_KEYPAD_INCREMENT: 'Bet Slip Custom Keypad Value Increment',
    BETSLIP_OPEN: 'Bet Slip Open',
    BETSLIP_REMOVE_ALL: 'Bet Slip Remove All Selections',
    BETSLIP_REQUEST_FAILED: 'Bet Slip Request Failed',
    BETSLIP_SETTINGS: 'Bet Slip Settings Interaction',
    BETSLIP_STAKE_PER_BET: 'Bet Slip Stake Per Bet Interaction',
    BETSLIP_TAB_CHANGE: 'Change bet type',
    TEASER_TYPE_CHANGE: 'Change teaser value',
    BONUSES_TAB_INTERACTION: 'Bonuses Tab Interaction',
    BREADCRUMB_INTERACTION: 'Breadcrumb Interaction',
    CARD_SWIPE: 'Card Swipe',
    CASH_OUT_STATUS: 'Cash Out Status',
    CASH_OUT_CONFIRM: 'Cash Out Confirm',
    CASH_OUT_INTERACTION: 'Cash Out Interaction',
    COMPETITION_COLLAPSE_OR_EXPAND_INTERACTION: 'Competition - Collapse/Expand Interaction',
    COUPON_INTERACTION: 'Coupon Interaction',
    EVENT_INTERACTION: 'Event Element Interaction',
    FAVOURITES_SPORT_ADDED: 'Sport Added To Favourites',
    FAVOURITES_SPORT_REMOVED: 'Sport Removed From Favourites',
    FOOTER_MOBILE_INTERACTION: 'Footer Interaction - Mobile Navigation',
    FUTURE_RACING_COUPON_SELECTED: 'Future Races Coupon Selected',
    HEADER_INTERACTION: 'Header Interaction',
    HIGHLIGHT_MEETINGS_SELECT_MEETING: 'Highlight Meetings Select Meeting',
    HIGHLIGHT_MEETINGS_SELECT_RACE: 'Highlight Meetings Select Race',
    HIGHLIGHT_MEETINGS_SELECT_REGION: 'Highlight Meetings Select Region',
    HIGHLIGHT_MEETINGS: '',
    IN_PLAY_FILTER_INTERACTION: 'In Play Filter Interaction',
    EVENTS_NAVIGATION_MENU_OPENED: 'Open Events Menu Navigation',
    EVENTS_NAVIGATION_MENU_CLOSED: 'Close Events Menu Navigation',
    LEAGUES_NAVIGATION_MENU_OPENED: 'Open Leagues Menu Navigation',
    LEAGUES_NAVIGATION_MENU_CLOSED: 'Close Leagues Menu Navigation',
    IN_PLAY_SIDE_MENU_ITEM_CLICK: 'In Play Side Menu Item Clicked',
    LEAGUE_FILTER_INTERACTION: 'League Filter Interaction',
    LOGIN: 'Login',
    LOGIN_OPEN: 'Login Open',
    LOGO: 'Logo Clicked',
    LOGOUT: 'Logout',
    MARKET_CATEGORIES_INTERACTION: 'Market Categories Interaction',
    HANDICAP_MARKET_TYPE_MENU_INTERACTION: 'Handicap market type menu interaction',
    MARKET_COLLAPSE_OR_EXPAND_INTERACTION: 'Market Collaps/Expand Interaction',
    MARKET_FILTER_INTERACTION: 'Market Filter Interaction',
    MARKET_GROUP_CATEGORY_CLICKED: 'Market Group Category Clicked',
    MARKET_GROUPS_SHOW_HIDE_TOGGLE_CLICKED: 'Market Groups Show/Hide Toggle Clicked',
    MATCH_ACCA_ADD_SELECTION: 'Match Acca Add Selection',
    MATCH_ACCA_REMOVE_SELECTION: 'Match Acca Remove Selection',
    MATCH_ACCA_ADD_TO_BETSLIP: 'Match Acca Add To Betslip',
    MATCH_ACCA_OPENED: 'Match Acca Opened',
    MATCH_ACCA_CLOSED: 'Match Acca Closed',
    MATCH_ACCA_CLEARED: 'Match Acca Cleared',
    MY_ACCOUNT_TAB_CLICKED: 'My Account Tab Changed',
    EVENT_CARD_CLICKED: 'Event Card Clicked',
    NEXT_RACES_RACE_CARD_CHANGE: 'Next Races Race Card Change',
    NEXT_RACES_RACE_CARD_CLICK: 'Next Races Race Card Navigation',
    NOTIFICATION_CLICKED: 'Notification clicked',
    OTHER_FILTER_INTERACTION: 'Other Filter Interaction (US Sport)',
    PLACE_BET: 'Place Bet',
    BETSLIP_CHANGES_ACCEPTED: 'Betslip Changes Accepted',
    PUSH_NOTIFICATIONS_SWITCH: 'Push notifications switch',
    QBS_STAKE_BOX: 'QBS Stake Box Interaction',
    QBS_INCREMENT: 'QBS Custom Keypad Increment Value',
    QBS_OPEN_FULL: 'QBS Open Full Betslip',
    QBS_SETTINGS: 'QBS Settings Interaction',
    QBS_REMOVE_SELECTION: 'QBS Remove Selection',
    QUICK_LINK_INTERACTION: 'Quick Link Interaction',
    RACE_CARD_SORT_RUNNERS: 'Race Card Sort Runners',
    RACE_CARD_SELECT_RACE: 'Race Card Select Race',
    RACE_CARD_SELECT_MEETING: 'Race Card Select Meeting',
    RACE_CARD_MARKET_CHANGED: 'Race Card Market Changed',
    RACE_MEETINGS_DAY_CHANGED: 'Race Meeting Day Changed',
    RACE_MEETINGS_SELECT_MEETING: 'Race Meeting Selected',
    RACE_MEETINGS_SELECT_RACE: 'Race Meeting Race Selected',
    RACING_TABS_NAVIGATION: 'Racing Tabs Interaction',
    SELECTION_ADD: 'Add Selection',
    SELECTION_REMOVE: 'Remove Selection',
    SETTINGS_LANGUAGE: 'Settings Language',
    SETTINGS_ODDS_FORMAT: 'Settings Odds Format',
    SETTINGS_TIME_ZONE: 'Settings Time Zone',
    SEARCH_CLEARED: 'Clear Search',
    SEARCH_INPUT_FOCUSED: 'Open Search',
    SEARCH_TERM_DELETE: 'Delete Search Term',
    SEARCH_TERM_SELECTED: 'Recent Search Term Interaction',
    SEARCH_PERFORMED: 'Search Performed',
    SEARCH_RESULT_SELECTED: 'Search Result Selected',
    SPORTS_CAROUSEL_INTERACTION: 'Sports Carousel Interaction',
    SPORT_FILTER_INTERACTION: 'Sport Filter Interaction',
    SPORT_TABS_INTERACTION: 'Sport Tabs Interaction',
    STREAM_CLOSED: 'Stream Closed',
    STREAM_FULL_SCREEN: 'Stream Full-screen Interaction',
    STREAM_MUTE: 'Stream Muted',
    STREAM_VOLUME_CHANGED: 'Stream Volume Changed',
    STREAM_PLAY: 'Stream Play',
    STREAM_STARTED: 'Stream Started Playing',
    STREAM_UNMUTED: 'Stream Unmuted',
    TOP_SPORT_SHOW_ALL: 'Show All Top Sports Clicked',
    USER_INFO_OPEN: 'User Info Opened',
    USER_INFO_CLOSED: 'User Info Closed',
    USER_INFO_INTERACTION: 'User Info Item Interaction',
    UNEXPECTED_USER_SESSION_LOST: 'Unexpected user session lost',
    VIRTUAL_STREAM_WATCH: 'Virtual Stream Open/Play',
    VIRTUAL_EVENT_SELECTED: 'Virtual Event Selected',
    VIRTUAL_SPORT_SELECTED: 'Virtual Sport Selected',
    YOUR_BETS_TAB_INTERACTION: 'Your Bets Tab Interaction',
    YOUR_BETS_OPEN: 'Your Bets Open',
    TOP_LEAGUE_INTERACTION: 'Top League Interaction',
    MATCHES_FILTER_INTERACTION: 'Matches Filter Interaction',
    QUICK_START_GUIDE_OPENED_FROM_BANNER: 'Quick Start Guide opened from banner',
    QUICK_START_GUIDE_BANNER_CLOSED: 'Quick Start Guide banner closed',
    BETSLIP_PRICE_TYPE_DROPDOWN_CHANGE: 'Betslip price type dropdown change',
    TIME_SORTING_CLICKED: 'Sorting Events By Time Clicked',
    TYPE_SORTING_CLICKED: 'Sorting Events By Type Clicked',
};

export const STATUS = {
    FAILED: 'Failed',
    SUCCESSFUL: 'Successful',
    INITIATED: 'Initiated',
};

// This service always added to analytics services list
export class AnalyticsProxyServiceClass extends AbstractAnalyticsService {
    constructor(config) {
        super(config);
        this.handlers = [];
    }

    addHandler(handlerToAdd) {
        if (this.handlers.find(handler => handler === handlerToAdd)) return;
        this.handlers.push(handlerToAdd);
    }

    removeHandler(handlerToRemove) {
        this.handlers = this.handlers.filter(handler => {
            return handler !== handlerToRemove;
        });
    }

    setPage(page) {
        this.page = page;
    }

    logEvent(name, data, raw) {
        if (!this.page) {
            this.page = window.location.pathname;
        }
        for (let handler of this.handlers) {
            try {
                handler({
                    eventName: name,
                    userName: this.username,
                    currency: this.currency,
                    page: this.page,
                    data: data,
                    raw: raw || data,
                });
            } catch (e) {
                AnalyticsProxy.logger.error(e);
            }
        }
        return data;
    }

    // Below, DBX are trying to keep the same structure as Mojito's GoogleAnalytics4Service has

    /**
     * Login opened.
     *
     * @param {string} loginButtonLocation - Which login button was clicked.
     *
     * @fires LOGIN_OPEN
     */
    openLogin(loginButtonLocation) {
        /**
         * @event LOGIN_OPEN
         * @type {object}
         * @property {string} location - Which login button was clicked.
         */
        this.logEvent(
            EVENTS.LOGIN_OPEN,
            {
                location: loginButtonLocation,
            },
            ...arguments
        );
    }

    /**
     * User logged in.
     *
     * @param {string} userName - Name of user logged in.
     *
     * @namespace LOGIN
     * @fires LOGIN~SUCCESSFUL
     */
    loggedIn(userName) {
        this.setUsername(userName);
        /**
         * @event LOGIN~SUCCESSFUL
         * @type {object}
         * @property {string} username - Name of user logged in.
         */
        this.logEvent(
            `${EVENTS.LOGIN} ${STATUS.SUCCESSFUL}`,
            {
                username: userName,
            },
            ...arguments
        );
    }

    /**
     * Login failed.
     *
     * @param {string} error - Error string.
     *
     * @namespace LOGIN
     * @fires LOGIN~FAILED
     */
    loginFailed(error) {
        /**
         * @event LOGIN~FAILED
         * @type {object}
         * @property {string} error_message - Error string.
         */
        this.logEvent(
            `${EVENTS.LOGIN} ${STATUS.FAILED}`,
            {
                error_message: error,
            },
            ...arguments
        );
    }

    /**
     * User logged out.
     *
     * @fires LOGOUT
     */
    loggedOut() {
        this.setUsername(undefined);
        /**
         * @event LOGOUT
         */
        this.logEvent(EVENTS.LOGOUT, ...arguments);
    }

    /**
     * Open user info in header.
     *
     * @fires USER_INFO_OPEN
     */
    openUserInfo() {
        /**
         * @event USER_INFO_OPEN
         */
        this.logEvent(EVENTS.USER_INFO_OPEN, ...arguments);
    }

    /**
     * Close user info in header.
     *
     * @fires USER_INFO_CLOSED
     */
    closeUserInfo() {
        /**
         * @event USER_INFO_CLOSED
         */
        this.logEvent(EVENTS.USER_INFO_CLOSED, ...arguments);
    }

    /**
     * Click link in user info panel.
     *
     * @param {object} data - Object containing analytics data.
     * @param {string} data.itemLabel - Label of link.
     *
     * @fires USER_INFO_INTERACTION
     */
    userInfoItemClicked({itemLabel}) {
        /**
         * @event USER_INFO_INTERACTION
         * @type {object}
         * @property {string} selected_element - Label of link.
         */
        this.logEvent(
            EVENTS.USER_INFO_INTERACTION,
            {
                selected_element: itemLabel,
            },
            ...arguments
        );
    }

    /**
     * Top sport clicked.
     *
     * @param {object} data - Object containing analytics data.
     * @param {string} data.sportListName - Sport list name.
     * @param {string} data.sportType - Clicked sport type.
     *
     * @fires SPORT_FILTER_INTERACTION
     */
    topSportClicked({sportListName, sportType}) {
        /**
         * @event SPORT_FILTER_INTERACTION
         * @type {object}
         * @property {string} sport - Clicked sport type.
         * @property {string} location_id - Sport list name.
         */
        this.logEvent(
            EVENTS.SPORT_FILTER_INTERACTION,
            {
                sport: sportType,
                location_id: sportListName,
            },
            ...arguments
        );
    }

    /**
     * Top sport show all clicked (inplay or highlight).
     *
     * @param {object} data - Object containing analytics data.
     * @param {string} data.sportType - Clicked sport type.
     * @param {boolean} data.isInplay - Flag that indicates whether or not the top sports are inplay or not.
     *
     * @fires TOP_SPORT_SHOW_ALL
     */
    topSportShowAllClicked({sportType, isInplay}) {
        /**
         * @event TOP_SPORT_SHOW_ALL
         * @type {object}
         * @property {string} sport - Clicked sport type.
         * @property {boolean} in_play - Flag that indicates whether the top sports are inplay or not.
         */
        this.logEvent(
            EVENTS.TOP_SPORT_SHOW_ALL,
            {
                sport: sportType,
                in_play: isInplay,
            },
            ...arguments
        );
    }

    /**
     * @ignore
     */
    eventsMarketFilterInteraction({sportId, marketId}) {
        /**
         * @event MARKET_FILTER_INTERACTION
         * @type {object}
         * @property {string} sport - Sport id.
         * @property {string} market_name - Market id.
         */
        this.logEvent(
            EVENTS.MARKET_FILTER_INTERACTION,
            {
                sport: sportId,
                market_name: marketId,
            },
            ...arguments
        );
    }

    /**
     * Event list market selector value changed.
     *
     * @param {object} data - Object containing analytics data.
     * @param {string} data.sportId - Sport id.
     * @param {string} data.marketId - Market id.
     *
     * @fires MARKET_FILTER_INTERACTION
     */
    eventListMarketSelectorValueChanged({sportId, marketId}) {
        this.eventsMarketFilterInteraction({sportId, marketId});
    }

    /**
     * Event list market selector value changed on home page only.
     *
     * @param {object} data - Object containing analytics data.
     * @param {string} data.sportId - Sport id.
     * @param {string} data.marketId - Market id.
     *
     * @fires MARKET_FILTER_INTERACTION
     */
    homePageEventListMarketSelectorValueChanged({sportId, marketId}) {
        this.eventsMarketFilterInteraction({sportId, marketId});
    }

    /**
     * Market groups show/hide toggle clicked.
     *
     * @param {boolean} isShown - Is market groups container shown.
     *
     * @fires MARKET_GROUPS_SHOW_HIDE_TOGGLE_CLICKED
     */
    marketGroupsShowHideToggleClicked(isShown) {
        /**
         * @event MARKET_GROUPS_SHOW_HIDE_TOGGLE_CLICKED
         * @type {object}
         * @property {boolean} show - Is market groups container shown.
         */
        this.logEvent(
            EVENTS.MARKET_GROUPS_SHOW_HIDE_TOGGLE_CLICKED,
            {
                show: isShown,
            },
            ...arguments
        );
    }

    /**
     * Market group category clicked.
     *
     * @param {string} marketGroupCategoryId - Market group category id.
     *
     * @fires MARKET_GROUP_CATEGORY_CLICKED
     */
    marketGroupCategoryClicked(marketGroupCategoryId) {
        /**
         * @event MARKET_GROUP_CATEGORY_CLICKED
         * @type {object}
         * @property {string} categoryId - Market group category id.
         */
        this.logEvent(
            EVENTS.MARKET_GROUP_CATEGORY_CLICKED,
            {
                categoryId: marketGroupCategoryId,
            },
            ...arguments
        );
    }

    /**
     * Event list selected period was changed by user.
     *
     * @param {object} data - Object containing analytics data.
     * @param {string} data.periodName - Selected period name.
     * @param {string} data.sportId - Sport id.
     *
     * @fires OTHER_FILTER_INTERACTION
     */
    eventListSelectedPeriodChanged({periodName, sportId}) {
        /**
         * @event OTHER_FILTER_INTERACTION
         * @type {object}
         * @property {string} sport - Sport id.
         * @property {string} market_name - Selected period name.
         */
        this.logEvent(
            EVENTS.OTHER_FILTER_INTERACTION,
            {
                sport: sportId,
                market_name: periodName,
            },
            ...arguments
        );
    }

    /**
     * Sports side menu opened or closed.
     *
     * @param {boolean} isOpened - Whether the Sports side menu is opened or closed.
     *
     * @fires A_Z_OPENED
     * @fires A_Z_CLOSED
     */
    sportsSideMenuOpenStateChanged(isOpened) {
        /**
         * @event A_Z_OPENED
         */
        /**
         * @event A_Z_CLOSED
         */
        this.logEvent(isOpened ? EVENTS.A_Z_OPENED : EVENTS.A_Z_CLOSED, ...arguments);
    }

    /**
     * Sport clicked in the Sports Menu.
     *
     * @param {object} data - Object containing analytics data.
     * @param {string} data.sportType - Clicked sport type.
     *
     * @fires A_Z_SPORT
     */
    sportsMenuSportClicked({sportType}) {
        /**
         * @event A_Z_SPORT
         * @type {object}
         * @property {string} sport - Clicked sport type.
         */
        this.logEvent(EVENTS.A_Z_SPORT, {
            sport: sportType,
        });
    }

    /**
     * SportsMenu expanded state changed to either expanded or collapsed.
     *
     * @param {boolean} isExpanded - Whether the sports menu is expanded or collapsed.
     *
     * @fires A_Z_MORE
     */
    sportsMenuExpandedStateChanged(isExpanded) {
        /**
         * @event A_Z_MORE
         * @type {object}
         * @property {string} element_id - New state ("Show All Sports" or "Hide All Sports").
         */
        this.logEvent(EVENTS.A_Z_MORE, {
            element_id: isExpanded ? 'Show All Sports' : 'Hide All Sports',
            ...arguments,
        });
    }

    /**
     * CMS link clicked.
     *
     * @param {string} linkName - Name of clicked link.
     *
     * @fires QUICK_LINK_INTERACTION
     */
    cmsQuickLinksClicked(linkName) {
        /**
         * @event QUICK_LINK_INTERACTION
         * @type {object}
         * @property {string} link_label - Name of clicked link.
         */
        this.logEvent(
            EVENTS.QUICK_LINK_INTERACTION,
            {
                link_label: linkName,
            },
            ...arguments
        );
    }

    /**
     * Sports Carousel link clicked.
     *
     * @param {string} sportId - Clicked sport id.
     *
     * @fires SPORTS_CAROUSEL_INTERACTION
     */
    sportsCarouselLinkClicked(sportId) {
        /**
         * @event SPORTS_CAROUSEL_INTERACTION
         * @type {object}
         * @property {string} sport - Clicked sport id.
         */
        this.logEvent(
            EVENTS.SPORTS_CAROUSEL_INTERACTION,
            {
                sport: sportId,
            },
            ...arguments
        );
    }

    /**
     * Match sport main navigation link clicked.
     *
     * @param {object} data - Object containing analytics data.
     * @param {string} data.linkName - Name of clicked link.
     * @param {string} data.sportId - Sport id.
     *
     * @fires SPORT_TABS_INTERACTION
     */
    matchSportMainNavigationClicked({linkName, sportId}) {
        /**
         * @event SPORT_TABS_INTERACTION
         * @type {object}
         * @property {string} element_label - Name of clicked link.
         * @property {string} sport - Sport id.
         */
        this.logEvent(
            EVENTS.SPORT_TABS_INTERACTION,
            {
                element_label: linkName,
                sport: sportId,
            },
            ...arguments
        );
    }

    /**
     * Racing main navigation link clicked.
     *
     * @param {object} data - Object containing analytics data.
     * @param {string} data.linkName - Name of clicked link.
     *
     * @fires RACING_TABS_NAVIGATION
     */
    racingMainNavigationClicked({linkName}) {
        /**
         * @event RACING_TABS_NAVIGATION
         * @type {object}
         * @property {string} element_label - Name of clicked link.
         */
        this.logEvent(
            EVENTS.RACING_TABS_NAVIGATION,
            {
                element_label: linkName,
            },
            ...arguments
        );
    }

    /**
     * CMS coupon clicked.
     *
     * @param {string} linkName - Name of clicked coupon.
     *
     * @fires COUPON_INTERACTION
     */
    cmsCouponsClicked(linkName) {
        /**
         * @event COUPON_INTERACTION
         * @type {object}
         * @property {string} element_label - Name of clicked coupon.
         */
        this.logEvent(
            EVENTS.COUPON_INTERACTION,
            {
                element_label: linkName,
            },
            ...arguments
        );
    }

    /**
     * Type filter clicked for a sport class.
     *
     * @param {object} data - Object containing analytics data.
     * @param {string} data.pageType - Page from where the click event originated.
     * @param {string} data.typeName - Name of selected filter.
     *
     * @fires LEAGUE_FILTER_INTERACTION
     */
    sportClassTypeClicked({typeName, pageType}) {
        /**
         * @event LEAGUE_FILTER_INTERACTION
         * @type {object}
         * @property {string} element_label - Name of selected filter.
         * @property {string} page_type - Page from where the click event originated.
         */
        this.logEvent(
            EVENTS.LEAGUE_FILTER_INTERACTION,
            {
                element_label: typeName,
                page_type: pageType,
            },
            ...arguments
        );
    }

    /**
     * QuickLink clicked.
     *
     * @param {string} label - Label of the quick link.
     *
     * @fires FOOTER_MOBILE_INTERACTION
     */
    quickLinkClicked(label) {
        /**
         * @event FOOTER_MOBILE_INTERACTION
         * @type {object}
         * @property {string} element_label - Label of the quick link.
         */
        this.logEvent(
            EVENTS.FOOTER_MOBILE_INTERACTION,
            {
                element_label: label,
            },
            ...arguments
        );
    }

    /**
     * Promotion clicked.
     *
     * @param {object} data - Object containing analytics data.
     * @param {string} data.locationId - Location ID.
     * @param {string} data.bannerName - Banner name.
     *
     * @fires BANNER_INTERACTION
     */
    promotionClicked({locationId, bannerName}) {
        /**
         * @event BANNER_INTERACTION
         * @type {object}
         * @property {string} location_id - Location ID.
         * @property {string} banner_name - Banner name.
         */
        this.logEvent(
            EVENTS.BANNER_INTERACTION,
            {
                location_id: locationId,
                banner_name: bannerName,
            },
            ...arguments
        );
    }

    /**
     * Navigation to specific event.
     *
     * @param {object} data - Object containing analytics data.
     * @param {string} data.name - Event name.
     * @param {string} data.type - Event type.
     * @param {string} data.sport - Event sport.
     *
     * @fires EVENT_INTERACTION
     */
    navigationEvent({name, type, sport}) {
        /**
         * @event EVENT_INTERACTION
         * @type {object}
         * @property {string} event_name - Event name.
         * @property {string} event_type - Event type.
         * @property {string} sport - Event sport.
         */
        this.logEvent(
            EVENTS.EVENT_INTERACTION,
            {
                event_name: name,
                event_type: type,
                sport,
            },
            ...arguments
        );
    }

    /**
     * Market group selector value changed.
     *
     * @param {object} data - Object containing analytics data.
     * @param {string} data.sportId - Sport id.
     * @param {string} data.marketGroupName - Market group name.
     * @param {string} data.eventName - Event name.
     *
     * @fires MARKET_CATEGORIES_INTERACTION
     */
    marketGroupChanged({sportId, marketGroupName, eventName}) {
        /**
         * @event MARKET_CATEGORIES_INTERACTION
         * @type {object}
         * @property {string} element_name - Market group name.
         * @property {string} sport - Sport id.
         * @property {string} event_name - Event name.
         */
        this.logEvent(
            EVENTS.MARKET_CATEGORIES_INTERACTION,
            {
                element_name: marketGroupName,
                sport: sportId,
                event_name: eventName,
            },
            ...arguments
        );
    }

    /**
     * Handicap market menu selector value changed.
     *
     * @param {string} id - Menu id.
     *
     * @fires HANDICAP_MARKET_TYPE_MENU_INTERACTION
     */
    handicapMarketMenuChanged(id) {
        /**
         * @event HANDICAP_MARKET_TYPE_MENU_INTERACTION
         * @type {object}
         * @property {string} id - Menu id.
         */
        this.logEvent(EVENTS.HANDICAP_MARKET_TYPE_MENU_INTERACTION, {
            id,
        });
    }

    /**
     * Selection added to the match acca slip.
     *
     * @param {string} selectionSource - Source of selection, typically from where in the application the selection was added.
     * @param {string} selectionId - Selection id.
     * @param {string} selectionPrice - Odds with added selection.
     * @param {string} eventName - Name of event.
     * @param {string} selectionName - Name of selection.
     * @param {string} marketGroupName - Market group name.
     *
     * @fires MATCH_ACCA_ADD_SELECTION
     */
    matchAccaAddSelection(selectionSource, selectionId, selectionPrice, eventName, selectionName, marketGroupName) {
        /**
         * @event MATCH_ACCA_ADD_SELECTION
         * @type {object}
         * @property {string} selection_source - Source of selection, typically from where in the application the selection was added.
         * @property {string} selection_name - Name of selection.
         * @property {string} selection_id - Selection id.
         * @property {string} event_name - Name of event.
         * @property {string} market_group_name - Market group name.
         */
        this.logEvent(
            EVENTS.MATCH_ACCA_ADD_SELECTION,
            {
                selection_source: selectionSource,
                selection_name: selectionName,
                selection_id: selectionId,
                event_name: eventName,
                market_group_name: marketGroupName,
            },
            ...arguments
        );
    }

    /**
     * Selection removed from the match acca slip.
     *
     * @param {string} selectionSource - Source of selection, typically from where in the application the selection was added.
     * @param {string} selectionId - Selection id.
     * @param {string} selectionPrice - Odds of selection.
     *
     * @fires MATCH_ACCA_REMOVE_SELECTION
     */
    removeMatchAccaSelection(selectionSource, selectionId, selectionPrice) {
        /**
         * @event MATCH_ACCA_REMOVE_SELECTION
         * @type {object}
         * @property {string} selection_id - Selection id.
         * @property {string} odds - Odds of selection.
         */
        this.logEvent(
            EVENTS.MATCH_ACCA_REMOVE_SELECTION,
            {
                selection_id: selectionId,
                odds: selectionPrice,
            },
            ...arguments
        );
    }

    /**
     * Cleared the entire match acca slip for the specified event.
     *
     * @fires MATCH_ACCA_CLEARED
     */
    clearMatchAccaSlip() {
        /**
         * @event MATCH_ACCA_CLEARED
         */
        this.logEvent(EVENTS.MATCH_ACCA_CLEARED, ...arguments);
    }

    /**
     * Match acca slip opened.
     *
     * @fires MATCH_ACCA_OPENED
     */
    matchAccaSlipOpen() {
        /**
         * @event MATCH_ACCA_OPENED
         */
        this.logEvent(EVENTS.MATCH_ACCA_OPENED, ...arguments);
    }

    /**
     * Match acca slip closed.
     *
     * @fires MATCH_ACCA_CLOSED
     */
    matchAccaSlipClose() {
        /**
         * @event MATCH_ACCA_CLOSED
         */
        this.logEvent(EVENTS.MATCH_ACCA_CLOSED, ...arguments);
    }

    /**
     * Match acca selections added to betslip.
     *
     * @param {string} selectionPrice - Selection price.
     * @param {number} numberOfSelections - Number of selection in the slip.
     * @param {string} balance - User balance when selection was added.
     *
     * @fires MATCH_ACCA_ADD_TO_BETSLIP
     */
    addMatchAccaToBetslip(selectionPrice, numberOfSelections, balance) {
        /**
         * @event MATCH_ACCA_ADD_TO_BETSLIP
         * @type {object}
         * @property {string} price - Selection price.
         * @property {number} number_selections - Number of selection in the slip.
         * @property {string} balance - User balance when selection was added.
         */
        this.logEvent(
            EVENTS.MATCH_ACCA_ADD_TO_BETSLIP,
            {
                price: selectionPrice,
                number_selections: numberOfSelections,
                balance,
            },
            ...arguments
        );
    }

    /**
     * Open betslip.
     *
     * @param {string} betslipType - Betslip type.
     *
     * @fires BETSLIP_OPEN
     */
    openBetslip(betslipType) {
        /**
         * @event BETSLIP_OPEN
         * @type {object}
         * @property {string} betslip_type - Betslip type.
         */
        this.logEvent(EVENTS.BETSLIP_OPEN, {betslip_type: betslipType}, ...arguments);
    }

    /**
     * Close betslip.
     *
     * @param {string} betslipType - Betslip type.
     *
     * @fires BETSLIP_CLOSE
     */
    closeBetslip(betslipType) {
        /**
         * @event BETSLIP_CLOSE
         * @type {object}
         * @property {string} betslip_type - Betslip type.
         */
        this.logEvent(EVENTS.BETSLIP_CLOSE, {betslip_type: betslipType}, ...arguments);
    }

    /**
     * Betslip stake group changed (single, multi, system, teaser, bankers).
     *
     * @param {string} stakeGroupName - Stake group name ("singles", "multiples", "systems", "teasers", "bankers" or "default").
     *
     * @namespace BETSLIP_TAB_CHANGE
     * @fires BETSLIP_TAB_CHANGE
     */
    betslipStakeGroupChanged(stakeGroupName) {
        /**
         * @event BETSLIP_TAB_CHANGE
         * @type {object}
         * @property {string} element_name - Stake group name ("singles", "multiples", "systems", "teasers", "bankers" or "default").
         */
        this.logEvent(
            `${EVENTS.BETSLIP_TAB_CHANGE}`,
            {
                element_name: stakeGroupName,
            },
            ...arguments
        );
    }

    /**
     * Betslip teaser type changed.
     *
     * @param {string} teaserType - Teaser type ("LOW", "MEDIUM" or "HIGH").
     *
     * @fires TEASER_TYPE_CHANGE
     */
    betslipTeaserTypeChanged(teaserType) {
        /**
         * @event TEASER_TYPE_CHANGE
         * @type {object}
         * @property {string} element_name - Teaser type ("LOW", "MEDIUM" or "HIGH").
         */
        this.logEvent(
            EVENTS.TEASER_TYPE_CHANGE,
            {
                element_name: teaserType,
            },
            ...arguments
        );
    }

    /**
     * Betslip settings popup is opened.
     *
     * @param {boolean} isQuickBetslip - True if settings was opened in quick betslip.
     *
     * @fires QBS_SETTINGS
     * @fires BETSLIP_SETTINGS
     */
    betslipSettingsOpened(isQuickBetslip) {
        /**
         * @event QBS_SETTINGS
         */
        /**
         * @event BETSLIP_SETTINGS
         */
        this.logEvent(isQuickBetslip ? EVENTS.QBS_SETTINGS : EVENTS.BETSLIP_SETTINGS, ...arguments);
    }

    /**
     * Betslip settings change accept all price changes.
     *
     * @param {string} isAcceptable - 'Yes' if all price changes are acceptable, 'No' otherwise.
     *
     * @fires BETSLIP_AUTOMATIC_PRICE_CHANGE_ALL
     */
    betslipChangeAcceptAllPrices(isAcceptable) {
        const element =
            isAcceptable === 'Yes' ? 'Accept All Price Changes Enabled' : 'Accept All Price Changes Disabled';
        /**
         * @event BETSLIP_AUTOMATIC_PRICE_CHANGE_ALL
         * @type {object}
         * @property {string} element - "Accept All Price Changes Enabled" or "Accept All Price Changes Disabled".
         * @property {string} element_label - Same as $element.
         */
        this.logEvent(
            EVENTS.BETSLIP_AUTOMATIC_PRICE_CHANGE_ALL,
            {
                element,
                element_label: element,
            },
            ...arguments
        );
    }

    /**
     * Betslip settings change accept higher price changes.
     *
     * @param {string} isAcceptable - 'Yes' if higher price changes are acceptable, 'No' otherwise.
     *
     * @fires BETSLIP_AUTOMATIC_PRICE_CHANGE_HIGHER
     */
    betslipChangeAcceptHigherPrices(isAcceptable) {
        const element =
            isAcceptable === 'Yes' ? 'Accept Higher Price Changes Enabled' : 'Accept Higher Price Changes Disabled';

        /**
         * @event BETSLIP_AUTOMATIC_PRICE_CHANGE_HIGHER
         * @type {object}
         * @property {string} element - "Accept Higher Price Changes Enabled" or "Accept Higher Price Changes Disabled".
         * @property {string} element_label - Same as $element.
         */
        this.logEvent(
            EVENTS.BETSLIP_AUTOMATIC_PRICE_CHANGE_HIGHER,
            {
                element,
                element_label: element,
            },
            ...arguments
        );
    }

    /**
     * Betslip settings change accept lower price changes.
     *
     * @param {string} isAcceptable - 'Yes' if lower price changes are acceptable, 'No' otherwise.
     *
     * @fires BETSLIP_AUTOMATIC_PRICE_CHANGE_LOWER
     */
    betslipChangeAcceptLowerPrices(isAcceptable) {
        const element =
            isAcceptable === 'Yes' ? 'Accept Lower Price Changes Enabled' : 'Accept Lower Price Changes Disabled';
        /**
         * @event BETSLIP_AUTOMATIC_PRICE_CHANGE_LOWER
         * @type {object}
         * @property {string} element - "Accept Lower Price Changes Enabled" or "Accept Lower Price Changes Disabled".
         * @property {string} element_label - Same as $element.
         */
        this.logEvent(
            EVENTS.BETSLIP_AUTOMATIC_PRICE_CHANGE_LOWER,
            {
                element,
                element_label: element,
            },

            ...arguments
        );
    }

    /**
     * Success adding freebet voucher.
     *
     * @param {string} voucherCode - Code to identify voucher.
     * @param {string} voucherName - Voucher name.
     *
     * @fires BETSLIP_FREEBET_ADD
     */
    addFreebetVoucherSuccess(voucherCode, voucherName) {
        /**
         * @event BETSLIP_FREEBET_ADD
         * @type {object}
         * @property {string} element_id - Code to identify voucher.
         * @property {string} element_name - Voucher name.
         * @property {string} value - "Free Bet Enabled".
         */
        this.logEvent(
            EVENTS.BETSLIP_FREEBET_ADD,
            {
                element_id: voucherCode,
                element_name: voucherName,
                value: 'Free Bet Enabled',
            },
            ...arguments
        );
    }

    /**
     * Fail adding freebet voucher.
     *
     * @param {object} data - Object containing analytics data.
     * @param {string} data.code - Free bet code.
     * @param {string} data.error - Cause of failure.
     *
     * @namespace BETSLIP_FREEBET_ADD
     * @fires BETSLIP_FREEBET_ADD~FAILED
     */
    addFreebetVoucherFailure({code, error}) {
        /**
         * @event BETSLIP_FREEBET_ADD~FAILED
         * @type {object}
         * @property {string} element_id - Free bet code.
         * @property {string} error - Cause of failure.
         */
        this.logEvent(
            `${EVENTS.BETSLIP_FREEBET_ADD} ${STATUS.FAILED}`,
            {
                element_id: code,
                error,
            },
            ...arguments
        );
    }

    /**
     * Success removing freebet voucher.
     *
     * @param {string} voucherCode - Code to identify voucher.
     *
     * @fires BETSLIP_FREEBET_REMOVE
     */
    removeFreebetVoucherSuccess(voucherCode) {
        /**
         * @event BETSLIP_FREEBET_REMOVE
         * @type {object}
         * @property {string} element_id - Code to identify voucher.
         * @property {string} value - "Free Bet Disabled".
         */
        this.logEvent(
            EVENTS.BETSLIP_FREEBET_REMOVE,
            {
                element_id: voucherCode,
                value: 'Free Bet Disabled',
            },
            ...arguments
        );
    }

    /**
     * Toggle "Show stakes per single" button.
     *
     * @param {boolean} showStake - Show or hide stake input per single.
     *
     * @fires BETSLIP_STAKE_PER_BET
     */
    toggleShowStakePerBetForSingles(showStake) {
        const element = showStake ? 'Show Stake Per Bet Enabled' : 'Show Stake Per Bet Disabled';
        /**
         * @event BETSLIP_STAKE_PER_BET
         * @type {object}
         * @property {string} element - "Show Stake Per Bet Enabled" or "Show Stake Per Bet Disabled".
         * @property {string} element_label - Same as $element.
         */
        this.logEvent(
            EVENTS.BETSLIP_STAKE_PER_BET,
            {
                element,
                element_label: element,
            },
            ...arguments
        );
    }

    /**
     * Each Way toggled on/off in betslip.
     *
     * @param {boolean} isEachWay - Flag indicating if Each Way is toggled 'on' or 'off'.
     *
     * @fires BETSLIP_EACH_WAY
     */
    toggleEachWay(isEachWay) {
        const element = isEachWay ? 'Each Way Enabled' : 'Each Way Disabled';
        /**
         * @event BETSLIP_EACH_WAY
         * @type {object}
         * @property {string} element - "Each Way Enabled" or "Each Way Disabled".
         * @property {string} element_label - Same as $element.
         */
        this.logEvent(
            EVENTS.BETSLIP_EACH_WAY,
            {
                element,
                element_label: element,
            },
            ...arguments
        );
    }

    /**
     * Key was clicked on custom numeric keypad.
     *
     * @param {object} data - Object containing analytics data.
     * @param {number} data.value - Numeric value of key.
     *
     * @fires BETSLIP_KEYPAD_INCREMENT
     */
    customKeypadKeyClicked({value}) {
        /**
         * @event BETSLIP_KEYPAD_INCREMENT
         * @type {object}
         * @property {number} value - Numeric value of key.
         */
        this.logEvent(
            EVENTS.BETSLIP_KEYPAD_INCREMENT,
            {
                value,
            },
            ...arguments
        );
    }

    /**
     * "Remove all" was clicked in betslip.
     *
     * @param {number} betslipSelectionCount - Number of selections on betslip.
     *
     * @fires BETSLIP_REMOVE_ALL
     */
    removeAllInBetslip(betslipSelectionCount) {
        /**
         * @event BETSLIP_REMOVE_ALL
         * @type {object}
         * @property {number} number_selections - Number of selections on betslip.
         */
        this.logEvent(
            EVENTS.BETSLIP_REMOVE_ALL,
            {
                number_selections: betslipSelectionCount,
            },
            ...arguments
        );
    }

    /**
     * Selection was added to betslip.
     *
     * @param {object} data - Object containing analytics data.
     * @param {string} data.selectionId - Selection id.
     * @param {string} data.selectionLabel - Selection button label.
     * @param {number} data.selectionPrice - Selection price.
     * @param {string} data.selectionSource - Source of selection, typically from where in the application the selection was added.
     * @param {string} data.selectionType - Selection type.
     * @param {string} data.eventName - Name of event to which the selection belongs.
     * @param {string} data.eventId - Id of event to which the selection belongs.
     * @param {string} data.marketId - Id of market.
     * @param {string} data.marketName - Name of market.
     *
     * @fires SELECTION_ADD
     */
    selectionAddedToBetslip({
        marketId,
        marketName,
        selectionId,
        selectionLabel,
        selectionPrice,
        selectionSource,
        selectionType,
        eventId,
        eventName,
    }) {
        const availableSelectionType = selectionType ? {selection_type: selectionType} : {};
        /**
         * @event SELECTION_ADD
         * @type {object}
         * @property {string} selection_id - Selection id.
         * @property {string} selection_label - Selection button label.
         * @property {string} [selection_type] - Selection type (optional).
         * @property {number} price - Selection price.
         * @property {string} location_id - Source of selection, typically from where in the application the selection was added.
         * @property {string} market_id - Id of market.
         * @property {string} market_name - Name of market.
         * @property {string} event_id - Id of event to which the selection belongs.
         * @property {string} event_name - Name of event to which the selection belongs.
         */
        this.logEvent(
            EVENTS.SELECTION_ADD,
            {
                selection_id: selectionId,
                selection_label: selectionLabel,
                price: selectionPrice,
                location_id: selectionSource,
                market_id: marketId,
                market_name: marketName,
                event_id: eventId,
                event_name: eventName,
                ...availableSelectionType,
            },
            ...arguments
        );
    }

    /**
     * Selection was removed from betslip.
     *
     * @param {object} data - Object containing analytics data.
     * @param {number} data.selectionPrice - Selection price.
     * @param {string} data.selectionLabel - Selection label.
     * @param {string} data.selectionSource - Source of selection, typically from where in the application the selection was removed.
     * @param {string} data.marketName - Name of market.
     * @param {string} data.eventName - Name of event to which the selection belongs.
     *
     * @fires SELECTION_REMOVE
     */
    selectionRemovedFromBetslip({selectionPrice, selectionLabel, selectionSource, marketName, eventName}) {
        /**
         * @event SELECTION_REMOVE
         * @type {object}
         * @property {string} selection_name - Selection label.
         * @property {string} selection_source - Source of selection, typically from where in the application the selection was removed.
         * @property {number} price - Selection price.
         * @property {string} market_name - Name of market.
         * @property {string} event_name - Name of event to which the selection belongs.
         */
        this.logEvent(
            EVENTS.SELECTION_REMOVE,
            {
                selection_name: selectionLabel,
                selection_source: selectionSource,
                price: selectionPrice,
                market_name: marketName,
                event_name: eventName,
            },
            ...arguments
        );
    }

    /**
     * Bet placement ended with status success.
     *
     * @param {object} data - Object containing analytics data.
     * @param {number} data.totalStake - Total stake.
     * @param {string} data.betType - Bet type.
     * @param {number} data.selectionCount - Number of selections in receipt.
     * @param {number} data.odds - Odds for bet.
     * @param {number} data.freebetStake - Value of free bet if used with bet.
     * @param {string} data.betslipType - Betslip type.
     * @param {number} data.accaBoost - AccaBoost value.
     * @param {string} data.buildType - BuildType, set if bet is of build type MATCH_ACCA or PREBUILT_MATCH_ACCA.
     * @param {number} data.baseOdds - BaseOdds, indicating priceBoost.
     *
     * @namespace PLACE_BET
     * @fires PLACE_BET~SUCCESSFUL
     */
    betPlaced({totalStake, betType, selectionCount, odds, betslipType, freebetStake, accaBoost, buildType, baseOdds}) {
        /**
         * @event PLACE_BET~SUCCESSFUL
         * @type {object}
         * @property {number} total_price - Odds for bet.
         * @property {string} bet_type - Bet type.
         * @property {number} total_stake - Total stake.
         * @property {number} number_selections - Number of selections in receipt.
         * @property {string} betslip_type - Betslip type.
         * @property {number} freebet_stake - Value of free bet if used with bet.
         * @property {number} acca_boost - AccaBoost value.
         * @property {string} build_type - BuildType, set if bet is of build type MATCH_ACCA or PREBUILT_MATCH_ACCA.
         * @property {number} base_price - BaseOdds, indicating priceBoost.
         */
        this.logEvent(
            `${EVENTS.PLACE_BET} ${STATUS.SUCCESSFUL}`,
            {
                total_price: odds,
                bet_type: betType,
                total_stake: totalStake,
                number_selections: selectionCount,
                betslip_type: betslipType,
                freebet_stake: freebetStake,
                acca_boost: accaBoost,
                build_type: buildType,
                base_price: baseOdds,
            },
            ...arguments
        );
    }

    /**
     * Betslip request failed.
     *
     * @param {string} betslipType - Betslip type.
     *
     * @fires BETSLIP_REQUEST_FAILED
     */
    betslipRequestFailed(betslipType) {
        /**
         * @event BETSLIP_REQUEST_FAILED
         * @type {object}
         * @property {string} betslip_type - Betslip type.
         */
        this.logEvent(
            EVENTS.BETSLIP_REQUEST_FAILED,
            {
                betslip_type: betslipType,
            },
            ...arguments
        );
    }

    /**
     * Video player full-screen state changed.
     *
     * @param {boolean} isFullScreen - Full-screen flag.
     * @param {string} eventName - Name of the betting event.
     *
     * @fires STREAM_FULL_SCREEN
     */
    streamFullScreenChanged(isFullScreen, eventName) {
        /**
         * @event STREAM_FULL_SCREEN
         * @type {object}
         * @property {boolean} is_fullscreen - Full-screen flag.
         * @property {string} event - Name of the betting event.
         */
        this.logEvent(EVENTS.STREAM_FULL_SCREEN, {is_fullscreen: isFullScreen, event: eventName}, ...arguments);
    }

    /**
     * User indicated the intent to start playing a video stream.
     *
     * @param {string} eventName - Name of the betting event.
     *
     * @fires STREAM_PLAY
     */
    playStream(eventName) {
        /**
         * @event STREAM_PLAY
         * @type {object}
         * @property {string} event - Name of the betting event.
         */
        this.logEvent(EVENTS.STREAM_PLAY, {event: eventName}, ...arguments);
    }

    /**
     * Video player emitted some error.
     *
     * @param {string} reason - Failure reason.
     *
     * @namespace STREAM_PLAY
     * @fires STREAM_PLAY~FAILED
     */
    streamFailToPlay(reason) {
        /**
         * @event STREAM_PLAY~FAILED
         * @type {object}
         * @property {string} error - Failure reason.
         */
        this.logEvent(
            `${EVENTS.STREAM_PLAY} ${STATUS.FAILED}`,
            {
                error: reason,
            },
            ...arguments
        );
    }

    /**
     * Video player stopped and closed its current stream.
     *
     * @param {string} eventName - Name of the betting event.
     *
     * @fires STREAM_CLOSED
     */
    closeStream(eventName) {
        /**
         * @event STREAM_CLOSED
         * @type {object}
         * @property {string} event - Name of the betting event.
         */
        this.logEvent(EVENTS.STREAM_CLOSED, {event: eventName}, ...arguments);
    }

    /**
     * Video player started playing a stream.
     *
     * @param {string} eventName - Name of the betting event.
     *
     * @fires STREAM_STARTED
     */
    streamPlaying(eventName) {
        /**
         * @event STREAM_STARTED
         * @type {object}
         * @property {string} event - Name of the betting event.
         */
        this.logEvent(EVENTS.STREAM_STARTED, {event: eventName}, ...arguments);
    }

    /**
     * Odds format was changed.
     *
     * @param {string} oldOddsFormat - Old odds format.
     * @param {string} newOddsFormat - New odds format.
     *
     * @fires SETTINGS_ODDS_FORMAT
     */
    oddsFormatChanged(oldOddsFormat, newOddsFormat) {
        /**
         * @event SETTINGS_ODDS_FORMAT
         * @type {object}
         * @property {string} old_odds_format - Old odds format.
         * @property {string} new_odds_format - New odds format.
         */
        this.logEvent(
            EVENTS.SETTINGS_ODDS_FORMAT,
            {
                old_odds_format: oldOddsFormat,
                new_odds_format: newOddsFormat,
            },
            ...arguments
        );
    }

    /**
     * Video player was muted.
     *
     * @param {string} eventName - Name of the betting event.
     *
     * @fires STREAM_MUTE
     */
    streamMute(eventName) {
        /**
         * @event STREAM_MUTE
         * @type {object}
         * @property {string} event - Name of the betting event.
         */
        this.logEvent(EVENTS.STREAM_MUTE, {event: eventName}, ...arguments);
    }

    /**
     * Video player was unmuted.
     *
     * @param {string} eventName - Name of the betting event.
     *
     * @fires STREAM_UNMUTED
     */
    streamUnmute(eventName) {
        /**
         * @event STREAM_UNMUTED
         * @type {object}
         * @property {string} event - Name of the betting event.
         */
        this.logEvent(EVENTS.STREAM_UNMUTED, {event: eventName}, ...arguments);
    }

    /**
     * Video player sound volume was changed.
     *
     * @param {object} data - Object containing analytics data.
     * @param {string} data.eventName - Name of the betting event.
     * @param {number} data.volume - Sound volume (0 - 100).
     *
     * @fires STREAM_VOLUME_CHANGED
     */
    streamVolumeChanged({eventName, volume}) {
        /**
         * @event STREAM_VOLUME_CHANGED
         * @type {object}
         * @property {string} event - Name of the betting event.
         * @property {number} volume - Sound volume (0 - 100).
         */
        this.logEvent(EVENTS.STREAM_VOLUME_CHANGED, {event: eventName, volume}, ...arguments);
    }

    /**
     * Toggle competition header.
     *
     * @param {object} data - Object containing analytics data.
     * @param {string} data.competitionName - Competition name.
     * @param {string} data.sport - Sport type.
     *
     * @fires COMPETITION_COLLAPSE_OR_EXPAND_INTERACTION
     */
    competitionToggleExpandable({competitionName, sport}) {
        /**
         * @event COMPETITION_COLLAPSE_OR_EXPAND_INTERACTION
         * @type {object}
         * @property {string} competition_name - Competition name.
         * @property {string} sport - Sport type.
         */
        this.logEvent(
            EVENTS.COMPETITION_COLLAPSE_OR_EXPAND_INTERACTION,
            {
                competition_name: competitionName,
                sport,
            },
            ...arguments
        );
    }

    /**
     * Toggle market header.
     *
     * @param {object} data - Object containing analytics data.
     * @param {string} data.marketName - Market name.
     * @param {string} data.eventName - Event name.
     * @param {string} data.competitionName - Competition name.
     * @param {string} data.sport - Sport type.
     *
     * @fires MARKET_COLLAPSE_OR_EXPAND_INTERACTION
     */
    marketToggleExpandable(data) {
        const {marketName, eventName, competitionName, sport} = data;

        /**
         * @event MARKET_COLLAPSE_OR_EXPAND_INTERACTION
         * @type {object}
         * @property {string} market_name - Market name.
         * @property {string} event_name - Event name.
         * @property {string} competition_name - Competition name.
         * @property {string} sport - Sport type.
         */
        this.logEvent(
            EVENTS.MARKET_COLLAPSE_OR_EXPAND_INTERACTION,
            {
                market_name: marketName,
                event_name: eventName,
                competition_name: competitionName,
                sport,
            },
            ...arguments
        );
    }

    /**
     * Back button clicked.
     *
     * @fires BACK_BUTTON_INTERACTION
     */
    backClicked() {
        /**
         * @event BACK_BUTTON_INTERACTION
         */
        this.logEvent(EVENTS.BACK_BUTTON_INTERACTION, ...arguments);
    }

    /**
     * Breadcrumbs clicked.
     *
     * @param {object} data - Object containing analytics data.
     * @param {number} data.linkLevel - The depth of the clicked breadcrumb.
     * @param {string} data.linkName - The label of the clicked breadcrumb.
     *
     * @fires BREADCRUMB_INTERACTION
     */
    breadcrumbsClicked({linkLevel, linkName}) {
        /**
         * @event BREADCRUMB_INTERACTION
         * @type {object}
         * @property {number} link_level - The depth of the clicked breadcrumb.
         * @property {string} link_name - The label of the clicked breadcrumb.
         */
        this.logEvent(
            EVENTS.BREADCRUMB_INTERACTION,
            {
                link_level: linkLevel,
                link_name: linkName,
            },
            ...arguments
        );
    }

    /**
     * New Time Zone Settings.
     *
     * @param {string} NewTimeZoneValue - New time zone value.
     *
     * @fires SETTINGS_TIME_ZONE
     */
    settingsTimeZone(NewTimeZoneValue) {
        /**
         * @event SETTINGS_TIME_ZONE
         * @type {object}
         * @property {string} new_time_zone - New time zone value.
         */
        this.logEvent(
            EVENTS.SETTINGS_TIME_ZONE,
            {
                new_time_zone: NewTimeZoneValue,
            },
            ...arguments
        );
    }

    /**
     * Language was changed.
     *
     * @param {string} oldLanguage - Old language.
     * @param {string} newLanguage - New language.
     *
     * @fires SETTINGS_LANGUAGE
     */
    languageChanged(oldLanguage, newLanguage) {
        /**
         * @event SETTINGS_LANGUAGE
         * @type {object}
         * @property {string} old_language - Old language.
         * @property {string} new_language - New language.
         */
        this.logEvent(
            EVENTS.SETTINGS_LANGUAGE,
            {
                old_language: oldLanguage,
                new_language: newLanguage,
            },
            ...arguments
        );
    }

    /**
     * Bet history on details click.
     *
     * @param {object} data - Object containing analytics data.
     * @param {string} data.action - Action of the click.
     * @param {string} data.betStatus - Status of selected bet.
     *
     * @fires BET_HISTORY_BET_DETAIL_INTERACTION
     */
    betHistoryOnDetailsClick({action, betStatus}) {
        if (betStatus) {
            /**
             * @event BET_HISTORY_BET_DETAIL_INTERACTION
             * @type {object}
             * @property {string} click_action - Action of the click.
             * @property {string} bet_status - Status of selected bet.
             */
            this.logEvent(
                EVENTS.BET_HISTORY_BET_DETAIL_INTERACTION,
                {
                    click_action: action,
                    bet_status: betStatus,
                },
                ...arguments
            );
        }
    }

    /**
     * Bet history range filter change.
     *
     * @param {string} value - Range filter value.
     *
     * @fires BET_HISTORY_BET_TIME_PERIOD_INTERACTION
     */
    betHistoryRangeFilterChange(value) {
        /**
         * @event BET_HISTORY_BET_TIME_PERIOD_INTERACTION
         * @type {object}
         * @property {string} range_filter - Range filter value.
         */
        this.logEvent(
            EVENTS.BET_HISTORY_BET_TIME_PERIOD_INTERACTION,
            {
                range_filter: value,
            },
            ...arguments
        );
    }

    /**
     * Bet history bet status change.
     *
     * @param {string} status - Selected bet status.
     *
     * @fires BET_HISTORY_BET_FILTER_INTERACTION
     */
    betHistoryStatusChange(status) {
        /**
         * @event BET_HISTORY_BET_FILTER_INTERACTION
         * @type {object}
         * @property {string} filter_status - Selected bet status.
         */
        this.logEvent(
            EVENTS.BET_HISTORY_BET_FILTER_INTERACTION,
            {
                filter_status: status,
            },
            ...arguments
        );
    }

    /**
     * Cash out operation ended.
     *
     * @param {string} status - Cashout status.
     * @param {number} cashoutValue - Offered cash out value.
     * @param {string} betId - Id of bet cashed out.
     *
     * @fires CASH_OUT_STATUS
     */
    cashedOut(status, cashoutValue, betId) {
        /**
         * @event CASH_OUT_STATUS
         * @type {object}
         * @property {string} cash_out_status - "Successful" or "Failed".
         * @property {number} cash_out_value - Offered cash out value.
         * @property {string} bet_id - Id of bet cashed out.
         */
        this.logEvent(
            EVENTS.CASH_OUT_STATUS,
            {
                cash_out_status: status === 'ok' ? STATUS.SUCCESSFUL : STATUS.FAILED,
                cash_out_value: cashoutValue,
                bet_id: betId,
            },
            ...arguments
        );
    }

    /**
     * Cash out was confirmed.
     *
     * @param {number} cashoutValue - Offered cash out value.
     * @param {string} betId - Id of bet cashed out.
     *
     * @fires CASH_OUT_CONFIRM
     */
    cashoutConfirmed(cashoutValue, betId) {
        /**
         * @event CASH_OUT_CONFIRM
         * @type {object}
         * @property {number} cash_out_value - Offered cash out value.
         * @property {string} bet_id - Id of bet cashed out.
         */
        this.logEvent(
            EVENTS.CASH_OUT_CONFIRM,
            {
                cash_out_value: cashoutValue,
                bet_id: betId,
            },
            ...arguments
        );
    }

    /**
     * The cash out process was initialized.
     *
     * @param {object} data - Object containing analytics data.
     * @param {string} data.betId - Bet id of bet cashed out.
     * @param {number} data.numberSelections - Number of selections in bet.
     * @param {string} data.betType - Bet type.
     * @param {string} data.betStatus - Status on bet.
     * @param {number} data.cashoutValue - Offered cash out value.
     *
     * @fires CASH_OUT_INTERACTION
     */
    initCashOut({betId, cashoutValue, numberSelections, betType, betStatus}) {
        /**
         * @event CASH_OUT_INTERACTION
         * @type {object}
         * @property {string} bet_id - Bet id of bet cashed out.
         * @property {number} total_price - Offered cash out value.
         * @property {number} number_selections - Number of selections in bet.
         * @property {string} bet_type - Bet type.
         * @property {string} bet_status - Status on bet.
         */
        this.logEvent(
            EVENTS.CASH_OUT_INTERACTION,
            {
                bet_id: betId,
                total_price: cashoutValue,
                number_selections: numberSelections,
                bet_type: betType,
                bet_status: betStatus,
            },
            ...arguments
        );
    }

    /**
     * Your bets sub tab clicked.
     *
     * @param {string} tab - Name of clicked tab.
     *
     * @fires YOUR_BETS_TAB_INTERACTION
     */
    yourBetsSubTabClicked(tab) {
        /**
         * @event YOUR_BETS_TAB_INTERACTION
         * @type {object}
         * @property {string} tab_name - Name of clicked tab.
         * @property {string} element_name - Same as $tab_name.
         */
        this.logEvent(
            EVENTS.YOUR_BETS_TAB_INTERACTION,
            {
                tab_name: tab,
                element_name: tab,
            },
            ...arguments
        );
    }

    /**
     * Selected betslip tab changed.
     *
     * @param {string} selectedTab - New selected tab.
     * @param {number} openBetsCount - Number of open bets.
     *
     * @fires YOUR_BETS_OPEN
     */
    betslipTabChanged(selectedTab, openBetsCount) {
        /**
         * @event YOUR_BETS_OPEN
         * @type {object}
         * @property {string} tab_name - New selected tab.
         * @property {string} element_name - Same as $tab_name.
         * @property {number} number_open_bets - Number of open bets.
         */
        this.logEvent(
            EVENTS.YOUR_BETS_OPEN,
            {
                tab_name: selectedTab,
                element_name: selectedTab,
                number_open_bets: openBetsCount,
            },
            ...arguments
        );
    }

    /**
     * Retain selection button pressed.
     *
     * @param {number} numberSelections - Number of selections in receipt.
     *
     * @fires BET_RECEIPT_RETAIN_SELECTIONS
     */
    retainSelection({numberSelections}) {
        /**
         * @event BET_RECEIPT_RETAIN_SELECTIONS
         * @type {object}
         * @property {number} number_selections - Number of selections in receipt.
         */
        this.logEvent(
            EVENTS.BET_RECEIPT_RETAIN_SELECTIONS,
            {
                number_selections: numberSelections,
            },
            ...arguments
        );
    }

    /**
     * Clear receipt button clicked.
     *
     * @param {number} numberSelections - Number of selections in receipt.
     *
     * @fires BET_RECEIPT_CLEAR_SELECTIONS
     */
    clearSelection({numberSelections}) {
        /**
         * @event BET_RECEIPT_CLEAR_SELECTIONS
         * @type {object}
         * @property {number} number_selections - Number of selections in receipt.
         */
        this.logEvent(
            EVENTS.BET_RECEIPT_CLEAR_SELECTIONS,
            {
                number_selections: numberSelections,
            },
            ...arguments
        );
    }

    /**
     * When Bet Receipt is open.
     *
     * @fires BET_RECEIPT_SHOWN
     */
    betReceiptShown() {
        /**
         * @event BET_RECEIPT_SHOWN
         */
        this.logEvent(EVENTS.BET_RECEIPT_SHOWN, ...arguments);
    }

    /**
     * Quick Betslip stake box interaction.
     *
     * @fires QBS_STAKE_BOX
     */
    quickBetslipStakeBoxInteraction() {
        /**
         * @event QBS_STAKE_BOX
         */
        this.logEvent(EVENTS.QBS_STAKE_BOX, ...arguments);
    }

    /**
     * Quick Betslip expanded to full betslip.
     *
     * @fires QBS_OPEN_FULL
     */
    quickBetslipToFull() {
        /**
         * @event QBS_OPEN_FULL
         */
        this.logEvent(EVENTS.QBS_OPEN_FULL, ...arguments);
    }

    /**
     * Event cards carousel swiped.
     *
     * @fires CARD_SWIPE
     */
    eventCardsCarouselSwiped() {
        /**
         * @event CARD_SWIPE
         */
        this.logEvent(EVENTS.CARD_SWIPE, ...arguments);
    }

    /**
     * Search input focused.
     *
     * @fires SEARCH_INPUT_FOCUSED
     */
    searchInputFocused() {
        /**
         * @event SEARCH_INPUT_FOCUSED
         */
        this.logEvent(EVENTS.SEARCH_INPUT_FOCUSED, ...arguments);
    }

    /**
     * Perform search.
     *
     * @param {string} searchString - Search string to be tracked.
     * @param {number} numberOfResults - Number of search result items.
     * @param {boolean} hasMoreResults - Flag that there are more results. Needed since pagination is supported.
     *
     * @fires SEARCH_PERFORMED
     */
    searchPerformed(searchString, numberOfResults, hasMoreResults) {
        /**
         * @event SEARCH_PERFORMED
         * @type {object}
         * @property {string} keyword_term - Search string to be tracked.
         * @property {string} search_term - Same as $keyword_term.
         * @property {number} number_results - Number of search result items.
         * @property {boolean} has_more_results - Flag that there are more results. Needed since pagination is supported.
         */
        this.logEvent(
            EVENTS.SEARCH_PERFORMED,
            {
                keyword_term: searchString,
                search_term: searchString,
                number_results: numberOfResults,
                has_more_results: hasMoreResults,
            },
            ...arguments
        );
    }

    /**
     * Search input has been cleared.
     *
     * @fires SEARCH_CLEARED
     */
    searchInputCleared() {
        /**
         * @event SEARCH_CLEARED
         */
        this.logEvent(EVENTS.SEARCH_CLEARED, ...arguments);
    }

    /**
     * Search history item has been removed.
     *
     * @param {string} searchString - Search term removed from history.
     *
     * @fires SEARCH_TERM_DELETE
     */
    searchHistoryItemRemoved(searchString) {
        /**
         * @event SEARCH_TERM_DELETE
         * @type {object}
         * @property {string} keyword_term - Search term removed from history.
         * @property {string} search_term - Same as $keyword_term.
         */
        this.logEvent(
            EVENTS.SEARCH_TERM_DELETE,
            {
                keyword_term: searchString,
                search_term: searchString,
            },
            ...arguments
        );
    }

    /**
     * Search history item clicked.
     *
     * @param {string} searchString - Search term clicked.
     *
     * @fires SEARCH_TERM_SELECTED
     */
    searchHistoryItemClicked(searchString) {
        /**
         * @event SEARCH_TERM_SELECTED
         * @type {object}
         * @property {string} keyword_term - Search term clicked.
         * @property {string} search_term - Same as $keyword_term.
         */
        this.logEvent(
            EVENTS.SEARCH_TERM_SELECTED,
            {
                keyword_term: searchString,
                search_term: searchString,
            },
            ...arguments
        );
    }

    /**
     * Search results item clicked.
     *
     * @param {object} data - Object containing search result item data.
     * @param {string} data.sportId - Search result sport category.
     * @param {string} data.name - Search result item name.
     * @param {string} data.parentName - Search result item parentName.
     * @param {string} data.resultType - Search result type ("EVENT", "TYPE", "RACE_MEETING" or "SPORT").
     *
     * @fires SEARCH_RESULT_SELECTED
     */
    searchResultsItemClicked({sportId, name, parentName, resultType}) {
        /**
         * @event SEARCH_RESULT_SELECTED
         * @type {object}
         * @property {string} sport - Search result sport category.
         * @property {string} event_name - Search result item name.
         * @property {string} parent_name - Search result item parentName.
         * @property {string} element_name - Same as $parent_name.
         * @property {string} result_type - Search result type ("EVENT", "TYPE", "RACE_MEETING" or "SPORT").
         */
        this.logEvent(
            EVENTS.SEARCH_RESULT_SELECTED,
            {
                sport: sportId,
                event_name: name,
                parent_name: parentName,
                element_name: parentName,
                result_type: resultType,
            },
            ...arguments
        );
    }

    /**
     * Leagues quick navigation opened.
     *
     * @fires LEAGUES_NAVIGATION_MENU_OPENED
     */
    leaguesQuickNavigationOpened() {
        /**
         * @event LEAGUES_NAVIGATION_MENU_OPENED
         */
        this.logEvent(EVENTS.LEAGUES_NAVIGATION_MENU_OPENED, ...arguments);
    }

    /**
     * Leagues quick navigation closed.
     *
     * @fires LEAGUES_NAVIGATION_MENU_CLOSED
     */
    leaguesQuickNavigationClosed() {
        /**
         * @event LEAGUES_NAVIGATION_MENU_CLOSED
         */
        this.logEvent(EVENTS.LEAGUES_NAVIGATION_MENU_CLOSED, ...arguments);
    }

    /**
     * Events quick navigation opened.
     *
     * @param {object} data - Object containing analytics data.
     * @param {string} data.eventName - Event name.
     * @param {string} data.eventType - Event type.
     * @param {string} data.sportId - Sport id.
     * @param {boolean} data.isInPlay - True is navigation is triggered across live events.
     *
     * @fires EVENTS_NAVIGATION_MENU_OPENED
     */
    eventsQuickNavigationOpened({eventName, eventType, sportId, isInPlay}) {
        /**
         * @event EVENTS_NAVIGATION_MENU_OPENED
         * @type {object}
         * @property {string} sport - Sport id.
         * @property {string} event_name - Event name.
         * @property {string} type_name - Event type.
         * @property {string} event_is_inPlay - "Yes" or "No".
         */
        this.logEvent(
            EVENTS.EVENTS_NAVIGATION_MENU_OPENED,
            {
                sport: sportId,
                event_name: eventName,
                type_name: eventType,
                event_is_inPlay: isInPlay ? 'Yes' : 'No',
            },
            ...arguments
        );
    }

    /**
     * Events quick navigation closed.
     *
     * @fires EVENTS_NAVIGATION_MENU_CLOSED
     */
    eventsQuickNavigationClosed() {
        /**
         * @event EVENTS_NAVIGATION_MENU_CLOSED
         */
        this.logEvent(EVENTS.EVENTS_NAVIGATION_MENU_CLOSED, ...arguments);
    }

    /**
     * Betslip changes are accepted.
     *
     * @param {string} betslipType - Betslip type.
     *
     * @fires BETSLIP_CHANGES_ACCEPTED
     */
    acceptChanges(betslipType) {
        /**
         * @event BETSLIP_CHANGES_ACCEPTED
         * @type {object}
         * @property {string} betslip_type - Betslip type.
         */
        this.logEvent(
            EVENTS.BETSLIP_CHANGES_ACCEPTED,
            {
                betslip_type: betslipType,
            },
            ...arguments
        );
    }

    /**
     * An attempt to place a bet was made.
     *
     * @param {object} data - Object containing analytics data.
     * @param {string} data.betslipType - Betslip type.
     *
     * @namespace PLACE_BET
     * @fires PLACE_BET~INITIATED
     */
    placingBet({betslipType}) {
        /**
         * @event PLACE_BET~INITIATED
         * @type {object}
         * @property {string} betslip_type - Betslip type.
         */
        this.logEvent(
            `${EVENTS.PLACE_BET} ${STATUS.INITIATED}`,
            {
                betslip_type: betslipType,
            },
            ...arguments
        );
    }

    /**
     * Custom numeric keypad was shown.
     *
     * @param {boolean} isShown - True if shown and false if hidden.
     *
     * @fires BETSLIP_KEYPAD_SHOWN
     * @fires BETSLIP_KEYPAD_HIDDEN
     */
    customKeypadShown(isShown = true) {
        const event = isShown ? EVENTS.BETSLIP_KEYPAD_SHOWN : EVENTS.BETSLIP_KEYPAD_HIDDEN;
        /**
         * @event BETSLIP_KEYPAD_SHOWN
         */
        /**
         * @event BETSLIP_KEYPAD_HIDDEN
         */
        this.logEvent(event, ...arguments);
    }

    /**
     * In play side menu coupon selected.
     *
     * @fires IN_PLAY_SIDE_MENU_ITEM_CLICK
     */
    inPlaySideMenuItemClicked() {
        /**
         * @event IN_PLAY_SIDE_MENU_ITEM_CLICK
         */
        this.logEvent(EVENTS.IN_PLAY_SIDE_MENU_ITEM_CLICK, ...arguments);
    }

    /**
     * Virtual stream started by user interaction for event id.
     *
     * @param {string} eventName - Name of the virtual event.
     *
     * @fires VIRTUAL_STREAM_WATCH
     */
    virtualStreamingWatchButtonClicked(eventName) {
        /**
         * @event VIRTUAL_STREAM_WATCH
         * @type {object}
         * @property {string} event - Name of the virtual event.
         */
        this.logEvent(EVENTS.VIRTUAL_STREAM_WATCH, {event: eventName}, ...arguments);
    }

    /**
     * Virtual selected event was changed.
     *
     * @param {string} eventName - Name of the virtual event.
     *
     * @fires VIRTUAL_EVENT_SELECTED
     */
    virtualSportsSelectedEventChanged(eventName) {
        /**
         * @event VIRTUAL_EVENT_SELECTED
         * @type {object}
         * @property {string} event - Name of the virtual event.
         */
        this.logEvent(EVENTS.VIRTUAL_EVENT_SELECTED, {event: eventName}, ...arguments);
    }

    /**
     * Virtual selected sport was changed.
     *
     * @param {string} sportName - Name of the virtual sport.
     *
     * @fires VIRTUAL_SPORT_SELECTED
     */
    virtualSportsSelectedSportChanged(sportName) {
        /**
         * @event VIRTUAL_SPORT_SELECTED
         * @type {object}
         * @property {string} sport - Name of the virtual sport.
         */
        this.logEvent(EVENTS.VIRTUAL_SPORT_SELECTED, {sport: sportName}, ...arguments);
    }

    /**
     * Logo clicked.
     *
     * @fires LOGO
     */
    logoClicked() {
        /**
         * @event LOGO
         */
        this.logEvent(EVENTS.LOGO, ...arguments);
    }

    /**
     * Race card selected on next races.
     *
     * @param {string} raceName - Selected race name.
     *
     * @fires NEXT_RACES_RACE_CARD_CHANGE
     */
    changeRaceCard(raceName) {
        /**
         * @event NEXT_RACES_RACE_CARD_CHANGE
         * @type {object}
         * @property {string} race - Selected race name.
         */
        this.logEvent(EVENTS.NEXT_RACES_RACE_CARD_CHANGE, {race: raceName}, ...arguments);
    }

    /**
     * Event card clicked.
     *
     * @param {string} eventName - The event name.
     *
     * @fires EVENT_CARD_CLICKED
     */
    eventCardClicked(eventName) {
        /**
         * @event EVENT_CARD_CLICKED
         * @type {object}
         * @property {string} event - The event name.
         */
        this.logEvent(EVENTS.EVENT_CARD_CLICKED, {event: eventName}, ...arguments);
    }

    /**
     * View full race details clicked.
     *
     * @param {object} data - Object containing analytics data.
     * @param {string} data.raceName - Clicked race name.
     * @param {string} data.status - Clicked race status.
     *
     * @fires NEXT_RACES_RACE_CARD_CLICK
     */
    viewFullRace({raceName, status}) {
        /**
         * @event NEXT_RACES_RACE_CARD_CLICK
         * @type {object}
         * @property {string} race - Clicked race name.
         * @property {string} status - Clicked race status.
         */
        this.logEvent(EVENTS.NEXT_RACES_RACE_CARD_CLICK, {race: raceName, status}, ...arguments);
    }

    /**
     * Race selected in top navigation on race card.
     *
     * @param {object} data - Object containing analytics data.
     * @param {string} data.raceName - Clicked race name.
     * @param {string} data.status - Clicked race status.
     *
     * @fires RACE_CARD_SELECT_RACE
     */
    selectRace({raceName, status}) {
        /**
         * @event RACE_CARD_SELECT_RACE
         * @type {object}
         * @property {string} race - Clicked race name.
         * @property {string} status - Clicked race status.
         */
        this.logEvent(EVENTS.RACE_CARD_SELECT_RACE, {race: raceName, status}, ...arguments);
    }

    /**
     * Meeting selected in dropdown on race card.
     *
     * @param {string} meetingName - Selected meeting name.
     *
     * @fires RACE_CARD_SELECT_MEETING
     */
    selectMeeting(meetingName) {
        /**
         * @event RACE_CARD_SELECT_MEETING
         * @type {object}
         * @property {string} meeting - Selected meeting name.
         */
        this.logEvent(EVENTS.RACE_CARD_SELECT_MEETING, {meeting: meetingName}, ...arguments);
    }

    /**
     * Runners sort changed on race card.
     *
     * @param {string} sortType - Type of selected sorting.
     *
     * @fires RACE_CARD_SORT_RUNNERS
     */
    sortRunners(sortType) {
        /**
         * @event RACE_CARD_SORT_RUNNERS
         * @type {object}
         * @property {string} sort_type - Type of selected sorting.
         */
        this.logEvent(EVENTS.RACE_CARD_SORT_RUNNERS, {sort_type: sortType}, ...arguments);
    }

    /**
     * Location selected on highlight coupon (desktop only).
     *
     * @param {string} location - Location name.
     *
     * @fires HIGHLIGHT_MEETINGS_SELECT_REGION
     */
    selectLocation(location) {
        /**
         * @event HIGHLIGHT_MEETINGS_SELECT_REGION
         * @type {object}
         * @property {string} location - Location name.
         */
        this.logEvent(EVENTS.HIGHLIGHT_MEETINGS_SELECT_REGION, {location}, ...arguments);
    }

    /**
     * Race selected on highlight coupon.
     *
     * @param {object} data - Object containing analytics data.
     * @param {string} data.raceName - Race name.
     * @param {string} data.status - Race status.
     *
     * @fires HIGHLIGHT_MEETINGS_SELECT_RACE
     */
    selectRaceOnHighlightsCoupon({raceName, status}) {
        /**
         * @event HIGHLIGHT_MEETINGS_SELECT_RACE
         * @type {object}
         * @property {string} race - Race name.
         * @property {string} status - Race status.
         */
        this.logEvent(EVENTS.HIGHLIGHT_MEETINGS_SELECT_RACE, {race: raceName, status}, ...arguments);
    }

    /**
     * Meeting selected on highlight coupon (desktop only).
     *
     * @param {string} meetingName - Meeting name.
     *
     * @fires HIGHLIGHT_MEETINGS_SELECT_MEETING
     */
    selectMeetingOnHighlightsCoupon(meetingName) {
        /**
         * @event HIGHLIGHT_MEETINGS_SELECT_MEETING
         * @type {object}
         * @property {string} meeting - Meeting name.
         */
        this.logEvent(EVENTS.HIGHLIGHT_MEETINGS_SELECT_MEETING, {meeting: meetingName}, ...arguments);
    }

    /**
     * Time period filter changed on meetings coupon.
     *
     * @param {object} data - Object containing analytics data.
     * @param {string} data.day - Selected filter value.
     * @param {string} data.location - Location name.
     *
     * @fires RACE_MEETINGS_DAY_CHANGED
     */
    changeMeetingsDay({day, location}) {
        /**
         * @event RACE_MEETINGS_DAY_CHANGED
         * @type {object}
         * @property {string} day - Selected filter value.
         * @property {string} location - Location name.
         */
        this.logEvent(EVENTS.RACE_MEETINGS_DAY_CHANGED, {day, location}, ...arguments);
    }

    /**
     * Race selected on meetings coupon.
     *
     * @param {object} data - Object containing analytics data.
     * @param {string} data.raceName - Race name.
     * @param {string} data.status - Race status.
     *
     * @fires RACE_MEETINGS_SELECT_RACE
     */
    selectRaceOnMeetingsCoupon({raceName, status}) {
        /**
         * @event RACE_MEETINGS_SELECT_RACE
         * @type {object}
         * @property {string} race - Race name.
         * @property {string} status - Race status.
         */
        this.logEvent(EVENTS.RACE_MEETINGS_SELECT_RACE, {race: raceName, status}, ...arguments);
    }

    /**
     * Meeting selected on meetings coupon (desktop only).
     *
     * @param {string} meetingName - Meeting name.
     *
     * @fires RACE_MEETINGS_SELECT_MEETING
     */
    selectMeetingOnMeetingsCoupon(meetingName) {
        /**
         * @event RACE_MEETINGS_SELECT_MEETING
         * @type {object}
         * @property {string} meeting - Meeting name.
         */
        this.logEvent(EVENTS.RACE_MEETINGS_SELECT_MEETING, {meeting: meetingName}, ...arguments);
    }

    /**
     * Future race selected.
     *
     * @param {string} raceName - Selected race name.
     *
     * @fires FUTURE_RACING_COUPON_SELECTED
     */
    selectRaceOnFutureCoupon(raceName) {
        /**
         * @event FUTURE_RACING_COUPON_SELECTED
         * @type {object}
         * @property {string} race - Selected race name.
         */
        this.logEvent(EVENTS.FUTURE_RACING_COUPON_SELECTED, {race: raceName}, ...arguments);
    }

    /**
     * Bet history print.
     *
     * @fires BET_HISTORY_PRINT
     */
    betHistoryPrint() {
        /**
         * @event BET_HISTORY_PRINT
         */
        this.logEvent(EVENTS.BET_HISTORY_PRINT, ...arguments);
    }

    /**
     * My account view change.
     *
     * @param {string} tab - The tab name.
     *
     * @fires MY_ACCOUNT_TAB_CLICKED
     */
    myAccountViewChange(tab) {
        /**
         * @event MY_ACCOUNT_TAB_CLICKED
         * @type {object}
         * @property {string} tab - The tab name.
         */
        this.logEvent(EVENTS.MY_ACCOUNT_TAB_CLICKED, {tab}, ...arguments);
    }

    /**
     * Change market in race card.
     *
     * @param {string} racingMarketType - racing market type
     *
     * @fires RACE_CARD_MARKET_CHANGED
     */
    changeMarketRaceCard(racingMarketType) {
        const RACING_MARKET_TYPES = {
            WIN_OR_EACHWAY_MARKET: 'Win or each way',
            FORECAST_MARKET: 'Tricast',
            TRICAST_MARKET: 'Forecast',
        };
        const market = RACING_MARKET_TYPES[racingMarketType];
        /**
         * @event RACE_CARD_MARKET_CHANGED
         * @type {object}
         * @property {string} market - racing market type ("Win or each way", "Tricast" or "Forecast").
         */
        this.logEvent(EVENTS.RACE_CARD_MARKET_CHANGED, {market}, ...arguments);
    }

    /**
     * Handles the event of changing tabs in the bonus section on a desktop device in the My Account modal.
     *
     * @param {string} tab - The name of the tab.
     *
     * @fires BONUSES_TAB_INTERACTION
     */
    bonusesTabChanged(tab) {
        /**
         * @event BONUSES_TAB_INTERACTION
         * @type {object}
         * @property {string} tab - The name of the tab.
         */
        this.logEvent(EVENTS.BONUSES_TAB_INTERACTION, {tab}, ...arguments);
    }

    /**
     * Add sport to favourites.
     *
     * @param {string} sportId - The sport ID.
     *
     * @fires FAVOURITES_SPORT_ADDED
     */
    addSportToFavourites(sportId) {
        /**
         * @event FAVOURITES_SPORT_ADDED
         * @type {object}
         * @property {string} sport - The sport ID.
         */
        this.logEvent(EVENTS.FAVOURITES_SPORT_ADDED, {sport: sportId}, ...arguments);
    }

    /**
     * Remove sport from favourites.
     *
     * @param {string} sportId - The sport ID.
     *
     * @fires FAVOURITES_SPORT_REMOVED
     */
    removeSportFromFavourites(sportId) {
        /**
         * @event FAVOURITES_SPORT_REMOVED
         * @type {object}
         * @property {string} sport - The sport ID.
         */
        this.logEvent(EVENTS.FAVOURITES_SPORT_REMOVED, {sport: sportId}, ...arguments);
    }

    /**
     * Bankers toggled on/off.
     *
     * @param {boolean} active - True if bankers is toggled on, false if toggled off.
     *
     * @fires BANKERS_SWITCH_TOGGLED
     */
    bankersSwitchToggled(active) {
        const newValue = active ? 'On' : 'Off';
        /**
         * @event BANKERS_SWITCH_TOGGLED
         * @type {object}
         * @property {string} value - "On" or "Off".
         */
        this.logEvent(EVENTS.BANKERS_SWITCH_TOGGLED, {value: newValue}, ...arguments);
    }

    /**
     * Bet set as bankers on/off.
     *
     * @param {string} betId - Id of bet.
     * @param {boolean} active - True if bet is toggled on, false if toggled off.
     *
     * @fires BANKERS_BET_TOGGLED
     */
    bankersBetToggled(betId, active) {
        const newValue = active ? 'On' : 'Off';
        /**
         * @event BANKERS_BET_TOGGLED
         * @type {object}
         * @property {string} bet_id - Id of bet.
         * @property {string} value - "On" or "Off".
         */
        this.logEvent(EVENTS.BANKERS_BET_TOGGLED, {bet_id: betId, value: newValue}, ...arguments);
    }

    /**
     * Click on event notification subscriber. Typically presented as bell icon.
     *
     * @fires NOTIFICATION_CLICKED
     */
    notificationsSubscriberClick() {
        /**
         * @event NOTIFICATION_CLICKED
         */
        this.logEvent(EVENTS.NOTIFICATION_CLICKED, ...arguments);
    }

    /**
     * Subscribe/unsubscribe to content notifications on event details page.
     *
     * @param {object} data - Object containing analytics data.
     * @param {string} data.source - Source where notifications toggle originates from.
     * @param {boolean} data.value - True if subscribed, false if unsubscribed.
     *
     * @fires PUSH_NOTIFICATIONS_SWITCH
     */
    toggleNotifications({source, value}) {
        const label = value ? 'On' : 'Off';
        /**
         * @event PUSH_NOTIFICATIONS_SWITCH
         * @type {object}
         * @property {string} source - Source where notifications toggle originates from.
         * @property {string} label - "On" or "Off".
         */
        this.logEvent(EVENTS.PUSH_NOTIFICATIONS_SWITCH, {source, label}, ...arguments);
    }

    /**
     * Click on a top league.
     *
     * @param {string} typeId - League type id.
     *
     * @fires TOP_LEAGUE_INTERACTION
     */
    topLeagueClick(typeId) {
        /**
         * @event TOP_LEAGUE_INTERACTION
         * @type {object}
         * @property {string} typeId - League type id.
         */
        this.logEvent(EVENTS.TOP_LEAGUE_INTERACTION, {typeId}, ...arguments);
    }

    /**
     * Click on a match filter.
     *
     * @param {object} data - Object containing analytics data.
     * @param {string} data.selectedFilterKey - Key of selected filter.
     * @param {string} data.sport - Sport id the filter is clicked from.
     *
     * @fires MATCHES_FILTER_INTERACTION
     */
    matchesFilterClicked({selectedFilterKey, sport}) {
        /**
         * @event MATCHES_FILTER_INTERACTION
         * @type {object}
         * @property {string} selectedFilterKey - Key of selected filter.
         * @property {string} element_id - Same as $selectedFilterKey.
         * @property {string} sport - Sport id the filter is clicked from.
         */
        this.logEvent(
            EVENTS.MATCHES_FILTER_INTERACTION,
            {selectedFilterKey, element_id: selectedFilterKey, sport},
            ...arguments
        );
    }

    /**
     * Opened Quick Start Guide from banner.
     *
     * @fires QUICK_START_GUIDE_OPENED_FROM_BANNER
     */
    quickStartGuideOpenedFromBanner() {
        /**
         * @event QUICK_START_GUIDE_OPENED_FROM_BANNER
         */
        this.logEvent(EVENTS.QUICK_START_GUIDE_OPENED_FROM_BANNER, ...arguments);
    }

    /**
     * Closed Quick Start Guide from banner close button.
     *
     * @fires QUICK_START_GUIDE_BANNER_CLOSED
     */
    quickStartGuideBannerClosed() {
        /**
         * @event QUICK_START_GUIDE_BANNER_CLOSED
         */
        this.logEvent(EVENTS.QUICK_START_GUIDE_BANNER_CLOSED, ...arguments);
    }

    /**
     * User session was lost.
     *
     * @fires UNEXPECTED_USER_SESSION_LOST
     */
    unexpectedSessionLost() {
        /**
         * @event UNEXPECTED_USER_SESSION_LOST
         */
        this.logEvent(EVENTS.UNEXPECTED_USER_SESSION_LOST, ...arguments);
    }

    /**
     * Navigation occurred.
     * Note: The landing page will be reported here on application launch.
     *
     * @param {string} url - New url.
     */
    navigation(url) {
        this.setPage(url);
    }

    /**
     * Change price type in betslip odds dropdown.
     *
     * @param {string} sportId - Sport id.
     *
     * @fires BETSLIP_PRICE_TYPE_DROPDOWN_CHANGE
     */
    betslipPriceTypeDropdownChange(sportId) {
        /**
         * @event BETSLIP_PRICE_TYPE_DROPDOWN_CHANGE
         * @type {object}
         * @property {string} sport - Sport id.
         */
        this.logEvent(EVENTS.BETSLIP_PRICE_TYPE_DROPDOWN_CHANGE, {sport: sportId});
    }

    /**
     * Click on time sorting.
     *
     * @param {string} sportId - Sport id.
     *
     * @fires TIME_SORTING_CLICKED
     */
    timeSortingClicked(sportId) {
        /**
         * @event TIME_SORTING_CLICKED
         * @type {object}
         * @property {string} sport - Sport id.
         */
        this.logEvent(EVENTS.TIME_SORTING_CLICKED, {sport: sportId});
    }

    /**
     * Click on type sorting.
     *
     * @param {string} sportId - Sport id.
     *
     * @fires TYPE_SORTING_CLICKED
     */
    typeSortingClicked(sportId) {
        /**
         * @event TYPE_SORTING_CLICKED
         * @type {object}
         * @property {string} sport - Sport id.
         */
        this.logEvent(EVENTS.TYPE_SORTING_CLICKED, {sport: sportId});
    }
}

class AnalyticsProxyFeature extends AbstractFeature {
    constructor(parent) {
        super(parent);
        this.service = null;
        this.initialized = false;
    }

    get name() {
        return 'Analytics Proxy';
    }

    beforeMojitoConfigBuild(mojitoConfig) {
        const services = ensureHierarchy(mojitoConfig, 'services.analytics.services');

        this.initialized = true;
        if (!this.service) {
            this.service = new AnalyticsProxyServiceClass();
        }

        services.default = services.default || [];
        services.native = services.native || [];
        services.default.push(this.service);
        services.native.push(this.service);

        return super.beforeMojitoConfigBuild(mojitoConfig);
    }

    setServiceImplementation(implementation) {
        if (this.initialized) throw new Error('Analytics proxy is already initialized');
        this.service = implementation;
    }

    addHandler(handlerToAdd) {
        this.service.addHandler(handlerToAdd);
    }

    removeHandler(handlerToRemove) {
        this.service.removeHandler(handlerToRemove);
    }
}

export const AnalyticsProxy = new AnalyticsProxyFeature(allFeatures);
