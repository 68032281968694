import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import MojitoCore from 'mojito/core';
import MojitoPresentation from 'mojito/presentation';
import { isEqual, isEmpty } from 'mojito/utils';
import serviceFactory from 'services/messaging/service/service-factory.js';
import MessagingTypes from 'services/messaging/types.js';
import { actions as authenticationActions } from 'services/authentication/slice.js';
import { actions as bootstrapActions } from 'services/bootstrap/slice.js';
import betslipActionTypes from 'services/betslip/action-types.js';

const log = MojitoCore.logger.get('MessagingStore');
const reduxInstance = MojitoCore.Services.redux;
const intentActions = MojitoCore.Intents.actions;
const IntentTypes = MojitoPresentation.Base.Intent.Types;
const { selectLanguage, selectApplicationMode } = MojitoCore.Services.SystemSettings.selectors;
const {
    SHOW_SIMPLE_ALERT,
    SHOW_SESSION_LIMIT_ALERT,
    SHOW_LAST_LOGIN_ALERT,
    SHOW_SIMPLE_TOAST,
    SHOW_SELECTIONS_LIMIT_TOAST,
    SHOW_DIALOG,
    HIDE_MESSAGE,
    RECEIVE_URL_TEMPLATES,
} = MessagingTypes.INCOMING_MESSAGES;

/**
 * Defines the state of the messaging store.
 *
 * @typedef MessagingState
 *
 * @property {Array} alertMessages - Alert messages list.
 * @property {Array} dialogMessages - Dialog messages list.
 * @property {Array} toastMessages - Toast messages list.
 * @property {Array} urlTemplates - Url templates list.
 *
 * @memberof Mojito.Services.Messaging
 */

/**
 * The name of the messaging store. Will be used to register in global redux store.
 *
 * @constant
 * @type {string}
 * @memberof Mojito.Services.Messaging
 */
export const STORE_KEY = 'messagingStore';

export const initialState = {
    alertMessages: [],
    dialogMessages: [],
    toastMessages: [],
    urlTemplates: [],
};

// Try to check for equality by id first,
// if id is undefined - check by reference.
const equal = (messageA, messageB) => {
    const hasId = messageA.id && messageB.id;
    return hasId ? messageA.id === messageB.id : isEqual(messageA, messageB);
};

const indexOfMessage = (messages, message) =>
    messages.findIndex(existing => equal(existing, message));

const hasMessage = (messages, message) => indexOfMessage(messages, message) !== -1;

const removeMessage = (messages, message) => {
    const index = indexOfMessage(messages, message);
    if (index !== -1) {
        messages.splice(index, 1);
    }
    return messages;
};

export const { reducer, actions } = createSlice({
    name: 'messaging',
    initialState: initialState,
    reducers: {
        messageReceive(state, { payload: message }) {
            switch (message.type) {
                case SHOW_SIMPLE_ALERT:
                case SHOW_SESSION_LIMIT_ALERT:
                case SHOW_LAST_LOGIN_ALERT:
                    state.alertMessages.push(message);
                    break;
                case SHOW_SIMPLE_TOAST:
                case SHOW_SELECTIONS_LIMIT_TOAST:
                    // Do not add toast that already exists
                    if (!hasMessage(state.toastMessages, message)) {
                        state.toastMessages.push(message);
                    }
                    break;
                case SHOW_DIALOG:
                    if (isEmpty(message.payload?.bgImageUrl)) {
                        log.error(
                            `Image url is missing for message type: ${message.type}. The message will be ignored.`
                        );
                    }
                    state.dialogMessages.push(message);
                    break;
                case HIDE_MESSAGE:
                    removeMessage(state.alertMessages, message);
                    removeMessage(state.dialogMessages, message);
                    break;
                case RECEIVE_URL_TEMPLATES:
                    state.urlTemplates = message.payload || [];
                    break;
            }
        },
        messageProcessed(state, { payload: message }) {
            switch (message.type) {
                case SHOW_SIMPLE_ALERT:
                case SHOW_SESSION_LIMIT_ALERT:
                case SHOW_LAST_LOGIN_ALERT:
                    removeMessage(state.alertMessages, message);
                    break;
                case SHOW_SIMPLE_TOAST:
                case SHOW_SELECTIONS_LIMIT_TOAST:
                    removeMessage(state.toastMessages, message);
                    break;
                case SHOW_DIALOG:
                    removeMessage(state.dialogMessages, message);
                    break;
            }
        },
        messageSend(state, { payload: message }) {
            serviceFactory.getService().sendMessage(message);
        },
        reset() {
            return { ...initialState };
        },
    },
    extraReducers: builder => {
        builder
            .addCase(authenticationActions.loginMessage, (state, { payload: message }) => {
                if (message.type === SHOW_LAST_LOGIN_ALERT || message.type === SHOW_SIMPLE_ALERT) {
                    state.alertMessages.push(message);
                }
            })
            .addCase(betslipActionTypes.selectionsLimitBreach, state => {
                const message = {
                    id: 'selections-limit',
                    type: SHOW_SELECTIONS_LIMIT_TOAST,
                };
                if (!hasMessage(state.toastMessages, message)) {
                    state.toastMessages.push(message);
                }
            })
            .addCase(intentActions.publishIntent, (state, { payload: intent }) => {
                if (intent.type === IntentTypes.SHOW_SIMPLE_TOAST) {
                    const message = {
                        type: SHOW_SIMPLE_TOAST,
                        id: intent.data?.id || intent.data?.text,
                        payload: intent.data,
                    };
                    if (!hasMessage(state.toastMessages, message)) {
                        state.toastMessages.push(message);
                    }
                }
            });
    },
});

/**
 * Subscribe to messaging service.
 *
 * @function subscribe
 * @param {{ credentials: object, options: object }} payload - Payload data needed to subscribe messaging service.
 * @returns {Mojito.Core.Services.redux.ThunkFunction} Subscribe to messages thunk.
 *
 * @memberof Mojito.Services.Messaging.actions
 */
actions.subscribe = ({ credentials, options }) => {
    return dispatch => {
        serviceFactory
            .getService()
            .connect(credentials, options, message => dispatch(actions.messageReceive(message)));
    };
};

/**
 * Unsubscribe from messaging service.
 *
 * @function unsubscribe
 * @returns {Mojito.Core.Services.redux.ThunkFunction} Subscribe to messages thunk.
 *
 * @memberof Mojito.Services.Messaging.actions
 */
actions.unsubscribe = () => {
    return () => {
        serviceFactory.getService().disconnect();
    };
};

reduxInstance.actionListener.startListening({
    actionCreator: authenticationActions.loginSuccess,
    effect: ({ payload }, listenerApi) => {
        const { userInfo } = payload;
        if (!userInfo) {
            return false;
        }
        const credentials = { username: userInfo.userName };
        // If there is no appropriate language in the list below, IMS uses "EN" as a fallback
        // On the moment of writing IMS supports the following list of languages:
        // AF, AR, BG, CA, CH, CS, DA, DE, EL, EN, ES, ET, FI, FR, GA, GR, HE, HI,
        // HR, HU, HY, ID, IT, JA, KA, KO, LT, LV, MS, MY, NL, NO, PL, PT, RO, RU,
        // SK, SL, SR, SV, TH, TR, UK, VI,
        // ZH-CN, ZH-SG, ZH-TW, DE-AT, DE-CH, EN-AU, EN-CA, EN-GB, EN-ZA, ES-CR,
        // ES-GT, ES-MX, ES-PA, FR-CA, JA-2, PT-BR
        const options = {
            language: selectLanguage(listenerApi.getState()),
            applicationMode: selectApplicationMode(),
        };

        listenerApi.dispatch(actions.subscribe({ credentials, options }));
    },
});

reduxInstance.actionListener.startListening({
    matcher: isAnyOf(bootstrapActions.dispose, authenticationActions.disposeSession),
    effect: (action, listenerApi) => listenerApi.dispatch(actions.unsubscribe()),
});

/**
 * Called on message send.
 *
 * @function messageSend
 * @type {Mojito.Core.Services.redux.ActionCreator}
 * @param {Mojito.Services.Messaging.types.Message} data - Data of sending message.
 *
 * @memberof Mojito.Services.Messaging.actions
 */

/**
 * Called on message received.
 *
 * @function messageReceive
 * @type {Mojito.Core.Services.redux.ActionCreator}
 * @param {Mojito.Services.Messaging.types.Message} data - Data of received message.
 *
 * @memberof Mojito.Services.Messaging.actions
 */

/**
 * Called when messaged has been processed by the user. Typically handled by stores for state cleanup.
 *
 * @function messageProcessed
 * @type {Mojito.Core.Services.redux.ActionCreator}
 * @param {Mojito.Services.Messaging.types.Message} data - Data of processed message.
 *
 * @memberof Mojito.Services.Messaging.actions
 */

/**
 * Called when messaging service is disconnected.
 *
 * @function disconnected
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @memberof Mojito.Services.Messaging.actions
 */

/**
 * Reset action returns messaging state to initial value.
 *
 * @function reset
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @memberof Mojito.Services.Messaging.actions
 */

reduxInstance.injectReducer(STORE_KEY, reducer);
