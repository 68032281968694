import {PALETTE_IDS} from '#core/application/modules/ids.js';

import {COLOR, FONT} from '../theme.js';

export function getBonusesConfig() {
    const CUSTOM_CONFIG = {
        ActiveBonuses: {
            header: {
                container: {
                    minHeight: '40px',
                },
            },
        },
        BonusItems: {
            expireDateContainer: {
                style: {
                    padding: '1px 0px 8px',
                },
            },
            amountContainer: {
                style: {
                    paddingTop: 8,
                },
            },
        },
    };

    return {
        custom: {
            [PALETTE_IDS.BONUSES]: CUSTOM_CONFIG,
        },
        palette: {
            [PALETTE_IDS.BONUSES]: {
                PROMOTION_CODE_INPUT: {
                    INPUT_TEXT: COLOR.BRAND.FOREGROUND2,
                    INPUT_LABEL_TEXT: COLOR.BRAND.FOREGROUND2,
                    INPUT_BORDER_COLOR: COLOR.BRAND.FOREGROUND4,
                    INPUT_FOCUSED_BORDER_COLOR: COLOR.BRAND.FOREGROUND4,
                },
                BONUSES_SECTION_TITLE_TEXT: {
                    fontSize: FONT.SIZE_SMALL,
                    color: COLOR.BRAND.FOREGROUND5,
                    textTransform: 'capitalize',
                },
                BONUS_ITEM_STATUS_TEXT: COLOR.BRAND.FOREGROUND2,
                BONUSES_NOT_FOUND_TEXT: COLOR.BRAND.FOREGROUND5,
                BONUS_ITEM_STEP_NUMBER_LABEL_BG: COLOR.BRAND.FOREGROUND3,
                BONUS_ITEM_STEP_NUMBER_LABEL_BORDER_COLOR: COLOR.BRAND.FOREGROUND3,
                BONUS_ITEM_STEP_STATUS_TEXT: {
                    color: COLOR.BRAND.FOREGROUND2,
                    fontWeight: FONT.WEIGHT_NORMAL,
                },
                BONUS_ITEM_STEP_SEPARATOR_COLOR: COLOR.BRAND.FOREGROUND3,
            },
        },
    };
}
