import { useCallback, useLayoutEffect, useState, useRef } from 'react';
import MojitoServices from 'mojito/services';
import MojitoPresentation from 'mojito/presentation';
import SelectionButton from 'modules/selection-button/index.jsx';
import withEventCardSkeleton from 'modules/event-cards/skeleton/with-event-card-skeleton.js';
import { useEventItem, useMarketItems, useEventsLoadDone } from 'modules/common/hooks';

const { FlexPane, AbsolutePane, Button, Image, Text, EventTimingStatus } =
    MojitoPresentation.Components;
const { IntentActionTrigger } = MojitoPresentation.Base.Intent;
const { EventFormatter } = MojitoPresentation.Utils;
const { utils: EventUtils } = MojitoServices.SportsContent.Events;

const useHasMoreThanOneLine = lineHeight => {
    const ref = useRef();
    const [hasMoreThanOneLine, setHasMoreThanOneLine] = useState(false);
    useLayoutEffect(() => {
        const clientHeight = ref?.current?.clientHeight;
        setHasMoreThanOneLine(clientHeight > lineHeight);
    }, [ref?.current?.clientHeight, lineHeight]);
    return [ref, hasMoreThanOneLine];
};

const getSelectionNameHeight = (selectionNameConfig = {}) => {
    const oneLineHeightPx =
        selectionNameConfig.style?.lineHeight || selectionNameConfig.style?.fontSize;
    return (oneLineHeightPx && oneLineHeightPx.match(/\d+/)) || 0; // 0 will make market name truncate on one line.
};

const useLoadDone = props => useEventsLoadDone([props.eventId]);

const SelectionCard = withEventCardSkeleton(
    props => {
        const {
            eventId,
            marketId,
            marketName,
            selectionId,
            selectionName,
            mojitoTools,
            shouldRequestData,
            elementRef,
        } = props;
        const { config, appContext, stringResolver, l10n, style } = mojitoTools;
        const event = useEventItem(eventId, shouldRequestData);
        const market = useMarketItems(eventId, [marketId], shouldRequestData)[0];

        const selection = EventUtils.getSelectionFromMarket(market, selectionId);
        const handicapLabel = selection?.handicapLabel ? ` ${selection?.handicapLabel}` : '';
        const basePrice =
            selection?.basePrice &&
            EventFormatter.formatSelectionPrice([selection.basePrice], l10n);

        const onEventClick = useCallback(() => {
            IntentActionTrigger.showSportEvent(event.sportId, event.id);
            appContext.analyticsEmitter.emitAnalytics('eventCardClicked', event.canonicalName);
        }, [event?.sportId, event?.id, event?.canonicalName, appContext.analyticsEmitter]);

        const [selectionNameRef, hasMoreThanOneLine] = useHasMoreThanOneLine(
            Number(getSelectionNameHeight(config.selectionName))
        );

        if (!event) {
            return null;
        }

        const shouldShowLiveLabel = !basePrice && event.details.inPlay;
        const cardConfig = basePrice ? config.boostedEventCard : config.genericEventCard;
        const sportImage = config.sportIcons[event.sportId] || config.sportIcons.defaultIcon;
        const showBackground =
            config.showBackgroundSportImage &&
            !config.noBackgroundImageSports.includes(event.sportId);

        const altText =
            appContext.sportName(event.sportId) || stringResolver.resolveString('$GENERIC.DEFAULT');
        return (
            <FlexPane
                class="ta-SelectionCard"
                config={cardConfig.container}
                elementRef={elementRef}
            >
                {showBackground && (
                    <BackgroundSportImage {...props} basePrice={basePrice} image={sportImage} />
                )}
                <Button onClick={onEventClick} config={cardConfig.clickableArea}>
                    <FlexPane config={config.headerContainer}>
                        {config.showSportIcon && (
                            <Image src={sportImage} config={config.sportIcon} alt={altText} />
                        )}
                        <Text config={cardConfig.title}>{event.name}</Text>
                        {shouldShowLiveLabel ? (
                            <Text config={config.headerLiveLabel}>
                                {stringResolver.resolveString('$SELECTION_CARD.LIVE')}
                            </Text>
                        ) : (
                            <EventTimingStatus config={config.headerTime} eventItem={event} />
                        )}
                    </FlexPane>
                    <FlexPane config={config.selectionInfo}>
                        <Text config={config.selectionName} textRef={selectionNameRef}>
                            {selectionName}
                            {handicapLabel}
                        </Text>
                        <Text
                            config={
                                !hasMoreThanOneLine
                                    ? config.marketName
                                    : style.marketNameOneRowTruncation
                            }
                        >
                            {marketName}
                        </Text>
                    </FlexPane>
                </Button>
                {basePrice ? (
                    <BoostedSelectionContent
                        {...props}
                        event={event}
                        market={market}
                        selection={selection}
                        basePrice={basePrice}
                    />
                ) : (
                    <SelectionContent
                        {...props}
                        event={event}
                        market={market}
                        selection={selection}
                    />
                )}
            </FlexPane>
        );
    },
    useLoadDone,
    config => config.genericEventCard.skeletonLoader
);

const BoostedSelectionContent = props => {
    const { mojitoTools, basePrice } = props;
    const { config, stringResolver } = mojitoTools;

    return (
        <FlexPane config={config.priceBoostContainer}>
            <FlexPane config={config.priceBoostTitleAndImageContainer}>
                <FlexPane config={config.priceBoostImageContainer}>
                    <Image config={config.priceBoostImage} alt="Price boost image" />
                </FlexPane>
                <Text config={config.priceBoostTitle}>
                    {stringResolver.resolveString('$SELECTION_CARD.PRICE_BOOST_TITLE')}
                </Text>
            </FlexPane>
            <FlexPane config={config.basePriceContainer}>
                <FlexPane config={config.basePriceInfoContainer}>
                    <Text config={config.basePriceTitle}>
                        {stringResolver.resolveString('$SELECTION_CARD.BASE_PRICE_TITLE')}
                    </Text>
                    <Text config={config.basePriceAmount}>{basePrice}</Text>
                </FlexPane>
                <Image config={config.basePriceImage} alt="Base price image" />
            </FlexPane>
            <SelectionContent {...props} />
        </FlexPane>
    );
};

const getBogusSelection = () => {
    return { id: 'BOGUS-selection', type: 'UNKNOWN' };
};

const SelectionContent = props => {
    const { event, market = {}, selection, mojitoTools } = props;
    const { config } = mojitoTools;
    return (
        <FlexPane config={config.selection}>
            <SelectionButton
                config={config.selectionButton}
                selection={selection || getBogusSelection()}
                event={event}
                marketStatus={market.status}
                marketType={market.type}
                marketId={market.id}
            />
        </FlexPane>
    );
};

const BackgroundSportImage = props => {
    const { mojitoTools, image, basePrice } = props;
    const { config } = mojitoTools;
    const containerConfig = basePrice
        ? config.boostedBackgroundImageContainer
        : config.genericBackgroundImageContainer;
    const imageConfig = basePrice
        ? config.boostedBackgroundSportImage
        : config.genericBackgroundSportImage;
    return (
        <AbsolutePane config={containerConfig}>
            <Image src={image} config={imageConfig} alt="Background" />
        </AbsolutePane>
    );
};

SelectionCard.getStyle = (config, applicationMode, merge) => {
    return {
        marketNameOneRowTruncation: merge(config.marketName, { ellipsisOnLineNumber: 1 }),
    };
};

export default SelectionCard;
