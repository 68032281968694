import Hooks from 'core/presentation/hooks';
import PropTypes from 'prop-types';
import AppContext, { ContextBuilder } from './index';

/**
 * Default Extending strategy implementation, used to merge current context with
 * extending `value` object.
 *
 * @function defaultExtendingStrategy
 * @param {Mojito.Core.Presentation.AppContext.AppContextDefinition} context - Application context object of type {@link Mojito.Core.Presentation.AppContext.ContextDefinition|AppContextDefinition}.
 * @param {object} value - Object to be merged into the application context.
 *
 * @returns {Mojito.Core.Presentation.AppContext.AppContextDefinition} The extended context.
 * @memberof Mojito.Core.Presentation.AppContext
 */
const defaultExtendingStrategy = (context, value) => ({ ...context, ...value });

/**
 * Extending strategy implementation, used to extend `uiContextPath` property in mojito context.
 * The `value` argument is string that will be added to the end of the current `uiContextPath`.
 * E.g., if current path is `_mobile_` and extending `value` is `inplay`, the resulting path will be `_mobile_inplay`.
 *
 * @function extendUiContextPath
 * @param {Mojito.Core.Presentation.AppContext.AppContextDefinition} context - Application context object of type {@link Mojito.Core.Presentation.AppContext.ContextDefinition|AppContextDefinition}.
 * @param {string} value - String used to extend the uiContextPath.
 *
 * @returns {Mojito.Core.Presentation.AppContext.AppContextDefinition} The extended context.
 * @memberof Mojito.Core.Presentation.AppContext
 */
export function extendUiContextPath(context, value) {
    if (!context) {
        return new ContextBuilder().withUiContextPath(value);
    }
    const path = context.uiContextPath;
    const uiContextPath = `${path}_${value}`;
    return { ...context, uiContextPath };
}

/**
 * Extending strategy function.
 *
 * @callback extendingStrategy
 * @property {Mojito.Core.Presentation.AppContext.AppContextDefinition} context - Application context object of type {@link Mojito.Core.Presentation.AppContext.ContextDefinition|AppContextDefinition}.
 * @property {*} value - Object or primitive type to extend context.
 *
 * @memberof Mojito.Core.Presentation.AppContext
 */

/**
 * The AppContextExtender functional component is used to extend application context with `value` prop according to
 * provided `extendingStrategy`. If the strategy is not provided the `defaultExtendingStrategy` is used.
 *
 * @example <caption>Usage of AppContextExtender. `InPlaySideMenuView` component and all its children will receive an extended application context with `myNewProperty: 'test'`.</caption>
 *  import React from 'react';
 *  import MojitoCore from 'mojito/core';
 *  import MojitoPresentation from 'mojito/presentation';
 *  const InPlaySideMenuView = MojitoPresentation.Components.InPlaySideMenuView;
 *
 *  const AppContextExtender = MojitoCore.Presentation.AppContext.ContextExtender;
 *  const InPlaySideMenuControllerView = () => (
 *      <AppContextExtender value={{ myNewProperty: 'test' }}>
 *          <InPlaySideMenuView />
 *      </AppContextExtender>
 *  );
 *
 * @function AppContextExtender
 * @memberof Mojito.Core.Presentation.AppContext
 *
 * @param {*} props - Component properties.
 * @param {Function} [props.extendingStrategy = Mojito.Core.Presentation.AppContext.defaultExtendingStrategy] - Function strategy that will be used to extend context.
 * @param {*} props.value - The value used to extend the current application context.
 * @param {node} props.children - Children that will inherit the extended context.
 *
 * @returns {React.ReactElement} React element ready to be rendered.
 */
export default function AppContextExtender(props) {
    const context = Hooks.useAppContext();
    const extendedContext = Hooks.useContextExtender(
        context,
        props.value,
        props.extendingStrategy || defaultExtendingStrategy
    );

    return <AppContext.Provider value={extendedContext}>{props.children}</AppContext.Provider>;
}

AppContextExtender.propTypes = {
    extendingStrategy: PropTypes.func,
    value: PropTypes.any.isRequired,
    children: PropTypes.node.isRequired,
};
