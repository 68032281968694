import {isLightColor, lightenDarkenColor, resolveBackground} from '#core/utils/color-utils.js';

import {registerTileSchema} from '#core/application/schema-registry.js';

import DROPDOWN_MENU from './dropdown-menu.palette-schema.yaml';

registerTileSchema(DROPDOWN_MENU);

// used only inside primary/secondary/tertiary dropdown generators, DO NOT use it anywhere else
export function generateDropdownMenu(TILE) {
    const {FONT} = TILE;

    const baseBackground = TILE.DROPDOWN_MENU_ITEM_BG;
    const selectedBackground = TILE.DROPDOWN_MENU_ITEM_SELECTED_BG;

    return {
        menuContainer: {
            style: {
                boxShadow: `0 1px 4px 0 rgba(0, 0, 0, 0.5)`,
            },
        },
        subHeaderText: {
            container: {
                padding: '8px 0',
                ...resolveBackground(baseBackground),
            },
            style: {
                ...TILE.DROPDOWN_MENU_SUBHEADER_TEXT,
            },
        },
        menuItemText: {
            truncate: true,
        },
        menuItem: {
            style: {
                base: {
                    fontSize: FONT.SIZE_MEDIUM,
                    fontWeight: FONT.WEIGHT_NORMAL,
                    ...resolveBackground(baseBackground),
                    ...TILE.DROPDOWN_MENU_ITEM_TEXT,
                    ...(TILE.DROPDOWN_MENU_ITEM_WITH_SEPARATOR && {
                        borderWidth: '0 0 1px 0',
                        borderStyle: 'solid',
                        borderColor: TILE.DROPDOWN_MENU_ITEM_DIVIDER_COLOR,
                        maxWidth: '100%',
                    }),
                },
                active: {
                    ...resolveBackground(selectedBackground),
                    ...(TILE.DROPDOWN_MENU_ITEM_WITH_SEPARATOR && {borderColor: 'transparent'}), // so that items didn't jump
                    ...TILE.DROPDOWN_MENU_ITEM_SELECTED_TEXT,
                },
                hover: {
                    ...resolveBackground(
                        lightenDarkenColor(selectedBackground, isLightColor(selectedBackground, true) ? 5 : -5)
                    ),
                    ...(TILE.DROPDOWN_MENU_ITEM_WITH_SEPARATOR && {borderColor: 'transparent'}), // so that items didn't jump
                    ...TILE.DROPDOWN_MENU_ITEM_SELECTED_TEXT,
                },
            },
        },
    };
}
