import { useCallback, useEffect, useMemo, useState } from 'react';
import { isEmpty, noop, pick } from 'mojito/utils';
import MojitoCore from 'mojito/core';
import MojitoServices from 'mojito/services';
import MojitoPresentation from 'mojito/presentation';
import { useEventsLoadDone, useContentPreload } from 'modules/common/hooks';
import EventListUtils from 'modules/event-list/utils.js';
import { useSelector } from 'react-redux';

const { useArrayComparator } = MojitoPresentation.Hooks;
const { utils: MarketGroupUtils } = MojitoServices.SportsContent.MarketGroups;
const { useResizeDetector } = MojitoCore.Base.resizeDetector;
const { createMarketInfo, createGameLineMarketInfo } = MarketGroupUtils;
const NumberUtils = MojitoCore.Base.NumberUtils;
const { escapePixels } = NumberUtils;
const SINGLE_MARKET_COLUMN = 1;
const { isShallowEqual } = MojitoCore.Base.objUtils;
const { selectEvents } = MojitoServices.SportsContent.Events.selectors;
const { DATA_TYPES } = MojitoServices.SportsContent.Events.descriptors;
const { EVENT_MARKET_CHUNK } = DATA_TYPES;
const EMPTY_ARRAY = [];

/**
 * Event list module related hooks.
 *
 * @name Hooks
 * @memberof Mojito.Modules.EventList
 */

/**
 * Hook evaluates the amount of applicable market columns which fit into specific container based on a container width
 * and provided EventListMarketsSection config.
 *
 * @function useMarketColumnCount
 *
 * @param {Mojito.Core.Services.Config.ConfigObject} config - Config object of type EventListMarketsSection.
 * @param {boolean} [isGameLineSport = false] - True if sport markets should be presented in game line form. Usually, for american sports.
 *
 * @returns {{elementRef: object, sportId: string}} Market columns count object including elementRef to be applied on desired markets container.
 * @memberof Mojito.Modules.EventList.Hooks
 */
export const useMarketColumnCount = (config, isGameLineSport = false) => {
    // No need to listen to resize for mobile devices, this will save us for few additional re-renders.
    const ignoreResize = config.maxMarketColumnCount === 1;
    const { width: marketsElementWidth, elementRef } = useResizeDetector(noop, [], ignoreResize);
    const count = useMemo(() => {
        const { marketColumnMinWidth, maxMarketColumnCount } = config;
        const { itemSpacing } = config.container;
        if (isGameLineSport) {
            return SINGLE_MARKET_COLUMN;
        }

        const evaluateMinRequiredWidth = columnCount => {
            const spacingCount = columnCount - 1;
            return (
                columnCount * escapePixels(marketColumnMinWidth) +
                spacingCount * escapePixels(itemSpacing)
            );
        };

        let feasibleColumnCount = maxMarketColumnCount;
        let minRequiredWidth = evaluateMinRequiredWidth(feasibleColumnCount);
        while (minRequiredWidth > marketsElementWidth && feasibleColumnCount > 1) {
            feasibleColumnCount--;
            minRequiredWidth = evaluateMinRequiredWidth(feasibleColumnCount);
        }

        return feasibleColumnCount;
    }, [marketsElementWidth, config, isGameLineSport]);

    return { elementRef, count };
};

/**
 * Hook evaluates selected market option and provides callback to be passed to the market switcher component.
 * Triggering callback will lead to selectedSwitchers re-evaluation and component re-render.
 *
 * @function useMarketSwitchers
 *
 * @param {Array} options - List of market options to pick default from.
 * @param {number} switcherCount - The number of available market switchers.
 *
 * @returns {{onMarketSwitch: Function, selectedSwitchers: Array<string>}} Market columns count object.
 * @memberof Mojito.Modules.EventList.Hooks
 */
export const useMarketSwitchers = (options, switcherCount) => {
    const [manuallySelectedOptions, setManuallySelectedOptions] = useState();
    const isOptionsChanged = useArrayComparator(options);

    useEffect(() => {
        if (isOptionsChanged(options)) {
            setManuallySelectedOptions(undefined);
        }
    }, [options, isOptionsChanged]);

    const onMarketSwitch = useCallback(
        (option, switcherIndex) => {
            const updatedSelection = { ...manuallySelectedOptions, [switcherIndex]: option };
            setManuallySelectedOptions(updatedSelection);
        },
        [manuallySelectedOptions]
    );

    const defaultSelectedOptions = useMemo(() => {
        if (!switcherCount || isEmpty(options)) {
            return;
        }
        return options.slice(0, switcherCount);
    }, [options, switcherCount]);

    const selectedOptions = useMemo(
        () => Object.values({ ...defaultSelectedOptions, ...(manuallySelectedOptions || {}) }),
        [defaultSelectedOptions, manuallySelectedOptions]
    );

    return { onMarketSwitch, selectedSwitchers: selectedOptions };
};

/**
 * A Hook that splits a list of events into batches of a given (customisable) size and provides the events that should be visible, at any given time.
 *
 * @function useLoadEventsInBatches
 *
 * @param {Array<Mojito.Services.EventGroups.types.EventInfo>} events - A list of EventInfo objects, to be split in batches.
 * @param {Function} onLastBatchScrollCallback - Callback invoked when the user reaches the last batch while scrolling down (used to hide the loader when there are no more batches to display).
 * @param {number} [batchSize = 50] - Number of items in each batch.
 *
 * @returns {{ visibleEvents: Array<Mojito.Services.EventGroups.types.EventInfo>, onFetchMore: Function  }} Returns the list of visible items and a callback that should be called on each batch scroll.
 * @memberof Mojito.Modules.EventList.Hooks
 */
export const useLoadEventsInBatches = (events, onLastBatchScrollCallback, batchSize = 50) => {
    const [lastVisibleBatchIndex, setLastVisibleBatchIndex] = useState(0);
    const [batchesIndexes, setBatchesIndexes] = useState([0]);

    const splitToBatches = events =>
        events.reduce((resultArray, item, index) => {
            const chunkIndex = Math.floor(index / batchSize);
            if (!resultArray[chunkIndex]) {
                resultArray[chunkIndex] = [];
            }
            resultArray[chunkIndex].push(item);
            return resultArray;
        }, []);

    const batches = splitToBatches(events);

    const onFetchMore = useCallback(() => {
        const nextIndex = lastVisibleBatchIndex + 1;
        setLastVisibleBatchIndex(nextIndex);
        setBatchesIndexes([...batchesIndexes, nextIndex]);
        const isLastBatchLoaded = nextIndex === batches.length;
        if (isLastBatchLoaded) {
            onLastBatchScrollCallback();
        }
    }, [lastVisibleBatchIndex, batchesIndexes, onLastBatchScrollCallback, batches.length]);

    const visibleEvents = useMemo(
        () => batches.filter((item, id) => batchesIndexes.includes(id)).flat(),
        [batchesIndexes, batches]
    );

    return { visibleEvents, onFetchMore };
};

/**
 * Hook resolves market infos from <code>eventItem.marketLines</code> object using a set of provided marketOptions.
 *
 * @function useMarketInfos
 *
 * @param {Array<string>} marketOptions - List of market options which identifies selected market types. Will be associated with corresponding market line to load actual market.
 * @param {Map<string, Mojito.Services.SportsContent.Events.types.MarketLine>} marketLines - The map of market lines. Key is line type and value is market line that contains actual market id.
 * @param {boolean} isGameLineMode - If true then hook will produce market infos of type
 * {@link Mojito.Services.SportsContent.MarketGroups.types.AGGREGATED_MARKET_TYPE.GAME_LINE|GAME_LINE} otherwise there will be objects of type {@link Mojito.Services.SportsContent.MarketGroups.types.AGGREGATED_MARKET_TYPE.GENERIC|GENERIC}.
 *
 * @returns {Array<Mojito.Services.SportsContent.MarketGroups.types.AggregatedMarketInfo>} List of market info objects.
 * @memberof Mojito.Modules.EventList.Hooks
 */
export const useMarketInfos = (marketOptions, marketLines, isGameLineMode) => {
    return useMemo(() => {
        const convertToMarketInfo = getMarketInfoConverter(marketLines, isGameLineMode);
        const marketInfos = marketOptions.map(convertToMarketInfo);
        return EventListUtils.cleanUpFallbackMarkets(marketInfos);
    }, [marketLines, marketOptions, isGameLineMode]);
};

/**
 * Object type which contains information of event market lines.
 *
 * @typedef MarketLineInfo
 *
 * @property {string} eventId - Event id.
 * @property {Map<string, Mojito.Services.SportsContent.Events.types.MarketLine>} marketLines - Market lines for event.
 *
 * @memberof Mojito.Modules.EventList.Hooks
 */

/**
 * Object type which contains information of event market lines.
 *
 * @typedef EventMarketInfo
 *
 * @property {string} eventId - Event id.
 * @property {Array<Mojito.Services.SportsContent.MarketGroups.types.AggregatedMarketInfo>} marketInfos - Market infos per event.
 *
 * @memberof Mojito.Modules.EventList.Hooks
 */

/**
 * Hook is doing the same as useMarketInfos except that it resolves markets infos for multiple events at once.
 *
 * @function useEventsMarketInfos
 *
 * @param {Array<string>} marketOptions - List of market options which identifies selected market types. Will be associated with corresponding market line to load actual market.
 * @param {Array<Mojito.Modules.EventList.Hooks.MarketLineInfo>} marketLineInfos - The market line objects per event.
 * @param {boolean} isGameLineMode - If true then hook will produce market infos of type
 * {@link Mojito.Services.SportsContent.MarketGroups.types.AGGREGATED_MARKET_TYPE.GAME_LINE|GAME_LINE} otherwise there will be objects of type {@link Mojito.Services.SportsContent.MarketGroups.types.AGGREGATED_MARKET_TYPE.GENERIC|GENERIC}.
 *
 * @returns {Array<Mojito.Modules.EventList.Hooks.EventMarketInfo>} List of market info objects per event.
 * @memberof Mojito.Modules.EventList.Hooks
 */
export const useEventsMarketInfos = (marketOptions, marketLineInfos, isGameLineMode) => {
    return useMemo(() => {
        return marketLineInfos.map(marketLineInfo => {
            const { eventId, marketLines } = marketLineInfo;
            const convertToMarketInfo = getMarketInfoConverter(marketLines, isGameLineMode);
            const marketInfos = marketOptions.map(convertToMarketInfo);
            return { eventId, marketInfos: EventListUtils.cleanUpFallbackMarkets(marketInfos) };
        });
    }, [marketLineInfos, marketOptions, isGameLineMode]);
};

/**
 * Hook used to preload event markets for provided event ids.
 * This is needed to subscribe to all markets within a coupon in one chunk which suppose to improve performance for networks with high latency.
 *
 * @function useMarketsPreload
 *
 * @param {string} instanceId - Instance id.
 * @param {string} contentType - The type of the markets content.
 * @param {Array<string>} eventIds - The list of event ids.
 * @param {Array<Mojito.Services.EventGroups.types.EventGroup>} eventGroups - Groups of events to show.
 * @param {Array<string>} selectedMarketOptions - Array defines selected market options. The index of an item in array reflects switcher index. This will be used to retrieve market ids which needs to be preload based on selected market switchers.
 * @param {boolean} isGameLineMode - True if coupon is shown in a game line mode.
 * @param {Array} deps - Reset dependencies list used to reset markets preload state and allow the same component to preload different content.
 * Can be useful when switching between sport tabs on a top sports coupon.
 *
 * @returns {boolean} Boolean indicating that preload request was triggered.
 * @memberof Mojito.Modules.EventList.Hooks
 */
export const useMarketsPreload = (
    instanceId,
    contentType,
    eventIds,
    eventGroups,
    selectedMarketOptions,
    isGameLineMode,
    deps
) => {
    const eventsLoadDone = useEventsLoadDone(eventIds);
    const eventInfos = useMemo(
        () => EventListUtils.resolveEventInfos(eventGroups, eventIds),
        [eventGroups, eventIds]
    );

    const eventItems = useSelector(
        state => (eventsLoadDone ? pick(selectEvents(state), ...eventIds) : EMPTY_ARRAY),
        isShallowEqual
    );

    const marketLineInfos = useMemo(() => {
        return eventInfos.map(eventInfo => {
            const eventItem = eventItems[eventInfo.id];
            const marketLines = isEmpty(eventInfo.marketLines)
                ? eventItem?.marketLines
                : eventInfo.marketLines;
            return { eventId: eventInfo.id, marketLines };
        });
    }, [eventItems, eventInfos]);

    const eventMarketInfos = useEventsMarketInfos(
        selectedMarketOptions,
        marketLineInfos,
        isGameLineMode
    );
    const marketIds = useMemo(() => {
        return eventMarketInfos
            .flatMap(eventInfo => eventInfo.marketInfos.flatMap(marketInfo => marketInfo.marketIds))
            .filter(Boolean);
    }, [eventMarketInfos]);
    const [preloadDone, resetMarketsPreload] = useContentPreload(
        instanceId,
        contentType,
        EVENT_MARKET_CHUNK,
        marketIds,
        deps
    );

    useEffect(() => {
        if (eventsLoadDone && isEmpty(marketIds)) {
            resetMarketsPreload();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventsLoadDone, marketIds]);

    return preloadDone;
};

const getMarketInfoConverter = (marketLines, isGameLineMode) => {
    return (marketOption, index) => {
        return isGameLineMode
            ? createGameLineMarketInfo(marketLines, marketOption)
            : createMarketInfo(marketLines, marketOption, index);
    };
};
