import { useEventItem, useMarketItems } from 'modules/common/hooks';
import MojitoPresentation from 'mojito/presentation';
import MojitoServices from 'mojito/services';
import MojitoCore from 'mojito/core';
import MatchAccaSelectionItem from 'modules/match-acca/match-acca-selection-item/index.jsx';
import BetslipModuleUtils from 'modules/betslip/utils.js';
import SelectionButton from 'modules/selection-button/index.jsx';
import SelectionsContext from 'modules/selection-button/context/selections-context.js';
import PrebuiltBetSelectionsContext from 'modules/prebuilt-bets/prebuilt-bet/context/prebuilt-bet-selections-context.js';
import { useCallback, useMemo } from 'react';

const AnalyticsContextExtender = MojitoCore.Presentation.AnalyticsContext.ContextExtender;
const AnalyticsContextBuilder = MojitoCore.Presentation.AnalyticsContext.ContextBuilder;
const analyticsContext = new AnalyticsContextBuilder().withAnalyticsPath('PBBB').build();

const { IntentActionTrigger } = MojitoPresentation.Base.Intent;
const { FlexPane, AbsolutePane, Image, Button, Text, DateTime } = MojitoPresentation.Components;
const { types: BetslipTypes } = MojitoServices.Betslip;

const PrebuiltBet = props => {
    const {
        eventId,
        marketId,
        shouldRequestData,
        mojitoTools: { config, l10n },
    } = props;

    const event = useEventItem(eventId, shouldRequestData);
    const market = useMarketItems(eventId, [marketId], shouldRequestData)[0];
    const selection = market?.selections[0];

    const selectionsContext = useMemo(
        () => new PrebuiltBetSelectionsContext(market?.preBuiltBetBuilderParts),
        [market?.preBuiltBetBuilderParts]
    );

    const onEventClick = useCallback(() => {
        IntentActionTrigger.showSportEvent(event.sportId, event.id);
    }, [event?.sportId, event?.id]);
    const sportImage = config.sportIcons[event?.sportId] || config.sportIcons.defaultIcon;

    if (!market || !event || !selection) {
        return null;
    }
    return (
        <FlexPane class="ta-PrebuiltBet" config={config.genericEventCard.container}>
            {config.showBackgroundSportImage && (
                <BackgroundSportImage {...props} image={sportImage} />
            )}
            <Button
                onClick={onEventClick}
                disabled={!config.showCardHeader}
                config={config.genericEventCard.clickableArea}
            >
                {config.showCardHeader && (
                    <FlexPane class="ta-PrebuiltBetHeader" config={config.headerContainer}>
                        <Image config={config.matchAccaIcon} />
                        <FlexPane config={config.headerTextContainer}>
                            <Text config={config.eventName}>{event.name}</Text>
                            <FlexPane config={config.timeAndTypeContainer}>
                                <Text config={config.startTime}>
                                    <DateTime
                                        config={config.startTimeFormat}
                                        dateTime={event.startTime}
                                    />
                                </Text>
                                <Text config={config.typeName}>{` - ${event.typeName}`}</Text>
                            </FlexPane>
                        </FlexPane>
                    </FlexPane>
                )}
                <FlexPane config={config.matchAccaSelectionsContainer}>
                    <MatchAccaSelections
                        parts={market.preBuiltBetBuilderParts}
                        l10n={l10n}
                        suspendedParts={[]}
                        config={config.matchAccaSelectionItem}
                    />
                </FlexPane>
            </Button>
            <AnalyticsContextExtender value={analyticsContext}>
                <SelectionsContext.Provider value={selectionsContext}>
                    <SelectionContent
                        {...props}
                        event={event}
                        market={market}
                        selection={selection}
                    />
                </SelectionsContext.Provider>
            </AnalyticsContextExtender>
        </FlexPane>
    );
};

const SelectionContent = props => {
    const { event, market = {}, selection, mojitoTools } = props;
    if (!selection) {
        return null;
    }
    const { config } = mojitoTools;
    return (
        <FlexPane config={config.selectionContentContainer}>
            <SelectionButton
                config={config.selectionButton}
                selection={selection}
                event={event}
                marketStatus={market.status}
                marketType={market.type}
                marketId={market.id}
                bettingContext={BetslipTypes.BETTING_CONTEXT.PRE_BUILT_MATCH_ACCA_BET}
            />
        </FlexPane>
    );
};

const BackgroundSportImage = props => {
    const { mojitoTools, image } = props;
    const { config } = mojitoTools;
    return (
        <AbsolutePane config={config.genericBackgroundImageContainer}>
            <Image src={image} config={config.genericBackgroundSportImage} />
        </AbsolutePane>
    );
};

const MatchAccaSelections = ({ parts, l10n, config }) =>
    parts.map((part, i, row) => {
        const showSeparator = i + 1 !== row.length;
        const selectionLabel = BetslipModuleUtils.resolveNameAndHandicapLabel(part, l10n);

        return (
            <MatchAccaSelectionItem
                config={config}
                key={`${part.marketName}-${part.selectionName}`}
                showSeparator={showSeparator}
                selectionLabel={selectionLabel}
                marketName={part.marketName}
            />
        );
    });

export default PrebuiltBet;
