import MojitoServices from 'mojito/services';

import {mergeToHierarchy} from '#core/utils/config-utils.js';

import {AbstractFeature, allFeatures} from '#core/application/abstract-feature.js';

import SCHEMA from './cashout.schema.yaml';

let MojitoGenBetIntegration = null;

class CashoutFeature extends AbstractFeature {
    get name() {
        return 'Cashout';
    }

    get configSchema() {
        return SCHEMA;
    }

    async beforeMojito() {
        if (GENBET_BASED) {
            MojitoGenBetIntegration = await import(
                /* webpackMode: "eager" */
                'mojito/genbet-integration'
            );
        }
        return super.beforeMojito(); // This Promise will auto-unwrap in this async function
    }

    beforeMojitoConfigBuild(mojitoConfig) {
        mergeToHierarchy(mojitoConfig, 'services.betsService.cashout', {
            service: GENBET_BASED ? MojitoServices.Bets.pushCashoutService : MojitoServices.Bets.pollCashoutService,
            dataMapper: GENBET_BASED ? MojitoGenBetIntegration.default.Cashout.cashoutOffersMapper : undefined,

            pollIntervalSec: this.config.pollIntervalSec,
            maxBetsPerRequest: this.config.maxBetsPerRequest,
        });

        mergeToHierarchy(mojitoConfig, 'services.stores.bets', {
            allowCashout: true, // default is 'false', so doing nothing means leaving default value
        });

        return super.beforeMojitoConfigBuild(mojitoConfig);
    }
}

export const Cashout = new CashoutFeature(allFeatures);
