import PropTypes from 'prop-types';
import OddsBoostNotificationImpl from './odds-boost-notification.jsx';
import MojitoCore from 'mojito/core';

const { UIView } = MojitoCore.Presentation;

/**
 * The view for bet info.
 *
 * @class OddsBoostNotification
 * @memberof Mojito.Presentation.Components
 */

/**
 * OddsBoostNotification component prop types.
 *
 * @property {Mojito.Services.Bets.types.Bet} bet - The bet information.
 *
 * @memberof Mojito.Presentation.Components.OddsBoostNotification
 */
const propTypes = {
    bet: PropTypes.object.isRequired,
};

export default UIView('OddsBoostNotification', OddsBoostNotificationImpl, propTypes);
