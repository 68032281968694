/**
 * Class defining generic lottery data descriptors.
 *
 * @class LotteryDataDescriptor
 * @name descriptor
 * @memberof Mojito.Services.Lottery
 */
export default class LotteryDataDescriptor {
    /**
     * Factory method for creating a generic lottery descriptor.
     *
     * @param {string} lotteryId - Id of lottery.
     *
     * @returns {{dataType: string, lotteryIds: Array<string>}} Generic lottery descriptor.
     *
     * @function Mojito.Services.Lottery.descriptor#createLotteryDescriptor
     * @static
     */
    static createLotteryDescriptor(lotteryId) {
        return LotteryDataDescriptor.createLotteriesDescriptor([lotteryId]);
    }

    /**
     * Factory method for creating a generic lottery descriptor for multiple lotteries.
     *
     * @param {Array<string>} lotteryIds - Ids of lotteries.
     *
     * @returns {{dataType: string, lotteryIds: Array<string>}} Generic lottery descriptor.
     *
     * @function Mojito.Services.Lottery.descriptor#createLotteriesDescriptor
     * @static
     */
    static createLotteriesDescriptor(lotteryIds) {
        return {
            dataType: LotteryDataDescriptor.DATA_TYPES.LOTTERY,
            lotteryIds: lotteryIds,
        };
    }
}

/**
 * System unique data descriptor types.
 *
 * @typedef DATA_TYPES
 * @property {string} LOTTERY - Lottery data descriptor type.
 * @memberof Mojito.Services.Lottery.descriptor
 */
LotteryDataDescriptor.DATA_TYPES = {
    LOTTERY: 'lottery',
    LOTTERY_CHUNK: 'lottery-chunk',
};
