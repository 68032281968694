import {PALETTE_IDS} from '#core/application/modules/ids.js';

import {COLOR} from '../theme.js';

export function getMatchAccaConfig() {
    return {
        palette: {
            [PALETTE_IDS.MATCH_ACCA]: {
                MATCH_ACCA_SELECTIONS_BAR_BG: COLOR.BRAND.BACKGROUND4,
                MATCH_ACCA_SELECTIONS_BAR_COUNTER_BUTTON_TEXT: COLOR.BRAND.FOREGROUND1,
            },
            [PALETTE_IDS.MARKET_INSTRUCTIONS]: {
                MARKET_INSTRUCTIONS_CONTAINER_BG: COLOR.BASE.PAGE,
                MARKET_INSTRUCTIONS_CONTENT_BG: COLOR.BRAND.BACKGROUND4,
                MARKET_INSTRUCTIONS_BET_BUILDER_ICON_COLOR: COLOR.BRAND.CTA2,
            },
            [PALETTE_IDS.MATCH_ACCA_SLIP]: {
                MATCH_ACCA_SLIP_ADD_TO_BETSLIP_BORDER_COLOR: COLOR.BRAND.ACCENT1,
            },
        },
    };
}
