import { createSlice, isPlain } from '@reduxjs/toolkit';
import MojitoCore from 'mojito/core';
import MojitoPresentation from 'mojito/presentation';
import MojitoServices from 'mojito/services';
import { pick } from 'mojito/utils';
import { getRouteResolver } from './selectors';

const { externalNavigationFactory } = MojitoServices.ExternalNavigation;
const IntentTypes = MojitoPresentation.Base.Intent.Types;
const intentActions = MojitoCore.Intents.actions;
const authenticationActions = MojitoServices.Authentication.actions;
const reduxInstance = MojitoCore.Services.redux;
const log = MojitoCore.logger.get('Router');

/**
 * Defines the state of router store.
 *
 * @typedef RouterState
 *
 * @property {string} route - Route path.
 * @property {boolean} redirect - Flag indicates if last navigation was triggered as redirect.
 *
 * @memberof Mojito.Applications.Common.Router
 */

/**
 * The name of router store. Will be used to register in global redux store.
 *
 * @constant
 * @type {string}
 * @memberof Mojito.Applications.Common.Router
 */

export const STORE_KEY = 'application/router';

export const INITIAL_STATE = {
    route: '',
    redirect: false,
    protectRoutes: true,
};

export const { reducer, actions } = createSlice({
    name: 'router',
    initialState: INITIAL_STATE,
    reducers: {
        redirectChange(state, { payload }) {
            state.redirect = payload;
        },
        routeInit(state, { payload }) {
            state.route = payload;
        },
        routeChange(state, { payload }) {
            state.route = payload;
        },
        routeProtection: (state, { payload }) => {
            state.protectRoutes = payload;
        },
        reset() {
            return { ...INITIAL_STATE };
        },
    },
});

const isInternalRouteUrl = intentData =>
    intentData.windowName === undefined &&
    getRouteResolver().isInternalUrl(intentData.url) &&
    !intentData.foreignWindow;

const resolveRouteFromIntent = intentData => {
    const isComplexIntent = isPlain(intentData) && !!intentData.type;
    return isComplexIntent ? getRouteResolver().getRoute(intentData) : intentData;
};

const triggerExternalNavigation = intentData => {
    const externalNavigationPayload = {
        ...pick(intentData, 'url', 'windowName', 'windowFeatures'),
        modal: intentData.foreignWindow,
    };
    const externalNavigationService = externalNavigationFactory.getService();
    externalNavigationService.navigate(externalNavigationPayload);
};

/**
 * Router store related actions.
 *
 * @name actions
 * @memberof Mojito.Applications.Common.Router
 */

/**
 * Can be set with router config through payload.
 *
 * @function handleConfigure
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @memberof Mojito.Applications.Common.Router.actions
 */

/**
 * Handling redirect change.
 *
 * @function redirectChange
 * @type {Mojito.Core.Services.redux.ActionCreator}
 * @param {boolean} payload - Flag indicating if navigation was triggered as redirect.
 *
 * @memberof Mojito.Applications.Common.Router.actions
 */

/**
 * Handling route init on application start.
 *
 * @function routeInit
 * @type {Mojito.Core.Services.redux.ActionCreator}
 * @param {string} payload - Route path.
 *
 * @memberof Mojito.Applications.Common.Router.actions
 */

/**
 * Handling route change.
 *
 * @function routeChange
 * @type {Mojito.Core.Services.redux.ActionCreator}
 * @param {string} payload - Route path.
 *
 * @memberof Mojito.Applications.Common.Router.actions
 */

/**
 * Reset Router state to initial value.
 *
 * @function reset
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @memberof Mojito.Applications.Common.Router.actions
 */

reduxInstance.actionListener.startListening({
    actionCreator: intentActions.publishIntent,
    effect: (action, listenerApi) => {
        const { type: intentType, data: intentData } = action.payload;
        const { dispatch } = listenerApi;

        switch (intentType) {
            case undefined:
                log.error('Undefined intent type. Routing not possible');
                break;
            case IntentTypes.VIEW: {
                dispatch(actions.redirectChange(intentData?.isRedirect ?? false));
                const route = resolveRouteFromIntent(intentData);
                if (route) {
                    dispatch(actions.routeChange(route));
                } else {
                    log.warn('No route found for intent: ', intentData);
                }
                break;
            }
            case IntentTypes.OPEN_URL: {
                if (!intentData?.url) {
                    log.warn(`Intent type: ${intentType} requires a url!`);
                    return;
                }
                if (isInternalRouteUrl(intentData)) {
                    if (getRouteResolver().isAbsoluteUrl(intentData.url)) {
                        const pathname = new URL(intentData.url).pathname;
                        dispatch(actions.routeChange(pathname));
                    } else {
                        dispatch(actions.routeChange(intentData.url));
                    }
                } else {
                    triggerExternalNavigation(intentData);
                }
                break;
            }
            default:
                break;
        }
    },
});

reduxInstance.actionListener.startListening({
    actionCreator: authenticationActions.logoutSuccess,
    effect: (action, listenerApi) => {
        listenerApi.dispatch(actions.routeProtection(true));
    },
});

reduxInstance.actionListener.startListening({
    actionCreator: authenticationActions.loginSuccess,
    effect: (action, listenerApi) => {
        listenerApi.dispatch(actions.routeProtection(false));
    },
});

reduxInstance.injectReducer(STORE_KEY, reducer);
