export const DebugFlags = {
    no3rdParties: false,
    wrapPhrases: false,
    keysAsPhrases: false,
    allLogs: false,
    drawOverlay: false,
    analyzeConfigs: false,
    measurePerformance: false,
    logNamespaces: '',
};

const SESSION_STORAGE_CONFIG_KEY = 'dbx.app-config';

function getFromSessionStorage() {
    let sessionConfig = {};
    try {
        sessionConfig = JSON.parse(window.sessionStorage.getItem(SESSION_STORAGE_CONFIG_KEY) || '{}');
    } catch (e) {
        // do nothing if error
    }
    return sessionConfig.debug || {};
}

function getParamFromURL(paramName, url = window.location.href) {
    url = new URL(url);
    let parameter = url.searchParams.get(paramName);
    return parameter;
}

export function setDebugFlagsFromConfiguration(config) {
    Object.assign(DebugFlags, config);
}

export function getDebugFlagsFromUrlParams() {
    const result = {};
    if (getParamFromURL('_no3rdParties') !== null) {
        result.no3rdParties = true;
    }
    if (getParamFromURL('_wrapPhrases') !== null) {
        result.wrapPhrases = true;
    }
    if (getParamFromURL('_keysAsPhrases') !== null) {
        result.keysAsPhrases = true;
    }
    if (getParamFromURL('_allLogs') !== null) {
        result.allLogs = true;
    }
    if (getParamFromURL('_analyzeConfigs') !== null) {
        result.analyzeConfigs = true;
    }
    return result;
}

setDebugFlagsFromConfiguration(getFromSessionStorage());
setDebugFlagsFromConfiguration(getParamFromURL());
