import { invert, mapValues } from 'mojito/utils';

/**
 * Bet type constants.
 *
 * @class BetsTypes
 * @name types
 * @memberof Mojito.Services.Bets
 */
export default class BetsTypes {}

/**
 * Bet status.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Services.Bets.types
 */
BetsTypes.BET_STATUS = {
    /** All bets. */
    ALL: 'ALL',
    /** Bet is still open. */
    OPEN: 'OPEN',
    /** Bet contains at least one won bet. */
    WON: 'WON',
    /** Bet contains only lost bets. */
    LOST: 'LOST',
    /** Bet was voided - This typically happens after placing bets like DNB (Draw No Bet) or Asian 0/-1/+2, resulting in the stake being returned to the user. */
    VOID: 'VOID',
    /** Bet is cashed out. */
    CASHED_OUT: 'CASHED_OUT',
    /** Bet is placed. */
    PLACED: 'PLACED',
};

/**
 * UK bet types.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Services.Bets.types
 */
BetsTypes.UK_BET_TYPE = {
    PAT: 'PAT',
    TRX: 'TRX',
    LY15: 'LY15',
    YAN: 'YAN',
    LY31: 'LY31',
    CAN: 'CAN',
    LY63: 'LY63',
    HNZ: 'HNZ',
    SHNZ: 'SHNZ',
    GOL: 'GOL',
    SGOL: 'SGOL',
};

/**
 * AutoCashout rule update status.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Services.Bets.types
 */
BetsTypes.AUTO_CASHOUT_UPDATE_STATUS = {
    PENDING: 'pending',
    SUCCEEDED: 'succeeded',
    FAILED: 'failed',
};

/**
 * AutoCashout rule update type.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Services.Bets.types
 */
BetsTypes.AUTO_CASHOUT_UPDATE_TYPE = {
    CREATE: 'create',
    REMOVE: 'remove',
};

/**
 * Bet types. Contains all entries from UK_BET_TYPE enum.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Services.Bets.types
 */
BetsTypes.BET_TYPE = {
    SINGLE: 'SGL',
    GROUPED_SINGLES: 'GRP_SGL',
    DBL: 'DBL',
    TBL: 'TBL',
    ACC4: 'ACC4',
    ACC5: 'ACC5',
    ACC6: 'ACC6',
    ACC7: 'ACC7',
    ACC8: 'ACC8',
    ACC9: 'ACC9',
    ACC10: 'ACC10',
    ACC11: 'ACC11',
    ACC12: 'ACC12',
    ACC13: 'ACC13',
    ACC14: 'ACC14',
    ACC15: 'ACC15',
    ACC16: 'ACC16',
    ACC17: 'ACC17',
    ACC18: 'ACC18',
    ACC19: 'ACC19',
    ACC20: 'ACC20',
    ACC21: 'ACC21',
    ACC22: 'ACC22',
    ACC23: 'ACC23',
    ACC24: 'ACC24',
    ACC25: 'ACC25',
    ...BetsTypes.UK_BET_TYPE,
    UNKNOWN: 'UNKNOWN',
};

/**
 * Leg sorts.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Services.Bets.types
 */
BetsTypes.LEG_SORT = {
    UNKNOWN: 'UNKNOWN',
    DEFAULT: 'DEFAULT',
    MATCH_ACCA: 'MATCH_ACCA',
    BET_BUILDER: 'BET_BUILDER',
    FORECAST: 'FORECAST',
    TRICAST: 'TRICAST',
    COMBINATION_FORECAST: 'COMBINATION_FORECAST',
    COMBINATION_TRICAST: 'COMBINATION_TRICAST',
    REVERSE_FORECAST: 'REVERSE_FORECAST',
};

/**
 * Bet way.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Services.Bets.types
 */
BetsTypes.BET_WAY = {
    EACH_WAY: 'EACH_WAY',
    WIN: 'WIN',
    PLACE: 'PLACE',
};

/**
 * Multicast (Forecast / Tricast) types.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Services.Bets.types
 */
BetsTypes.MULTICAST_TYPE = {
    /** Straight forecast or tricast bet type. */
    STRAIGHT: 'STRAIGHT',
    /** Combination forecast or tricast bet type. */
    COMBINATION: 'COMBINATION',
};

const RANKED_BY_INDEX = [
    'EMPTY',
    'SGL',
    'DBL',
    'TBL',
    'ACC4',
    'ACC5',
    'ACC6',
    'ACC7',
    'ACC8',
    'ACC9',
    'ACC10',
    'ACC11',
    'ACC12',
    'ACC13',
    'ACC14',
    'ACC15',
    'ACC16',
    'ACC17',
    'ACC18',
    'ACC19',
    'ACC20',
    'ACC21',
    'ACC22',
    'ACC23',
    'ACC24',
    'ACC25',
];

/**
 * Ordered list of bet types.
 * The index of the element in the list corresponds to the amount
 * of bet parts added to betslip.
 *
 * @readonly
 * @enum {Array}
 * @memberof Mojito.Services.Bets.types
 */
BetsTypes.RANKED_BY_INDEX = RANKED_BY_INDEX;

/**
 * Map of bet type to ranked index.
 * Key is bet type and value is number which represents
 * the amount of bet parts added to the betslip.
 *
 * @readonly
 * @enum {Map<Mojito.Services.Bets.types.BET_TYPE, number>}
 * @memberof Mojito.Services.Bets.types
 */
BetsTypes.BET_TYPE_TO_INDEX = mapValues(invert(RANKED_BY_INDEX), Number);

/**
 * Bonus types.
 *
 * @enum {string}
 * @readonly
 * @memberof Mojito.Services.Bets.types
 */
BetsTypes.BONUS_TYPE = {
    ACCA_BOOST: 'ACCA_BOOST',
    ACCA_INSURANCE: 'ACCA_INSURANCE',
    FREEBET: 'FREEBET',
    EVENT_TRIGGERED: 'EVENT_TRIGGERED',
};

/**
 * Bet early cashout availability.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Services.Bets.types
 */
BetsTypes.CASHOUT_AVAILABILITY = {
    /** Cashout is available. */
    AVAILABLE: 'AVAILABLE',
    /** Cashout is unavailable. */
    UNAVAILABLE: 'UNAVAILABLE',
    /** Cashout is inactive. Usually treated as temporary unavailable. */
    INACTIVE: 'INACTIVE',
};

/**
 * Bet availability reason.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Services.Bets.types
 */
BetsTypes.CASHOUT_AVAILABILITY_REASON = {
    /** Bet has already been settled. */
    BET_IS_SETTLED: 'BET_IS_SETTLED',
};

/**
 * Bet early cashout status.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Services.Bets.types
 */
BetsTypes.CASHOUT_STATUS = {
    /** Cashout is idle. Ready to be cashed out. */
    IDLE: 'IDLE',
    /** Cashout requires confirmation. */
    CONFIRMATION: 'CONFIRMATION',
    /** Cashout is in process. */
    PENDING: 'PENDING',
    /** Cashout has been accepted. */
    ACCEPTED: 'ACCEPTED',
    /** Cashout failed. */
    FAILED: 'FAILED',
};

/**
 * Bet early cashout error codes.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Services.Bets.types
 */
BetsTypes.CASHOUT_ERROR_CODE = {
    /** The price has been changed during cashout process. */
    PRICE_CHANGED: 'PRICE_CHANGED',
    /** Cashout has been forbidden. */
    FORBIDDEN: 'CASHOUT_FORBIDDEN',
    /** Cashout turns out to be not active any more. */
    INACTIVE: 'CASHOUT_INACTIVE',
};

/**
 * Transport protocols used to retrieve cashout offer values.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Services.Bets.types
 */
BetsTypes.CASHOUT_PROTOCOLS = {
    /** Cashout offers retrieved using polling. */
    POLL: 'POLL',
    /** Cashout offers retrieved using pushing. Typically through WebSocket. */
    PUSH: 'PUSH',
};

/**
 * Filter criteria types.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Services.Bets.types
 */
BetsTypes.FILTER_CRITERIA_TYPE = {
    STATUS: 'STATUS',
    LAST_BETS_AMOUNT: 'LAST_BETS_AMOUNT',
    TIME_FRAME: 'TIME_FRAME',
};

/**
 * The name of the open bets store. Will be used to register in global redux store.
 *
 * @constant
 * @type {string}
 * @memberof Mojito.Services.Bets.types
 */
BetsTypes.OPEN_BETS_STORE_KEY = 'openBetsStore';

/**
 * Price types.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Services.Bets.types
 */
BetsTypes.PRICE_TYPE = {
    /** Live price. */
    LP: 'LP',
    /** Starting price. Typically applicable for racing sports. */
    SP: 'SP',
    /** Best price. Typically applicable for racing sports. */
    BP: 'BP',
    /** Handicap price. Typically applicable for teaser bets where user has possibility to "buy" handicap. */
    HCAP: 'HCAP',
};

/**
 * Get bets payload.
 *
 * @typedef GetBetsPayload
 * @type {object}
 * @property {Mojito.Services.Bets.types.Pagination} pagination - Pagination.
 * @property {Mojito.Services.Bets.types.FilterCriteria} filterCriteria - Filter criteria.
 * @property {string} recentAmount - Recent amount.
 * @property {Mojito.Services.UserSettings.types.ODDS_FORMAT} oddsFormat - Odds format.
 *
 * @memberof Mojito.Services.Bets.types
 */

/**
 * Refresh bets payload.
 *
 * @typedef RefreshBetsPayload
 * @type {object}
 * @property {Mojito.Services.Bets.types.FilterCriteria} filterCriteria - Filter criteria.
 * @property {Mojito.Services.UserSettings.types.ODDS_FORMAT} oddsFormat - Odds format.
 * @property {string} dataHash - Data hash.
 *
 * @memberof Mojito.Services.Bets.types
 */

/**
 * Bet History Filter.
 *
 * @typedef BetHistoryFilter
 * @type {object}
 * @property {Mojito.Services.Bets.types.FILTER_CRITERIA_TYPE} type - Filter criteria type.
 * @property {BetsTimeFrameOptions | BetsLastBetsOptions | StatusFilterOptions } options - Filter options.
 *
 * @memberof Mojito.Services.Bets.types
 */

/**
 * Status Filter Options. Options of status filter including the value of bet to filter bet history.
 *
 * @typedef StatusFilterOptions
 * @type {object}
 * @property {Mojito.Services.Bets.types.BET_STATUS} value - Value of status.
 *
 * @memberof Mojito.Services.Bets.types
 */

/**
 * Bets Time Frame Options. Options of time frame in bets range filters.
 *
 * @typedef BetsTimeFrameOptions
 * @type {object}
 * @property {string} value - Value of time frame.
 * @property {string} duration - The duration of the timeSpan (any integer is valid). Ignored if timeSpan is set to 'today'.
 * @property {number} timespan - The timeSpan. Possible values are today, day/days, week/weeks, month/months and year/years.
 *
 * @memberof Mojito.Services.Bets.types
 */

/**
 * Bets Last Bets Options. Options of last bets in bets range filters including the amount of bets to be shown.
 *
 * @typedef BetsLastBetsOptions
 * @type {object}
 * @property {string} value - Amount of last bets.
 *
 * @memberof Mojito.Services.Bets.types
 */

/**
 * Open bets response.
 *
 * @typedef OpenBetsResponse
 * @type {object}
 * @property {Array<Mojito.Services.Bets.types.Bet>} bets - Bets.
 * @property {Mojito.Services.Bets.types.Pagination} pagination - Pagination information.
 * @property {string} dataHash - Unique data hash.
 *
 * @memberof Mojito.Services.Bets.types
 */

/**
 * Pagination object. Can be used along with request to get limited array of items, for example '/bets'.
 *
 * @typedef Pagination
 * @type {object}
 * @property {number} itemsPerPage - Items per page.
 * @property {number} page - Current page.
 * @property {number} totalItems - Total number of items.
 * @property {number} totalPages - Total number of pages.
 * @property {boolean} hasMoreData - True if user is able to fetch next page.
 *
 * @memberof Mojito.Services.Bets.types
 */

/**
 * Filter criteria object.
 *
 * @typedef FilterCriteria
 * @type {object}
 * @property {Date} from - From date.
 * @property {Date} to - To date.
 * @property {Array<Mojito.Services.Bets.types.BET_STATUS>} filters - Filters.
 *
 * @memberof Mojito.Services.Bets.types
 */

/**
 * Bet.
 *
 * @typedef Bet
 * @readonly
 * @type {object}
 * @property {string} id - Bet id.
 * @property {Mojito.Services.Bets.types.BET_TYPE} betType - Bet type.
 * @property {Mojito.Services.Bets.types.BET_STATUS} betStatus - Bet status.
 * @property {number} numberOfLines - Number of lines.
 * @property {Array<Mojito.Services.Bets.types.Leg>} legs - Legs.
 * @property {Mojito.Services.Bets.types.Funds} funds - Funds.
 * @property {Array<Mojito.Services.Bets.types.Bonus>} bonus - Bonus.
 * @property {string} odds - Odds.
 * @property {string} exactOdds - Odds.
 * @property {Mojito.Services.Bets.types.PRICE_TYPE} priceType - Price type.
 * @property {Date} placementTime - Placement time.
 * @property {Mojito.Services.Common.types.CHANNEL} channel - Channel.
 * @property {object} integrationProperties - Integration properties.
 * @property {boolean} locked - Is the given bet locked.
 *
 * @memberof Mojito.Services.Bets.types
 */

/**
 * Leg.
 *
 * @typedef Leg
 * @readonly
 * @type {object}
 * @property {Mojito.Services.Bets.types.LEG_SORT} legSort - Leg sort.
 * @property {Mojito.Services.Bets.types.BET_STATUS} status - Leg status.
 * @property {Mojito.Services.Bets.types.BET_WAY} betWay - Bet way.
 * @property {Mojito.Services.Bets.types.EachWayTerms} eachWayTerms - Each way terms.
 * @property {string} odds - Odds.
 * @property {Mojito.Services.Bets.types.PRICE_TYPE} priceType - Price type.
 * @property {string} baseOdds - Base odds when leg is price boosted.
 * @property {Array<Mojito.Services.Bets.types.Part>} parts - Parts.
 * @property {Mojito.Services.Bets.types.LegInfo} legInfo - Leg info.
 * @property {boolean} isBanker - If leg is of type banker.
 *
 * @memberof Mojito.Services.Bets.Types
 */

/**
 * Part.
 *
 * @typedef Part
 * @readonly
 * @type {object}
 * @property {string} selectionId - Selection id.
 * @property {string} selectionName - Selection name.
 * @property {string} marketId - Market id.
 * @property {string} marketName - Market name.
 * @property {Mojito.Services.Bets.types.BET_STATUS} status - Part status.
 * @property {number} number - Number. E.g., runner number in racing event.
 * @property {Array<number>} places - Places.
 *
 * @memberof Mojito.Services.Bets.Types
 */

/**
 * Leg info.
 *
 * @typedef LegInfo
 * @readonly
 * @type {object}
 * @property {string} eventId - Event id.
 * @property {string} eventName - Event name.
 * @property {Date} eventStartTime - Event start time.
 * @property {string} sportId - Sport id.
 *
 * @memberof Mojito.Services.Bets.Types
 */

/**
 * Funds.
 *
 * @typedef Funds
 * @readonly
 * @type {object}
 * @property {string} currency - Currency.
 * @property {number} stake - Stake.
 * @property {number} stakeAfterTaxes - Stake after taxes.
 * @property {number} stakePerLine - Stake per line.
 * @property {number} stakeTax - Stake tax.
 * @property {number} payout - Contains gross payout amount.
 * @property {number} payoutTax - Payout tax.
 * @property {number} payoutNet - Contains net payout after deduction of taxes.
 * @property {number} freebetStake - Freebet stake.
 * @property {number} cashStake - Cash stake.
 * @property {Mojito.Services.Bets.types.Prewager} prewager - Prewager.
 *
 * @memberof Mojito.Services.Bets.types
 */

/**
 * Prewager.
 *
 * Prewager is bonus related money used in the bet.
 * Prewager stake returns prewager payout until bonus goal is completed, then the prewager money is converted to real money.
 *
 * @typedef Prewager
 * @readonly
 * @type {object}
 * @property {Mojito.Services.Bets.types.PrewagerAmount} stake - Bonus money staked in the bet.
 * @property {Mojito.Services.Bets.types.PrewagerAmount} payout - Payout based on the prewager stake.
 *
 * @memberof Mojito.Services.Bets.types
 */

/**
 * Prewager amount.
 *
 * @typedef PrewagerAmount
 * @readonly
 * @type {object}
 * @property {number} cash - Cash.
 * @property {number} bonus - Bonus.
 *
 * @memberof Mojito.Services.Bets.types
 */

/**
 * Bonus.
 *
 * @typedef Bonus
 * @readonly
 * @type {object}
 * @property {Mojito.Services.Bets.types.BONUS_TYPE} type - Bonus type.
 *
 * @memberof Mojito.Services.Bets.types
 */

/**
 * Each way terms.
 *
 * @typedef EachWayTerms
 * @type {object}
 * @property {number} numerator - Numerator.
 * @property {number} denominator - Denominator.
 * @property {number} places - Places.
 * @memberof Mojito.Services.Bets.types
 */

/**
 * Bet information object. Contains slim data from {@link Mojito.Services.Bets.types.Bet|Bet}
 * and can be used as descriptor for specific request, e.g., requests for early cashout options.
 *
 * @typedef BetInfo
 * @type {object}
 * @property {string} betId - Id of the bet.
 * @property {object} integrationProperties - Integration parameters. Can contain random data depending on betting platform specifics.
 * Typically, it is populated directly from {@link Mojito.Services.Bets.types.Bet.integrationProperties|integrationProperties} on a bet level.
 * @property {string} placementTime - Date when bet was placed.
 * @memberof Mojito.Services.Bets.types
 */

/**
 * Bet cash out information object.
 * Contains cash offer options for a {@link Mojito.Services.Bets.types.Bet|Bet}.
 *
 * @typedef CashoutInfo
 * @type {object}
 * @property {string} betId - Id of the bet.
 * @property {string} amount - Amount that is available for cashout.
 * @property {Mojito.Services.Bets.types.CASHOUT_AVAILABILITY} availability - Cashout availability.
 * @property {Mojito.Services.Bets.types.CASHOUT_AVAILABILITY_REASON} availabilityReason - Cashout availability reason.
 * @property {Array<Mojito.Services.Bets.types.CashoutError>} errors - List of cashout errors.
 * Usually not empty if cashout process has been failed due to business reasons.
 * @property {Mojito.Services.Bets.types.AutoCashout} autoCashout - Auto cashout information.
 * @memberof Mojito.Services.Bets.types
 */

/**
 * Auto cashout information.
 *
 * @typedef AutoCashout
 * @type {object}
 * @property {number} minAmount - Min amount that can be set for auto cashout.
 * @property {number} maxAmount - Maximum amount that can be set for auto cashout.
 * @property {number} triggerAmount - Amount that is being set for auto cashout.
 * @memberof Mojito.Services.Bets.types
 */

/**
 * Cashout error object.
 *
 * @typedef CashoutError
 * @type {object}
 * @property {Mojito.Services.Bets.types.CASHOUT_ERROR_CODE} code - Error code.
 * @property {string} message - Error message.
 * @memberof Mojito.Services.Bets.types
 */

/**
 * Message that is typically sent to betting provider API in order to
 * request cashout offers for specified bets inside <code>betInfos</code> list.
 *
 * @typedef CashoutOffersRequest
 * @type {object}
 * @property {Array<Mojito.Services.Bets.types.BetInfo>} betInfos - List of bet info objects.
 * @property {string} token - Unique token granted to user by API.
 * @memberof Mojito.Services.Bets.types
 */

/**
 * Cashout offers mapper interface.
 * The implementation typically used to map incoming and outgoing messages during communication with specific betting platform API in order to retrieve cashout offers.
 *
 * @interface ICashoutOffersMapper
 * @memberof Mojito.Services.Bets.types
 *
 * Maps CashoutOffersRequest into API specific message format.
 *
 * @function
 * @name mapRequest
 * @memberof Mojito.Services.Bets.types.ICashoutOffersMapper
 *
 * @param {Mojito.Services.Bets.types.CashoutOffersRequest} request - Offers request.
 * @returns {object} API specific message object.
 *
 * Maps cashout offer object of concrete betting platform to the list of {@link Mojito.Services.Bets.types.CashoutInfo|Bet}CashoutInfo objects.
 *
 * @function
 * @name mapResponse
 * @memberof Mojito.Services.Bets.types.ICashoutOffersMapper
 *
 * @param {object} response - Cashout offer response object to be mapped into the list of CashoutInfo's.
 * Typically comes directly from betting provider through push updates mechanism, e.g., using WebSocket.
 * @returns {Array<Mojito.Services.Bets.types.CashoutInfo>} Cashout info objects list built from `response` param.
 */

/**
 * Called on cashout values fetch success.
 *
 * @param {{cashoutInfos: Array<Mojito.Services.Bets.types.CashoutInfo>}} cashoutData - Object that contains list of cashout infos.
 * @callback Mojito.Services.Bets.types.cashoutValuesSuccess
 */

/**
 * Called on cashout offers fetch failed.
 *
 * @param {Mojito.Core.Services.Transactions.types.Error} error - Error info object.
 * @callback Mojito.Services.Bets.types.cashoutValuesFail
 */

/**
 * Configuration applicable on stores within bets service layer.
 *
 * @typedef BetsStoreConfig
 * @type {object}
 *
 * @param {boolean} [allowCashout = false] - True if cashout on bets should be allowed, false otherwise.
 * Note: this config property is applicable on {@link Mojito.Services.Bets.cashoutStore|cashoutStore} and can be used
 * to enable/disable cashout feature on client..
 *
 * @memberof Mojito.Services.Bets.types
 */

/**
 * Bet offer.
 *
 * @typedef BetOffer
 * @readonly
 * @type {object}
 * @property {string} betId - Bet id.
 * @property {string} orginialBetId - Original bet id.
 * @property {Mojito.Services.Betslip.types.PLACEMENT_STATUS} status - Place status.
 * @property {Mojito.Services.Betslip.types.OVERASK_CHECK_STATUS} checkStatus - Status to show if offer is selected before accepting.
 * @property {number} numberOfLines - Number of lines.
 * @property {string} bonusId - Bonus id.
 * @property {Array<Mojito.Services.Bets.types.LegOffer>} legs - Legs offers.
 * @property {Mojito.Services.Bets.types.Funds} funds - Funds.
 *
 * @memberof Mojito.Services.Bets.types
 */

/**
 * Leg offer.
 *
 * @typedef LegOffer
 * @readonly
 * @type {object}
 * @property {Mojito.Services.Bets.types.BET_WAY} betWay - Bet way.
 * @property {Mojito.Services.Bets.types.Odds} odds - Odds.
 * @property {Mojito.Services.Bets.types.PRICE_TYPE} priceType - Price type.
 * @property {Array<Mojito.Services.Bets.types.PartOffer>} parts - Parts offers.
 *
 * @memberof Mojito.Services.Bets.Types
 */

/**
 * Part offer.
 *
 * @typedef PartOffer
 * @readonly
 * @type {object}
 * @property {string} selectionId - Selection id.
 *
 * @memberof Mojito.Services.Bets.Types
 */

/**
 * Auto cashout status.
 *
 * @typedef AutoCashoutStatus
 * @readonly
 * @type {object}
 * @property {Mojito.Services.Bets.types.AutoCashoutProgress} progress - Auto cashout progress.
 * @property {string} amount - Amount.
 *
 * @memberof Mojito.Services.Bets.types
 */

/**
 * Auto cashout progress. Progress indicates the type of executed action and the status of its execution.
 *
 * @typedef AutoCashoutProgress
 * @readonly
 * @type {object}
 * @property {Mojito.Services.Bets.types.AUTO_CASHOUT_UPDATE_STATUS} status - Auto cashout update status.
 * @property {Mojito.Services.Bets.types.AUTO_CASHOUT_UPDATE_TYPE} type - Auto cashout update type.
 *
 * @memberof Mojito.Services.Bets.types
 */

/**
 * Payout state.
 *
 * @typedef PayoutState
 * @readonly
 * @type {object}
 * @property {string} betId - Bet id.
 * @property {Mojito.Services.Bets.types.PAYOUT_STATE} status - State of processing payout.
 * @property {Mojito.Services.Bets.types.PAYOUT_BET_ERROR_CODE} error - Error info.
 *
 * @memberof Mojito.Services.Bets.types
 */
