import EventCardsSkeletonImpl from './event-cards-skeleton.jsx';
import MojitoCore from 'mojito/core';
import PropTypes from 'prop-types';

const { UIView } = MojitoCore.Presentation;

/**
 * Event cards skeleton component used to represent loading event cards.
 *
 * @function EventCardsSkeleton
 * @memberof Mojito.Modules.EventCards
 */

/**
 * `EventCardsSkeleton` component prop types.
 *
 * @property {object | Function} [elementRef] - A ref to the rendered element.
 *
 * @memberof Mojito.Modules.EventCards.EventCardsSkeleton
 */
const propTypes = {
    elementRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

export default UIView('EventCardsSkeleton', EventCardsSkeletonImpl, propTypes);
