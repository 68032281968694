import MojitoPresentation from 'mojito/presentation';
import EventCardSkeleton from './card/index.jsx';
import { useCallback, useState } from 'react';
import { noop } from 'mojito/utils';
import { useLoadingStatusMetric } from 'modules/performance/hooks';

const { LoaderSuspense } = MojitoPresentation.Components;

export default function withEventCardSkeleton(
    EventCard,
    useLoadDone = () => false,
    loaderConfigResolver = noop
) {
    const EventCardWrapper = props => {
        const {
            mojitoTools: { config, instanceId },
            showSkeleton,
            reportLoadingStatus,
        } = props;

        const cardContentLoaded = useLoadDone(props);
        const [cardLoaded = cardContentLoaded, setCardLoaded] = useState(undefined);
        const onLoadDone = useCallback(() => setCardLoaded(true), []);
        const loaderConfig = loaderConfigResolver(config) || config.eventCard.skeletonLoader;

        const performanceElementRef = useLoadingStatusMetric(
            cardLoaded,
            reportLoadingStatus ? instanceId : undefined,
            'EventCard'
        );

        return (
            <LoaderSuspense
                config={loaderConfig}
                isContentPending={!cardLoaded && showSkeleton}
                loader={<EventCardSkeleton elementRef={performanceElementRef} />}
            >
                <EventCard {...props} onLoadDone={onLoadDone} elementRef={performanceElementRef} />
            </LoaderSuspense>
        );
    };

    return EventCardWrapper;
}
