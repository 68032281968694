import MojitoCore from 'mojito/core';
import { STORE_KEY, INITIAL_STATE } from './slice.js';
import routeResolverFactory from './route-resolver-factory.js';

const reduxInstance = MojitoCore.Services.redux;
/**
 * Router store selectors.
 *
 * @class RouterSelectors
 * @name selectors
 * @memberof Mojito.Applications.Common.Router
 */

/**
 * Selects router state.
 *
 * @function selectState
 *
 * @param {object} [state] - Application state object. If not provided then state from global {@link Mojito.Core.Services.redux|redux store} will be returned.
 * @returns {Mojito.Applications.Common.RouterState} Router state.
 * @memberof Mojito.Applications.Common.Router.selectors
 */
export const selectState = state => {
    // Should be no need to fall back to global state once fully migrated to Redux.
    const appState = state || reduxInstance.store?.getState();
    return appState?.[STORE_KEY] || INITIAL_STATE;
};

/**
 * Route resolver instance. RouteResolver config can be set through {@link Mojito.Applications.Common.Router.routeResolverFactory.setRouteResolver|set method of RouteResolverFactory}.
 * If not configured the {@link Mojito.Applications.Common.RouteResolver|default route resolver} will be used.
 *
 * @function getRouteResolver
 * @returns {Mojito.Core.Base.AbstractRouteResolver} The instance of concrete route resolver implementation.
 * @memberof Mojito.Applications.Common.Router.selectors
 */
export const getRouteResolver = () => {
    return routeResolverFactory.getRouteResolver();
};

/**
 * The latest resolved route, updated based on published 'VIEW' or 'OPEN_URL' intents.
 *
 * @function selectRoute
 * @param {object} [state] - Application state object. If not provided then state from global {@link Mojito.Core.Services.redux|redux store} will be returned.
 * @returns {string} Latest resolved route.
 * @memberof Mojito.Applications.Common.Router.selectors
 */
export const selectRoute = state => {
    return selectState(state).route;
};

/**
 * True if last navigation was triggered as redirect, false otherwise.
 *
 * @function selectIsRedirect
 * @param {object} [state] - Application state object. If not provided then state from global {@link Mojito.Core.Services.redux|redux store} will be returned.
 * @returns {boolean} Flag indicates if last navigation was triggered as redirect.
 * @memberof Mojito.Applications.Common.Router.selectors
 */
export const selectIsRedirect = state => {
    return selectState(state).redirect;
};

/**
 * True if route protection should be enabled.
 *
 * @function selectProtectRoutes
 * @param {object} [state] - Application state object. If not provided then state from global {@link Mojito.Core.Services.redux|redux store} will be returned.
 * @returns {boolean} Flag indicates if route protection should be used.
 * @memberof Mojito.Applications.Common.Router.selectors
 */
export const selectProtectRoutes = state => {
    return selectState(state).protectRoutes;
};
