import MojitoCore from 'mojito/core';
import BonusCardImpl from './bonus-card.jsx';
import PropTypes from 'prop-types';
import { noop } from 'mojito/utils';

const UIView = MojitoCore.Presentation.UIView;

/**
 * Component that represents Bonus Card information.
 *
 * @class BonusCard
 * @memberof Mojito.Modules.BonusCard
 */

/**
 * BonusCard prop types.
 *
 * @property {Array} [bonus = {}] - Object containing bonus data.
 * @property {boolean} [shouldShowRemainingAmount = false] - Flag indication whether to show remaining amount information.
 * @property {Function} [renderProgressContent = ()=>{}] - Callback that renders ProgressBar section only for prewager.
 * @property {string} [currencyCode] - User currency code.
 *
 * @memberof Mojito.Modules.BonusCard
 */

const propTypes = {
    bonus: PropTypes.object,
    shouldShowRemainingAmount: PropTypes.bool,
    renderProgressContent: PropTypes.func,
    currencyCode: PropTypes.string,
};

const defaultProps = {
    bonus: {},
    shouldShowRemainingAmount: false,
    renderProgressContent: noop,
};

export default UIView('BonusCard', BonusCardImpl, propTypes, defaultProps);
