import MojitoServices from 'mojito/services';

const {FILTER_CRITERIA_TYPE} = MojitoServices.Bets.types;

export const DEFAULT_RANGE_FILTERS = [
    {
        options: {
            translationKey: '$FILTERS.TIME_FRAME_SELECTION.OPTIONS.LAST_BETS_SELECTION',
            value: '10',
        },
        type: FILTER_CRITERIA_TYPE.LAST_BETS_AMOUNT,
    },
    {
        options: {
            translationKey: '$FILTERS.TIME_FRAME_SELECTION.OPTIONS.LAST_BETS_SELECTION',
            value: '20',
        },
        type: FILTER_CRITERIA_TYPE.LAST_BETS_AMOUNT,
    },
    {
        options: {
            translationKey: '$FILTERS.TIME_FRAME_SELECTION.OPTIONS.TIME_FRAME_SELECTION_TODAY',
            value: 'today',
            timeSpan: 'today',
        },
        type: FILTER_CRITERIA_TYPE.TIME_FRAME,
    },
    {
        options: {
            translationKey: '$FILTERS.TIME_FRAME_SELECTION.OPTIONS.TIME_FRAME_SELECTION_LAST_WEEK',
            value: 'last-week',
            duration: 1,
            timeSpan: 'week',
        },
        type: FILTER_CRITERIA_TYPE.TIME_FRAME,
    },
    {
        options: {
            translationKey: '$FILTERS.TIME_FRAME_SELECTION.OPTIONS.TIME_FRAME_SELECTION_LAST_MONTH',
            value: 'last-month',
            duration: 1,
            timeSpan: 'month',
        },
        type: FILTER_CRITERIA_TYPE.TIME_FRAME,
    },
    {
        options: {
            translationKey: '$FILTERS.TIME_FRAME_SELECTION.OPTIONS.TIME_FRAME_SELECTION_LAST_THREE_MONTHS',
            value: 'last-three-months',
            duration: 3,
            timeSpan: 'months',
        },
        type: FILTER_CRITERIA_TYPE.TIME_FRAME,
    },
].concat(
    GENBET_BASED
        ? [
              {
                  options: {
                      translationKey: '$FILTERS.TIME_FRAME_SELECTION.OPTIONS.TIME_FRAME_SELECTION_ONE_YEAR',
                      value: 'one-year',
                      duration: 1,
                      timeSpan: 'year',
                  },
                  type: FILTER_CRITERIA_TYPE.TIME_FRAME,
              },
              {
                  options: {
                      translationKey: '$FILTERS.TIME_FRAME_SELECTION.OPTIONS.TIME_FRAME_SELECTION_FIVE_YEARS',
                      value: 'five-years',
                      duration: 5,
                      timeSpan: 'year',
                  },
                  type: FILTER_CRITERIA_TYPE.TIME_FRAME,
              },
          ]
        : []
);
