import MojitoPresentation from 'mojito/presentation';
import MojitoServices from 'mojito/services';

const { FlexPane, Notification } = MojitoPresentation.Components;
const BetsUtils = MojitoServices.Bets.utils;

const OddsBoostNotification = props => {
    const { bet, mojitoTools } = props;
    const { config, stringResolver } = mojitoTools;
    const percentStr = BetsUtils.getOddsBoost(bet)?.percent;

    if (percentStr === undefined) {
        return null;
    }

    const message = percentStr
        ? stringResolver.resolveAndFormatString(
              '$ODDS_BOOST_NOTIFICATION.ODDS_BOOST_TEXT_WITH_PERCENT',
              percentStr
          )
        : stringResolver.resolveString('$ODDS_BOOST_NOTIFICATION.ODDS_BOOST_TEXT');

    return (
        <FlexPane config={config.container} class="ta-oddsBoostNotification">
            <Notification config={config.notification} class={`ta-OddsBoost`} text={message} />
        </FlexPane>
    );
};

export default OddsBoostNotification;
