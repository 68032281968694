import MojitoCore from 'mojito/core';
const UIPage = MojitoCore.Presentation.UIPage;
import BadRoutePageImpl from './bad-route-page.jsx';

/**
 * @namespace BadRoutePage
 * @memberof Mojito.Applications.Sports.Pages
 */

/**
 * The LottoPage class represents a UI page presented when a bad or invalid route is accessed.
 * <br>
 * This page is supposed to be used only for bad routes (like '/index.html/HIF').
 *
 * @class BadRoutePage
 * @extends Mojito.Core.Presentation.UIPage
 * @memberof Mojito.Applications.Sports.Pages.BadRoutePage
 */

export default UIPage('BadRoutePage', BadRoutePageImpl);
