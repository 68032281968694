import { useState, useEffect } from 'react';

/**
 * Component used to render children asynchronously and with a possible delay.
 *
 * @example <caption>Delayed rendering<br><br>Can be used to delay rendering certain large components in order to free up execution time for more time-critical tasks. A parent component may render a handful of children, but child X is not as critical as the others. By wrapping child X in PostponedRenderer, the initialization and rendering of X will be part of a new task. This task will not execute until after the current ask is finished, letting the other children initialize and render first.</caption>
 *  render() {
 *      return (
 *          <>
 *              <PostponedRenderer delay={500}>
 *                  <Child/>
 *              </PostponedRenderer>
 *              <CriticalChild/>
 *          </>
 *      );
 *  }
 *
 *
 * @example <caption>Speeding up side-effects<br><br>If a component needs to speed up execution of functions with side-effects, wrapping all children in PostponedRenderer will execute that side-effect before rendering the children, given that the side-effect can be executed at the time. If, given the code below, <code>doSomething</code> needs to be executed asynchronously but before <code>Child</code> is rendered, this is how it can be used.</caption>
 *  class ExampleView extends UIViewImplementation
 *      constructor() {
 *          setTimeout(() => {
 *              doSomething()
 *          })
 *      }
 *
 *      render() {
 *          return (
 *              <PostponedRenderer>
 *                  <Child/>
 *              </PostponedRenderer>
 *          );
 *      }
 *  }
 *
 * @function PostponedRenderer
 * @memberof Mojito.Presentation.Components.PostponedRenderer
 *
 * @param {*} props - Represents the properties passed to the component.
 * @param {number} props.delay - Determines the amount of delay, in milliseconds, before rendering the children. The default value is 0.
 * @param {node} props.children - Specifies the children elements to be rendered after the delay.
 * @returns {React.ReactElement} Returns a React element prepared for rendering.
 */

const PostponedRenderer = ({ children, delay = 0 }) => {
    const [renderedChild, setRenderedChild] = useState(null);

    useEffect(() => {
        const task = renderQueue
            .addToQueue({
                children,
                delay,
            })
            .then(setRenderedChild);

        return () => {
            task.canceled = true;
        };
    }, [children, delay]);

    return renderedChild;
};

/**
 * Class that provides a queue for managing render tasks with delays.
 * Ensures that tasks are processed sequentially with specified timeouts.
 *
 * @class RenderQueue
 * @name renderQueue
 * @memberof Mojito.Presentation.Components.PostponedRenderer
 * @private
 */
class RenderQueue {
    constructor() {
        this.renderQueue = [];
        this.isProcessing = false;
    }

    /**
     * Adds a new render task to the queue.
     *
     * @param {object} task - The render task to be added.
     * @param {node} task.children - The React component to render.
     * @param {number} task.delay - The delay in milliseconds before rendering the component.
     * @returns {Promise<node>} A promise that resolves with the child component when it's time to render.
     */
    addToQueue(task) {
        return new Promise(resolve => {
            this.renderQueue.push({ ...task, resolve, canceled: false });
            this.processQueue();
        });
    }

    /**
     * Processes the render queue.
     * Executes each task sequentially, respecting the specified delays.
     *
     * @private
     */
    async processQueue() {
        if (this.isProcessing || this.renderQueue.length === 0) {
            return;
        }

        this.isProcessing = true;

        while (this.renderQueue.length > 0) {
            const task = this.renderQueue.shift();
            const { children, delay, resolve } = task;
            if (task.canceled) {
                continue;
            }

            await new Promise(r => setTimeout(r, delay));

            if (!task.canceled) {
                resolve(children);
            }
        }

        this.isProcessing = false;
    }
}

const renderQueue = new RenderQueue();

export default PostponedRenderer;
