import { Fragment } from 'react';
import MojitoCore from 'mojito/core';
import MojitoPresentation from 'mojito/presentation';
import MojitoServices from 'mojito/services';
import BetBonusPromotions from 'modules/bet-bonus-promotions/index.jsx';
import BetViewUtils from 'modules/open-bets/bet-view/utils.js';
import MatchAccaSelectionItem from 'modules/match-acca/match-acca-selection-item/index.jsx';
import OddsBoostNotification from 'modules/odds-boost-notification/index.jsx';

const { FormattedLabelWithTotalOdds } = MojitoPresentation.Components.FormattedText;
const { UIViewImplementation } = MojitoCore.Presentation;
const { EventFormatter, OddsUtils, MarketHelper } = MojitoPresentation.Utils;
const { BettingHelper } = MojitoPresentation.Utils;
const { utils: BetsUtils, types: BetsTypes } = MojitoServices.Bets;

const {
    Text,
    FlexPane,
    Image,
    DownloadBetPdfButton,
    Button,
    ScrollPane,
    DateTime,
    BetFunds,
    ChangedValueIndicator,
    EarlyPayoutIndicator,
} = MojitoPresentation.Components;
const {
    PayableInfo,
    FreebetStakeInfo,
    CashStakeInfo,
    StakeTaxInfo,
    StakeInfo,
    PrewagerStakeInfo,
    TotalPayoutInfo,
    PayoutInfo,
} = BetFunds;
const { PRICE_TYPE } = BetsTypes;

export default class BetHistoryDetails extends UIViewImplementation {
    constructor(props) {
        super(props);
        this.state = {
            hasClickedDownloadPdf: false,
            isScrolled: false,
        };
        this.handleScroll = this.handleScroll.bind(this);
        this.onDownloadBetPdfClicked = () => {
            this.emitAnalytics('betHistoryPrint');
            this.setState({ hasClickedDownloadPdf: true });
        };
    }

    showExtraFields() {
        return this.props.bet.hasOwnProperty('settled');
    }

    handleScroll(scrollInfo) {
        if (!this.config.showCompactLayout) {
            const { scrollPosition } = scrollInfo;
            if (scrollPosition > 0 && !this.state.isScrolled) {
                this.setState({ isScrolled: true });
            } else if (scrollPosition <= 0) {
                this.setState({ isScrolled: false });
            }
        }
    }

    getHeaderStyle() {
        if (this.state.isScrolled) {
            return {
                style: {
                    ...this.config.header.style,
                    ...this.config.style.headerScrolled,
                },
            };
        }
        return this.config.header;
    }

    getBetTypeName(bet) {
        const { betType, isTeaser } = bet;
        return BettingHelper.getTypeName(betType, this.l10n, isTeaser);
    }

    resolveBetModifiers(bet) {
        return BettingHelper.getBetModifiers(bet, this.l10n);
    }

    formatEventName(startTime, eventName) {
        return EventFormatter.formatEventName(eventName, startTime);
    }

    renderTotalOdds(bet) {
        const { odds, baseOdds, priceType } = bet;
        return (
            <FormattedLabelWithTotalOdds
                odds={OddsUtils.getDisplayedOdds(odds, priceType, this.l10n)}
                baseOdds={
                    priceType !== BetsTypes.PRICE_TYPE.SP
                        ? OddsUtils.getDisplayedOdds(baseOdds, priceType, this.l10n)
                        : ''
                }
                isTotalOddsApplicable={BetsUtils.isTotalOddsApplicable(bet)}
                config={this.config.totalOdds}
            />
        );
    }

    renderOddsBoostNotification(bet, config = this.config.oddsBoostNotification) {
        return <OddsBoostNotification config={config} bet={bet} />;
    }

    renderHeader(bet) {
        const { status, betType, placementTime } = bet;
        const isEarlyPayout = BetViewUtils.isEarlyPayout(bet);
        const betTypeName = this.getBetTypeName(bet);
        const iconStatus = BetViewUtils.getStatusIcon(status);
        const isMatchAcca = BetsUtils.hasMatchAccaLeg(bet);
        const isOddsBoostBet =
            BetsUtils.hasOddsBoost(bet) && bet.betType !== BetsTypes.BET_TYPE.SINGLE;

        return (
            <FlexPane config={this.getHeaderStyle()}>
                <FlexPane config={this.config.betTypeContainer} class="ta-betHeader">
                    <Text
                        config={this.config.betTypeText}
                        class={`ta-${betType}${isMatchAcca ? ' ta-matchAcca' : ''}`}
                    >{`${betTypeName} ${this.resolveBetModifiers(bet)}`}</Text>
                    {this.renderTotalOdds(bet)}
                    {isOddsBoostBet && (
                        <OddsBoostNotification
                            config={this.config.oddsBoostNotification}
                            bet={bet}
                        />
                    )}
                    <Text config={this.config.dateText} class="ta-dateText">
                        <DateTime
                            config={this.config.betPlacementDateTimeFormat}
                            dateTime={placementTime}
                        />
                    </Text>
                </FlexPane>
                <FlexPane config={this.config.betStatusContainer} class="ta-betStatusContainer">
                    <Image config={this.config.betStatusIcon[iconStatus]} key={iconStatus} />
                    <Text config={this.config.betStatusText} class="ta-betStatus">
                        {BettingHelper.getBetStatusString(bet, this.l10n, isEarlyPayout)}
                    </Text>
                    {!this.config.showCompactLayout ? (
                        <Button
                            onClick={this.props.onCloseClick}
                            config={this.config.closeButton}
                            class="ta-closeBtn"
                        >
                            <Image config={this.config.closeIcon} />
                        </Button>
                    ) : null}
                </FlexPane>
            </FlexPane>
        );
    }

    renderBonuses(bet) {
        const { bonuses = [] } = bet;
        const { currency } = bet.funds;
        if (!bonuses.length) {
            return null;
        }

        return (
            <BetBonusPromotions
                config={this.config.betBonusPromotions}
                bonuses={bet.bonuses}
                currencyCode={currency}
            />
        );
    }

    renderBetHeaderInfo(bet) {
        const { funds, numberOfLines } = bet;
        const { stakePerLine, currency } = funds;
        const valueNotAvailable = this.resolveString('$BET_HISTORY_DETAILS.VALUE_NOT_AVAILABLE');
        const stakeLabel = BettingHelper.formatPayout(stakePerLine, valueNotAvailable, currency);
        const separator = this.resolveString('$BET_HISTORY_DETAILS.BET_SEPARATOR_SYMBOL');
        const valueSeparator = this.resolveString('$BET_HISTORY_DETAILS.VALUE_SEPARATOR');
        return (
            <FlexPane config={this.config.betHeaderInfoContainer} class="ta-betHeaderInfoContainer">
                <Text config={this.config.betLabel} class="ta-betLabel">
                    {this.resolveAndFormatString(
                        '$BET_HISTORY_DETAILS.BET_HEADER',
                        valueSeparator,
                        stakeLabel,
                        separator,
                        numberOfLines
                    )}
                </Text>
            </FlexPane>
        );
    }

    renderBetFunds(bet) {
        const { funds } = bet;
        const { currency, prewager } = funds;
        const {
            stake,
            stakeAfterTaxes,
            stakeTax,
            freebetStake,
            cashStake = 0,
            boostReturns,
        } = funds;

        return (
            <FlexPane config={this.config.betFundsContainer}>
                {stakeTax > 0 ? (
                    <PayableInfo
                        config={this.config.totalPayable}
                        value={stake}
                        currencyCode={currency}
                    />
                ) : (
                    <StakeInfo
                        config={this.config.primaryTotalStake}
                        currencyCode={currency}
                        value={stake}
                    />
                )}
                {freebetStake > 0 && (
                    <>
                        <CashStakeInfo
                            config={this.config.cashStake}
                            value={cashStake}
                            currencyCode={currency}
                        />
                        <FreebetStakeInfo
                            config={this.config.freebetStake}
                            value={freebetStake}
                            currencyCode={currency}
                        />
                    </>
                )}
                {prewager && (
                    <PrewagerStakeInfo
                        config={this.config.prewager}
                        prewager={prewager}
                        currencyCode={currency}
                    />
                )}
                {stakeTax > 0 && (
                    <>
                        <StakeTaxInfo
                            config={this.config.stakeTax}
                            value={stakeTax}
                            currencyCode={currency}
                        />
                        <StakeInfo
                            config={this.config.secondaryTotalStake}
                            currencyCode={currency}
                            value={stakeAfterTaxes}
                        />
                    </>
                )}
                <TotalPayoutInfo
                    config={this.config.totalPayoutInfo}
                    funds={{ ...funds, currency, prewager }}
                    isPotential={BetsUtils.isOpen(bet)}
                    isBoosted={BetsUtils.hasAccaBoost(bet)}
                />
                {boostReturns && (
                    <PayoutInfo
                        config={this.config.oddsBoostInfo}
                        payout={boostReturns}
                        labelKey={'$BET_FUNDS.BOOST_RETURN'}
                        currencyCode={currency}
                    />
                )}
            </FlexPane>
        );
    }

    renderSettledBetInfo(bet) {
        if (!this.showExtraFields()) {
            return null;
        }
        const settled = bet.settled;

        return (
            <FlexPane config={this.config.settleBetInfoContainer}>
                <Text class="ta-settledBetLabel" config={this.config.settleBetLabel}>
                    {this.resolveString('$BET_HISTORY_DETAILS.SETTLED')}
                </Text>
                {!!settled && (
                    <Text class="ta-settledBetValue" config={this.config.settleBetValue}>
                        <DateTime config={this.config.settledDateTimeFormat} dateTime={settled} />
                    </Text>
                )}
            </FlexPane>
        );
    }

    renderBetInfo(bet) {
        return (
            <FlexPane config={this.config.betInfo} class="ta-betInfo">
                {this.renderSettledBetInfo(bet)}
                {this.renderReceiptInfo(bet)}
                {this.renderBetHeaderInfo(bet)}
                {this.renderBetFunds(bet)}
                {this.renderBonuses(bet)}
            </FlexPane>
        );
    }

    renderLegDateTimeInformation(translation, dateTime) {
        const label = this.resolveString(translation);
        return (
            <Text config={this.config.legDateInfoText}>
                {label}
                {!!dateTime.length && (
                    <DateTime config={this.config.legDateTimeFormat} dateTime={dateTime} />
                )}
            </Text>
        );
    }

    renderDateTimeInformation(part) {
        if (!part) {
            return;
        }
        return (
            <FlexPane class="ta-legDateTimeContainer" config={this.config.legDateTimeContainer}>
                {this.renderLegDateTimeInformation('$BET_HISTORY_DETAILS.STARTED', part.started)}
                {this.renderLegDateTimeInformation('$BET_HISTORY_DETAILS.ENDED', part.ended)}
                {this.renderLegDateTimeInformation(
                    '$BET_HISTORY_DETAILS.CONFIRMED',
                    part.completed
                )}
            </FlexPane>
        );
    }

    renderNotification(leg) {
        const { bet } = this.props;
        const isEarlyPayout = !!leg.parts?.find(part => part.earlyPayout);
        const isOddsBoostBet =
            BetsUtils.hasOddsBoost(bet) && bet.betType === BetsTypes.BET_TYPE.SINGLE;

        if (!isEarlyPayout && !isOddsBoostBet) {
            return null;
        }

        return (
            <FlexPane config={this.config.notificationContainer}>
                {isEarlyPayout && (
                    <EarlyPayoutIndicator config={this.config.earlyPayoutIndicator} />
                )}
                {isOddsBoostBet && (
                    <OddsBoostNotification config={this.config.oddsBoostNotification} bet={bet} />
                )}
            </FlexPane>
        );
    }

    renderLeg(leg) {
        const { odds, baseOdds, legInfo, status, priceType } = leg;
        const { eventStartTime, eventName } = legInfo;
        const firstLegPart = BetsUtils.getFirstLegPart(leg) || {};
        const iconStatus = BetViewUtils.getStatusIcon(status);

        return (
            <FlexPane
                config={this.config.legInfo}
                class="ta-legInfo"
                key={firstLegPart.selectionId}
            >
                <FlexPane config={this.config.selectionContainer} class="ta-selectionContainer">
                    <FlexPane
                        config={this.config.selectionNameContainer}
                        class="ta-selectionNameContainer"
                    >
                        <Image config={this.config.legStatusIcon[iconStatus]} />
                        <Text config={this.config.selectionName} class="ta-selectionName">
                            {BettingHelper.getSelectionText(leg, this.l10n)}
                        </Text>
                    </FlexPane>
                    {this.renderBestOdds(priceType)}
                    {baseOdds
                        ? this.renderPriceBoostedOdds(odds, baseOdds, priceType)
                        : this.renderOdds(odds, priceType)}
                </FlexPane>
                <Text class="ta-marketName" config={this.config.marketName}>
                    {BettingHelper.getMarketNameOrEachWayLabel(
                        leg,
                        this.l10n,
                        this.props.bet.numberOfLines
                    )}
                </Text>
                <Text config={this.config.eventName} class="ta-eventName">
                    <DateTime
                        config={this.config.eventNameDateTimeFormat}
                        dateTime={eventStartTime}
                        dateTimeRenderer={this.formatEventName}
                        rendererData={eventName}
                    />
                </Text>
                {this.renderNotification(leg)}
                {this.showExtraFields()
                    ? this.renderDateTimeInformation(firstLegPart)
                    : this.renderEventStartDateTime(eventStartTime)}
            </FlexPane>
        );
    }

    renderOdds(odds, priceType) {
        return (
            <FlexPane config={this.config.priceInfoContainer}>
                <Text config={this.config.oddsLabel} class="ta-priceText">
                    {OddsUtils.getDisplayedOdds(odds, priceType, this.l10n)}
                </Text>
            </FlexPane>
        );
    }

    renderPriceBoostedOdds(odds, baseOdds, priceType) {
        const oddsLabel = OddsUtils.getDisplayedOdds(odds, priceType, this.l10n);
        const baseOddsLabel = OddsUtils.getDisplayedOdds(baseOdds, priceType, this.l10n);
        return (
            <ChangedValueIndicator
                config={this.config.priceBoostIndicator}
                previousValue={baseOddsLabel}
                newValue={oddsLabel}
            />
        );
    }

    renderBestOdds(priceType) {
        return (
            priceType === PRICE_TYPE.BP && (
                <Image config={this.config.bestOddsIcon} class="ta-bestOddsIcon" />
            )
        );
    }

    renderMatchAccaLeg(leg) {
        return leg.parts.map((part, i, row) => {
            const iconStatus = BetViewUtils.getStatusIcon(part.status);
            const showSeparator = i + 1 !== row.length;
            const { selectionName: partSelectionName, hcap = '', marketName } = part;
            const { name, handicapLabel } = MarketHelper.handleHandicap(partSelectionName, hcap);
            const selectionName =
                name || this.resolveString('$BET_HISTORY_DETAILS.VALUE_NOT_AVAILABLE');
            const selectionLabel = `${selectionName} ${handicapLabel}`.trim();
            const isEarlyPayout = !!part?.earlyPayout;

            return (
                <Fragment key={part.selectionId}>
                    <MatchAccaSelectionItem
                        config={this.config.matchAccaSelectionItem}
                        showSeparator={showSeparator}
                        selectionLabel={selectionLabel}
                        marketName={marketName}
                        iconStatus={iconStatus}
                        isEarlyPayout={isEarlyPayout}
                    />
                </Fragment>
            );
        });
    }

    renderEventStartDateTime(startTime) {
        if (this.config.eventStartDateTime.hide) {
            return null;
        }

        return (
            <Text config={this.config.eventStartDateTimeText} class="ta-eventStartDateTime">
                <DateTime config={this.config.eventStartDateTimeFormat} dateTime={startTime} />
            </Text>
        );
    }

    renderMatchAccaInfo(bet) {
        const { legs, status, odds, baseOdds, priceType } = bet;
        const iconStatus = BetViewUtils.getStatusIcon(status);
        const { legInfo } = legs[0];
        const firstLegPart = BetsUtils.getFirstLegPart(legs[0]);
        const { eventStartTime, eventName } = legInfo;
        const header = this.resolveString('$BETSLIP.MATCH_ACCA.BET_HEADER');
        const isOddsBoostBet = BetsUtils.hasOddsBoost(bet);

        return (
            <FlexPane config={this.config.matchAcca}>
                <FlexPane config={this.config.selectionContainer}>
                    <FlexPane config={this.config.selectionNameContainer}>
                        <Image config={this.config.legStatusIcon[iconStatus]} />
                        <Text config={this.config.matchAccaHeaderText} class="ta-matchAcca-header">
                            {header}
                        </Text>
                    </FlexPane>
                    {baseOdds ? (
                        this.renderPriceBoostedOdds(odds, baseOdds, priceType)
                    ) : (
                        <Text config={this.config.oddsLabel} class="ta-matchAcca-totalOdds">
                            {odds}
                        </Text>
                    )}
                </FlexPane>
                <Text config={this.config.matchAccaEventName} class="ta-matchAcca-eventName">
                    {eventName}
                </Text>
                {isOddsBoostBet && (
                    <OddsBoostNotification
                        config={this.config.matchAccaOddsBoostNotification}
                        bet={bet}
                    />
                )}
                {legs.map(leg => this.renderMatchAccaLeg(leg))}
                {this.showExtraFields()
                    ? this.renderDateTimeInformation(firstLegPart)
                    : this.renderEventStartDateTime(eventStartTime)}
            </FlexPane>
        );
    }

    renderDetails(bet) {
        const { legs = [] } = bet;
        const isMatchAcca = BetsUtils.hasMatchAccaLeg(bet);
        const legsInfos = isMatchAcca
            ? this.renderMatchAccaInfo(bet)
            : legs.map(leg => this.renderLeg(leg));

        return (
            <>
                <Text config={this.config.detailsLabel} class="ta-detailsLabel">
                    {this.resolveString('$BET_HISTORY_DETAILS.DETAILS')}
                </Text>
                <FlexPane config={this.config.legs} class="ta-details">
                    {legsInfos}
                </FlexPane>
            </>
        );
    }

    renderReceiptInfo(bet) {
        return (
            <FlexPane config={this.config.receiptInfo} class="ta-receiptInfo">
                <FlexPane config={this.config.receiptTextInfo}>
                    <Text config={this.config.receiptLabel} class="ta-receiptLabel">
                        {this.resolveString('$BET_HISTORY_DETAILS.RECEIPT')}
                        {this.resolveString('$BET_HISTORY_DETAILS.VALUE_SEPARATOR')}
                    </Text>
                    <Text config={this.config.receiptNumber} class="ta-receiptNumber">
                        {bet.id}
                    </Text>
                </FlexPane>
                {bet.deviceId && (
                    <FlexPane config={this.config.receiptTextInfo}>
                        <Text config={this.config.deviceIdLabel} class="ta-deviceIdLabel">
                            {this.resolveString('$BET_HISTORY_DETAILS.DEVICE_ID')}
                            {this.resolveString('$BET_HISTORY_DETAILS.VALUE_SEPARATOR')}
                        </Text>
                        <Text config={this.config.deviceIdNumber} class="ta-deviceIdNumber">
                            {bet.deviceId}
                        </Text>
                    </FlexPane>
                )}
                <FlexPane config={this.config.receiptTextInfo}>
                    <Text config={this.config.channelLabel} class="ta-channelLabel">
                        {this.resolveString('$BET_HISTORY_DETAILS.CHANNEL')}
                        {this.resolveString('$BET_HISTORY_DETAILS.VALUE_SEPARATOR')}
                    </Text>
                    <Text config={this.config.channelValue} class="ta-channelValue">
                        {bet.channel?.name ||
                            this.resolveString(
                                `$BET_HISTORY_DETAILS.CHANNEL_TYPE.${bet.channel?.code}`
                            )}
                    </Text>
                </FlexPane>
            </FlexPane>
        );
    }

    renderDownloadPdfButton(bet) {
        if (this.config.hidePdfButton) {
            return null;
        }

        const { isFetching = false } = this.props.pdfData || {};

        return (
            <FlexPane config={this.config.pdfButtonContainer} class="ta-pdfBtnContainer">
                <DownloadBetPdfButton
                    isFetching={isFetching}
                    bets={[bet]}
                    onClick={this.onDownloadBetPdfClicked}
                    config={this.config.downloadBetPdfButton}
                />
            </FlexPane>
        );
    }

    renderPdfError() {
        if (
            this.state.hasClickedDownloadPdf &&
            this.props.pdfData.hasError &&
            !this.props.pdfData.isFetching
        ) {
            return (
                <FlexPane config={this.config.errorContainer}>
                    <Text config={this.config.errorLabel}>
                        {this.resolveString('$BET_VIEW.PDF_ERROR')}
                    </Text>
                </FlexPane>
            );
        }
        return null;
    }

    render() {
        const { bet } = this.props;
        const betContent = (
            <>
                {this.renderBetInfo(bet)}
                {this.renderDetails(bet)}
                {this.renderDownloadPdfButton(bet)}
                {this.renderPdfError()}
            </>
        );
        return (
            <FlexPane config={this.config.content} class="ta-detailsPage">
                {this.renderHeader(bet)}
                {!this.config.showCompactLayout ? (
                    <ScrollPane config={this.config.betInfoContent} onScroll={this.handleScroll}>
                        {betContent}
                    </ScrollPane>
                ) : (
                    betContent
                )}
            </FlexPane>
        );
    }
}
