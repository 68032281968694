import { isEmpty } from 'mojito/utils';

/**
 * The registry keeps the map of component which are awaiting on a page for a data to arrive or to be rendered.
 *
 * @class AwaitingClientsRegistry
 * @private
 */
class AwaitingClientsRegistry {
    constructor() {
        this.awaitingLoadClients = {};
    }

    add(clientId, name) {
        this.awaitingLoadClients[clientId] = name;
    }

    remove(clientId) {
        delete this.awaitingLoadClients[clientId];
    }

    get(clientId) {
        return this.awaitingLoadClients[clientId];
    }

    isEmpty() {
        return isEmpty(this.awaitingLoadClients);
    }

    has(clientId) {
        return Object.hasOwn(this.awaitingLoadClients, clientId);
    }

    print() {
        return `${JSON.stringify(this.awaitingLoadClients)}`;
    }
}

export default new AwaitingClientsRegistry();
