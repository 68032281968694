import MojitoCore from 'mojito/core';
import ActiveBonusesControllerImpl from './active-bonuses-controller.jsx';
import PropTypes from 'prop-types';

/**
 * Component responsible for managing the presentation of active bonuses.
 *
 * @name Controller
 * @class ActiveBonusesController
 * @memberof Mojito.Modules.ActiveBonuses
 */

/**
 * `ActiveBonusesController` prop types.
 *
 * @property {Mojito.Services.Bonus.types.Campaign[]} campaigns - An array of campaigns.
 * @property {boolean} [showPrewager = true] - Indicates whether the prewager should be displayed or not.
 * @property {boolean} [showOddsBoosts = true] - Indicates whether the odds boosts should be displayed or not.
 *
 * @memberof Mojito.Modules.ActiveBonuses.Controller
 */
const propTypes = {
    showPrewager: PropTypes.bool,
    showOddsBoosts: PropTypes.bool,
    campaigns: PropTypes.array.isRequired,
};

const defaultProps = {
    showPrewager: true,
    showOddsBoosts: true,
};

export default MojitoCore.Presentation.UIControllerView(
    'ActiveBonusesController',
    ActiveBonusesControllerImpl,
    propTypes,
    defaultProps
);
