import MojitoCore from 'mojito/core';

import {ensureHierarchy} from '#core/utils/config-utils.js';

import {AbstractFeature, allFeatures} from '#core/application/abstract-feature.js';

import {MainControllerSportsbook} from './main-controller-sportsbook.jsx';

import './router-resolver-fix.js';
import './system-settings-storage/index.js';

// TODO remove when Portal will release 25.7 (June 2025)
MojitoCore.Presentation.uiStyle.prefix = function (values) {
    for (let key in values) {
        if (typeof values[key] === 'function') {
            for (let i in values[key]) {
                // Portal extends Function.prototype with two functions: wrap and argumentNames, which leads to "Range Error: Maximum call stack size exceed"
                if (i === 'wrap' || i === 'argumentNames') {
                    values[key][i] = undefined;
                }
            }
        }
    }

    return this.customPrefixer.prefix(values);
};

class MojitoFixesFeature extends AbstractFeature {
    get name() {
        return 'Mojito Fixes';
    }

    afterMojitoConfigBuild(mojitoConfig) {
        const viewImplementations = ensureHierarchy(mojitoConfig, 'services.viewImplementations');

        /*
           This fixes issues:
             1) Misuse of react lifeCycle (useEffect is not the same as componentDidMount).
                Misusing makes Safari crashing on portal integrated app (portal just call logout.defer() before render) - DBX-9768
             2) Unmount-mount causes bugs with routing. This fix recreates 'history' on component creation (not app start)
                that resets history cache (DBX-9946)
           Code is cloned & fixed from 22.5 version
        */
        viewImplementations.MainSportsbook = {
            implementation: MainControllerSportsbook,
        };

        return super.afterMojitoConfigBuild(mojitoConfig);
    }

    afterMojito() {
        // Mute annoying and useless PromotionUtils warnings
        MojitoCore.logger.configureLogger('PromotionUtils', {level: MojitoCore.logger.NONE});

        return super.afterMojito();
    }
}

new MojitoFixesFeature(allFeatures);
