import BetslipTypes from 'services/betslip/types.js';
import BetslipUtils from 'services/betslip/utils.js';
import EventUtils from 'services/sports-content/events/utils.js';
import MojitoCore from 'mojito/core';
import ServicesTypes from 'services/common/types.js';
import betslipModelFactory from './model-factory.js';
import { createSlice } from '@reduxjs/toolkit';
import {
    selectEventItem,
    selectMarket,
    selectSelection,
    selectMarketState,
} from 'services/sports-content/events/selectors';
import {
    groupBy,
    isBoolean,
    isEmpty,
    keyBy,
    mapValues,
    pick,
    pickBy,
    pullAll,
    uniq,
} from 'mojito/utils';

const { ERROR_CODE, ERROR_SOURCE_TYPE, NON_BLOCKING_ERROR_CODES, PART_UPDATE_FACTOR } =
    BetslipTypes;
const { NumberUtils, DateTimeUtils } = MojitoCore.Base;
const CoreUtils = MojitoCore.Utils;
const reduxInstance = MojitoCore.Services.redux;
const { PENDING, UNKNOWN } = ServicesTypes.CONTENT_STATE;

/**
 * Betslip validation result.
 *
 * @enum {string}
 * @readonly
 * @memberof Mojito.Services.Betslip.helper
 */
export const VALIDATION_RESULT = {
    OK: 'OK',
    STAKE_MULTIPLIER_BREACH: 'STAKE_MULTIPLIER_BREACH',
    FUNDS_BREACH: 'FUNDS_BREACH',
};

/**
 * Betslip store helper class.
 *
 * @class BetslipHelper
 * @name helper
 * @memberof Mojito.Services.Betslip
 */

/**
 * Split combined action type into separate action types list.
 *
 * @param {string} actionType - Combined actions type which is represented as comma separated action types.
 * @returns {Array<string>} List of action types.
 *
 * @function Mojito.Services.Betslip.helper.splitActions
 */
export const splitActions = actionType => actionType.split(',');

/**
 * Combines action types into single action type by joining all input actions with comma separator.
 *
 * @param {...string} actions - Variable number of action types.
 * @returns {string} Combined action type.
 *
 * @function Mojito.Services.Betslip.helper.combineActions
 */
export const combineActions = (...actions) => actions.join(',');

/**
 * Creates slice specific for betslip purposes. Extends redux toolkit {@link https://redux-toolkit.js.org/api/createslice|createslice} functionality
 * with possibilities to combine actions for single reducer, bind event listeners with {@link https://redux-toolkit.js.org/api/createListenerMiddleware|Redux listener middleware}
 * and accept thunksCreator function.
 *
 * @param {object} options - Betslip slice options.
 * @returns {object} Redux toolkit {@link https://redux-toolkit.js.org/api/createslice|slice}.
 *
 * @function Mojito.Services.Betslip.helper.createBetslipSlice
 */
export const createBetslipSlice = options => {
    const { name, reducers, thunksCreator, listenersCreator } = options;
    const actionCases = Object.entries(reducers).flatMap(([actionsKey, reducer]) => {
        const boundActions = splitActions(actionsKey);
        return boundActions.map(action => ({ action, reducer }));
    });

    const groupedCases = groupBy(actionCases, actionCase => actionCase.action);

    // Action cases with composed reducers
    const flattenReducers = mapValues(groupedCases, cases => {
        const reducers = cases.map(actionCase => actionCase.reducer);
        return reducers.length > 1 ? composeReducerHandlers(reducers) : reducers[0];
    });
    const slice = createSlice({
        ...options,
        reducers: flattenReducers,
    });
    const actions = { ...slice.actions, ...thunksCreator(slice.actions) };
    bindActionListeners(name, listenersCreator(actions));
    return { reducer: slice.reducer, actions };
};

/**
 * Compose reducer handler functions into one function. The resulting function will execute each reducer argument in a sequence.
 *
 * @param {Array<Function>} reducerFunctions - List reducer handler functions.
 * @returns {Function} A single reducer handler function which will call all reducerFunctions in order.
 *
 * @function Mojito.Services.Betslip.helper.composeReducerHandlers
 */
export const composeReducerHandlers =
    reducerFunctions =>
    (state, { payload }) => {
        reducerFunctions.forEach(handler => {
            handler(state, { payload });
        });
    };

/**
 * Check if stored betslip has current git version and not outdated.
 *
 * @param {object} storedData - Betslip data object.
 * @param {string} storedData.date - Date time when betslip was stored.
 * @param {string} storedData.gitVersion - Git version of source code that stored betslip.
 * @param {number} maxAgeInHours - Max age of the betslip in hours.
 *
 * @returns {boolean} True if stored betslip is still valid.
 * @function Mojito.Services.Betslip.helper.isRelevantBetslip
 */
export const isRelevantBetslip = (storedData, maxAgeInHours) => {
    if (!storedData) {
        return false;
    }
    const { gitVersion, date } = storedData;
    const storedTime = new Date(date);
    const betslipAgeInHours = DateTimeUtils.diffInHours(new Date(), new Date(storedTime));
    return gitVersion === CoreUtils.gitVersion() && maxAgeInHours > betslipAgeInHours;
};

/**
 * Checks if betslip placement is blocked due to critical
 * errors on betslip level and on bet stake level within specified <code>stakeGroupName<code/>.
 *
 * @param {Mojito.Services.Betslip.types.Betslip} betslip - The betslip.
 * @param {Mojito.Modules.Betslip.types.STAKE_GROUP_NAME} stakeGroupName - Stake group name.
 *
 * @returns {boolean} True if bet placement is blocked, false otherwise.
 * @function Mojito.Services.Betslip.helper.isPlacementBlocked
 */
export const isPlacementBlocked = (betslip, stakeGroupName) => {
    const stakeGroupErrors = BetslipUtils.getErrorsOnStakeGroup(betslip, stakeGroupName);
    const betslipErrors = betslip.errors || [];
    const isBlockerError = error => !NON_BLOCKING_ERROR_CODES.includes(error.code);
    const blockerErrors = [...stakeGroupErrors, ...betslipErrors].filter(isBlockerError);
    return !isEmpty(blockerErrors);
};

/**
 * Sanitize bet state object. Iterates over all <code>betsState</code> keys and removes <code>propName</code>
 * property from each bet state.
 *
 * @param {object} betsState - Bet state object where key is bet id and value is state object, typically contains flags like `isSuspended`, `hasOddsChange` etc.
 * @param {string} propName - Property name to be removed from each bet state.
 *
 * @function Mojito.Services.Betslip.helper.sanitizeBetsState
 */
export const sanitizeBetsState = (betsState, propName) => {
    if (!betsState) {
        return;
    }
    Object.keys(betsState).forEach(betId => {
        delete betsState[betId][propName];
    });
};

/**
 * Apply key and value to bet in bet state object.
 *
 * @param {string} betId - Bet id.
 * @param {string} key - Key that will be applied to bet in bet state.
 * @param {*} value - Value that will be applied to bet in bet state.
 * @param {object} betsState - Bet state object where key is bet id and value is state object, typically contains flags like `isSuspended`, `hasOddsChange` etc.
 *
 * @function Mojito.Services.Betslip.helper.applyBetStateProp
 */
export const applyBetStateProp = (betId, key, value, betsState) => {
    const hasBetState = betsState.hasOwnProperty(betId);
    if (!hasBetState) {
        betsState[betId] = {};
    }
    betsState[betId][key] = value;
};

/**
 * Apply suspended state to bet in bet state.
 *
 * @param {string} betId - Bet id.
 * @param {boolean} suspended - Bet suspended state.
 * @param {object} betsState - Bet state object where key is bet id and value is state object, typically contains flags like `isSuspended`, `hasOddsChange` etc.
 *
 * @function Mojito.Services.Betslip.helper.setBetSuspended
 */
export const setBetSuspended = (betId, suspended, betsState) => {
    isBoolean(suspended) && applyBetStateProp(betId, 'isSuspended', suspended, betsState);
};

/**
 * Apply validity state to bet in bet state.
 *
 * @param {string} betId - Bet id.
 * @param {boolean} invalid - Bet validity state.
 * @param {object} betsState - Bet state object where key is bet id and value is state object, typically contains flags like `isSuspended`, `hasOddsChange` etc.
 *
 * @function Mojito.Services.Betslip.helper.setBetInvalid
 */
export const setBetInvalid = (betId, invalid, betsState) => {
    isBoolean(invalid) && applyBetStateProp(betId, 'isInvalid', invalid, betsState);
};

/**
 * Apply odds changed state to bet in bet state.
 *
 * @param {string} betId - Bet id.
 * @param {boolean} changed - Bet odds changed state.
 * @param {object} betsState - Bet state object where key is bet id and value is state object, typically contains flags like `isSuspended`, `hasOddsChange` etc.
 *
 * @function Mojito.Services.Betslip.helper.setBetOddsChange
 */
export const setBetOddsChange = (betId, changed, betsState) => {
    isBoolean(changed) && applyBetStateProp(betId, 'hasOddsChange', changed, betsState);
};

/**
 * Apply handicap changed state to bet in bet state.
 *
 * @param {string} betId - Bet id.
 * @param {boolean} changed - Bet handicap changed state.
 * @param {object} betsState - Bet state object where key is bet id and value is state object, typically contains flags like `isSuspended`, `hasOddsChange` etc.
 *
 * @function Mojito.Services.Betslip.helper.setBetHcapChange
 */
export const setBetHcapChange = (betId, changed, betsState) => {
    isBoolean(changed) && applyBetStateProp(betId, 'hasHcapChange', changed, betsState);
};

/**
 * Apply suspended state to bet parts in bet state.
 *
 * @param {string} betId - Bet id.
 * @param {{selectionId: string, isSuspended: boolean}} suspendedParts - Bet parts suspended state object.
 * @param {object} betsState - Bet state object where key is bet id and value is state object, typically contains flags like `isSuspended`, `hasOddsChange` etc.
 *
 * @function Mojito.Services.Betslip.helper.setBetPartsSuspended
 */
export const setBetPartsSuspended = (betId, suspendedParts, betsState) => {
    applyBetStateProp(betId, 'suspendedParts', suspendedParts, betsState);
};

/**
 * Set selection pending in selection state.
 *
 * @param {string} id - Selection id.
 * @param {boolean} isPending - Selection pending state.
 * @param {object} selectionsState - Selection state object where key is selection id and value is state object, typically contains `isSuspended` flag.
 *
 * @function Mojito.Services.Betslip.helper.setSelectionPending
 */
export const setSelectionPending = (id, isPending, selectionsState) => {
    if (!selectionsState[id]) {
        selectionsState[id] = {};
    }
    selectionsState[id].isPending = isPending;
};

/**
 * Apply banker pending state to bet in bet state.
 *
 * @param {string} betId - Bet id.
 * @param {boolean} changed - Bet banker pending state.
 * @param {object} betsState - Bet state object where key is bet id and value is state object, typically contains flags like `isSuspended`, `hasOddsChange` etc.
 *
 * @function Mojito.Services.Betslip.helper.setBankerPending
 */
export const setBankerPending = (betId, changed, betsState) => {
    applyBetStateProp(betId, 'isBankerPending', changed, betsState);
};

/**
 * Validates betslip stake group.
 * Usually applicable before betslip placement.
 * Returns validation status {@link Mojito.Services.Betslip.helper.VALIDATION_RESULT.OK|OK} if stake group is valid to be placed.
 * Otherwise returns corresponding faulty validation status.
 *
 * @param {Mojito.Services.Betslip.types.Betslip} betslip - Betslip.
 * @param {Mojito.Services.Betslip.types.STAKE_GROUP_NAME} stakeGroupName - Stake group name.
 * @param {Mojito.Services.Authentication.types.UserInfo} [userInfo] - User information. If provided - balances validation will be performed.
 *
 * @returns {Mojito.Services.Betslip.helper.VALIDATION_RESULT} Validation result.
 *
 * @function Mojito.Services.Betslip.helper.validateStakeGroup
 */
export const validateStakeGroup = (betslip, stakeGroupName, userInfo) => {
    const { MULTIPLIER_BREACH } = ERROR_CODE;
    if (!isEmpty(BetslipUtils.findBetIdsWithError(betslip, stakeGroupName, MULTIPLIER_BREACH))) {
        return VALIDATION_RESULT.STAKE_MULTIPLIER_BREACH;
    }
    const calculation = BetslipUtils.getCalculation(betslip, stakeGroupName);
    const isValidBalance = !userInfo?.balanceUncertain;
    if (userInfo && isValidBalance && !hasEnoughBalance(calculation, userInfo, betslip.freebets)) {
        return VALIDATION_RESULT.FUNDS_BREACH;
    }
    return VALIDATION_RESULT.OK;
};

/**
 * Checks if the user has enough balance to place the bet.
 *
 * @param {Mojito.Services.Betslip.types.Calculation} calculation - Stake group calculation.
 * @param {Mojito.Services.Authentication.types.UserInfo} userInfo - User information.
 * @param {string[]} freeBets - Array of free bets codes.
 *
 * @returns {boolean} True if the user has enough balance or free bet is used, otherwise false.
 * @function Mojito.Services.Betslip.helper.hasEnoughBalance
 */
export const hasEnoughBalance = (calculation, userInfo, freeBets = []) => {
    // Free bet logic is to complex to validate on FE side.
    if (freeBets.length > 0) {
        return true;
    }
    const { balances = {} } = userInfo;
    const { stake } = calculation;
    const withdrawable = parseFloat(balances.withdrawable) || 0;
    const bonus = parseFloat(balances.bonus) || 0;
    const spendableBalance = withdrawable + bonus;
    return stake <= spendableBalance;
};

/**
 * Iterates through betslip stakes within specific <code>stakeGroupName</code>
 * and round them down according to <code>multiplierPerLine<code/> bet level.
 * Returns list of {@link Mojito.Services.Betslip.types.SetStakeRequest|SetStakeRequest} objects with
 * rounded stakes.
 * Note: this function does not mutate <code>betslip<code/> object.
 *
 * @param {Mojito.Services.Betslip.types.Betslip} betslip - Betslip.
 * @param {Mojito.Services.Betslip.types.STAKE_GROUP_NAME} stakeGroupName - Stake group name.
 *
 * @returns {Array<Mojito.Services.Betslip.types.SetStakeRequest>} List of set stake request objects.
 *
 * @function Mojito.Services.Betslip.helper.getRoundedStakes
 */
export const getRoundedStakes = (betslip, stakeGroupName) => {
    const { MULTIPLIER_BREACH } = ERROR_CODE;
    const betIdsToRound = BetslipUtils.findBetIdsWithError(
        betslip,
        stakeGroupName,
        MULTIPLIER_BREACH
    );
    return betIdsToRound
        .map(betId => {
            const { stake, betWay } = BetslipUtils.getBetStake(betslip, betId, stakeGroupName);
            const { multiplierPerLine } = BetslipUtils.getBetById(betslip, betId);
            const remainder = stake % multiplierPerLine;
            if (remainder > 0) {
                const roundedStake = Math.floor(stake / multiplierPerLine) * multiplierPerLine;
                return {
                    stake: NumberUtils.toFixed(roundedStake, 2),
                    betId,
                    betWay,
                };
            }
        })
        .filter(Boolean);
};

/**
 * Generates list of errors with {@link Mojito.Services.Betslip.types.ERROR_CODE.MULTIPLIER_BREACH|MULTIPLIER_BREACH} code.
 * The resulting list contains only one error per breached <code>multiplierPerLine<code/> value.
 * For example, if two bet stakes have breached the same <code>multiplierPerLine<code/> value,
 * then only one error with corresponding multiplier value will be present in the list.
 *
 * @param {Mojito.Services.Betslip.types.Betslip} betslip - Betslip.
 * @param {Array} betIds - List of bet ids that has
 * {@link Mojito.Services.Betslip.types.ERROR_CODE.MULTIPLIER_BREACH|MULTIPLIER_BREACH} errors on bet stake level.
 *
 * @returns {Array<Mojito.Services.Betslip.types.Error>} List error objects.
 *
 * @function Mojito.Services.Betslip.helper.generateMultiplierErrors
 */
export const generateMultiplierErrors = (betslip, betIds) => {
    const breachedMultipliers = betIds
        .map(betId => BetslipUtils.getBetById(betslip, betId).multiplierPerLine)
        .filter(Boolean);
    return uniq(breachedMultipliers).map(factor => ({
        code: ERROR_CODE.MULTIPLIER_BREACH,
        factor,
    }));
};

/**
 * Resolves activated stake group if activeStakeGroup changed on a betslip.
 *
 * @param {Mojito.Services.Betslip.types.Betslip} betslip - Current betslip object.
 * @param {Mojito.Services.Betslip.types.Betslip} prevBetslip - Previous betslip object.
 * @param {Mojito.Services.Betslip.types.STAKE_GROUP_NAME} lastActivatedStakeGroup - Last activated stake group.
 *
 * @returns {Mojito.Services.Betslip.types.STAKE_GROUP_NAME} Stake group name.
 *
 * @function Mojito.Services.Betslip.helper.resolveActiveStakeGroup
 */
export const resolveActiveStakeGroup = (betslip, prevBetslip, lastActivatedStakeGroup) => {
    if (
        isEmpty(betslip) ||
        !betslip.activeStakeGroup ||
        BetslipUtils.isBetsNumberEqual(prevBetslip, betslip)
    ) {
        return lastActivatedStakeGroup;
    }
    return betslip.activeStakeGroup;
};

/**
 * Sync selections state with the actual betslip. It will remove non-existing selection ids from selectionsState map
 * and mark existing selections with isPending: false.
 *
 * @param {Mojito.Services.Betslip.types.Betslip} betslip - Betslip.
 * @param {Map<string, Mojito.Services.Betslip.types.BetSelectionState>} selectionsState - Map of selection states where key is selection id.
 *
 * @function Mojito.Services.Betslip.helper.processSelectionsState
 */
export const processSelectionsState = (betslip, selectionsState) => {
    // If selection state is active but betslip doesn't contain single bets with this selection id,
    // then we assume that selection has been removed. Hence drop it from `selectionsState` object.
    const isActive = state => !state.isPending;
    const activeSelectionIds = Object.keys(pickBy(selectionsState, isActive) || {});
    const missingSelectionIds = activeSelectionIds.filter(
        id => !BetslipUtils.getSingleBetBySelectionId(betslip, id)
    );
    missingSelectionIds.forEach(id => delete selectionsState[id]);
    // Here we will search for all selections that are present in betslip and not highlighted in `selectionsState`.
    // We need to do this because some API can swap parts on with most balanced lines and to sync with betslip state
    // during betslip init.
    const parts = BetslipUtils.getPartsFromSingles(betslip);
    const notHighlightedSelectionIds = parts
        .filter(part => !selectionsState[part.selectionId])
        .map(part => part.selectionId);
    notHighlightedSelectionIds.forEach(id => setSelectionPending(id, false, selectionsState));
};

/**
 * Cleans errors that were accepted by user, e.g. ODDS_CHANGE.
 *
 * @param {Mojito.Services.Betslip.types.Betslip} betslip - Current betslip object.
 * @param {Mojito.Services.Betslip.types.Betslip} prevBetslip - Previous betslip object.
 * @param {Mojito.Services.Betslip.types.PriceChangeAcceptanceSettings} priceChangeSettings - Price change acceptance settings.
 *
 * @function Mojito.Services.Betslip.helper.sanitizeErrors
 */
export const sanitizeErrors = (betslip, prevBetslip, priceChangeSettings) => {
    const oddsChangeErrors = BetslipUtils.getErrors(
        betslip,
        ERROR_CODE.ODDS_CHANGE,
        ERROR_SOURCE_TYPE.BET
    );
    const errorsToIgnore = oddsChangeErrors.filter(error => {
        const betId = error.source.id;
        const prevPrice = BetslipUtils.getBetOdds(prevBetslip, betId);
        const newPrice = BetslipUtils.getBetOdds(betslip, betId);
        return BetslipUtils.isPriceChangeAccepted(newPrice, prevPrice, priceChangeSettings);
    });
    errorsToIgnore && pullAll(betslip.errors, errorsToIgnore);
};

/**
 * Sync bets state with the actual betslip. It will remove non-existing bet ids from betsState map
 * and apply all existing errors on betsState from betslip.
 *
 * @param {Mojito.Services.Betslip.types.Betslip} betslip - Current betslip object.
 * @param {Map<string, Mojito.Services.Betslip.types.BetState>} betsState - Map of bet states where key is bet id.
 *
 * @function Mojito.Services.Betslip.helper.processBetsState
 */
export const processBetsState = (betslip, betsState) => {
    // Remove outdated bets
    Object.keys(betsState)
        .filter(betId => !BetslipUtils.getBetById(betslip, betId))
        .forEach(betId => delete betsState[betId]);

    sanitizeBetsState(betsState, 'isInvalid');

    const { SUSPENDED, ODDS_CHANGE, HANDICAP_CHANGE, INVALID_SELECTION } = ERROR_CODE;
    iterateBetErrors(betslip, INVALID_SELECTION, betId => setBetInvalid(betId, true, betsState));
    iterateBetErrors(betslip, SUSPENDED, betId => setBetSuspended(betId, true, betsState));
    // It is safe to apply odds change flag for each betId within incoming ODDS_CHANGE error,
    // because all errors that were not accepted by the user will be filtered out in sanitizeErrors method.
    iterateBetErrors(betslip, ODDS_CHANGE, betId => setBetOddsChange(betId, true, betsState));
    iterateBetErrors(betslip, HANDICAP_CHANGE, betId => setBetHcapChange(betId, true, betsState));
};

/**
 * Detects if content update should be skipped.
 *
 * @param {Array<Mojito.Services.Betslip.types.Bet>} affectedBets - List of bet objects which are affected by content update (event updated or market updated).
 * @param {Mojito.Services.Betslip.types.BETSLIP_STATUS} betslipStatus - Betslip status.
 * @param {object} state - The application Redux state from {@link Mojito.Core.Services.redux#getStore|global store}.
 *
 * @returns {boolean} True if content update should be ignored.
 *
 * @function Mojito.Services.Betslip.helper.shouldIgnoreContentUpdate
 */
export const shouldIgnoreContentUpdate = (affectedBets, betslipStatus, state) => {
    if (BetslipUtils.isPendingPlaceOrOverask(betslipStatus)) {
        return true;
    }
    const pendingBets = BetslipUtils.filterBetsByPartInfo(affectedBets, partInfo => {
        const marketState = selectMarketState(partInfo.marketId, state);
        return marketState === PENDING || marketState === UNKNOWN;
    });
    // On page load after subscription markets arrive sequentially. We will hold with update until any of the market
    // is still pending to prevent sending inconsistent update composite requests, hence excluding pending bets.
    pullAll(affectedBets, pendingBets);
    return isEmpty(affectedBets);
};

/**
 * Finds default bets among provided affected bets and generates selection part update payload
 * for each single that contains odds or handicap updates. In teaser mode it only takes into account handicap updates.
 *
 * @param {Array<Mojito.Services.Betslip.types.Bet>} affectedBets - List of bets that belongs to the market.
 * @param {object} market - Market content object.
 * @param {Mojito.Services.UserSettings.types.ODDS_FORMAT} oddsFormat - Odds format to properly detect price change.
 * @param {boolean} [isTeaserMode = false] - True if betslip teaser mode is enabled.
 *
 * @returns {Array<Mojito.Services.Betslip.types.SelectionUpdate>} List of selection part update objects.
 *
 * @function Mojito.Services.Betslip.helper.resolvePartUpdates
 */
export const resolvePartUpdates = (affectedBets, market, oddsFormat, isTeaserMode = false) => {
    const simpleBets = affectedBets.filter(BetslipUtils.isDefault);
    const { ODDS, HCAP } = PART_UPDATE_FACTOR;
    // We should ignore price updates once in a teaser mode.
    const updateFactors = isTeaserMode ? [HCAP] : [ODDS, HCAP];
    return buildPartUpdates(simpleBets, market, oddsFormat, updateFactors);
};

/**
 * Finds match acca bets among provided affected bets and generates update composite payload for each match acca that contains updates.
 *
 * @param {Array<Mojito.Services.Betslip.types.Bet>} affectedBets - List of bets that belongs to the market.
 * @param {Map<string, Mojito.Services.Betslip.types.BetState>} betsState - Bets state map.
 * @param {object} event - Event content object.
 * @param {object} state - Redux application state object.
 * @param {object} [market = {}] - Market content object.
 *
 * @returns {Array<Mojito.Services.Betslip.types.UpdateCompositePayload>} List of update composite payloads.
 *
 * @function Mojito.Services.Betslip.helper.resolveMatchAccaUpdates
 */
export const resolveMatchAccaUpdates = (affectedBets, betsState, event, state, market) => {
    const isActiveBet = bet => !betsState[bet.id]?.isSuspended;
    const matchAccaBets = affectedBets.filter(BetslipUtils.isMatchAccaBet).filter(isActiveBet);
    return buildCompositeUpdates(matchAccaBets, event, state, market);
};

/**
 * Find content differences between `market` and corresponding `bets`
 * that belongs to this market. Builds and returns update part payloads.
 * The output is generated based on {@link Mojito.Services.Betslip.utils.isOddsEqual|odds} and
 * {@link Mojito.Services.Betslip.utils.isHandicapEqual|handicap} comparison between
 * each <code>bet</code> in the list and corresponding <code>selection</selection> object.
 *
 * @param {Array<Mojito.Services.Betslip.types.Bet>} bets - List of bets that belongs to the market.
 * @param {object} market - Market content object. Can contain selections that has odds or handicap updates.
 * @param {Mojito.Services.UserSettings.types.ODDS_FORMAT} oddsFormat - Current odds format.
 * @param {Array<Mojito.Services.Betslip.types.PART_UPDATE_FACTOR>} factors - List of update factors that should be considered.
 * E.g., [PART_UPDATE_FACTOR.ODDS] will build selection updates for odds changes only.
 *
 * @returns {Array<Mojito.Services.Betslip.types.SelectionUpdate>} List of selection part update objects.
 *
 * @function Mojito.Services.Betslip.helper.buildPartUpdates
 */
export const buildPartUpdates = (bets, market, oddsFormat, factors = []) => {
    if (!market) {
        return [];
    }
    const selections = bets
        .map(bet => {
            const part = BetslipUtils.getFirstBetPart(bet);
            const { selections = [] } = market;
            return selections.find(selection => selection.id === part.selectionId);
        })
        .filter(Boolean);
    const selectionsMap = keyBy(selections, selection => selection.id);
    const { ODDS, HCAP } = PART_UPDATE_FACTOR;
    return bets.reduce((result, bet) => {
        const part = BetslipUtils.getFirstBetPart(bet);
        const selection = selectionsMap[part.selectionId];
        if (!selection) {
            return result;
        }
        const oddsChanged =
            factors.includes(ODDS) && !BetslipUtils.isOddsEqual(bet, selection, oddsFormat);
        const handicapChanged =
            factors.includes(HCAP) && !BetslipUtils.isHandicapEqual(part, selection);
        const selectionUpdate =
            (oddsChanged || handicapChanged) && betslipModelFactory.getSelection(selection);
        if (!selectionUpdate) {
            return result;
        }
        const updateFactors = [];
        if (oddsChanged && handicapChanged) {
            updateFactors.push(ODDS, HCAP);
        } else if (oddsChanged) {
            updateFactors.push(ODDS);
        } else if (handicapChanged) {
            updateFactors.push(HCAP);
        }
        result.push({ factors: updateFactors, selection: selectionUpdate });
        return result;
    }, []);
};

/**
 * Get selection objects to which bet belongs to.
 *
 * @param {Mojito.Services.Betslip.types.Bet} bet - Betslip bet.
 * @param {object} state - Redux application state object.
 *
 * @returns {Array} List of content selection objects.
 *
 * @function Mojito.Services.Betslip.helper.getSelections
 */
export const getSelections = (bet, state) => {
    return bet.parts
        .map(part => {
            const { selectionId, partInfo } = part;
            const { marketId } = partInfo;
            const selection = selectSelection(marketId, selectionId, state);
            return selection && { ...selection, marketId };
        })
        .filter(Boolean);
};

/**
 * Find content differences between <code>event<code/>|<code>market<code/> and corresponding bets
 * that belongs to them. Builds update composite payloads and returns them for each bet that suppose to be updated.
 *
 * @param {Array<Mojito.Services.Betslip.types.Bet>} bets - List of bets that belongs to the market.
 * @param {object} event - Event content object.
 * @param {object} state - Redux application state object.
 * @param {object} [market = {}] - Market content object.
 *
 * @returns {Array<Mojito.Services.Betslip.types.UpdateCompositePayload>} List of update composite payloads.
 *
 * @function Mojito.Services.Betslip.helper.buildCompositeUpdates
 */
export const buildCompositeUpdates = (bets, event, state, market = {}) => {
    if (!event) {
        return [];
    }
    const { id: eventId } = event;
    const marketId = market?.id;
    return bets.reduce((result, bet) => {
        const marketIds = BetslipUtils.getMarketIdsFromBet(bet);
        const hasUpdatedEvent = BetslipUtils.getEventIdsFromBet(bet)[0] === eventId;
        const hasUpdatedMarkets = marketIds.includes(marketId);
        if (hasUpdatedEvent || hasUpdatedMarkets) {
            const selectionsData = getSelections(bet, state);
            result.push({
                selections: selectionsData.map(data => betslipModelFactory.getSelection(data)),
                betId: bet.id,
                odds: bet.exactOdds,
                parent: betslipModelFactory.getParent(event),
            });
        }
        return result;
    }, []);
};

/**
 * Checks if betsState has any suspended bets.
 *
 * @param {Map<string, Mojito.Services.Betslip.types.BetState>} betsState - Bets state map where key is bet id.
 *
 * @returns {boolean} True if there are any suspended bets in a state.
 *
 * @function Mojito.Services.Betslip.helper.hasSuspendedBet
 */
export const hasSuspendedBet = betsState =>
    Object.values(betsState).some(betState => betState.isSuspended);

/**
 * Creates bet changes function resolver which can be used to evaluate list of odds change or handicap change updates for each updated selection in part updates.
 *
 * @param {Array<Mojito.Services.Betslip.types.SelectionUpdate>} partUpdates - List of selection part updates.
 * @param {object} market - Market content object.
 * @param {object} betslip - Betslip object.
 * @param {Mojito.Services.UserSettings.types.ODDS_FORMAT} oddsFormat - Odds format to properly detect selection odds.
 *
 * @returns {Function} Resolve bet changes function which accepts {@link Mojito.Services.Betslip.types.PART_UPDATE_FACTOR|part update factor}
 * and returns list of update info objects for each updated selection in part updates. It can be list of odds changes or handicap changes depending on provided factor.
 *
 * @function Mojito.Services.Betslip.helper.betChangesResolver
 */
export const betChangesResolver = (partUpdates, market, betslip, oddsFormat) => factor => {
    return partUpdates
        .filter(partUpd => partUpd.factors.includes(factor))
        .flatMap(partUpd => {
            const { selection: selectionPart } = partUpd;
            const selectionId = selectionPart.id;
            const bets = BetslipUtils.getSingleBetsBySelectionId(betslip, selectionId);
            return bets
                .filter(bet => BetslipUtils.isDefault(bet) || BetslipUtils.isBetBuilderBet(bet))
                .map(bet => {
                    const updatedSelection = EventUtils.getSelectionFromMarket(market, selectionId);
                    const price = EventUtils.getSelectionOdds(updatedSelection, oddsFormat);
                    const { ODDS, HCAP } = PART_UPDATE_FACTOR;
                    switch (factor) {
                        case ODDS:
                            return { betId: bet.id, price, prevPrice: bet.odds };
                        case HCAP:
                            return { betId: bet.id, changed: true };
                    }
                });
        });
};

/**
 * Resolves match acca bets state from a betslip.
 * It will use betsState to find out which single bets are currently marked as suspended.
 * If these suspended singles contain selections which also are part of match acca bets then those match accas
 * will be treated as suspended as well. This processing is crucial for integrations like GenBet where switching to MULTIPLES
 * stake group leads to MATCH_ACCA bet removal and switch back to SINGLES will restore MATCH_ACCA bet. In this scenario we need to restore MATCH_ACCA bet state
 * and show it as suspended if any of its parts (selections) are SUSPENDED.
 *
 * @param {Mojito.Services.Betslip.types.Betslip} betslip - Current betslip object.
 * @param {Map<string, Mojito.Services.Betslip.types.BetState>} betsState - Bets state map, where key is bet id.
 */
export const processMatchAccBetsState = (betslip, betsState) => {
    const suspendedSelectionIds = BetslipUtils.getSuspendedSelectionIds(betslip, betsState);
    const isSuspendedSelection = selectionId => suspendedSelectionIds.includes(selectionId);
    const matchAccaBets = BetslipUtils.getMatchAccaBets(betslip);

    const suspendedMatchAccaBets = matchAccaBets.filter(bet =>
        BetslipUtils.getSelectionIdsFromBet(bet).some(isSuspendedSelection)
    );

    const resolveSuspendedParts = bet => {
        const suspendedParts = BetslipUtils.getSelectionIdsFromBet(bet)
            .filter(isSuspendedSelection)
            .map(selectionId => ({ selectionId, isSuspended: true }));
        return keyBy(suspendedParts, part => part.selectionId);
    };
    suspendedMatchAccaBets.forEach(suspendedBet => {
        setBetSuspended(suspendedBet.id, true, betsState);
        setBetPartsSuspended(suspendedBet.id, resolveSuspendedParts(suspendedBet), betsState);
    });
    processMatchAccaOddsChange(betslip, betsState);
};

/**
 * Get list of objects that describe bet status.
 * Uses data from <code>events store slice</code> to detect either bet is suspended or not
 * based on the <code>status</code> property of events, markets and selections that bet belongs to.
 *
 * @param {Array<Mojito.Services.Betslip.types.Bet>} bets - List of bets.
 * @param {object} state - Redux application state object.
 *
 * @returns {Array<{id: string, isSuspended: boolean, parts: Object<string, {isSuspended: boolean}>}>} List of bet statuses.
 *
 * @function Mojito.Services.Betslip.helper.getBetsStatuses
 */
export const getBetsStatuses = (bets, state) => {
    return bets.map(bet => {
        const eventId = BetslipUtils.getEventIdFromBet(bet);
        const event = selectEventItem(eventId, state) || {
            status: EventUtils.STATUS.SUSPENDED,
        };
        const suspendedSelections = bet.parts.reduce(
            (acc, part) => {
                const { partInfo, selectionId } = part;
                const market = selectMarket(partInfo.marketId, state) || {
                    status: EventUtils.STATUS.SUSPENDED,
                };
                const selection = selectSelection(partInfo.marketId, selectionId, state) || {
                    status: EventUtils.STATUS.SUSPENDED,
                };

                const selectionIsSuspended = EventUtils.selectionIsSuspended(
                    selection.status,
                    market.status,
                    event.status
                );

                acc.isSuspended = acc.isSuspended || selectionIsSuspended;

                acc.parts[selectionId] = { isSuspended: selectionIsSuspended };

                return acc;
            },
            { isSuspended: false, parts: {} }
        );

        return { betId: bet.id, ...suspendedSelections };
    });
};

const processMatchAccaOddsChange = (betslip, betsState) => {
    const matchAccaSingleBetsById = BetslipUtils.getMatchAccaSingleBetsById(betslip);

    iterateBetErrors(betslip, ERROR_CODE.ODDS_CHANGE, betId => {
        const matchAccaBetId = Object.keys(matchAccaSingleBetsById).find(matchAccaBetId =>
            matchAccaSingleBetsById[matchAccaBetId].some(({ id }) => id === betId)
        );
        matchAccaBetId && setBetOddsChange(matchAccaBetId, true, betsState);
    });
};

const bindActionListeners = (storeKey, listeners) => {
    Object.entries(listeners).forEach(([boundActions, listener]) => {
        reduxInstance.actionListener.startListening({
            predicate: action => splitActions(boundActions).includes(action.type),
            effect: (action, listenerApi) => {
                const globalState = listenerApi.getState();
                const state = globalState[storeKey];
                listener && listener(action.payload, listenerApi.dispatch, state, globalState);
            },
        });
    });
};

const iterateBetErrors = (betslip, errorCode, predicateFunc) => {
    const { BET } = ERROR_SOURCE_TYPE;
    const betErrors = BetslipUtils.getErrors(betslip, errorCode, BET);
    betErrors.forEach(error => predicateFunc(error.source.id));
};

/**
 * Selects only needed selection fields from the selection array.
 *
 * @param {Array<Mojito.Services.Betslip.types.Selection>} selections - List of selections.
 *
 * @returns {Array<Mojito.Services.Betslip.types.SelectionPayload>} Array with payloads.
 */
export const toSelectionPayload = (selections = []) =>
    selections.map(selection => pick(selection, ['marketId', 'betRef', 'hcap', 'places']));
