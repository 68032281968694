import {PALETTE_IDS} from '#core/application/modules/ids.js';

import {COLOR, FONT} from '../theme.js';

export function getBettingCenterConfig() {
    return {
        palette: {
            [PALETTE_IDS.BET_RECEIPT]: {
                BET_RECEIPT_BETS_SEPARATOR_COLOR: COLOR.BRAND.FOREGROUND3,
                BET_RECEIPT_BET_HEADER_TEXT: {
                    color: COLOR.BRAND.FOREGROUND4,
                    fontSize: FONT.SIZE_SMALL,
                    fontWeight: FONT.WEIGHT_BOLD,
                    textTransform: 'capitalize',
                },
                BET_RECEIPT_NUMBER_TEXT: COLOR.BRAND.FOREGROUND4,
                BET_RECEIPT_DEVICE_ID_TEXT: COLOR.BRAND.FOREGROUND4,
                BET_RECEIPT_TYPE_TEXT: COLOR.BRAND.FOREGROUND4,
            },
            [PALETTE_IDS.BETTING_CENTER]: {
                BETTING_CENTER_TAB_BUTTON_BG: COLOR.BRAND.BACKGROUND5,
                BETTING_CENTER_TAB_BUTTON_TEXT: `${COLOR.BRAND.BACKGROUND2}|${COLOR.BRAND.FOREGROUND2}`,

                BETTING_CENTER_BET_CONTAINER_BG: COLOR.BRAND.BACKGROUND6,
            },
            [PALETTE_IDS.BETSLIP]: {
                BETSLIP_EMPTY_CONTENT_MESSAGE: {
                    EMPTY_CONTENT_MESSAGE_TEXT: {
                        fontSize: FONT.SIZE_MEDIUM,
                        color: COLOR.BRAND.FOREGROUND2,
                    },
                },

                BETSLIP_SEPARATOR_COLOR: COLOR.BRAND.FOREGROUND3,

                BETSLIP_BB_BET_ICON_COLOR: COLOR.BRAND.CTA2,
                BETSLIP_BET_POTENTIAL_RETURNS_TEXT: COLOR.BRAND.FOREGROUND2,

                // Freebets
                BETSLIP_FREE_BETS_HEADER_ROTATION_ICON_COLOR: COLOR.BRAND.CTA2,
                BETSLIP_FREE_BETS_HEADER_TEXT: {
                    color: COLOR.BRAND.FOREGROUND2,
                    fontWeight: FONT.WEIGHT_BOLD,
                    fontSize: FONT.SIZE_SMALL,
                    textTransform: 'capitalize',
                },
                BETSLIP_FREE_BETS_SWITCH: {
                    SWITCH_CONTAINER_BG: COLOR.RESERVED.TRANSPARENT,
                    SWITCH_CONTAINER_BORDER_COLOR: COLOR.BRAND.BACKGROUND1,
                },
                // Sliding betslip
                SLIDING_BETSLIP_BUTTON_HIGHLIGHTED_BG: COLOR.BRAND.CTA1,
                SLIDING_BETSLIP_BUTTON_HIGHLIGHTED_TEXT: COLOR.BRAND.FOREGROUND1,

                // Odds boosts
                BETSLIP_ODDS_BOOSTS_COMMON_COLOR: COLOR.BRAND.FOREGROUND3,
                BETSLIP_ODDS_BOOSTS_CONTAINER_BB_BORDER_COLOR: COLOR.BRAND.FOREGROUND3,
                BETSLIP_ODDS_BOOSTS_INFO_ICON_COLOR: COLOR.BRAND.ACCENT3,
                BETSLIP_ODDS_BOOSTS_RETURNS_TEXT: {
                    fontWeight: FONT.WEIGHT_BOLD,
                },
            },
            [PALETTE_IDS.NUMERIC_KEYPAD]: {
                KEYPAD_INCREASE_BUTTON_BG: COLOR.BRAND.FOREGROUND3,
                KEYPAD_INCREASE_BUTTON_TEXT: COLOR.BRAND.BACKGROUND3,
                KEYPAD_BUTTON: {
                    TOGGLE_BUTTON_BG: COLOR.BRAND.FOREGROUND1,
                    TOGGLE_BUTTON_TEXT: {
                        color: `${COLOR.BRAND.FOREGROUND1}|${COLOR.BRAND.FOREGROUND2}`,
                        fontWeight: FONT.WEIGHT_SEMI_BOLD,
                    },
                    TOGGLE_BUTTON_SELECTED_BG: COLOR.BRAND.ACCENT3,
                },
                KEYPAD_COMPACT_BUTTON: {
                    TOGGLE_BUTTON_BG: COLOR.BRAND.FOREGROUND1,
                    TOGGLE_BUTTON_TEXT: {
                        color: `${COLOR.BRAND.FOREGROUND1}|${COLOR.BRAND.FOREGROUND2}`,
                        fontWeight: FONT.WEIGHT_SEMI_BOLD,
                    },
                    TOGGLE_BUTTON_SELECTED_BG: COLOR.BRAND.ACCENT3,
                },
            },
            [PALETTE_IDS.YOUR_BETS]: {
                OPEN_BET_LIVE_EVENT_TIMING_STATUS: {
                    EVENT_TIMING_LIVE_TEXT: COLOR.BRAND.FOREGROUND2,
                    EVENT_TIMING_UPCOMING_TEXT: COLOR.BRAND.FOREGROUND2,
                },
                OPEN_BET_LIVE_EVENT_DATA_SEPARATOR: COLOR.BRAND.FOREGROUND2,
                OPEN_BET_RESULT_TEXT: COLOR.BRAND.FOREGROUND2,

                YOUR_BETS_EMPTY_CONTENT_MESSAGE: {
                    EMPTY_CONTENT_MESSAGE_TEXT: {
                        fontSize: FONT.SIZE_MEDIUM,
                        color: COLOR.BRAND.FOREGROUND2,
                    },
                },

                OPEN_BET_SKELETON_PART: {
                    SKELETON_COLOR: COLOR.BRAND.FOREGROUND3,
                },
            },
            [PALETTE_IDS.OVERASK]: {
                OVERASK_TIMER_TEXT: COLOR.BRAND.FOREGROUND2,
                OVERASK_BET_OFFER_STATUS_TITLE_TEXT: {
                    color: COLOR.BRAND.FOREGROUND4,
                    fontSize: FONT.SIZE_SMALL,
                    textTransform: 'capitalize',
                },
                OVERASK_BET_OFFER_HEADER_TEXT: {
                    color: COLOR.BRAND.FOREGROUND4,
                    fontSize: FONT.SIZE_SMALL,
                    fontWeight: FONT.WEIGHT_BOLD,
                    textTransform: 'capitalize',
                },
                OVERASK_TRADER_BET_OFFER_CONTAINER_BG: COLOR.BRAND.FOREGROUND5,
            },
            [PALETTE_IDS.QUICK_BETSLIP]: {
                QUICK_BETSLIP_BET_RECEIPT_PLACED_BET_DATE_TEXT: COLOR.BRAND.FOREGROUND2,
            },
        },
    };
}
