import MojitoServices from 'mojito/services';
import MojitoCore from 'mojito/core';
import { isFunction } from 'mojito/utils';

const analyticsReporter = MojitoServices.Analytics.reporter;
const AbstractAnalyticsEmitter = MojitoCore.Presentation.AbstractAnalyticsEmitter;

/**
 *
 * A class for emitting analytics data from UI components.
 *
 * @class AnalyticsEmitter
 * @memberof Mojito.Applications.Sports.Analytics
 */
class AnalyticsEmitter extends AbstractAnalyticsEmitter {
    /**
     * Emits analytics data.
     *
     * @param {string} type - The type of analytics event.
     * @param {*} data - Any type of data that will be used in analytics report.
     * @function Mojito.Applications.Sports.Analytics.AnalyticsEmitter#emitAnalytics
     */
    emitAnalytics(type, data) {
        const func = analyticsReporter[type];
        if (isFunction(func)) {
            func.call(analyticsReporter, data);
        }
    }
}

export default new AnalyticsEmitter();
