import MojitoCore from 'mojito/core';
import { COLLECTION_NAME } from 'services/promotions/types.js';
const DataProvider = MojitoCore.Services.DataProvider;

/**
 *
 * Class offering the possibility to link to promotions.
 *
 * @class PromotionsProvider
 * @extends Mojito.Core.DataProviders.DataProvider
 * @memberof Mojito.Services.Promotions
 */
class PromotionsProvider extends DataProvider {
    init(languageCode) {
        this.addLocalizedCollection(COLLECTION_NAME, languageCode);
    }

    /**
     * Link to a promotion channel.
     *
     * @param {string} channel - The specific promotion channel to link to (example: desktop|mobile|desktop_auth).
     * @param {Function} setter - Function called when the requested data is updated.
     * @returns {object} Subscription that must be disposed when no longer needed.
     * @function Mojito.Services.Promotions.PromotionsProvider#subscribeToEntity
     */
    subscribeToEntity(channel, setter) {
        return super.subscribeToEntity(channel, setter, COLLECTION_NAME);
    }
}

PromotionsProvider.COLLECTION_NAME = COLLECTION_NAME;

export default new PromotionsProvider();
