import MojitoPresentation from 'mojito/presentation';

import {escapeHiddenItems} from '#core/utils/config-utils.js';
import {isNative} from '#core/utils/context-utils.js';

import {PALETTE_IDS} from '#core/application/modules/ids.js';

import {COLOR, FONT} from '../theme.js';

export function getMenuListsConfigs() {
    // AZ
    const CUSTOM_CONFIG = {
        AppOverlay: {
            slidingAZMenu: {
                slidingPane: {
                    style: {
                        maxWidth: '368px',
                    },
                    closeButtonContainer: {
                        style: {
                            right: '100%',
                        },
                    },
                },
            },
        },
        AzOverview: {
            informationSectionItems: escapeHiddenItems([
                {
                    hide: !isNative(),
                    label: '$MY_ACCOUNT_PAGE.MENU_ITEMS.APP_SETTINGS',
                    intent: {
                        type: MojitoPresentation.Base.Intent.Types.OPEN_URL,
                        data: {
                            url: 'htcmd:openappsettings',
                        },
                    },
                },
            ]),
        },
    };

    return {
        custom: {
            [PALETTE_IDS.AZ_MENU]: CUSTOM_CONFIG,
        },
        palette: {
            [PALETTE_IDS.MINI_EVENT_ITEM_CONTENT]: {
                MINI_EVENT_ITEM_CHEVRON_ICON_COLOR: COLOR.BRAND.ACCENT1,
            },
            [PALETTE_IDS.MINI_EVENT_ITEM]: {
                MINI_EVENT_ITEM_BG: COLOR.RESERVED.TRANSPARENT,
                MINI_EVENT_ITEM_SELECTED_BG: COLOR.BRAND.ACCENT3,
                MINI_EVENT_ITEM_SELECTED_CONTENT: {
                    MINI_EVENT_ITEM_TIMING_STATUS: {
                        EVENT_TIMING_SEPARATOR_COLOR: COLOR.BASE.DIVIDER,
                    },
                    MINI_EVENT_ITEM_SCOREBOARD: {
                        MINI_SCOREBOARD_SCORE_COUNT_TEXT: COLOR.BRAND.FOREGROUND1,
                    },
                },
            },
            [PALETTE_IDS.QUICK_NAVIGATIONS]: {
                QUICK_NAVIGATIONS_DIALOG_BG: COLOR.BRAND.BACKGROUND4,
                EVENTS_MINI_EVENT_ITEM: {
                    MINI_EVENT_ITEM_SELECTED_CONTENT: {
                        MINI_EVENT_ITEM_TIMING_STATUS: {
                            EVENT_TIMING_LIVE_TEXT: COLOR.BRAND.BACKGROUND2,
                        },
                    },
                },
                LEAGUES_MINI_EVENT_ITEM: {
                    MINI_EVENT_ITEM_CONTENT: {
                        MINI_EVENT_ITEM_CHEVRON_ICON_COLOR: COLOR.BRAND.ACCENT1,
                        MINI_EVENT_ITEM_PARTICIPANTS: {
                            PARTICIPANTS_TEXT: COLOR.BRAND.BACKGROUND6,
                        },
                    },
                    MINI_EVENT_ITEM_SELECTED_CONTENT: {
                        MINI_EVENT_ITEM_CHEVRON_ICON_COLOR: COLOR.BRAND.ACCENT1,
                        MINI_EVENT_ITEM_PARTICIPANTS: {
                            PARTICIPANTS_TEXT: COLOR.BRAND.BACKGROUND6,
                        },
                    },
                },
            },
            [PALETTE_IDS.LIST_ITEM]: {
                LIST_ITEM_SELECTED_BG: COLOR.BRAND.ACCENT3,
                LIST_ITEM_SELECTED_TEXT: COLOR.BRAND.FOREGROUND1,
                LIST_ITEM_TEXT: {
                    color: COLOR.BRAND.FOREGROUND1,
                    fontSize: FONT.SIZE_MEDIUM,
                    fontWeight: FONT.WEIGHT_NORMAL,
                },
            },
            [PALETTE_IDS.MENUS_LINKS_LISTS]: {
                LHM_CONTAINER_BG: COLOR.BASE.PAGE,
                LHM_HEADER_TEXT: COLOR.BRAND.FOREGROUND5,
                LHM_HEADER_ICON_COLOR: COLOR.BRAND.FOREGROUND1,
            },
            [PALETTE_IDS.INPLAY_MENU]: {
                DESKTOP_INPLAY_MENU_SPORT_ITEM_EXPAND_ICON_COLOR: COLOR.BRAND.ACCENT1,
            },
            [PALETTE_IDS.AZ_MENU]: {
                DESKTOP_AZ_EXPAND_BUTTON: {
                    TERTIARY_BUTTON_TEXT: COLOR.BRAND.FOREGROUND1,
                    TERTIARY_BUTTON_ICON_COLOR: COLOR.BRAND.FOREGROUND1,
                },
                DESKTOP_AZ_FAVORITE_ICON_COLOR: COLOR.BRAND.ACCENT1,
                MOBILE_AZ_CLOSE_BUTTON: {
                    CIRCULAR_CLOSE_BUTTON_BG: COLOR.BASE.PAGE,
                    CIRCULAR_CLOSE_BUTTON_ICON_COLOR: COLOR.BRAND.FOREGROUND1,
                },
                MOBILE_AZ_CONTAINER_BG: COLOR.BASE.PAGE,
                MOBILE_AZ_BACK_BUTTON_TEXT: COLOR.BRAND.ACCENT1,
                MOBILE_AZ_SHOW_SECTION_SEPARATOR: true,
                MOBILE_AZ_SECTION_HEADER_TEXT: {
                    color: COLOR.BRAND.FOREGROUND5,
                    fontWeight: FONT.WEIGHT_SEMI_BOLD,
                    textTransform: 'capitalize',
                    fontSize: FONT.SIZE_SMALL,
                },
            },
        },
    };
}
