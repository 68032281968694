import MojitoCore from 'mojito/core';
import { STORE_KEY, INITIAL_STATE } from './slice.js';
const reduxInstance = MojitoCore.Services.redux;

/**
 * Sports store selectors.
 *
 * @class SportSelectors
 * @name selectors
 * @memberof Mojito.Services.SportsContent.Sports
 */

/**
 * Selects sports state.
 *
 * @function selectState
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {Mojito.Services.SportsContent.Sports.SportsState} The state of the sports store.
 * @memberof Mojito.Services.SportsContent.Sports.selectors
 */
export const selectState = state => {
    // Should be no need to fallback to global state once fully migrated to Redux.
    const appState = state || reduxInstance.store?.getState();
    return appState?.[STORE_KEY] || INITIAL_STATE;
};

/**
 * Selects sports list.
 *
 * @function selectSports
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {Array<object>} Sports list.
 * @memberof Mojito.Services.SportsContent.Sports.selectors
 */
export const selectSports = state => selectState(state).sports;

/**
 * Selects sport by id.
 *
 * @function selectSport
 *
 * @param {string} sportId - Sport Id.
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {object} Sport content item.
 * @memberof Mojito.Services.SportsContent.Sports.selectors
 */
export const selectSport = (sportId, state) =>
    selectSports(state).find(sport => sport.id === sportId);

/**
 * Subscribes to the changes of particular sports state property.
 *
 * @function subscribe
 *
 * @type {Mojito.Core.Services.redux.SubscriberFunction}
 * @memberof Mojito.Services.SportsContent.Events.selectors
 */
export const subscribe = reduxInstance.getSubscriber(STORE_KEY);
