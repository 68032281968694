import MojitoCore from 'mojito/core';
import MojitoPresentation from 'mojito/presentation';
import MojitoModules from 'mojito/modules';
import ApplicationHeader from 'applications/sports/components/applicationheader/index.jsx';
import ApplicationFooter from 'applications/common/components/application-footer/index.jsx';
import AppOverlay from 'applications/sports/components/app-overlay/index.jsx';

const BettingCenter = MojitoModules.BettingCenter.module;
const betslipSelectionsContext = MojitoModules.Betslip.betslipSelectionsContext;
const { SelectionsContext } = MojitoModules.SelectionButton.Context;
const QuickLinks = MojitoModules.QuickLinks.module;
const UIViewImplementation = MojitoCore.Presentation.UIViewImplementation;
const { BannerPromotions, PromotionUtils } = MojitoModules.Promotions;
const MatchAccaSlip = MojitoModules.MatchAcca.MatchAccaSlip;
const MatchAccaTypes = MojitoModules.MatchAcca.Types;
const { FlexPane, ImageButton, PostponedRenderer } = MojitoPresentation.Components;
const AccaBar = MojitoModules.AccaBar.module;

export default class AppViewSportsbook extends UIViewImplementation {
    renderPromotions() {
        const { betslipPromotions: promotionsConfig } = this.config;
        if (!PromotionUtils.hasLocationId(promotionsConfig)) {
            return;
        }
        return <BannerPromotions config={promotionsConfig} applicablePage="homepage" />;
    }

    renderSideMenu() {
        if (this.config.hideSideMenu) {
            return null;
        }

        // Postponing the rendering of heavier components to let Promotions create data subscriptions quicker.
        // What happens is that the postponed components will not be part of the initial synchronous rendering task and instead
        // will be rendered as part of a new task, which will execute according to the event loop queue.
        return (
            <FlexPane config={this.config.sideMenuContainer} class="ta-sideMenu">
                <PostponedRenderer>
                    <MatchAccaSlip showMode={MatchAccaTypes.SHOW_MODE.AUTO_SHOW_HIDE} />
                    <BettingCenter />
                </PostponedRenderer>
                {this.renderPromotions()}
            </FlexPane>
        );
    }

    renderApplicationHeader() {
        if (this.config.applicationHeader.hide) {
            return null;
        }

        return <ApplicationHeader userInfo={this.props.userInfo} freeBets={this.props.freeBets} />;
    }

    renderBackToTopButton() {
        if (this.config.backToTopButtonToggle.hide) {
            return;
        }

        return (
            <ImageButton
                class="ta-backToTopButton"
                config={this.config.backToTopButtonConfig}
                onClick={this.onBackToTopButtonClick}
                label={this.resolveAndFormatString('$HOME_PAGE.BACK_TO_TOP_BUTTON')}
            />
        );
    }

    onBackToTopButtonClick() {
        window.scrollTo(0, 0);
    }

    renderApplicationFooter() {
        if (this.config.applicationFooter.hide) {
            return null;
        }

        return <ApplicationFooter />;
    }

    renderQuickLinks() {
        if (this.config.quickLinks.hide) {
            return;
        }

        return <QuickLinks />;
    }

    renderAccaBar() {
        if (this.config.accaBar.hide) {
            return null;
        }

        return <AccaBar />;
    }

    render() {
        const style = this.style.mainContainer;
        return (
            <SelectionsContext.Provider value={betslipSelectionsContext}>
                <div id="appView" style={style}>
                    {this.renderApplicationHeader()}

                    <main style={this.style.container}>
                        <div style={this.style.content.page}>
                            {this.props.children}
                            {this.renderBackToTopButton()}
                        </div>
                        {this.renderSideMenu()}
                    </main>
                    <PostponedRenderer>{this.renderAccaBar()}</PostponedRenderer>

                    <PostponedRenderer>
                        <footer>
                            {this.renderApplicationFooter()}
                            {this.renderQuickLinks()}
                        </footer>
                    </PostponedRenderer>
                    <PostponedRenderer>
                        <AppOverlay
                            activeSlidingViewType={this.props.activeSlidingViewType}
                            selectedSportId={this.props.selectedSportId}
                            activeSlidingViewData={this.props.activeSlidingViewData}
                        />
                    </PostponedRenderer>
                </div>
            </SelectionsContext.Provider>
        );
    }
}

AppViewSportsbook.getStyle = function (config, mode, merge) {
    const style = {
        mainContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            minHeight: '100vh',
            userSelect: 'none',
            ...config.style.mainContainer,
        },
        container: {
            flex: '1 1 auto',
            width: '100%',
            alignSelf: 'center',
            display: 'flex',
            ...config.style.container,
        },
        content: {
            page: {
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
            },
        },
    };

    return merge({}, style);
};
