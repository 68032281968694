import {PALETTE_IDS} from '#core/application/modules/ids.js';

import {COLOR, FONT} from '../theme.js';

export function getRacingConfig() {
    return {
        custom: {
            [PALETTE_IDS.RACING]: {},
        },
        palette: {
            [PALETTE_IDS.RACE_CARD]: {
                RACE_CARD_CONTAINER_BG: COLOR.BASE.PAGE,
                RACE_CARD_RUNNERS_TABLE: {
                    TABLE_HEADER_TEXT: {
                        color: COLOR.BRAND.FOREGROUND1,
                        fontWeight: FONT.WEIGHT_NORMAL,
                    },
                    TABLE_HEADER_SORT_BUTTON_TEXT: {
                        color: COLOR.BRAND.ACCENT2,
                        fontWeight: FONT.WEIGHT_NORMAL,
                    },
                    TABLE_HEADER_MOBILE_SORT_BUTTON_TEXT: COLOR.BRAND.ACCENT1,
                },
                RACE_CARD_CAST_MARKETS_TABLE: {
                    TABLE_HEADER_TEXT: {
                        color: COLOR.BRAND.FOREGROUND1,
                        fontWeight: FONT.WEIGHT_BOLD,
                    },
                    TABLE_ITEM_TEXT: COLOR.BRAND.FOREGROUND1,
                },
                RACE_CARD_INFO_RACE_NUMBER_TEXT: COLOR.BRAND.FOREGROUND3,
            },
            [PALETTE_IDS.FUTURE_RACING]: {
                FUTURE_RACE_CARD_CONTAINER_BG: COLOR.BASE.PAGE,
                FUTURE_RACE_CARD_EVENT_NAME_TEXT: {
                    fontSize: FONT.SIZE_XLARGE,
                    fontWeight: FONT.WEIGHT_BOLD,
                },
                FUTURE_RACE_CARD_DATE_TEXT: COLOR.BRAND.FOREGROUND3,
                FUTURE_RACES_CONTAINER_BG: COLOR.BASE.PAGE,
            },
            [PALETTE_IDS.RACE_MEETINGS]: {
                RACE_MEETINGS_HEADER_BG: COLOR.BASE.PAGE,
                RACE_MEETINGS_HEADER_TEXT: {
                    color: COLOR.BRAND.FOREGROUND1,
                    fontSize: FONT.SIZE_XLARGE,
                    fontWeight: FONT.WEIGHT_BOLD,
                },
                RACE_MEETINGS_CONTENT_CONTAINER_BG: COLOR.BASE.PAGE,
                RACE_MEETING_MOBILE_COMPETITIONS_DROPDOWN: {
                    TERTIARY_DROPDOWN_TEXT: COLOR.BRAND.CTA2,
                },
                RACE_MEETING_MOBILE_RACE_TIME_SELECTOR_BG: COLOR.BASE.PAGE,
            },
        },
    };
}
