import {PALETTE_IDS} from '#core/application/modules/ids.js';

import {COLOR, FONT} from '../theme.js';

export function getBaseComponentsConfig() {
    return {
        palette: {
            [PALETTE_IDS.STREAMING_INDICATOR]: {
                INDICATOR_ICON_COLOR: COLOR.BRAND.BACKGROUND2,
            },
            [PALETTE_IDS.ODDS_BUTTON]: {
                ODDS_INFO_TEXT: COLOR.BRAND.FOREGROUND3,
                ODDS_SELECTED_INFO_TEXT: COLOR.BRAND.BACKGROUND2,
                ODDS_PRICE_TEXT: COLOR.BRAND.FOREGROUND1,
                ODDS_SELECTED_PRICE_TEXT: COLOR.BRAND.BACKGROUND2,
                ODDS_UNAVAILABLE_TEXT: {
                    color: COLOR.BRAND.FOREGROUND1,
                    fontSize: FONT.SIZE_SMALL,
                    fontWeight: FONT.WEIGHT_SEMI_BOLD,
                },
                ODDS_LOCKED_ICON_COLOR: COLOR.BRAND.FOREGROUND5,

                ODDS_PRICE_INDICATOR_DOWN_COLOR: COLOR.SEMANTIC.ERROR1,
                ODDS_PRICE_INDICATOR_UP_COLOR: COLOR.SEMANTIC.SUCCESS1,
            },
            [PALETTE_IDS.POPOVER]: {
                POPOVER_BG: COLOR.BRAND.BACKGROUND5,
                POPOVER_TEXT: {
                    color: COLOR.BRAND.FOREGROUND1,
                    fontWeight: FONT.WEIGHT_NORMAL,
                    fontSize: FONT.SIZE_MEDIUM,
                },
                POPOVER_CLOSE_ICON_COLOR: COLOR.BRAND.FOREGROUND1,
            },
            [PALETTE_IDS.DROPDOWN_MENU]: {
                DROPDOWN_MENU_ITEM_TEXT: COLOR.BRAND.FOREGROUND1,
                DROPDOWN_MENU_ITEM_BG: COLOR.BRAND.BACKGROUND5,
                DROPDOWN_MENU_ITEM_SELECTED_BG: COLOR.BRAND.ACCENT3,
            },
            [PALETTE_IDS.PRIMARY_DROPDOWN]: {
                PRIMARY_DROPDOWN_BG: COLOR.BRAND.BACKGROUND6,
                PRIMARY_DROPDOWN_TEXT: COLOR.BRAND.FOREGROUND2,
                PRIMARY_DROPDOWN_ICON_COLOR: COLOR.BRAND.CTA2,
            },
            [PALETTE_IDS.SECONDARY_DROPDOWN]: {
                SECONDARY_DROPDOWN_ICON_COLOR: COLOR.BRAND.CTA2,

                ON_LIGHT: {
                    SECONDARY_DROPDOWN_BORDER_COLOR: COLOR.BRAND.FOREGROUND2,
                },
                ON_DARK: {
                    SECONDARY_DROPDOWN_BORDER_COLOR: COLOR.BRAND.FOREGROUND4,
                },
            },
            [PALETTE_IDS.TERTIARY_DROPDOWN]: {
                TERTIARY_DROPDOWN_ICON_COLOR: COLOR.BRAND.CTA2,
            },
            [PALETTE_IDS.TOGGLE_BUTTON]: {
                TOGGLE_BUTTON_TEXT: {
                    fontWeight: FONT.WEIGHT_SEMI_BOLD,
                    color: `${COLOR.BRAND.ACCENT3}|${COLOR.BRAND.FOREGROUND1}`,
                },
                TOGGLE_BUTTON_BORDER_COLOR: COLOR.BRAND.FOREGROUND4,
                TOGGLE_BUTTON_SELECTED_TEXT: {
                    color: COLOR.BRAND.FOREGROUND1,
                    fontWeight: FONT.WEIGHT_BOLD,
                },
                TOGGLE_BUTTON_SELECTED_BG: COLOR.BRAND.ACCENT3,
                TOGGLE_BUTTON_DISABLED_TEXT: `${COLOR.BRAND.FOREGROUND5}|${COLOR.BRAND.FOREGROUND4}`,
            },
            [PALETTE_IDS.PRIMARY_BUTTON]: {
                PRIMARY_BUTTON_BG: COLOR.BRAND.CTA1,
                PRIMARY_BUTTON_TEXT: COLOR.BRAND.BACKGROUND2,
                PRIMARY_BUTTON_DISABLED_BG: COLOR.BRAND.FOREGROUND4,
                PRIMARY_BUTTON_DISABLED_TEXT: COLOR.BRAND.FOREGROUND5,
                PRIMARY_BUTTON_CONFIRM_BG: COLOR.BRAND.CTA2,
            },
            [PALETTE_IDS.SECONDARY_BUTTON]: {
                SECONDARY_BUTTON_TEXT: `${COLOR.BRAND.ACCENT3}|${COLOR.BRAND.FOREGROUND1}`,
                SECONDARY_BUTTON_BORDER_COLOR: `${COLOR.BRAND.ACCENT3}|${COLOR.BRAND.FOREGROUND1}`,
                SECONDARY_BUTTON_SELECTED_BG: `${COLOR.BRAND.ACCENT3}|${COLOR.BRAND.FOREGROUND1}`,
                SECONDARY_BUTTON_SELECTED_TEXT: `${COLOR.BRAND.BACKGROUND2}|${COLOR.BRAND.FOREGROUND2}`,
                SECONDARY_BUTTON_DISABLED_TEXT: `${COLOR.BRAND.FOREGROUND5}|${COLOR.BRAND.FOREGROUND4}`,
            },
            [PALETTE_IDS.TERTIARY_BUTTON]: {
                TERTIARY_BUTTON_TEXT: `${COLOR.BRAND.FOREGROUND4}|${COLOR.BRAND.BACKGROUND2}`,
                TERTIARY_BUTTON_SELECTED_BG: `${COLOR.BRAND.FOREGROUND4}|${COLOR.BRAND.FOREGROUND1}`,
                TERTIARY_BUTTON_SELECTED_TEXT: `${COLOR.BRAND.BACKGROUND2}|${COLOR.BRAND.FOREGROUND2}`,
                TERTIARY_BUTTON_DISABLED_TEXT: `${COLOR.BRAND.FOREGROUND5}|${COLOR.BRAND.FOREGROUND4}`,
            },
            [PALETTE_IDS.PIVOT]: {
                PIVOT_INDICATOR_BG: COLOR.BRAND.ACCENT1,
                PIVOT_SCROLL_BUTTON_BG: COLOR.BASE.SCRIM_DARK,
                PIVOT_SCROLL_BUTTON_ICON_COLOR: COLOR.BRAND.FOREGROUND5,
            },
            [PALETTE_IDS.PRIMARY_LINK]: {
                PRIMARY_LINK_TEXT: COLOR.BRAND.FOREGROUND1,
                PRIMARY_LINK_BG: COLOR.BASE.PAGE,
                PRIMARY_LINK_BORDER_COLOR: COLOR.BRAND.FOREGROUND4,
                PRIMARY_LINK_ICON_COLOR: COLOR.BRAND.CTA2,
            },
            [PALETTE_IDS.SECONDARY_LINK]: {
                SECONDARY_LINK_TEXT: COLOR.BRAND.CTA2,
            },
            [PALETTE_IDS.CLOSE_BUTTON]: {
                CLOSE_BUTTON_ICON_COLOR: `${COLOR.BRAND.FOREGROUND2}|${COLOR.RESERVED.WHITE}`,
                CIRCULAR_CLOSE_BUTTON_BG: COLOR.BRAND.FOREGROUND3,
            },
            [PALETTE_IDS.EVENT_TIMING_STATUS]: {
                EVENT_TIMING_LIVE_TEXT: COLOR.BRAND.CTA2,
                EVENT_TIMING_UPCOMING_TEXT: COLOR.BRAND.BACKGROUND2,
                EVENT_TIMING_SEPARATOR_COLOR: COLOR.BRAND.FOREGROUND3,
            },
            [PALETTE_IDS.MINI_SCOREBOARD]: {
                MINI_SCOREBOARD_SCORE_COUNT_TEXT: {
                    fontWeight: FONT.WEIGHT_BOLD,
                    color: COLOR.BRAND.ACCENT1,
                    textAlign: 'right',
                    padding: '2px 0',
                },
                MINI_SCOREBOARD_SCORES_TEXT: {
                    fontWeight: FONT.WEIGHT_BOLD,
                    color: COLOR.BRAND.FOREGROUND1,
                },
                MINI_SCOREBOARD_SEGMENT_INDICATOR_TEXT: {
                    color: COLOR.BRAND.FOREGROUND3,
                    fontWeight: FONT.WEIGHT_NORMAL,
                },
                MINI_SCOREBOARD_SERVER_INDICATOR: COLOR.BRAND.ACCENT2,
                MINI_SCOREBOARD_GOAL_BG: COLOR.BRAND.ACCENT2,
                MINI_SCOREBOARD_GOAL_TEXT: COLOR.BRAND.FOREGROUND1,
            },
            [PALETTE_IDS.RUNNER_INFO]: {
                RUNNER_CONTAINER_TEXT: COLOR.BRAND.FOREGROUND5,
                RUNNER_DRAW_NUMBER_TEXT: COLOR.BRAND.FOREGROUND5,
                RUNNER_TRAINER_AND_JOCKEY_NAMES_TEXT: COLOR.BRAND.FOREGROUND5,
            },
            [PALETTE_IDS.TABLE]: {
                TABLE_HEADER_BG: 'none',
                TABLE_HEADER_TEXT: {
                    color: COLOR.BRAND.FOREGROUND2,
                    fontWeight: FONT.WEIGHT_BOLD,
                },
                TABLE_ITEM_BG: COLOR.BRAND.FOREGROUND1,
                TABLE_ITEM_SELECTED_BG: COLOR.BRAND.FOREGROUND3,
                TABLE_ITEM_TEXT: COLOR.BASE.PAGE,
                TABLE_ITEM_SELECTED_TEXT: COLOR.BRAND.FOREGROUND2,
            },
            [PALETTE_IDS.PARTICIPANTS]: {
                PARTICIPANTS_TEXT: {
                    color: COLOR.BRAND.BACKGROUND6,
                    fontWeight: FONT.WEIGHT_BOLD,
                },
                PARTICIPANTS_OUTER_TEXT: {
                    color: COLOR.BRAND.FOREGROUND1,
                    fontWeight: FONT.WEIGHT_NORMAL,
                },
                PITCHER_TEXT: COLOR.BRAND.FOREGROUND3,
                PARTICIPANTS_SEPARATOR_COLOR: COLOR.BRAND.FOREGROUND3,
            },
            [PALETTE_IDS.BET_FUNDS_INFO]: {
                BET_FUNDS_INFO_TEXT: COLOR.BRAND.FOREGROUND4,
                BET_FUNDS_INFO_MEDIUM_TEXT: COLOR.BRAND.FOREGROUND2,
                BET_FUNDS_INFO_EMPHASIZED_TEXT: COLOR.BRAND.FOREGROUND2,
            },
            [PALETTE_IDS.BET_STATUS_ICON]: {
                OPEN_BET_ICON_COLOR: COLOR.BRAND.ACCENT1,
                VOID_BET_ICON_COLOR: '#000', // because for MatchAccaSelectionItem we can only set config globally with global based-on value
            },
            [PALETTE_IDS.BET_BUILDER_SELECTION_ITEM]: {
                BB_BET_SELECTION_NAME_TEXT: COLOR.BRAND.FOREGROUND2,
                BB_BET_MARKET_TEXT: COLOR.BRAND.FOREGROUND2,
                BB_BET_PATH_LINE_COLOR: COLOR.BRAND.FOREGROUND3,
                BB_BET_SUSPENDED_NOTIFICATION: {
                    INFO_BG: COLOR.BRAND.FOREGROUND3,
                },
            },
            [PALETTE_IDS.BET_BASE_INFO]: {
                BET_TITLE_TEXT: {
                    textTransform: 'capitalize',
                    fontWeight: FONT.WEIGHT_BOLD,
                    color: COLOR.BRAND.FOREGROUND4,
                    fontSize: FONT.SIZE_SMALL,
                },
                BET_DATE_TEXT: COLOR.BRAND.FOREGROUND4,
                BET_STATUS_TEXT: {
                    textTransform: 'capitalize',
                    fontWeight: FONT.WEIGHT_NORMAL,
                    color: COLOR.BRAND.FOREGROUND4,
                },
                BET_MARKET_NAME_TEXT: COLOR.BRAND.FOREGROUND2,
                BET_EVENT_NAME_TEXT: COLOR.BRAND.FOREGROUND2,
                BET_MULTIPLES_INFO_TEXT: COLOR.BRAND.FOREGROUND2,
                BET_TOTAL_ODDS_LABEL_TEXT: COLOR.BRAND.FOREGROUND4,
                BET_BUILDER_BET_MARKET_NAME_TEXT: COLOR.BRAND.FOREGROUND2,
            },
            [PALETTE_IDS.PROGRESS_BAR]: {
                PROGRESS_BAR_LINE_COLOR: COLOR.BRAND.FOREGROUND3,
                PROGRESS_BAR_FILL_LINE_COLOR: COLOR.BRAND.CTA2,
                PROGRESS_BAR_RANGE_LABEL_TEXT: {
                    color: COLOR.BASE.PAGE,
                    fontSize: FONT.SIZE_XSMALL,
                    paddingTop: 14,
                },
                PROGRESS_BAR_LIMIT_LABEL_TEXT: COLOR.BRAND.FOREGROUND4,
            },
            [PALETTE_IDS.BET_BONUS_LABEL]: {
                BET_BONUS_LABEL_TEXT: `${COLOR.BRAND.FOREGROUND2}|${COLOR.BRAND.FOREGROUND1}`,
            },
            [PALETTE_IDS.EARLY_PAYOUT_INDICATOR]: {
                EARLY_PAYOUT_POPOVER_BG: COLOR.BRAND.BACKGROUND5,
            },
            [PALETTE_IDS.PRICE_BOOST_LABEL]: {
                PRICE_BOOST_LABEL_ICON_COLOR: COLOR.BRAND.FOREGROUND1,
                PRICE_BOOST_LABEL_TEXT: {
                    color: `${COLOR.BRAND.FOREGROUND2}|${COLOR.BRAND.FOREGROUND1}`,
                    textTransform: 'capitalize',
                },
            },
        },
    };
}
