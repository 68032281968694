import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';

import MojitoModules from 'mojito/modules';
import MojitoPresentation from 'mojito/presentation';
import MojitoServices from 'mojito/services';

import { convertToMenuLink } from 'applications/sports/pages/sport-page/views/utils.js';
import { useListItems, useEventGroups } from 'modules/common/hooks';

const PromotionTypes = MojitoServices.Promotions.types;
const { NextRaces } = MojitoModules;
const EventCardsCarousel = MojitoModules.EventCardsCarousel.module;
const { MenuLinkPromotions } = MojitoModules.Promotions;
const { FlexPane } = MojitoPresentation.Components;
const eventGroupsSelectors = MojitoServices.EventGroups.selectors;

const RaceCoupons = MojitoModules.RaceCoupons.module;

const filters = [
    {
        key: 'today',
        label: 'HIGHLIGHTS_VIEW.FILTERS.TODAY',
    },
    {
        key: 'tomorrow',
        label: 'HIGHLIGHTS_VIEW.FILTERS.TOMORROW',
    },
];

export default function HighlightsView({ mojitoTools }) {
    const { config, appContext, stringResolver, emitPerformanceMark } = mojitoTools;
    const {
        analyticsEmitter: { emitAnalytics },
        subscriptionResolver,
    } = appContext;
    const { sportId } = useParams();

    const groupIds = useListItems(subscriptionResolver.cmsCouponPrefix(sportId));
    const eventGroups = useEventGroups(groupIds);
    const eventGroupNames = useSelector(state => eventGroupsSelectors.selectEventGroupNames(state));
    const cmsCouponLinks = Object.keys(eventGroups)
        .map(eventGroupId => ({ id: eventGroupId, name: eventGroupNames[eventGroupId] }))
        .filter(group => !!group.name)
        .map(coupon => convertToMenuLink(coupon, sportId, appContext.routeResolver));

    const onLinkClick = item => {
        const { isCMSCoupon, data } = item;
        const type = isCMSCoupon ? 'cmsCouponsClicked' : 'cmsQuickLinksClicked';
        emitAnalytics(type, data);
    };

    const [promotionsLoaded, setPromotionsLoaded] = useState(false);

    useEffect(() => {
        emitPerformanceMark('moduleRendered', promotionsLoaded);
    }, [emitPerformanceMark, promotionsLoaded]);

    return (
        <FlexPane config={config.container} class="ta-HighlightsView">
            <EventCardsCarousel
                sportId={sportId}
                pageId={sportId}
                config={config.eventCardsCarousel}
            />
            <NextRaces sportId={sportId} config={config.nextRacesController} />
            <MenuLinkPromotions
                config={config.menuLinkPromotions}
                extraLinks={cmsCouponLinks}
                applicablePage={sportId}
                locationId={PromotionTypes.LOCATION.QUICK_LINKS}
                onLinkClick={onLinkClick}
                onDataLoad={() => setPromotionsLoaded(true)}
            />
            <RaceCoupons
                sportId={sportId}
                couponFilters={filters.map(filter => ({
                    ...filter,
                    label: stringResolver.resolveString(`$${filter.label}`),
                }))}
                config={config.raceCoupons}
            />
        </FlexPane>
    );
}
