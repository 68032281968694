import React from 'react';
import {connect} from 'react-redux';

import MojitoCore from 'mojito/core';
import MojitoModules from 'mojito/modules';
import MojitoServices from 'mojito/services';

import {MobileContext} from '#core/utils/context-utils.js';
import {generateUniqueId} from '#core/utils/utils.js';
import {getResettingCSSForContainerId} from '#core/utils/embedding-utils.js';

import AbstractWidget from '#core/widgets/AbstractWidget.js';

import {Bonuses} from '#core/application/modules/bonuses/bonuses.js';

import {StoreProviderWrapper} from './wrappers/index.js';
import {NotAuthenticatedMessage} from './components/message.jsx';

const ActiveBonusesController = MojitoModules.ActiveBonuses.module;
const FreeBetCode = MojitoModules.FreeBetCode;

const AnalyticsContextExtender = MojitoCore.Presentation.AnalyticsContext.ContextExtender;
const AnalyticsContextBuilder = MojitoCore.Presentation.AnalyticsContext.ContextBuilder;

const {BONUS_FILTER} = MojitoServices.Bonus.types;
const {selectors: campaignSelectors, actions: campaignActions} = MojitoServices.BonusCampaign;
const {selectors: authenticationSelectors} = MojitoServices.Authentication;

class WidgetActiveBonusesImpl extends AbstractWidget {
    constructor(props) {
        super(props);

        this.analyticsContext = new AnalyticsContextBuilder().withAnalyticsPath('WidgetActiveBonuses').build();

        this.widgetId = 'widget-active-bonuses-' + generateUniqueId();
    }

    componentDidMount() {
        if (this.props.isLoggedIn) {
            this.props.dispatch(campaignActions.getCampaigns(BONUS_FILTER.ACTIVE));
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isLoggedIn !== this.props.isLoggedIn && this.props.isLoggedIn) {
            this.props.dispatch(campaignActions.getCampaigns(BONUS_FILTER.ACTIVE));
        }
    }

    render() {
        const {isLoggedIn, campaigns} = this.props;

        if (!isLoggedIn) return <NotAuthenticatedMessage />;

        return (
            <AnalyticsContextExtender value={this.analyticsContext}>
                <MobileContext>
                    <style
                        type="text/css"
                        dangerouslySetInnerHTML={{__html: getResettingCSSForContainerId(this.widgetId)}}
                    ></style>
                    <div id={this.widgetId}>
                        <FreeBetCode />
                        <ActiveBonusesController campaigns={campaigns} showPrewager={Bonuses.showPrewager} />
                    </div>
                </MobileContext>
            </AnalyticsContextExtender>
        );
    }
}

const mapStateToProps = state => ({
    isLoggedIn: authenticationSelectors.isLoggedIn(state),
    campaigns: campaignSelectors.selectActiveBonusCampaigns(state),
});

export const WidgetActiveBonuses = StoreProviderWrapper(
    'WidgetActiveBonuses',
    connect(mapStateToProps)(WidgetActiveBonusesImpl)
);
