import MojitoPresentation from 'mojito/presentation';
import MojitoServices from 'mojito/services';

const { NavigationPathBuilder } = MojitoPresentation.Base.Navigation;
const { CMS_COUPON } = MojitoServices.Analytics.types.LINK_CATEGORIES;
const IntentTypes = MojitoPresentation.Base.Intent.Types;

/**
 * Constructs a menu link object based on a given event group and sportId.
 *
 * @example <caption>Input:</caption>
 *      group: { id: 'cms-coupons-horse_racing-1049-all-match-events', name: 'Some CMS Coupon Name' }
 *      sportId: 'horse_racing'
 *
 * @example <caption>Output:</caption>
 * {
 *   id: "cms-coupons-horse_racing-1049-all-match-events",
 *   className: "ta-cms_coupon-cms-coupons-horse_racing-1049-all-match-events",
 *   hrefLink:"/sports/horse_racing/coupons/cms-coupons-horse_racing-1049-all-match-events",
 *   data: "Some CMS Coupon Name",
 *   analyticsData: {
 *     type: 'cmsCouponsClicked',
 *     linkName: 'cms-coupons-horse_racing-1049-all-match-events'
 *   },
 *   isCMSCoupon: true,
 *   onClickIntent: {
 *     type: "view"
 *     data: {
 *       type: 'SPORTS_COUPON_PAGE',
 *       params: {
 *         couponId: "cms-coupons-horse_racing-1049-all-match-events",
 *         sportId: "horse_racing"
 *       }
 *     }
 *   }
 * }
 *
 * @param {{id: string, name: string}} group - The event group object with `id` and `name` properties. For example: { id: 'cms-coupons-horse_racing-1049-all-match-events', name: 'Some CMS Coupon Name' }.
 * @param {string} sportId - The unique identifier for the sport.
 * @param {object} routeResolver - A routing utility object required for constructing the link which the menu item will point to.
 *
 * @returns {{
 * id: string,
 * data: string,
 * onClickIntent: { type: string, data: object },
 * isCMSCoupon: boolean,
 * analyticsData: { type: string, linkName: string },
 * hrefLink: string,
 * className: string
 * }} Object describing the constructed menu link.
 */
export const convertToMenuLink = (group, sportId, routeResolver) => {
    const groupId = group.id;
    const routingData = NavigationPathBuilder.toSportCoupon(sportId, groupId);
    const customClassName = `ta-${CMS_COUPON.toLowerCase()}-${groupId}`;
    const className = customClassName ? customClassName : `ta-${groupId}`;

    return {
        id: groupId,
        data: group.name,
        onClickIntent: { type: IntentTypes.VIEW, data: routingData },
        isCMSCoupon: true,
        analyticsData: { type: 'cmsCouponsClicked', linkName: groupId },
        hrefLink: routeResolver.getRoute(routingData),
        className,
    };
};
