import MojitoPresentation from 'mojito/presentation';
import PrebuiltBet from './prebuilt-bet/index.jsx';
import PrebuiltBetsSkeleton from './skeleton/index.jsx';
import { useEffect, useMemo } from 'react';
import { range } from 'mojito/utils';

const { Swiper, LoaderSuspense, FlexPane, Text, EventCards } = MojitoPresentation.Components;
const { CardLoader } = EventCards;

const PrebuiltBets = props => {
    const {
        betItems,
        mojitoTools: { config, stringResolver },
        onPrepareCardsToRender,
        isContentLoaded,
        elementRef,
    } = props;
    const skeleton = <PrebuiltBetsSkeleton config={config.prebuiltBetsSkeleton} />;
    const headerLabel = stringResolver.resolveString('$PREBUILT_BETS.TITLE_HEADER');

    useEffect(() => {
        if (!config.showCarousel) {
            onPrepareCardsToRender(range(betItems.length));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [betItems.length, config.showCarousel]);

    const cardRenderer = useMemo(
        () => createCardRenderer(isContentLoaded, config),
        [isContentLoaded, config]
    );

    return (
        <FlexPane class="ta-PrebuiltBets" config={config.container} elementRef={elementRef}>
            {config.prebuiltBet.showCardHeader && (
                <Text class="ta-PrebuiltBetsCarouselTitle" config={config.carouselTitle}>
                    {headerLabel}
                </Text>
            )}
            <LoaderSuspense
                config={config.skeletonLoader}
                isContentPending={!isContentLoaded}
                loader={skeleton}
            >
                {config.showCarousel ? (
                    <FlexPane class="ta-PrebuiltBetsCarousel" config={config.carouselContainer}>
                        <Swiper
                            config={config.swiper}
                            onPrepareSlidesToRender={onPrepareCardsToRender}
                            hideNavigationArrows={
                                betItems.length < config.minBetsForEnablingArrowNav
                            }
                        >
                            {betItems.map(betInfo =>
                                prebuiltCardLoaderWrapper(betInfo, cardRenderer)
                            )}
                        </Swiper>
                    </FlexPane>
                ) : (
                    <FlexPane class="ta-PrebuiltBetsContainer" config={config.betsContainer}>
                        {betItems.map(betInfo => (
                            <PrebuiltBet
                                key={betInfo.marketId}
                                config={config.prebuiltBet}
                                eventId={betInfo.eventId}
                                marketId={betInfo.marketId}
                                shouldRequestData={props.shouldRequestData}
                            />
                        ))}
                    </FlexPane>
                )}
            </LoaderSuspense>
        </FlexPane>
    );
};

const prebuiltCardLoaderWrapper = (betInfo, cardRenderer) => {
    const prebuiltCardLoaderWrapper = readyToLoad => (
        <CardLoader readyToLoad={readyToLoad} card={betInfo} renderer={cardRenderer} />
    );
    prebuiltCardLoaderWrapper.displayName = Object.keys({ prebuiltCardLoaderWrapper })[0];
    return prebuiltCardLoaderWrapper;
};

const createCardRenderer = (preloadDone, config) => {
    // eslint-disable-next-line react/display-name
    return card => (
        <PrebuiltBet
            key={card.marketId}
            config={config.prebuiltBet}
            eventId={card.eventId}
            marketId={card.marketId}
            shouldRequestData={preloadDone}
        />
    );
};

export default PrebuiltBets;
