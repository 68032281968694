import BetsTypes from './types.js';
import { keyBy, mapValues } from 'mojito/utils';

/**
 * Betslip action types. Exposes the map of stringify action types used within betslip slice.
 * This is a private module used to decouple betslip slice from other slices and listeners across mojito that can be interested
 * in certain betslip actions. This kind of decoupling is mainly useful to unblock tree-shaking and prevent redundant modules to be added in a bundle during application build.
 * Example: sportsbook application has feature to present betslip view for a punter, hence betslip slice and all its dependencies are useful and should be part of the bundle.
 * On the other side, lotto application has it own betslip feature, so no sport betslip slice or other related modules should be part of the bundle.
 * Apart from this lotto also has user info slice to handle user specific flows (which is perfectly fine). The problem is that user info slice is listening to placeBetslipSuccess action from betslip slice to fetch the balance.
 * Betslip actions exported from betslip slice so importing them in user info slice automatically includes the whole sports betslip slice in a bundle for a lotto.
 * The aim of BetslipActionTypes is to prevent this from happening.
 *
 * @module BetslipActionTypes
 * @private
 * @memberof Mojito.Services.Betslip
 */

export const ACTION_NAMES = keyBy(['placeBetslipSuccess', 'selectionsLimitBreach'], name => name);

export default mapValues(ACTION_NAMES, action => `${BetsTypes.STORE_KEY}/${action}`);
