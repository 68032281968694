import { useEffect } from 'react';
import UIControllerView from 'core/presentation/ui-controller-view';
import bodyScrollManager from 'core/presentation/body-scroll-manager';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router';

/**
 * UIPage HoC function is used to wrap component implementation
 * with UIControllerView functionality and adds possibility to
 * auto scroll window to top once page is getting rendered.
 *
 * @function UIPage
 * @memberof Mojito.Core.Presentation
 *
 * @param {string} name - PageView name.
 * @param {object} defaultImpl - Default implementation class.
 * @param {object} [propTypes] - PageView prop types.
 * @param {object} [defaultProps = {}] - PageView default prop values.
 * @param {Function} [loadingView] - Loading view component will be shown for suspense implementations.
 *
 * @returns {Function} PageWrapper function.
 */
export default function UIPage(name, defaultImpl, propTypes, defaultProps = {}, loadingView) {
    /**
     * UIPage prop types.
     *
     * @property {boolean} [autoScrollTop = true] - Determines if the page should automatically scroll to the top whenever the location.pathname changes.
     * @memberof Mojito.Core.Presentation.UIPage
     */
    const ownPropTypes = {
        autoScrollTop: PropTypes.bool,
    };

    const pagePropTypes = { ...propTypes, ...ownPropTypes };

    const Implementation = UIControllerView(
        name,
        defaultImpl,
        pagePropTypes,
        defaultProps,
        loadingView
    );

    const PageWrapper = props => {
        const { pathname } = useLocation();
        const { autoScrollTop = true } = props;
        useEffect(() => {
            // As an effect we scroll page to top
            if (autoScrollTop) {
                bodyScrollManager.reset();
            }
        }, [pathname, autoScrollTop]);

        return <Implementation {...props} />;
    };

    PageWrapper.displayName = `UIPageWrapper`;
    PageWrapper.propTypes = pagePropTypes;

    return PageWrapper;
}
