import PropTypes from 'prop-types';
import MojitoCore from 'mojito/core';
import FiltersImpl from './filters.jsx';

const UIView = MojitoCore.Presentation.UIView;

/**
 * Filters component is responsible for displaying filters for bet history on sports and lotto apps.
 *
 * @function Filters
 * @memberof Mojito.Modules.BetHistory
 */

/**
 * A callback function triggered when the bets status filter is changed in the dropdown.
 *
 * @param {string} statusFilter - Status filter value.
 *
 * @callback onStatusFilterChange
 * @memberof Mojito.Modules.BetHistory
 */

/**
 * A callback function triggered when the bets range filter is changed in the dropdown.
 *
 * @param {string} rangeFilter - Bets range filter value.
 *
 * @callback onBetsRangeFilterChange
 * @memberof Mojito.Modules.BetHistory
 */

/**
 * Filters prop types.
 *
 * @property {string} [rangeTitle] - Text above range filter dropdown.
 * @property {string} [statusTitle] - Text above status filter dropdown.
 * @property {string} selectedRangeFilter - Selected range filter value.
 * @property {string} selectedStatusFilter - Selected status filter value.
 * @property {Mojito.Modules.BetHistory.Filters.onStatusFilterChange} onStatusFilterChange - Callback that triggers once status value changes in the dropdown.
 * @property {Mojito.Modules.BetHistory.Filters.onBetsRangeFilterChange} onBetsRangeFilterChange - Callback that triggers once range value changes in the dropdown.
 *
 * @memberof Mojito.Modules.BetHistory.Filters
 */
const propTypes = {
    rangeTitle: PropTypes.string,
    statusTitle: PropTypes.string,
    onStatusFilterChange: PropTypes.func.isRequired,
    selectedRangeFilter: PropTypes.string.isRequired,
    selectedStatusFilter: PropTypes.string.isRequired,
    onBetsRangeFilterChange: PropTypes.func.isRequired,
};

export default UIView('Filters', FiltersImpl, propTypes);
