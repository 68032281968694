import MojitoApplications from 'mojito/applications';

const {selectors: routerSelectors} = MojitoApplications.Common.Router;

export function isInternalUrl(url) {
    const routeResolver = routerSelectors.getRouteResolver();
    const root = (routeResolver && routeResolver.getRoot()) || '/';

    return url.startsWith(root) || url.startsWith(`${document.location.origin}${root}`);
}
