import MojitoServices from 'mojito/services';
import MojitoCore from 'mojito/core';

const log = MojitoCore.logger.get('GaToGtm');
const AbstractAnalyticsService = MojitoServices.Analytics.AbstractAnalyticsService;
const RACING_MARKET_TYPES = MojitoServices.Analytics.types.RACING_MARKET_TYPES;
const BetsTypes = MojitoServices.Bets.types;

/* eslint sort-keys: "error" */
const CATEGORIES = {
    BACK_CLICKED: 'Back button',
    BANKERS: 'Bankers',
    BETSLIP: 'Betslip',
    BET_HISTORY: 'Bet history',
    BET_RECEIPT: 'Bet receipt',
    BONUSES: 'Bonuses',
    BOTTOM_BAR: 'Bottom bar',
    BREADCRUMBS: 'Breadcrumbs',
    CASHOUT: 'Cashout',
    CMS_COUPONS: 'CMS coupons',
    COMPETITIONS: 'Competitions',
    COUPONS: 'Coupons',
    CUSTOM_KEYPAD: 'Custom Keypad',
    EVENT: 'Event',
    EVENT_CARDS_CAROUSEL: 'Event cards carousel',
    EVENT_DETAIL_PAGE: 'Event detail page',
    EVENT_MENU: 'Event Menu',
    FREEBETS: 'Freebets',
    FUTURE_RACING: 'Future racing',
    HEADER: 'Header',
    HIGHLIGHT_MEETINGS: 'Highlight meetings',
    HOME_PAGE: 'Home page',
    IN_PLAY: 'In play',
    LEAGUE_MENU: 'League Menu',
    LOGIN: 'Login',
    MAIN_MENU: 'Main menu',
    MAIN_RACING_NAVIGATION: 'Main racing navigation',
    MAIN_SPORTS_NAVIGATION: 'Main sports navigation',
    MATCHES_FILTER: 'Matches Filter',
    MATCH_ACCA: 'Match acca',
    MEETINGS: 'Meetings',
    MY_ACCOUNT: 'My account',
    NEXT_RACES: 'Next races',
    OUTRIGHTS: 'Outrights',
    PROMOTIONS: 'Promotions',
    PUSH_NOTIFICATIONS: 'Push notifications',
    QUICK_BETSLIP: 'Quick Betslip',
    QUICK_LINK: 'Quick links',
    QUICK_START_GUIDE_BANNER: 'Quick Start Guide Banner',
    RACECARD: 'Racecard',
    SEARCH: 'Search',
    SETTINGS: 'Settings',
    SPORTS_CAROUSEL: 'Sports Carousel',
    SPORTS_LIST: 'Sports list',
    STREAMING: 'Streaming',
    TEASERS: 'Teasers',
    TOP_LEAGUE: 'Top League',
    TOP_SPORTS: 'Top sports coupon',
    VIRTUALS: 'Virtuals',
    YOUR_BETS: 'Your bets',
};

const STATUS = {
    FAILED: 'Failure',
    SUCCESS: 'Success',
};

const KeyCodes = {
    ENTER: 'Enter',
    INCREMENT: 'Increment',
};

export class ForwardGaService extends AbstractAnalyticsService {
    constructor() {
        super('ForwardGa');
    }

    loggedIn(/* userName */) {
        this._sendEvent(CATEGORIES.LOGIN, 'Logged in');
    }

    loginFailed(error) {
        this._sendEvent(CATEGORIES.LOGIN, 'Login failed', error);
    }

    loggedOut() {
        // TODO: This gets called when reloading the page which it should not, re add when fixed.
        // this._sendEvent(CATEGORIES.LOGIN, 'Logged out');
    }

    navigation(url) {
        this._sendPage(url);
    }

    selectionAddedToBetslip({selectionSource}) {
        if (!selectionSource) {
            log.warn('SelectionAddedToBetslip: selectionSource missing!');
        }
        this._sendEvent(CATEGORIES.BETSLIP, 'Add selection', selectionSource);
    }

    selectionRemovedFromBetslip({selectionSource}) {
        if (!selectionSource) {
            log.warn('SelectionRemovedFromBetslip: selectionSource missing!');
        }
        this._sendEvent(CATEGORIES.BETSLIP, 'Remove selection', selectionSource);
    }

    placingBet({betslipType}) {
        this._sendEvent(CATEGORIES.BETSLIP, 'Place slip submit', betslipType);
    }

    betPlaced({betType, betslipType}) {
        // Multiples are given a slightly higher value
        const betTypeLabel = betType !== BetsTypes.BET_TYPE.SINGLE ? 'Multiple' : 'Single';

        this._sendEvent(
            CATEGORIES.BETSLIP,
            'Bet placed successfully',
            JSON.stringify({betType: betTypeLabel, betslipType})
        );
    }

    removeAllInBetslip() {
        this._sendEvent(CATEGORIES.BETSLIP, 'Remove all selections');
    }

    customKeypadKeyClicked({keyCode, keyName, value}) {
        switch (keyCode) {
            case KeyCodes.INCREMENT: {
                this._sendEvent(
                    CATEGORIES.CUSTOM_KEYPAD,
                    CATEGORIES.CUSTOM_KEYPAD,
                    `Click preset value #${keyName.slice(-1)}`,
                    value
                );
                break;
            }
            case KeyCodes.ENTER: {
                this._sendEvent(CATEGORIES.CUSTOM_KEYPAD, 'Click Done', value);
                break;
            }
        }
    }

    customKeypadShown(isShown = true) {
        this._sendEvent(CATEGORIES.CUSTOM_KEYPAD, isShown ? 'Show keypad' : 'Hide keypad');
    }

    retainSelection({numberSelections}) {
        this._sendEvent(CATEGORIES.BETSLIP, 'Retain selections', numberSelections);
    }

    betslipChangeAcceptAllPrices(isAcceptable) {
        this._sendEvent(CATEGORIES.BETSLIP, 'Change accept all price changes', isAcceptable);
    }

    betslipChangeAcceptHigherPrices(isAcceptable) {
        this._sendEvent(CATEGORIES.BETSLIP, 'Change accept only higher price', isAcceptable);
    }

    betslipChangeAcceptLowerPrices(isAcceptable) {
        this._sendEvent(CATEGORIES.BETSLIP, 'Change accept only lower price', isAcceptable);
    }

    betslipSettingsOpened(isQuickBetslip) {
        const betslipType = isQuickBetslip ? 'Quick Betslip' : 'Betslip';
        this._sendEvent(CATEGORIES.BETSLIP, 'Open settings', betslipType);
    }

    betslipTabChanged(selectedTab) {
        this._sendEvent(CATEGORIES.BETSLIP, 'Change view', selectedTab);
    }

    openBetslip(betslipType) {
        this._sendEvent(CATEGORIES.BETSLIP, 'Open betslip', betslipType);
    }

    closeBetslip(betslipType) {
        this._sendEvent(CATEGORIES.BETSLIP, 'Close betslip', betslipType);
    }

    betslipStakeGroupChanged(stakeGroup) {
        this._sendEvent(CATEGORIES.BETSLIP, `Change bet type`, stakeGroup);
    }

    betslipTeaserTypeChanged(type) {
        this._sendEvent(CATEGORIES.TEASERS, 'Change teaser value', type);
    }

    acceptChanges(betslipType) {
        this._sendEvent(CATEGORIES.BETSLIP, 'Accept change', betslipType);
    }

    toggleShowStakePerBetForSingles(showStake) {
        this._sendEvent(CATEGORIES.BETSLIP, 'Change stake per bet', showStake ? 'Yes' : 'No');
    }

    initCashOut() {
        this._sendEvent(CATEGORIES.CASHOUT, 'Initialize cashout');
    }

    cashoutConfirmed() {
        this._sendEvent(CATEGORIES.CASHOUT, 'Confirm cashout');
    }

    cashedOut(status) {
        this._sendEvent(CATEGORIES.CASHOUT, 'Cashed out status', status === 'ok' ? STATUS.SUCCESS : STATUS.FAILED);
    }

    languageChanged(oldLanguage, newLanguage) {
        this._sendEvent(CATEGORIES.SETTINGS, 'Change language', newLanguage);
    }

    oddsFormatChanged(oldOddsFormat, newOddsFormat) {
        this._sendEvent(CATEGORIES.SETTINGS, 'Change odds format', newOddsFormat);
    }

    promotionClicked({locationId, bannerName}) {
        this._sendEvent(CATEGORIES.PROMOTIONS, 'Click banner', JSON.stringify({bannerName, locationId}));
    }

    eventListMarketSelectorValueChanged({marketId}) {
        this._sendEvent(CATEGORIES.COUPONS, 'Change market', marketId);
    }

    eventsMarketFilterInteraction({marketId, listName}) {
        this._sendEvent(CATEGORIES.TOP_SPORTS, `Change market - ${listName}`, marketId);
    }

    eventListSelectedPeriodChanged({periodName}) {
        this._sendEvent(CATEGORIES.COUPONS, 'Select period', periodName);
    }

    marketGroupChanged({marketGroupId}) {
        this._sendEvent(CATEGORIES.EVENT_DETAIL_PAGE, 'Change market group', marketGroupId);
    }

    matchAccaAddSelection(selectionSource) {
        this._sendEvent(CATEGORIES.MATCH_ACCA, 'Add selection', selectionSource);
    }

    removeMatchAccaSelection(selectionSource) {
        this._sendEvent(CATEGORIES.MATCH_ACCA, 'Remove selection', selectionSource);
    }

    clearMatchAccaSlip() {
        this._sendEvent(CATEGORIES.MATCH_ACCA, 'Empty slip');
    }

    addMatchAccaToBetslip() {
        this._sendEvent(CATEGORIES.MATCH_ACCA, 'Add to betslip');
    }

    matchAccaSlipOpen() {
        this._sendEvent(CATEGORIES.MATCH_ACCA, 'Open slip');
    }

    matchAccaSlipClose() {
        this._sendEvent(CATEGORIES.MATCH_ACCA, 'Close slip');
    }

    quickLinkClicked(label) {
        this._sendEvent(CATEGORIES.BOTTOM_BAR, 'Select option', label);
    }

    sportsSideMenuOpenStateChanged(isOpened) {
        const action = isOpened ? 'Open A-Z menu' : 'Close A-Z menu';
        this._sendEvent(CATEGORIES.MAIN_MENU, action);
    }

    sportsMenuExpandedStateChanged(isExpanded) {
        const action = isExpanded ? 'Expand A-Z menu' : 'Collapse A-Z menu';
        this._sendEvent(CATEGORIES.SPORTS_LIST, action);
    }

    sportsMenuSportClicked({sportType, applicationMode}) {
        const category = applicationMode === 'mobile' ? CATEGORIES.MAIN_MENU : CATEGORIES.SPORTS_LIST;
        this._sendEvent(category, 'Select sport', sportType);
    }

    sportClassTypeClicked({pageType, typeName}) {
        if (pageType === 'competitions') {
            this._sendEvent(CATEGORIES.COMPETITIONS, 'Select competition', typeName);
        } else if (pageType === 'outrights') {
            this._sendEvent(CATEGORIES.OUTRIGHTS, 'Select outright', typeName);
        }
    }

    inPlaySideMenuItemClicked() {
        this._sendEvent(CATEGORIES.IN_PLAY, 'Switch in-play event from EDP');
    }

    topSportClicked({sportListName, sportType}) {
        this._sendEvent(CATEGORIES.TOP_SPORTS, `Select sport - ${sportListName}`, sportType);
    }

    topSportShowAllClicked({sportType, isInplay}) {
        this._sendEvent(CATEGORIES.TOP_SPORTS, 'Show all', `${sportType}${isInplay ? ' - inplay' : ''}`);
    }

    backClicked() {
        this._sendEvent(CATEGORIES.BACK_CLICKED, 'Select back button');
    }

    breadcrumbsClicked({linkLevel, linkName}) {
        this._sendEvent(CATEGORIES.BREADCRUMBS, `Select breadcrumb - level ${linkLevel}`, linkName);
    }

    cmsQuickLinksClicked(linkName) {
        this._sendEvent(CATEGORIES.QUICK_LINK, 'Select cms quick link', linkName);
    }

    sportsCarouselLinkClicked(sportId) {
        this._sendEvent(CATEGORIES.SPORTS_CAROUSEL, 'Sports Carousel Interaction', sportId);
    }

    streamFullScreenChanged({isFullScreen, eventName}) {
        this._sendEvent(CATEGORIES.STREAMING, isFullScreen ? 'Open fullscreen' : 'Close fullscreen', eventName);
    }

    virtualStreamingWatchButtonClicked(eventName) {
        this._sendEvent(CATEGORIES.VIRTUALS, 'Open/play stream', eventName);
    }

    eventCardClicked(eventName) {
        this._sendEvent(CATEGORIES.EVENT_CARDS_CAROUSEL, 'Event card clicked', eventName);
    }

    virtualSportsSelectedEventChanged(eventName) {
        this._sendEvent(CATEGORIES.VIRTUALS, 'Select event', eventName);
    }

    virtualSportsSelectedSportChanged(sportName) {
        this._sendEvent(CATEGORIES.VIRTUALS, 'Select sport', sportName);
    }

    playStream(eventName) {
        this._sendEvent(CATEGORIES.STREAMING, 'Open/play stream', eventName);
    }

    closeStream(eventName) {
        this._sendEvent(CATEGORIES.STREAMING, 'Close stream', eventName);
    }

    streamPlaying(eventName) {
        this._sendEvent(CATEGORIES.STREAMING, 'Stream successfully playing', eventName);
    }

    streamFailToPlay(reason) {
        this._sendEvent(CATEGORIES.STREAMING, 'Stream failed to play', reason);
    }

    streamMute(eventName) {
        this._sendEvent(CATEGORIES.STREAMING, 'Mute', eventName);
    }

    streamUnmute(eventName) {
        this._sendEvent(CATEGORIES.STREAMING, 'Unmute', eventName);
    }

    streamVolumeChanged({eventName, volume}) {
        this._sendEvent(CATEGORIES.STREAMING, 'Volume', eventName, volume);
    }

    racingMainNavigationClicked({linkName}) {
        this._sendEvent(CATEGORIES.MAIN_RACING_NAVIGATION, 'Change view', linkName);
    }

    matchSportMainNavigationClicked({linkName, sportId}) {
        this._sendEvent(CATEGORIES.MAIN_SPORTS_NAVIGATION, 'Change view', JSON.stringify({linkName, sportId}));
    }

    cmsCouponsClicked(linkName) {
        this._sendEvent(CATEGORIES.CMS_COUPONS, 'Select coupon', linkName);
    }

    logoClicked() {
        this._sendEvent(CATEGORIES.HOME_PAGE, 'Home via logo');
    }

    openLogin(loginButtonLocation) {
        this._sendEvent(CATEGORIES.LOGIN, 'Open login', loginButtonLocation);
    }

    yourBetsSubTabClicked(tab) {
        this._sendEvent(CATEGORIES.YOUR_BETS, 'Change view', tab);
    }

    changeRaceCard(raceName) {
        this._sendEvent(CATEGORIES.NEXT_RACES, 'Change racecard view', raceName);
    }

    viewFullRace({raceName, status}) {
        this._sendEvent(CATEGORIES.NEXT_RACES, 'Select race', `${raceName} - ${status}`);
    }

    selectRace({raceName, status}) {
        this._sendEvent(CATEGORIES.RACECARD, 'Select race', `${raceName} - ${status}`);
    }

    selectMeeting(meetingName) {
        this._sendEvent(CATEGORIES.RACECARD, 'Select meeting', meetingName);
    }

    sortRunners(sortType) {
        this._sendEvent(CATEGORIES.RACECARD, 'Sort', sortType);
    }

    selectLocation(location) {
        this._sendEvent(CATEGORIES.HIGHLIGHT_MEETINGS, 'Select region', location);
    }

    selectRaceOnHighlightsCoupon({raceName, status}) {
        this._sendEvent(CATEGORIES.HIGHLIGHT_MEETINGS, 'Select race', `${raceName} - ${status}`);
    }

    selectMeetingOnHighlightsCoupon(meetingName) {
        this._sendEvent(CATEGORIES.HIGHLIGHT_MEETINGS, 'Select meeting', meetingName);
    }

    changeMeetingsDay({day, location}) {
        this._sendEvent(CATEGORIES.MEETINGS, 'Select day', `${day} - ${location}`);
    }

    selectRaceOnMeetingsCoupon({raceName, status}) {
        this._sendEvent(CATEGORIES.MEETINGS, 'Select race', `${raceName} - ${status}`);
    }

    selectMeetingOnMeetingsCoupon(meetingName) {
        this._sendEvent(CATEGORIES.MEETINGS, 'Select meeting', meetingName);
    }

    selectRaceOnFutureCoupon(raceName) {
        this._sendEvent(CATEGORIES.FUTURE_RACING, 'Select race', raceName);
    }

    toggleEachWay(isEachWay) {
        this._sendEvent(CATEGORIES.BETSLIP, 'Select each way', isEachWay ? 'on' : 'off');
    }

    betHistoryStatusChange(status) {
        this._sendEvent(CATEGORIES.BET_HISTORY, 'Select bet type', status);
    }

    betHistoryRangeFilterChange(rangeFilter) {
        this._sendEvent(CATEGORIES.BET_HISTORY, 'Select range filter', rangeFilter);
    }

    betHistoryOnDetailsClick({action}) {
        this._sendEvent(CATEGORIES.BET_HISTORY, action);
    }

    betHistoryPrint() {
        this._sendEvent(CATEGORIES.BET_HISTORY, 'Print');
    }

    myAccountViewChange(tab) {
        this._sendEvent(CATEGORIES.MY_ACCOUNT, 'Change view', tab);
    }

    addFreebetVoucherSuccess(voucherId, voucherName) {
        this._sendEvent(CATEGORIES.FREEBETS, 'Add freebet code successfully', JSON.stringify({voucherId, voucherName}));
    }

    removeFreebetVoucherSuccess(voucherId) {
        this._sendEvent(CATEGORIES.FREEBETS, 'Removed freebet code successfully', voucherId);
    }

    addFreebetVoucherFailure({code, error}) {
        this._sendEvent(CATEGORIES.FREEBETS, 'Add freebet code failed', `${code} - ${error}`);
    }

    changeMarketRaceCard(racingMarket) {
        this._sendEvent(CATEGORIES.RACECARD, 'Change market', RACING_MARKET_TYPES[racingMarket]);
    }

    bonusesTabChanged(tab) {
        this._sendEvent(CATEGORIES.BONUSES, 'Change view', tab);
    }

    addSportToFavourites(sportId) {
        this._sendEvent(CATEGORIES.SPORTS_LIST, 'Add to favourites', sportId);
    }

    removeSportFromFavourites(sportId) {
        this._sendEvent(CATEGORIES.SPORTS_LIST, 'Remove from favourites', sportId);
    }

    searchPerformed(searchString) {
        this._sendEvent(CATEGORIES.SEARCH, 'Search', searchString);
    }

    searchInputCleared(searchString) {
        this._sendEvent(CATEGORIES.SEARCH, 'Clear search input', searchString);
    }

    searchResultsItemClicked({name, parentName, sportId}) {
        this._sendEvent(CATEGORIES.SEARCH, 'Selected search item', JSON.stringify({name, parentName, sportId}));
    }

    betslipRequestFailed(betslipType) {
        this._sendEvent(CATEGORIES.BETSLIP, 'Betslip request failed', betslipType);
    }

    openUserInfo() {
        this._sendEvent(CATEGORIES.MY_ACCOUNT, 'Opened');
    }

    closeUserInfo() {
        this._sendEvent(CATEGORIES.MY_ACCOUNT, 'Closed');
    }

    userInfoItemClicked({itemLabel}) {
        this._sendEvent(CATEGORIES.MY_ACCOUNT, 'Interaction', itemLabel);
    }

    navigationEvent({name, type, sport}) {
        this._sendEvent(CATEGORIES.EVENT, 'Navigated to', JSON.stringify({name, sport, type}));
    }

    competitionToggleExpandable({competitionName, sport}) {
        this._sendEvent(
            CATEGORIES.COMPETITIONS,
            'Collapse/Expand interaction',
            JSON.stringify({competitionName, sport})
        );
    }

    marketToggleExpandable({marketName, eventName, competitionName, sport}) {
        this._sendEvent(
            CATEGORIES.EVENT_DETAIL_PAGE,
            'Collapse/Expand market',
            JSON.stringify({competitionName, eventName, marketName, sport})
        );
    }

    settingsTimeZone(newOffset) {
        this._sendEvent(CATEGORIES.SETTINGS, 'Time Zone changed', newOffset);
    }

    clearSelection({numberSelections}) {
        this._sendEvent(CATEGORIES.BET_RECEIPT, 'Clear selections', numberSelections);
    }

    betReceiptShown() {
        this._sendEvent(CATEGORIES.BET_RECEIPT, 'Shown');
    }

    quickBetslipStakeBoxInteraction() {
        this._sendEvent(CATEGORIES.QUICK_BETSLIP, 'Stake box interaction');
    }

    quickBetslipToFull() {
        this._sendEvent(CATEGORIES.QUICK_BETSLIP, 'Expanded to full betslip');
    }

    eventCardsCarouselSwiped() {
        this._sendEvent(CATEGORIES.EVENT_CARDS_CAROUSEL, 'Swiped');
    }

    searchHistoryItemRemoved(searchString) {
        this._sendEvent(CATEGORIES.SEARCH, 'History item removed', searchString);
    }

    searchHistoryItemClicked(searchString) {
        this._sendEvent(CATEGORIES.SEARCH, 'History item clicked', searchString);
    }

    leaguesQuickNavigationOpened() {
        this._sendEvent(CATEGORIES.LEAGUE_MENU, 'Opened');
    }

    leaguesQuickNavigationClosed() {
        this._sendEvent(CATEGORIES.LEAGUE_MENU, 'Closed');
    }

    eventsQuickNavigationOpened({eventName, eventType, sportId, isInPlay}) {
        this._sendEvent(
            CATEGORIES.EVENT_MENU,
            'Opened',
            JSON.stringify({
                eventIsInPlay: isInPlay ? 'Yes' : 'No',
                eventName,
                eventType,
                sportId,
            })
        );
    }

    eventsQuickNavigationClosed() {
        this._sendEvent(CATEGORIES.EVENT_MENU, 'Closed');
    }

    searchInputFocused() {
        this._sendEvent(CATEGORIES.SEARCH, 'Opened');
    }

    bankersSwitchToggled(value) {
        this._sendEvent(CATEGORIES.BANKERS, 'Switch Toggled', value ? 'On' : 'Off');
    }

    bankersBetToggled(betId, value) {
        const newValue = value ? 'On' : 'Off';
        this._sendEvent(CATEGORIES.BANKERS, 'Bet Toggled', JSON.stringify({betId, value: newValue}));
    }

    notificationsSubscriberClick() {
        this._sendEvent(CATEGORIES.PUSH_NOTIFICATIONS, 'Notification clicked');
    }

    toggleNotifications({source, value}) {
        const label = value ? 'On' : 'Off';
        this._sendEvent(source, 'Push notifications switch', label);
    }

    topLeagueClick(typeId) {
        this._sendEvent(CATEGORIES.TOP_LEAGUE, 'Top League Interaction', typeId);
    }

    matchesFilterClicked({selectedFilterKey, sport}) {
        this._sendEvent(
            CATEGORIES.MATCHES_FILTER,
            'Matches Filter Interaction',
            JSON.stringify({selectedFilterKey, sport})
        );
    }
    quickStartGuideOpenedFromBanner() {
        this._sendEvent(CATEGORIES.QUICK_START_GUIDE_BANNER, 'Quick Start Guide opened from banner');
    }

    quickStartGuideBannerClosed() {
        this._sendEvent(CATEGORIES.QUICK_START_GUIDE_BANNER, 'Quick Start Guide banner closed');
    }

    _sendEvent(category, action, label, value) {
        // Add user name if we got one
        if (this.username) {
            this._safeGa('set', 'userId', this.username);
        }

        // Value must be sent as an integer
        this._safeGa('send', 'event', category, action, label, value ? Math.round(value) : undefined);
    }

    _sendPage(page) {
        // Set the page value on the tracker.
        // Setting it on the tracker will ensure the new page value gets used for all subsequent hits.
        this._safeGa('set', 'page', page);

        this._safeGa('send', 'pageview');
    }

    _safeGa(...args) {
        this.ga && this.ga(...args);
    }
}
