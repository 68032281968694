import {PALETTE_IDS} from '#core/application/modules/ids.js';

import {COLOR, FONT} from '../theme.js';

export function getSportsContentConfigs() {
    return {
        custom: {
            [PALETTE_IDS.SPORTS_CONTENT]: {
                SingleLeagueCoupon: {
                    eventsCoupon: {
                        eventList: {
                            eventContainer: {
                                style: {
                                    base: {
                                        paddingBottom: 72,
                                    },
                                },
                            },
                        },
                    },
                },
            },
        },
        palette: {
            [PALETTE_IDS.EVENT_LIST]: {
                EVENT_LIST_BG: COLOR.BRAND.BACKGROUND4,
                EVENT_LIST_HEADER_TEXT: {
                    color: COLOR.BRAND.FOREGROUND1,
                    fontSize: FONT.SIZE_XLARGE,
                    fontWeight: FONT.WEIGHT_BOLD,
                },
                EVENT_LIST_HEADER_SORT_TEXT: {
                    fontWeight: FONT.WEIGHT_NORMAL,
                    color: COLOR.BRAND.FOREGROUND3,
                },
                EVENT_LIST_MARKETS_HEADER_TEXT: COLOR.BRAND.FOREGROUND1,

                EVENT_LIST_GROUP_HEADER_EXPAND_ICON_COLOR: COLOR.BRAND.ACCENT1,
                EVENT_LIST_GROUP_CLASS_TEXT: COLOR.BRAND.FOREGROUND3,
                EVENT_LIST_ITEM_FALLBACK_MARKET_NAME_TEXT: COLOR.BRAND.FOREGROUND3,
                EVENT_LIST_ITEM_MORE_MARKETS_BUTTON_TEXT: COLOR.BRAND.FOREGROUND3,
                EVENT_LIST_ITEM_OUTRIGHT_EVENT_NAME: {
                    fontSize: FONT.SIZE_MEDIUM,
                    color: COLOR.BRAND.FOREGROUND1,
                },
                EVENT_LIST_OFFERS_LABEL_TEXT: COLOR.BRAND.FOREGROUND1,
                EVENT_LIST_EARLY_PAYOUT_INDICATOR: {
                    EARLY_PAYOUT_INDICATOR_LABEL: {
                        BET_BONUS_LABEL_TEXT: COLOR.BRAND.FOREGROUND1,
                        BET_BONUS_LABEL_BG: COLOR.BRAND.CTA1,
                        BET_BONUS_LABEL_BORDER_COLOR: COLOR.RESERVED.TRANSPARENT,
                    },
                },
            },
            [PALETTE_IDS.EVENTS_COUPON]: {
                EVENTS_COUPON_EMPTY_CONTENT_MESSAGE: {
                    EMPTY_CONTENT_MESSAGE_TEXT: COLOR.BRAND.BACKGROUND2,
                },
            },
            [PALETTE_IDS.COUPONS]: {
                COUPONS_TITLE_TEXT: {
                    fontSize: FONT.SIZE_XLARGE,
                    color: COLOR.BRAND.FOREGROUND1,
                    fontWeight: FONT.WEIGHT_BOLD,
                    textTransform: 'capitalize',
                },
                TOP_SPORTS_SPORT_SELECTOR_CONTAINER_BG: COLOR.BRAND.BACKGROUND4,
                PRICE_BOOST_COUPON_EVENT_NAME_TEXT: {
                    color: COLOR.BRAND.FOREGROUND1,
                    fontWeight: FONT.WEIGHT_BOLD,
                    fontSize: FONT.SIZE_MEDIUM,
                },
                TOP_SPORTS_SKELETON_PART: {
                    SKELETON_COLOR: COLOR.BRAND.FOREGROUND4,
                },

                MATCHES_FILTER_BG: COLOR.BRAND.BACKGROUND4,

                PRICE_BOOST_COUPON_BG: COLOR.BRAND.BACKGROUND4,
                PRICE_BOOST_COUPON_ITEM: {
                    PBI_ODDS_BUTTON: {
                        ODDS_PRICE_TEXT: {
                            fontSize: FONT.SIZE_SMALL,
                        },
                    },
                },
            },
            [PALETTE_IDS.SPORTS_CONTENT]: {
                SPORT_TABS_PIVOT: {
                    PIVOT_ROW_BG: COLOR.BASE.PAGE,
                },
            },
            [PALETTE_IDS.EVENT_PAGE]: {
                MARKETS_GROUPS_SELECTOR_BUTTON_NEW_FEATURE_LABEL_BG: COLOR.BRAND.BACKGROUND4,
                MARKETS_GROUPS_SELECTOR_BUTTON_NEW_FEATURE_LABEL_TEXT: COLOR.BRAND.BACKGROUND2,
            },
            [PALETTE_IDS.SPORT_PAGE]: {
                SPORT_HEADER_TEXT: COLOR.BRAND.FOREGROUND5,

                LEAGUES_CONTAINER_BG: COLOR.BRAND.BACKGROUND4,
                LEAGUES_SECTION_HEADER_TEXT: {
                    color: COLOR.BRAND.FOREGROUND5,
                    fontSize: FONT.SIZE_SMALL,
                    fontWeight: FONT.WEIGHT_BOLD,
                    textTransform: 'capitalize',
                },
                LEAGUES_HEADER_TEXT: {
                    fontWeight: FONT.WEIGHT_BOLD,
                    color: COLOR.BRAND.FOREGROUND1,
                },
                LEAGUES_ITEM_CHEVRON_ICON_COLOR: COLOR.BRAND.ACCENT2,
            },
            [PALETTE_IDS.HOME_PAGE]: {
                HOME_PAGE_TOP_SPORTS_SKELETON_PART: {
                    SKELETON_COLOR: COLOR.BRAND.FOREGROUND4,
                },
            },
        },
    };
}
