import {resolveBackground} from '#core/utils/color-utils.js';

import {registerTileSchema} from '#core/application/schema-registry.js';

import CLOSE_BUTTON from './close-button.palette-schema.yaml';

registerTileSchema(CLOSE_BUTTON);

export const CLOSE_BUTTON_TYPES = {
    CIRCULAR: 'CIRCULAR',
    COMMON: 'COMMON',
};

const CLOSE_BUTTONS_MAP = {
    [CLOSE_BUTTON_TYPES.CIRCULAR]: generateCircularCloseButton,
    [CLOSE_BUTTON_TYPES.COMMON]: generateCommonCloseButton,
};

export function generateCloseButton(TILE, TYPE) {
    const closeButtonGenerator = CLOSE_BUTTONS_MAP[TYPE] || CLOSE_BUTTONS_MAP[CLOSE_BUTTON_TYPES.COMMON];

    return closeButtonGenerator(TILE);
}

function generateCommonCloseButton(TILE) {
    return {
        buttonIcon: {
            src: 'close',
            style: {
                width: 12,
                height: 12,
                minWidth: 12,
                minHeight: 12,
                fill: TILE.CLOSE_BUTTON_ICON_COLOR,
            },
        },
    };
}

function generateCircularCloseButton(TILE) {
    return {
        button: {
            style: {
                base: {
                    ...resolveBackground(TILE.CIRCULAR_CLOSE_BUTTON_BG),
                    width: 40,
                    height: 40,
                    borderRadius: '50%',
                    alignItems: 'center',
                    justifyContent: 'center',
                    boxShadow: `0 1px 2px 0 rgba(0, 0, 0, 0.3)`,
                },
            },
        },
        innerContainer: {
            style: {
                flexDirection: 'column',
                alignItems: 'center',
            },
        },
        buttonIcon: {
            src: 'close',
            style: {
                width: 16,
                height: 16,
                minWidth: 16,
                minHeight: 16,
                fill: TILE.CIRCULAR_CLOSE_BUTTON_ICON_COLOR,
            },
        },
    };
}
