import {PALETTE_IDS} from '#core/application/modules/ids.js';

import {COLOR, FONT} from '../theme.js';

export function getDialogConfig() {
    const CUSTOM_CONFIG = {};

    return {
        custom: CUSTOM_CONFIG,
        palette: {
            [PALETTE_IDS.COMBINATION_ODDS_POPUP]: {
                COMBINATION_ODDS_BG: COLOR.BRAND.BACKGROUND6,
                COMBINATION_ODDS_HEADER_TITLE: {
                    color: COLOR.BRAND.FOREGROUND2,
                    fontSize: FONT.SIZE_SMALL,
                },
                COMBINATION_ODDS_CLOSE_BUTTON: {
                    CLOSE_BUTTON_ICON_COLOR: COLOR.BRAND.FOREGROUND5,
                },
                COMBINATION_ODDS_HEADER_SHOW_SEPARATOR: false,
                COMBINATION_ODDS_TABLE: {
                    TABLE_HEADER_TEXT: COLOR.BRAND.FOREGROUND4,
                    TABLE_ITEM_TEXT: COLOR.BRAND.FOREGROUND2,
                },
            },
            [PALETTE_IDS.LOGIN_DIALOG]: {
                LOGIN_DIALOG_CONTAINER_BG: COLOR.BRAND.FOREGROUND1,
                LOGIN_DIALOG_CONTAINER_TEXT: COLOR.BASE.PAGE,
                LOGIN_DIALOG_TITLE_TEXT: COLOR.BASE.PAGE,
                LOGIN_DIALOG_INPUT: {
                    INPUT_LABEL_TEXT: {
                        fontWeight: FONT.WEIGHT_NORMAL,
                        fontSize: FONT.SIZE_SMALL,
                        color: COLOR.BASE.PAGE,
                    },
                },
                LOGIN_DIALOG_FORGOT_DETAILS_TEXT: {
                    color: COLOR.BRAND.FOREGROUND2,
                    fontWeight: FONT.WEIGHT_NORMAL,
                },
                LOGIN_DIALOG_REGISTER_INVITATION_TEXT: {
                    color: COLOR.BRAND.FOREGROUND4,
                    fontWeight: FONT.WEIGHT_NORMAL,
                },
                CHANGE_PASSWORD_CONTAINER_TEXT: {
                    color: COLOR.BASE.PAGE,
                    fontSize: FONT.SIZE_XSMALL,
                },
                CHANGE_PASSWORD_POLICIES_TEXT: COLOR.BRAND.ACCENT3,
            },
            [PALETTE_IDS.TOAST]: {
                TOAST_BG: COLOR.BRAND.FOREGROUND1,
                TOAST_TITLE_TEXT: COLOR.BASE.PAGE,
                TOAST_MESSAGE_TEXT: COLOR.BASE.PAGE,
            },
            [PALETTE_IDS.ALERT_DIALOG]: {
                ALERT_DIALOG_BG: COLOR.BRAND.BACKGROUND6,
                ALERT_DIALOG_TITLE_TEXT: COLOR.BRAND.FOREGROUND4,
                ALERT_DIALOG_MESSAGE_TEXT: COLOR.BRAND.FOREGROUND4,
            },
            [PALETTE_IDS.QUICK_START_GUIDE]: {
                QSG_DIALOG_CONTAINER_BG: COLOR.BRAND.BACKGROUND4,
                QSG_DIALOG_CLOSE_BUTTON: {
                    CIRCULAR_CLOSE_BUTTON_ICON_COLOR: COLOR.BRAND.FOREGROUND2,
                    CIRCULAR_CLOSE_BUTTON_BG: COLOR.BRAND.FOREGROUND3,
                },
                QSG_DIALOG_GUIDE_BUTTON: {
                    TOGGLE_BUTTON_TEXT: {
                        color: COLOR.BRAND.FOREGROUND1,
                        textTransform: 'normal',
                    },
                    TOGGLE_BUTTON_BORDER_COLOR: COLOR.BRAND.FOREGROUND1,
                },

                QSG_BANNER_CONTAINER_BG: COLOR.BRAND.BACKGROUND5,
                QSG_BANNER_CLOSE_BUTTON: {
                    CLOSE_BUTTON_ICON_COLOR: COLOR.BRAND.FOREGROUND1,
                },
            },
            [PALETTE_IDS.ODDS_BOOSTS_DIALOG]: {
                OB_BONUS_SEPARATOR_COLOR: COLOR.BRAND.FOREGROUND3,
            },
        },
    };
}
