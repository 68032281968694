import BetslipTypes from 'services/betslip/types.js';
import BetsTypes from 'services/bets/types.js';

const MarketBetTypes = BetslipTypes.MARKET_BET_TYPES;
const { LEG_SORT } = BetsTypes;

/**
 * @typedef AnalyticsAPI
 * @type {object}
 * @property {Function} push - Sending analytic event with arguments as a parameter.
 * @memberof Mojito.Services.Analytics
 */

/**
 * Analytics types.
 *
 * @class AnalyticsTypes
 * @name types
 * @memberof Mojito.Services.Analytics
 */
export default class AnalyticsTypes {}

/**
 * Link categories.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Services.Analytics.types
 */
AnalyticsTypes.LINK_CATEGORIES = {
    MATCH_SPORT: 'MATCH_SPORT',
    RACING: 'RACING',
    CMS_COUPON: 'CMS_COUPON',
    CMS_LINK: 'CMS_LINK',
};

/**
 * Source types.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Services.Analytics.types
 */
AnalyticsTypes.SOURCE = {
    WIN: 'Win or each way',
    FORECAST: 'Forecast',
    TRICAST: 'Tricast',
};

/**
 * Link racing market types to types used for analytics. Includes forecast, tricast and default market types.
 *
 * @typedef RACING_MARKET_TYPES
 *
 * @property {Mojito.Services.Betslip.types.MARKET_BET_TYPES} [WIN_OR_EACHWAY_MARKET='Win or each way']
 * @property {Mojito.Services.Betslip.types.MARKET_BET_TYPES} [FORECAST_MARKET='Forecast']
 * @property {Mojito.Services.Betslip.types.MARKET_BET_TYPES} [TRICAST_MARKET='Tricast']
 * @memberof Mojito.Services.Analytics.types
 */
AnalyticsTypes.RACING_MARKET_TYPES = {
    [`${MarketBetTypes.WIN_OR_EACHWAY_MARKET}`]: AnalyticsTypes.SOURCE.WIN,
    [`${MarketBetTypes.FORECAST_MARKET}`]: AnalyticsTypes.SOURCE.FORECAST,
    [`${MarketBetTypes.TRICAST_MARKET}`]: AnalyticsTypes.SOURCE.TRICAST,
};

/**
 * Link leg sort types to source types used for analytics.
 *
 * @typedef LEG_SORT_TO_SOURCE
 *
 * @property {Mojito.Services.Betslip.types.LEG_SORT} [FORECAST='FORECAST']
 * @property {Mojito.Services.Betslip.types.LEG_SORT} [REVERSE_FORECAST='FORECAST']
 * @property {Mojito.Services.Betslip.types.LEG_SORT} [COMBINATION_FORECAST='FORECAST']
 * @property {Mojito.Services.Betslip.types.LEG_SORT} [TRICAST='TRICAST']
 * @property {Mojito.Services.Betslip.types.LEG_SORT} [COMBINATION_TRICAST='TRICAST']
 * @memberof Mojito.Services.Analytics.types
 */
AnalyticsTypes.LEG_SORT_TO_SOURCE = {
    [`${LEG_SORT.FORECAST}`]: AnalyticsTypes.SOURCE.FORECAST,
    [`${LEG_SORT.REVERSE_FORECAST}`]: AnalyticsTypes.SOURCE.FORECAST,
    [`${LEG_SORT.COMBINATION_FORECAST}`]: AnalyticsTypes.SOURCE.FORECAST,
    [`${LEG_SORT.TRICAST}`]: AnalyticsTypes.SOURCE.TRICAST,
    [`${LEG_SORT.COMBINATION_TRICAST}`]: AnalyticsTypes.SOURCE.TRICAST,
};

/**
 * Selection types.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Services.Analytics.types
 */
AnalyticsTypes.SELECTION_TYPE = {
    FORECAST: 'Forecast',
    TRICAST: 'Tricast',
    PRICE_BOOST: 'Priceboost',
    PREBUILT_MATCH_ACCA: 'PBBB',
};
