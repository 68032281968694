import MojitoServices from 'mojito/services';
import PromotionUtils from 'modules/promotions/utils/promotion-utils.js';

const { CMS_LINK } = MojitoServices.Analytics.types.LINK_CATEGORIES;

/**
 * @namespace Utils
 * @memberof Mojito.Modules.PageContextMenu
 */

/**
 * Utilities for CmsLinks handling.
 *
 * @class CmsLinksUtils
 * @memberof Mojito.Modules.PageContextMenu.Utils
 */
export default class CmsLinksUtils {
    /**
     * Returns the items object corresponding to the given CMS links.
     *
     * @param {Array} promotions - CMS data model promotion objects.
     * @param {Mojito.Core.Base.AbstractRouteResolver} routeResolver - Route resolver instance.
     * @returns {Array} The childItem objects corresponding to the available CMS links.
     *
     * @function Mojito.Modules.PageContextMenu.Utils.CmsLinksUtils#getCmsLinks
     */
    static getCmsLinks(promotions, routeResolver) {
        return promotions.map(promotion => {
            const { id, linkText, target, imgUrl, canonicalLinkText, altText } = promotion;
            const label = linkText || id;
            const intent = PromotionUtils.resolveIntentFromPromotion(promotion);
            const hrefLink =
                (target && target.url) ||
                (intent && routeResolver && routeResolver.getRoute(intent.data)) ||
                '';
            return {
                label,
                hrefLink,
                icon: imgUrl,
                intent: intent,
                analyticsData: {
                    type: CMS_LINK,
                    linkName: canonicalLinkText || label,
                },
                className: `ta-${CMS_LINK.toLowerCase()}-${id}`,
                altText: altText,
            };
        });
    }
}
