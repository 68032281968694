import PropTypes from 'prop-types';
import MojitoCore from 'mojito/core';
import InplayEventControllerImpl from './inplay-event-controller.jsx';

const { UIView } = MojitoCore.Presentation;

/**
 * InplayEventController module to handle routing to event page with in-play event.
 *
 * @class InplayEventController
 * @memberof Mojito.Applications.Sports
 */

/**
 * InplayEventController prop types.
 *
 * @property {boolean} [isInplayRoute = false] - True if event page should render in play content.
 *
 * @memberof Mojito.Applications.Sports.InplayEventController
 */

const propTypes = {
    isInplayRoute: PropTypes.bool,
};

const defaultProps = {
    isInplayRoute: false,
};

export default UIView('InplayEventController', InplayEventControllerImpl, propTypes, defaultProps);
