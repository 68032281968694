import MojitoCore from 'mojito/core';
import ItemListTypes from 'services/item-list/types.js';

const DataProvider = MojitoCore.Services.DataProvider;
const { COLLECTION_NAME } = ItemListTypes;

/**
 *
 * Class offering the possibility to link to generic item lists.
 *
 * @class ItemListProvider
 * @private
 * @extends Mojito.Core.Services.DataProvider
 * @memberof Mojito.Services.ItemList
 */
class ItemListProvider extends DataProvider {
    init() {
        super.addSimpleCollection(COLLECTION_NAME);
    }

    subscribeToEntity(listId, setter) {
        return super.subscribeToEntity(listId, setter, COLLECTION_NAME);
    }
}

export default new ItemListProvider();
