/**
 * Enum representing affixes for subscription strings.
 *
 * @enum {string}
 */
const SUBSCRIPTION_CONSTANTS = {
    // Standalone name of the subscription. Example of subscription: 'sports'.
    SPORTS: 'sports',
    // Prefix for generating subscription to get sports with specific details. Example of subscription: 'sports-with-live-match-events-small'.
    SPORTS_WITH: 'sports-with',
    // Suffix for generating subscription to get future outright events for specific sport. Example of subscription: 'soccer-future-outright-events'.
    FUTURE_OUTRIGHT_EVENTS: '-future-outright-events',
    // Suffix for generating subscription to get future match events medium for specific sport. Example of subscription: 'soccer-future-match-events-medium'.
    FUTURE_MATCH_EVENTS_MEDIUM: '-future-match-events-medium',
    // Suffix for generating subscription to get live match events small for specific sport. Example of subscription: 'soccer-live-match-events-small'.
    LIVE_MATCH_EVENTS_SMALL: '-live-match-events-small',
    // Suffix for generating subscription to get live and upcoming matches and outright events for specific sport. Example of subscription: 'soccer-live-and-upcoming-match-and-outright-events'.
    LIVE_AND_UPCOMING_MATCH_AND_OUTRIGHT_EVENTS: '-live-and-upcoming-match-and-outright-events',
    // Suffix for generating subscription to get all virtual events for specific type. Example of subscription: '12345-all-virtual-events'.
    ALL_VIRTUAL_EVENTS: '-all-virtual-events',
    // Suffix for generating subscription to get all outright events for specific type. Example of subscription: '10095-all-outright-events'.
    ALL_OUTRIGHT_EVENTS: '-all-outright-events',
    // Suffix for generating subscription to get all match events for specific type. Example of subscription: '17594-all-match-events'.
    ALL_MATCH_EVENTS: '-all-match-events',
    // Suffix for generating subscription to get match data by class. Example of subscription: 'soccer-soccer-ua-match-all-types-by-class'.
    MATCH_ALL_TYPES_BY_CLASS: '-match-all-types-by-class',
    // Used in vision only.
    LOCATIONS_WITH_MEETINGS: 'locations-with-meetings',
};

export default SUBSCRIPTION_CONSTANTS;
