import MojitoNGen from 'mojito/ngen';

const log = MojitoNGen.logger.get('SubscriptionResolverFactory');

const voidResponder = () => log.warn('Subscription resolver instance is not set');
const NULL_SUBSCRIPTION_RESOLVER = {
    getParser: voidResponder,
    sports: voidResponder,
    sportsWithLiveAndUpcomingMatchAndOutrightEvents: voidResponder,
    sportsWithLiveMatchEvents: voidResponder,
    sportsWithFutureMatchEvents: voidResponder,
    cmsCouponPrefix: voidResponder,
    topLeagues: voidResponder,
    topOutrights: voidResponder,
    specificSport: voidResponder,
    futureOutrightEvents: voidResponder,
    futureMatchEvents: voidResponder,
    liveMatchEvents: voidResponder,
    liveAndUpcomingMatchAndOutrightEvents: voidResponder,
    allOutrightEvents: voidResponder,
    allMatchEvents: voidResponder,
    allVirtualEvents: voidResponder,
    matchAllTypesByClass: voidResponder,
};

/**
 * Singleton object that receives subscriptionResolver config and spawn subscription resolver instance.
 *
 * @class SubscriptionResolverFactory
 * @name subscriptionResolverFactory
 * @memberof Mojito.Applications.Sports
 */
class SubscriptionResolverFactory {
    constructor() {
        this.subscriptionResolver = NULL_SUBSCRIPTION_RESOLVER;
    }

    /**
     * Set subscription resolver.
     *
     * @param {Mojito.Applications.Sports.SubscriptionResolver} subscriptionResolver - Subscription resolver instance.
     * @function Mojito.Applications.Sports.SubscriptionResolver.subscriptionResolverFactory#setSubscriptionResolver
     */
    setSubscriptionResolver(subscriptionResolver) {
        this.subscriptionResolver = subscriptionResolver || NULL_SUBSCRIPTION_RESOLVER;
    }

    /**
     * Get subscription resolver.
     *
     * @returns {Mojito.Applications.Sports.SubscriptionResolver} Subscription resolver instance.
     * @function Mojito.Applications.Sports.SubscriptionResolver.subscriptionResolverFactory#getSubscriptionResolver
     */
    getSubscriptionResolver() {
        return this.subscriptionResolver;
    }
}

export default new SubscriptionResolverFactory();
