import MojitoServices from 'mojito/services';

/*
This is a temporary workaround done for HollandCasino v23.2.1 in the context of DBX-10610.
It needs to be reverted back when the content is fixed at Mojito backend level or outside of Mojito.
 */
MojitoServices.SportsContent.Events.utils.getPrice = function (prices) {
    if (prices) {
        return prices[0];
    }
};
