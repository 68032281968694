import OutrightEventCardModuleImpl from './outright-event-card-module.jsx';
import MojitoCore from 'mojito/core';
import PropTypes from 'prop-types';

/**
 * Outright event card module.
 *
 * @class OutrightEventCardModule
 * @name module
 *
 * @extends Mojito.Core.Presentation.UIControllerView
 * @memberof Mojito.Modules.EventCards.Outright
 */

/**
 * Outright event card prop types.
 *
 * @property {string} eventId - Id of event to show.
 * @property {string} marketId - Id of market to show.
 * @property {boolean} [shouldRequestData = true] - Flag indicating whether component should request event and market data from content. If false, then neither event nor markets will be requested, hence in order to present info corresponding data should be already available in a store.
 * @property {boolean} [showSkeleton = true] - True if data loading skeleton should be visible. It can be useful to hide skeleton if parent component already covers component with its of top level skeleton.
 * @property {boolean} [reportLoadingStatus = false] - Flag indicating if component should register itself to track on data loading and rendering status which ends up in reporting `Mojito.Core.Services.Performance.types.TIMELINE_RECORD_TYPES.PAGE_READY` metric once all visible components on the page are rendered and presenting data.
 * @property {object | Function} [elementRef] - A ref to the rendered element.
 *
 * @memberof Mojito.Modules.EventCards.Outright.module
 */
const propTypes = {
    eventId: PropTypes.string.isRequired,
    marketId: PropTypes.string.isRequired,
    shouldRequestData: PropTypes.bool,
    showSkeleton: PropTypes.bool,
    reportLoadingStatus: PropTypes.bool,
    elementRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

const defaultProps = {
    shouldRequestData: true,
    showSkeleton: true,
    reportLoadingStatus: false,
};

export default MojitoCore.Presentation.UIControllerView(
    'OutrightEventCardModule',
    OutrightEventCardModuleImpl,
    propTypes,
    defaultProps
);
