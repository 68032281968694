import {constructTilePalette} from '#core/application/modules/tiles-cache.js';

import {absoluteUrlToInternalResource} from './url-utils.js';

export function getResettingCSSForContainerId(id) {
    const FONT = constructTilePalette('FONT');

    return `
            html {
                -webkit-text-size-adjust: 100%;
            }
            #${id} *:not(.ta-VideoStream *, .ta-VideoStream *:active, #sr-widget *, #sr-widget *:hover, #KambiBC *, .ta-Statscore *),
            #${id} *:not(.ta-Statscore *):before,
            #${id} *:after, #${id} *:visited {
                line-height: initial;
                letter-spacing: initial;
                text-decoration: initial;
                box-sizing: initial;
                -webkit-text-size-adjust: initial;
                outline: initial;
                color: inherit;
                background: initial;
                background-color: initial;
                z-index: initial;
                font-weight: inherit;
                font-size: inherit;
                border: initial;
                padding: initial;
                margin: initial;
            }
            #${id} {
                font-family: ${FONT.FAMILY};
            }            
            #${id} [aria-hidden=true] *:not(.ta-VideoStream *, .ta-VideoStream *:active, #sr-widget *, #sr-widget *:hover){
                visibility: initial;
            }
            #${id} input[type="number"] {
                -webkit-appearance: textfield !important;
            }
            #${id} input::-webkit-outer-spin-button,
            #${id} input::-webkit-inner-spin-button {
                display: none;
            }
       `; // TODO: find a better solution
    // Input styles above hides the increment and decrement buttons on number inputs, copied from Mojito Refbook to solve DBX-12286
}

export function getCSSFromFontsArray(fonts) {
    if (!fonts) {
        return '';
    }

    return fonts.reduce((reducer, font) => {
        let fontFormat = font.url && font.url.split('.').pop();
        switch (fontFormat) {
            case 'otf':
                fontFormat = 'opentype';
                break;
            case 'ttf':
                fontFormat = 'truetype';
                break;
            case 'woff2':
                fontFormat = 'woff2';
        }

        const {
            family,
            style = 'normal',
            weight,
            display = 'swap',
            url,
            format = fontFormat,
            unicodeRange,
            ascentOverride,
            descentOverride,
        } = font;
        const fontSrc = absoluteUrlToInternalResource(url);

        return `${reducer}
                @font-face {
                    font-family: '${family}';
                    font-style: ${style};
                    font-weight: ${weight};
                    font-display: ${display};
                    src: url(${fontSrc}) format('${format}');
                    ${ascentOverride ? 'ascent-override: ' + ascentOverride + ';' : ''}
                    ${descentOverride ? 'descent-override: ' + descentOverride + ';' : ''}
                    ${unicodeRange ? 'unicode-range: ' + unicodeRange + ';' : ''}
                }`;
    }, '');
}

export function checkFontFaceAvailable(family) {
    let found = false;
    document.fonts.forEach(ff => (found = found || ff.family.replaceAll('"', '') === family));
    return found;
}
