/**
 * Item list types.
 *
 * @class ItemListTypes
 * @name types
 * @memberof Mojito.Services.ItemList
 */
export default class ItemListTypes {}

/**
 * Item list collection name.
 *
 * @typedef COLLECTION_NAME
 * @type {string}
 * @memberof Mojito.Services.ItemList.types
 */
ItemListTypes.COLLECTION_NAME = 'items/list';
