import PropTypes from 'prop-types';
import EventListHeaderImpl from './event-list-header.jsx';
import MojitoCore from 'mojito/core';
import { noop } from 'mojito/utils';

const UIView = MojitoCore.Presentation.UIView;

/**
 * This view renders the header for the event list.
 *
 * @class EventListHeader
 * @memberof Mojito.Modules.EventList
 */

/**
 * Callback type definition for market option changes.
 *
 * @param {string} marketOptionId - The id of the market option that changed.
 * @param {string} switcherId - The id of the market switcher that was used.
 *
 * @callback Mojito.Modules.EventList.MarketOptionChange
 * @memberof Mojito.Modules.EventList
 */

/**
 * EventListHeader prop types.
 *
 * @property {string} titleText - The text to be used as the event list header.
 * @property {boolean} isGameLineMode - Flag indicating whether the game line switcher should be displayed instead of market switchers to toggle between market options.
 * @property {boolean} isInPlay - Flag indicating if the component is operating under an in-play coupon context. This can affect the appearance of controls and their behavior.
 * @property {Array<string>} marketOptions - List of market options. Will be used to display either market switchers or game line switchers. Each option in a list should have corresponding l10n key in EVENT_LIST_HEADER.MARKET_OPTIONS object.
 * @property {Array<string>} selectedMarketOptions - Array defines selected market options. The index of an item in array reflects switcher index. In case game line switcher is show or in mobile mode, this list contains only one item.
 * @property {string} sportId - An optional identifier for the sport which may be used for analytics.
 * @property {number} numberOfMarketSwitchers - The number of market option switchers displayed. If this number is equal to or larger than the length of `marketOptions`, each market option will be displayed as a simple text label. This property is ignored when `isGameLineMode` flag is `true`.
 * @property {Mojito.Modules.EventList.MarketOptionChange} onSelectedMarketOptionChange - A callback triggered when a selected market option changes.
 * @property {object | Function} [marketSectionRef] - A reference to the rendered element of the market section.
 * @property {boolean} [enableSorting = false] - Flag to display sorting functionality.
 * @property {boolean} [showEarlyPayoutOffers = false] - Flag to display or hide early payout offers.
 * @property {Function}  [onSortingClicked = ()=>{}] - Sorting button handler function.
 * @property {Array<object>} eventGroups - Groups of events to get early payout info.
 * @property {string} sortingTitle - The sorting button title.
 *
 * @memberof Mojito.Modules.EventList.EventListHeader
 */
const propTypes = {
    titleText: PropTypes.string,
    isGameLineMode: PropTypes.bool,
    isInPlay: PropTypes.bool,
    marketOptions: PropTypes.array,
    selectedMarketOptions: PropTypes.array,
    sportId: PropTypes.string,
    numberOfMarketSwitchers: PropTypes.number,
    onSelectedMarketOptionChange: PropTypes.func,
    marketSectionRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    enableSorting: PropTypes.bool,
    showEarlyPayoutOffers: PropTypes.bool,
    onSortingClicked: PropTypes.func,
    eventGroups: PropTypes.array,
    sortingTitle: PropTypes.string,
};

const defaultProps = {
    isGameLineMode: false,
    onSelectedMarketOptionChange: noop,
    enableSorting: false,
    showEarlyPayoutOffers: false,
    onSortingClicked: noop,
};

export default UIView('EventListHeader', EventListHeaderImpl, propTypes, defaultProps);
