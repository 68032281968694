/**
 * Date time utils types.
 *
 * @class DateTimeTypes
 * @memberof Mojito.Core.Base
 */
export default class DateTimeTypes {}

/**
 * Timezone offset format.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Core.Base.DateTimeTypes
 */
DateTimeTypes.OFFSET_FORMAT = {
    /** Defines offset representation in minutes. */
    MINUTES: 'MINUTES',
    /** Defines offset representation in hours. */
    HOURS: 'HOURS',
};

/**
 * Date format options.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Core.Base.DateTimeTypes
 */
DateTimeTypes.DATE_FORMAT_OPTIONS = {
    YEAR_LONG: 'YYYY',
    MONTH_NUMERIC: 'MM',
    DAY_NUMERIC_LONG: 'DD',
    DAY_NUMERIC_SHORT: 'D',
    ORDINAL_DAY: 'd',
    MONTH_SHORT_NAME: 'MMM',
    MONTH_FULL_NAME: 'MMMM',
    WEEKDAY_SHORT_NAME: 'EEE',
    WEEKDAY_LONG_NAME: 'EEEE',
};
