import { isEmpty } from 'mojito/utils';
import MojitoCore from 'mojito/core';
import { createSlice } from '@reduxjs/toolkit';
import channelFactory from 'services/common/content/content-channel-factory.js';
import lotteryProvider from './provider/index.js';
import LotteryDescriptor from './descriptor.js';
import ServicesTypes from 'services/common/types.js';
import ServicesUtils from 'services/common/utils.js';

const reduxInstance = MojitoCore.Services.redux;
const { actionsRegistry } = MojitoCore.Services.Content;
const { LOTTERY, LOTTERY_CHUNK } = LotteryDescriptor.DATA_TYPES;
const { AVAILABLE, UNAVAILABLE, UNKNOWN } = ServicesTypes.CONTENT_STATE;
export const getLotteryChannel = () => channelFactory.getChannel(lotteryProvider, LOTTERY);

/**
 * Defines the state of the lottery store.
 *
 * @typedef LotteryState
 *
 * @property {object} lotteries - Map of lotteries where each key is a lottery id and value is lottery.
 * @property {Object<string, Mojito.Services.Common.types.CONTENT_STATE>} lotteriesState - Map of lotteries lifetime state. Key is lottery id and value is of type {@link Mojito.Services.SportsContent.Common.types.CONTENT_STATE|CONTENT_STATE}.
 *
 * @memberof Mojito.Services.Lottery
 */

/**
 * The name of the lottery store. Will be used to register in global redux store.
 *
 * @constant
 * @type {string}
 * @memberof Mojito.Services.Lottery
 */
export const STORE_KEY = 'lotteryStore';

export const INITIAL_STATE = {
    lotteries: {},
    lotteriesState: {},
};

const reducers = {
    updateLottery(state, { payload }) {
        const { id, data } = payload;
        state.lotteries[id] = data;
        state.lotteriesState[id] = data ? AVAILABLE : UNAVAILABLE;
    },
    updateLotteries(state, { payload: lotteries }) {
        lotteries.forEach(lottery => {
            const payload = { id: lottery.id, data: lottery.data };
            reducers.updateLottery(state, { payload });
        });
    },
    removeLotteries(state, { payload: ids = [] }) {
        ids.forEach(id => {
            delete state.lotteries[id];
            state.lotteriesState[id] = UNKNOWN;
        });
    },
    pendingLotteries: ServicesUtils.createPendingHandler('lotteriesState'),
    reset() {
        return { ...INITIAL_STATE };
    },
};

export const { reducer, actions } = createSlice({
    name: 'lottery',
    initialState: INITIAL_STATE,
    reducers,
});

/**
 * Subscribe to lottery.
 *
 * @function subscribe
 *
 * @param {{lotteryIds: Array<string>, clientId: string}} payload - Subscription payload.
 * @returns {Mojito.Core.Services.redux.ThunkFunction} Subscribe to lottery thunk.
 * @memberof Mojito.Services.Lottery.actions
 */
actions.subscribe = payload => dispatch => {
    const { lotteryIds, clientId } = payload;
    const pendingLotteryIds = getLotteryChannel().subscribe(lotteryIds, clientId, (id, data) => {
        dispatch(actions.updateLottery({ id, data }));
    });
    !isEmpty(pendingLotteryIds) && dispatch(actions.pendingLotteries(pendingLotteryIds));
};

/**
 * Triggers lottery chunk subscription for multiple clients.
 *
 * @function subscribeLotteryChunk
 *
 * @param {object} payload - Subscription payload. Contains the list of lottery requests for each client subscriber.
 * @returns {Mojito.Core.Services.redux.ThunkFunction} Subscribe to lottery thunk.
 * @memberof Mojito.Services.Lottery.actions
 */
actions.subscribeLotteryChunk = payload => {
    return dispatch => {
        const { requests } = payload;

        const onInit = chunk => dispatch(actions.updateLotteries(chunk));
        const onUpdate = (lotteryId, lottery) =>
            dispatch(actions.updateLottery({ lotteryId, lottery }));

        const pendingLotteryIds = getLotteryChannel().subscribeMultiple(requests, onInit, onUpdate);
        queueMicrotask(() => {
            !isEmpty(pendingLotteryIds) && dispatch(actions.pendingLotteries(pendingLotteryIds));
        });
    };
};

/**
 * Unsubscribe client from lotteries.
 *
 * @function unsubscribe
 *
 * @param {string} clientId - The id of subscriber which aims to be unsubscribed.
 * @returns {Mojito.Core.Services.redux.ThunkFunction} Unsubscribe from lotteries thunk.
 * @memberof Mojito.Services.Lottery.actions
 */
actions.unsubscribe = clientId => dispatch => {
    getLotteryChannel().unsubscribeAll(clientId, ids => {
        dispatch(actions.removeLotteries(ids));
    });
};

// Register thunks in common registry to use them by request data helper.
actionsRegistry.addSubscription(LOTTERY, actions.subscribe, actions.unsubscribe);
actionsRegistry.addSubscription(LOTTERY_CHUNK, actions.subscribeLotteryChunk);
reduxInstance.injectReducer(STORE_KEY, reducer);

/**
 * Lottery store actions.
 *
 * @class LotteryActions
 * @name actions
 * @memberof Mojito.Services.Lottery
 */

/**
 * Update lottery.
 *
 * @function updateLottery
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @param {object} payload - Payload for lottery update.
 * @param {string} payload.id - Lottery id.
 * @param {object|undefined} payload.data - Lottery object.
 * @memberof Mojito.Services.Lottery.actions
 */

/**
 * Update lotteries.
 *
 * @function updateLotteries
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @param {{lotteries: Array<object>}} payload - List of lotteries to update.
 * @memberof Mojito.Services.Lottery.actions
 */

/**
 * Remove lotteries.
 *
 * @function removeLotteries
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @param {{lotteryIds: Array<string>}} payload - List of lottery id's for lotteries.
 * @memberof Mojito.Services.Lottery.actions
 */

/**
 * Pending lotteries.
 *
 * @function pendingLotteries
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @param {{lotteryIds: Array<string>}} payload - Id's of pending lotteries.
 * @memberof Mojito.Services.Lottery.actions
 */

/**
 * Reset lottery state.
 *
 * @function reset
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @memberof Mojito.Services.Lottery.actions
 */
