import ApplicationOverlayImpl from './app-overlay.jsx';
import MojitoCore from 'mojito/core';
import PropTypes from 'prop-types';
import { SLIDING_VIEW_TYPES } from 'applications/sports/app/view/types.js';

const UIView = MojitoCore.Presentation.UIView;

/**
 * AppOverlay module to render components that should be displayed in overlay on top of the application content.
 *
 * @class AppOverlay
 * @name view
 * @memberof Mojito.Applications.Sports.AppOverlay
 */

/**
 * AppOverlay prop types.
 *
 * @property {string} [selectedSportId] - The id of the selected sport.
 * @property {Mojito.Applications.Sports.AppView.SLIDING_VIEW_TYPES} [activeSlidingViewType] - The type of the sliding view that should be shown. See {@link Mojito.Applications.Sports.AppView.SLIDING_VIEW_TYPES|SLIDING_VIEW_TYPES}.
 * @property {string} [activeSlidingViewData] - Data for activeSlidingViewType, if applicable.
 *
 * @memberof Mojito.Applications.Sports.AppOverlay.view
 */
const propTypes = {
    selectedSportId: PropTypes.string,
    activeSlidingViewType: PropTypes.oneOf(Object.values(SLIDING_VIEW_TYPES)),
    activeSlidingViewData: PropTypes.object,
};

export default UIView('AppOverlay', ApplicationOverlayImpl, propTypes);
