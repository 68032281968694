/**
 * Data providers types.
 *
 * @class DataProvidersTypes
 * @name types
 * @memberof Mojito.Services.SportsContent.DataProviders
 */
export default class DataProvidersTypes {}

/**
 * Container provider types.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Services.SportsContent.DataProviders.types
 */
DataProvidersTypes.ContainerProvider = { COLLECTION_NAME: 'container' };

/**
 * Event provider types.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Services.SportsContent.DataProviders.types
 */
DataProvidersTypes.EventProvider = { COLLECTION_NAME: 'events' };

/**
 * Market groups provider types.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Services.SportsContent.DataProviders.types
 */
DataProvidersTypes.MarketGroupsProvider = { COLLECTION_NAME: 'marketgroup' };

/**
 * Market provider types.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Services.SportsContent.DataProviders.types
 */
DataProvidersTypes.MarketProvider = { COLLECTION_NAME: 'markets' };

/**
 * Meeting provider types.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Services.SportsContent.DataProviders.types
 */
DataProvidersTypes.MeetingProvider = { COLLECTION_NAME: 'raceMeetings' };

/**
 * Menus provider types.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Services.SportsContent.DataProviders.types
 */
DataProvidersTypes.MenusProvider = { COLLECTION_NAME: 'sportmenu' };

/**
 * Race region provider types.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Services.SportsContent.DataProviders.types
 */
DataProvidersTypes.RaceRegionProvider = { COLLECTION_NAME: 'raceRegions' };

/**
 * Sport meta information provider types.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Services.SportsContent.DataProviders.types
 */
DataProvidersTypes.SportMetaInformationProvider = { COLLECTION_NAME: 'sportMetaInformation' };
