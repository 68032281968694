import MojitoCore from 'mojito/core';
import createThunks from './thunks.js';
import { actions as authenticationActions } from 'services/authentication/slice.js';
import { actions as eventActions } from 'services/sports-content/events/slice.js';
import { selectEventItem } from 'services/sports-content/events/selectors.js';
import { selectOddsFormat } from 'services/user-settings/selectors.js';
import * as helper from './helper.js';
import betslipDataRetriever from './data-retriever.js';
import BetslipTypes from './types';
import BetslipUtils from './utils';
import { pick, groupBy, uniq, isEmpty, mapValues } from 'mojito/utils';
import { selectLoginState } from 'services/authentication/selectors';
import AuthenticationTypes from 'services/authentication/types.js';

const { STATES } = AuthenticationTypes;

const CoreUtils = MojitoCore.Utils;
const reduxInstance = MojitoCore.Services.redux;
const {
    BETSLIP_STORAGE_TYPE,
    ERROR_CODE,
    BETSLIP_STATUS,
    PRICE_CHANGE_TYPE,
    PART_UPDATE_FACTOR,
    STORE_KEY,
} = BetslipTypes;
const ObjUtils = MojitoCore.Base.objUtils;
const DEFAULT_STORAGE_TIME = 2;
const DEFAULT_MAX_SELECTION_COUNT = 0;

/**
 * Betslip store config.
 *
 * @typedef BetslipStoreConfig
 * @type {object}
 * @property {number} [storageTimeInHours = 2] - Defines betslip state persistence time in hours.
 * @property {boolean} [allowBetPlacement = true] - True if bet placement is allowed, false otherwise.
 * @property {boolean} [enableUserFundsValidation = true] - True if user balances should be checked before bet placement, false otherwise.
 * @property {boolean} [enableBonusValidation = true] - True if bonuses validation should be enabled. Typically needed to validate acc bonuses and ETB.
 * @property {number} [maxSelectionsCount = 0] - Maximum number of selections allowed in betslip. 0 means there are no maximum amount of allowed selections.
 *
 * @memberof Mojito.Services.Betslip
 */

/**
 * Defines the state of the betslip store.
 * Apart from definitions here the state also contains {@link Mojito.Services.Betslip.BetslipStoreConfig|store config}.
 *
 * @typedef BetslipState
 *
 * @type {object}
 * @property {string} betslipState - The state of the betslip. Typically, used by backend and should never be accessed from client.
 * @property {Mojito.Services.Betslip.types.BETSLIP_STATUS} betslipStatus - Betslip status.
 * @property {Mojito.Services.Betslip.types.Betslip} betslip - Betslip object.
 * @property {Mojito.Services.Betslip.types.Receipt} receipt - Receipt object.
 * @property {object} selectionsState - Selections state object.
 * @property {object} betsState - Bets state object.
 * @property {Array} validationErrors - List of validation errors.
 * @property {Mojito.Services.Betslip.types.STAKE_GROUP_NAME} lastActivatedStakeGroup - Last activated stake group.
 * @property {boolean} bonusValidationPending - Flag to indicate if bonus validation is pending.
 * @property {boolean} bankersActivated - Flag to indicate if bankers mode is active.
 * @property {boolean} stakeGroupActivationPending - Flag to indicate if stake group activation is pending.
 * @property {Mojito.Services.Betslip.types.Overask} overask - Overask object.
 * @property {Mojito.Services.Betslip.types.Betslip} lastBetslip - Last betslip from response.
 * @property {Mojito.Services.Betslip.types.BetslipSettings} betslipSettings - Price change acceptance settings.
 * @property {boolean} anonymous - Flag to indicate if user is anonymous.
 *
 * @memberof Mojito.Services.Betslip
 */

const INITIAL_STATE = {
    initialized: false,
    storageTimeInHours: DEFAULT_STORAGE_TIME,
    allowBetPlacement: true,
    enableUserFundsValidation: true,
    deepLinkingBets: undefined,
    betslipState: undefined,
    betslipStatus: undefined,
    betslip: {},
    receipt: undefined,
    selectionsState: {},
    betsState: {},
    validationErrors: [],
    lastActivatedStakeGroup: undefined,
    bonusValidationPending: false,
    bankersActivated: false,
    stakeGroupActivationPending: false,
    overask: undefined,
    lastBetslip: undefined,
    affectedMarkets: [],
    affectedEvents: [],
    betslipSettings: {
        priceChangeAcceptance: {
            [PRICE_CHANGE_TYPE.HIGHER]: false,
            [PRICE_CHANGE_TYPE.LOWER]: false,
        },
        quickBetslipEnabled: true,
    },
    anonymous: true,
};

const reducers = {
    configure: (state, { payload: config = {} }) => {
        const {
            storageTimeInHours,
            enableBonusValidation = true,
            allowBetPlacement = true,
            enableUserFundsValidation = true,
            maxSelectionsCount,
        } = config;
        const storageTime = Number(storageTimeInHours);
        state.storageTimeInHours = !isNaN(storageTime) ? storageTime : DEFAULT_STORAGE_TIME;
        state.enableBonusValidation = enableBonusValidation;
        state.allowBetPlacement = allowBetPlacement;
        state.enableUserFundsValidation = enableUserFundsValidation;
        state.maxSelectionsCount = maxSelectionsCount || DEFAULT_MAX_SELECTION_COUNT;
    },
    reset: state => {
        state.betslipState = undefined;
        state.betslipStatus = undefined;
        state.betslip = {};
        state.receipt = undefined;
        state.selectionsState = {};
        state.betsState = {};
        state.validationErrors = [];
        state.lastActivatedStakeGroup = undefined;
        state.bankersActivated = false;
        state.bonusValidationPending = false;
        state.stakeGroupActivationPending = false;
        state.overask = undefined;
        state.lastBetslip = undefined;
        state.affectedMarkets = [];
        state.affectedEvents = [];
        state.anonymous = true;
    },
    hardReset: () => INITIAL_STATE,
    betslipResponseSuccess: (state, { payload }) => {
        if (!payload) {
            return;
        }
        const { state: betslipState, betslip = {}, receipt, status, overask, anonymous } = payload;
        const { priceChangeAcceptance } = state.betslipSettings;
        state.betslipState = betslipState;
        state.lastActivatedStakeGroup = helper.resolveActiveStakeGroup(
            betslip,
            state.betslip,
            state.lastActivatedStakeGroup
        );
        helper.processSelectionsState(betslip, state.selectionsState);
        helper.sanitizeErrors(betslip, state.lastBetslip, priceChangeAcceptance);
        helper.processBetsState(betslip, state.betsState);
        helper.processMatchAccBetsState(betslip, state.betsState);
        state.betslip = betslip;
        state.anonymous = anonymous;
        state.receipt = receipt;
        state.betslipStatus = status;
        state.overask = overask;
        if (!isEmpty(state.betslip)) {
            // We will store the latest available betslip for cases when we want to access its data for future purposes.
            // For example to detect the ODDS_CHANGE direction (up|down) if this error arrives after user clicks "retain selections" from receipt.
            // We can't relay on this.betslip as it will be erased once receipt or overask is available.
            // The property should never be exposed externally.
            // Example: place bet -> got receipt and betslip is undefined -> price changed on one of selection ->
            // retain selections -> betslip is back with updated price and ODDS_CHANGE error ->
            // we need either highlight price change or skip it if user accepted this changes.
            state.lastBetslip = state.betslip;
        }
    },
    betslipResponseFailed: state => {
        reducers.reset(state);
        state.betslipStatus = BETSLIP_STATUS.FAILED;
    },
    initSuccess: (state, action) => {
        reducers.betslipResponseSuccess(state, action);
        state.initialized = true;
    },
    selectionPending: (state, action) => {
        reducers.updatePendingOnInteraction(state, action);
        const { id, isPending } = action.payload;
        helper.setSelectionPending(id, isPending, state.selectionsState);
    },
    addPartSuccess: (state, { payload }) => {
        reducers.betslipResponseSuccess(state, { payload });
        const { betslip, requestPayload } = payload;
        const { id: selectionId } = requestPayload.selection;
        const failedToAddPart = !BetslipUtils.getSingleBetBySelectionId(betslip, selectionId);
        // There are limitation on max amount of selections on certain providers,
        // this causes our selections to hang in a pending state. This code solves that.
        if (failedToAddPart) {
            delete state.selectionsState[selectionId];
        } else {
            helper.setSelectionPending(selectionId, false, state.selectionsState);
        }
    },
    removePartSuccess: (state, { payload }) => {
        reducers.betslipResponseSuccess(state, { payload });
        const { requestPayload } = payload;
        const { selectionId } = requestPayload;
        delete state.selectionsState[selectionId];
    },
    refreshBonusOffersSuccess: (state, { payload }) => {
        const { state: betslipState } = payload;
        state.betslipState = betslipState;
    },
    updatePendingOnInteraction: state => {
        state.betslipStatus = BETSLIP_STATUS.UPDATING;
        state.validationErrors = [];
    },
    updatePending: state => {
        state.betslipStatus = BETSLIP_STATUS.UPDATING;
    },
    updateAffectedMarkets: (state, { payload }) => {
        state.affectedMarkets = [...state.affectedMarkets, payload];
    },
    updateAffectedEvents: (state, { payload }) => {
        state.affectedEvents = [...state.affectedEvents, payload];
    },
    resetAffectedMarkets: state => {
        state.affectedMarkets = [];
    },
    resetAffectedEvents: state => {
        state.affectedEvents = [];
    },
    activateStakeGroupPending: (state, action) => {
        reducers.updatePendingOnInteraction(state, action);
        const { payload: stakeGroupName } = action;
        state.stakeGroupActivationPending = true;
        state.lastActivatedStakeGroup = stakeGroupName;
    },
    activateStakeGroupSuccess: (state, action) => {
        reducers.betslipResponseSuccess(state, action);
        state.stakeGroupActivationPending = false;
    },
    betPlacementAvailable: (state, { payload: allowBetPlacement }) => {
        state.allowBetPlacement = allowBetPlacement;
    },
    updateDeepLinkingBets: (state, { payload: deepLinkingBets }) => {
        state.deepLinkingBets = deepLinkingBets;
    },
    betslipValidationFailed: (state, { payload: validationErrors }) => {
        state.validationErrors = validationErrors;
    },
    placeBetslipPending: state => {
        state.validationErrors = [];
        state.betslipStatus = BETSLIP_STATUS.PENDING_PLACE;
    },
    clearReceipt: state => {
        state.receipt = undefined;
    },
    processErrors: (state, { payload: errors }) => {
        const { validationErrors } = state;
        const errorCodes = errors.map(e => e.code);
        state.validationErrors = validationErrors.filter(err => !errorCodes.includes(err.code));
    },
    bankersModeChange: (state, { payload: { active } }) => {
        state.bankersActivated = active;
    },
    toggleBankerPending: (state, { payload: betId }) => {
        const { betsState } = state;
        helper.setBankerPending(betId, true, betsState);
    },
    toggleBankerSuccess: (state, action) => {
        reducers.betslipResponseSuccess(state, action);
        const { betsState } = state;
        const { betId } = action.payload;
        helper.setBankerPending(betId, false, betsState);
    },
    acceptChanges: state => {
        const { betsState, betslip } = state;
        Object.keys(betsState).forEach(betId => {
            helper.setBetOddsChange(betId, false, betsState);
            helper.setBetHcapChange(betId, false, betsState);
        });
        // Clean all ODDS_CHANGE and HANDICAP_CHANGE errors if we got some from bet builder API.
        const { ODDS_CHANGE, HANDICAP_CHANGE } = ERROR_CODE;
        const { errors = [] } = betslip;
        betslip.errors = errors.filter(
            error => error.code !== ODDS_CHANGE && error.code !== HANDICAP_CHANGE
        );
    },
    discardChanges: (state, action) => {
        const betId = action.payload;
        helper.setBetOddsChange(betId, false, state.betsState);
        helper.setBetHcapChange(betId, false, state.betsState);
    },
    validateBonusesPending: state => {
        state.bonusValidationPending = true;
    },
    validateBonusesSuccess: (state, action) => {
        reducers.betslipResponseSuccess(state, action);
        state.bonusValidationPending = false;
    },
    validateBonusesFailed: state => {
        state.bonusValidationPending = false;
    },
    betslipSettingsUpdate: (state, { payload: settings }) => {
        state.betslipSettings = ObjUtils.merge(state.betslipSettings, settings || {});
    },
    updateSuspendedBetsState: (state, { payload: betStatuses }) => {
        betStatuses.forEach(({ betId, isSuspended, parts }) => {
            helper.setBetSuspended(betId, isSuspended, state.betsState);
            helper.setBetPartsSuspended(betId, parts, state.betsState);
        });
    },
    updateOddsChangeBetsState: (state, { payload: betStatuses }) => {
        const { priceChangeAcceptance } = state.betslipSettings;
        betStatuses.forEach(({ betId, price, prevPrice }) => {
            // If it was accepted - we don't need to highlight odds change at all.
            const oddsChanged = !BetslipUtils.isPriceChangeAccepted(
                price,
                prevPrice,
                priceChangeAcceptance
            );
            helper.setBetOddsChange(betId, oddsChanged, state.betsState);
        });
    },
    updateHcapChangeBetsState: (state, { payload: betStatuses }) => {
        betStatuses.forEach(({ betId, changed }) => {
            helper.setBetHcapChange(betId, changed, state.betsState);
        });
    },
    cleanSuspendErrors: state => {
        state.betslip.errors = (state.betslip.errors || []).filter(
            error => error.code !== ERROR_CODE.SUSPENDED
        );
    },
    [helper.combineActions(
        'addCompositeSuccess',
        'removeCompositeSuccess',
        'clearBetslipSuccess',
        'toggleFreeBetSuccess',
        'placeBetslipSuccess'
    )]: (state, action) => reducers.betslipResponseSuccess(state, action),

    [helper.combineActions(
        'initPending',
        'setStakePending',
        'selectionPending',
        'switchSelectionPending',
        'activateStakeGroupPending',
        'executeActionsPending',
        'clearBetslipPending',
        'addCompositePending',
        'removeBetPending',
        'overaskUpdatePending'
    )]: (state, action) => reducers.updatePendingOnInteraction(state, action),

    // These actions don't affect betslip reducer but are used by other verticals, e.g. analytics.
    [helper.combineActions(
        'activateTeaserTypePending',
        'placeBetslipAttempt',
        'clearBetslip',
        'toggleBankerPending',
        'refreshBonusOffersFailed',
        'selectionsLimitBreach'
    )]: state => state,
};

const { reducer, actions } = helper.createBetslipSlice({
    name: STORE_KEY,
    initialState: INITIAL_STATE,
    reducers,
    thunksCreator: createThunks,
    listenersCreator: actions => ({
        [helper.combineActions(
            actions.initSuccess,
            actions.addCompositeSuccess,
            actions.removeCompositeSuccess,
            actions.clearBetslipSuccess,
            actions.toggleFreeBetSuccess,
            actions.placeBetslipSuccess,
            actions.addPartSuccess,
            actions.removePartSuccess,
            actions.activateStakeGroupSuccess,
            actions.toggleBankerSuccess,
            actions.validateBonusesSuccess,
            actions.betslipResponseSuccess
        )]: (payload, dispatch, state) => {
            const { betslip, betslipState } = state;
            dispatch(actions.syncBonusOffers());
            const bankersInfo = BetslipUtils.isBetslipEmpty(betslip)
                ? { bankersActivated: false }
                : {};
            const gitVersion = CoreUtils.gitVersion();
            const date = new Date().toISOString();
            betslipDataRetriever.storeBetslipData(
                { state: betslipState, ...bankersInfo, gitVersion, date },
                BETSLIP_STORAGE_TYPE.DATA
            );
        },
        [actions.betslipResponseFailed]: () =>
            betslipDataRetriever.cleanBetslipData(BETSLIP_STORAGE_TYPE.DATA),
        [helper.combineActions(
            authenticationActions.loginSuccess,
            authenticationActions.logoutSuccess,
            authenticationActions.logoutFailed
        )]: (payload, dispatch, state, globalState) => {
            const loginState = selectLoginState(globalState);
            const isAuthSynced =
                (!state.anonymous && loginState === STATES.LOGGED_IN) ||
                (state.anonymous && loginState === STATES.LOGGED_OUT);
            !isAuthSynced && dispatch(actions.initBetslip());
        },
        [authenticationActions.disposeSession]: (payload, dispatch) => {
            dispatch(actions.syncBonusOffers());
        },
        [actions.clearBetslipSuccess]: (payload, dispatch, state) => {
            const { betslip, retainParts = [] } = payload;

            if (retainParts.includes(BetslipTypes.BETSLIP_PART.ITEMS)) {
                const eventUpdatesStrategy = {
                    getItemIdsFromBet: BetslipUtils.getEventIdFromBet,
                    getGroupingId: update => update.eventId,
                    updater: update => {
                        const eventId = update.eventId;
                        const event = update.event;
                        dispatch(eventActions.updateEvent({ eventId, event }));
                    },
                };
                const marketUpdatesStrategy = {
                    getItemIdsFromBet: BetslipUtils.getMarketIdsFromBet,
                    getGroupingId: update => update.marketId,
                    updater: update => {
                        const eventId = update.eventId;
                        const marketId = update.marketId;
                        const market = update.market;
                        dispatch(eventActions.updateMarket({ eventId, marketId, market }));
                    },
                };
                playContentUpdates(state.affectedEvents, eventUpdatesStrategy, betslip);
                playContentUpdates(state.affectedMarkets, marketUpdatesStrategy, betslip);
            }

            dispatch(actions.resetAffectedMarkets());
            dispatch(actions.resetAffectedEvents());

            function playContentUpdates(updatesQueue, strategy, betslip) {
                const singleBets = BetslipUtils.getSingleBets(betslip);
                const itemIdsInBetslip = uniq(singleBets.flatMap(strategy.getItemIdsFromBet));
                const updateQueueGroups = groupBy(updatesQueue, strategy.getGroupingId);
                const applicableUpdates = pick(updateQueueGroups, itemIdsInBetslip);
                const updates = Object.values(
                    mapValues(applicableUpdates, updates => updates.pop())
                );
                updates.forEach(strategy.updater);
            }
        },
        [eventActions.updateEvent]: (payload, dispatch, state, globalState) => {
            const { eventId, event } = payload;
            const affectedBets = BetslipUtils.getSingleBetsByEventId(state.betslip, eventId);

            // Between `place bet` and `retain selection` we collect updates
            if (isEmpty(state.betslip) && !isEmpty(state.lastBetslip)) {
                dispatch(actions.updateAffectedEvents({ eventId, event }));
            }

            if (helper.shouldIgnoreContentUpdate(affectedBets, state.betslipStatus, globalState)) {
                return;
            }

            const matchAccaUpdates = helper.resolveMatchAccaUpdates(
                affectedBets,
                state.betsState,
                event,
                globalState
            );
            matchAccaUpdates.forEach(updatePayload =>
                betslipDataRetriever.updateComposite(updatePayload)
            );

            const betStatuses = helper.getBetsStatuses(affectedBets, globalState);
            dispatch(actions.updateSuspendedBetsState(betStatuses));
        },
        [eventActions.updateMarket]: (payload, dispatch, state, globalState) => {
            const market = payload.market;
            const marketId = payload.marketId || market?.id;
            const eventId = payload.eventId;
            const affectedBets = BetslipUtils.getSingleBetsByMarketId(state.betslip, marketId);

            // Between `place bet` and `retain selection` we collect updates
            if (isEmpty(state.betslip) && !isEmpty(state.lastBetslip)) {
                dispatch(actions.updateAffectedMarkets({ eventId, marketId, market }));
            }

            if (helper.shouldIgnoreContentUpdate(affectedBets, state.betslipStatus, globalState)) {
                return;
            }
            const oddsFormat = selectOddsFormat(globalState);
            const event = selectEventItem(eventId, globalState);
            const isTeaserMode = BetslipUtils.isTeaser(state.lastActivatedStakeGroup);
            const partUpdates = helper.resolvePartUpdates(
                affectedBets,
                market,
                oddsFormat,
                isTeaserMode
            );
            const selectionsToUpdate = partUpdates.map(partUpd => partUpd.selection);
            !isEmpty(selectionsToUpdate) && betslipDataRetriever.updatePart(selectionsToUpdate);

            const matchAccaUpdates = helper.resolveMatchAccaUpdates(
                affectedBets,
                state.betsState,
                event,
                globalState,
                market
            );
            matchAccaUpdates.forEach(updatePayload =>
                betslipDataRetriever.updateComposite(updatePayload)
            );

            const resolveBetChanges = helper.betChangesResolver(
                partUpdates,
                market,
                state.betslip,
                oddsFormat
            );

            const betStatuses = helper.getBetsStatuses(affectedBets, globalState);
            const oddsChangeUpdates = resolveBetChanges(PART_UPDATE_FACTOR.ODDS);
            const hcapChangeUpdates = resolveBetChanges(PART_UPDATE_FACTOR.HCAP);

            !helper.hasSuspendedBet(state.betsState) && dispatch(actions.cleanSuspendErrors());
            dispatch(actions.updateSuspendedBetsState(betStatuses));
            dispatch(actions.updateOddsChangeBetsState(oddsChangeUpdates));
            dispatch(actions.updateHcapChangeBetsState(hcapChangeUpdates));
        },
    }),
});

/**
 * Betslip actions.
 *
 * @class BetslipActions
 * @name actions
 * @memberof Mojito.Services.Betslip
 */

/**
 * Configure store.
 * Everything in payload.config will be added as separate key/value in payload.
 *
 * @function configure
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @param {Mojito.Services.Betslip.BetslipStoreConfig} config - Configure payload.
 * @memberof Mojito.Services.Betslip.actions
 */

/**
 * Betslip response success.
 *
 * @function betslipResponseSuccess
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @param {Mojito.Services.Betslip.types.BetslipResponse} betslipData - Betslip response data.
 * @memberof Mojito.Services.Betslip.actions
 */

/**
 * Init success.
 * Everything in payload.response will be added as separate key/value in payload.
 *
 * @function initSuccess
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @param {Mojito.Services.Betslip.types.BetslipResponse} betslipData - Betslip response data.
 * @memberof Mojito.Services.Betslip.actions
 */

/**
 * Add part success.
 *
 * @function addPartSuccess
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @param {object} payload - Add part success payload. Contains items from {@link Mojito.Services.Betslip.types.BetslipResponse|BetslipResponse} and
 * requestPayload of type {@link Mojito.Services.Betslip.types.AddSelectionPartPayload|AddSelectionPartPayload}.
 * @memberof Mojito.Services.Betslip.actions
 */

/**
 * Remove part success.
 *
 * @function removePartSuccess
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @param {object} payload - Remove part success payload. Contains items from {@link Mojito.Services.Betslip.types.BetslipResponse|BetslipResponse} and
 * requestPayload of type {@link Mojito.Services.Betslip.types.RemovePartPayload|RemovePartPayload}.
 * @memberof Mojito.Services.Betslip.actions
 */

/**
 * Add composite success.
 *
 * @function addCompositeSuccess
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @param {object} payload - Add composite success payload. Contains items from {@link Mojito.Services.Betslip.types.BetslipResponse|BetslipResponse} and
 * requestPayload of type {@link Mojito.Services.Betslip.types.AddCompositePayload|AddCompositePayload}.
 * @memberof Mojito.Services.Betslip.actions
 */

/**
 * Remove composite success.
 *
 * @function removeCompositeSuccess
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @param {object} payload - Remove composite success payload. Contains items from {@link Mojito.Services.Betslip.types.BetslipResponse|BetslipResponse} and
 * requestPayload of type {@link Mojito.Services.Betslip.types.RemoveCompositePayload|RemoveCompositePayload}.
 * @memberof Mojito.Services.Betslip.actions
 */

/**
 * Clear betslip success.
 *
 * @function clearBetslipSuccess
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @param {object} payload - Clear betslip success payload. Contains items from {@link Mojito.Services.Betslip.types.BetslipResponse|BetslipResponse} and
 * retainParts which is a list of type {@link Mojito.Services.Betslip.types.BETSLIP_PART|BETSLIP_PART}.
 * @memberof Mojito.Services.Betslip.actions
 */

/**
 * Activate stake group success.
 *
 * @function activateStakeGroupSuccess
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @param {Mojito.Services.Betslip.types.BetslipResponse} betslipData - Betslip response data.
 * @memberof Mojito.Services.Betslip.actions
 */

/**
 * Init betslip is pending.
 *
 * @function initPending
 * @type {Mojito.Core.Services.redux.ActionCreator}
 * @memberof Mojito.Services.Betslip.actions
 */

/**
 * Set stake is pending.
 *
 * @function setStakePending
 * @type {Mojito.Core.Services.redux.ActionCreator}
 * @memberof Mojito.Services.Betslip.actions
 */

/**
 * Validate bonuses success.
 *
 * @function validateBonusesSuccess
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @param {Mojito.Services.Betslip.types.BetslipResponse} betslipData - Betslip response data.
 * @memberof Mojito.Services.Betslip.actions
 */

/**
 * Validate bonuses failed.
 *
 * @function validateBonusesFailed
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @memberof Mojito.Services.Betslip.actions
 */

/**
 * Validate bonuses pending.
 *
 * @function validateBonusesPending
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @memberof Mojito.Services.Betslip.actions
 */

/**
 * Toggle free bet success.
 *
 * @function toggleFreeBetSuccess
 *
 * @param {object} payload - Remove composite success payload. Contains items from {@link Mojito.Services.Betslip.types.BetslipResponse|BetslipResponse},
 * token, name and shouldRemove properties from original request action.
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @memberof Mojito.Services.Betslip.actions
 */

/**
 * Toggle banker success.
 *
 * @function toggleBankerSuccess
 *
 * @param {object} payload - Remove composite success payload. Contains items from {@link Mojito.Services.Betslip.types.BetslipResponse|BetslipResponse} and
 * banker betId from original request action.
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @memberof Mojito.Services.Betslip.actions
 */

/**
 * Place betslip success.
 *
 * @function placeBetslipSuccess
 *
 * @param {Mojito.Services.Betslip.types.BetslipResponse} betslipData - Betslip response data.
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @memberof Mojito.Services.Betslip.actions
 */

/**
 * Betslip response failed.
 *
 * @function betslipResponseFailed
 *
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @memberof Mojito.Services.Betslip.actions
 */

/**
 * Refresh bonus offers success.
 *
 * @function refreshBonusOffersSuccess
 *
 * @param {Mojito.Services.Betslip.types.BetslipResponse} betslipData - Betslip response data.
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @memberof Mojito.Services.Betslip.actions
 */

/**
 * Process errors.
 *
 * @function processErrors
 *
 * @param {Array<Mojito.Services.Betslip.types.Error>} errors - List of errors.
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @memberof Mojito.Services.Betslip.actions
 */

/**
 * Stake group activation is pending.
 *
 * @function activateStakeGroupPending
 *
 * @param {Mojito.Services.Betslip.types.STAKE_GROUP_NAME} stakeGroup - Stake group name.
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @memberof Mojito.Services.Betslip.actions
 */

/**
 * Execute actions is pending.
 *
 * @function executeActionsPending
 *
 * @type {Mojito.Core.Services.redux.ActionCreator}
 * @memberof Mojito.Services.Betslip.actions
 */

/**
 * Clear betslip is pending.
 *
 * @function clearBetslipPending
 *
 * @type {Mojito.Core.Services.redux.ActionCreator}
 * @memberof Mojito.Services.Betslip.actions
 */

/**
 * Updating betslip is triggered by internal application processing.
 *
 * @function updatePending
 *
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @memberof Mojito.Services.Betslip.actions
 */

/**
 * Updating betslip was triggered by user interaction.
 *
 * @function updatePendingOnInteraction
 *
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @memberof Mojito.Services.Betslip.actions
 */

/**
 * Accept changes.
 *
 * @function acceptChanges
 *
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @memberof Mojito.Services.Betslip.actions
 */

/**
 * Remove bet is pending.
 *
 * @function removeBetPending
 *
 * @type {Mojito.Core.Services.redux.ActionCreator}
 * @memberof Mojito.Services.Betslip.actions
 */

/**
 * Add composite is pending.
 *
 * @function addCompositePending
 *
 * @type {Mojito.Core.Services.redux.ActionCreator}
 * @memberof Mojito.Services.Betslip.actions
 */

/**
 * Overask status update is pending.
 *
 * @function overaskUpdatePending
 *
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @memberof Mojito.Services.Betslip.actions
 */

/**
 * Betslip validation failed on bet placement.
 *
 * @function betslipValidationFailed
 *
 * @param {Array<Mojito.Services.Betslip.types.Error>} errors - List of validation errors.
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @memberof Mojito.Services.Betslip.actions
 */

/**
 * Selections limit breach.
 *
 * @function selectionsLimitBreach
 *
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @memberof Mojito.Services.Betslip.actions
 */

/**
 * Update deep linking bets.
 *
 * @function updateDeepLinkingBets
 *
 * @param {Array<Mojito.Services.Betslip.types.Bet>} bets - List of bets.
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @memberof Mojito.Services.Betslip.actions
 */

/**
 * Clear receipt.
 *
 * @function clearReceipt
 *
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @memberof Mojito.Services.Betslip.actions
 */

/**
 * Bet placement available.
 *
 * @function betPlacementAvailable
 *
 * @param {boolean} allowBetPlacement - Flag indicating if bet placement is allowed.
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @memberof Mojito.Services.Betslip.actions
 */

/**
 * Set selection to pending state.
 *
 * @function selectionPending
 *
 * @param {object} payload - Selection pending payload.
 * @param {string} payload.id - Selection id.
 * @param {boolean} payload.isPending - Flag if selection is pending.
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @memberof Mojito.Services.Betslip.actions
 */

/**
 * Switch selection is pending.
 *
 * @function switchSelectionPending
 *
 * @type {Mojito.Core.Services.redux.ActionCreator}
 * @memberof Mojito.Services.Betslip.actions
 */

/**
 * Toggle banker bet is in pending state.
 *
 * @function toggleBankerPending
 *
 * @param {string} betId - Bet id.
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @memberof Mojito.Services.Betslip.actions
 */

/**
 * Remove errors with error code {@link Mojito.Services.Betslip.types.ERROR_CODE.SUSPENDED|SUSPENDED}.
 *
 * @function cleanSuspendErrors
 *
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @memberof Mojito.Services.Betslip.actions
 */

/**
 * Update bets state with suspended info.
 *
 * @function updateSuspendedBetsState
 *
 * @param {Array<{betId: string, isSuspended: boolean, parts: Array}>} payload - List of bets with suspended info.
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @memberof Mojito.Services.Betslip.actions
 */

/**
 * Update bets state with odds change info.
 *
 * @function updateOddsChangeBetsState
 *
 * @param {Array<{betId: string, price: string, prevPrice: string}>} payload - List of bets with odds change info.
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @memberof Mojito.Services.Betslip.actions
 */

/**
 * Update bets state with handicap change info.
 *
 * @function updateHcapChangeBetsState
 *
 * @param {Array<{betId: string, changed: boolean}>} payload - List of bets with hcap change info.
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @memberof Mojito.Services.Betslip.actions
 */

/**
 * Update betslip settings. Include price change policy and quick betslip mode.
 *
 * @function betslipSettingsUpdate
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @param {Mojito.Services.Betslip.types.BetslipSettings} settings - Betslip settings.
 *
 * @memberof Mojito.Services.Betslip.actions
 */

/**
 * Activating teaser type is pending.
 *
 * @function activateTeaserTypePending
 *
 * @param {Mojito.Services.Betslip.types.TEASER_TYPE} type - Teaser type.
 *
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @memberof Mojito.Services.Betslip.actions
 */

/**
 * Place betslip attempt triggered.
 *
 * @function placeBetslipAttempt
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @memberof Mojito.Services.Betslip.actions
 */

/**
 * Setting bankers mode.
 *
 * @function bankersModeChange
 *
 * @param {object} payload - Bankers mode change payload.
 * @param {boolean} payload.active - Flag indicates if bankers mode is active.
 * @param {boolean} payload.manual - Flag indicates if bankers mode was activated/deactivated manually by user interaction or automatically.
 *
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @memberof Mojito.Services.Betslip.actions
 */

/**
 * Refresh bonus offers failed.
 *
 * @function refreshBonusOffersFailed
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @memberof Mojito.Services.Betslip.actions
 */

/**
 * Reset store state. This does _not_ reset configuration.
 *
 * @function reset
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @memberof Mojito.Services.Betslip.actions
 */

/**
 * Hard reset store state. This reset store to initial state.
 *
 * @function hardReset
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @memberof Mojito.Services.Betslip.actions
 */

reduxInstance.injectReducer(STORE_KEY, reducer);

export { reducer, actions, STORE_KEY, INITIAL_STATE };
