import DateTimeTypes from './types.js';
import MojitoNGen from 'mojito/ngen';

const { OFFSET_FORMAT } = DateTimeTypes;
const log = MojitoNGen.logger.get('DateTimeUtils');

const formatOptions = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hourCycle: 'h23',
};

const MILLISECONDS_IN_MINUTE = 60000;
const MILLISECONDS_IN_HOUR = MILLISECONDS_IN_MINUTE * 60;
const MILLISECONDS_IN_DAY = MILLISECONDS_IN_HOUR * 24;

const timezoneFormatter = timeZone => new Intl.DateTimeFormat('en', { ...formatOptions, timeZone });
const utcFormatter = timezoneFormatter('UTC');

/**
 * Method returns the difference, between a date as evaluated in the UTC time zone,
 * and the same date as evaluated in the time zone provided in <code>timeZone</code> parameter.
 * The results are presented in units defined by <code>offsetFormat</code> parameter.
 *
 * @param {string} timeZone - IANA time zone name, e.g. `America/New_York`.
 * @param {Mojito.Core.Base.DateTimeTypes.OFFSET_FORMAT} [offsetFormat = HOURS] - Desired offset format.
 *
 * @returns {number|undefined} Number that represents time zone offset or undefined.
 * @function Mojito.Core.Base.DateTimeUtils.getTimezoneOffset
 */
const getTimezoneOffset = (timeZone, offsetFormat = OFFSET_FORMAT.HOURS) => {
    if (!timeZone) {
        return;
    }
    if (!OFFSET_FORMAT.hasOwnProperty(offsetFormat)) {
        log.error('Unknown offset format ', offsetFormat);
        return;
    }
    // Pure JS doesn't have a simple way to evaluate UTC offset between two time zones so far.
    // That is why we create two dates object from time zone formatted date strings, then
    // we evaluate time difference and return it in desired format.
    const now = new Date();
    const utcDate = new Date(utcFormatter.format(now));
    const tzDate = new Date(timezoneFormatter(timeZone).format(now));
    const diff = utcDate.getTime() - tzDate.getTime();

    const toMinutes = offset => offset / MILLISECONDS_IN_MINUTE;
    const toHours = offset => toMinutes(offset) / 60;
    const formatters = {
        [OFFSET_FORMAT.MINUTES]: toMinutes,
        [OFFSET_FORMAT.HOURS]: toHours,
    };
    const offsetFormatter = formatters[offsetFormat];
    return Math.floor(offsetFormatter(diff));
};

/**
 * Method returns the difference, between a date as evaluated in the UTC time zone,
 * and the same date as evaluated in local system time zone.
 * The results is presented in units defined by <code>offsetFormat</code> parameter.
 *
 * @param {Mojito.Core.Base.DateTimeTypes.OFFSET_FORMAT} [offsetFormat = HOURS] - Desired offset format.
 *
 * @returns {number|undefined} Current system UTC offset or undefined if incorrect <code>offsetFormat</code> has been provided.
 * @function Mojito.Core.Base.DateTimeUtils.getLocalTimezoneOffset
 */
const getLocalTimezoneOffset = (offsetFormat = OFFSET_FORMAT.HOURS) => {
    if (!OFFSET_FORMAT.hasOwnProperty(offsetFormat)) {
        log.error('Unknown offset format ', offsetFormat);
        return;
    }
    const localOffset = new Date().getTimezoneOffset();
    const toHours = offset => offset / 60;
    const formatters = {
        [OFFSET_FORMAT.MINUTES]: offset => offset,
        [OFFSET_FORMAT.HOURS]: toHours,
    };
    const formatter = formatters[offsetFormat];
    return formatter(localOffset);
};

/**
 * Adds a specified number of years to a given date.
 *
 * @example DateTimeUtils.addYears(new Date(2015, 11, 2), 6) => Thu Dec 02 2021.
 *
 * // Note: This takes into account leap years. For example, adding six years to 'Feb 29 2016' results in 'Feb 28 2022', since there's no 'Feb 29' in 2022.
 *
 * @param {Date} date - The date to be modified.
 * @param {number} amount - The number of years to be added.
 *
 * @returns {Date} A new date with the added years.
 * @function Mojito.Core.Base.DateTimeUtils.addYears
 */
const addYears = (date, amount) => {
    return addMonths(date, amount * 12);
};

/**
 * Adds a specified number of months to a given date, respecting monthly boundaries.
 * <br>
 * For instance, DateTimeUtils.addMonths(new Date(2015, 11, 2), 6) => Thu Jun 02 2016.
 * <br><br>
 * Note: Using #addMonths shifts month and also adjusts year if necessary.
 * An example is adding one month to 'Dec 31 2016', it returns 'Feb 28 2017', preserving end of month boundaries.
 *
 * @param {Date} date - The date to be modified.
 * @param {number} amount - The number of months to be added.
 *
 * @returns {Date} A new date with the added months.
 * @function Mojito.Core.Base.DateTimeUtils.addMonths
 */
const addMonths = (date, amount) => {
    const dateTime = new Date(date);
    const dayOfMonth = dateTime.getDate();

    const endOfDesiredMonth = new Date(dateTime.getTime());
    endOfDesiredMonth.setMonth(dateTime.getMonth() + amount + 1, 0);

    const daysInMonth = endOfDesiredMonth.getDate();
    if (dayOfMonth >= daysInMonth) {
        return endOfDesiredMonth;
    }

    dateTime.setFullYear(endOfDesiredMonth.getFullYear(), endOfDesiredMonth.getMonth(), dayOfMonth);
    return dateTime;
};

/**
 * Add the specified number of days to the given date.
 *
 * @param {Date} date - The date to be changed.
 * @param {number} amount - The amount of days to be added.
 *
 * @returns {Date} The new date with the days added.
 * @function Mojito.Core.Base.DateTimeUtils.addDays
 */
const addDays = (date, amount) => {
    return new Date(date.getTime() + amount * MILLISECONDS_IN_DAY);
};

/**
 * Add the specified number of hours to the given date.
 *
 * @param {Date} date - The date to be changed.
 * @param {number} amount - The amount of hours to be added.
 *
 * @returns {Date} The new date with the hours added.
 * @function Mojito.Core.Base.DateTimeUtils.addHours
 */
const addHours = (date, amount) => {
    return new Date(date.getTime() + amount * MILLISECONDS_IN_HOUR);
};

/**
 * Add the specified number of minutes to the given date.
 *
 * @param {Date} date - The date to be changed.
 * @param {number} amount - The amount of minutes to be added.
 *
 * @returns {Date} The new date with the minutes added.
 * @function Mojito.Core.Base.DateTimeUtils.addMinutes
 */
const addMinutes = (date, amount) => {
    return new Date(date.getTime() + amount * MILLISECONDS_IN_MINUTE);
};

/**
 * Add the specified number of milliseconds to the given date.
 *
 * @param {Date} date - The date to be changed.
 * @param {number} amount - The amount of milliseconds to be added.
 *
 * @returns {Date} The new date with the milliseconds added.
 * @function Mojito.Core.Base.DateTimeUtils.addMilliseconds
 */
const addMilliseconds = (date, amount) => {
    return new Date(date.getTime() + amount);
};

/**
 * Get the number of hours between the given dates.
 *
 * @param {Date} firstDate - The first date.
 * @param {Date} secondDate - The second date.
 *
 * @returns {number} The difference in hours. Can be a negative value if secondDate occurs after firstDate.
 * @function Mojito.Core.Base.DateTimeUtils.diffInHours
 */
const diffInHours = (firstDate, secondDate) => {
    return (firstDate - secondDate) / MILLISECONDS_IN_HOUR;
};

/**
 * Get the number of minutes between the given dates.
 *
 * @param {Date} firstDate - The first date.
 * @param {Date} secondDate - The second date.
 *
 * @returns {number} The difference in minutes. Can be a negative value if secondDate occurs after firstDate.
 * @function Mojito.Core.Base.DateTimeUtils.diffInMinutes
 */
const diffInMinutes = (firstDate, secondDate) => {
    return (firstDate - secondDate) / MILLISECONDS_IN_MINUTE;
};

/**
 * Get the number of seconds between the given dates.
 *
 * @param {Date} firstDate - The first date.
 * @param {Date} secondDate - The second date.
 *
 * @returns {number} The difference in seconds. Can be a negative value if secondDate occurs after firstDate.
 * @function Mojito.Core.Base.DateTimeUtils.diffInSeconds
 */
const diffInSeconds = (firstDate, secondDate) => {
    return (firstDate - secondDate) / 1000;
};
/**
 * Get the number of milliseconds between the given dates.
 *
 * @param {Date} firstDate - The first date.
 * @param {Date} secondDate - The second date.
 *
 * @returns {number} The difference in milliseconds. Can be a negative value if secondDate occurs after firstDate.
 * Can be also number with fractions.
 * @function Mojito.Core.Base.DateTimeUtils.diffInMilliseconds
 */
const diffInMilliseconds = (firstDate, secondDate) => {
    return firstDate - secondDate;
};

/**
 * Check whether the given dates are in the same day.
 *
 * @param {Date} firstDate - The first date.
 * @param {Date} secondDate - The second date.
 *
 * @returns {boolean} The dates are in the same day.
 * @function Mojito.Core.Base.DateTimeUtils.isSameDay
 */
const isSameDay = (firstDate, secondDate) => {
    return (
        firstDate.getFullYear() === secondDate.getFullYear() &&
        firstDate.getMonth() === secondDate.getMonth() &&
        firstDate.getDate() === secondDate.getDate()
    );
};

/**
 * Checks if a date corresponds to the specified number of days forward in time.
 *
 * @param {Date} currentDate - A date and time string in ISO 8601 format.
 * @param {number} amountDays - Number of days forward to compare with.
 * @param {string|number} timeOffset - UTC time offset in hours.
 *
 * @returns {boolean} True if date corresponds to the specified number of days forward in time. False otherwise.
 * @function Mojito.Core.Base.DateTimeUtils.isSameDateWithOffset
 */
const isSameDateWithOffset = (currentDate, amountDays, timeOffset) => {
    const localTimezoneOffset = getLocalTimezoneOffset();

    const timeZoneWithOffset = timeOffset + localTimezoneOffset;
    const dateToCheck = addHours(currentDate, timeZoneWithOffset);
    const dateNow = addHours(new Date(), timeZoneWithOffset);
    const dateForward = addDays(dateNow, amountDays);

    return isSameDay(dateToCheck, dateForward);
};

/**
 * Holds the translations for ordinal suffixes.
 *
 * @type {object}
 */
const _ordinalTranslations = {
    localeTranslations: {}, // Initialize with an empty object by default
};
/**
 * Initializes the DateTimeUtils with translations for ordinal suffixes.
 *
 * @param {object} translationsObject - An object containing translations for ordinal suffixes.
 * @example
 * // Example structure:
 * {
 *     'en-US': {
 *         one: 'st',
 *         two: 'nd',
 *         few: 'rd',
 *         other: 'th'
 *     },
 *     'fr-FR': {
 *         one: 'er',
 *         other: 'e'
 *     },
 *     'el-GR': {
 *         two: 'α',
 *         other: 'η'
 *     }
 * }
 *
 * @function Mojito.Core.Base.DateTimeUtils.initOrdinalTranslations
 */
function initOrdinalTranslations(translationsObject) {
    _ordinalTranslations.localeTranslations = translationsObject;
}
/**
 * Formats a Date object or a date-like value into a string based on the provided formatting string and locale.
 *
 * This function replaces placeholders in the format string with corresponding date values
 * and supports various locale-aware formatting options.
 *
 * @param {Date} date - The date to format. If the value is not a `Date` object, the function attempts to create a `Date`
 * object from it. If conversion to a `Date` object fails, the function returns 'Invalid Date'.
 * @param {string} format - The formatting string using keys defined in `DateTimeTypes.DATE_FORMAT_OPTIONS`.
 * Supported format tokens:
 * - 'YYYY', 'yyyy': Full year.
 * - 'MM': Numeric month with leading zero (01-12).
 * - 'DD': Numeric day with leading zero (01-31).
 * - 'D': Numeric day without leading zero (1-31).
 * - 'd': Numeric day with ordinal suffix (e.g. "1st", "2nd").
 * - 'MMM': Short month name (e.g. "Jul").
 * - 'MMMM': Full month name (e.g. "July").
 * - 'EEE': Short weekday name (e.g. "Mon").
 * - 'EEEE': Long weekday name (e.g. "Monday").
 * @param {string} [locale='en-US'] - A BCP 47 language tag representing the locale to use for formatting. Defaults to 'en-US'.
 *
 * @returns {string} The formatted date string or 'Invalid Date' if the date is not valid.
 *
 * @example
 * // Example usage of getCustomFormattedDate:
 * const date = new Date(2023, 6, 20); // July 20, 2023
 * const formattedDate = getCustomFormattedDate(date, 'DD MMMM YYYY', 'en-GB');
 * console.log(formattedDate); // "20 July 2023"
 *
 * @example
 * // Using ordinal formatting:
 * const date = new Date(2023, 6, 1); // July 1, 2023
 * const formattedDate = getCustomFormattedDate(date, 'MMMM D, YYYY (d)', 'en-US');
 * console.log(formattedDate); // "July 1, 2023 (1st)"
 *
 * @function Mojito.Core.Base.DateTimeUtils.getCustomFormattedDate
 */
function getCustomFormattedDate(date, format, locale = 'en-US') {
    if (typeof date === 'string' || !(date instanceof Date)) {
        date = new Date(date);
    }

    if (isNaN(date.getTime())) {
        return 'Invalid Date';
    }

    // Extract date components
    const day = date.getUTCDate();
    const month = date.getUTCMonth(); // Zero-indexed month
    const year = date.getUTCFullYear();

    // Use Intl.DateTimeFormat to get localized month names and weekdays
    const weekdayShort = new Intl.DateTimeFormat(locale, { weekday: 'short' }).format(date);
    const weekdayLong = new Intl.DateTimeFormat(locale, { weekday: 'long' }).format(date);
    const monthShort = new Intl.DateTimeFormat(locale, { month: 'short' }).format(date);
    const monthLong = new Intl.DateTimeFormat(locale, { month: 'long' }).format(date);

    // Create a mapping of placeholders to corresponding values
    const placeholders = {
        'YYYY': year, // Full year
        'MM': String(month + 1).padStart(2, '0'), // Numeric month with leading zero
        'DD': String(day).padStart(2, '0'), // Numeric day with leading zero
        'D': day, // Numeric day without leading zero
        'd': `${day}${_getOrdinalSuffix(day, locale)}`, // Use ordinal suffix for day
        'MMM': monthShort, // Short month name (e.g. "Jul")
        'MMMM': monthLong, // Full month name (e.g. "July")
        'EEE': weekdayShort, // Short weekday name (e.g. "Wed")
        'EEEE': weekdayLong, // Full weekday name (e.g. "Wednesday")
    };

    // Replace placeholders in the format string with actual values and return the formatted date with separators intact
    // Replacer function: Replace with corresponding value or return the match if not found
    return format.replace(
        /MMMM|MMM|YYYY|MM|DD|D|EEEE|EEE|d/g,
        match => placeholders[match] || match
    );
}

const defaultSuffixes = {
    'en': { one: 'st', two: 'nd', few: 'rd', other: 'th' },
    'el': { two: 'α', other: 'η' },
    'fr': { one: 'er', other: 'e' },
    'bg': { one: 'ви', other: 'и' },
    'fi': { other: 's' },
    'ga': { other: 'ú' }, // Irish
    'lt': { one: 'as', other: 'a' },
    'pt': { one: 'º', other: 'ª' },
    'ro': { one: 'lea', other: 'a' },
    'es': { one: 'º', other: 'ª' },
};
/**
 * Helper function to get the ordinal suffix for a given day in multiple languages.
 *
 * @param {number} day - Day of the month.
 * @param {string} locale - Locale string for language support.
 * @returns {string} Ordinal suffix for the day.
 */
function _getOrdinalSuffix(day, locale) {
    const pluralRules = new Intl.PluralRules(locale, { type: 'ordinal' });

    // Use translations if provided, otherwise use default.
    const suffixes =
        _ordinalTranslations.localeTranslations[locale] ||
        defaultSuffixes[locale.split('-')[0]] ||
        {};
    const pluralRule = pluralRules.select(day);
    return suffixes[pluralRule] || suffixes.other || '';
}

/**
 * Get a Date object representing the current UTC time plus a specified GMT offset (e.g. "gmt+1").
 *
 * @param {number} gmtOffset - The GMT offset in hours (can be positive or negative - e.g. "gmt+1", "gmt-3", etc.).
 * @returns {Date} A Date object adjusted for the specified GMT offset.
 * @function Mojito.Core.Base.DateTimeUtils.getDateWithGmtOffset
 */
function getDateWithGmtOffset(gmtOffset) {
    // Get the current UTC time
    const currentUtcTimeInMilliseconds = Date.now();

    // Convert the GMT offset from hours to milliseconds
    const gmtOffsetInMilliseconds = gmtOffset * 60 * 60 * 1000;

    // Adjust UTC time by adding the GMT offset
    const adjustedTimeInMilliseconds = currentUtcTimeInMilliseconds + gmtOffsetInMilliseconds;

    // Create and return a new Date object with the adjusted time
    return new Date(adjustedTimeInMilliseconds);
}

/**
 * Extracts the signed integer part from a GMT offset string (e.g. 'gmt-3' or 'gmt+7').
 * If the input is a timezone string (e.g. 'tz-australia-sydney'), it calculates the
 * corresponding GMT offset based on the current date and time.
 *
 * @param {string} gmtOffset - The GMT offset string or timezone string from which the
 * signed integer is extracted.
 * @returns {number|undefined} The signed integer part of the GMT offset, or undefined
 * if the format is invalid or the input is not a string.
 *
 * @function Mojito.Core.Base.DateTimeUtils.getSignedGmtOffset
 */
function getSignedGmtOffset(gmtOffset) {
    if (typeof gmtOffset !== 'string') {
        return undefined;
    }

    // Handle special case for 'gmt'
    if (gmtOffset === 'gmt') {
        return 0;
    }

    // Match signed integers in the format gmt±X (with optional space)
    const gmtMatch = gmtOffset.match(/gmt([+-]\d+)/);
    if (gmtMatch) {
        return parseInt(gmtMatch[1], 10);
    }

    // Check if it's a timezone offset in the form tz-australia-sydney
    const tzMatch = gmtOffset.match(/^tz-(.+)$/i);
    if (tzMatch) {
        const timeZone = tzMatch[1].replace(/-/g, '/');
        const offset = getTimezoneOffset(timeZone, OFFSET_FORMAT.HOURS);
        return -offset; // Handle the offset sign to match the expected positive value
    }

    // If no match is found, log an error
    log.error(`Invalid GMT offset format for ${gmtOffset}.`);

    return undefined;
}

/**
 * Date time utility functions. The given functions do not mutate passed parameters, particularly dates.
 *
 * @class DateTimeUtils
 * @memberof Mojito.Core.Base
 */
export default {
    getCustomFormattedDate,
    getDateWithGmtOffset,
    getSignedGmtOffset,
    getTimezoneOffset,
    getLocalTimezoneOffset,
    addYears,
    addMonths,
    addDays,
    addHours,
    addMinutes,
    addMilliseconds,
    diffInHours,
    diffInMinutes,
    diffInSeconds,
    diffInMilliseconds,
    initOrdinalTranslations,
    isSameDay,
    isSameDateWithOffset,
};
