/**
 * This file is copied from:
 * https://stash.playtech.corp/projects/DGT/repos/digital-mojito/browse/client/mojito/modules/bonuses/bonus-history
 *
 * Reworked userBonusesActions.getHistoricFreeBets and bonusCampaignActions.getCampaigns methods fetch,
 * logged in state check was added into componentDidMount.
 * If user was not logged in during componentDidMount, methods will be fetched once user become logged in.
 */

import React from 'react';
import {connect} from 'react-redux';

import MojitoCore from 'mojito/core';
import MojitoModules from 'mojito/modules';
import MojitoServices from 'mojito/services';

const BonusHistory = MojitoModules.BonusHistory.View;
const {BONUS_FILTER} = MojitoServices.Bonus.types;
const authenticationSelectors = MojitoServices.Authentication.selectors;
const {actions: campaignActions, selectors: campaignSelectors} = MojitoServices.BonusCampaign;
const {actions: userBonusesActions, selectors: userBonusesSelectors} = MojitoServices.UserBonuses;
const {selectors: userInfoSelectors} = MojitoServices.UserInfo;

const {UIViewImplementation} = MojitoCore.Presentation;

class BonusHistoryController extends UIViewImplementation {
    componentDidMount() {
        const {showPrewager, dispatch, userLoggedIn} = this.props;

        if (userLoggedIn) {
            dispatch(userBonusesActions.fetchHistoricUserBonuses());
        }

        if (showPrewager) {
            dispatch(campaignActions.getCampaigns(BONUS_FILTER.HISTORIC));
        }
    }

    componentDidUpdate(prevProps) {
        const {showPrewager, dispatch, userLoggedIn} = this.props;

        if (!prevProps.userLoggedIn && userLoggedIn) {
            dispatch(userBonusesActions.fetchHistoricUserBonuses());

            if (showPrewager) {
                dispatch(campaignActions.getCampaigns(BONUS_FILTER.HISTORIC));
            }
        }
    }

    isLoading() {
        const {showPrewager, isBonusCampaignPending, isFetchingHistoricFreeBets} = this.props;

        const loadingCampaigns = showPrewager && isBonusCampaignPending;
        return loadingCampaigns || isFetchingHistoricFreeBets;
    }

    render() {
        const {historicBonusCampaigns, freeBets, currencyCode, oddsBoosts} = this.props;

        return (
            <BonusHistory
                campaigns={historicBonusCampaigns}
                freeBets={freeBets}
                oddsBoosts={oddsBoosts}
                currencyCode={currencyCode}
                config={this.config.view}
                bonusHistoryLoading={this.isLoading()}
            />
        );
    }
}

const mapStateToProps = state => ({
    userLoggedIn: authenticationSelectors.isLoggedIn(state),
    historicBonusCampaigns: campaignSelectors.selectHistoricBonusCampaigns(state),
    isBonusCampaignPending: campaignSelectors.selectIsPending(state),
    currencyCode: userInfoSelectors.selectCurrency(state),
    freeBets: userBonusesSelectors.selectHistoricFreeBets(state),
    oddsBoosts: userBonusesSelectors.selectHistoricOddsBoosts(state),
    isFetchingHistoricFreeBets: userBonusesSelectors.selectIsFetchingHistoric(state),
});

export default connect(mapStateToProps)(BonusHistoryController);
