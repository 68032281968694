import {merge} from '#core/utils/config-utils.js';

import {PALETTE_IDS} from '#core/application/modules/ids.js';

import {COLOR, FONT} from './theme.js';
import {getBaseComponentsConfig} from './style/base-components';
import {getBettingCenterConfig} from './style/betting-center';
import {getPageHeadersConfigs} from './style/interface';
import {getRacingConfig} from './style/racing';
import {getStaticPagesConfig} from './style/static-pages';
import {getVirtualsConfig} from './style/virtuals';
import {getDialogConfig} from './style/dialogs';
import {getOnboardingConfig} from './style/onboarding';
import {getMenuListsConfigs} from './style/menus-links-lists';
import {getMatchAccaConfig} from './style/matchacca';
import {getBonusesConfig} from './style/bonuses';
import {getBetHistoryConfig} from './style/bethistory';
import {getAccountConfigs} from './style/account';
import {getCardsConfig} from './style/cards';
import {getMarketsConfig} from './style/markets';
import {getSportsContentConfigs} from './style/sports-content';

export function getTokens() {
    const palette = {
        [PALETTE_IDS.FONT]: FONT,
        [PALETTE_IDS.APP_VIEW]: {
            APPLICATION_PAGE_TEXT: {
                color: COLOR.BRAND.FOREGROUND1,
                fontSize: FONT.SIZE_XSMALL,
            },
            BACK_TO_TOP_BUTTON: {
                TERTIARY_BUTTON_TEXT: COLOR.BRAND.FOREGROUND1,
                TERTIARY_BUTTON_ICON_COLOR: COLOR.BRAND.BACKGROUND2,
            },
            ACCA_BAR_BG: COLOR.BRAND.BACKGROUND4,
            ACCA_BAR_LABEL_TEXT: COLOR.BRAND.FOREGROUND1,
        },
        [PALETTE_IDS.MOBILE_NAVBAR]: {
            CONTAINER_BG: COLOR.BRAND.BACKGROUND7,
            ICON_COLOR: COLOR.BRAND.FOREGROUND5,
            LABEL_TEXT: COLOR.BRAND.FOREGROUND1,
        },
        [PALETTE_IDS.SEARCH_RESULT]: {
            SEARCH_RECENT_ITEM_ICON_COLOR: COLOR.BRAND.FOREGROUND4,
            SEARCH_RECENT_ITEM_TEXT: COLOR.BRAND.FOREGROUND1,
            SEARCH_RECENT_ITEM_REMOVE_ICON_COLOR: COLOR.BRAND.FOREGROUND1,

            SEARCH_RESULT_ITEM_ICON_COLOR: COLOR.BRAND.FOREGROUND1,
            SEARCH_RESULT_ITEM_TOURNAMENT_TEXT: COLOR.BRAND.FOREGROUND3,
            SEARCH_RESULT_ITEM_NAME_TEXT: {
                color: COLOR.BRAND.FOREGROUND1,
                fontWeight: FONT.WEIGHT_BOLD,
            },
            SEARCH_RESULT_ITEM_STATUS_TEXT: COLOR.BRAND.FOREGROUND1,
        },
        [PALETTE_IDS.SEARCH]: {
            SEARCH_OVERLAY_BG: COLOR.BRAND.BACKGROUND4,
            SEARCH_OVERLAY_BACK_BUTTON_ICON_COLOR: COLOR.BRAND.ACCENT1,
        },
        [PALETTE_IDS.SCOREBOARDS]: {
            SCOREBOARD_CONTAINER_BG: COLOR.BRAND.BACKGROUND6,
            PRIMARY_COLOR: COLOR.BRAND.FOREGROUND2,
            ACCENT_COLOR: COLOR.BRAND.ACCENT2,

            SCORE_TEXT: COLOR.BRAND.ACCENT1,
            SEGMENT_TEXT: {
                fontWeight: 500,
                color: COLOR.BRAND.FOREGROUND4,
            },
            TIMING_STATUS_LABEL: {
                EVENT_TIMING_LIVE_TEXT: COLOR.BRAND.FOREGROUND4,
                EVENT_TIMING_UPCOMING_TEXT: COLOR.BRAND.FOREGROUND4,
            },
            SEGMENT_TOTAL_TEXT: {
                color: COLOR.BRAND.CTA2,
                fontWeight: FONT.WEIGHT_BOLD,
            },
            PARTICIPANT_NAME_TEXT: COLOR.BRAND.FOREGROUND2,
            SEGMENT_TOTAL_HEADER_TEXT: COLOR.BRAND.FOREGROUND2,
        },
        [PALETTE_IDS.SKELETON]: {
            SKELETON_COLOR: COLOR.BRAND.BACKGROUND5,
        },
        [PALETTE_IDS.PROMOTIONS]: {
            BANNER_CONTAINER_BG: COLOR.BASE.PAGE,
            BANNER_MARKET_EVENT_NAME_TEXT: {
                fontSize: FONT.SIZE_LARGE,
                _mobile_fontSize: FONT.SIZE_XSMALL,
                fontWeight: FONT.WEIGHT_BOLD,
                textTransform: 'uppercase',
            },
        },
        [PALETTE_IDS.INPUT]: {
            INPUT_BG: COLOR.RESERVED.TRANSPARENT,
            ON_DARK: {
                INPUT_TEXT: COLOR.BRAND.FOREGROUND1,
                INPUT_BORDER_COLOR: COLOR.BRAND.FOREGROUND3,
                INPUT_FOCUSED_BORDER_COLOR: COLOR.BRAND.FOREGROUND1,
                INPUT_ICON_COLOR: COLOR.BRAND.FOREGROUND5,
                INPUT_FOCUSED_ICON_COLOR: COLOR.BRAND.FOREGROUND1,
                INPUT_PLACEHOLDER_TEXT: COLOR.BRAND.FOREGROUND5,
                INPUT_LABEL_TEXT: COLOR.BRAND.FOREGROUND3,
            },
            ON_LIGHT: {
                INPUT_TEXT: COLOR.BRAND.FOREGROUND2,
                INPUT_BORDER_COLOR: COLOR.BRAND.FOREGROUND4,
                INPUT_FOCUSED_BORDER_COLOR: COLOR.BRAND.FOREGROUND2,
                INPUT_ICON_COLOR: COLOR.BRAND.FOREGROUND2,
                INPUT_LABEL_TEXT: COLOR.BRAND.FOREGROUND2,
                INPUT_PLACEHOLDER_TEXT: COLOR.BRAND.FOREGROUND5,
                INPUT_DISABLED_TEXT: COLOR.BRAND.FOREGROUND3,
            },
        },
        [PALETTE_IDS.STAKE_INPUT]: {
            STAKE_INPUT_TEXT: COLOR.BRAND.FOREGROUND2,
            STAKE_INPUT_BORDER_COLOR: COLOR.BRAND.FOREGROUND4,
            STAKE_INPUT_FOCUSED_BORDER_COLOR: COLOR.BRAND.FOREGROUND2,
            STAKE_INPUT_DISABLED_TEXT: COLOR.BRAND.FOREGROUND3,
        },
        [PALETTE_IDS.RANGE_INPUT]: {
            RANGE_TRACK_COLOR: COLOR.BRAND.FOREGROUND3,
            RANGE_THUMB_COLOR: COLOR.BRAND.FOREGROUND2,
            RANGE_THUMB_DISABLED_COLOR: COLOR.BRAND.FOREGROUND3,
            RANGE_LABEL_TEXT: COLOR.BRAND.FOREGROUND4,
        },
        [PALETTE_IDS.INFO_NOTIFICATION]: {
            INFO_BG: COLOR.BRAND.BACKGROUND6,
            INFO_BORDER_COLOR: COLOR.BRAND.FOREGROUND5,
            INFO_ICON_COLOR: COLOR.BRAND.FOREGROUND2,
            INFO_TEXT: COLOR.BRAND.FOREGROUND2,
        },
        [PALETTE_IDS.EMPTY_CONTENT_MESSAGE]: {
            EMPTY_CONTENT_MESSAGE_TEXT: COLOR.BRAND.BACKGROUND2,
        },
        [PALETTE_IDS.SWITCH]: {
            SWITCH_CONTAINER_BG: COLOR.BASE.PAGE,
            SWITCH_CONTAINER_BORDER_COLOR: COLOR.BRAND.FOREGROUND1,
            SWITCH_BUTTON_BG: COLOR.BRAND.FOREGROUND1,
            SWITCH_ICON_COLOR: COLOR.BASE.PAGE,
            SWITCH_LABEL_TEXT: COLOR.BRAND.FOREGROUND1,
            SWITCH_DISABLED_CONTAINER_BORDER_COLOR: COLOR.BRAND.FOREGROUND4,
            SWITCH_DISABLED_BUTTON_BG: COLOR.BRAND.FOREGROUND4,
            SWITCH_DISABLED_LABEL_TEXT: COLOR.BRAND.FOREGROUND4,

            ON_LIGHT: {
                SWITCH_CONTAINER_BG: COLOR.BRAND.FOREGROUND1,
                SWITCH_CONTAINER_BORDER_COLOR: COLOR.BRAND.FOREGROUND2,
                SWITCH_BUTTON_BG: COLOR.BRAND.FOREGROUND2,
                SWITCH_ICON_COLOR: COLOR.BRAND.FOREGROUND1,
                SWITCH_LABEL_TEXT: COLOR.RESERVED.BLACK,
                SWITCH_DISABLED_CONTAINER_BORDER_COLOR: COLOR.BRAND.FOREGROUND3,
                SWITCH_DISABLED_BUTTON_BG: COLOR.BRAND.FOREGROUND3,
                SWITCH_DISABLED_LABEL_TEXT: COLOR.BRAND.FOREGROUND3,
            },
        },
        [PALETTE_IDS.VIDEO_STREAMING]: {
            VIDEO_STREAMING_CONTAINER_BG: COLOR.RESERVED.BLACK,
            VIDEO_STREAMING_STATUSBAR_TEXT: {
                color: COLOR.BRAND.FOREGROUND1,
                fontWeight: FONT.WEIGHT_NORMAL,
            },
        },
        [PALETTE_IDS.PRICE_BOOST_LABEL]: {
            PRICE_BOOST_LABEL_ICON_CONTAINER_BG: COLOR.RESERVED.TRANSPARENT,
            PRICE_BOOST_LABEL_ICON_SIZE: 18,
        },
    };

    const viewsConfig = {
        AppViewSportsbook: {
            style: {
                container: {
                    _mobile_margin: '0px 0px 64px 0px',
                },
            },
        },
        _mobile_EventListItem: {
            marketContainer: {
                style: {
                    padding: '16px 0px 2px 8px',
                },
            },
        },
        NumericKeypad: {
            container: {
                style: {
                    padding: '4px',
                },
            },
        },
        ExpandableView: {
            headerContainer: {
                style: {
                    base: {
                        zIndex: 'auto', // reset Portal's CSS rule for `.header` selector; could be removed once MOJ-9324 implemented and all sportsbook will be hidden under Shadow DOM
                    },
                },
            },
        },
        BettingCenterView: {
            container: {
                style: {
                    margin: '0 0 8px 0',
                },
            },
        },
    };

    const tokens = merge(
        {
            custom: {
                [PALETTE_IDS.APP_VIEW]: viewsConfig,
            },
            palette: palette,
        },
        getBaseComponentsConfig(),
        getAccountConfigs(),
        getMenuListsConfigs(),
        getBetHistoryConfig(),
        getBettingCenterConfig(),
        getBonusesConfig(),
        getCardsConfig(),
        getDialogConfig(),
        getMarketsConfig(),
        getMatchAccaConfig(),
        getOnboardingConfig(),
        getPageHeadersConfigs(),
        getRacingConfig(),
        getStaticPagesConfig(),
        getVirtualsConfig(),
        getSportsContentConfigs()
    );
    return tokens;
}
