/**
 * Abstract route resolver.
 *
 * @class AbstractRouteResolver
 * @abstract
 * @memberof Mojito.Core.Base
 */
export default class AbstractRouteResolver {
    /**
     * Get root prefix for all URLs.
     *
     * To be implemented by concrete subclasses.
     *
     * @function Mojito.Core.Base.AbstractRouteResolver#getRoot
     */
    getRoot() {
        // To be implemented by subclasses
    }

    /**
     * Set root prefix for all URLs.
     *
     * To be implemented by concrete subclasses.
     *
     * @param {string} value - Root prefix.
     * @function Mojito.Core.Base.AbstractRouteResolver#setRoot
     */
    // eslint-disable-next-line no-unused-vars
    setRoot(value) {
        // To be implemented by subclasses
    }

    /**
     * Get the URL pattern corresponding to the type.
     *
     * To be implemented by concrete subclasses.
     *
     * @param {string} type - Type of navigation.
     * @function Mojito.Core.Base.AbstractRouteResolver#getPattern
     */
    // eslint-disable-next-line no-unused-vars
    getPattern(type) {
        // To be implemented by subclasses
    }

    /**
     * Resolve the navigational route based on provided routing data.
     *
     * To be implemented by concrete subclasses.
     *
     * @param {object} data - Routing data object.
     * @param {string} data.type - Navigation type.
     * @param {object} data.params - Navigation params.
     * @function Mojito.Core.Base.AbstractRouteResolver#getRoute
     */
    // eslint-disable-next-line no-unused-vars
    getRoute(data) {
        // To be implemented by subclasses
    }

    /**
     * Detects if provided URL string belongs to the current location origin.
     *
     * @param {string} url - URL.
     *
     * @returns {boolean} True if URL is considered to be internal application URL.
     * @function Mojito.Core.Base.AbstractRouteResolver#isInternalUrl
     */
    isInternalUrl(url) {
        return (
            url.startsWith('/') ||
            url.startsWith('./') ||
            url.startsWith(`${document.location.origin}${this.getRoot()}`)
        );
    }

    /**
     * Detects if provided URL string is a valid absolute URL.
     *
     * @param {string} url - URL.
     *
     * @returns {boolean} True if URL is absolute URL.
     * @function Mojito.Core.Base.AbstractRouteResolver#isAbsoluteUrl
     */
    isAbsoluteUrl(url) {
        try {
            new URL(url);
            return true;
            // eslint-disable-next-line no-unused-vars
        } catch (e) {
            // URL constructors throws error for any non-valid absolute path
            return false;
        }
    }
}
