import MojitoCore from 'mojito/core';
import AbstractTask from 'services/common/task-runner/abstract-task';
import { actions as userBonusesActions } from 'services/user-bonuses/slice.js';
import BonusTypes from 'services/bonus/types.js';

const { dispatch } = MojitoCore.Services.redux.store;
const log = MojitoCore.logger.get('UserBonusesStore');

/**
 * Task that retrieves active free bets.
 *
 * @class PollFreeBetsTask
 * @memberof Mojito.Services.UserBonuses
 */
export default class PollFreeBetsTask extends AbstractTask {
    constructor(service, currency) {
        super();
        this.service = service;
        this.currency = currency;
    }

    execute(callback) {
        this.service
            .getFreeBets(this.currency, BonusTypes.BONUS_FILTER.ACTIVE)
            .then(freebets => {
                dispatch(userBonusesActions.fetchActiveFreeBetsSuccess(freebets));
                callback();
            })
            .catch(({ message = '', type }) => {
                log.warn(`Failed to fetch free bets. Error: ${type} ${message}`);
                callback();
            });
    }
}
