import {PALETTE_IDS} from '#core/application/modules/ids.js';

import {COLOR, FONT} from '../theme.js';

export function getAccountConfigs() {
    return {
        palette: {
            [PALETTE_IDS.MY_ACCOUNT_DIALOG]: {
                MY_ACCOUNT_DIALOG_TAB_BUTTON_BG: COLOR.BASE.PAGE,
                MY_ACCOUNT_DIALOG_TAB_BUTTON_SELECTED_BG: COLOR.BRAND.FOREGROUND1,
                MY_ACCOUNT_DIALOG_TAB_BUTTON_TEXT: {
                    color: COLOR.BRAND.BACKGROUND2,
                    fontWeight: FONT.WEIGHT_BOLD,
                    fontSize: FONT.SIZE_MEDIUM,
                    justifyContent: 'flex-start',
                },
                MY_ACCOUNT_DIALOG_TAB_BUTTON_SELECTED_TEXT: {
                    color: COLOR.BRAND.FOREGROUND2,
                    fontWeight: FONT.WEIGHT_BOLD,
                    fontSize: FONT.SIZE_MEDIUM,
                    justifyContent: 'flex-start',
                },
            },
        },
    };
}
