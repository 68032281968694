import { isEmpty } from 'mojito/utils';
import MojitoCore from 'mojito/core';
import MojitoServices from 'mojito/services';
import MojitoPresentation from 'mojito/presentation';
import BonusCard from 'modules/bonuses/bonus-card/index.jsx';

const { Text, ProgressBar, FlexPane } = MojitoPresentation.Components;
const CurrencyHelper = MojitoPresentation.Utils.CurrencyHelper;
const NumberUtils = MojitoCore.Base.NumberUtils;
const BonusType = MojitoServices.Bonus.types.BONUS_TYPE;

const ActiveBonuses = props => {
    const {
        oddsBoosts,
        campaigns,
        currencyCode,
        showPrewager,
        showOddsBoosts,
        mojitoTools: { config, stringResolver },
    } = props;

    const freeBets = props.freeBets.map(freeBet => ({
        ...freeBet,
        bonusType: BonusType.FREEBET,
    }));

    function renderBonusItemsNotAvailable(items, infoString) {
        if (!isEmpty(items)) {
            return null;
        }

        return (
            <FlexPane config={config.noBonusesCard}>
                <Text class="ActiveBonuses-NoBonus" config={config.bonusItemsNotAvailable}>
                    {infoString}
                </Text>
            </FlexPane>
        );
    }

    function renderProgressContent(target, wagered, remaining, currencyCode) {
        const progressRatio = NumberUtils.numberToPrecision(wagered / target, 2);
        const remainingRatio = 1 - progressRatio;
        const remainingLabel =
            remainingRatio > config.remainingProgressVisibilityLimit / 100 &&
            CurrencyHelper.formatCurrency(remaining, currencyCode);

        return (
            <ProgressBar
                config={config.progressBar}
                progress={progressRatio}
                endLabel={CurrencyHelper.formatCurrency(target, currencyCode)}
                progressLabel={CurrencyHelper.formatCurrency(wagered, currencyCode)}
                remainLabel={remainingLabel}
                isActiveBonus={true}
            />
        );
    }

    return (
        <FlexPane config={config.container}>
            <FlexPane config={config.header} class="ta-bonusesList-freeBets">
                <FlexPane config={config.headerTextWrapper}>
                    <Text config={config.headerText}>
                        {stringResolver.resolveString(`$ACTIVE_BONUSES.FREE_BET`)}
                    </Text>
                </FlexPane>
                {freeBets.map(bonus => (
                    <BonusCard
                        config={config.bonusCard}
                        shouldShowRemainingAmount={config.shouldShowRemainingAmount}
                        key={bonus.id}
                        bonus={bonus}
                        currencyCode={currencyCode}
                    />
                ))}
                {renderBonusItemsNotAvailable(
                    freeBets,
                    stringResolver.resolveString(`$ACTIVE_BONUSES.NO_FREE_BETS`)
                )}
            </FlexPane>
            {showOddsBoosts && (
                <FlexPane config={config.header} class="ta-bonusesList-oddsBoost">
                    <FlexPane config={config.headerTextWrapper}>
                        <Text config={config.headerText}>
                            {stringResolver.resolveString(`$ACTIVE_BONUSES.ODDS_BOOST`)}
                        </Text>
                    </FlexPane>
                    {oddsBoosts.map(bonus => (
                        <BonusCard
                            config={config.bonusCard}
                            shouldShowRemainingAmount={config.shouldShowRemainingAmount}
                            key={bonus.id}
                            bonus={bonus}
                            currencyCode={currencyCode}
                        />
                    ))}
                    {renderBonusItemsNotAvailable(
                        oddsBoosts,
                        stringResolver.resolveString(`$ACTIVE_BONUSES.NO_ODDS_BOOSTS`)
                    )}
                </FlexPane>
            )}
            {showPrewager && (
                <FlexPane config={config.header} class="ta-bonusesList-prewager">
                    <FlexPane config={config.headerTextWrapper}>
                        <Text config={config.headerText}>
                            {stringResolver.resolveString(`$ACTIVE_BONUSES.PREWAGER`)}
                        </Text>
                    </FlexPane>
                    {campaigns.map(bonus => (
                        <BonusCard
                            config={config.bonusCard}
                            shouldShowRemainingAmount={config.shouldShowRemainingAmount}
                            key={bonus.id}
                            bonus={bonus}
                            currencyCode={currencyCode}
                            renderProgressContent={renderProgressContent}
                        />
                    ))}

                    {renderBonusItemsNotAvailable(
                        campaigns,
                        stringResolver.resolveString(`$ACTIVE_BONUSES.NO_PREWAGER`)
                    )}
                </FlexPane>
            )}
        </FlexPane>
    );
};

export default ActiveBonuses;
