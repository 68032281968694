import MojitoCore from 'mojito/core';
import SportGroupsListSkeletonImpl from './sport-groups-list-skeleton.jsx';
import PropTypes from 'prop-types';

const { UIView } = MojitoCore.Presentation;

/**
 * @function SportGroupsListSkeleton
 * @memberof Mojito.Modules.SportGroupsList
 */

/**
 * SportGroupsListSkeleton prop types.
 *
 * @property {string} headerText - Text used as a header.
 * @property {boolean} [isTopSportGroups = false] - Indicates whether we should render Top SportGroups.
 * @memberof Mojito.Modules.SportGroupsList.SportGroupsListSkeleton
 */
const propTypes = {
    headerText: PropTypes.string.isRequired,
    isTopSportGroups: PropTypes.bool,
};

const defaultProps = {
    isTopSportGroups: false,
};

export default UIView(
    'SportGroupsListSkeleton',
    SportGroupsListSkeletonImpl,
    propTypes,
    defaultProps
);
