import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import MojitoCore from 'mojito/core';
import { noop, toNumber } from 'mojito/utils';
import { actions as bootstrapActions } from 'services/bootstrap/slice.js';
import { actions as authenticationActions } from 'services/authentication/slice.js';
import betslipActionTypes from 'services/betslip/action-types.js';
import openBetsActionTypes from 'services/bets/open-bets/action-types.js';
import dataRetriever from './data-retriever.js';
import UserInfoTypes from './types.js';

const intentActions = MojitoCore.Intents.actions;

const reduxInstance = MojitoCore.Services.redux;
const ReduxUtils = MojitoCore.Services.ReduxUtils;
const CurrencyConfig = MojitoCore.Services.CurrencyConfig;
const { DUE_DILIGENCE_FLAGS } = UserInfoTypes;

/**
 * Defines the state of the user info store.
 *
 * @typedef UserInfoState
 *
 * @property {string} userName - Name of user logged in.
 * @property {string} currency - The currency code.
 * @property {boolean} allowEarlyPayout - Allow early payout flag.
 * @property {boolean} allowBestOddsGuaranteed - Allow best odds guaranteed flag.
 * @property {object} uploadDocumentationIntent - Intent triggered if user must upload documentation after login (KYC).
 * @property {object} dueDiligenceFlags - Object defines user due diligence status. Key is one of {@link Mojito.Services.UserInfo.types.DUE_DILIGENCE_FLAGS|DUE_DILIGENCE_FLAGS}, value is actual flag payload.
 * @property {boolean} balanceUncertain - Value that returns true if the balance amount is considered to be uncertain. E.g., if client lost connection to balance service or network error occurred during balance fetch. In such cases client still keeps previously loaded balance amount but there is no guarantee if data is still valid. This flag can affect some balance aware user interactions like bet placement.
 * @property {object} balances - User balances object where key is one of {@link Mojito.Services.UserInfo.types.BALANCE_TYPES|BALANCE_TYPES}, value is actual balance amount.
 * @property {Array} groups - Groups.
 *
 * @memberof Mojito.Services.UserInfo
 */

/**
 * The name of the user info store. Will be used to register in global redux store.
 *
 * @constant
 * @type {string}
 * @memberof Mojito.Services.UserInfo
 */
export const STORE_KEY = 'userInfoStore';

export const initialState = {
    userName: undefined,
    currency: undefined,
    allowEarlyPayout: true,
    allowBestOddsGuaranteed: true,
    uploadDocumentationIntent: undefined,
    dueDiligenceFlags: {},
    balanceUncertain: false,
    balances: {},
    groups: [],
};

const ensureUserCurrency = (userInfo = {}) => {
    if (userInfo.currency) {
        return userInfo;
    }
    const { defaultCurrencyCode } = CurrencyConfig.getCurrencies();
    return { ...userInfo, currency: defaultCurrencyCode };
};

const processDueDiligenceFlags = (state, dispatch, { dueDiligenceFlags } = {}) => {
    const requireDocuments = dueDiligenceFlags?.[DUE_DILIGENCE_FLAGS.REQUIRE_DOCUMENTS];
    if (requireDocuments) {
        const intent = state.uploadDocumentationIntent;
        if (intent?.type) {
            dispatch(intentActions.publishIntent(intent.type, intent.data));
        }
    }
};

export const { reducer, actions } = createSlice({
    name: 'userInfo',
    initialState,
    reducers: {
        configure(state, { payload: { uploadDocumentationIntent } = {} }) {
            state.uploadDocumentationIntent = uploadDocumentationIntent;
        },
        resetUserInfo(state) {
            return {
                ...initialState,
                uploadDocumentationIntent: state.uploadDocumentationIntent,
            };
        },
        updateBalance(state, { payload: balances }) {
            state.balanceUncertain = false;
            const formatBalances = {};
            Object.entries(balances || {}).forEach(([key, value]) => {
                formatBalances[key] = toNumber(value) || 0;
            });
            state.balances = formatBalances;
        },
        updateUserInfo(state, { payload: userInfo }) {
            return { ...state, ...userInfo };
        },
        updateBalanceFailed(state) {
            state.balanceUncertain = true;
        },
        requestBalanceUpdate: noop,
    },
});

reduxInstance.actionListener.startListening({
    actionCreator: authenticationActions.loginFailed,
    effect: (_, listenerApi) => listenerApi.dispatch(actions.resetUserInfo()),
});

reduxInstance.actionListener.startListening({
    actionCreator: bootstrapActions.dispose,
    effect: () => dataRetriever.unsubscribeFromBalanceUpdates(),
});

reduxInstance.actionListener.startListening({
    matcher: isAnyOf(bootstrapActions.dispose, authenticationActions.disposeSession),
    effect: (action, listenerApi) => {
        listenerApi.dispatch(actions.resetUserInfo());
        dataRetriever.unsubscribeFromBalanceUpdates();
    },
});

reduxInstance.actionListener.startListening({
    actionCreator: authenticationActions.loginSuccess,
    effect: (action, listenerApi) => {
        const userInfo = action.payload?.userInfo || {};
        const { dispatch } = listenerApi;
        processDueDiligenceFlags(listenerApi.getState()[STORE_KEY], dispatch, userInfo);
        const ensuredUserInfo = ensureUserCurrency(userInfo);
        dataRetriever.subscribeToBalanceUpdates(ensuredUserInfo.currency);

        if (ensuredUserInfo) {
            dispatch(actions.updateUserInfo(ensuredUserInfo));
            dispatch(actions.updateBalance(ensuredUserInfo.balances));
        } else {
            dispatch(actions.resetUserInfo());
        }
    },
});

reduxInstance.actionListener.startListening({
    matcher: ReduxUtils.isAnyOfType(
        openBetsActionTypes.cashoutSuccess,
        betslipActionTypes.placeBetslipSuccess,
        actions.requestBalanceUpdate.type
    ),
    effect: (action, listenerApi) => {
        dataRetriever.getBalance(listenerApi.getState()[STORE_KEY].currency);
    },
});

/**
 * User information related actions.
 *
 * @module UserInfoActions
 * @name actions
 * @memberof Mojito.Services.UserInfo
 */

/**
 * Configure user documentation upload intent.
 *
 * @function configure
 * @type {Mojito.Core.Services.redux.ActionCreator}
 * @param {object} uploadDocumentationIntent - Intent triggered if user must upload documentation after login (KYC).
 *
 * @memberof Mojito.Services.UserInfo.actions
 */

/**
 * Update user information.
 *
 * @function updateUserInfo
 * @type {Mojito.Core.Services.redux.ActionCreator}
 * @param {object} userInfo - User information.
 *
 * @memberof Mojito.Services.UserInfo.actions
 */

/**
 * Reset user information.
 *
 * @function resetUserInfo
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @memberof Mojito.Services.UserInfo.actions
 */

/**
 * Update balance.
 *
 * @function updateBalance
 * @type {Mojito.Core.Services.redux.ActionCreator}
 * @param {object} balances - Object defines user balances. Key is one of {@link Mojito.Services.UserInfo.types.BALANCE_TYPES|BALANCE_TYPES}, value is actual balance amount.
 *
 * @memberof Mojito.Services.UserInfo.actions
 */

/**
 * Failed update balance response received.
 *
 * @function updateBalanceFailed
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @memberof Mojito.Services.UserInfo.actions
 */

/**
 * Request balance update. Can be called by any entity that want the balance to be updated ASAP.
 *
 * @function requestBalanceUpdate
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @memberof Mojito.Services.UserInfo.actions
 */

reduxInstance.injectReducer(STORE_KEY, reducer);
