import PropTypes from 'prop-types';
import BannerPromotionsViewImpl from './banner-promotions-view.jsx';
import MojitoCore from 'mojito/core';
import { noop } from 'mojito/utils';

const UIView = MojitoCore.Presentation.UIView;

/**
 * A view which is designed to display one or more promotional items.
 *
 * @class BannerPromotionsView
 * @memberof Mojito.Modules.Promotions
 */

/**
 * `BannerPromotionsView` prop types.
 *
 * @property {object[]} [promotions] - An array containing promotion items.
 * @property {boolean} [promotionsLoaded] - A flag indicating whether the promotions have been loaded from content.
 * @property {number} [initialWidth = 0] - An optional property setting the initial width of the component.
 * @property {events} [events] - An object containing events (keyed by event ids) for odds banners.
 * @property {betslip} [betslip] - A betslip for odds banners, enabling users to click on odds banner selections.
 * @property {string} locationId - The id indicating the location of the promotion.
 * @property {Function} [onPrepareBannersToRender = () => {}] - Callback triggered when swiper slides are prepared to render.
 * @property {boolean} [shouldRequestData = true] - Flag indicating whether component should request event and market data from banners. If false, then neither event nor markets will be requested, hence in order to present info corresponding data should be already available in a store.
 * @property {object | Function} [skeletonRef] - A ref to the rendered element.
 *
 * @memberof Mojito.Modules.Promotions.BannerPromotionsView
 */
const propTypes = {
    promotions: PropTypes.array,
    promotionsLoaded: PropTypes.bool,
    initialWidth: PropTypes.number,
    events: PropTypes.object,
    betslip: PropTypes.object,
    locationId: PropTypes.string.isRequired,
    onPrepareBannersToRender: PropTypes.func,
    shouldRequestData: PropTypes.bool,
    elementRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

const defaultProps = {
    promotionsLoaded: false,
    onPrepareBannersToRender: noop,
    shouldRequestData: true,
};

export default UIView('BannerPromotionsView', BannerPromotionsViewImpl, propTypes, defaultProps);
