/* eslint-disable react/prop-types */
import React from 'react';
import {connect} from 'react-redux';

import MojitoCore from 'mojito/core';
import MojitoModules from 'mojito/modules';
import MojitoServices from 'mojito/services';
import MojitoPresentation from 'mojito/presentation';

import {MobileContext} from '#core/utils/context-utils.js';
import {getResettingCSSForContainerId} from '#core/utils/embedding-utils.js';
import {generateUniqueId} from '#core/utils/utils.js';

import AbstractWidget from '#core/widgets/AbstractWidget.js';

import {StoreProviderWrapper} from './wrappers/index.js';

const AnalyticsContextExtender = MojitoCore.Presentation.AnalyticsContext.ContextExtender;
const AnalyticsContextBuilder = MojitoCore.Presentation.AnalyticsContext.ContextBuilder;

const UIViewUtils = MojitoCore.Presentation.UIViewUtils;

const BetHistoryDetails = MojitoModules.BetHistoryDetails;

const betsService = MojitoServices.Bets.service;
const {actions: betHistoryActions} = MojitoServices.Bets.betHistorySlice;
const userSettingsSelectors = MojitoServices.UserSettings.selectors;

const {Text, FlexPane} = MojitoPresentation.Components;

class WidgetBetDetailsImpl extends AbstractWidget {
    constructor(props) {
        super(props);

        this.widgetId = 'widget-bethistory-details-' + generateUniqueId();
        this.controllerConfig = UIViewUtils.getConfig('BetHistoryDetailsController');

        this.state = {
            error: '',
        };

        this.analyticsContext = new AnalyticsContextBuilder().withAnalyticsPath('WidgetBetDetails').build();

        this.props.dispatch(betHistoryActions.startFetching());
    }

    componentDidMount() {
        const {dispatch, userOddsFormat} = this.props;
        const {betId} = this.params;

        if (!betId) return this.setError("Please, provide 'betId' parameter, it is required.");

        const errorMessagePart =
            "Please, check if you provided correct 'betId' parameter or have permission to view this bet.";

        betsService
            .getBet(betId, userOddsFormat)
            .then(data => {
                if (!data?.bets?.length) return this.setError(`No bet found with id '${betId}'. ${errorMessagePart}`);

                dispatch(betHistoryActions.fetchBetsFinish(data));
            })
            .catch(() => this.setError(`Bet with id '${betId}' cannot be fetched. ${errorMessagePart}`));
    }

    componentWillUnmount() {
        this.props.dispatch(betHistoryActions.reset());
    }

    setError(error) {
        return this.setState({error}, () => this.props.dispatch(betHistoryActions.reset()));
    }

    render() {
        const {error} = this.state;

        return (
            <AnalyticsContextExtender value={this.analyticsContext}>
                <MobileContext>
                    <style
                        type="text/css"
                        dangerouslySetInnerHTML={{__html: getResettingCSSForContainerId(this.widgetId)}}
                    ></style>
                    <div id={this.widgetId}>
                        {error ? (
                            <FetchingBetError config={this.controllerConfig} error={error} />
                        ) : (
                            <BetHistoryDetails betId={this.params.betId} />
                        )}
                    </div>
                </MobileContext>
            </AnalyticsContextExtender>
        );
    }
}

const mapStateToProps = state => ({
    userOddsFormat: userSettingsSelectors.selectOddsFormat(state),
});

export const WidgetBetDetails = StoreProviderWrapper(
    'WidgetBetDetails',
    connect(mapStateToProps)(WidgetBetDetailsImpl)
);

function FetchingBetError({config, error}) {
    return (
        // to not create new config properties I just decided to reuse existing configuration from BetHistoryDetailsController
        <FlexPane config={config.emptyContent}>
            <Text config={config.loadingLabel}>{error}</Text>
        </FlexPane>
    );
}
