import {registerTileSchema} from '#core/application/schema-registry.js';

import TERTIARY_LINK from './tertiary-link.palette-schema.yaml';

registerTileSchema(TERTIARY_LINK);

export function generateTertiaryLink(TILE) {
    const {FONT} = TILE;

    return {
        style: {
            base: {
                backgroundImage: 'none', // Reset background set by base style
                backgroundColor: 'transparent',
                fontSize: FONT.SIZE_MEDIUM,
                fontWeight: FONT.WEIGHT_NORMAL,
                ...TILE.TERTIARY_LINK_TEXT,
            },
            active: TILE.TERTIARY_LINK_TEXT,
            hover: TILE.TERTIARY_LINK_TEXT,
            disabled: {
                cursor: 'not-allowed',
            },
        },
    };
}
