import {assignToHierarchy} from '#core/utils/config-utils.js';

import {AbstractFeature, allFeatures} from '#core/application/abstract-feature.js';

import DocumentHeadView from './document-head-view.jsx';

import SCHEMA from './seo.schema.yaml';

class SEOFeature extends AbstractFeature {
    get name() {
        return 'SEO';
    }

    get configSchema() {
        return SCHEMA;
    }

    get opengraphImages() {
        const images = this.config.opengraphImages;
        return Array.isArray(images) ? images : [images];
    }

    get robotsMetaTags() {
        return this.config.robotsMetaTags;
    }

    beforeMojitoConfigBuild(mojitoConfig) {
        assignToHierarchy(mojitoConfig, 'services.configurations.DocumentHead', {
            enable: this.config.enabled,
        });
        assignToHierarchy(mojitoConfig, 'services.viewImplementations.DocumentHead', {
            implementation: DocumentHeadView,
        });
        return super.beforeMojitoConfigBuild(mojitoConfig);
    }

    afterMojito() {
        setTimeout(() => {
            window.prerenderReady = true; // tell Prerender.io to snapshot after everything is initialized
        }, 10000);
        return super.afterMojito();
    }
}

export const SEO = new SEOFeature(allFeatures);
