import MojitoServices from 'mojito/services';
import Text from 'presentation/components/text/index.jsx';
import FlexPane from 'presentation/components/flex-pane/index.jsx';
import Button from 'presentation/components/button/index.jsx';
import Image from 'presentation/components/image/index.jsx';
import Timer from 'presentation/components/timer/index.jsx';
import DateTime from 'presentation/components/date-time/index.jsx';
import EventFormatter from 'presentation/formatters/event/formatter.js';
import DateTimeFormatter from 'presentation/formatters/date-time/index.js';
import { DURATION } from 'presentation/formatters/date-time/types.js';

const { utils: EventsUtils } = MojitoServices.SportsContent.Events;
const VideoStreamsUtils = MojitoServices.VideoStreaming.utils;

export default function EventTimingStatus(props) {
    const {
        mojitoTools: { config },
        eventItem,
        onClick,
    } = props;
    const hasStreaming = VideoStreamsUtils.isStreamAvailable(eventItem);
    return (
        <Button config={config.container} onClick={onClick}>
            {eventItem.details.inPlay ? <LiveContent {...props} /> : <UpcomingContent {...props} />}
            {config.showStreamIcon && hasStreaming && (
                <Image class="ta-StreamingIndicator" config={config.streamingIcon} />
            )}
        </Button>
    );
}

const LiveContent = props => {
    const {
        mojitoTools: { config, l10n, stringResolver, appContext },
        eventItem,
    } = props;
    const { details, sportId } = eventItem;

    const hasClockDetails = EventsUtils.hasInPlayClockDetails(eventItem);
    const time = resolveLiveTime(eventItem);
    const durationInMinutes = DateTimeFormatter.formatDuration(
        time,
        appContext.userSettings(),
        appContext.systemSettings().language,
        DURATION.MINUTE
    );
    const period = EventFormatter.getDescription(sportId, details?.inplayDescription, l10n);
    const liveStatus = period || stringResolver.resolveString('$EVENT_TIMING_STATUS.LIVE');
    const showTimeInfo = hasClockDetails || time > 0;
    return (
        <FlexPane config={config.dateTimeContainer}>
            <Text config={config.liveText}>{liveStatus}</Text>
            {showTimeInfo && (
                <Text config={config.liveText}>
                    {hasClockDetails ? (
                        <Timer
                            config={config.inPlayTime}
                            time={time}
                            direction={details.clockDirection}
                            running={details.isClockRunning}
                        />
                    ) : (
                        durationInMinutes
                    )}
                </Text>
            )}
        </FlexPane>
    );
};

const UpcomingContent = props => {
    const {
        mojitoTools: { config },
        eventItem,
    } = props;
    const { startTime } = eventItem;
    return (
        <FlexPane config={config.dateTimeContainer}>
            <Text config={config.upcomingText}>
                <DateTime config={config.dateFormatter} dateTime={startTime} />
            </Text>
            {config.splitDateTime && (
                <Text config={config.upcomingText}>
                    <DateTime config={config.timeFormatter} dateTime={startTime} />
                </Text>
            )}
        </FlexPane>
    );
};

const resolveLiveTime = event => {
    const { details = {} } = event;
    const { timeInformation, isClockRunning, clockDirection } = details;
    return EventsUtils.calculateTime(timeInformation, isClockRunning, clockDirection);
};
