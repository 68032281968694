import MojitoCore from 'mojito/core';
import MojitoPresentation from 'mojito/presentation';
import { isEmpty } from 'mojito/utils';
import MojitoServices from 'mojito/services';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useMidnightEffect } from 'modules/filters-bar/hooks';

const {
    DateTimeUtils: { addDays, getCustomFormattedDate, getDateWithGmtOffset, getSignedGmtOffset },
    DateTimeTypes: { DATE_FORMAT_OPTIONS },
} = MojitoCore.Base;
const SUPPORTED_DAY_FORMAT_OPTIONS = Object.values(DATE_FORMAT_OPTIONS);
const { ButtonBar, ButtonBarSkeleton, ScrollPane, LoaderSuspense } = MojitoPresentation.Components;
const { RacingHelper } = MojitoPresentation.Utils;
const { selectTimeZoneGmt } = MojitoServices.UserSettings.selectors;
const { selectLanguage } = MojitoCore.Services.SystemSettings.selectors;

const DAY_FILTER = 'day';
const DEFAULT_LOCALE = 'en-US';

const getKey = (filter = {}) => filter.span || filter.dayOffset || filter.type;
const isDayFilter = filterType => filterType === DAY_FILTER;
const convertDayOffsetToDate = (dayOffset, timeZone) => {
    const baseDateInUserTimeZone = getDateWithGmtOffset(getSignedGmtOffset(timeZone));
    return addDays(baseDateInUserTimeZone, dayOffset);
};

const resolveDayFilterLabel = (dayOffset, dayFilterNamesDeps, sportId, stringResolver) => {
    const {
        defaultNamingSchemaForDayFilters,
        selectedTimeZone,
        selectedLocale = DEFAULT_LOCALE,
    } = dayFilterNamesDeps;

    const dateWithOffset = convertDayOffsetToDate(dayOffset, selectedTimeZone);

    const DAY_FILTERS_PREFIX = `$FILTERS_BAR.${RacingHelper.isRacingSport(sportId) ? 'RACING_BASED_SPORTS' : 'MATCH_BASED_SPORTS'}.DAY.`;

    let namingSchema = defaultNamingSchemaForDayFilters;
    const schemaOverride =
        stringResolver.resolveString(`${DAY_FILTERS_PREFIX}${sportId}.${dayOffset}`, true) ||
        stringResolver.resolveString(`${DAY_FILTERS_PREFIX}${dayOffset}`, true);
    const isDynamic =
        schemaOverride &&
        schemaOverride.split(' ').some(s => SUPPORTED_DAY_FORMAT_OPTIONS.includes(s));

    if (isDynamic) {
        namingSchema = schemaOverride;
    }

    const isFixed = schemaOverride && !isDynamic;
    return isFixed
        ? schemaOverride
        : getCustomFormattedDate(dateWithOffset, namingSchema, selectedLocale);
};

const resolveBaseFilterLabel = (key, sportId, stringResolver) => {
    const [, numberFromKey, valueFromKey] = key.split(/(\d+)/);
    const FILTERS_PREFIX = `$FILTERS_BAR.${RacingHelper.isRacingSport(sportId) ? 'RACING_BASED_SPORTS' : 'MATCH_BASED_SPORTS'}.BASE.`;

    return (
        stringResolver.resolveString(`${FILTERS_PREFIX}${sportId}.${key.toUpperCase()}`, true) ||
        stringResolver.resolveString(`${FILTERS_PREFIX}${key.toUpperCase()}`, true) ||
        stringResolver.resolveAndFormatString(
            `${FILTERS_PREFIX}${valueFromKey.toUpperCase()}`,
            numberFromKey
        )
    );
};

const convertToButtonItems = (filters, sportId, stringResolver, { dayFilterNamesDeps }) => {
    const resolveTranslation = filter => {
        if (isDayFilter(filter.type)) {
            return resolveDayFilterLabel(
                filter.dayOffset,
                dayFilterNamesDeps,
                sportId,
                stringResolver
            );
        }

        const key = getKey(filter);
        return resolveBaseFilterLabel(key, sportId, stringResolver);
    };

    return filters.map(filter => ({
        key: `${getKey(filter)}`,
        label: resolveTranslation(filter),
        group: filter.group,
    }));
};

export default function FiltersBar({
    sportId,
    filters,
    selectedFilterKey,
    onFilterClicked,
    getItemHrefLink,
    mojitoTools,
}) {
    const { config, stringResolver } = mojitoTools;

    const [midnightReRenderTrigger, setMidnightReRenderTrigger] = useState(0);

    const timeZone = useSelector(state => selectTimeZoneGmt(state));
    const locale = useSelector(state => selectLanguage(state));

    useMidnightEffect(getDateWithGmtOffset(getSignedGmtOffset(timeZone)), () => {
        setMidnightReRenderTrigger(prev => prev + 1);
    });

    const buttonItems = useMemo(
        () =>
            filters
                ? convertToButtonItems(filters, sportId, stringResolver, {
                      dayFilterNamesDeps: {
                          defaultNamingSchemaForDayFilters: config.defaultNamingSchemaForDayFilters,
                          selectedTimeZone: timeZone,
                          selectedLocale: locale,
                      },
                  })
                : [],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [filters, locale, sportId, timeZone, stringResolver, midnightReRenderTrigger]
    );

    const buttonBarSkeleton = <ButtonBarSkeleton config={config.topMenuSkeleton} />;
    return (
        <>
            <ScrollPane config={config.scrollPane}>
                <LoaderSuspense
                    config={config.skeletonLoader}
                    loader={buttonBarSkeleton}
                    isContentPending={isEmpty(filters)}
                >
                    <ButtonBar
                        items={buttonItems}
                        selectedKey={selectedFilterKey}
                        onSelectionChanged={onFilterClicked}
                        getItemHrefLink={getItemHrefLink}
                        config={config.filter}
                    />
                </LoaderSuspense>
            </ScrollPane>
        </>
    );
}
