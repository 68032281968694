import React from 'react';

import {createRoot} from 'react-dom/client';

import {AbstractFeature, allFeatures} from '#core/application/abstract-feature.js';

class WidgetsProviderServiceFeature extends AbstractFeature {
    constructor(parent) {
        super(parent);
        this.widgets = Object.create(null);
        this.allRoutes = [];
        this.mountedWidgetsByRegion = {};
    }

    get name() {
        return 'WidgetsProviderService';
    }

    afterMojitoConfigBuild(mojitoConfig) {
        if (this.getWidgetData().noBaseUrl) {
            // if current URL is a widget, and has noBaseUrl flag
            mojitoConfig.core.baseUrl = '';
            mojitoConfig.services.transactionUrl = '';
        }

        return super.afterMojitoConfigBuild(mojitoConfig);
    }

    registerWidget(name, route, component, data) {
        const widget = {
            name: name,
            route: route,
            component: component,
            data: data,
        };

        this.widgets[name] = widget;

        if (route) {
            this.allRoutes.push(route);
        }
    }

    // Following is needed for 2-phase setup
    updateWidgetComponent(name, component) {
        this.widgets[name].component = component;
    }

    isFullscreenWidget() {
        return Boolean(this.getWidgetByCurrentRoute());
    }

    getWidgetData() {
        return this.getWidgetByCurrentRoute()?.data || {};
    }

    getWidgetByCurrentRoute() {
        const currentRoute = this.getCurrentRoute();
        const allWidgets = Object.values(this.widgets);

        return allWidgets.find(widget => currentRoute.startsWith(widget.route));
    }

    getCurrentRoute() {
        const {pathname} = window.location;

        if (!pathname) {
            return '';
        }

        // Remove first and last slash with all parameters
        return pathname.replace(/^\/|\/$|\?.*$|\/\?.*$/g, '');
    }

    mountWidget(widgetId, regionId, params = {}) {
        const widgetName = widgetId;
        const region = document.getElementById(regionId);
        if (!region) {
            this.logger.error(`RegionId "${regionId}" does not point to a DOM element`);
            return false;
        }
        if (!this.widgets[widgetName]) {
            this.logger.error(`Widget is not registered: ${widgetName}`);
            return false;
        }
        if (this.mountedWidgetsByRegion[regionId]) {
            this.logger.error(
                `Cannot mount widget "${widgetId}" to "${regionId}" because this region is already occupied by "${this.mountedWidgetsByRegion[regionId].widgetId}"`
            );
            return false;
        }

        const root = createRoot(region);
        try {
            const Component = this.widgets[widgetName].component;
            root.render(React.createElement(Component, params));
        } catch (e) {
            this.logger.error(`Failed to mount widget: ${widgetId}`);
            return false;
        }
        this.mountedWidgetsByRegion[regionId] = {
            root,
            widgetId, // not used at the moment
        };
        return true;
    }

    unmountWidget(regionId) {
        const region = document.getElementById(regionId);
        if (!region) {
            this.logger.error(`RegionId "${regionId}" does not point to a DOM element`);
            return false;
        }
        try {
            const {root} = this.mountedWidgetsByRegion[regionId];
            root.unmount();
            delete this.mountedWidgetsByRegion[regionId];
        } catch (e) {
            this.logger.error(`Failed to unmount widget at: ${regionId}`);
            return false;
        }
        return true;
    }

    unmountAllWidgets() {
        let result = true;
        Object.keys(this.mountedWidgetsByRegion).forEach(regionId => {
            const res = this.unmountWidget(regionId);
            result = result & res;
        });
        this.mountedWidgetsByRegion = {};
        return result;
    }
}

export const WidgetsProviderService = new WidgetsProviderServiceFeature(allFeatures);
