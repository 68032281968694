/* eslint-disable */
import React, {useEffect, useRef, useState} from 'react';

import MojitoPresentation from 'mojito/presentation';

const FixedWidthTime = MojitoPresentation.Components.FixedWidthTime;
const FlexPane = MojitoPresentation.Components.FlexPane;
const Text = MojitoPresentation.Components.Text;

const DateTimeFormatter = MojitoPresentation.Utils.DateTime.DateTimeFormatter;

const UPDATE_TIMER = {
    F: prevTime => prevTime + 1,
    B: (prevTime, callback) => {
        if (prevTime < 1) {
            callback();
            return 0;
        }

        return prevTime - 1;
    },
};

const Timer = props => {
    const {time, direction, running, format, mojitoTools, onTimerExpired, startTimeLabel} = props;
    const {config, appContext} = mojitoTools;
    const [timeInSeconds, setTimer] = useState(time);
    const [timerExpired, setTimerExpired] = useState(false);
    const intervalRef = useRef();
    const previousTime = useRef(time);

    const timerExpiredCallback = () => {
        if (!timerExpired) {
            setTimerExpired(true);
            onTimerExpired(true);
        }
    };

    const timerStyle = timeInSeconds < 100 * 60 ? config.timerFourDigits : config.timerFiveDigits;

    /*
    useEffect React hook below is commented out to prevent timer from ticking on mockup pages.
    This is done to guarantee scamper screenshots sustainability.
     */
    // useEffect(() => {
    //     adjustTimer(intervalRef, time, previousTime, setTimer);
    //     running && startTimer(intervalRef, setTimer, UPDATE_TIMER[direction], timerExpiredCallback);
    //     !running && stopTimer(intervalRef);
    //
    //     return () => clearInterval(intervalRef.current);
    // }, [running, time, direction, timerExpired]);

    return (
        <FlexPane config={config.timerWrapper}>
            {startTimeLabel && <Text config={config.startTimeLabel}>{startTimeLabel}</Text>}
            <FlexPane config={config.timeString}>
                {config.isExtendedFormat ? (
                    <ExtendedTimeString {...props} time={timeInSeconds} />
                ) : (
                    <FixedWidthTime
                        config={timerStyle}
                        time={DateTimeFormatter.formatDuration(
                            timeInSeconds,
                            appContext.userSettings(),
                            appContext.systemSettings().language,
                            format
                        )}
                    />
                )}
            </FlexPane>
        </FlexPane>
    );
};

const ExtendedTimeString = props => {
    const {time, mojitoTools} = props;
    const {config, stringResolver} = mojitoTools;

    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time - hours * 3600) / 60);
    const seconds = time - hours * 3600 - minutes * 60;

    const hoursLabel =
        hours === 1 ? stringResolver.resolveString('$TIMER.HOUR') : stringResolver.resolveString('$TIMER.HOURS');
    const minutesLabel =
        minutes === 1 ? stringResolver.resolveString('$TIMER.MINUTE') : stringResolver.resolveString('$TIMER.MINUTES');
    const secondsLabel =
        seconds === 1 ? stringResolver.resolveString('$TIMER.SECOND') : stringResolver.resolveString('$TIMER.SECONDS');

    return (
        <>
            {!!hours && (
                <Text config={config.hoursWrapper}>
                    <span style={config.style.extendedTime}>{hours}</span> {hoursLabel}
                </Text>
            )}
            {(!!minutes || !!hours) && (
                <Text config={config.minutesWrapper}>
                    <span style={config.style.extendedTime}>{minutes < 10 ? `0${minutes}` : minutes}</span>{' '}
                    {minutesLabel}
                </Text>
            )}
            {(!!seconds || !!minutes || !!hours) && (
                <Text config={config.secondsWrapper}>
                    <span style={config.style.extendedTime}>{seconds < 10 ? `0${seconds}` : seconds}</span>{' '}
                    {secondsLabel}
                </Text>
            )}
        </>
    );
};

function startTimer(intervalRef, setTimer, updateTimer, callback) {
    if (!intervalRef.current) {
        intervalRef.current = setInterval(() => {
            setTimer(timer => updateTimer(timer, callback));
        }, 1000);
    }
}

function stopTimer(intervalRef) {
    if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = undefined;
    }
}

function adjustTimer(intervalRef, time, previousTime, setTimer) {
    if (time !== previousTime.current) {
        stopTimer(intervalRef);
        previousTime.current = time;
        setTimer(time);
    }
}

export default Timer;
