import MojitoCore from 'mojito/core';

import {STORE_KEY, INITIAL_STATE} from './slice.js';

const reduxInstance = MojitoCore.Services.redux;

export const selectState = state => {
    const appState = state || reduxInstance.store?.getState();

    return appState?.[STORE_KEY] || INITIAL_STATE;
};

export const selectMeta = state => selectState(state).meta;
