import MojitoCore from 'mojito/core';
const UIView = MojitoCore.Presentation.UIView;
import CompetitionsViewImpl from './competitions-view.jsx';

/**
 * CompetitionsView - renders competitions content inside SportPage.
 *
 * @class CompetitionsView
 * @memberof Mojito.Applications.Sports.Pages
 */
export default UIView('CompetitionsView', CompetitionsViewImpl);
