/**
 * User bonuses types.
 *
 * @class UserBonusesTypes
 * @name types
 * @memberof Mojito.Services.UserBonuses
 */
export default class UserBonusesTypes {}

/**
 * Bonus free bets code state.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Services.UserBonuses.types
 */
UserBonusesTypes.FREE_BETS_CODE_STATE = {
    ACCEPTED: 'ACCEPTED',
    ERROR: 'ERROR',
    PENDING: 'PENDING',
    READY: 'READY',
};

/**
 * Error codes that can arrive during add free bet process.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Services.UserBonuses.types
 */
UserBonusesTypes.FREE_BETS_ERROR_CODE = {
    /* This code appears if there is an attempt to add free bet using empty input string. */
    EMPTY_INPUT: 'EMPTY_INPUT',
};
