import MojitoCore from 'mojito/core';
const { UIView } = MojitoCore.Presentation;
import MeetingsViewImpl from './meetings-view.jsx';

/**
 * MeetingsView - renders meetings content inside SportPage.
 *
 * @class MeetingsView
 * @memberof Mojito.Applications.Sports.Pages
 */
export default UIView('MeetingsView', MeetingsViewImpl);
