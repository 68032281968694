import MojitoServices from 'mojito/services';
const AnalyticsService = MojitoServices.Analytics.AnalyticsService;
// /**
//  * Google analytics 4 service extended with a support of multiple trackers, see DBX-10054 for details
//  */
export class DBXGoogleAnalytics4Service extends AnalyticsService {
    constructor() {
        super('ga4');
    }
}
