import MojitoCore from 'mojito/core';
import MojitoServices from 'mojito/services';

import {INTENT_TYPES} from '#core/utils/intent-types.js';
import {isNative} from '#core/utils/context-utils.js';
import {isString} from '#core/utils/utils.js';

import {isInternalUrl} from '#core/application/utils.js';
import {ApplicationConfig} from '#core/config/application-config.js';

const {dispatch} = MojitoCore.Services.redux.store;
const AbstractExternalNavigationService = MojitoServices.ExternalNavigation.AbstractExternalNavigationService;
const IntentActions = MojitoCore.Intents.actions;

export class ExternalNavigationService extends AbstractExternalNavigationService {
    constructor(config) {
        super();
        this.config = config;
    }

    /**
     * Navigate to external url.
     * @param payload {Object} - External navigation payload.
     * @param payload.modal {object} - If exists the url should be opened as a modal window.
     * @param payload.url {string} - External url to navigate to.
     * @param [payload.windowFeatures] {string} - A string containing a comma-separated list of window features.
     * @param payload.windowName {string} - Window name.
     */
    navigate({modal, url, windowFeatures, windowName}) {
        // Foreign windows are delegated to the ForeignWindow domain
        if (modal) {
            return;
        }

        if (ApplicationConfig.isEmbedded && !isInternalUrl(url)) {
            /*
            The payload.windowName parameter is an empty string when the "Open Link In New Tab" option enabled in GenMoj CMS.
            Portal doesn't support opening a URL in a new tab at the moment.
            It was agreed that Sportsbook will open such a URL by its own on Desktop and on Mobile.
            While on Native Sportsbook will pass the URL to Portal.
             */
            if (windowName !== '' || isNative()) {
                // Override absolute URLs
                if (Array.isArray(this.config.relativeRoutes)) {
                    for (const str of this.config.relativeRoutes) {
                        if (isString(str) && url.includes(str)) {
                            const {pathname, search} = new URL(url);
                            url = pathname + search;
                        }
                    }
                }

                // Let Portal decide how to open the URL
                dispatch(IntentActions.publishIntent(INTENT_TYPES.NAVIGATE_HOST, {url}));
                return;
            }
        }

        MojitoCore.Utils.openUrl(url, windowName, windowFeatures);
    }
}
