import PropTypes from 'prop-types';
import FlexPaneImpl from './flex-pane.jsx';
import MojitoCore from 'mojito/core';

/**
 * A component that arranges its children using CSS Flexible Box Layout. Includes features for defining custom spacing and inserting custom separators between elements.
 *
 * **Note:** The API for this component is unstable and may change.
 *
 * @class FlexPane
 * @memberof Mojito.Presentation.Components
 */

/**
 * `FlexPane` component prop types.
 *
 * @property {string} [id] - The id of the rendered DOM element.
 * @property {Function} [separator] - A function that returns a rendered component, inserted between child elements. It receives a unique key as its first argument and the separator index as its second argument.
 * @property {string} [class] - The class name applied to the root element of the component.
 * @property {node} [children] - The child elements of the FlexPane.
 * @property {object | Function} [elementRef] - A ref to the rendered element.
 *
 * @memberof Mojito.Presentation.Components.FlexPane
 */
const propTypes = {
    id: PropTypes.string,
    separator: PropTypes.func,
    class: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    elementRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

export default MojitoCore.Presentation.UIView('FlexPane', FlexPaneImpl, propTypes);
