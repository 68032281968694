import TimerImpl from './timer.jsx';
import MojitoCore from 'mojito/core';
import PropTypes from 'prop-types';
import TimerTypes from './types.js';
import { noop } from 'mojito/utils';
import { DURATION } from 'presentation/formatters/date-time/types.js';

const { TIMER_DIRECTION } = TimerTypes;
const { UIView } = MojitoCore.Presentation;
/**
 * Timer component. Used to show a ticking timer that can either count up or down. The timer will show
 * minutes and seconds only.
 * This timer only supports five digits (ex. 999:99). If it reaches six digits, it can start to jump in the UI and behave in an unwanted way.
 *
 * @class Timer
 * @memberof Mojito.Presentation.Components
 */

/**
 * Timer direction.
 *
 * @typedef DIRECTION
 * @property {string} F - Timer ticking forward.
 * @property {string} B - Timer ticking backward.
 * @memberof Mojito.Presentation.Components.Timer
 */

/**
 * Timer prop types.
 *
 * @property {number} time - Time in seconds. The time of the timer to initially display and start ticking from. If time
 * is updated, the timer will adjust to the new value. A positive number is expected.
 * @property {Mojito.Presentation.Components.TimerTypes.DIRECTION} direction - Defines if the timer ticks backward of forward.
 * @property {boolean} [running = true] - Defines if the timer is running. When set to <code>false</code>, the timer
 * will pause the ticking.
 * @property {Mojito.Presentation.Utils.DateTime.DateTimeTypes.DURATION} [format = MINUTES_SECONDS] - Defines the time format.
 * @property {string} [startTimeLabel = ''] - The start time label of an event.
 * @property {Function} [onTimerExpired = () => {}] - Function that will be called when timer is expired (time went down to zero).
 * @memberof Mojito.Presentation.Components.Timer
 */
const propTypes = {
    time: PropTypes.number.isRequired,
    direction: PropTypes.oneOf(Object.values(TIMER_DIRECTION)).isRequired,
    running: PropTypes.bool,
    format: PropTypes.oneOf(Object.values(DURATION)),
    onTimerExpired: PropTypes.func,
    startTimeLabel: PropTypes.string,
};

const defaultPropTypes = {
    running: true,
    format: DURATION.MINUTES_SECONDS,
    onTimerExpired: noop,
    startTimeLabel: '',
};

export default UIView('Timer', TimerImpl, propTypes, defaultPropTypes);
