import { useState, useEffect } from 'react';
import { useOutletContext } from 'react-router';
import MojitoPresentation from 'mojito/presentation';
import MojitoModules from 'mojito/modules';

const PriceBoostCoupon = MojitoModules.PriceBoostCoupon;

const { FlexPane } = MojitoPresentation.Components;

export default function PriceBoostsView({ mojitoTools }) {
    const { config, emitPerformanceMark } = mojitoTools;
    const { price_boosts } = useOutletContext();
    const [dataLoaded, setDataLoaded] = useState(false);

    // Determining child modules loading state
    useEffect(() => {
        emitPerformanceMark('moduleRendered', dataLoaded);
    }, [emitPerformanceMark, dataLoaded]);

    const onChildLoaded = () => {
        setDataLoaded(true);
    };

    if (!price_boosts) {
        return null;
    }

    return (
        <FlexPane config={config.container}>
            <PriceBoostCoupon
                boostedGroupId={price_boosts.group}
                config={config.priceBoostCoupon}
                onDataLoad={onChildLoaded}
            />
        </FlexPane>
    );
}
