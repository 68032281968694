/**
 * System information types.
 *
 * @class SystemInformation
 * @name types
 * @memberof Mojito.Services.SystemInformation
 */
export default class SystemInformationTypes {}

/**
 * System information collection name.
 *
 * @typedef COLLECTION_NAME
 * @type {string}
 * @memberof Mojito.Services.SystemInformation.types
 */
SystemInformationTypes.COLLECTION_NAME = 'systeminformation';
