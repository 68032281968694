import MojitoServices from 'mojito/services';
import MojitoPresentation from 'mojito/presentation';
import { useEffect } from 'react';
import { selectProtectRoutes } from 'applications/common/stores/router/selectors.js';
import { useSelector } from 'react-redux';

const { IntentActionTrigger } = MojitoPresentation.Base.Intent;
const { selectLoginState } = MojitoServices.Authentication.selectors;
const { UNKNOWN } = MojitoServices.Authentication.types.STATES;

export default function Protected({ children }) {
    const shouldProtectRoute = useSelector(selectProtectRoutes);
    const loginState = useSelector(selectLoginState);

    useEffect(() => {
        if (loginState !== UNKNOWN && shouldProtectRoute) {
            IntentActionTrigger.showHomePage(true);
        }
    }, [shouldProtectRoute, loginState]);

    return !shouldProtectRoute ? children : null;
}
