import React, { useMemo } from 'react';
import { useParams, Outlet } from 'react-router';
import { useSelector } from 'react-redux';
import MojitoModules from 'mojito/modules';
import MojitoPresentation from 'mojito/presentation';
import MojitoServices from 'mojito/services';
import MojitoCore from 'mojito/core';

const uiStyle = MojitoCore.Presentation.uiStyle;
const { useMenuContent, useMenuLoading } = MojitoModules.Common.Hooks;
const Breadcrumbs = MojitoModules.Breadcrumbs.module;
const BreadcrumbsTypes = MojitoModules.Breadcrumbs.types;
const SportsMenu = MojitoModules.SportsMenu.module;
const { BannerPromotions, PromotionUtils } = MojitoModules.Promotions;
const { SportTabSelector } = MojitoModules;
const { selectTimeZoneGmt } = MojitoServices.UserSettings.selectors;
const NavigationPathBuilder = MojitoPresentation.Base.Navigation.NavigationPathBuilder;
const {
    FlexPane,
    DocumentHead,
    GlobalLinks,
    PageSplitLayout,
    DefaultHeadMetaFactory,
    PageHeader,
    EmptyContent,
} = MojitoPresentation.Components;

const renderBreadcrumbs = (sportId, config) => {
    if (config.hideBreadcrumbs) {
        return null;
    }
    const breadcrumbs = [
        {
            type: BreadcrumbsTypes.SPORT_NAME,
            data: sportId,
            analyticsData: sportId,
        },
    ];

    return <Breadcrumbs breadcrumbs={breadcrumbs} />;
};

const renderLeftPromotions = config => {
    const { leftPromotions: promotionsConfig } = config;
    if (!PromotionUtils.hasLocationId(promotionsConfig)) {
        return;
    }

    return <BannerPromotions config={promotionsConfig} />;
};

const renderDocumentHead = (sportId, appContext, config, l10n) => {
    const sportName = sportId && appContext.sportName(sportId);
    const metaFactory = config.documentHeadMetaFactory || DefaultHeadMetaFactory;

    if (!sportName) {
        return null;
    }

    const headData = {
        title: '$SPORT_PAGE.PAGE_TITLE',
        keywords: '$SPORT_PAGE.PAGE_KEYWORDS',
        description: '$SPORT_PAGE.PAGE_DESCRIPTION',
        canonical: '$SPORT_PAGE.PAGE_CANONICAL',
        localizedParams: new Map([['sportName', sportName]]),
        params: {
            sportId: sportId,
        },
    };

    return <DocumentHead data={headData} metaFactory={new metaFactory(l10n)} />;
};

const toMenuNavigation = (menuId, sportId) => {
    switch (menuId) {
        case 'competitions':
            return NavigationPathBuilder.toSportCompetitions(sportId);
        case 'matches':
            return NavigationPathBuilder.toSportMatches(sportId);
        case 'price_boosts':
            return NavigationPathBuilder.toSportPriceBoosts(sportId);
        case 'outrights':
            return NavigationPathBuilder.toSportOutright(sportId);
        case 'future':
            return NavigationPathBuilder.toSportFuture(sportId);
        case 'highlights':
            return NavigationPathBuilder.toSportHighlights(sportId);
        case 'meetings':
            return NavigationPathBuilder.toSportMeetings(sportId);
    }
};

export default function SportPage({ mojitoTools }) {
    const { config, appContext, l10n } = mojitoTools;
    const { primaryContentContainer, sportTabSelector, secondaryContentContainer } = config;
    const { sportId, typeId } = useParams();

    const timeZone = useSelector(state => selectTimeZoneGmt(state));
    const contentInfo = useMenuContent(sportId, timeZone);
    const menuItemsLoading = useMenuLoading(timeZone);

    const headerConfig = useMemo(() => {
        const { sportBackgroundImages, pageHeader } = config;
        const bgOverride = { backgroundImage: { src: sportBackgroundImages[sportId] } };
        return uiStyle.merge(pageHeader, bgOverride);
    }, [sportId, config]);

    const availableMenuNavigations = Object.keys(contentInfo).map(menuId =>
        toMenuNavigation(menuId, sportId)
    );
    // We will detect if URL navigation that user performs is strictly eligible to any available menu.
    // e.g. if user has soccer/competitions in URL then we will check if competitions are available among contentInfo keys.
    const isLanding = appContext.isContentShown(NavigationPathBuilder.toSport(sportId), true);
    const hasContent =
        isLanding ||
        availableMenuNavigations.some(navigation => appContext.isContentShown(navigation));

    return (
        <>
            {renderDocumentHead(sportId, appContext, config, l10n)}
            <PageSplitLayout>
                {/* Primary content */}
                <FlexPane config={primaryContentContainer}>
                    {hasContent && (
                        <>
                            {renderBreadcrumbs(sportId, config)}
                            {!typeId && (
                                <PageHeader
                                    config={headerConfig}
                                    label={appContext.sportName(sportId)}
                                >
                                    <SportTabSelector config={sportTabSelector} sportId={sportId} />
                                </PageHeader>
                            )}
                            <Outlet context={{ ...contentInfo, menuItemsLoading }} />
                        </>
                    )}
                    {!menuItemsLoading && !hasContent && (
                        <EmptyContent config={config.emptyContent} />
                    )}
                </FlexPane>
                {/* Secondary content */}
                <FlexPane config={secondaryContentContainer}>
                    <SportsMenu selectedSportId={sportId} />
                    <GlobalLinks />
                    {renderLeftPromotions(config)}
                </FlexPane>
            </PageSplitLayout>
        </>
    );
}
