export const AUTH_METHOD = {
    INTERNAL: 'internal',
    EXTERNAL: 'external',
};

export const INITIALIZATION_STATE = {
    INIT_NONE: '',
    INIT_INITIALIZING: 'INITIALIZING',
    INIT_DONE: 'INITIALIZED',
};

export const EXTERNAL_METHODS = {
    SHOW_LOGIN: 'SHOW_LOGIN',
    SHOW_DEPOSIT: 'SHOW_DEPOSIT',
    BALANCE_UPDATED: 'BALANCE_UPDATED',
    GET_SESSION_INFO: 'GET_SESSION_INFO',
    LOG_OUT: 'LOG_OUT',
    PAGE_METATAGS_UPDATED: 'PAGE_METATAGS_UPDATED',
    NAVIGATE: 'NAVIGATE',
    ANALYTICS_EVENT: 'ANALYTICS_EVENT',
    PERFORMANCE_LOG: 'PERFORMANCE_LOG',
    INTERNAL_ROUTE: 'INTERNAL_ROUTE',
    SPORTSBOOK_LOADED: 'SPORTSBOOK_LOADED', // Deprecated
    APPLICATION_LOADED: 'APPLICATION_LOADED',
};

export const API_MESSAGES = {
    SYNC_SESSION: 'SYNC_SESSION',
    CHANGE_TIMEZONE: 'CHANGE_TIMEZONE',
    CHANGE_ODDS_FORMAT: 'CHANGE_ODDS_FORMAT',
    GET_BALANCE: 'GET_BALANCE',
    SET_BALANCE: 'SET_BALANCE',
    OAPI_RESPONSE: 'OAPI_RESPONSE',
    GIVE_COOKIE_CONSENT: 'GIVE_COOKIE_CONSENT',
    PERFORMANCE_METRIC: 'PERFORMANCE_METRIC',
    PREFETCH_DATA: 'PREFETCH_DATA',
};
