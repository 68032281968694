import MojitoCore from 'mojito/core';
import EventGroupTypes from 'services/eventgroups/types.js';

const DataProvider = MojitoCore.Services.DataProvider;

const { COLLECTION_NAME } = EventGroupTypes;

/**
 *
 * Class offering the possibility to link to specific event group.
 *
 * @class EventGroupProvider
 * @extends Mojito.Core.Services.DataProvider
 * @memberof Mojito.Services.EventGroups
 */
class EventGroupProvider extends DataProvider {
    init(languageCode) {
        super.addSimpleCollection(COLLECTION_NAME, languageCode);
    }

    subscribeToEntity(eventGroupId, onUpdate) {
        return super.subscribeToEntity(eventGroupId, onUpdate, COLLECTION_NAME);
    }

    subscribeToEntities(eventGroupIds, onInit, onUpdate) {
        return super.subscribeToEntities(eventGroupIds, onInit, onUpdate, COLLECTION_NAME);
    }
}

export default new EventGroupProvider();
