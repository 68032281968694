import {deepFreezeAllEnumerables} from '#core/utils/config-utils.js';

export const configSchemas = [];
export const tileSchemas = [];
export const tileSchemaById = Object.create(null);
export const paletteSchemas = [];

/**
 * Registers schema
 *
 * @param schema {object} - feature to register
 */
export function registerSchema(schema) {
    configSchemas.push(schema);
    process.env.NODE_ENV === 'development' && deepFreezeAllEnumerables(schema);
}

/**
 * Registers tile schema
 *
 * @param schema {object} - palette schema to register
 */
export function registerTileSchema(schema) {
    if (!schema.key) throw new Error('Tile id is not specified');
    tileSchemas.push(schema);

    const simplifiedKey = schema.key.startsWith('tile.') ? schema.key.substring(5) : schema.key;
    tileSchemaById[simplifiedKey] = schema;
    process.env.NODE_ENV === 'development' && deepFreezeAllEnumerables(schema);
}

/**
 * Registers palette schema
 *
 * @param schema {object} - palette schema to register
 */
export function registerPaletteSchema(schema) {
    if (!schema.key) throw new Error('Palette id is not specified');
    paletteSchemas.push(schema);
    process.env.NODE_ENV === 'development' && deepFreezeAllEnumerables(schema);
}
