import MojitoServices from 'mojito/services';
import MojitoPresentation from 'mojito/presentation';
import BonusCard from 'modules/bonuses/bonus-card/index.js';

const BonusType = MojitoServices.Bonus.types.BONUS_TYPE;
const { FlexPane, Text, Spinner } = MojitoPresentation.Components;

const BonusHistory = props => {
    const {
        oddsBoosts,
        campaigns,
        currencyCode,
        bonusHistoryLoading,
        mojitoTools: { config, stringResolver },
    } = props;

    const freeBets = props.freeBets.map(freeBet => ({
        ...freeBet,
        bonusType: BonusType.FREEBET,
    }));

    const bonuses = campaigns
        .concat(oddsBoosts)
        .concat(freeBets)
        .sort((a, b) => a.expireDate - b.expireDate);

    const renderDataLoader = () => {
        return (
            <FlexPane config={config.spinnerContainer}>
                <Text config={config.spinnerLabel} class="ta-fetchingBonusesHistoryText">
                    {stringResolver.resolveString('$BONUS_HISTORY.BONUSES_ARE_LOADING')}
                </Text>
                <Spinner config={config.spinner} />
            </FlexPane>
        );
    };

    if (bonusHistoryLoading && !bonuses.length) {
        return renderDataLoader();
    }

    return (
        <FlexPane config={config.topWrapper}>
            {!bonuses.length && (
                <Text config={config.noBonusesText}>
                    {stringResolver.resolveString('$BONUS_HISTORY.NO_BONUSES_FOUND_LABEL')}
                </Text>
            )}
            {bonuses.map(bonus => (
                <BonusCard
                    config={config.bonusCard}
                    shouldShowRemainingAmount={false}
                    key={bonus.id}
                    bonus={bonus}
                    currencyCode={currencyCode}
                />
            ))}
        </FlexPane>
    );
};

export default BonusHistory;
