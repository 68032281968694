import MojitoModules from 'mojito/modules';
import MojitoPresentation from 'mojito/presentation';
import { useLayoutEffect } from 'react';
import { useParams, useOutletContext, Outlet } from 'react-router';
import { isEmpty } from 'mojito/utils';

const NavigationPathBuilder = MojitoPresentation.Base.Navigation.NavigationPathBuilder;
const IntentActionTrigger = MojitoPresentation.Base.Intent.IntentActionTrigger;

const { FiltersBar } = MojitoModules;
const { FlexPane } = MojitoPresentation.Components;

const INPLAY = 'inplay';

const getKey = (filter = {}) => filter.span ?? `${filter.dayOffset ?? filter.type}`;
const getGroupBySelectedFilterKey = (filters, selectedKey) =>
    (filters.find(filter => getKey(filter) === selectedKey) || {}).group || null;
const resolveFirstFilterId = matches => getKey(matches?.filters[0]);
const isValidFilterId = (filterId, matches) =>
    matches?.filters.some(filter => getKey(filter) === filterId);
const getSelectedFilter = matches => matches?.filters.find(item => item.selected);

const MatchesView = props => {
    const {
        mojitoTools: { config, appContext },
    } = props;

    const { routeResolver, analyticsEmitter } = appContext;
    const { sportId, filter } = useParams();
    const { matches } = useOutletContext();
    const filterAutoSelect = !isValidFilterId(filter, matches);
    let selectedFilterKey = filter || getKey(getSelectedFilter(matches));
    if (!isValidFilterId(selectedFilterKey, matches)) {
        selectedFilterKey = resolveFirstFilterId(matches);
    }
    const selectedFilterGroup = getGroupBySelectedFilterKey(matches?.filters, selectedFilterKey);

    useLayoutEffect(() => {
        if (filterAutoSelect && selectedFilterKey) {
            IntentActionTrigger.showSportsMatches(sportId, selectedFilterKey, true);
        }
    }, [sportId, selectedFilterKey, filterAutoSelect]);

    if (isEmpty(matches) && config.filtersBar.skeletonLoader.disableLoader) {
        return null;
    }

    const isInPlayCoupon = selectedFilterKey === INPLAY;
    const onEventItemClicked = eventId => IntentActionTrigger.showSportEvent(sportId, eventId);
    const getHrefEventItem = eventId =>
        routeResolver.getRoute(NavigationPathBuilder.toSportEvent(sportId, eventId));
    const getHrefMatchesFilterItem = filterId =>
        routeResolver.getRoute(NavigationPathBuilder.toSportMatches(sportId, filterId));
    const onFilterClick = key => {
        IntentActionTrigger.showSportsMatches(sportId, key);
        analyticsEmitter.emitAnalytics('matchesFilterClicked', {
            selectedFilterKey: key,
            sport: sportId,
        });
    };

    return (
        <FlexPane config={config.container} class="ta-MatchesView">
            <FlexPane config={config.contentContainer}>
                <FlexPane config={config.filterContainer}>
                    <FiltersBar
                        sportId={sportId}
                        filters={matches?.filters}
                        selectedFilterKey={selectedFilterKey}
                        onFilterClicked={onFilterClick}
                        getItemHrefLink={getHrefMatchesFilterItem}
                        config={config.filtersBar}
                    />
                </FlexPane>
                <Outlet
                    context={{
                        isInPlayCoupon,
                        eventGroupsId: selectedFilterGroup,
                        onEventItemClicked,
                        getHrefItem: getHrefEventItem,
                    }}
                />
            </FlexPane>
        </FlexPane>
    );
};

export default MatchesView;
