import PropTypes from 'prop-types';
import EarlyPayoutOffersImpl from './early-payout-offers';
import MojitoCore from 'mojito/core';

const UIView = MojitoCore.Presentation.UIView;

/**
 * Renders early payout offers component based in a event group.
 *
 * @class EarlyPayoutOffers
 * @memberof Mojito.Modules.EventList
 */

/**
 * EarlyPayoutOffers prop types.
 *
 * @property {Mojito.Services.EventGroups.types.EventGroup} eventGroup - The group of events.
 *
 * @memberof Mojito.Modules.EventList.EarlyPayoutOffers
 */
const propTypes = {
    eventGroup: PropTypes.object.isRequired,
};

export default UIView('EarlyPayoutOffers', EarlyPayoutOffersImpl, propTypes);
