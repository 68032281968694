import MojitoCore from 'mojito/core';
const UIPage = MojitoCore.Presentation.UIPage;
import PriceBoostPageImpl from './price-boost-page.jsx';

/**
 * Page that displays all boosted markets, grouped by sport id.
 *
 * @class PriceBoostPage
 * @extends Mojito.Core.Presentation.UIPage
 * @memberof Mojito.Applications.Sports.Pages
 */

export default UIPage('PriceBoostPage', PriceBoostPageImpl);
