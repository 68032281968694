import MojitoCore from 'mojito/core';
import DataProvidersTypes from 'services/sports-content/content-provider/types.js';

const DataProvider = MojitoCore.Services.DataProvider;
const {
    MeetingProvider: { COLLECTION_NAME },
} = DataProvidersTypes;

/**
 *
 * Class offering the possibility to link to specific meeting lists.
 *
 * @class MeetingProvider
 * @extends Mojito.Core.Services.DataProvider
 * @memberof Mojito.Services.SportsContent.DataProviders
 */
class MeetingProvider extends DataProvider {
    /**
     * Initialize the meeting provider.
     *
     * @param {string} languageCode - The current language code in use.
     *
     * @function Mojito.Services.SportsContent.DataProviders.MeetingProvider#init
     */
    init(languageCode) {
        super.addSimpleCollection(COLLECTION_NAME, languageCode);
    }

    subscribeToEntity(meetingId, setter) {
        return super.subscribeToEntity(meetingId, setter, COLLECTION_NAME);
    }
}

export default new MeetingProvider();
