import MojitoCore from 'mojito/core';
import ImageButton from 'presentation/components/image-button/index.jsx';
import FlexPane from 'presentation/components/flex-pane/index.jsx';
import AbsolutePane from 'presentation/components/absolute-pane/index.jsx';
import Image from 'presentation/components/image/index.jsx';
import Text from 'presentation/components/text/index.jsx';
import Tooltip from 'presentation/components/tooltip/index.jsx';
import { isEmpty } from 'mojito/utils';

const UIViewImplementation = MojitoCore.Presentation.UIViewImplementation;

export default class PageHeader extends UIViewImplementation {
    constructor(props) {
        super(props);

        this.onBackClicked = this.onBackClicked.bind(this);
    }

    onBackClicked() {
        this.emitAnalytics('backClicked');
        const { mojitoTools, onBackClicked } = this.props;
        mojitoTools.appContext.overlayInteraction();
        onBackClicked();
    }

    renderBackgroundImage() {
        const { backgroundImage } = this.config;
        if (isEmpty(backgroundImage.src)) {
            return null;
        }
        return (
            <AbsolutePane
                config={this.config.backgroundImageContainer}
                class={'ta-backgroundImageContainer'}
            >
                <Image alt="Background" config={backgroundImage} />
            </AbsolutePane>
        );
    }

    renderBackButton() {
        if (!this.props.showBackButton) {
            return null;
        }
        return (
            <ImageButton
                config={this.config.backButton}
                onClick={this.onBackClicked}
                class="ta-pageHeaderBackButton"
            />
        );
    }

    renderEmptyPlaceholder() {
        // We need to render empty div in order to support spacing on the left if neither back button nor image are available.
        // In that case label should have a padding on the left and it seems to be the easiest way to achieve this.
        const showIcon = this.props.iconSrc || this.config.icon.src;
        const { emptySpacePlaceholder = {} } = this.config.style;
        const { width, height } = emptySpacePlaceholder;
        if (this.props.showBackButton || showIcon || (isEmpty(width) && isEmpty(height))) {
            return null;
        }
        return <div style={emptySpacePlaceholder} />;
    }

    renderIcon() {
        if (!this.props.iconSrc && !this.config.icon.src) {
            return null;
        }

        return <Image src={this.props.iconSrc} config={this.config.icon} class="icon" alt="icon" />;
    }

    renderLabel() {
        if (!this.props.label) {
            return null;
        }

        return (
            <FlexPane config={this.config.labelContainer} class="ta-labelContainer">
                <Text config={this.config.label}>{this.props.label}</Text>
            </FlexPane>
        );
    }

    renderTooltip() {
        const { tooltipText, onTooltipClick } = this.props;
        if (!tooltipText) {
            return null;
        }
        return (
            <Tooltip
                config={this.config.tooltip}
                onClose={onTooltipClick}
                onClick={onTooltipClick}
                text={tooltipText}
            />
        );
    }

    renderHeader() {
        return (
            <FlexPane config={this.config.headerContainer} class={'ta-PageHeader'}>
                {this.renderBackgroundImage()}
                {this.renderBackButton()}
                {this.renderEmptyPlaceholder()}
                {this.renderIcon()}
                {this.renderLabel()}
                {this.props.children}
                {this.renderTooltip()}
            </FlexPane>
        );
    }

    render() {
        const { fixedHeader, sticky } = this.config;

        return sticky ? (
            <AbsolutePane config={fixedHeader}>{this.renderHeader()}</AbsolutePane>
        ) : (
            this.renderHeader()
        );
    }
}
