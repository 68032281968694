import MojitoCore from 'mojito/core';
const { UIPage } = MojitoCore.Presentation;
import InPlayPageImpl from './inplay-page.jsx';

/**
 * @namespace InPlayPage
 * @memberof Mojito.Applications.Sports.Pages
 */

/**
 * Page that displays InPlay events
 * <br>
 * This page expects the route params to include a sportCode.
 *
 * @class InPlayPage
 * @extends Mojito.Core.Presentation.UIPage
 * @memberof Mojito.Applications.Sports.Pages.InPlayPage
 */

export default UIPage('InPlayPage', InPlayPageImpl);
