import TooltipImpl from './tooltip.jsx';
import MojitoCore from 'mojito/core';
import PropTypes from 'prop-types';
import { noop } from 'mojito/utils';

const UIView = MojitoCore.Presentation.UIView;
/**
 * Tooltip component. Typically, a short messages related to specific UI element that provide additional explanations
 * and guide users towards taking specific actions. Can contain an icon next to a text.
 *
 * @function Tooltip
 * @memberof Mojito.Presentation.Components
 */

/**
 * Tooltip prop types.
 *
 * @property {Function} [onClick = () => {}] - Callback triggered once tooltip clicked.
 * @property {Function} [onClose = () => {}] - Callback for closing tooltip.
 * @property {string} [text] - Content text.
 * @property {object | Function} [elementRef] - A ref to the rendered element.
 * @property {object | Function} [arrowRef] - A ref to the arrow rendered element.
 * @memberof Mojito.Presentation.Components.Tooltip
 */
const propTypes = {
    onClick: PropTypes.func,
    onClose: PropTypes.func,
    text: PropTypes.string,
    elementRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    arrowRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

const defaultProps = {
    onClick: noop,
    onClose: noop,
};

export default UIView('Tooltip', TooltipImpl, propTypes, defaultProps);
