import PropTypes from 'prop-types';
import EventListSkeletonImpl from './event-list-skeleton.jsx';
import MojitoCore from 'mojito/core';

const { UIView } = MojitoCore.Presentation;

/**
 * Event list skeleton component used to represent loading event list.
 *
 * @function EventListSkeleton
 * @memberof Mojito.Modules.EventList
 */

/**
 * EventListSkeleton prop types.
 *
 * @property {string} [sportId] - Sport id. Used to determine if US style skeletons will be displayed.
 * @property {boolean} [enableSorting] - Enable sorting mode style.
 * @property {boolean} [isInPlay] - Flag indicating if it is In Play type.
 * @property {object | Function} [elementRef] - A ref to the rendered element.
 * @property {object} [eventListConfig] - EventList config.
 *
 * @memberof Mojito.Modules.EventList.EventListSkeleton
 */
const propTypes = {
    sportId: PropTypes.string,
    enableSorting: PropTypes.bool,
    isInPlay: PropTypes.bool,
    elementRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    eventListConfig: PropTypes.object,
};

export default UIView('EventListSkeleton', EventListSkeletonImpl, propTypes);
