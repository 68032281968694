import MojitoCore from 'mojito/core';
import MeetingsCouponImpl from './meetings-coupon.jsx';

const { UIView } = MojitoCore.Presentation;

/**
 * MeetingsCoupon - render the content based on meetings sub sport page for a specific filter of racing content.
 *
 * @class MeetingsCoupon
 * @memberof Mojito.Applications.Sports.Pages
 */
export default UIView('MeetingsCoupon', MeetingsCouponImpl);
