import {PALETTE_IDS} from '#core/application/modules/ids.js';

import {COLOR, FONT} from '../theme.js';

export function getBetHistoryConfig() {
    const CUSTOM_CONFIG = {};

    return {
        custom: {
            [PALETTE_IDS.BET_HISTORY]: CUSTOM_CONFIG,
        },
        palette: {
            [PALETTE_IDS.BET_HISTORY]: {
                BET_HISTORY_EMPTY_CONTENT_MESSAGE: {
                    EMPTY_CONTENT_MESSAGE_TEXT: COLOR.BRAND.FOREGROUND2,
                },
                BET_HISTORY_ERROR_TEXT: COLOR.BRAND.FOREGROUND2,
                BET_HISTORY_MOBILE_EMPTY_CONTENT_MESSAGE: {
                    EMPTY_CONTENT_MESSAGE_TEXT: COLOR.BRAND.FOREGROUND2,
                },
                BET_HISTORY_MOBILE_ERROR_TEXT: COLOR.BRAND.FOREGROUND2,
                BET_HISTORY_TABLE: {
                    TABLE_ITEM_DIVIDER_COLOR: COLOR.BRAND.FOREGROUND3,
                },
                BET_HISTORY_MOBILE_ITEM_SEPARATOR_COLOR: COLOR.BRAND.FOREGROUND3,
                BET_HISTORY_DETAILS_SEPARATOR_COLOR: COLOR.BRAND.FOREGROUND3,

                BET_HISTORY_DETAILS_LEGS_HEADER_TEXT: {
                    fontSize: FONT.SIZE_SMALL,
                    textTransform: 'capitalize',
                    fontWeight: FONT.WEIGHT_BOLD,
                    color: COLOR.BRAND.FOREGROUND5,
                },

                BET_HISTORY_DETAILS_LEG_DATE_INFO_TEXT: COLOR.BRAND.FOREGROUND4,
                BET_HISTORY_DETAILS_RECEIPT_INFO_TEXT: COLOR.BRAND.FOREGROUND4,
            },
        },
    };
}
