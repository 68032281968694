import MojitoCore from 'mojito/core';

import {getAllParamsFromURL} from '#core/utils/url-utils.js';

import {WidgetsProviderService} from '#core/application/widgets-provider/index.js';

const {UIViewImplementation} = MojitoCore.Presentation;

export default class AbstractWidget extends UIViewImplementation {
    constructor(props) {
        super(props);
        this.isFullscreenWidget = WidgetsProviderService.isFullscreenWidget();
        this.params = this.isFullscreenWidget ? getAllParamsFromURL() : (this.params = props);
    }
}
