import PropTypes from 'prop-types';
import GridPaneImpl from './grid-pane.jsx';
import MojitoCore from 'mojito/core';

/**
 * A component that arranges its child elements using the CSS Grid Layout.
 *
 * @class GridPane
 * @memberof Mojito.Presentation.Components.GridLayout
 */

/**
 * `GridPane` component prop types.
 *
 * @property {number} [repeatedColumnsNum] - Defines the number of columns in the grid.
 * @property {string} [gridTemplateColumns] - Overrides the CSS grid-template-columns property if specified; otherwise, the configured style is used.
 * @property {string} [class] - The CSS classname applied to the root element of the GridPane component.
 * @property {object | Function} [elementRef] - A ref to the rendered element.
 *
 * @memberof Mojito.Presentation.Components.GridLayout.GridPane
 */
const propTypes = {
    repeatedColumnsNum: PropTypes.number,
    gridTemplateColumns: PropTypes.string,
    class: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    elementRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

export default MojitoCore.Presentation.UIView('GridPane', GridPaneImpl, propTypes);
