/* eslint-disable */
import React from 'react';
import MojitoCore from 'mojito/core';
import MojitoPresentation from 'mojito/presentation';

const UIViewImplementation = MojitoCore.Presentation.UIViewImplementation;
const Text = MojitoPresentation.Components.Text;
const FlexPane = MojitoPresentation.Components.FlexPane;
const DateTimeFormatter = MojitoPresentation.Utils.DateTime.DateTimeFormatter;
const FixedWidthTime = MojitoPresentation.Components.FixedWidthTime;
const isShallowEqual = MojitoCore.Base.objUtils.isShallowEqual;
import {getISODate} from '../../utils.js';

export default class Clock extends UIViewImplementation {
    constructor(props) {
        super(props);
        this.state = {
            currentUTC: getISODate(1000 * 60 * 60 * 5),
        };
    }

    shouldComponentUpdate(nextProps, nextState) {
        return !isShallowEqual(this.props, nextProps) || !isShallowEqual(this.state, nextState);
    }

    componentDidMount() {
        const updateCurrentTime = () => {
            const currentUTC = new Date();
            if (this.state.currentUTC.getSeconds() !== currentUTC.getSeconds()) {
                this.setState({currentUTC});
            }
            this.rAf = requestAnimationFrame(updateCurrentTime);
        };
        /*
        requestAnimationFrame below is commented out to prevent timer from ticking on mockup pages.
        This is done to guarantee scamper screenshots sustainability.
        */
        // this.rAf = requestAnimationFrame(updateCurrentTime);
    }

    componentWillUnmount() {
        this.rAf && cancelAnimationFrame(this.rAf);
    }

    renderOffset() {
        if (this.config.isOffsetHidden) {
            return null;
        }
        const offsetValueFormat = this.props.timeOffset >= 0 ? `+${this.props.timeOffset}` : this.props.timeOffset;
        return (
            <Text config={this.config.offset} class="offset">
                {this.resolveAndFormatString('$CLOCK.OFFSET_FORMAT', offsetValueFormat)}
            </Text>
        );
    }

    render() {
        const userSettings = this.appContext().userSettings();
        const {language} = this.appContext().systemSettings();
        const {currentUTC} = this.state;
        const {timeFormat} = this.config;
        const timeStr = DateTimeFormatter.formatDateTime(currentUTC, timeFormat, userSettings, language);
        return (
            <FlexPane config={this.config.container} class={`ta-Clock ${this.props.class || ''}`}>
                <Text config={this.config.time} class="time">
                    <FixedWidthTime time={timeStr} />
                </Text>
                {this.renderOffset()}
            </FlexPane>
        );
    }
}
