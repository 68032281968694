import {isAnyOf} from '@reduxjs/toolkit';

import MojitoCore from 'mojito/core';
import MojitoServices from 'mojito/services';

import {isEmpty, ensureHierarchy} from '#core/utils/config-utils.js';

import {AbstractFeature, allFeatures} from '#core/application/abstract-feature.js';
import {WITHDRAWABLE, SPORTS_BONUS, SPORTS_TOKEN} from '#core/application/balances/store/utils.js';

import {Bonuses} from '#core/application/modules/bonuses/bonuses.js';

import {DummyBalanceService} from './dummy-balance-service.js';

import SCHEMA from './balance.schema.yaml';

const balanceService = MojitoServices.UserInfo.Balance.service;

const reduxInstance = MojitoCore.Services.redux;
const {dispatch} = MojitoCore.Services.redux.store;

const {actions: UserInfoActions, selectors: UserInfoSelectors, types: UserInfoTypes} = MojitoServices.UserInfo;
const {actions: AuthenticationActions, selectors: AuthenticationSelectors} = MojitoServices.Authentication;
const {actions: BootstrapActions} = MojitoServices.Bootstrap;

class BalanceFeature extends AbstractFeature {
    get configSchema() {
        return SCHEMA;
    }

    beforeMojitoConfigBuild(mojitoConfig) {
        const config = this.config;

        // Push default balance service that doing roundtrip client<->user-service<->integration-service<->ims
        // Only if pollingInterval > 0
        if (config.pollingInterval !== 0) {
            // services.userInfoService.balanceService
            const userInfoService = ensureHierarchy(mojitoConfig, 'services.userInfoService');
            userInfoService.balanceService = balanceService.configure({
                balanceUpdateIntervalSeconds: config.pollingInterval,
            });
        }

        return super.beforeMojitoConfigBuild(mojitoConfig);
    }

    afterMojitoConfigBuild(mojitoConfig) {
        const userInfoService = ensureHierarchy(mojitoConfig, 'services.userInfoService');
        if (!userInfoService.balanceService) {
            // Push something if no balance services are provided (prevent mojito crashing)
            // Note: Balance could be updated via SportsbookAPI
            userInfoService.balanceService = new DummyBalanceService();
        }
        super.afterMojitoConfigBuild(mojitoConfig);
    }
}

new BalanceFeature(allFeatures);

let pendingBalances = {};
/*
Sometimes, Portal provides the balances update (OAPI_RESPONSE) before Sportsbook is logged in.
This is a problem when Sportsbook fully relies on IMS balances update (useIMSFreebetBalance: true), because:
 1) On loginSuccess, Sportsbook takes the balances from userInfo.balances;
 2) userInfo.balances does not contain the freebet balance.
As the result, Sportsbook overrides the balances from OAPI_RESPONSE with userInfo.balances, missing the freebet balance.
To prevent this, DBX stores the balances from OAPI_RESPONSE as pendingBalancesToMojito, and passes it into Sportsbook on loginSuccess.
 */
reduxInstance.actionListener.startListening({
    actionCreator: AuthenticationActions.loginSuccess,
    effect: action => {
        if (!isEmpty(pendingBalances)) {
            dispatch(UserInfoActions.updateBalance({...pendingBalances, ...action.payload?.userInfo?.balances}));
            pendingBalances = {};
        }
    },
});

reduxInstance.actionListener.startListening({
    matcher: isAnyOf(BootstrapActions.dispose, AuthenticationActions.disposeSession),
    effect: () => {
        pendingBalances = {};
    },
});

//------------------------------------------------------------------------------

function updateBalancesInMojFormat(balances) {
    if (AuthenticationSelectors.isLoggedIn()) {
        const userInfo = UserInfoSelectors.selectUserInfo();
        if (userInfo && userInfo.balances) {
            const {balances: currentBalances} = userInfo;
            const newBalances = {...currentBalances, ...balances};
            dispatch(UserInfoActions.updateBalance(newBalances));
        }
    } else {
        // Just postpone the balances until loginSuccess
        pendingBalances = {...pendingBalances, ...balances};
    }
}

// Put balances into UserInfo store (or pending), balances has the same format as SportsbookAPIBalancesStore.getBalances
export function updateBalances(balances) {
    const result = Object.create(null);

    (balances || []).forEach(balance => {
        switch (balance.balanceType) {
            case WITHDRAWABLE:
                result[UserInfoTypes.BALANCE_TYPES.WITHDRAWABLE] = Number(balance.balance.amount) || 0;
                break;
            case SPORTS_BONUS:
                result[UserInfoTypes.BALANCE_TYPES.BONUS] = Number(balance.balance.amount) || 0;
                break;
            case SPORTS_TOKEN:
                result[UserInfoTypes.BALANCE_TYPES.FREE_BET] = Number(balance.balance.amount) || 0;
                break;
        }
    });
    updateBalancesInMojFormat(result);
}

/**
 * Adapt and store user balances that coming from IMS OpenAPI.
 * Both withdrawable and bonus balances must be specified.
 * @param message32010 {object} - message directly from OpenAPI
 */
// To start retrieving balances, client must be subscribed to balance updates
// See doc: https://ims.ptdev.eu/apis/openapi/services/AuthenticationService/operations/StartWindowSession.html
export function handle32010message(message32010) {
    // -- Adopt balances message
    if (message32010.ID !== BALANCE_UPDATE_ID) {
        return; // it is not balance update message
    }

    const {balances} = message32010.data;
    const data = {};

    balances.forEach(balanceItem => {
        const {balanceType: imsBalanceType} = balanceItem;
        const balanceType = BALANCE_TYPES_MAP[imsBalanceType];
        if (!balanceType) return;

        // Ignore freebet balance updates from IMS (OpenAPI) when freebet polling enabled. In such a case, the freebet balance calculated based on polled freebets.
        // This is because the IMS freebet balance updates were unstable in the past. Once it's fixed, Sportsbook could disable the freebet balance polling on it's side, and rely on IMS.
        if (balanceType === UserInfoTypes.BALANCE_TYPES.FREE_BET && !Bonuses.useIMSFreebetBalance) return;

        data[balanceType] = balanceItem.balance.amount;
    });

    if (Object.keys(data).length === 0) {
        return; // Do not override balance if nothing to update
    }
    updateBalancesInMojFormat(data);
}

const BALANCE_TYPES_MAP = {
    /** Withdrawable balance */
    sportsbook_gaming_balance: UserInfoTypes.BALANCE_TYPES.WITHDRAWABLE,
    /** Bonus balance */
    sportsbook_bonus_balance: UserInfoTypes.BALANCE_TYPES.BONUS,
    /** FreeBet balance */
    sportsbook_freebet_balance: UserInfoTypes.BALANCE_TYPES.FREE_BET,
};

const BALANCE_UPDATE_ID = 32010; // NotifyBalanceChangeNotification message ID
