import React, {StrictMode} from 'react';

import {createRoot} from 'react-dom/client';

import MojitoCore from 'mojito/core';

import {loadScript, loadStyle} from '#core/utils/utils.js';
import {absoluteUrlToInternalResource} from '#core/utils/url-utils.js';
import {ALLOWED_ROLES} from '#core/utils/data-validation.cjs';

import {AbstractFeature, allFeatures} from '#core/application/abstract-feature.js';

import {BasicPalette} from '#core/application/modules/basic-palette.js';

import {actions as mozaicActions} from './store/mozaic/slice.js';
import {collectUniqueColors} from './utils/utils';
import {loadDescriptions} from './store/descriptions';

const {dispatch} = MojitoCore.Services.redux.store;

class MozaicEditorFeature extends AbstractFeature {
    get name() {
        return 'Mozaic Editor';
    }

    updateUserRole({isAdmin, userRole = ALLOWED_ROLES.DEVELOPER}) {
        const role = isAdmin ? ALLOWED_ROLES.BRAND_STYLIST : userRole;
        dispatch(mozaicActions.updateUserRole(role));
    }

    updateVisibility(isVisible) {
        dispatch(mozaicActions.updateIsVisible(isVisible));
    }

    isCMS(isCMS) {
        dispatch(mozaicActions.isCMS(!!isCMS));
    }

    show(params = {}) {
        if (!CSS.supports('selector(&)')) {
            const message =
                'CSS nesting is not supported in this browser 🦖🦖🦖. Please use modern browser to run Mozaic';
            this.logger.error(message);
            window.alert(message);
            return;
        }
        const containerId = 'dbx-mozaic-host';
        let host = document.getElementById(containerId);
        this.updateUserRole(params);
        this.isCMS(params.isAdmin);
        if (!host) {
            host = document.createElement('DIV');
            host.id = containerId;
            document.body.appendChild(host);
            const shadow = host; //.attachShadow({mode: 'open', delegatesFocus: true});

            loadStyle(absoluteUrlToInternalResource('mozaic/coloris/coloris.min.css'), shadow);
            loadStyle(absoluteUrlToInternalResource('mozaic/mozaic.css'), shadow);
            if (!window.Coloris) {
                loadScript(absoluteUrlToInternalResource('mozaic/coloris/coloris.min.js')).then(() => {
                    // this.setState({ready: true});
                    window.Coloris({
                        theme: 'pill',
                        themeMode: 'dark',
                        el: '.color-input',
                        wrap: false,
                        formatToggle: true,
                        swatches: collectUniqueColors(BasicPalette.palette),
                    });
                });
            }

            const reactContainer = document.createElement('DIV');
            reactContainer.id = 'dbx-mozaic';
            shadow.appendChild(reactContainer);
            loadDescriptions().then(() => {
                return import(
                    /* webpackChunkName: "dbx.mozaic" */
                    './controller/controller-view.jsx'
                )
                    .then(module => module.MozaicControllerView)
                    .then(MozaicControllerView => {
                        const root = createRoot(reactContainer);
                        const component = React.createElement(
                            StrictMode,
                            {},
                            React.createElement(MozaicControllerView)
                        );
                        root.render(component);
                        this.updateVisibility(true);
                    });
            });

            // retargetEvents(shadow);
        } else {
            this.updateVisibility(true);
        }
    }

    hide() {
        this.updateVisibility(false);
    }
}

export const MozaicEditor = new MozaicEditorFeature(allFeatures);

window.mozaic = params => {
    MozaicEditor.show(params);
};
