/* eslint-disable import/order */
import MojitoCore from 'mojito/core';
import MojitoApplications from 'mojito/applications';
import MojitoModules from 'mojito/modules';
import MojitoServices from 'mojito/services';

import './utils/dinosaur-detector.js';
import {ApplicationConfig} from '#core/config/application-config.js';
import {stripRoutingPrefix} from '#core/utils/url-utils.js';
import {AbstractFeature, allFeatures} from './application/abstract-feature.js';
import {isInternalUrl} from './application/utils.js';
import {API_MESSAGES, EXTERNAL_METHODS, INITIALIZATION_STATE} from './application/types.js';
import {Localization} from '#core/localization.js';
import {Logger} from '#core/utils/logger.js';
import {assignRecursively, mergeTo, setObjectProperty} from '#core/utils/config-utils.js';
import {isValidIntent, openInternalIntent} from '#core/utils/intent-utils.js';
import {applicationModulesPromise} from '#core/application/index.sportsbook.js';
import {init, mount, terminate, unmount} from '#core/application/application.js';
import '#core/application/debug/index.js';
import '#core/features/sentry/index.js';
import {WidgetBetHistory} from '#core/widgets/widget-bet-history.jsx';
import {WidgetBetDetails} from '#core/widgets/widget-bet-details.jsx';
import {WidgetBonusHistory} from '#core/widgets/widget-bonus-history.jsx';
import {WidgetActiveBonuses} from '#core/widgets/widget-active-bonuses.jsx';
import {WidgetInProgressBonuses} from '#core/widgets/widget-in-progress-bonuses.jsx';
import {WidgetSelectionButton} from '#core/widgets/widget-selection-button.jsx';
import {WidgetsProviderService} from '#core/application/widgets-provider/index.js';
import '#core/features/playground/index.js'; // Should have the highest priority and imported after widgets-provider
import '#core/features/ims/index.js';
import '#core/features/analytics/gtm/index.js';
import '#core/features/analytics/gtag/index.js';
import '#core/features/analytics/google-analytics-4/index.js';
import '#core/features/analytics/facebook-pixel/index.js';
import '#core/features/codesnippets/index.js';
import '#core/features/affiliates/index.js';
import '#core/features/deep-linking/index.js';

import {actionsProxyStorage} from '#core/application/actions-proxy/actions-proxy.js';
import {DrivenSSOService} from '#core/application/embedded-app/driven-sso-service.js';
import {handle32010message, updateBalances} from '#core/application/balances/index.js';
import * as BalancesSelectors from '#core/application/balances/store/selectors.js';
import {actions as BalancesActions} from '#core/application/balances/store/slice.js';

import '#core/features/mozaic/index.js';
import {countAndIncreaseAutomatedPropertiesCount} from '#core/utils/statistics.js';
import {Intents} from '#core/application/intents/index.js';
import * as MetaDescriptionSelectors from '#core/application/seo/store/selectors.js';
import {actions as metaDescriptionActions} from '#core/application/seo/store/slice.js';
import {AnalyticsProxy} from './application/analytics-proxy';
import {DBXPerformanceService} from './services/performance-reporting-service';

const log = Logger('DBX');
const apiLog = Logger('Sportsbook API');

const {ADD_FIRST_SELECTION} = MojitoApplications.Sports.ApplicationTypes.SHOW_BETSLIP_CONDITION;
const {BETSLIP_TYPE} = MojitoModules.SlidingBetslip.types;

const {bootstrap: mojitoBootstrap, SubscriptionResolver} = MojitoApplications.Sports;
const IntentActions = MojitoCore.Intents.actions;
const CookieConsentActions = MojitoCore.Services.CookieConsent.actions;
const AuthenticationActions = MojitoServices.Authentication.actions;
const UserSettingsActions = MojitoServices.UserSettings.actions;
const UserSettingsTypes = MojitoServices.UserSettings.types;
const {dispatch} = MojitoCore.Services.redux.store;
const reduxInstance = MojitoCore.Services.redux;
const {selectors: routerSelectors, actions: routerActions} = MojitoApplications.Common.Router;
const {prefetchDataSuccess} = MojitoServices.Prefetch.actions;

let getMojitoConfigFn;

export function configureSportsbook(conf) {
    getMojitoConfigFn = conf.getMojitoConfigFn;

    const bundledConfig = conf.configuration || {};
    if (conf.getTokensFn) {
        const tokens = conf.getTokensFn();

        // ---- Store tokens into config. TODO: everything should be passed via config
        for (const id in tokens.palette || {}) {
            const value = tokens.palette[id];
            setObjectProperty(bundledConfig, id, value);
        }
        for (const id in tokens.tile || {}) {
            const value = tokens.tile[id];
            setObjectProperty(bundledConfig, id, value);
        }

        const mojCustomBundledStyles = {};
        const customs = tokens.custom;
        for (const id in customs) {
            assignRecursively(mojCustomBundledStyles, customs[id]);
            countAndIncreaseAutomatedPropertiesCount(id, 0, customs[id]);
        }
        mergeTo(bundledConfig, {mojitoConfiguration: {services: {configurations: mojCustomBundledStyles}}});
    }

    ApplicationConfig.setBundledConfig(bundledConfig);
    ApplicationConfig.updateInitParams({
        configNameToLoad: 'config', // external config name from where to load configuration file, by default its config.json
        postInitConfig: undefined,
        cookieConsentGiven: false,
        routingPrefix: '/',
        applicationPlaneZIndex: 9,
        overlayPlaneZIndex: 1000,
        containerId: 'app',
        overlayContainerId: undefined,
        currencyCode: undefined,
        uiContext: undefined,
        language: undefined,
        authMethod: 'external',
        widgets: [],
    });

    // Register default widgets
    WidgetsProviderService.registerWidget('bet-history', 'widget-bet-history', WidgetBetHistory);
    WidgetsProviderService.registerWidget('bet-details', 'widget-bet-details', WidgetBetDetails);
    WidgetsProviderService.registerWidget('bonus-history', 'widget-bonus-history', WidgetBonusHistory);
    WidgetsProviderService.registerWidget('active-bonuses', 'widget-active-bonuses', WidgetActiveBonuses);
    WidgetsProviderService.registerWidget('in-progress-bonuses', 'widget-in-progress-bonuses', WidgetInProgressBonuses);
    WidgetsProviderService.registerWidget('selection-button', null, WidgetSelectionButton);
}
class SportsbookSubscriptionResolver extends AbstractFeature {
    beforeMojitoConfigBuild(mojitoConfig) {
        assignRecursively(mojitoConfig, {
            application: {
                stores: {
                    application: {
                        betslipAutoShowUp: {
                            [ADD_FIRST_SELECTION]: BETSLIP_TYPE.QUICK_BETSLIP,
                        },
                    },
                },
            },
        });
        mojitoConfig.application.subscriptionResolver = new SubscriptionResolver();

        super.beforeMojitoConfigBuild(mojitoConfig);
    }
}
new SportsbookSubscriptionResolver(allFeatures);

const {INIT_NONE} = INITIALIZATION_STATE;

const state = {
    initializationState: INIT_NONE,
    initializationPromise: null,
    uid: '',
    overlayId: '',

    containerNode: null,
    stylesNode: null,
    reactAppNode: null,
    overlayContainerNode: null, // we do not own it
    overlayNode: null, // we own it

    isBalancesListenerSet: false,
    isAnalyticsListenerSet: false,
    isSetMetaDescription: false,
};

const externalHandlers = Object.create(null);
let performanceLogHandler = null;

function callExternal(id, data) {
    apiLog.debug(...arguments);
    const fn = externalHandlers[id];
    if (!fn) {
        // do nothing
        apiLog.warn(`External method "${id}" is not set. Doing nothing`);
        return;
    }
    try {
        return fn(data);
    } catch (e) {
        apiLog.warn(`Failed to call external method "${id}": ${e.message}`);
    }
}

function wrapExternal(id) {
    return function () {
        apiLog.debug(id, ...arguments);
        const fn = externalHandlers[id];
        if (!fn) {
            // do nothing
            apiLog.warn(`External method "${id}" is not set. Doing nothing`);
            return;
        }
        try {
            return fn(...arguments);
        } catch (e) {
            apiLog.warn(`Failed to call external method "${id}": ${e.message}`);
        }
    };
}

function notifyBalancesUpdated() {
    callExternal(EXTERNAL_METHODS.BALANCE_UPDATED, BalancesSelectors.selectBalances());
}

function metaDescriptionUpdated(state) {
    callExternal(EXTERNAL_METHODS.PAGE_METATAGS_UPDATED, state.payload);
}

function notifyAnalyticsEvent(data) {
    callExternal(EXTERNAL_METHODS.ANALYTICS_EVENT, data);
}

function notifyInternalRouteEvent(route) {
    if (isInternalUrl(route.payload)) callExternal(EXTERNAL_METHODS.INTERNAL_ROUTE, route.payload);
}

window.Sportsbook = {
    ...(window.Sportsbook || {}),

    init: function (options = {}) {
        apiLog.debug('init', ...arguments);
        Sportsbook.isEmbedded = !options.standalone;

        state.initializationPromise = init({
            log,
            state,
            _initParams: options,
            mojitoBootstrap,
            getMojitoConfigFn,
            applicationModulesPromise,
        });
        return state.initializationPromise;
    },

    terminate: function () {
        apiLog.debug('terminate', ...arguments);
        try {
            if (state.isBalancesListenerSet) {
                reduxInstance.actionListener.stopListening({
                    actionCreator: BalancesActions.updateBalancesForHost,
                    effect: notifyBalancesUpdated,
                });
                state.isBalancesListenerSet = false;
            }

            if (state.isSetMetaDescription) {
                reduxInstance.actionListener.stopListening({
                    actionCreator: metaDescriptionActions.setMetaDescription,
                    effect: metaDescriptionUpdated,
                });
                state.isSetMetaDescription = false;
            }

            actionsProxyStorage.setNavigateHostHandler(null);
            AnalyticsProxy.removeHandler(notifyAnalyticsEvent);
            state.isAnalyticsListenerSet = false;

            return terminate({log, state, mojitoBootstrap});
        } catch (e) {
            return false;
        }
    },

    dispose() {
        this.terminate();
    },

    loadDependencies() {
        // override this method to allow to start from sportsbook.js, intead of bootstrap-embedded.js
        return Promise.resolve();
    },

    sendMessage: function (id, data) {
        apiLog.debug('sendMessage', ...arguments);
        try {
            switch (id) {
                case API_MESSAGES.SYNC_SESSION:
                    if (data) {
                        dispatch(AuthenticationActions.login(data)); // We assume that DriveSSOService is used
                    } else if (data === null) {
                        dispatch(AuthenticationActions.logout());
                    } else {
                        // data is not set (undefined)
                        dispatch(AuthenticationActions.restoreSession());
                    }

                    return Promise.resolve();
                case API_MESSAGES.CHANGE_TIMEZONE:
                    dispatch(UserSettingsActions.updateTimeOffset(data));
                    return Promise.resolve();
                case 'CHANGE_ODDS_FORMAT': {
                    const ODDS_FORMAT = UserSettingsTypes.ODDS_FORMAT;
                    if (
                        data !== ODDS_FORMAT.DECIMAL ||
                        data !== ODDS_FORMAT.FRACTIONAL ||
                        data !== ODDS_FORMAT.AMERICAN
                    ) {
                        log.error(`Invalid odds format: ${data}`);
                        return Promise.reject();
                    }
                    dispatch(UserSettingsActions.updateOddsFormat(data));
                    return Promise.resolve();
                }
                case API_MESSAGES.GET_BALANCE: {
                    const result = BalancesSelectors.selectBalances();
                    return Promise.resolve(result);
                }
                case API_MESSAGES.SET_BALANCE: {
                    updateBalances(data);
                    return Promise.resolve();
                }
                case API_MESSAGES.OAPI_RESPONSE: {
                    switch (data.ID) {
                        case 32010:
                            handle32010message(data);
                            break;
                    }

                    return Promise.resolve();
                }
                case API_MESSAGES.GIVE_COOKIE_CONSENT: {
                    if (data.analytics) {
                        dispatch(CookieConsentActions.giveConsent());
                    }
                    return Promise.resolve();
                }
                case API_MESSAGES.PERFORMANCE_METRIC: {
                    let metrics = Array.isArray(data) ? data : [data];
                    for (let metric of metrics)
                        DBXPerformanceService.reportMetric(metric.name, metric.value, metric.unit);
                    return Promise.resolve();
                }
                case API_MESSAGES.PREFETCH_DATA: {
                    dispatch(prefetchDataSuccess(data));
                    return Promise.resolve();
                }
                default:
                    return Promise.resolve(data);
            }
        } catch (e) {
            log.error(`Failed to invoke method ${id}`, e);
        }
    },

    // Handler can be changed in runtime
    setListener: function (id, fn) {
        apiLog.debug('setListener', ...arguments);
        externalHandlers[id] = fn;
        try {
            switch (id) {
                case EXTERNAL_METHODS.SHOW_LOGIN:
                    actionsProxyStorage.setShowLoginHandler(wrapExternal(id), true);
                    break;
                case EXTERNAL_METHODS.PAGE_METATAGS_UPDATED:
                    if (!state.isSetMetaDescription) {
                        reduxInstance.actionListener.startListening({
                            actionCreator: metaDescriptionActions.setMetaDescription,
                            effect: metaDescriptionUpdated,
                        });
                        state.isSetMetaDescription = true;

                        // immediately send the current meta information
                        const metaDescription = MetaDescriptionSelectors.selectMeta();
                        if (metaDescription) {
                            setTimeout(() => {
                                callExternal(EXTERNAL_METHODS.PAGE_METATAGS_UPDATED, metaDescription);
                            }, 0);
                        }
                    }
                    break;
                case EXTERNAL_METHODS.SHOW_DEPOSIT:
                    actionsProxyStorage.setDepositHandler(wrapExternal(id));
                    break;
                case EXTERNAL_METHODS.NAVIGATE:
                    actionsProxyStorage.setNavigateHostHandler(wrapExternal(id));
                    break;
                case EXTERNAL_METHODS.BALANCE_UPDATED:
                    if (!state.isBalancesListenerSet) {
                        reduxInstance.actionListener.startListening({
                            actionCreator: BalancesActions.updateBalancesForHost,
                            effect: notifyBalancesUpdated,
                        });
                        state.isBalancesListenerSet = true;
                    }
                    break;
                case EXTERNAL_METHODS.ANALYTICS_EVENT:
                    if (!state.isAnalyticsListenerSet) {
                        AnalyticsProxy.addHandler(notifyAnalyticsEvent);
                        state.isAnalyticsListenerSet = true;
                    }
                    break;
                case EXTERNAL_METHODS.GET_SESSION_INFO:
                    DrivenSSOService.setCredentialsRetriever(wrapExternal(id));
                    break;
                case EXTERNAL_METHODS.LOG_OUT:
                    actionsProxyStorage.setLogoutHandler(wrapExternal(id));
                    break;
                case EXTERNAL_METHODS.PERFORMANCE_LOG:
                    if (fn) {
                        DBXPerformanceService.removeLogger(performanceLogHandler);
                        performanceLogHandler = fn;
                        DBXPerformanceService.addLogger(performanceLogHandler);
                    } else {
                        DBXPerformanceService.removeLogger(performanceLogHandler);
                        performanceLogHandler = null;
                    }
                    break;
                case EXTERNAL_METHODS.APPLICATION_LOADED:
                    break;
                case EXTERNAL_METHODS.SPORTSBOOK_LOADED:
                    externalHandlers[EXTERNAL_METHODS.APPLICATION_LOADED] = fn;
                    break;
                case EXTERNAL_METHODS.INTERNAL_ROUTE:
                    reduxInstance.actionListener.startListening({
                        actionCreator: routerActions.routeChange,
                        effect: notifyInternalRouteEvent,
                    });
                    break;
                default:
                    log.warn(`Sportsbook.setListener: Unknown event id: ${id}`);
                    return false;
            }
            return true;
        } catch (e) {
            log.error(e);
            return false;
        }
    },

    removeListener: function (id) {
        apiLog.debug('removeListener', ...arguments);
        externalHandlers[id] = null;
        try {
            switch (id) {
                case EXTERNAL_METHODS.SHOW_LOGIN:
                    actionsProxyStorage.removeShowLoginHandler();
                    break;
                case EXTERNAL_METHODS.PAGE_METATAGS_UPDATED:
                    if (state.isSetMetaDescription) {
                        reduxInstance.actionListener.stopListening({
                            actionCreator: metaDescriptionActions.setMetaDescription,
                            effect: metaDescriptionUpdated,
                        });
                        state.isSetMetaDescription = false;
                    }
                    break;
                case EXTERNAL_METHODS.SHOW_DEPOSIT:
                    actionsProxyStorage.removeDepositHandler();
                    break;
                case EXTERNAL_METHODS.NAVIGATE:
                    actionsProxyStorage.removeNavigateHostHandler();
                    break;
                case EXTERNAL_METHODS.BALANCE_UPDATED:
                    if (state.isBalancesListenerSet) {
                        reduxInstance.actionListener.stopListening({
                            actionCreator: BalancesActions.updateBalancesForHost,
                            effect: notifyBalancesUpdated,
                        });
                        state.isBalancesListenerSet = false;
                    }
                    break;
                case EXTERNAL_METHODS.ANALYTICS_EVENT:
                    AnalyticsProxy.removeHandler(notifyAnalyticsEvent);
                    state.isAnalyticsListenerSet = false;
                    break;
                case EXTERNAL_METHODS.GET_SESSION_INFO:
                    DrivenSSOService.removeCredentialsRetriever();
                    break;
                case EXTERNAL_METHODS.LOG_OUT:
                    actionsProxyStorage.removeLogoutHandler();
                    break;
                case EXTERNAL_METHODS.INTERNAL_ROUTE:
                    reduxInstance.actionListener.stopListening({
                        actionCreator: routerActions.routeChange,
                        effect: notifyInternalRouteEvent,
                    });
                    break;
                default:
                    log.warn(`Sportsbook.removeListener: Unknown event id: ${id}`);
                    return false;
            }
            return true;
        } catch (e) {
            log.error(e);
            return false;
        }
    },

    mountSportsbook(containerId, params = {}) {
        apiLog.debug('mountSportsbook', ...arguments);
        const result = mount(containerId, params, {
            log,
            state,
            mojitoBootstrap,
            onLoaded: () => {
                externalHandlers[EXTERNAL_METHODS.APPLICATION_LOADED] &&
                    externalHandlers[EXTERNAL_METHODS.APPLICATION_LOADED]();
            },
        });
        if (result && Intents.SPORTSBOOK_MOUNTED) {
            dispatch(IntentActions.publishIntent(Intents.SPORTSBOOK_MOUNTED.type, Intents.SPORTSBOOK_MOUNTED.data));
        }
        return result;
    },

    unmountSportsbook() {
        apiLog.debug('unmountSportsbook', ...arguments);
        return unmount({log, state, mojitoBootstrap});
    },

    getWidgetByCurrentRoute: function () {
        return WidgetsProviderService.getWidgetByCurrentRoute();
    },

    mountWidget: function (widgetId, regionId, params) {
        apiLog.debug('mountWidget', ...arguments);

        if (widgetId === 'sportsbook') return this.mountSportsbook(regionId, params);

        return WidgetsProviderService.mountWidget(widgetId, regionId, params);
    },

    unmountWidget: function (regionId) {
        apiLog.debug('unmountWidget', ...arguments);

        if (regionId === 'sportsbook') return this.unmountSportsbook();

        return WidgetsProviderService.unmountWidget(regionId);
    },

    navigate: function (method, ...params) {
        apiLog.debug('navigate', ...arguments);

        let intent;
        if (!state.containerNode) {
            log.warn('Cannot navigate. Sportsbook is not mounted');
            return false;
        }

        if (!method) {
            // Sync router store with current history location path.
            // This is needed for a cases when navigation is done outside sportsbook.
            const pathChanged = window.location.pathname !== routerSelectors.selectRoute();
            if (pathChanged) {
                dispatch(routerActions.routeChange(window.location.pathname));
            }
            return true;
        }

        if (method === stripRoutingPrefix(ApplicationConfig.initParams.routingPrefix, window.location.pathname)) {
            method = method.substring(1);
            intent = openInternalIntent('toCustom', [method]);
            intent.data.isRedirect = true;
        } else if (method === '/') {
            intent = openInternalIntent('toHome');
        } else if (method[0] === '/') {
            method = method.substring(1); // delete leading slash (moj specific)
            intent = openInternalIntent('toCustom', [method]);
        } else {
            intent = openInternalIntent(method, params);
        }

        if (isValidIntent(intent)) {
            dispatch(IntentActions.publishIntent(intent.type, intent.data));
            return true;
        }

        return false;
    },

    get initializationState() {
        return state.initializationState;
    },

    get initializationPromise() {
        return state.initializationPromise;
    },

    get isMounted() {
        return !!state.containerNode;
    },

    get language() {
        return ApplicationConfig.initParams.language;
    },

    get usedLanguage() {
        return Localization.currentLanguage;
    },

    get availableLanguages() {
        return Localization.availableLanguages;
    },

    get _state() {
        return Object.assign({}, state);
    },

    get _config() {
        return Object.assign({}, ApplicationConfig.initParams);
    },

    get _routingPrefix() {
        return ApplicationConfig.initParams.routingPrefix;
    },
};

window.DBX_APPLICATION = window.Sportsbook;
