import {isProbablyBGImage, luminance} from '#core/utils/color-utils.js';
import {DATA_TYPE} from '#core/utils/data-validation.cjs';

export function collectUniqueColors(basicPalette) {
    const set = new Set();
    function addBg(bg) {
        !isProbablyBGImage(bg) && set.add(bg);
    }
    function addColor(colors) {
        colors.split('|').forEach(color => set.add(color));
    }

    addBg(basicPalette.DEFAULT_PAGE_BG);
    addBg(basicPalette.DEFAULT_CONTAINER_BG);
    addBg(basicPalette.DEFAULT_HEADER_BG);
    addBg(basicPalette.DEFAULT_POPOVER_BG);
    addBg(basicPalette.DEFAULT_SELECTION_BG);
    addBg(basicPalette.DEFAULT_PROMO_CARD_BG);
    addBg(basicPalette.DEFAULT_SHEET_BG);
    addBg(basicPalette.DEFAULT_ODDS_BG);
    addBg(basicPalette.DEFAULT_ODDS_SELECTED_BG);

    addColor(basicPalette.DEFAULT_PRIMARY_TEXT);
    addColor(basicPalette.DEFAULT_SECONDARY_TEXT);
    addColor(basicPalette.DEFAULT_GENERIC_ACCENT_COLOR);
    addColor(basicPalette.DEFAULT_PRIMARY_BUTTON_COLOR);
    addColor(basicPalette.DEFAULT_LIVE_COLOR);
    addColor(basicPalette.DEFAULT_DIVIDER);
    addColor(basicPalette.DEFAULT_CONTROLS_BORDER_COLOR);
    const colors = Array.from(set);
    // Sort colors by luminance
    colors.sort((a, b) => {
        return luminance(a, 0) - luminance(b, 0);
    });
    return colors;
}

export function flattenConfig(cfg, obj = {}, keyToSchema, pathPrefix = '') {
    for (const key in cfg) {
        if (cfg.hasOwnProperty && !cfg.hasOwnProperty(key)) continue;
        const fullPath = pathPrefix ? pathPrefix + '.' + key : key;
        const value = cfg[key];
        if (
            typeof value === 'object' &&
            value !== null &&
            !Array.isArray(value) &&
            !(keyToSchema[fullPath]?.type === DATA_TYPE.TEXT_STYLE)
        ) {
            flattenConfig(value, obj, keyToSchema, fullPath);
        } else {
            obj[fullPath] = value;
        }
    }
    return obj;
}
