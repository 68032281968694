import { createSlice } from '@reduxjs/toolkit';
import MojitoCore from 'mojito/core';
import ServicesTypes from 'services/common/types.js';
import bootstrapController from './controller.js';
import { noop } from 'mojito/utils';
import { selectChannel } from './selectors';

const { selectLanguage } = MojitoCore.Services.SystemSettings.selectors;
const log = MojitoCore.logger.get('Bootstrap');

const reduxInstance = MojitoCore.Services.redux;
const { INIT_STATUS } = ServicesTypes;

/**
 * The name of the bootstrap state property. Will be used to register in global redux store.
 * This state holds information about services initialisation status.
 *
 * @constant
 * @type {string}
 * @memberof Mojito.Services.Bootstrap
 */
export const STORE_KEY = 'bootstrapStore';

export const INITIAL_STATE = {
    initStatus: INIT_STATUS.NOT_INITIALISED,
};

export const { reducer, actions } = createSlice({
    name: 'bootstrap',
    initialState: INITIAL_STATE,
    reducers: {
        configure: noop,
        initPending: noop,
        initSuccess(state) {
            state.initStatus = INIT_STATUS.INITIALISED;
        },
        initFailed(state) {
            state.initStatus = INIT_STATUS.FAILED;
        },
        dispose(state) {
            state.initStatus = INIT_STATUS.NOT_INITIALISED;
        },
    },
});

/**
 * Services layer bootstrap actions.
 *
 * @module BootstrapActions
 * @name actions
 * @memberof Mojito.Services.Bootstrap
 */

/**
 * Init API pending action.
 *
 * @function initPending
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @memberof Mojito.Services.Bootstrap.actions
 */

/**
 * Init API success action.
 *
 * @function initSuccess
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @memberof Mojito.Services.Bootstrap.actions
 */

/**
 * Init API failed action.
 *
 * @function initFailed
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @memberof Mojito.Services.Bootstrap.actions
 */

/**
 * Dispose API action.
 *
 * @function dispose
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @memberof Mojito.Services.Bootstrap.actions
 */

/**
 * Configure bootstrap layer.
 * This thunk doesn't dispatch anything and operates as a facade for {@link Mojito.Services.Bootstrap.bootstrapController#configure|bootstrap controller configure} method.
 *
 * @function configure
 *
 * @param {{service: Mojito.Services.Bootstrap.AbstractBootstrapService, serviceUrl: string}} config - Bootstrap config. Contains bootstrap service instance and service URL.
 *
 * @returns {Mojito.Core.Services.redux.ThunkFunction} Configure thunk.
 * @memberof Mojito.Services.Bootstrap.actions
 */
actions.configure = config => () => {
    bootstrapController.configure(config);
};

/**
 * Initializes the Mojito transactional API with the given configuration.
 * This function delegates the call to {@link Mojito.Services.Bootstrap.bootstrapController#initApi|initApi}.
 * Depending on the result of the initApi call, it will dispatch either {@link Mojito.Services.Bootstrap.actions.initSuccess|initSuccess} or {@link Mojito.Services.Bootstrap.actions.initFailed|initFailed}.
 * The {@link Mojito.Services.Bootstrap.actions.initPending|initPending} action is triggered while the call is in progress.
 *
 * @function init
 *
 * @returns {Mojito.Core.Services.redux.ThunkFunction} This init thunk dispatches the restoreSession action when the initialization is complete.
 * @memberof Mojito.Services.Bootstrap.actions
 */
actions.init = () => (dispatch, getState) => {
    const state = getState();
    const locale = selectLanguage(state);
    const channel = selectChannel(state);
    dispatch(actions.initPending());
    bootstrapController
        .initApi({ locale, channel })
        .then(() => dispatch(actions.initSuccess()))
        .catch(e => {
            dispatch(actions.initFailed());
            log.error(
                `Failed to initialise Mojito transactional API. This can lead to errors on API requests. ${e.stack}`
            );
        });
};

reduxInstance.injectReducer(STORE_KEY, reducer);
