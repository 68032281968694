import MojitoCore from 'mojito/core';
import BetradarWidgetLoader from './components/betradar/betradar-widget-loader.js';

class Presentation {
    /**
     * Initialize the presentation layer given the application configuration.
     *
     * @function Mojito.Presentation#init
     */
    init() {
        MojitoCore.Presentation.uiStyle.init();
    }

    /**
     * Disposes the presentation layer.
     *
     * @function Mojito.Presentation#dispose
     */
    dispose() {
        BetradarWidgetLoader.destruct();
    }
}

export default new Presentation();
