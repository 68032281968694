import React, { useEffect, useMemo, useState } from 'react';
import { useParams, useOutletContext } from 'react-router';
import { useSelector } from 'react-redux';

import MojitoCore from 'mojito/core';
import MojitoServices from 'mojito/services';
import MojitoModules from 'mojito/modules';
import MojitoPresentation from 'mojito/presentation';

import { convertToMenuLink } from 'applications/sports/pages/sport-page/views/utils.js';
import { useListItems, useEventGroups } from 'modules/common/hooks';

const { module: SportGroupsList, types: SportGroupsListTypes } = MojitoModules.SportGroupsList;
const eventGroupsSelectors = MojitoServices.EventGroups.selectors;
const EventCardsCarousel = MojitoModules.EventCardsCarousel.module;
const { MenuLinkPromotions, PrebuiltPromotionCards } = MojitoModules.Promotions;
const { FlexPane } = MojitoPresentation.Components;
const PromotionTypes = MojitoServices.Promotions.types;
const { COUPONS } = MojitoModules.SingleLeagueCoupon.SingleLeagueCouponTypes;
const ContentPreloader = MojitoCore.Services.Content.ContentPreloader;
const ContentPreloaderContext = MojitoCore.Presentation.ContentPreloaderContext;

export default function CompetitionsView({ mojitoTools }) {
    const { config, appContext, emitPerformanceMark } = mojitoTools;
    const { analyticsEmitter, subscriptionResolver } = appContext;
    const { emitAnalytics } = analyticsEmitter;
    const { sportId } = useParams();
    const contentInfo = useOutletContext();
    const contentPreloader = useMemo(() => new ContentPreloader(), []);

    const groupIds = useListItems(subscriptionResolver.cmsCouponPrefix(sportId));
    const eventGroups = useEventGroups(groupIds);
    const eventGroupNames = useSelector(state => eventGroupsSelectors.selectEventGroupNames(state));
    const cmsCouponLinks = Object.keys(eventGroups)
        .map(eventGroupId => ({ id: eventGroupId, name: eventGroupNames[eventGroupId] }))
        .filter(group => !!group.name)
        .map(coupon => convertToMenuLink(coupon, sportId, appContext.routeResolver));

    const onLinkClick = item => {
        const { isCMSCoupon, data } = item;
        const type = isCMSCoupon ? 'cmsCouponsClicked' : 'cmsQuickLinksClicked';
        emitAnalytics(type, data);
    };

    const [allLeaguesLoaded, setAllLeaguesLoaded] = useState(false);
    const [promotionsLoaded, setPromotionsLoaded] = useState(false);

    useEffect(() => {
        emitPerformanceMark('moduleRendered', allLeaguesLoaded && promotionsLoaded);
    }, [emitPerformanceMark, allLeaguesLoaded, promotionsLoaded]);

    return (
        <ContentPreloaderContext.Provider value={contentPreloader}>
            <FlexPane config={config.container} class="ta-CompetitionsView">
                <EventCardsCarousel
                    sportId={sportId}
                    pageId={sportId}
                    config={config.eventCardsCarousel}
                />
                <MenuLinkPromotions
                    config={config.menuLinkPromotions}
                    extraLinks={cmsCouponLinks}
                    applicablePage={sportId}
                    locationId={PromotionTypes.LOCATION.QUICK_LINKS}
                    onLinkClick={onLinkClick}
                    onDataLoad={() => {
                        setPromotionsLoaded(true);
                    }}
                />
                <SportGroupsList
                    config={config.topLeagues}
                    sportId={sportId}
                    type={SportGroupsListTypes.TYPE.TOP}
                    groupId={subscriptionResolver.topLeagues(sportId)}
                    pageType={COUPONS.COMPETITIONS}
                />
                <PrebuiltPromotionCards
                    applicablePage={sportId}
                    config={config.prebuiltPromotionCards}
                />
                <SportGroupsList
                    config={config.allLeagues}
                    sportId={sportId}
                    type={SportGroupsListTypes.TYPE.ALL}
                    groupId={contentInfo?.competitions?.group}
                    pageType={COUPONS.COMPETITIONS}
                    onDataLoad={() => {
                        setAllLeaguesLoaded(true);
                    }}
                />
            </FlexPane>
        </ContentPreloaderContext.Provider>
    );
}
