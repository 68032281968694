import {resolveBackground} from '#core/utils/color-utils.js';
import {isMobile} from '#core/utils/context-utils.js';
import {merge} from '#core/utils/config-utils.js';
import {icon} from '#core/utils/style-utils.js';

import {generateInput} from '#core/application/modules/tiles/input/index.js';

export function activeBonusesFactory(PALETTE, BONUS_ITEM) {
    const {FONT} = PALETTE;

    return {
        ActiveBonuses: {
            headerTextWrapper: {
                style: {
                    minHeight: 'initial',
                    padding: '8px 8px 0',
                },
            },
            headerText: {
                style: {
                    fontSize: FONT.SIZE_MEDIUM,
                    textTransform: 'uppercase',
                    fontWeight: FONT.WEIGHT_NORMAL,
                    ...PALETTE.BONUSES_SECTION_TITLE_TEXT,
                },
            },
            progressBar: BONUS_ITEM.progressBar,
            noBonusesCard: {
                style: {
                    ...BONUS_ITEM.container,
                    padding: '16px 8px',
                },
            },
            bonusItemsNotAvailable: {
                container: {
                    padding: 0,
                },
                style: {
                    fontSize: FONT.SIZE_MEDIUM,
                    ...PALETTE.BONUSES_NOT_FOUND_TEXT,
                },
            },
        },
        ...promotionCodeFactory(PALETTE),
    };
}

function promotionCodeFactory(PALETTE) {
    const {FONT} = PALETTE;

    return {
        FreeBetCode: {
            container: {
                style: {
                    ...resolveBackground(PALETTE.PROMOTION_CODE_CONTAINER_BG, 'mobile'),
                    flexGrow: 0,
                    _mobile_padding: '0 8px',
                    ...(isMobile()
                        ? {
                              borderStyle: 'solid',
                              borderWidth: '0 0 1px 0',
                              borderColor: PALETTE.PROMOTION_CODE_SEPARATOR_COLOR,
                          }
                        : undefined),
                },
            },
            contentContainer: {
                style: {
                    flexBasis: 32,
                },
            },
            label: {
                style: {
                    fontSize: FONT.SIZE_SMALL,
                    textTransform: 'capitalize',
                    ...PALETTE.PROMOTION_CODE_INPUT.INPUT_LABEL_TEXT,
                },
            },
            input: merge(generateInput(PALETTE.PROMOTION_CODE_INPUT), {
                style: {
                    base: {
                        padding: 8,
                        flexBasis: 240,
                        _desktop_height: 32,
                        minHeight: 'initial',
                        _mobile_fontSize: FONT.SIZE_MEDIUM,
                        _desktop_flexGrow: 1,
                        flexGrow: 1,
                    },
                },
            }),
            button: {
                style: {
                    base: {
                        ...resolveBackground(PALETTE.PROMOTION_CODE_SUBMIT_BUTTON.PRIMARY_BUTTON_BG),
                        borderRadius: PALETTE.PROMOTION_CODE_SUBMIT_BUTTON.PRIMARY_BUTTON_BORDER_RADIUS,
                        alignItems: 'center',
                        _desktop_flexBasis: 32,
                        _desktop_height: 32,
                        _desktop_padding: 8,
                        _desktop_minWidth: 32,
                    },
                },
            },
            submitIcon: merge(icon(PALETTE.PROMOTION_CODE_SUBMIT_BUTTON.PRIMARY_BUTTON_ICON_COLOR), {
                style: {
                    minWidth: 16,
                    minHeight: 16,
                    height: 16,
                },
            }),
            spinner: {
                style: {
                    container: {
                        height: '16px',
                    },
                },
                image: icon(PALETTE.PROMOTION_CODE_SUBMIT_BUTTON.PRIMARY_BUTTON_ICON_COLOR),
            },
            successContainer: {
                style: {
                    ...resolveBackground(PALETTE.PROMOTION_CODE_SUCCESS_NOTIFICATION.SUCCESS_BG),
                    borderRadius: PALETTE.PROMOTION_CODE_SUCCESS_NOTIFICATION.SUCCESS_BORDER_RADIUS,
                    borderColor: PALETTE.PROMOTION_CODE_SUCCESS_NOTIFICATION.SUCCESS_BORDER_COLOR,
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    ...PALETTE.PROMOTION_CODE_SUCCESS_NOTIFICATION.SUCCESS_TEXT,
                },
            },
            successMessageIcon: {
                src: 'leg-status-won-icon',
                style: {
                    fill: PALETTE.PROMOTION_CODE_SUCCESS_NOTIFICATION.SUCCESS_ICON_COLOR,
                    minWidth: '16px',
                    minHeight: '16px',
                },
            },
            error: {
                container: {
                    _desktop_flexBasis: 240,
                    _desktop_padding: '0 40px 0 0',
                    _mobile_padding: '4px 0',
                },
                style: {
                    fontSize: FONT.SIZE_SMALL,
                    ...PALETTE.PROMOTION_CODE_ERROR_TEXT,
                },
            },
        },
    };
}
