export class FakeContentTransporter {
    constructor(config) {
        this.config = config;
    }

    init() {}

    registerCollection() {}

    subscribe() {
        return {
            promise: new Promise(resolve => {
                resolve({});
            }),
            dispose: () => {},
        };
    }

    subscribeMultiple() {
        return {
            promise: new Promise(resolve => {
                resolve({});
            }),
            dispose: () => {},
        };
    }

    search() {}

    dispose() {}
}
