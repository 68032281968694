import MojitoCore from 'mojito/core';
import { omit, pick } from 'mojito/utils';
import { ImageContext } from 'presentation/components/image/context/index.jsx';

const StringUtils = MojitoCore.Base.StringUtils;
const log = MojitoCore.logger.get('Image');
const { merge, primitives } = MojitoCore.Base.objUtils;

const supportedImageFormats = Object.freeze([
    '.jpg',
    '.jpeg',
    '.png',
    '.svg',
    '.gif',
    '.avif',
    '.webp',
]);

export default class Image extends MojitoCore.Presentation.UIViewImplementation {
    constructor(...args) {
        super(...args);

        this.state = {
            hasImageError: false,
        };

        this.onImageError = () => {
            this.setState({ hasImageError: true });
            this.props.onError && this.props.onError();
        };
    }

    renderImgElement(src, context) {
        const source = this.appContext().getImage(src);
        const imgProps = {
            src: source,
            style: this.style.imgTag,
            title: this.getTitle(),
            onError: this.onImageError,
            onLoad: this.props.onLoad,
            decoding: 'async',
            className: 'ta-Image-img_tag',
            alt: this.props.alt,
        };
        context && context.propsModifier(imgProps);

        return <img {...imgProps} />;
    }

    renderAsSvgSpriteImage(src) {
        const xlinkHref = `${this.config.svgSpritemap.path || ''}#${
            this.config.svgSpritemap.idPrefix || ''
        }${src || ''}`;

        const svgTagAttributes = merge(
            this.style.svgTagAttributes,
            pick(this.props, ['fill', 'stroke'])
        );
        return (
            <div style={this.style.svgTagContainer}>
                <svg
                    role="img"
                    {...svgTagAttributes}
                    style={this.style.svgTag}
                    aria-label={this.props.alt}
                    className="ta-Image-svg_tag"
                >
                    {this.props.alt && <title>{this.props.alt}</title>}
                    <use xlinkHref={xlinkHref} />
                </svg>
            </div>
        );
    }

    renderImage(context) {
        const src = this.getSrc();

        if (!src) {
            log.warn('Image component is missing a source value');
            return null;
        }

        const lowerCaseSrc = src.toLowerCase();
        const isPath = supportedImageFormats.some(imageFormat =>
            lowerCaseSrc.endsWith(imageFormat)
        );

        if (!isPath) {
            return this.renderAsSvgSpriteImage(this.getSrc());
        }

        return this.renderImgElement(src, context);
    }

    getSrc() {
        const primarySrc = this.getPrimarySrc();
        return ((!primarySrc || this.state.hasImageError) && this.getFallbackSrc()) || primarySrc;
    }

    getPrimarySrc() {
        return this.props.src || this.config.src;
    }

    getFallbackSrc() {
        return this.props.fallbackSrc || this.config.fallbackSrc;
    }

    getTitle() {
        const title = this.props.title || this.config.title;
        return StringUtils.hasStringKeyFormat(title) ? this.resolveString(title) : title;
    }

    render() {
        return (
            <div style={this.style.container} className={`ta-Image ${this.props.class || ''}`}>
                <ImageContext.Consumer>
                    {context => this.renderImage(context)}
                </ImageContext.Consumer>
            </div>
        );
    }
}

Image.getStyle = config => {
    const { style } = config;

    return {
        container: {
            display: 'flex',
            ...omit(
                primitives(style),
                'padding',
                'stroke',
                'fill',
                'objectFit',
                'objectPosition',
                'visibility',
                'opacity',
                'width',
                'height',
                'aspectRatio'
            ),
        },
        imgTag: {
            boxSizing: 'border-box',
            ...pick(
                style,
                'visibility',
                'objectFit',
                'objectPosition',
                'opacity',
                'width',
                'height',
                'aspectRatio',
                'padding',
                'maxWidth'
            ),
            ...style.transition,
        },
        svgTagContainer: {
            position: 'relative',
            flex: '1 1 auto',
            margin: style.padding,
            ...pick(style, 'width', 'height', 'opacity'),
        },
        svgTag: {
            // Using absolute positioning to make the <svg> element not get a 300x150 px default size
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            visibility: style.visibility,
            ...style.transition,
        },
        svgTagAttributes: pick(style, 'stroke', 'fill'),
    };
};
