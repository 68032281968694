import MojitoCore from 'mojito/core';
import { isEmpty } from 'mojito/utils';
import BetsTypes from 'services/bets/types.js';

const log = MojitoCore.logger.get('Bets service layer');
const { BONUS_TYPE, LEG_SORT, BET_STATUS, BET_WAY, UK_BET_TYPE, FILTER_CRITERIA_TYPE, BET_TYPE } =
    BetsTypes;
const { ACCA_BOOST, ACCA_INSURANCE, EVENT_TRIGGERED } = BONUS_TYPE;
const { MATCH_ACCA, BET_BUILDER } = LEG_SORT;
const { EACH_WAY } = BET_WAY;
const { OPEN } = BET_STATUS;

const { DateTimeUtils } = MojitoCore.Base;

/**
 * Utility functions associated with bets.
 *
 * @class BetsUtils
 * @name utils
 * @memberof Mojito.Services.Bets
 */
export default class BetsUtils {
    /**
     * Check if bet has acca boost bonus.
     *
     * @param {Mojito.Services.Bets.types.Bet} bet - Bet.
     *
     * @returns {boolean} True if bet has acca boost, else false.
     * @function Mojito.Services.Bets.utils.hasAccaBoost
     */
    static hasAccaBoost(bet) {
        return !!BetsUtils.getAccaBoost(bet);
    }

    /**
     * Check if bet has acca insurance bonus.
     *
     * @param {Mojito.Services.Bets.types.Bet} bet - Bet.
     *
     * @returns {boolean} True if bet has acca insurance, else false.
     * @function Mojito.Services.Bets.utils.hasAccaInsurance
     */
    static hasAccaInsurance(bet) {
        return BetsUtils.getBonus(bet, ACCA_INSURANCE).length > 0;
    }

    /**
     * Get odds boost.
     *
     * @param {Mojito.Services.Bets.types.Bet} bet - Bet.
     *
     * @returns {object|undefined} Odds boost if found, else undefined.
     * @function Mojito.Services.Bets.utils.getOddsBoost
     */
    static getOddsBoost(bet) {
        return !isEmpty(bet.oddsBoost) ? bet.oddsBoost : undefined;
    }

    /**
     * Check if bet has oddsBoost functionality enabled or not.
     *
     * @param {object} bet - Bet item.
     *
     * @returns {boolean} True if bet has odds boost, else false.
     * @function Mojito.Services.Bets.utils.hasOddsBoost
     */
    static hasOddsBoost(bet) {
        return !!BetsUtils.getOddsBoost(bet);
    }

    /**
     * Get returns of odds boost in bet.
     *
     * @param {Mojito.Services.Bets.types.Bet} bet - Bet.
     *
     * @returns {number|undefined} Number of returns if found, else undefined.
     * @function Mojito.Services.Bets.utils.getReturnsOfOddsBoost
     */
    static getReturnsOfOddsBoost(bet) {
        const value = Number(BetsUtils.getOddsBoost(bet)?.returns);
        return isNaN(value) ? undefined : value;
    }

    /**
     * Check if bet is a match acca. It is a match acca if any leg has leg sort match acca.
     *
     * @param {Mojito.Services.Bets.types.Bet} bet - Bet.
     * @returns {boolean} True if bet is match acca, else false.
     * @function Mojito.Services.Bets.utils.hasMatchAccaLeg
     */
    static hasMatchAccaLeg(bet) {
        const { legs = [] } = bet;
        return legs.some(leg => leg.legSort === MATCH_ACCA || leg.legSort === BET_BUILDER);
    }

    /**
     * Get acca boost bonus.
     *
     * @param {Mojito.Services.Bets.types.Bet} bet - Bet.
     *
     * @returns {Mojito.Services.Bets.types.Bonus|undefined} Bonus if found, else undefined.
     * @function Mojito.Services.Bets.utils.getAccaBoost
     */
    static getAccaBoost(bet) {
        return BetsUtils.getBonus(bet, ACCA_BOOST)[0];
    }

    /**
     * Check if bet is each way. It is a each way if any leg has bet type each way.
     *
     * @param {Mojito.Services.Bets.types.Bet} bet - Bet.
     *
     * @returns {boolean} True if bet is each way, else false.
     * @function Mojito.Services.Bets.utils.isEachWay
     */
    static isEachWay(bet) {
        const { legs = [] } = bet;
        return legs.some(leg => leg.betWay === EACH_WAY);
    }

    /**
     * Check if bet is banker. It is a banker bet if any leg has property `isBanker` true.
     *
     * @param {Mojito.Services.Bets.types.Bet} bet - Bet.
     *
     * @returns {boolean} True if bet is banker, else false.
     * @function Mojito.Services.Bets.utils.isBanker
     */
    static isBanker(bet) {
        const { legs = [] } = bet;
        return legs.some(leg => leg.isBanker);
    }

    /**
     * Bet number of bankers in bet.
     *
     * @param {Mojito.Services.Bets.types.Bet} bet - Bet.
     *
     * @returns {number} Number of bankers.
     * @function Mojito.Services.Bets.utils.getNumberOfBankers
     */
    static getNumberOfBankers(bet) {
        const { legs = [] } = bet;
        return legs.reduce((acc, { isBanker }) => acc + (isBanker ? 1 : 0), 0);
    }

    /**
     * Get event triggered bonuses.
     *
     * @param {Mojito.Services.Bets.types.Bet} bet - Bet.
     *
     * @returns {Array<Mojito.Services.Bets.types.Bonus>} Event triggered bonuses.
     * @function Mojito.Services.Bets.utils.getEventTriggeredBonuses
     */
    static getEventTriggeredBonuses(bet) {
        return BetsUtils.getBonus(bet, EVENT_TRIGGERED);
    }

    /**
     * Check if bet status is open.
     *
     * @param {Mojito.Services.Bets.types.Bet} bet - Bet.
     *
     * @returns {boolean} True if bet has status open, else false.
     * @function Mojito.Services.Bets.utils.isOpen
     */
    static isOpen(bet) {
        return bet.status === OPEN;
    }

    /**
     * Get first part from leg.
     *
     * @param {Mojito.Services.Bets.types.Leg} leg - Leg.
     *
     * @returns {Mojito.Services.Bets.types.Part|undefined} Bet leg part if exists, else undefined.
     * @function Mojito.Services.Bets.utils.getFirstLegPart
     */
    static getFirstLegPart(leg) {
        return leg.parts[0];
    }

    /**
     * Get bonuses of type bonusType from bet.
     *
     * @param {Mojito.Services.Bets.types.Bet} bet - Bet.
     * @param {Mojito.Services.Bets.types.BONUS_TYPE} bonusType - Bonus type.
     *
     * @returns {Array<Mojito.Services.Bets.types.Bonus>} Array of bonuses, empty if no bonuses found.
     * @function Mojito.Services.Bets.utils.getBonus
     */
    static getBonus(bet, bonusType) {
        const { bonuses = [] } = bet;
        return bonuses.filter(bonus => bonus.type === bonusType);
    }

    /**
     * Check if bet/leg object is of type multicast.
     *
     * @param {Mojito.Services.Betslip.types.Bet|Mojito.Services.Bets.types.Leg} objectWithLegSort - Object containing legSort property, typically a betslip bet or a leg.
     *
     * @returns {boolean} True if object is of type multicast, else false.
     * @function Mojito.Services.Bets.utils.isMulticast
     */
    static isMulticast(objectWithLegSort) {
        const { FORECAST, TRICAST, COMBINATION_FORECAST, COMBINATION_TRICAST, REVERSE_FORECAST } =
            LEG_SORT;

        const multicastTypes = [
            FORECAST,
            TRICAST,
            COMBINATION_FORECAST,
            COMBINATION_TRICAST,
            REVERSE_FORECAST,
        ];

        return multicastTypes.includes(objectWithLegSort.legSort);
    }

    /**
     * Get all parts from the bet.
     *
     * @param {Mojito.Services.Bets.types.Bet} bet - Bet.
     *
     * @returns {Array<Mojito.Services.Bets.types.Part>} List of parts.
     * @function Mojito.Services.Bets.utils.getAllParts
     */
    static getAllParts(bet) {
        const { legs = [] } = bet;
        return legs.flatMap(leg => leg.parts);
    }

    /**
     * Builds bet info object from bet.
     *
     * @param {Mojito.Services.Bets.types.Bet} bet - Bet object.
     *
     * @returns {Mojito.Services.Bets.types.BetInfo} Bet info object.
     * @function Mojito.Services.Bets.utils.buildBetInfo
     */
    static buildBetInfo(bet) {
        const { id: betId, integrationProperties } = bet;
        return { betId, integrationProperties };
    }

    /**
     * Get start time from time frame config.
     *
     * @param {Mojito.Services.Bets.types.BetsTimeFrameOptions} timeFrameConfigOptions - Time frame config object.
     *
     * @returns {Date} ISO date.
     * @function Mojito.Services.Bets.utils.timeFrameStartTime
     */
    static timeFrameStartTime(timeFrameConfigOptions) {
        const now = new Date();
        now.setHours(0, 0, 0, 0);

        const { timeSpan, duration } = timeFrameConfigOptions;
        // Duration should be 0 to get start time for current day

        switch (timeSpan) {
            case 'year':
            case 'years':
                return DateTimeUtils.addYears(now, -duration);

            case 'month':
            case 'months':
                return DateTimeUtils.addMonths(now, -duration);

            case 'week':
            case 'weeks':
                return DateTimeUtils.addDays(now, -7 * duration);

            case 'day':
            case 'days':
                return DateTimeUtils.addDays(now, -duration);

            case 'today':
                return now;
        }
    }

    /**
     * Get status list from status filter.
     *
     * @param {Mojito.Services.Bets.types.BET_STATUS} statusType - Value of status filter.
     *
     * @returns {Array<Mojito.Services.Bets.types.BET_STATUS>} Value of status filter in array.
     * @function Mojito.Services.Bets.utils.resolveStatusList
     */
    static resolveStatusList(statusType) {
        return statusType === BET_STATUS.ALL ? [] : [BET_STATUS[statusType]];
    }

    /**
     * Get filter as object where keys are filter types and values are filter values.
     *
     * @param {Array<Mojito.Services.Bets.types.BetHistoryFilter>} filters - Filters.
     * @returns {object} Filter object.
     * @function Mojito.Services.Bets.utils.getFilterObj
     */
    static getFilterObj(filters) {
        return filters.reduce((obj, filter) => ({ ...obj, ...getFilterByType(filter) }), {});
    }

    /**
     * Constructs a payload object for the `getBets` request, from range and status filter parameters.
     *
     * @param {Mojito.Services.Bets.types.BetHistoryFilter} rangeFilter - Object specifying the range filter for the request.
     * @param {Mojito.Services.Bets.types.BET_STATUS} statusFilter - Filter to specify the status of bets in the request.
     *
     * @returns {Mojito.Services.Bets.types.GetBetsPayload} The constructed payload for the `getBets` request.
     * @function Mojito.Services.Bets.utils.getPayloadFromFilters
     */
    static getPayloadFromFilters(rangeFilter, statusFilter) {
        const payload = {};
        const { type, options } = rangeFilter;
        switch (type) {
            case FILTER_CRITERIA_TYPE.LAST_BETS_AMOUNT:
                payload.recentAmount = options.value;
                break;

            case FILTER_CRITERIA_TYPE.TIME_FRAME: {
                const fromTime = BetsUtils.timeFrameStartTime(options);
                payload.filterCriteria = {
                    to: new Date().toISOString(),
                    from: fromTime.toISOString(),
                };
                break;
            }
        }

        const betStatuses = BetsUtils.resolveStatusList(statusFilter);
        if (betStatuses.length) {
            payload.filterCriteria = {
                ...payload.filterCriteria,
                filters: betStatuses,
            };
        }

        return payload;
    }

    /**
     * Check if bet has freebet stake.
     *
     * @param {Mojito.Services.Bets.types.Bet} bet - Bet.
     * @returns {boolean} True if freebet stake is larger than 0, else false.
     * @function Mojito.Services.Bets.utils.hasFreebetStake
     */
    static hasFreebetStake(bet) {
        const { freebetStake = 0 } = bet.funds;
        return !!Number(freebetStake);
    }

    /**
     * Check if bet is a UK bet.
     *
     * @param {Mojito.Services.Bets.types.Bet} bet - Bet object.
     *
     * @returns {boolean} True if bet type is a UK type, else false.
     * @function Mojito.Services.Bets.utils.isUkBet
     */
    static isUkBet(bet) {
        return !!UK_BET_TYPE[bet.betType];
    }

    /**
     * Find leg containing selection id among a list of legs.
     *
     * @param {string} selectionId - Selection id.
     * @param {Array<Mojito.Services.Bets.types.Leg>} legs - Array of legs.
     *
     * @returns {Mojito.Services.Bets.types.Leg|undefined} Leg if found, else undefined.
     * @function  Mojito.Services.Bets.utils#findLegWithSelectionId
     */
    static findLegWithSelectionId(selectionId, legs = []) {
        const legHasSelectionId = leg =>
            (leg.parts || []).find(part => part.selectionId === selectionId);
        return legs.find(legHasSelectionId);
    }

    /**
     * Get all selection ids from leg.
     *
     * @param {Mojito.Services.Bets.types.Leg} leg - Leg.
     *
     * @returns {Array<string>} Array of selection ids, empty if no selection id is found.
     * @function  Mojito.Services.Bets.utils#getLegSelectionIds
     */
    static getLegSelectionIds(leg) {
        return (leg.parts || []).map(part => part.selectionId);
    }

    /**
     * Total odds should not be rendered for multiple line bets or grouped singles.
     *
     * @param {Mojito.Services.Bets.types.Bet} bet - Bet object.
     * @param {number} [lines] - Number of lines or ew-lines in bet.
     *
     * @returns {boolean} True if total odds should be displayed.
     * @function Mojito.Services.Bets.utils.isTotalOddsApplicable
     */
    static isTotalOddsApplicable(bet, lines) {
        const { betType, numberOfLines } = bet;
        // Use numberOfLines on bet object if not provided.
        const numOfLines = lines || numberOfLines;
        return !(
            numOfLines > 1 ||
            betType === BET_TYPE.GROUPED_SINGLES ||
            betType === BET_TYPE.SINGLE
        );
    }

    /**
     * Converts value of status filter to Mojito.Services.Bets.types.StatusFilterOptions object.
     *
     * @param {Array<object | string>} filters - List of Filters.
     *
     * @returns {Mojito.Services.Bets.types.StatusFilterOptions} Converted status filter.
     * @function Mojito.Services.Bets.utils.convertToStatusFilterItem
     */
    static convertToStatusFilterItem(filters = []) {
        return filters.map(value => {
            if (typeof value === 'string') {
                return {
                    options: {
                        value,
                    },
                    type: FILTER_CRITERIA_TYPE.STATUS,
                };
            }

            return value;
        });
    }

    /**
     * Get event name from bet.
     *
     * @param {Mojito.Services.Bets.types.Bet} bet - Bet.
     *
     * @returns {object} Leg info.
     * @function Mojito.Services.Bets.utils.getFirstLegInfo
     */
    static getFirstLegInfo(bet) {
        return bet.legs[0].legInfo;
    }

    /**
     * Get event name from bet.
     *
     * @param {string} serviceName - Bet service name to log.
     *
     * @returns {Function} Respond function.
     * @function Mojito.Services.Bets.utils.voidResponder
     */
    static voidResponder(serviceName) {
        return () => {
            log.warn(`${serviceName} instance is missing.`);
            return Promise.reject();
        };
    }

    /**
     * Get first leg from bet.
     *
     * @param {Mojito.Services.Bets.types.Bet} bet - Bet.
     *
     * @returns {object} Leg.
     * @function Mojito.Services.Bets.utils.getFirstLeg
     */
    static getFirstLeg(bet) {
        return bet.legs[0];
    }

    /**
     * Returns true if lotto bet has been placed with bonus ball applied.
     *
     * @param {Mojito.Services.Bets.types.Bet} bet - Lotto bet.
     *
     * @returns {boolean} Was bonus ball applied.
     * @function Mojito.Services.Bets.utils.hasBonusBall
     */
    static hasBonusBall(bet) {
        const leg = BetsUtils.getFirstLeg(bet);
        const { subsetCount } = leg;
        const { ballsDrawn } = leg.legInfo;
        return !isNaN(subsetCount) && !isNaN(ballsDrawn) && subsetCount > ballsDrawn;
    }
}

function getFilterByType(filter) {
    switch (filter.type) {
        case FILTER_CRITERIA_TYPE.TIME_FRAME:
        case FILTER_CRITERIA_TYPE.LAST_BETS_AMOUNT:
            return { rangeFilter: filter };
        case FILTER_CRITERIA_TYPE.STATUS:
            return { statusFilter: filter.options.value };
    }
}
