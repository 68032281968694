import MojitoModules from 'mojito/modules';
import MojitoServices from 'mojito/services';
import SportCouponTypes from 'applications/sports/pages/sport-coupon-page/types/index.js';
import SUBSCRIPTION_CONSTANTS from './constants';
import {
    resolveEndDate,
    resolveStartDate,
    resolveTimeFilter,
    resolveMatchType,
    resolveEventsType,
    resolveSportId,
    resolveTournamentId,
} from './utils';

const SportGroupsListTypes = MojitoModules.SportGroupsList.types;
const ItemListTypes = MojitoServices.ItemList.types;
const PromotionsTypes = MojitoServices.Promotions.types;
const EventGroupsTypes = MojitoServices.EventGroups.types;
const SystemInformationTypes = MojitoServices.SystemInformation.types;
const ContentProviderTypes = MojitoServices.SportsContent.ContentProvider.types;

const DISPLAY_DATE_REGEX = /gmt.*?(\d+)/;
const LIMIT_FILTER_REGEX = /events-(.*)$/;

const limitFilters = ['small', 'medium', 'large'];

/**
 * Class to be used to generate and parse subscriptions.
 *
 * @class SubscriptionResolver
 * @memberof Mojito.Applications.Sports
 */
class SubscriptionResolver {
    constructor() {
        /**
         * Object containing parsers for different collection names.
         *
         * @type {object}
         */
        this.parsers = {
            [ContentProviderTypes.EventProvider.COLLECTION_NAME]: this.parseEvents.bind(this),
            [ContentProviderTypes.MarketProvider.COLLECTION_NAME]: this.parseMarkets.bind(this),
            [ContentProviderTypes.MenusProvider.COLLECTION_NAME]: this.parseSportMenu.bind(this),
            [ContentProviderTypes.ContainerProvider.COLLECTION_NAME]:
                this.parseContainer.bind(this),
            [ContentProviderTypes.MarketGroupsProvider.COLLECTION_NAME]:
                this.parseMarketGroup.bind(this),
            [ContentProviderTypes.SportMetaInformationProvider.COLLECTION_NAME]:
                this.parseSportMetaInformation.bind(this),
            [ContentProviderTypes.RaceRegionProvider.COLLECTION_NAME]:
                this.parseRaceRegions.bind(this),
            [ContentProviderTypes.MeetingProvider.COLLECTION_NAME]:
                this.parseRaceMeetings.bind(this),
            [ItemListTypes.COLLECTION_NAME]: this.parseItemsList.bind(this),
            [PromotionsTypes.COLLECTION_NAME]: this.parsePromotions.bind(this),
            [SystemInformationTypes.COLLECTION_NAME]: this.parseSystemInformation.bind(this),
            [EventGroupsTypes.COLLECTION_NAME]: this.parseEventGroups.bind(this),
        };
    }

    /**
     * Get the parser function for a given collection.
     *
     * @param {string} collection - The collection to get the parser for.
     * @returns {Function} The parser function.
     */
    getParser(collection) {
        return this.parsers[collection];
    }

    /**
     * Parse event subscription.
     *
     * @param {string} subscription - The subscription to parse.
     * @returns {object} The parsed event subscription.
     */
    parseEvents(subscription) {
        return { eventId: subscription };
    }

    /**
     * Parse promotion subscription.
     *
     * @param {string} subscription - The subscription to parse.
     * @returns {object} The parsed promotion subscription.
     */
    parsePromotions(subscription) {
        return { targetType: subscription };
    }

    /**
     * Parse market subscription.
     *
     * @param {string} subscription - The subscription to parse.
     * @returns {object} The parsed market subscription.
     */
    parseMarkets(subscription) {
        return { marketId: subscription };
    }

    /**
     * Parse system information subscription.
     *
     * @param {string} subscription - The subscription to parse.
     * @returns {object} The parsed system information subscription.
     */
    parseSystemInformation(subscription) {
        return { systemInformationId: subscription };
    }

    /**
     * Parse sport menu subscription.
     *
     * @param {string} subscription - The subscription to parse.
     * @returns {object} The parsed sport menu subscription.
     */
    parseSportMenu(subscription) {
        return { timeZone: subscription };
    }

    /**
     * Parse container subscription.
     *
     * @param {string} subscription - The subscription to parse.
     * @returns {object} The parsed container subscription.
     */
    parseContainer(subscription) {
        const parametersParts = subscription.split('-');
        const sportId = resolveSportId(subscription);
        const typeId = parametersParts.slice(1).join('-') || null;

        return { sportId, typeId };
    }

    /**
     * Parse market group subscription.
     *
     * @param {string} subscription - The subscription to parse.
     * @returns {object} The parsed market group subscription.
     */
    parseMarketGroup(subscription) {
        const parametersParts = subscription.split('-');

        return {
            parameters: subscription,
            marketGroupId: parametersParts[0],
            marketGroupCategoryId: parametersParts[1],
        };
    }

    /**
     * Parse sport meta information subscription.
     *
     * @param {string} subscription - The subscription to parse.
     * @returns {object} The parsed sport meta information subscription.
     */
    parseSportMetaInformation(subscription) {
        const typeRegex = subscription.match(/sports-with-(.*?)-match/);
        const matchType = typeRegex && typeRegex[1];

        const limitFilterMatch = subscription.match(LIMIT_FILTER_REGEX);
        const limitFilter =
            limitFilterMatch && limitFilters.includes(limitFilterMatch[1])
                ? limitFilterMatch[1]
                : undefined;

        return { matchType, limitFilter };
    }

    /**
     * Parse race region subscription.
     *
     * @param {string} subscription - The subscription to parse.
     * @returns {object} The parsed race region subscription.
     */
    parseRaceRegions(subscription) {
        return {
            sportId: resolveSportId(subscription),
            locale: subscription.match(/-([^-\s]+)-meetings/)?.[1],
            endDate: resolveEndDate(subscription),
            timeZone: subscription.match(DISPLAY_DATE_REGEX)?.[0],
        };
    }

    /**
     * Parse race meeting subscription.
     *
     * @param {string} subscription - The subscription to parse.
     * @returns {object} The parsed race meeting subscription.
     */
    parseRaceMeetings(subscription) {
        return { raceMeetingId: subscription };
    }

    /**
     * Parse items list subscription.
     *
     * @param {string} subscription - The subscription to parse.
     * @returns {object | undefined} The parsed items list subscription.
     */
    parseItemsList(subscription) {
        const { SPORTS_WITH, LOCATIONS_WITH_MEETINGS } = SUBSCRIPTION_CONSTANTS;

        if (subscription.startsWith(SportCouponTypes.CMS_COUPON_PREFIX)) {
            const parts = subscription.split(SportCouponTypes.CMS_COUPON_PREFIX);
            return { type: SportCouponTypes.CMS_COUPON_PREFIX.slice(0, -1), sportId: parts[1] };
        }
        if (subscription.startsWith(SPORTS_WITH)) {
            return {
                type: SPORTS_WITH,
                matchType: resolveMatchType(subscription),
                eventsType: resolveEventsType(subscription),
            };
        }
        if (subscription.includes(LOCATIONS_WITH_MEETINGS)) {
            return {
                type: LOCATIONS_WITH_MEETINGS,
                timeFilter: resolveTimeFilter(subscription),
                sportId: resolveSportId(subscription),
                startDate: resolveStartDate(subscription),
                endDate: resolveEndDate(subscription),
                timeZone: subscription.match(DISPLAY_DATE_REGEX)?.[0],
            };
        }
    }

    /**
     * Parse event group subscription.
     *
     * @param {string} subscription - The subscription to parse.
     * @returns {object} The parsed event group subscription.
     */
    parseEventGroups(subscription) {
        const limitFilterMatch = subscription.match(LIMIT_FILTER_REGEX);
        const isCmsCoupons = subscription.includes(SportCouponTypes.CMS_COUPON_PREFIX);

        let sportId = resolveSportId(subscription);
        let tournamentId = resolveTournamentId(subscription);

        if (isCmsCoupons) {
            sportId = subscription.match(/cms-coupons-(.*?)-(\d+)/)?.[1];
            tournamentId = subscription.match(/tournament:(.*?)-/)?.[1];
        }

        return {
            parameters: subscription,
            type: isCmsCoupons ? SportCouponTypes.CMS_COUPON_PREFIX.slice(0, -1) : null,
            sportId,
            endDate: resolveEndDate(subscription),
            isPriceBoostedEvents: subscription.includes('price-boosted'),
            matchType: resolveMatchType(subscription),
            eventsType: resolveEventsType(subscription),
            limitFilter:
                limitFilterMatch && limitFilters.includes(limitFilterMatch[1])
                    ? limitFilterMatch[1]
                    : undefined,
            groupedBy: subscription.split('grouped-by-')[1],
            categoryId: subscription.match(/category:(.*?)-/)?.[1],
            tournamentId,
        };
    }

    /**
     * Generate sports subscriptionId. This method can be overriden.
     * It is used to subscribe to sports.
     *
     * @returns {string} Sports subscription id.
     */
    sports() {
        return SUBSCRIPTION_CONSTANTS.SPORTS;
    }

    /**
     * Generate cms coupon prefix subscriptionId. This method can be overriden.
     * It is used to subscribe to cms coupons of list items.
     *
     * @param {string} sportId - The sport ID.
     * @returns {string} Subscription id with cms coupon prefix.
     */
    cmsCouponPrefix(sportId) {
        return `${SportCouponTypes.CMS_COUPON_PREFIX}${sportId}`;
    }

    /**
     * Generate top leagues subscriptionId. This method can be overriden.
     * It is used to subscribe to top leagues of container items and event groups.
     *
     * @param {string} sportId - The sport ID.
     * @returns {string} The top leagues subscription id.
     */
    topLeagues(sportId) {
        return `${sportId}${SportGroupsListTypes.GROUP_ID.TOP_LEAGUES}`;
    }

    /**
     * Generate top outrights subscriptionId. This method can be overriden.
     * It is used to subscribe to top outrights of container items and event groups.
     *
     * @param {string} sportId - The sport ID.
     * @returns {string} The top outrights subscription id.
     */
    topOutrights(sportId) {
        return `${sportId}${SportGroupsListTypes.GROUP_ID.TOP_OUTRIGHTS}`;
    }

    /**
     * Generate specific sport subscriptionId. This method can be overriden.
     * It is used to subscribe to specific sport event group with price boosted events.
     *
     * @param {string} sportId - The sport ID.
     * @returns {string} The specific sport subscription id.
     */
    specificSport(sportId) {
        return `${sportId}${EventGroupsTypes.PRICE_BOOST_EVENT_GROUP_NAME.SPECIFIC_SPORT}`;
    }

    /**
     * Generate future outright events subscriptionId. This method can be overriden.
     * It is used to subscribe to specific sport event group with future outright events.
     *
     * @param {string} sportId - The sport ID.
     * @returns {string} The future outright events subscription id.
     */
    futureOutrightEvents(sportId) {
        const { FUTURE_OUTRIGHT_EVENTS } = SUBSCRIPTION_CONSTANTS;

        return `${sportId}${FUTURE_OUTRIGHT_EVENTS}`;
    }

    /**
     * Generate future match events subscriptionId. This method can be overriden to generate subscription key for small or large events document.
     * This subscription key is used to subscribe to event groups for top sports coupon featured section.
     *
     * @param {string} sportId - The sport ID.
     * @returns {string} The future match events subscription id.
     */
    futureMatchEvents(sportId) {
        const { FUTURE_MATCH_EVENTS_MEDIUM } = SUBSCRIPTION_CONSTANTS;

        return `${sportId}${FUTURE_MATCH_EVENTS_MEDIUM}`;
    }

    /**
     * Generate live match events subscriptionId. This method can be overriden to generate subscription key for medium or large events document.
     * This subscription key is used to subscribe to event groups for top sports coupon inplay section.
     *
     * @param {string} sportId - The sport ID.
     * @returns {string} The live match events subscription id.
     */
    liveMatchEvents(sportId) {
        const { LIVE_MATCH_EVENTS_SMALL } = SUBSCRIPTION_CONSTANTS;

        return `${sportId}${LIVE_MATCH_EVENTS_SMALL}`;
    }

    /**
     * Generate sports with live and upcoming match and outright events subscriptionId. This method can be overriden.
     * This subscription key is used to subscribe to event groups for inplay and event page.
     *
     * @returns {string} The sports with live and upcoming match and outright events subscription id.
     */
    sportsWithLiveAndUpcomingMatchAndOutrightEvents() {
        const { SPORTS_WITH } = SUBSCRIPTION_CONSTANTS;

        return this.liveAndUpcomingMatchAndOutrightEvents(SPORTS_WITH);
    }

    /**
     * Generate sports with live match events subscriptionId. This method can be overriden to generate subscription key for medium or large events document.
     * This subscription key is used to subscribe to event groups for top sports coupon inplay section.
     *
     * @returns {string} The sports with live match events subscription id.
     */
    sportsWithLiveMatchEvents() {
        const { SPORTS_WITH } = SUBSCRIPTION_CONSTANTS;

        return this.liveMatchEvents(SPORTS_WITH);
    }

    /**
     * Generate sports with future match events subscriptionId. This method can be overriden.
     * This subscription key is used to subscribe to event groups for top sports coupon featured section.
     *
     * @returns {string} The sports with future match events subscription id.
     */
    sportsWithFutureMatchEvents() {
        const { SPORTS_WITH } = SUBSCRIPTION_CONSTANTS;

        return this.futureMatchEvents(SPORTS_WITH);
    }

    /**
     * Generate live and upcoming match and outright events subscriptionId. This method can be overriden.
     * It is used to subscribe to event groups of live, upcoming and outright events.
     *
     * @param {string} sportId - The sport ID.
     * @returns {string} The live and upcoming match and outright events subscription id.
     */
    liveAndUpcomingMatchAndOutrightEvents(sportId) {
        const { LIVE_AND_UPCOMING_MATCH_AND_OUTRIGHT_EVENTS } = SUBSCRIPTION_CONSTANTS;

        return `${sportId}${LIVE_AND_UPCOMING_MATCH_AND_OUTRIGHT_EVENTS}`;
    }

    /**
     * Generate all outright events subscriptionId. This method can be overriden.
     * It is used to subscribe to event groups of outrights league.
     *
     * @param {string} typeId - The type ID.
     * @returns {string} The all outright events subscription id.
     */
    allOutrightEvents(typeId) {
        const { ALL_OUTRIGHT_EVENTS } = SUBSCRIPTION_CONSTANTS;

        return `${typeId}${ALL_OUTRIGHT_EVENTS}`;
    }

    /**
     * Generate all match events subscriptionId. This method can be overriden.
     * It is used to subscribe to event groups of matches league.
     *
     * @param {string} typeId - The type ID.
     * @returns {string} The all match events subscription id.
     */
    allMatchEvents(typeId) {
        const { ALL_MATCH_EVENTS } = SUBSCRIPTION_CONSTANTS;

        return `${typeId}${ALL_MATCH_EVENTS}`;
    }

    /**
     * Generate all virtual events subscriptionId. This method can be overriden.
     * It is used to subscribe to event groups of virtual events.
     *
     * @param {string} sportClassId - The sport class ID.
     * @returns {string} The all virtual events subscription id.
     */
    allVirtualEvents(sportClassId) {
        const { ALL_VIRTUAL_EVENTS } = SUBSCRIPTION_CONSTANTS;

        return `${sportClassId}${ALL_VIRTUAL_EVENTS}`;
    }

    /**
     * Generate match all types by class subscriptionId. This method can be overriden.
     * It is used to subscribe to container items for quick leagues navigation.
     *
     * @param {string} sportId - The sport ID.
     * @param {string} sportClassId - The sport class ID.
     * @returns {string} The match all types by class subscription id.
     */
    matchAllTypesByClass(sportId, sportClassId) {
        const { MATCH_ALL_TYPES_BY_CLASS } = SUBSCRIPTION_CONSTANTS;

        return sportId && sportClassId && `${sportId}-${sportClassId}${MATCH_ALL_TYPES_BY_CLASS}`;
    }
}

export default SubscriptionResolver;
