import MojitoCore from 'mojito/core';
import { noop } from 'mojito/utils';
import { withRetry } from 'services/utils';
import { actions } from './bet-history-slice.js';
import BetsUtils from 'services/bets/utils';

const { dispatch } = MojitoCore.Services.redux.store;
const { ERROR_CODE } = MojitoCore.Services.Transactions.types;
const NULL_SERVICE = {
    configure: noop,
    getBets: BetsUtils.voidResponder('betsService'),
};

/**
 * Bet history data retriever config.
 *
 * @typedef BetHistoryDataRetrieverConfig
 * @type {object}
 * @property {Mojito.Services.Bets.AbstractBetsService} service - Instance of concrete bets service implementation.
 * @property {string} serviceUrl - URL that will be used by <code>service<code/> instance.
 *
 * @memberof Mojito.Services.Bets.betHistoryDataRetriever
 */

/**
 * Bet history data retriever used to delegate calls to bets service abstraction.
 *
 * @class BetHistoryDataRetriever
 * @name betHistoryDataRetriever
 * @memberof Mojito.Services.Bets
 */
class BetHistoryDataRetriever {
    constructor() {
        this.service = NULL_SERVICE;
    }

    /**
     * Init bets data retriever.
     *
     * @param {Mojito.Services.Bets.betHistoryDataRetriever.BetsDataRetrieverConfig} config - Config object.
     *
     * @function Mojito.Services.Bets.betHistoryDataRetriever#init
     */
    init({ service, serviceUrl }) {
        this.service = service || NULL_SERVICE;
        this.service.configure({ serviceUrl });
    }

    /**
     * Get bet history.
     *
     * @param {Mojito.Services.Bets.types.GetBetsPayload} payload - Get bets payload.
     * @function Mojito.Services.Bets.betHistoryDataRetriever#getBets
     */
    getBets(payload) {
        const { pagination } = payload;
        const shouldRetry = pagination && pagination.page > 1;
        const getBets = shouldRetry
            ? withRetry(this.service.getBets.bind(this.service), 1)
            : this.service.getBets.bind(this.service);
        getBets(payload)
            .then(data => dispatch(actions.fetchBetsSuccess(data)))
            .catch(error => {
                if (error?.type !== ERROR_CODE.ABORTED) {
                    dispatch(actions.fetchBetsFailed(error));
                }
            });
    }
}

export default new BetHistoryDataRetriever();
