import SelectionCardImpl from './selection-card.jsx';
import MojitoCore from 'mojito/core';
import PropTypes from 'prop-types';

const { UIView } = MojitoCore.Presentation;

/**
 * Selection card component.
 *
 * @function SelectionCard
 * @name view
 *
 * @memberof Mojito.Modules.EventCards.SelectionCard
 */

/**
 * Selection event card prop types.
 *
 * @property {string} eventId - Event id.
 * @property {string} marketId - Market id is used to get a market info.
 * @property {string} marketName - Market name.
 * @property {string} selectionId - Selection id is used to get a selection.
 * @property {string} selectionName - Selection name.
 * @property {boolean} [shouldRequestData = true] - Flag indicating whether component should request event and market data from content. If false, then neither event nor markets will be requested, hence in order to present info corresponding data should be already available in a store.
 * @property {boolean} [showSkeleton = true] - True if data loading skeleton should be visible. It can be useful to hide skeleton if parent component already covers component with its of top level skeleton.
 * @property {boolean} [reportLoadingStatus = false] - Flag indicating if component should register itself to track on data loading and rendering status which ends up in reporting `Mojito.Core.Services.Performance.types.TIMELINE_RECORD_TYPES.PAGE_READY` metric once all visible components on the page are rendered and presenting data.
 * @property {object | Function} [elementRef] - A ref to the rendered element.
 *
 * @memberof Mojito.Modules.EventCards.SelectionCard.view
 */
const propTypes = {
    eventId: PropTypes.string.isRequired,
    marketId: PropTypes.string.isRequired,
    marketName: PropTypes.string.isRequired,
    selectionId: PropTypes.string.isRequired,
    selectionName: PropTypes.string.isRequired,
    shouldRequestData: PropTypes.bool,
    showSkeleton: PropTypes.bool,
    reportLoadingStatus: PropTypes.bool,
    elementRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

const defaultProps = {
    shouldRequestData: true,
    showSkeleton: true,
    reportLoadingStatus: false,
};

export default UIView('SelectionCard', SelectionCardImpl, propTypes, defaultProps);
