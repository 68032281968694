import MojitoPresentation from 'mojito/presentation';
import { useContainerItems, useEventGroups } from 'modules/common/hooks/index.js';
const { RacingHelper } = MojitoPresentation.Utils;
import SportGroupsListView from './view/index.jsx';

const SportGroupsListController = ({
    sportId,
    groupId,
    type,
    pageType,
    onDataLoad,
    mojitoTools,
}) => {
    const { config } = mojitoTools;
    const baseSportGroups = useContainerItems(groupId);
    const eventGroups = useEventGroups([groupId]);
    const racingSportGroups = eventGroups[groupId]?.map(
        ({ id, countryCode, events, typeName, canonicalTypeName }) => ({
            id,
            countryCode,
            canonicalName: canonicalTypeName,
            items: events,
            name: typeName,
        })
    );

    const sportGroups = RacingHelper.isRacingSport(sportId) ? racingSportGroups : baseSportGroups;

    return (
        <SportGroupsListView
            sportId={sportId}
            sportGroups={sportGroups}
            type={type}
            pageType={pageType}
            config={config.sportGroupsListView}
            groupId={groupId}
            onDataLoad={onDataLoad}
        />
    );
};

export default SportGroupsListController;
