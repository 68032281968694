import MojitoPresentation from 'mojito/presentation';

import {getExtendedFrameIntentData, LOGIN_INTO_ACCOUNT, ON_CLOSE_CASHIER} from '#core/utils/intent-factory.js';
import {OpenUrlStrategies} from '#core/utils/intent-validation.cjs';
import {removeContextProperties} from '#core/utils/config-utils.js';

import {AbstractFeature, allFeatures} from '#core/application/abstract-feature.js';

import SCHEMA from './intents.schema.yaml';

export const Intents = {
    LOGIN: null,
    LOGOUT: null,
    DEPOSIT: null,
    REGISTER: null,
    FORGOT_DETAILS: null,

    COOKIE_CONSENT: null,
    EARLY_PAYOUT_READ_MORE: null,
    HELP: null,
    OPEN_BETS: {
        type: MojitoPresentation.Base.Intent.Types.SHOW_SLIDING_OPEN_BETS,
    },
    AZ_MENU: {
        type: MojitoPresentation.Base.Intent.Types.SHOW_SLIDING_AZ_MENU,
    },
    SPORTSBOOK_MOUNTED: null,
};

function getHandledIntent(intent, title, onCloseHandler) {
    if (!intent) return null;

    if (intent.strategy === OpenUrlStrategies.FRAME) {
        return {
            ...intent,
            data: getExtendedFrameIntentData(intent.data, title, onCloseHandler),
        };
    }

    return intent;
}

class IntentsFeature extends AbstractFeature {
    get name() {
        return 'Intents';
    }

    get configSchema() {
        return SCHEMA;
    }

    setupConfigs(globalConfig, options, filterFn) {
        super.setupConfigs(removeContextProperties(globalConfig), options, filterFn);

        const {
            login,
            logout,
            deposit,
            register,
            forgotDetails,
            cookieConsent,
            help,
            sportsbookMounted,
            earlyPayoutReadMore,
        } = this.config;

        // TODO: Sort out with standard labels & translations
        Intents.LOGIN = getHandledIntent(login, '$USER_AUTHENTICATION_INFO.LOGIN', LOGIN_INTO_ACCOUNT);
        Intents.LOGOUT = getHandledIntent(logout);
        Intents.DEPOSIT = getHandledIntent(deposit, '$MY_ACCOUNT_PAGE.MENU_ITEMS.DEPOSIT', ON_CLOSE_CASHIER);
        Intents.REGISTER = getHandledIntent(register, '$USER_AUTHENTICATION_INFO.SIGNUP', LOGIN_INTO_ACCOUNT);
        Intents.FORGOT_DETAILS = getHandledIntent(
            forgotDetails,
            '$LOGIN_DIALOG.USERNAME_PASSWORD.FORGOT_DETAILS',
            LOGIN_INTO_ACCOUNT
        );
        Intents.COOKIE_CONSENT = getHandledIntent(cookieConsent, '$DBX.COOKIE_POLICY_TITLE');
        Intents.EARLY_PAYOUT_READ_MORE = getHandledIntent(earlyPayoutReadMore);
        Intents.HELP = getHandledIntent(help);
        Intents.SPORTSBOOK_MOUNTED = getHandledIntent(sportsbookMounted);
    }
}

new IntentsFeature(allFeatures);
