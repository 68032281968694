import React, { useMemo } from 'react';
import MojitoCore from 'mojito/core';
import { isEmpty } from 'mojito/utils';
import BannerPromotionsView from './view/index.jsx';
import {
    usePromotions,
    usePromotionsPreload,
    usePromotionsLoadDone,
} from 'modules/promotions/hooks';
import { useLoadingStatusMetric } from 'modules/performance/hooks';

const { ContextExtender: AnalyticsContextExtender, ContextBuilder: AnalyticsContextBuilder } =
    MojitoCore.Presentation.AnalyticsContext;

const analyticsContext = new AnalyticsContextBuilder().withAnalyticsPath('Banner').build();

export default function BannerPromotions(props) {
    const {
        applicablePage,
        mojitoTools: { config, instanceId },
    } = props;
    const { locationId, maxNumberOfPromotions, reportLoadingStatus } = config;
    const promotions = usePromotions(locationId, applicablePage);
    const promotionsLoadDone = usePromotionsLoadDone([locationId]);

    const activePromotions = useMemo(
        () =>
            maxNumberOfPromotions >= 0 ? promotions.slice(0, maxNumberOfPromotions) : promotions,
        [maxNumberOfPromotions, promotions]
    );

    const [preloadDone, onPrepareBannersToRender] = usePromotionsPreload(
        activePromotions,
        instanceId,
        promotionsLoadDone
    );

    const noContent = promotionsLoadDone && isEmpty(activePromotions);

    const performanceElementRef = useLoadingStatusMetric(
        promotionsLoadDone || noContent,
        reportLoadingStatus ? instanceId : undefined,
        'BannerPromotions'
    );

    if (noContent) {
        return null;
    }

    return (
        <AnalyticsContextExtender value={analyticsContext}>
            <BannerPromotionsView
                promotions={activePromotions}
                promotionsLoaded={promotionsLoadDone}
                config={config.view}
                locationId={locationId}
                onPrepareBannersToRender={onPrepareBannersToRender}
                shouldRequestData={preloadDone}
                elementRef={performanceElementRef}
            />
        </AnalyticsContextExtender>
    );
}
