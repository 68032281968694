import { range } from 'mojito/utils';
import FlexPane from 'presentation/components/flex-pane/index.jsx';
import SkeletonPart from 'presentation/components/skeleton-part/index.jsx';

const MenuRowSkeleton = props => {
    const {
        mojitoTools: { config },
        elementRef,
    } = props;

    return (
        <FlexPane config={config.container} class="ta-MenuRowSkeleton" elementRef={elementRef}>
            {range(config.numberOfItems).map(index => {
                return config.showIconPlaceholder ? (
                    <FlexPane key={index} config={config.itemContainer}>
                        {/* NOSONAR - The array index is used as a simple placeholder key. */}
                        <SkeletonPart config={config.iconPlaceholder} class="ta-IconPlaceholder" />
                        <SkeletonPart config={config.linkPlaceholder} />
                    </FlexPane>
                ) : (
                    <SkeletonPart key={index} config={config.linkPlaceholder} /> // NOSONAR - The array index is used as a simple placeholder key.
                );
            })}
        </FlexPane>
    );
};

export default MenuRowSkeleton;
