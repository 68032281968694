import MojitoCore from 'mojito/core';
import MojitoNGEN from 'mojito/ngen';
import MojitoServices from 'mojito/services';

import {merge} from '#core/utils/config-utils.js';
import {absoluteUrlToInternalResource, getDetectedHostURL} from '#core/utils/url-utils.js';
import {getAdditionalContext, isMobile} from '#core/utils/context-utils.js';

import {Localization} from '#core/localization.js';
import {ApplicationConfig} from '#core/config/application-config.js';
import {DBXPerformanceService} from '#core/services/performance-reporting-service/index.js';

import {AUTH_METHOD} from './types.js';
import {DrivenSSOService} from './embedded-app/driven-sso-service.js';

const SystemSettingsTypes = MojitoCore.Services.SystemSettings.types;
const {APPLICATION_MODE} = SystemSettingsTypes;
const ServicesTypes = MojitoServices.Common.types;

const NgenContentTransporter = MojitoNGEN.NgenContentTransporter;
const wsStatusListener = MojitoCore.Services.Performance.wsStatusListener;

export function getCommonMojitoConfig({noAuthentication}) {
    const initParams = ApplicationConfig.initParams;
    function getAuthServiceConfig(noAuthentication = false) {
        if (!BUILT_IN_AUTHORIZATION) {
            // No authentication available at all
            return {
                service: MojitoServices.Authentication.service,
                ssoImplementation: DrivenSSOService,
            };
        }
        if (noAuthentication) {
            // 1. This is a widget
            // 2. It doesn't require authentication service
            return undefined;
        }

        return {
            service: MojitoServices.Authentication.service,
            redirectUrl: `${getDetectedHostURL()}/resetpassword`,
            ssoImplementation:
                ApplicationConfig.initParams.authMethod === AUTH_METHOD.EXTERNAL ? DrivenSSOService : undefined,
        };
    }

    function getServicesConfig() {
        const result = {
            // Common part
            transactionUrl: ApplicationConfig.apiUrl,
            bootstrapService: MojitoServices.Bootstrap.service,
            betsService: {
                service: MojitoServices.Bets.service,
                openBets: {
                    timePeriodInYears: 1,
                    pollIntervalSec: 150,
                },
            },
            userInfoService: {
                service: MojitoServices.UserInfo.service,
            },
            authenticationService: getAuthServiceConfig(noAuthentication),
            configurations: {
                AppControllerView: {},
                Image: {
                    svgSpritemap: {
                        idPrefix: 'svgsprite-',
                        path: '',
                    },
                },
                Overlay: {
                    rootElementId: initParams.overlayContainerId,
                    style: {
                        container: {
                            zIndex: initParams.overlayPlaneZIndex,
                        },
                    },
                },
                Currencies: initParams.currencyCode
                    ? {
                          defaultCurrencyCode: initParams.currencyCode,
                      }
                    : {},
                ConfigFactory: {
                    strict: false,
                },
                SkeletonPart: {
                    svgSrc: '#svgsprite-skeleton-part',
                },
            },
            stores: {
                content: {
                    contentKeepAliveTime: 5 * 60 * 1000, // Keep data cached for 30 minutes
                },
                systemSettings: {
                    applicationMode: isMobile() ? APPLICATION_MODE.MOBILE : APPLICATION_MODE.DESKTOP,
                    language: Localization.getCurrentLanguage(),
                    additionalContext: getAdditionalContext(),
                    contextToChannelMap: {
                        native: GENBET_BASED ? ServicesTypes.CHANNEL.NATIVE : ServicesTypes.CHANNEL.MOBILE, // not possible to configure promotions for the native context on Quantum-based operators since the native context is not supported by Apollo CMS
                    },
                },
            },
        };
        if (IS_LOTTO) {
            return merge(result, {
                betslipService: {
                    service: MojitoServices.LottoBetslip.service,
                },
            });
        } else {
            // Sportsbook
            return merge(result, {
                pdfServiceUrl: ApplicationConfig.apiUrl,
                promotionsService: {
                    service: MojitoServices.Promotions.service,
                },
                matchAccaService: {
                    service: MojitoServices.MatchAcca.matchAccaService,
                },
                videoStreamingService: {
                    service: MojitoServices.VideoStreaming.service,
                },
                priceChangePolicy: 'ALLOW_ALL',
                searchService: MojitoServices.Search.searchService,
                stores: {
                    promotions: {
                        // Genbet doesnt support polling
                        pollOnLoggedIn: !GENBET_BASED,
                    },
                    search: {
                        recentHistory: {
                            maxSize: 10,
                            maxDays: 180,
                        },
                    },
                },
            });
        }
    }

    return {
        core: {
            executionMode:
                process.env.NODE_ENV === 'production'
                    ? MojitoCore.EXECUTION_MODES.RELEASE
                    : MojitoCore.EXECUTION_MODES.DEBUG,
            baseUrl: ApplicationConfig.apiUrl,
            translations: Localization.getMojitoConfig(),
            deviceGuesser: {
                isProbablyHandHeldDevice: isMobile,
            },
            defaultCookieConsentGiven: initParams.cookieConsentGiven,
            contentTransporter: new NgenContentTransporter({
                wsStatusListener,
                maxConcurrentRequests: 30,
                baseUrl: ApplicationConfig.apiUrl,
            }),
            services: {
                performanceService: {
                    service: DBXPerformanceService,
                    // serviceConfig: {} // Nothing to configure here. DBXPerformanceService could be configured in runtime
                },
            },
        },
        services: getServicesConfig(),
        resources: {
            getImage: function (path) {
                return absoluteUrlToInternalResource(`/images/${path}`);
            },
            getFixture: function (path) {
                return absoluteUrlToInternalResource(`/fixtures/${path}`);
            },
        },
    };
}
