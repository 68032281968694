import {mergeToHierarchy} from '#core/utils/config-utils.js';

import {AbstractFeature, allFeatures} from '#core/application/abstract-feature.js';
import {ApplicationConfig} from '#core/config/application-config.js';

import {DBXCompactHeader} from './compact-header/compact-header.jsx';

/**
 * Simple feature that adjust presentation if application is embedded into host app
 */
class EmbeddedAppFeature extends AbstractFeature {
    get name() {
        return 'EmbeddedApp';
    }

    get enabled() {
        // Always shows as widget, for application without built-in authorization
        return !BUILT_IN_AUTHORIZATION || ApplicationConfig.isEmbedded;
    }

    beforeMojitoConfigBuild(mojitoConfig) {
        mergeToHierarchy(mojitoConfig, 'services.configurations', {
            AppViewSportsbook: {
                quickLinks: {
                    hide: false,
                },
            },
            MainNavigationBar: {
                showSettingsButton: false,
            },
            BetHistoryView: {
                style: {
                    compactTableContainer: {
                        // Following is workaround for to fix infinite scroll issue in portal integrated solution
                        height: '100%',
                        maxHeight: 'calc(100vh - 160px)', // just make container fixed size
                        overflowY: 'scroll',
                        //----
                        wrapInfiniteList: true,
                    },
                },
            },
        });
        mergeToHierarchy(mojitoConfig, 'services.viewImplementations.ApplicationHeader', {
            implementation: DBXCompactHeader,
        });

        return super.beforeMojitoConfigBuild(mojitoConfig);
    }
}

new EmbeddedAppFeature(allFeatures);
