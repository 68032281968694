import MojitoCore from 'mojito/core';
import { useIrreversibleTrue } from 'presentation/hooks';

const log = MojitoCore.logger.get('EventCardsCarousel');

const CardLoader = props => {
    const { card, readyToLoad, renderer } = props;
    const shouldShowCard = useIrreversibleTrue(readyToLoad);

    if (!renderer) {
        log.error(`Can not find renderer for '${card?.type || card?.eventId}' card`);
        return null;
    }

    return shouldShowCard && renderer(card);
};

export default CardLoader;
