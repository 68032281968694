import {PALETTE_IDS} from '#core/application/modules/ids.js';

import {COLOR, FONT} from '../theme.js';

export function getVirtualsConfig() {
    return {
        custom: {
            [PALETTE_IDS.VIRTUALS]: {
                VirtualSportPageView: {
                    hideSportsMenu: true,
                },
            },
        },
        palette: {
            [PALETTE_IDS.VIRTUALS]: {
                DESKTOP_VIRTUALS_SPORT_MENU_BG: COLOR.BASE.PAGE,
                MOBILE_SPORT_SELECTOR_CONTAINER: COLOR.BASE.PAGE,
                VIRTUALS_EVENT_NAME_TEXT: {
                    color: COLOR.BRAND.FOREGROUND1,
                    fontSize: FONT.SIZE_XLARGE,
                    textTransform: 'capitalize',
                },
                VIRTUALS_EVENT_SELECTOR_BG: COLOR.BRAND.BACKGROUND7,
                VIRTUALS_STREAM_CONTAINER_BG: COLOR.BRAND.BACKGROUND7,
                VIRTUALS_NEXT_EVENT_COUNTDOWN_TEXT: COLOR.BRAND.BACKGROUND2,
                VIRTUALS_NEXT_EVENT_COUNTDOWN_BG: COLOR.BRAND.FOREGROUND4,
                VIRTUALS_EVENT_SELECTOR_BOTTOM_DIVIDER: COLOR.BASE.PAGE,
            },
        },
    };
}
