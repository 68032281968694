import MojitoCore from 'mojito/core';
import { actions as userInfoActions } from './slice.js';

const log = MojitoCore.logger.get('UserInfoDataRetriever');
const reduxInstance = MojitoCore.Services.redux;
const { dispatch } = reduxInstance.store;

/**
 * User info data retriever config.
 *
 * @typedef UserInfoDataRetrieverConfig
 * @type {object}
 * @property {Mojito.Services.UserInfo.AbstractUserInfoService} service - Instance of concrete user info service implementation.
 * @property {string} serviceUrl - URL that will be used by <code>service<code/> instance.
 *
 * @memberof Mojito.Services.UserInfo.dataRetriever
 */

/**
 * Data retriever used to delegate calls to user info service abstraction.
 *
 * @class UserInfoDataRetriever
 * @name dataRetriever
 * @memberof Mojito.Services.UserInfo
 */
class UserInfoDataRetriever {
    /**
     * Init with service implementation.
     *
     * @param {Mojito.Services.UserInfo.dataRetriever.UserInfoDataRetrieverConfig} [config = undefined] - User info data retriever config.
     * @function Mojito.Services.UserInfo.dataRetriever#init
     */
    init({ service, serviceUrl }) {
        if (!service) {
            log.error('BalanceService implementation instance is not configured');
            return;
        }
        this.userInfoService = service.service;
        this.balanceService = service.balanceService;

        this.userInfoService.configure &&
            this.userInfoService.configure({
                serviceUrl,
            });
    }

    restore() {
        this.userInfoService = undefined;
    }

    hasService() {
        return Boolean(this.userInfoService);
    }

    getBalance(currency) {
        if (!this.balanceService) {
            return;
        }

        this.balanceService.fetchBalance(currency);
    }

    subscribeToBalanceUpdates(currency) {
        if (!this.balanceService) {
            return;
        }

        this.balanceService.subscribeToBalanceUpdates(
            currency,
            balances => dispatch(userInfoActions.updateBalance(balances)),
            this.handleBalanceFailed
        );
    }

    handleBalanceFailed() {
        const { dispatch } = reduxInstance.store;
        dispatch(userInfoActions.updateBalanceFailed());
    }

    unsubscribeFromBalanceUpdates() {
        if (!this.balanceService) {
            return;
        }
        this.balanceService.unsubscribeFromBalanceUpdates();
    }
}

export default new UserInfoDataRetriever();
