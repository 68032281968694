import MojitoPresentation from 'mojito/presentation';
import { range } from 'mojito/utils';

const { SkeletonPart, FlexPane } = MojitoPresentation.Components;

export default function GameLineSkeleton(props) {
    const {
        mojitoTools: { config },
    } = props;
    return (
        <FlexPane config={config.container}>
            <FlexPane config={config.selectionsContainer}>
                {range(config.marketsCount).map(i => (
                    <SkeletonPart key={i} config={config.selectionSkeleton} />
                ))}
            </FlexPane>
            <FlexPane config={config.selectionsContainer}>
                {range(config.marketsCount).map(i => (
                    <SkeletonPart key={i} config={config.selectionSkeleton} />
                ))}
            </FlexPane>
        </FlexPane>
    );
}
