import { Children, cloneElement } from 'react';
import { range } from 'mojito/utils';

/**
 * Contains utils specially needed for event list module.
 *
 * @class SwiperUtils
 * @memberof Mojito.Presentation.Components
 */
export default class SwiperUtils {
    /**
     * Creates slide id from index.
     *
     * @param {number} index - Slide index.
     *
     * @returns {number} Slide id.
     * @function Mojito.Presentation.Components.SwiperUtils.createSlideId
     */
    static createSlideId(index) {
        return `swiper-slide-${index}`;
    }

    /**
     * Returns the slide indices.
     *
     * @param {HTMLElement} [container] - Swiper container.
     *
     * @returns {{prevSlideIndex: number, nextSlideIndex: number}} Previous and Next slide indexes.
     * @function Mojito.Presentation.Components.SwiperUtils.getSlideIndices
     */
    static getSlideIndices(container) {
        const prevSlideIndex = SwiperUtils.getPrevVisibleChildIndex(container);
        const nextSlideIndex = SwiperUtils.getNextVisibleChildIndex(container);
        return { prevSlideIndex, nextSlideIndex };
    }

    /**
     * Parses slide id and returns slide index from it.
     *
     * @param {string} slideId - Slide id.
     *
     * @returns {number|NaN} Slide index.
     * @function Mojito.Presentation.Components.SwiperUtils.parseSlideId
     */
    static parseSlideId(slideId) {
        if (!slideId) {
            return NaN;
        }
        return Number(slideId.replace('swiper-slide-', ''));
    }

    /**
     * Check if container has scrollable content by comparing its clientWidth and scrollWidth.
     *
     * @param {HTMLElement} [container] - Swiper container.
     *
     * @returns {boolean} True if container is scrollable.
     * @function Mojito.Presentation.Components.SwiperUtils.isScrollable
     */
    static isScrollable(container) {
        return container?.clientWidth < container?.scrollWidth;
    }

    /**
     * Ensure children slides contains loop buffer in the head and tail of the list.
     * This loop buffer is generated by cloning react elements to ensure smooth loop scrolling.
     *
     * @param {Array<React.ReactElement>} children - List of React elements.
     * @param {object} [loopConfig] - Loop config.
     *
     * @returns {Array<React.ReactElement>} The list of React elements wrapped with buffer elements.
     * @function Mojito.Presentation.Components.SwiperUtils.ensureLoopBuffer
     */
    static ensureLoopBuffer(children, loopConfig = {}) {
        const { bufferLength = 0 } = loopConfig;
        const slides = Children.toArray(children);
        if (slides.length <= 1 || bufferLength === 0) {
            return slides;
        }
        const head = slides
            .slice(-bufferLength)
            .map(slide => cloneElement(slide, { key: `head-${slide.key}` }));
        const tail = slides
            .slice(0, bufferLength)
            .map(slide => cloneElement(slide, { key: `tail-${slide.key}` }));
        slides.unshift(...head);
        slides.push(...tail);
        return slides;
    }

    /**
     * Get the size of the loop buffer. Typically, equals to the size of viewable slides but no less than 2.
     *
     * @param {HTMLElement} [container] - Swiper container.
     *
     * @returns {number} The size of the buffer.
     * @function Mojito.Presentation.Components.SwiperUtils.getLoopBufferLength
     */
    static getLoopBufferLength(container) {
        return Math.max(SwiperUtils.getVisibleSlidesCount(container), 2);
    }

    /**
     * Get viewable children elements in container.
     *
     * @param {HTMLElement} container - Swiper container.
     * @param {boolean} [fullFit = false] - True if the function should only take into consideration the slides which are fully viewable.
     *
     * @returns {Array<HTMLElement>} The list of viewable slide elements.
     * @function Mojito.Presentation.Components.SwiperUtils.getVisibleChildren
     */
    static getVisibleChildren(container, fullFit = false) {
        if (!container) {
            return [];
        }
        const children = [...container.children];
        return children.filter(child => SwiperUtils.isChildInView(child, container, fullFit));
    }

    static getFirstVisibleSlide(container, loopConfig) {
        if (!container) {
            return;
        }
        const children = [...container.children];
        const visibleChild = children.find(child =>
            SwiperUtils.isChildInView(child, container, true)
        );
        const index = children.indexOf(visibleChild);
        return SwiperUtils.getSlideElement(container, index, loopConfig);
    }

    static isChildInView(child, container, fullFit) {
        const childRect = child?.getBoundingClientRect();
        const containerRect = container?.getBoundingClientRect();

        const offsetLeft = containerRect.left - childRect.left;
        const offsetRight = containerRect.right - childRect.right;
        // In case when browser window is zoomed in/out the element boundaries can have floating part.
        // Once this happens a child always has small offset to the right relatively to parent container
        // (this offset it not visual as it is getting rounded by GPU before rendering).
        // According to observations the offset is in range [0..2] unconditionally to browser and screen resolution.
        // In order to mitigate this issue the tolerance value will be used to define if child is in a container boundaries range.
        const TOLERANCE = 2;
        const getTolerance = offset => (Math.abs(offset) <= TOLERANCE ? TOLERANCE : 0);

        const leftInView = SwiperUtils.isInRange(
            childRect.left,
            containerRect.left,
            containerRect.right,
            getTolerance(offsetLeft)
        );
        const rightInView = SwiperUtils.isInRange(
            childRect.right - 1,
            containerRect.left,
            containerRect.right,
            getTolerance(offsetRight)
        );

        return fullFit ? leftInView && rightInView : leftInView || rightInView;
    }

    /**
     * Checks if num is between start and up to end.
     *
     * @param {number} num - Number to check.
     * @param {number} start - The start of the range.
     * @param {number} end - The end of the range.
     * @param {number} [tolerance = 0] - Tolerance number allows to adjust number spread in range.
     *
     * @returns {boolean} True if value is in range.
     * @function Mojito.Presentation.Components.SwiperUtils.isInRange
     */
    static isInRange(num, start, end, tolerance = 0) {
        const leftInRange = num + tolerance >= start;
        const rightInRange = num - tolerance < end;
        return leftInRange && rightInRange;
    }

    /**
     * Get element width using getBoundingClientRect.
     *
     * @param {Element} element - Element.
     *
     * @returns {number|undefined} Element width in pixels.
     * @function Mojito.Presentation.Components.SwiperUtils.getElementWidth
     */
    static getElementWidth(element) {
        return element?.getBoundingClientRect().width;
    }

    /**
     * Get viewable slides count. Utilizes {@link Mojito.Presentation.Components.SwiperUtils.getVisibleChildren|getVisibleChildren}.
     *
     * @param {HTMLElement} container - Swiper container.
     * @param {boolean} [fullFit = false] - True if the function should only take into consideration the slides which are fully viewable.
     *
     * @returns {number} Number of visible slides.
     * @function Mojito.Presentation.Components.SwiperUtils.getVisibleSlidesCount
     */
    static getVisibleSlidesCount(container, fullFit = false) {
        return SwiperUtils.getVisibleChildren(container, fullFit).length;
    }

    /**
     * Get offset left position of particular slide within a container. Utilizes {@link Mojito.Presentation.Components.SwiperUtils.getSlideElement|getSlideElement}
     * function to detect HTMLElement based on the slide index. The function can be useful to detect the position of the slide to scroll to.
     *
     * @param {HTMLElement} container - Swiper container.
     * @param {number} slideIndex - The index of the slide among other children in container.
     * @param {object} [loopConfig] - Loop config.
     *
     * @returns {number} Slide offset left in pixels.
     * @function Mojito.Presentation.Components.SwiperUtils.getSlideOffsetLeft
     */
    static getSlideOffsetLeft(container, slideIndex, loopConfig) {
        const slide = SwiperUtils.getSlideElement(container, slideIndex, loopConfig);
        return SwiperUtils.getChildOffsetLeft(container, slide, loopConfig?.centerViewableSlide);
    }

    /**
     * Checks if container can fit multiple fully viewable slides.
     * It assumes that slides have the same width.
     *
     * @param {HTMLElement} container - Swiper container.
     *
     * @returns {boolean} True if more then one slide can be fully visible in container viewable area.
     * @function Mojito.Presentation.Components.SwiperUtils.isMultiSlideView
     */
    static isMultiSlideView(container) {
        const slideWidth = SwiperUtils.getElementWidth(container.firstElementChild);
        const containerWidth = SwiperUtils.getElementWidth(container);
        return slideWidth * 2 <= containerWidth;
    }

    /**
     * Evaluates offset right position of particular slide within a container considering possible loop buffer size.
     * This function will evaluate offset for original slide element ignoring cloned slides for loop buffer.
     *
     * @param {HTMLElement} container - Swiper container.
     * @param {number} slideIndex - Slide index.
     * @param {object} [loopConfig] - Loop config.
     *
     * @returns {number} Slide offset right in pixels.
     * @function Mojito.Presentation.Components.SwiperUtils.getSlideOffsetRight
     */
    static getSlideOffsetRight(container, slideIndex, loopConfig) {
        const slide = SwiperUtils.getSlideElement(container, slideIndex, loopConfig);
        if (!slide) {
            return 0;
        }
        const slideOffset = slide.offsetLeft - container.offsetLeft;
        return Math.round(slideOffset + SwiperUtils.getElementWidth(slide));
    }

    /**
     * Get next visible child to the left.
     *
     * @param {HTMLElement} container - Swiper container.
     *
     * @returns {number} Child index to be shown next.
     * @function Mojito.Presentation.Components.SwiperUtils.getNextVisibleChildIndex
     */
    static getNextVisibleChildIndex(container) {
        if (!container) {
            return -1;
        }
        const children = [...container.children];
        const lastVisibleSlide = SwiperUtils.getVisibleChildren(container, true).pop();
        const nextIndex = children.indexOf(lastVisibleSlide) + 1;
        return nextIndex < children.length ? nextIndex : -1;
    }

    /**
     * Get previous visible child to the right.
     *
     * @param {HTMLElement} container - Swiper container.
     *
     * @returns {number} Child index to be shown next.
     * @function Mojito.Presentation.Components.SwiperUtils.getPrevVisibleChildIndex
     */
    static getPrevVisibleChildIndex(container) {
        if (!container) {
            return -1;
        }
        const children = [...container.children];
        const firstVisibleSlide = SwiperUtils.getVisibleChildren(container, true)[0];
        return children.indexOf(firstVisibleSlide) - 1;
    }

    /**
     * Evaluates offset center position of particular child within a container.
     *
     * @param {HTMLElement} container - Swiper container.
     * @param {number} index - Child index.
     *
     * @returns {number} Child offset center in pixels.
     * @function Mojito.Presentation.Components.SwiperUtils.getChildOffsetCenter
     */
    static getChildOffsetCenter(container, index) {
        const children = [...container.children];
        const child = children[index];
        if (!child) {
            return 0;
        }
        const childOffset = child.offsetLeft - container.offsetLeft;
        return Math.round(childOffset + SwiperUtils.getElementWidth(child) / 2);
    }

    /**
     * Scrolls container to original slide. Ignores slides generated to support swiper loop.
     *
     * @param {HTMLElement} container - Swiper container.
     * @param {number} index - Slide index to scroll to.
     * @param {object} loopConfig - Loop config.
     *
     * @function Mojito.Presentation.Components.SwiperUtils.scrollToSlide
     */
    static scrollToSlide(container, index, loopConfig) {
        if (!container) {
            return;
        }
        const scrollX = SwiperUtils.getSlideOffsetLeft(container, index, loopConfig);
        container.scrollTo({ left: scrollX, behavior: 'instant' });
    }

    /**
     * Scrolls container to child.
     *
     * @param {HTMLElement} container - Swiper container.
     * @param {number} index - Slide index to scroll to.
     * @param {object} loopConfig - Loop config.
     * @param {string} behavior - Scrolling behaviour supported by CSS.
     * @param {string} toBound - Bound to scroll to. Supported values are 'left' and 'center'.
     *
     * @function Mojito.Presentation.Components.SwiperUtils.scrollToChild
     */
    static scrollToChild(container, index, loopConfig, behavior, toBound = 'left') {
        if (!container) {
            return;
        }
        const child = [...container.children][index];
        const { centerViewableSlide } = loopConfig || {};
        const scrollerPositionDetector = {
            'left': () => SwiperUtils.getChildOffsetLeft(container, child, centerViewableSlide),
            'center': () => {
                return (
                    SwiperUtils.getChildOffsetLeft(container, child, centerViewableSlide) -
                    container.clientWidth / 2 +
                    SwiperUtils.getElementWidth(child) / 2
                );
            },
        };
        const scrollX = scrollerPositionDetector[toBound]();
        scrollX !== undefined && container.scrollTo({ left: scrollX, behavior });
    }

    /**
     * Resolves ready to load slide indexes. Those are the slides which are currently in a view port and slides which are
     * going to be shown next if user swipes left or right (ready to show up slides).
     *
     * @param {number} lazyBufferSize - The amount of ready to show up slides.
     * @param {number} slidesLength - Total amount of slides.
     * @param {Array<number>} viewableSlideIndexes - The list of currently viewable slide indexes.
     * @param {boolean} loop - Flag defines if swiper is in loop mode.
     *
     * @returns {Array<number>} The list of slide indexes that are ready to show up.
     * @function Mojito.Presentation.Components.SwiperUtils.enrichWithReadyToShowSlideIndexes
     */
    static enrichWithReadyToShowSlideIndexes(
        lazyBufferSize,
        slidesLength,
        viewableSlideIndexes,
        loop
    ) {
        if (!viewableSlideIndexes?.length) {
            return viewableSlideIndexes;
        }
        if (lazyBufferSize < 0) {
            return range(slidesLength);
        }
        const firstSlide = viewableSlideIndexes[0];
        const lastSlide = viewableSlideIndexes[viewableSlideIndexes.length - 1];
        const leftBuffer = [];
        const rightBuffer = [];
        for (let i = 1; i <= lazyBufferSize; i++) {
            const stepLeftIndex = firstSlide - i;
            const leftInBuffer =
                stepLeftIndex < 0 && loop ? slidesLength + stepLeftIndex : stepLeftIndex;
            leftInBuffer >= 0 && leftBuffer.unshift(leftInBuffer);
        }
        for (let i = 1; i <= lazyBufferSize; i++) {
            const stepRightIndex = lastSlide + i;
            const rightInBuffer =
                stepRightIndex >= slidesLength && loop
                    ? stepRightIndex - slidesLength
                    : stepRightIndex;
            rightInBuffer < slidesLength && rightBuffer.push(rightInBuffer);
        }
        return [...leftBuffer, ...viewableSlideIndexes, ...rightBuffer];
    }

    /**
     * Evaluates offset left position of particular child within a container.
     *
     * @private
     * @param {HTMLElement} container - Swiper container.
     * @param {HTMLElement} child - Child element.
     * @param {boolean} [toCenter = false] - True if child should be centralized within single slide view container.
     *
     * @returns {number|undefined} Child offset left in pixels.
     * @function Mojito.Presentation.Components.SwiperUtils.getChildOffsetLeft
     */
    static getChildOffsetLeft(container, child, toCenter = false) {
        if (!container || !child) {
            return;
        }
        const toCenterGap = toCenter ? SwiperUtils.getChildCenteredScrollGap(container, child) : 0;
        const slideOffset = child.offsetLeft - container.offsetLeft;
        return slideOffset - toCenterGap;
    }

    /**
     * Evaluates offset gap to center the child within a container for single slide view.
     *
     * @private
     * @param {HTMLElement} container - Swiper container.
     * @param {HTMLElement} child - Child element.
     *
     * @returns {number} Child offset center in pixels.
     * @function Mojito.Presentation.Components.SwiperUtils.getChildCenteredScrollGap
     */
    static getChildCenteredScrollGap(container, child) {
        const containerWidth = SwiperUtils.getElementWidth(container);
        return (containerWidth - SwiperUtils.getElementWidth(child)) / 2;
    }

    /**
     * Resolves slide element from origin slides list.
     * This function will return original slide element ignoring cloned slides for loop buffer.
     *
     * @private
     * @param {HTMLElement} container - Swiper container.
     * @param {number} index - Child element.
     * @param {object} [loopConfig = {}] - Loop config.
     *
     * @returns {HTMLElement|undefined} Slide element.
     * @function Mojito.Presentation.Components.SwiperUtils.getSlideElement
     */
    static getSlideElement(container, index, loopConfig = {}) {
        if (!container) {
            return;
        }
        const children = [...container.children];
        const { bufferLength = 0 } = loopConfig;
        const originSlides = children.slice(bufferLength, children.length - bufferLength);
        return originSlides[index];
    }
}
