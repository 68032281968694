import Image from 'presentation/components/image/index.jsx';
import FlexPane from 'presentation/components/flex-pane/index.jsx';

const getIcon = (sportId, { countryIcons, sportIcons }, countryCode) =>
    countryIcons[countryCode] || sportIcons[sportId] || sportIcons.defaultIcon;

const CountryIcon = ({ sportId, countryCode, countryName, mojitoTools }) => {
    const { config, appContext, stringResolver } = mojitoTools;
    const sportName = appContext.sportName(sportId);
    const altText = countryName
        ? stringResolver.resolveAndFormatString(`$GENERIC.FLAG_OF`, countryName)
        : sportName;

    return (
        <FlexPane config={config.container}>
            <Image config={config.icon} src={getIcon(sportId, config, countryCode)} alt={altText} />
        </FlexPane>
    );
};

export default CountryIcon;
