import PropTypes from 'prop-types';
import MojitoCore from 'mojito/core';
import AppControllerSportsbookImpl from './app-controller-sportsbook.jsx';
const { UIControllerView } = MojitoCore.Presentation;

const propTypes = {
    location: PropTypes.object.isRequired,
    customRoutes: PropTypes.arrayOf(
        PropTypes.shape({
            path: PropTypes.string,
            component: PropTypes.func,
        })
    ),
};

const defaultProps = {
    customRoutes: [],
};

export default UIControllerView(
    'AppControllerSportsbook',
    AppControllerSportsbookImpl,
    propTypes,
    defaultProps
);
