import MojitoCore from 'mojito/core';
import MojitoModules from 'mojito/modules';
import MojitoServices from 'mojito/services';

import {INTENT_TYPES} from '#core/utils/intent-types.js';

const {dispatch} = MojitoCore.Services.redux.store;
const reduxInstance = MojitoCore.Services.redux;
const loginActions = MojitoModules.Login.actions;
const loginHelper = MojitoModules.Login.helper;
const IntentActions = MojitoCore.Intents.actions;
const authenticationActions = MojitoServices.Authentication.actions;

class ActionsProxyStorage {
    constructor() {
        this.wasLoggedIn = false; // it is needed to skip first logout (from undefined login state)
        this.onLoginHandler = null; // Intent or function
        this.onLogoutHandler = null;
        this.onDepositHandler = null;
        this.onNavigateHostHandler = null;

        reduxInstance.actionListener.startListening({
            actionCreator: loginActions.showLoginView,
            effect: this.handleShowLoginView.bind(this),
        });

        reduxInstance.actionListener.startListening({
            actionCreator: authenticationActions.loginSuccess,
            effect: this.handleLoginSuccess.bind(this),
        });

        reduxInstance.actionListener.startListening({
            actionCreator: authenticationActions.logoutSuccess,
            effect: this.handleLogout.bind(this),
        });

        reduxInstance.actionListener.startListening({
            actionCreator: IntentActions.publishIntent,
            effect: this.handlePublishIntent.bind(this),
        });
    }

    handleShowLoginView() {
        if (this.onLoginHandler) {
            invokeHandler(this.onLoginHandler);
            return false;
        }
    }

    handleLoginSuccess() {
        this.wasLoggedIn = true;
    }

    handleLogout() {
        if (!this.wasLoggedIn) return false; // Never been logged in, so its logout from undefined state

        if (this.onLogoutHandler) {
            invokeHandler(this.onLogoutHandler);
            return false;
        }
    }

    handlePublishIntent(action) {
        const {payload: intent} = action;
        // in case there was intent published that forces user to login,
        // we will show login view unless user is already authenticated
        const isLoginRequired = loginHelper.isLoginRequired(intent.type);
        if (isLoginRequired) {
            return this.handleShowLoginView();
        }

        switch (intent.type) {
            case INTENT_TYPES.PROXY_SHOW_LOGIN: {
                return this.handleShowLoginView();
            }

            case INTENT_TYPES.PROXY_SHOW_DEPOSIT: {
                if (this.onDepositHandler) {
                    invokeHandler(this.onDepositHandler);
                    return false;
                }
                return false;
            }

            case INTENT_TYPES.NAVIGATE_HOST: {
                const data = intent.data || {};
                if (!this.onNavigateHostHandler) {
                    //window.location.assign(data.url); // Do nothing in no host configured
                } else {
                    invokeHandler(this.onNavigateHostHandler, data.url);
                }
            }
        }
    }

    // SHOW_LOGIN
    setShowLoginHandler(intent, configureLoginStore = false) {
        this.onLoginHandler = intent;

        if (configureLoginStore) {
            dispatch(loginActions.configure({ignoreShowLoginView: !!intent}));
        }
    }
    removeShowLoginHandler() {
        this.onLoginHandler = null;
    }

    // LOG_OUT
    setLogoutHandler(intent) {
        this.onLogoutHandler = intent;
    }
    removeLogoutHandler() {
        this.onLogoutHandler = null;
    }

    // SHOW_DEPOSIT
    setDepositHandler(intent) {
        this.onDepositHandler = intent;
    }
    removeDepositHandler() {
        this.onDepositHandler = null;
    }

    // NAVIGATE
    setNavigateHostHandler(handler) {
        this.onNavigateHostHandler = handler;
    }
    removeNavigateHostHandler() {
        this.onNavigateHostHandler = null;
    }
}

function invokeHandler(handler, ...params) {
    if (!handler) {
        return;
    }

    switch (typeof handler) {
        case 'object':
            // it is an intent
            dispatch(IntentActions.publishIntent(handler.type, handler.data));
            break;
        case 'function':
            handler(...params);
            break;
        default:
        // do nothing
    }
}

// Always has instance
export const actionsProxyStorage = new ActionsProxyStorage();
