import {AbstractFeature, allFeatures} from '#core/application/abstract-feature.js';

/**
 * This feature reloads Sportsbook when the user stays on the page for a long time
 */
class ReloaderFeature extends AbstractFeature {
    constructor(parent) {
        super(parent);

        this.handleVisibilityChange = this.handleVisibilityChange.bind(this);
    }

    get name() {
        return 'Reloader';
    }

    afterMount() {
        document.addEventListener('visibilitychange', this.handleVisibilityChange);
    }

    beforeUnmount() {
        document.removeEventListener('visibilitychange', this.handleVisibilityChange);
    }

    handleVisibilityChange() {
        // reload the page if the current document's lifetime > 2 days
        if (document.visibilityState === 'visible' && performance.now() >= 172800000) {
            window.location.reload();
        }
    }
}

new ReloaderFeature(allFeatures);
