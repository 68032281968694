import AbstractCashoutService from './abstract-cashout-service';
import MojitoCore from 'mojito/core';
import ApiRequestProvider from 'services/common/api-request/request-provider.js';

const TransactionsTypes = MojitoCore.Services.Transactions.types;
const { ERROR_CODE } = TransactionsTypes;

const BASE_URL = '/bets';

/**
 * Cashout service config.
 *
 * @typedef CashoutServiceConfig
 * @type {object}
 * @property {string} serviceUrl - API endpoint URL.
 *
 * @memberof Mojito.Services.Bets.BaseCashoutService
 */

/**
 * BaseCashoutService constructor.
 *
 * @class BaseCashoutService
 * @param {Mojito.Services.Bets.types.CASHOUT_PROTOCOLS} protocol - Transport protocol to be used for cash out offers fetch.
 * @classdesc Class offering the possibility to interact with the mojito bets cash out API.
 * Request cash out offers and perform early cash out of desired bet.
 * @extends Mojito.Services.Bets.AbstractCashoutService
 * @memberof Mojito.Services.Bets
 */
export default class BaseCashoutService extends AbstractCashoutService {
    constructor(protocol) {
        super();
        this.protocol = protocol;
        this.logger = MojitoCore.logger.get(this.constructor.name);
    }
    /**
     * Configure service.
     *
     * @param {{serviceUrl: string}} config - Service configuration object containing API endpoint <code>serviceUrl</code>.
     *
     * @function Mojito.Services.Bets.BaseCashoutService#configure
     */
    configure({ serviceUrl }) {
        this.serviceUrl = serviceUrl;
        this.requestFactory = new ApiRequestProvider(this.logger);
        this.apiUrl = `${this.serviceUrl + BASE_URL}`;
        this.isTransportValid = false;
    }

    validateCashoutTransport() {
        if (this.isTransportValid) {
            return Promise.resolve();
        }
        return this.getCashoutCapabilities().then(({ protocols }) => {
            this.isTransportValid = protocols.includes(this.protocol);
            if (!this.isTransportValid) {
                const supportedProtocols = protocols.join(', ');
                this.logger.error(
                    `${this.protocol} protocol is not supported by betting API. Supported protocols: ${supportedProtocols}`
                );
                return Promise.reject({ type: ERROR_CODE.UNSUPPORTED_PROTOCOL });
            }
            return Promise.resolve();
        });
    }

    invalidateCashoutTransport() {
        this.isTransportValid = false;
    }

    /**
     * Validate service url.
     *
     * @private
     * @returns {boolean} True if service url exists, else false.
     */
    validateServiceUrl() {
        if (!this.serviceUrl) {
            this.logger.warn('serviceUrl is missing.');
            return false;
        }
        return true;
    }

    /**
     * Returns the cashout capabilities.
     *
     * @private
     * @returns {Promise} Get cashout capabilities promise.
     */
    getCashoutCapabilities() {
        if (!this.validateServiceUrl()) {
            return Promise.reject();
        }
        // Wrap with real promise as our Request implementation doesn't support chaining.
        return new Promise((resolve, reject) => {
            this.requestFactory
                .get(`${this.apiUrl}/cashout-capabilities`)
                .withDescription('get cashout capabilities')
                .withCredentials()
                .send()
                .then(resolve)
                .catch(reject);
        });
    }

    cashout(betInfo, amount) {
        if (!this.validateServiceUrl()) {
            return Promise.reject();
        }
        return this.requestFactory
            .post(`${this.apiUrl}/cashout`)
            .withDescription('cashout bet')
            .withCredentials()
            .send({ betInfo, amount });
    }
}
