import Image from 'presentation/components/image/index.jsx';
import { useEffect } from 'react';

const TeamIcon = props => {
    const {
        mojitoTools: { config, stringResolver },
        sportCode,
        teamId,
        title,
        onInit = () => {},
        onLoad,
        onError,
    } = props;

    const { enabledSports = [], teamIcons = {} } = config;
    const isSportEnabled = enabledSports.includes(sportCode);

    const src = teamIcons[teamId] ? teamIcons[teamId] : teamIcons['default'];
    const altText = teamIcons[teamId] ? title : stringResolver.resolveString('$GENERIC.DEFAULT');

    useEffect(() => {
        onInit({
            src,
            teamId,
            hasTeamIconMapping: teamIcons[teamId] ? true : false,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [teamId]);

    return isSportEnabled && src ? (
        <div className="ta-TeamIcon" style={config.container}>
            <Image
                config={config.image}
                src={src}
                title={title}
                onLoad={onLoad}
                onError={onError}
                alt={altText}
            />
        </div>
    ) : null;
};

export default TeamIcon;
