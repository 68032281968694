import MojitoCore from 'mojito/core';
import TaskRunner from 'services/common/task-runner/task-runner';
import BonusTypes from 'services/bonus/types.js';
import { actions as bonusCampaignActions } from 'services/bonus-campaign/slice.js';
import { actions as userBonusesActions } from './slice.js';
import PollFreeBetsTask from './task/poll-free-bets-task';

const { dispatch } = MojitoCore.Services.redux.store;
const log = MojitoCore.logger.get('UserBonusesStore');
const DEFAULT_FREE_BETS_POLL_INTERVAL = 10 * 1000;

/**
 * @typedef UserBonusesDataRetrieverConfig
 * @type {object}
 * @property {Mojito.Services.Bonus.AbstractBonusService} service - Bonus service.
 * @property {number} [pollFreeBetsIntervalSec = 10] - Poll period in seconds to trigger a new free bets request. Default poll period is 10 seconds. If set to 0 the polling task will be disabled.
 * @memberof Mojito.Services.UserBonuses.dataRetriever
 */

/**
 * Data retriever used delegate calls to load user bonuses(free bets, odds boosts) from Bonus service.
 * Will poll free bets periodically (if configured) when triggered.
 *
 * @class UserBonusesDataRetriever
 * @name dataRetriever
 * @memberof Mojito.Services.UserBonuses
 */
class UserBonusesDataRetriever {
    /**
     * Initialize data retriever.
     *
     * @param {Mojito.Services.UserBonuses.dataRetriever.UserBonusesDataRetrieverConfig} config - Config.
     * @function Mojito.Services.UserBonuses.dataRetriever#init
     */
    init(config) {
        if (!config) {
            return;
        }
        this.bonusService = config.service;

        if (config.pollFreeBetsIntervalSec !== 0) {
            this.pollFreeBetsInterval = config.pollFreeBetsIntervalSec
                ? config.pollFreeBetsIntervalSec * 1000
                : DEFAULT_FREE_BETS_POLL_INTERVAL;
            this.freeBetsTaskRunner = new TaskRunner(this.pollFreeBetsInterval);
        }
    }

    hasService() {
        return Boolean(this.bonusService);
    }

    hasTaskRunner() {
        return Boolean(this.freeBetsTaskRunner);
    }

    /**
     * Initiates an additional fetch request to the bonus service.
     * To create the task, you should call {@link Mojito.Services.UserBonuses.dataRetriever#pollFreeBets}.
     *
     * @function Mojito.Services.UserBonuses.dataRetriever#fetchUserBonuses
     */
    fetchFreeBets() {
        if (!this.hasService() || !this.hasTaskRunner() || !this.pollFreeBetsTask) {
            return;
        }
        this.freeBetsTaskRunner.run(this.pollFreeBetsTask);
    }

    /**
     * Create polling task with currency and initiate free bets polling.
     *
     * @param {string} currency - Currency.
     * @function Mojito.Services.UserBonuses.dataRetriever#pollFreeBets
     */
    pollFreeBets(currency) {
        if (!this.hasService() || !this.hasTaskRunner()) {
            return;
        }
        this.pollFreeBetsTask = new PollFreeBetsTask(this.bonusService, currency);
        this.freeBetsTaskRunner.run(this.pollFreeBetsTask);
    }

    /**
     * Stops free bets polling.
     *
     * @function Mojito.Services.UserBonuses.dataRetriever#stopPollingFreeBets
     */
    stopPollingFreeBets() {
        if (!this.hasService() || !this.hasTaskRunner()) {
            return;
        }
        this.freeBetsTaskRunner.reset();
    }

    /**
     * Get active user bonuses.
     *
     * @param {string} currency - Currency.
     * @function Mojito.Services.UserBonuses.dataRetriever#getActiveUserBonuses
     */
    getActiveUserBonuses(currency) {
        if (!this.hasService()) {
            return;
        }

        this.bonusService
            .getUserBonuses(currency, BonusTypes.BONUS_FILTER.ACTIVE)
            .then(bonuses => dispatch(userBonusesActions.fetchActiveUserBonusesSuccess(bonuses)))
            .catch(({ message = '', type }) => {
                log.warn(`Failed to fetch user bonuses. Error: ${type} ${message}`);
            });
    }

    /**
     * Get active free bets.
     *
     * @param {string} currency - Currency.
     * @function Mojito.Services.UserBonuses.dataRetriever#getActiveFreeBets
     */
    getActiveFreeBets(currency) {
        if (!this.hasService()) {
            return;
        }

        this.bonusService
            .getFreeBets(currency, BonusTypes.BONUS_FILTER.ACTIVE)
            .then(bonuses => dispatch(userBonusesActions.fetchActiveFreeBetsSuccess(bonuses)))
            .catch(({ message = '', type }) => {
                log.warn(`Failed to fetch free bets. Error: ${type} ${message}`);
            });
    }

    /**
     * Get historic user bonuses.
     *
     * @param {string} currency - Currency.
     * @function Mojito.Services.UserBonuses.dataRetriever#getHistoricUserBonuses
     */
    getHistoricUserBonuses(currency) {
        if (!this.hasService()) {
            return;
        }

        this.bonusService
            .getUserBonuses(currency, BonusTypes.BONUS_FILTER.HISTORIC)
            .then(bonuses => dispatch(userBonusesActions.fetchHistoricUserBonusesSuccess(bonuses)))
            .catch(({ message = '', type }) => {
                log.warn(`Failed to fetch user bonuses. Error: ${type} ${message}`);
                dispatch(userBonusesActions.fetchHistoricUserBonusesFailure());
            });
    }

    /**
     * Add free bet code.
     *
     * @param {string} freeBetCode - Free bet code id.
     * @function Mojito.Services.UserBonuses.dataRetriever#addFreeBet
     */
    addFreeBet(freeBetCode) {
        if (!this.hasService()) {
            return;
        }

        this.bonusService
            .addFreeBet(freeBetCode)
            .then(() => {
                dispatch(userBonusesActions.addFreeBetSuccess());
                dispatch(userBonusesActions.fetchActiveFreeBets());
                dispatch(bonusCampaignActions.getCampaigns(BonusTypes.BONUS_FILTER.ACTIVE));
            })
            .catch(error => dispatch(userBonusesActions.addFreeBetFailure(error)));
    }
}

export default new UserBonusesDataRetriever();
