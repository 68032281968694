import MainNavigationBarImpl from './main-navigation-bar.jsx';
import MojitoCore from 'mojito/core';

const UIView = MojitoCore.Presentation.UIView;

/**
 * Main navigation bar offering the user to do overall application navigation.
 * <br><br>
 * It can be configured to show session time, a settings button and a help button.
 *
 * @class MainNavigationBar
 * @memberof Mojito.Applications.Sports
 */

export default UIView('MainNavigationBar', MainNavigationBarImpl);
