/**
 * Application types.
 *
 * @class ApplicationTypes
 * @name types
 * @memberof Mojito.Applications.Sports
 */
export default class ApplicationTypes {}

/**
 * Types of conditions to show sliding betslip.
 *
 * @typedef SHOW_BETSLIP_CONDITION
 * @property {string} ADD_FIRST_SELECTION - Will open when a first selection is added.
 * @property {string} ADD_FIRST_PREPLAY_SELECTION - Will open when a first upcoming selection is added.
 * @property {string} ADD_FIRST_PREPLAY_SELECTION - Will only open when first inplay selection is added.
 * @memberof Mojito.Applications.Sports.types
 */
ApplicationTypes.SHOW_BETSLIP_CONDITION = {
    ADD_FIRST_SELECTION: 'ADD_FIRST_SELECTION',
    ADD_FIRST_PREPLAY_SELECTION: 'ADD_FIRST_PREPLAY_SELECTION',
    ADD_FIRST_INPLAY_SELECTION: 'ADD_FIRST_INPLAY_SELECTION',
};
