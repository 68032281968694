/*
 * NOTICE: It is CommonJS module format to be able to use by webpack
 */

const {isValidCustomStrategy, isValidIntentStrategy} = require('./intent-validation.cjs');

const DATA_TYPE = {
    ANY: 'any',
    INT: 'int',
    BOOL: 'bool',
    NUMBER: 'number',
    STRING: 'string',
    MAP: 'map',
    LIST: 'list',
    REGEXP: 'regexp',
    ROOT: 'root',
    TEXT_STYLE: 'text-style',
    FONT_STYLE: 'font-style',
    COLOR: 'color',
    BACKGROUND: 'background',
    INTENT: 'intent',
    LABELED_ACTION: 'labeled-action',
    TILE: 'tile',
};

const DATA_TYPE_LIST_PARSER_MAP = {
    [DATA_TYPE.INT]: parseInteger,
    [DATA_TYPE.NUMBER]: parseNumber,
    [DATA_TYPE.BOOL]: parseBool,
    [DATA_TYPE.STRING]: parseString,
    [DATA_TYPE.REGEXP]: parseRegExp,
    [DATA_TYPE.INTENT]: parseIntent,
    [DATA_TYPE.LABELED_ACTION]: parseLabeledAction,
};

const LINK_TYPES = {
    PRIMARY: 'Primary',
    SECONDARY: 'Secondary',
    TERTIARY: 'Tertiary',
};

const FALLBACK_FN = {
    AUTO_LIGHTEN_DARKEN: 'auto-lighten-darken',
    OPACITY: 'set-opacity',
    DESATURATE: 'desaturate',
};

const COLOR_CHOOSE_STRATEGY = {
    EXACT: 'exact',
    MIN_CONTRAST: 'min-contrast',
    MAX_CONTRAST: 'max-contrast',
    FIRST_WITH_CONTRAST: 'first-with-contrast', // Choose first that fulfill contrast ratio requirements
};

const ALLOWED_ROLES = {
    SEO_MANAGER: 'seo manager',
    BRAND_STYLIST: 'brand stylist',
    DEVELOPER: 'developer',
};

function capitalize(s) {
    return s.charAt(0).toUpperCase() + s.slice(1);
}

function parse(value, isRequired, defaultVal, fullPath, typeStr, rx) {
    if (value === '' || value === null || value === undefined) {
        if (isRequired) {
            return {
                error: `${capitalize(typeStr)} value must be not empty: "${fullPath}".`,
            };
        } else {
            return {
                value: defaultVal,
            };
        }
    }

    if (typeof value === typeStr) {
        return {
            value,
        };
    }
    if (typeof value !== 'string') {
        return {
            error: `Value is not a ${typeStr}: "${fullPath}".`,
        };
    }

    if (!rx.test(value)) {
        return {
            error: `Value is not a ${typeStr}: "${fullPath}".`,
        };
    }

    return {
        value: JSON.parse(value),
    };
}

function parseBool(value, isRequired, defaultVal, fullPath) {
    return parse(value, isRequired, defaultVal, fullPath, 'boolean', /^false$|^true$/);
}

function parseInteger(value, isRequired, defaultVal, fullPath) {
    return parse(value, isRequired, defaultVal, fullPath, 'number', /^-?\d*$/);
}

function parseNumber(value, isRequired, defaultVal, fullPath) {
    return parse(value, isRequired, defaultVal, fullPath, 'number', /^-?\d*\.?\d+(?:[Ee][+-]?\d+)?$/);
}

function parseString(value, isRequired, defaultVal, fullPath) {
    if (value === '' || value === null || value === undefined) {
        if (isRequired) {
            return {
                error: `String value must be not empty: "${fullPath}".`,
            };
        } else {
            return {
                value: defaultVal,
            };
        }
    }

    if (typeof value === 'string') {
        return {
            value,
        };
    }
    return {
        value: JSON.stringify(value),
    };
}

// Return value is always an object
function parseText(value, isRequired, defaultVal, fullPath) {
    if (value === '' || value === null || value === undefined) {
        if (isRequired) {
            return {
                error: `Text value must not be empty: "${fullPath}".`,
            };
        } else {
            return {
                value: defaultVal,
            };
        }
    }

    if (typeof value === 'string') {
        return {
            value: {...defaultVal, color: value},
        };
    } else if (typeof value === 'object' && !Array.isArray(value)) {
        return {
            value: {...defaultVal, ...value},
        };
    } else {
        return {
            error: `Text style value must be string or object: "${fullPath}".`,
        };
    }
}

function parseFontStyle(value, isRequired, defaultVal = {}, fullPath) {
    if (value === null || value === undefined) {
        if (isRequired) {
            return {
                error: `Font style value must not be empty: "${fullPath}".`,
            };
        } else {
            return {
                value: defaultVal,
            };
        }
    }

    if (typeof value === 'object' && !Array.isArray(value)) {
        return {
            value: {...defaultVal, ...value},
        };
    } else {
        return {
            error: `Font style value must be an object: "${fullPath}".`,
        };
    }
}

function parseRegExp(value, isRequired, defaultVal, fullPath) {
    if (value === '' || value === null || value === undefined) {
        if (isRequired) {
            return {
                error: `RegExp value must be not empty: "${fullPath}".`,
            };
        } else {
            if (typeof defaultVal === 'string') {
                try {
                    return {
                        value: new RegExp(defaultVal),
                    };
                } catch {
                    return {
                        error: `Default value for "${fullPath}" is not a RegExp.`,
                    };
                }
            }
            return {
                value: defaultVal,
            };
        }
    }

    try {
        return {
            value: new RegExp(value),
        };
    } catch (e) {
        return {
            error: `Value is not a RegExp: "${fullPath}".`,
        };
    }
}

function parseList(value, isRequired, defaultVal, listItemsType, fullPath) {
    if (value === '' || value === null || value === undefined) {
        if (isRequired) {
            return {
                error: `List must be not empty: "${fullPath}".`,
            };
        } else {
            return {
                value: defaultVal,
            };
        }
    }

    if (!Array.isArray(value)) {
        return {
            error: `Value is not a list: "${fullPath}".`,
        };
    }

    // if list doesn't contain specified type we return not validated items
    if (!listItemsType) {
        return {
            value,
        };
    }

    const listItemParser = DATA_TYPE_LIST_PARSER_MAP[listItemsType];

    if (!listItemParser) {
        return {
            error: `List with items type "${listItemsType}" cannot be validated: "${fullPath}". Supported types: ${Object.keys(DATA_TYPE_LIST_PARSER_MAP).join(', ')}`,
        };
    }

    let itemWithError;
    const parsedList = value.map((listItem, index) => {
        const parsedItem = listItemParser(listItem, false, undefined, `${fullPath}.${index}`);

        if (!itemWithError && parsedItem.error) itemWithError = parsedItem;

        return parsedItem.value;
    });

    if (itemWithError) {
        return itemWithError;
    }

    return {
        value: parsedList,
    };
}

function parseIntent(value, isRequired, defaultVal, fullPath) {
    if (value === '' || value === null || value === undefined) {
        if (isRequired) {
            return {
                error: `Intent value must not be empty: "${fullPath}".`,
            };
        } else {
            return {
                value: defaultVal,
            };
        }
    }

    if (!(typeof value === 'string' || typeof value === 'object')) {
        return {
            error: `Intent value must be string or object: "${fullPath}".`,
        };
    }

    if (typeof value === 'string') {
        const [openStrategy, urlTemplate, params] = value.split('|');

        value = {openStrategy, urlTemplate, params};
    }

    const {openStrategy, urlTemplate} = value;

    if (!(openStrategy === 'auto' || isValidIntentStrategy(openStrategy) || isValidCustomStrategy(openStrategy))) {
        return {
            error: `Intent strategy value ("${openStrategy}") is unknown: "${fullPath}".`,
        };
    }

    if (!urlTemplate) {
        return {
            error: `Intent url template value must not be empty: "${fullPath}".`,
        };
    }

    return {
        value,
    };
}

function parseLabeledAction(value, isRequired, defaultVal, fullPath) {
    if (value === '' || value === null || value === undefined) {
        if (isRequired) {
            return {
                error: `Labeled action value must not be empty: "${fullPath}".`,
            };
        } else {
            return {
                value: defaultVal,
            };
        }
    }

    if (typeof value !== 'object' || !value.intent || !value.label) {
        return {
            error: `Labeled action value must be an object and contain at least intent and label values: "${fullPath}".`,
        };
    }

    const newValue = {...value};
    const parsedIntent = parseIntent(value.intent, true, null, `${fullPath}.intent`);

    if (parsedIntent.error) {
        return parsedIntent;
    }

    newValue.intent = parsedIntent.value;

    // TODO: rework label usage here

    return {
        value: newValue,
    };
}

module.exports = {
    LINK_TYPES,
    DATA_TYPE,
    FALLBACK_FN,
    COLOR_CHOOSE_STRATEGY,
    ALLOWED_ROLES,
    DATA_TYPE_LIST_PARSER_MAP,
    parseBool,
    parseInteger,
    parseNumber,
    parseString,
    parseText,
    parseFontStyle,
    parseRegExp,
    parseList,
    parseIntent,
    parseLabeledAction,
};
