import MojitoPresentation from 'mojito/presentation';
import MojitoServices from 'mojito/services';

const { CurrencyHelper } = MojitoPresentation.Utils;
const BonusType = MojitoServices.Bonus.types.BONUS_TYPE;
const BonusStatus = MojitoServices.Bonus.types.STATUS;
const { Image, FlexPane, DateTime, Text } = MojitoPresentation.Components;

const bonusToIconName = {
    [BonusType.FREEBET]: 'freeBetIcon',
    [BonusType.PREWAGER]: 'prewagerIcon',
    [BonusType.CASH]: 'cashIcon',
    [BonusType.ODDS_BOOST]: 'oddsBoostIcon',
    [BonusType.ODDS_MULTIPLIER]: 'oddsBoostIcon',
};

const isOddsBoost = bonusType =>
    bonusType === BonusType.ODDS_BOOST || bonusType === BonusType.ODDS_MULTIPLIER;

export default function BonusCard(props) {
    const {
        bonus,
        shouldShowRemainingAmount,
        renderProgressContent,
        currencyCode,
        mojitoTools: { config, stringResolver },
    } = props;
    const bonusIcon = bonusToIconName[bonus.bonusType];

    function renderHeaderStatus() {
        return (
            <Text config={config.statusText} class="ta-BonusStatus">
                {resolveItemStatusLabel(bonus.status, stringResolver)}
            </Text>
        );
    }

    function renderHeaderAmount() {
        return (
            <Text config={config.statusText} class="ta-BonusTotalAmount">
                {CurrencyHelper.formatCurrency(bonus.amount, currencyCode)}
            </Text>
        );
    }

    function renderBonusAmount() {
        const commonProps = { bonus, config, stringResolver };
        return isOddsBoost(bonus.bonusType) ? (
            <OddsBoostAmount {...commonProps} />
        ) : (
            <BonusAmount
                currencyCode={currencyCode}
                shouldShowRemainingAmount={shouldShowRemainingAmount}
                {...commonProps}
            />
        );
    }

    function getItemExpireText(expireDate, hasExpired) {
        const expireLabel = hasExpired ? '$BONUS_CARD.EXPIRED' : '$BONUS_CARD.EXPIRY';
        return stringResolver.resolveAndFormatString(expireLabel, expireDate);
    }

    const showHeaderAmount = isOddsBoost(bonus.bonusType) && bonus.amount > 0;
    return (
        <FlexPane config={config.container}>
            <FlexPane config={config.headerContainer}>
                <FlexPane config={config.nameAndStatusContainer}>
                    {bonusIcon && <Image config={config[bonusIcon]} />}
                    <Text config={config.headerText}>{bonus.name}</Text>
                    {showHeaderAmount ? renderHeaderAmount() : renderHeaderStatus()}
                </FlexPane>
                {bonus.expireDate && (
                    <Text config={config.expireDateText}>
                        <DateTime
                            config={config.expireDateFormat}
                            dateTime={bonus.expireDate}
                            dateTimeRenderer={getItemExpireText}
                            rendererData={new Date(bonus.expireDate) < new Date()}
                        />
                    </Text>
                )}
            </FlexPane>
            <FlexPane config={config.descriptionContainer}>
                <Text config={config.bonusDescriptionText}>{bonus.description}</Text>
                {renderProgressContent && (
                    <FlexPane config={config.progressBarContainer}>
                        {renderProgressContent(
                            bonus.target,
                            bonus.wagered,
                            bonus.remaining,
                            currencyCode
                        )}
                    </FlexPane>
                )}
            </FlexPane>
            <FlexPane config={config.amountContainer}>{renderBonusAmount()}</FlexPane>
        </FlexPane>
    );
}

const OddsBoostAmount = ({ bonus, config, stringResolver }) => {
    if (!bonus.percent) {
        return null;
    }

    return (
        <Text config={config.amountText} class="ta-BonusTotalAmount">
            {stringResolver.resolveAndFormatString(
                '$BONUS_CARD.ODDS_BOOST.MULTIPLIER_DESCRIPTION',
                bonus.percent
            )}
        </Text>
    );
};

const BonusAmount = ({
    bonus,
    currencyCode,
    shouldShowRemainingAmount,
    config,
    stringResolver,
}) => {
    // Free bet and prewager have different property name for remaining amount.
    const remaining = bonus.remainingAmount || bonus.remaining;
    const showRemaining = shouldShowRemainingAmount && remaining > 0;
    const showAmount = bonus.amount > 0;
    return (
        <>
            {showAmount && (
                <Text config={config.amountText} class="ta-BonusTotalAmount">
                    {stringResolver.resolveAndFormatString(
                        '$BONUS_CARD.TOTAL_VALUE',
                        CurrencyHelper.formatCurrency(bonus.amount, currencyCode)
                    )}
                </Text>
            )}
            {showRemaining && (
                <Text config={config.amountText} class="ta-BonusRemainingAmount">
                    {stringResolver.resolveAndFormatString(
                        '$BONUS_CARD.REMAINING_VALUE',
                        CurrencyHelper.formatCurrency(remaining, currencyCode)
                    )}
                </Text>
            )}
        </>
    );
};

const resolveItemStatusLabel = (status, stringResolver) => {
    switch (status) {
        case BonusStatus.ACTIVE:
            return stringResolver.resolveString('$BONUS_CARD.STATUS.ACTIVE');
        case BonusStatus.AWARDED:
            return stringResolver.resolveString('$BONUS_CARD.STATUS.AWARDED');
        case BonusStatus.CANCELLED:
            return stringResolver.resolveString('$BONUS_CARD.STATUS.CANCELLED');
        case BonusStatus.EXPIRED:
            return stringResolver.resolveString('$BONUS_CARD.STATUS.EXPIRED');
        case BonusStatus.COMPLETE:
            return stringResolver.resolveString('$BONUS_CARD.STATUS.COMPLETE');
        case BonusStatus.REDEEMED:
            return stringResolver.resolveString('$BONUS_CARD.STATUS.REDEEMED');
        case BonusStatus.SUSPENDED:
            return stringResolver.resolveString('$BONUS_CARD.STATUS.SUSPENDED');
        case BonusStatus.USED:
            return stringResolver.resolveString('$BONUS_CARD.STATUS.USED');
        default:
            return stringResolver.resolveString('$BONUS_CARD.STATUS.UNKNOWN');
    }
};
