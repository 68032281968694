import {createSlice} from '@reduxjs/toolkit';

import MojitoCore from 'mojito/core';
import MojitoServices from 'mojito/services';

import {balancesAreEqual, calculateBalances} from './utils.js';
import {selectBalances} from './selectors.js';

const reduxInstance = MojitoCore.Services.redux;
const {dispatch} = MojitoCore.Services.redux.store;

const {actions: UserInfoActions, selectors: UserInfoSelectors} = MojitoServices.UserInfo;
const {actions: UserBonusesActions, selectors: UserBonusesSelectors} = MojitoServices.UserBonuses;
const {selectors: AuthenticationSelectors} = MojitoServices.Authentication;

export const STORE_KEY = 'SportsbookAPIBalancesStore';

export const INITIAL_STATE = {
    balancesForHost: [],
};

export const {reducer, actions} = createSlice({
    name: 'sportsbookAPI/balances',
    initialState: INITIAL_STATE,
    reducers: {
        updateBalancesForHost(state, {payload: newBalances = []}) {
            state.balancesForHost = newBalances;
        },
    },
});

const notifyHostIfBalancesHaveChanged = state => {
    const isUserLoggedIn = AuthenticationSelectors.isLoggedIn(state);
    const userInfo = UserInfoSelectors.selectUserInfo(state);
    const activeFreeBets = UserBonusesSelectors.selectActiveFreeBets(state);
    const currentBalances = selectBalances(state);

    const newBalances = calculateBalances({isUserLoggedIn, userInfo, activeFreeBets});
    const changed = !balancesAreEqual(newBalances, currentBalances);

    if (changed) {
        dispatch(actions.updateBalancesForHost(newBalances));
    }
};

reduxInstance.actionListener.startListening({
    actionCreator: UserInfoActions.updateBalance,
    effect: (action, listenerApi) => notifyHostIfBalancesHaveChanged(listenerApi.getState()),
});

reduxInstance.actionListener.startListening({
    actionCreator: UserBonusesActions.fetchActiveFreeBetsSuccess,
    effect: (action, listenerApi) => notifyHostIfBalancesHaveChanged(listenerApi.getState()),
});

reduxInstance.injectReducer(STORE_KEY, reducer);
