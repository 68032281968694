import {PALETTE_IDS} from '#core/application/modules/ids.js';

import {COLOR, FONT} from '../theme.js';

export function getPageHeadersConfigs() {
    return {
        custom: {
            [PALETTE_IDS.INTERFACE]: {
                ApplicationMenu: {
                    logo: {
                        style: {
                            _desktop_height: '36px',
                            _mobile_height: '32px',
                            width: 93,
                        },
                    },
                    logoButton: {
                        style: {
                            base: {
                                flexBasis: 93,
                            },
                        },
                    },
                },
            },
        },
        palette: {
            [PALETTE_IDS.APPLICATION_HEADER]: {
                APPLICATION_HEADER_NAV_BAR_BG: COLOR.BASE.PAGE,
                APPLICATION_HEADER_NAV_BAR_PAGE_SELECTOR: {
                    PIVOT_TEXT: {
                        fontWeight: FONT.WEIGHT_BOLD,
                        color: COLOR.BRAND.BACKGROUND2,
                        flex: 'initial',
                    },
                    PIVOT_SELECTED_TEXT: {
                        fontWeight: FONT.WEIGHT_BOLD,
                        color: COLOR.BRAND.BACKGROUND2,
                        flex: 'initial',
                    },
                    PIVOT_INDICATOR_BG: COLOR.RESERVED.TRANSPARENT,
                },
                APPLICATION_HEADER_NAV_BAR_SESSION_TIME_LABEL_TEXT: {
                    color: COLOR.BRAND.FOREGROUND3,
                    fontSize: 9, // Zeplin
                },
                APPLICATION_HEADER_NAV_BAR_SESSION_TIME_VALUE_TEXT: {
                    color: COLOR.BRAND.FOREGROUND1,
                    fontWeight: FONT.WEIGHT_BOLD,
                    fontSize: 11, // Zeplin
                },
                APPLICATION_HEADER_NAV_BAR_SETTINGS: {
                    HEADER_SETTINGS_POPOVER_BG: COLOR.BRAND.BACKGROUND5,
                },
                SEARCH_POPOVER: {
                    POPOVER_BG: COLOR.BRAND.BACKGROUND5,
                    POPOVER_TEXT: {
                        color: COLOR.BRAND.FOREGROUND1,
                        fontWeight: FONT.WEIGHT_NORMAL,
                        fontSize: FONT.SIZE_MEDIUM,
                    },
                },
            },
            [PALETTE_IDS.INTERFACE]: {
                BANNER_TEXT: COLOR.BRAND.FOREGROUND2,
                BREADCRUMBS_TEXT: COLOR.BRAND.ACCENT1,
                MOBILE_BREADCRUMBS_BG: COLOR.BASE.PAGE,
                MOBILE_BREADCRUMBS_TEXT: COLOR.BRAND.ACCENT1,
                MOBILE_BREADCRUMBS_QUICK_NAVIGATION_CATEGORY_TEXT: COLOR.BRAND.ACCENT1,
                SYSTEM_INITIALIZATION_TEXT: COLOR.RESERVED.WHITE,
                QSG_BANNER_CONTAINER_BG: COLOR.BRAND.BACKGROUND4,
            },
        },
    };
}
