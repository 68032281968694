import {ensureHierarchy} from '#core/utils/config-utils.js';
import {loadScript} from '#core/utils/utils.js';
import {Logger} from '#core/utils/logger.js';

import {AbstractFeature, allFeatures} from '#core/application/abstract-feature.js';

import {DBXGoogleAnalytics4Service} from './service.js';

import SCHEMA from './google-analytics-4.schema.yaml';

const log = Logger('GoogleAnalytics4');
const gTagUrl = 'https://www.googletagmanager.com/gtag/js';

class GoogleAnalytics4Feature extends AbstractFeature {
    constructor(parent) {
        super(parent);

        this.scriptLoaded = false;
    }
    get name() {
        return 'GoogleAnalytics4';
    }

    get configSchema() {
        return SCHEMA;
    }

    get isThirdParty() {
        return true;
    }

    get isTracker() {
        return true;
    }

    loadGTM() {
        const measurementId = this.config.measurementId;

        const tagId = Array.isArray(measurementId) ? measurementId[0] : measurementId;
        const url = `${gTagUrl}?id=${tagId}`;
        loadScript(url)
            .then(() => {
                window.dataLayer = window.dataLayer || [];
                this.gtag('js', new Date());
                if (Array.isArray(measurementId)) {
                    measurementId.forEach(measurementId => {
                        this.gtag('config', measurementId);
                    });
                } else {
                    this.gtag('config', measurementId);
                }
                this.service.init(window.dataLayer);
                this.scriptLoaded = true;
            })
            .catch(() => {
                log.warn('Failed to load Google analytics 4');
            });
    }

    afterMojitoConfigBuild(mojitoConfig) {
        this.service = new DBXGoogleAnalytics4Service();
        const services = ensureHierarchy(mojitoConfig, 'services.analytics.services');

        services.default = services.default || [];
        services.default.push(this.service);

        return super.afterMojitoConfigBuild(mojitoConfig);
    }

    cookieConsentGiven() {
        if (this.scriptLoaded) {
            return true;
        }

        this.loadGTM();
    }
}

export const GoogleAnalytics4 = new GoogleAnalytics4Feature(allFeatures);
