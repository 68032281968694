import MojitoCore from 'mojito/core';
import MatchesViewImpl from './matches-view.jsx';

const UIView = MojitoCore.Presentation.UIView;

/**
 * MatchesView - render a content based on sport page.
 *
 * @class MatchesView
 * @memberof Mojito.Applications.Sports.Pages
 */
export default UIView('MatchesView', MatchesViewImpl);
