/**
 * Date time types.
 *
 * @namespace DateTimeTypes
 * @memberof Mojito.Presentation.Utils.DateTime
 */

/**
 * Enum for different date and/or time formats.
 * <br><br>
 * Date will always include month and day.
 * <br>
 * Time will always include hours and minutes.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Presentation.Utils.DateTime.DateTimeTypes
 */
export const DATE_TIME_FORMAT = {
    /** Date and time. Will include year but not seconds. */
    LONG_DATE_TIME: 'LONG_DATE_TIME',
    /** Date and time. Will not include year and seconds. */
    SHORT_DATE_TIME: 'SHORT_DATE_TIME',
    /** Date and time with short week day. Will not include year and seconds. */
    SHORT_DATE_TIME_AND_WEEK_DAY: 'SHORT_DATE_TIME_AND_WEEK_DAY',
    /** Date and time. Will include year and seconds. */
    FULL_DATE_TIME: 'FULL_DATE_TIME',
    /** Date. Will include year. */
    LONG_DATE: 'LONG_DATE',
    /** Date. Will not include year. */
    SHORT_DATE: 'SHORT_DATE',
    /** Time. Will include seconds. */
    LONG_TIME: 'LONG_TIME',
    /** Time. Will not include seconds. */
    SHORT_TIME: 'SHORT_TIME',
    /** Date. Will be in numeric form and include year. */
    NUMERIC_DATE: 'NUMERIC_DATE',
    /** Date. Will be in numeric form only including day and month. */
    NUMERIC_SHORT_DATE: 'NUMERIC_SHORT_DATE',
    /** Date and time. Will be in numeric form and include year but not seconds. */
    NUMERIC_DATE_TIME: 'NUMERIC_DATE_TIME',
    /** Date and time. Will be in numeric form and include year with seconds. */
    NUMERIC_DATE_LONG_TIME: 'NUMERIC_DATE_LONG_TIME',
};

/**
 * Duration patterns
 * Typically used when formatting seconds.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Presentation.Utils.DateTime.DateTimeTypes
 */
export const DURATION = {
    /* Seconds are being transformed into hours, minutes and seconds, 24h span is ignored */
    HOURS_MINUTES_SECONDS: 'HH:mm:ss',
    /* Seconds are being transformed into minutes and seconds, 60 min span is ignored */
    MINUTES_SECONDS: 'mm:ss',
    /* Seconds are being transformed into current minute */
    MINUTE: "m'",
    /* Pure seconds, 60 secs span is ignored */
    SECONDS: 'ss',
};
