import MojitoCore from 'mojito/core';
import { STORE_KEY, INITIAL_STATE } from './slice.js';
import { isPlacementBlocked } from './helper.js';

const reduxInstance = MojitoCore.Services.redux;

/**
 * Betslip related selectors.
 *
 * @module BetslipSelectors
 * @name selectors
 * @memberof Mojito.Services.Betslip
 */

/**
 * Selects betslip store state.
 *
 * @function selectState
 *
 * @param {object} [state] - Application state object. If not provided then state from global {@link Mojito.Core.Services.redux|redux store} will be returned.
 * @returns {Mojito.Services.Betslip.BetslipState} Betslip store state.
 * @memberof Mojito.Services.Betslip.selectors
 */
export const selectState = state => {
    // Should be no need to fallback to global state once fully migrated to Redux.
    const appState = state || reduxInstance.store?.getState();
    return appState?.[STORE_KEY] || INITIAL_STATE;
};

/**
 * Selects betslip state.
 *
 * @function selectBetslipState
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {string} Betslip state.
 * @memberof Mojito.Services.Betslip.selectors
 */
export const selectBetslipState = state => selectState(state).betslipState;

/**
 * Selects betslip status.
 *
 * @function selectBetslipStatus
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {Mojito.Services.Betslip.types.BETSLIP_STATUS} Betslip status.
 * @memberof Mojito.Services.Betslip.selectors
 */
export const selectBetslipStatus = state => selectState(state).betslipStatus;

/**
 * Selects betslip.
 *
 * @function selectBetslip
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {Mojito.Services.Betslip.types.Betslip} Betslip object.
 * @memberof Mojito.Services.Betslip.selectors
 */
export const selectBetslip = state => selectState(state).betslip;

/**
 * Selects selections state.
 *
 * @function selectSelectionsState
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {object} Selections state object.
 * @memberof Mojito.Services.Betslip.selectors
 */
export const selectSelectionsState = state => selectState(state).selectionsState;

/**
 * Selects selection state.
 *
 * @function selectSelectionState
 *
 * @param {string} selectionId - Selection id.
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {object} State object linked to selection.
 * @memberof Mojito.Services.Betslip.selectors
 */
export const selectSelectionState = (selectionId, state) =>
    selectSelectionsState(state)[selectionId];

/**
 * Selects active stake group.
 *
 * @function selectActiveStakeGroup
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {Mojito.Services.Betslip.types.STAKE_GROUP_NAME} Stake group.
 * @memberof Mojito.Services.Betslip.selectors
 */
export const selectActiveStakeGroup = state => selectState(state).lastActivatedStakeGroup;

/**
 * Selects stake group activation pending state.
 *
 * @function selectIsStakeGroupActivationPending
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {boolean} True if stake group activation is in progress.
 * @memberof Mojito.Services.Betslip.selectors
 */
export const selectIsStakeGroupActivationPending = state =>
    selectState(state).stakeGroupActivationPending;

/**
 * Selects bets state.
 *
 * @function selectBetsState
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {object} Bets state object.
 * @memberof Mojito.Services.Betslip.selectors
 */
export const selectBetsState = state => selectState(state).betsState;

/**
 * Selects bet state.
 *
 * @function selectBetState
 *
 * @param {string} betId - Bet id.
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {object} State object linked to bet.
 * @memberof Mojito.Services.Betslip.selectors
 */
export const selectBetState = (betId, state) => selectState(state).betsState[betId];

/**
 * Check if there are any suspended bets in bets state.
 *
 * @function selectHasSuspendedBet
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {boolean} True if any bet is suspended.
 * @memberof Mojito.Services.Betslip.selectors
 */
export const selectHasSuspendedBet = state => {
    const betsState = selectBetsState(state);
    return checkBetStateKey(betsState, 'isSuspended');
};

/**
 * Check if there are any bets with odds changed in bets state.
 *
 * @function selectHasOddsChangeBet
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {boolean} True if any bet has odds change.
 * @memberof Mojito.Services.Betslip.selectors
 */
export const selectHasOddsChangeBet = state => {
    const betsState = selectBetsState(state);
    return checkBetStateKey(betsState, 'hasOddsChange');
};

/**
 * Check if there are any bets with handicap changed in bets state.
 *
 * @function selectHasHcapChangeBet
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {boolean} True if any bet has handicap change.
 * @memberof Mojito.Services.Betslip.selectors
 */
export const selectHasHcapChangeBet = state => {
    const betsState = selectBetsState(state);
    return checkBetStateKey(betsState, 'hasHcapChange');
};

/**
 * Selects validation errors.
 *
 * @function selectValidationErrors
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {Array} List of validation errors.
 * @memberof Mojito.Services.Betslip.selectors
 */
export const selectValidationErrors = state => selectState(state).validationErrors;

/**
 * Selects receipt.
 *
 * @function selectReceipt
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {Mojito.Services.Betslip.types.Receipt} Betslip state.
 * @memberof Mojito.Services.Betslip.selectors
 */
export const selectReceipt = state => selectState(state).receipt;

/**
 * Selects overask.
 *
 * @function selectOverask
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {Mojito.Services.Betslip.types.Overask} Overask object.
 * @memberof Mojito.Services.Betslip.selectors
 */
export const selectOverask = state => selectState(state).overask;

/**
 * Selects enable bonus validation.
 *
 * @function selectEnableBonusValidation
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {boolean} Bonus validation enabled.
 * @memberof Mojito.Services.Betslip.selectors
 */
export const selectEnableBonusValidation = state => selectState(state).enableBonusValidation;

/**
 * Checks if bonuses validation is in pending state.
 *
 * @function selectIsBonusValidationPending
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {boolean} True if bonus validation process is pending, false otherwise.
 * @memberof Mojito.Services.Betslip.selectors
 */
export const selectIsBonusValidationPending = state => selectState(state).bonusValidationPending;

/**
 * Check if any banker bet set/unset operation is pending.
 *
 * @function selectHasPendingBankerBet
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {boolean} True if banker bet set/unset is in progress.
 * @memberof Mojito.Services.Betslip.selectors
 */
export const selectHasPendingBankerBet = state => {
    const betsState = selectBetsState(state);
    return checkBetStateKey(betsState, 'isBankerPending');
};

/**
 * Checks if bet placement is allowed.
 *
 * @function selectIsBetPlacementAllowed
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {boolean} True if bet placement is allowed, false otherwise.
 * @memberof Mojito.Services.Betslip.selectors
 */
export const selectIsBetPlacementAllowed = state => {
    const stakeGroupName = selectActiveStakeGroup(state);
    const betslip = selectBetslip(state);
    const { allowBetPlacement } = selectState(state);
    return allowBetPlacement && !isPlacementBlocked(betslip, stakeGroupName);
};

/**
 * Selects betslip state persistence time in hours.
 *
 * @function selectStorageTimeInHours
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {number} Maximum number of selections.
 * @memberof Mojito.Services.Betslip.selectors
 */
export const selectStorageTimeInHours = state => selectState(state).storageTimeInHours;

/**
 * Selects configured maximum number of selections allowed in betslip.
 *
 * @function selectMaxSelectionsCount
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {number} Maximum number of selections.
 * @memberof Mojito.Services.Betslip.selectors
 */
export const selectMaxSelectionsCount = state => selectState(state).maxSelectionsCount;

/**
 * Selects betslip settings. Typically, represents user betslip preferences.
 *
 * @function selectBetslipSettings
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {Mojito.Services.Betslip.types.BetslipSettings} Betslip settings.
 * @memberof Mojito.Services.Betslip.selectors
 */
export const selectBetslipSettings = state => selectState(state).betslipSettings;

/**
 * Check bankers activation state.
 *
 * @function selectBankersActivated
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {boolean} True if bankers mode is activated, else false.
 * @memberof Mojito.Services.Betslip.selectors
 */
export const selectBankersActivated = state => selectState(state).bankersActivated;

/**
 * Selects deep linking bets.
 *
 * @function selectDeepLinkingBets
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {Array<Mojito.Services.Betslip.types.Bet>} List of deep linking bets.
 * @memberof Mojito.Services.Betslip.selectors
 */
export const selectDeepLinkingBets = state => selectState(state).deepLinkingBets;

/**
 * Selects betslip initialized state.
 *
 * @function selectInitialized
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {boolean} If true then {@link Mojito.Services.Betslip.actions.initBetslip|initBetslip} was triggered and finalized successfully.
 * @memberof Mojito.Services.Betslip.selectors
 */
export const selectInitialized = state => selectState(state).initialized;

/**
 * Check if bets state contain bet with key that is true.
 *
 * @param {object} betsState - Bets state object.
 * @param {string} key - Key.
 * @returns {boolean} True if key is true for any bet in bets state.
 * @private
 */
const checkBetStateKey = (betsState, key) => {
    return Object.values(betsState).some(betState => betState[key] === true);
};
