import ApplicationFooterImpl from './application-footer.jsx';
import MojitoCore from 'mojito/core';

const UIView = MojitoCore.Presentation.UIView;
/**
 * ApplicationFooter placeholder component. Mojito has empty implementation of it. The implementation of this component should be provided by application.
 *
 * @class ApplicationFooter
 * @memberof Mojito.Applications.Common.Components
 */

export default UIView('ApplicationFooter', ApplicationFooterImpl);
