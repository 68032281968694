import {setCookie} from '#core/utils/cookie-utils.js';
import {getParamFromURL, removeParamFromURL} from '#core/utils/url-utils.js';

import {AbstractFeature, allFeatures} from '#core/application/abstract-feature.js';

import SCHEMA from './affiliates.schema.yaml';

const KEY_NAME = 'dbx.affiliateId';
const EXPIRE_TIMESTAMP_KEY_NAME = 'dbx.affiliateId.expireTimestamp';
const EXPIRE_DATE_KEY_NAME_OLD = 'expireDate';
const EXPIRE_IN_DAYS = 30;

class AffiliatesFeature extends AbstractFeature {
    constructor(parent) {
        super(parent);
        this._affiliateId = localStorage.getItem(KEY_NAME);

        // Check for expiration ---------
        let expireTimestamp = Number(
            localStorage.getItem(EXPIRE_TIMESTAMP_KEY_NAME) || localStorage.getItem(EXPIRE_DATE_KEY_NAME_OLD)
        );
        if (window.localStorage.getItem(EXPIRE_DATE_KEY_NAME_OLD)) {
            // remove deprecated one
            localStorage.removeItem(EXPIRE_DATE_KEY_NAME_OLD);
            localStorage.setItem(EXPIRE_TIMESTAMP_KEY_NAME, expireTimestamp.toString());
        }
        if (expireTimestamp && Date.now() > expireTimestamp) {
            localStorage.removeItem(KEY_NAME);
            localStorage.removeItem(EXPIRE_TIMESTAMP_KEY_NAME);
            this._affiliateId = undefined;
        }
        // ------------------------------
    }

    get name() {
        return 'AffiliatesFeature';
    }

    get configSchema() {
        return SCHEMA;
    }

    get affiliateId() {
        return this._affiliateId;
    }

    set affiliateId(id) {
        this._affiliateId = id;
        window.localStorage.setItem(KEY_NAME, this._affiliateId);
    }

    clear() {
        this.affiliateId = undefined; // call setter
    }

    beforeMojito() {
        const {output, input} = this.config;

        const valuesArray = this._parseInput(input);
        if (valuesArray) {
            this._affiliateId = this.processTemplate(output.valueTemplate, valuesArray);

            const expireTimestamp = Date.now() + EXPIRE_IN_DAYS * 24 * 60 * 60 * 1000;
            localStorage.setItem(KEY_NAME, this._affiliateId);
            localStorage.setItem(EXPIRE_TIMESTAMP_KEY_NAME, expireTimestamp.toString());

            if (output.setCookie) {
                const cookieValue = this.processTemplate(output.cookieValueTemplate, valuesArray);
                const domainLevels = window.location.hostname.split('.');
                const baseDomain =
                    domainLevels.length <= 2
                        ? window.location.hostname
                        : domainLevels.slice(output.cookieDomainLevel, domainLevels.length).join('.');

                setCookie(output.cookieName, cookieValue, {
                    domain: baseDomain,
                    expireInDays: EXPIRE_IN_DAYS,
                    sameSite: 'None',
                });

                // Hide ${affiliateIdParameterName} from url
                if (this.config.clearUrl) {
                    let newUrl = '';
                    if (input.affiliateIdParameterName === '<value_only>') {
                        newUrl = location.origin + location.pathname;
                    } else {
                        newUrl =
                            input.affiliateIdParameterName &&
                            removeParamFromURL(location.href, input.affiliateIdParameterName);
                    }
                    const {state, title} = window.history;
                    window.history.replaceState(state, title, newUrl);
                }
            }
        }

        return super.beforeMojito();
    }

    /**
     * Parsed affiliateIdParameterName url parameter and returns its parts
     * @param input {object} Configuration
     * @return {null|Array}
     * @private
     */
    _parseInput(input) {
        let value;
        if (input.affiliateIdParameterName === '<value_only>') {
            // check and apply only if its only one parameter
            // like https://apostas.solverde.pt/?654457_4B1B8EB5792A4323B89784B97D873261
            const query = window.location.search.substring(1);
            const onlyOneParameter = !/[?=]/.test(query);
            value = onlyOneParameter ? query : undefined;
        } else {
            value = input.affiliateIdParameterName && getParamFromURL(input.affiliateIdParameterName);
        }
        if (!value) return null;
        try {
            return input.parseRX.exec(value);
        } catch (e) {
            return null;
        }
    }

    processTemplate(template, valuesArray) {
        let value = template;
        for (let i = 0; i < valuesArray.length; i++) {
            value = value.replace(`{{${i}}}`, valuesArray[i]);
        }
        return value;
    }
}

export const Affiliates = new AffiliatesFeature(allFeatures);
