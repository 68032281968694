import {resolveBackground} from '#core/utils/color-utils.js';
import {icon} from '#core/utils/style-utils.js';

import {registerTileSchema} from '#core/application/schema-registry.js';

import {generateBetBuilderSelectionItem} from '#core/application/modules/tiles/bet-builder-selection-item/index.js';
import {BET_FUNDS_INFO_TYPES, generateBetFundsInfo} from '#core/application/modules/tiles/bet-funds-info/index.js';
import {generateBetStatusIcon} from '#core/application/modules/tiles/bet-status-icon/index.js';
import {generateChangedValueIndicator} from '#core/application/modules/tiles/changed-value-indicator/index.js';
import {generateEarlyPayoutIndicator} from '#core/application/modules/tiles/early-payout-indicator/index.js';
import {generateBetBonusLabel} from '#core/application/modules/tiles/bet-bonus-label/index.js';

import BET_BASE_INFO from './bet-base-info.palette-schema.yaml';

registerTileSchema(BET_BASE_INFO);

export function generateBetBaseInfo(TILE) {
    const {FONT} = TILE;

    const BET_BONUS_LABEL = generateBetBonusLabel(TILE.BET_BONUS_LABEL);

    return {
        title: {
            fontWeight: FONT.WEIGHT_BOLD,
            fontSize: FONT.SIZE_LARGE,
            textTransform: 'uppercase',
            ...TILE.BET_TITLE_TEXT,
        },
        date: {
            fontSize: FONT.SIZE_SMALL,
            ...TILE.BET_DATE_TEXT,
        },
        scanDate: {
            fontSize: FONT.SIZE_SMALL,
            ...TILE.BET_SCAN_DATE_TEXT,
        },
        suspendedNotification: {
            borderRadius: 4,
            padding: 4,
            ...resolveBackground(TILE.BET_SUSPENDED_NOTIFICATION.WARNING_BG),
            alignSelf: 'baseline',
            fontSize: FONT.SIZE_MEDIUM,
            fontWeight: FONT.WEIGHT_BOLD,
            ...TILE.BET_SUSPENDED_NOTIFICATION.WARNING_TEXT,
        },
        betStatusIcon: generateBetStatusIcon(TILE.BET_STATUS_ICON),
        betStatus: {
            fontSize: FONT.SIZE_MEDIUM,
            textTransform: 'uppercase',
            fontWeight: FONT.WEIGHT_SEMI_BOLD,
            ...TILE.BET_STATUS_TEXT,
        },
        selectionName: {
            fontWeight: FONT.WEIGHT_BOLD,
            fontSize: FONT.SIZE_MEDIUM,
            textTransform: 'capitalize',
            overflowWrap: 'anywhere',
            ...TILE.BET_SELECTION_NAME_TEXT,
        },
        betType: {
            fontWeight: FONT.WEIGHT_BOLD,
            fontSize: FONT.SIZE_MEDIUM,
            textTransform: 'capitalize',
            ...TILE.BET_TYPE_TEXT,
        },
        betTotalOdds: {
            label: {
                style: {
                    fontWeight: FONT.WEIGHT_NORMAL,
                    fontSize: FONT.SIZE_SMALL,
                    ...TILE.BET_TOTAL_ODDS_LABEL_TEXT,
                },
            },
            value: {
                style: {
                    fontWeight: FONT.WEIGHT_BOLD,
                    fontSize: FONT.SIZE_MEDIUM,
                    ...TILE.BET_TOTAL_ODDS_VALUE_TEXT,
                },
            },
            priceBoostIndicator: generateChangedValueIndicator(TILE.BET_TOTAL_ODDS_PRICE_BOOST_CVI, {
                SHOW_ICON: true,
                HIGHLIGHT_NEW_VALUE: false,
            }),
        },
        odds: {
            fontWeight: FONT.WEIGHT_BOLD,
            fontSize: FONT.SIZE_MEDIUM,
            ...TILE.BET_ODDS_TEXT,
        },
        oddsChangedValue: generateChangedValueIndicator({
            ...TILE.BET_ODDS_CVI,
            CVI_PREVIOUS_VALUE_TEXT: {
                fontWeight: FONT.WEIGHT_NORMAL,
                fontSize: FONT.SIZE_MEDIUM,
                ...TILE.BET_ODDS_CVI.CVI_PREVIOUS_VALUE_TEXT,
            },
            CVI_NEW_VALUE_TEXT: {
                fontWeight: FONT.WEIGHT_BOLD,
                fontSize: FONT.SIZE_MEDIUM,
                ...TILE.BET_ODDS_CVI.CVI_NEW_VALUE_TEXT,
            },
        }),
        priceBoostValue: generateChangedValueIndicator(TILE.BET_PRICE_BOOST_CVI, {
            SHOW_ICON: true,
            HIGHLIGHT_NEW_VALUE: false,
        }),
        marketName: {
            fontWeight: FONT.WEIGHT_SEMI_BOLD,
            fontSize: FONT.SIZE_SMALL,
            overflowWrap: 'anywhere',
            ...TILE.BET_MARKET_NAME_TEXT,
        },
        marketChangedValue: generateChangedValueIndicator({
            ...TILE.BET_MARKET_CVI,
            CVI_PREVIOUS_VALUE_TEXT: {
                fontSize: FONT.SIZE_SMALL,
                fontWeight: FONT.WEIGHT_NORMAL,
                ...TILE.BET_MARKET_CVI.CVI_PREVIOUS_VALUE_TEXT,
            },
            CVI_NEW_VALUE_TEXT: {
                fontWeight: FONT.WEIGHT_BOLD,
                fontSize: FONT.SIZE_SMALL,
                ...TILE.BET_MARKET_CVI.CVI_NEW_VALUE_TEXT,
            },
        }),
        eventName: {
            fontSize: FONT.SIZE_SMALL,
            overflowWrap: 'anywhere',
            ...TILE.BET_EVENT_NAME_TEXT,
        },
        multiplesInfo: {
            fontSize: FONT.SIZE_SMALL,
            ...TILE.BET_MULTIPLES_INFO_TEXT,
        },
        betBuilderSelectionName: {
            fontWeight: FONT.WEIGHT_BOLD,
            fontSize: FONT.SIZE_MEDIUM,
            ...TILE.BET_BUILDER_BET_SELECTION_NAME_TEXT,
        },
        betBuilderMarketName: {
            fontWeight: FONT.WEIGHT_NORMAL,
            fontSize: FONT.SIZE_SMALL,
            ...TILE.BET_BUILDER_BET_MARKET_NAME_TEXT,
        },
        betBuilderSelectionItem: generateBetBuilderSelectionItem(TILE.BET_BUILDER_SELECTION_ITEM),
        totalBetInfoChangedValue: generateChangedValueIndicator({
            ...TILE.BET_TOTAL_INFO_CVI,
            CVI_NEW_VALUE_TEXT: {
                fontWeight: FONT.WEIGHT_NORMAL,
                ...TILE.BET_TOTAL_INFO_CVI.CVI_NEW_VALUE_TEXT,
            },
        }),
        betFundsInfo: {
            normal: generateBetFundsInfo(TILE.BET_FUNDS_INFO, BET_FUNDS_INFO_TYPES.NORMAL),
            medium: generateBetFundsInfo(TILE.BET_FUNDS_INFO, BET_FUNDS_INFO_TYPES.MEDIUM),
            emphasized: generateBetFundsInfo(TILE.BET_FUNDS_INFO, BET_FUNDS_INFO_TYPES.EMPHASIZED),
        },
        earlyPayoutIndicator: generateEarlyPayoutIndicator(TILE.BET_EARLY_PAYOUT_INDICATOR),
        bestOddsIcon: icon(TILE.BET_BEST_ODDS_GUARANTEED_ICON_COLOR),
        oddsBoostLabel: {
            notification: {
                container: {
                    style: BET_BONUS_LABEL.container,
                },
                text: {
                    style: BET_BONUS_LABEL.text,
                },
                icon: BET_BONUS_LABEL.icon,
            },
        },
    };
}
