import PropTypes from 'prop-types';
import MojitoCore from 'mojito/core';
import ProgressBarImpl from './progress-bar.jsx';

const UIView = MojitoCore.Presentation.UIView;

/**
 * Component for displaying a progress bar.
 *
 * @class ProgressBar
 * @memberof Mojito.Presentation.Components
 */

/**
 * ProgressBar prop types.
 *
 * @property {number} [progress = 0] - The progress value. Accepts a number in the range from 0 to 1, which translates to progress from 0 to 100 percent respectively. For instance, 0.33 represents 33% progress.
 * @property {string} [title] - The title of the progress bar.
 * @property {string} [startLabel] - The label displayed at the start of the progress bar. Typically signifies the initial value of progress.
 * @property {string} [endLabel] - The label displayed at the end of the progress bar. Typically indicates the target value of progress.
 * @property {string} [progressLabel] - The label that reflects the current progress.
 * @property {string} [remainLabel] - The label that denotes the remaining portion of the progress that has yet to be achieved.
 * @property {boolean} [isActiveBonus = false] - Flag indicating that component is used inside ActiveBonuses. Needed to change a corresponding view.
 *
 * @memberof Mojito.Presentation.Components.ProgressBar
 */
const propTypes = {
    progress: PropTypes.number,
    title: PropTypes.string,
    startLabel: PropTypes.string,
    endLabel: PropTypes.string,
    progressLabel: PropTypes.string,
    remainLabel: PropTypes.string,
    isActiveBonus: PropTypes.bool,
};

const defaultProps = {
    progress: 0,
    isActiveBonus: false,
};

export default UIView('ProgressBar', ProgressBarImpl, propTypes, defaultProps);
