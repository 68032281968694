import MojitoServices from 'mojito/services';
import MojitoPresentation from 'mojito/presentation';

const { IntentActionTrigger } = MojitoPresentation.Base.Intent;
const { utils: EventUtils, types: EventsTypes } = MojitoServices.SportsContent.Events;

const IS_REDIRECT = true;

export const navigateToBetEvents = events => {
    if (!events.length) {
        return IntentActionTrigger.showHomePage(IS_REDIRECT);
    }

    const firstEvent = events[0];
    if (firstEvent.isVirtual) {
        // in case of virtual events there is no separate page for sport/class/type
        // navigate to virtual pages
        return redirectToVirtualEventPage(events);
    } else if (firstEvent.eventType === EventsTypes.EVENT_TYPE.RACE) {
        // navigate to racing pages
        return redirectToRacingPage(events);
    }
    // navigate to sport pages
    return redirectToSportPage(events);
};

const areAllEventsSame = (events, propName) =>
    !events.some(event => event[propName] !== events[0][propName]);
const areAllEventDetailsSame = events =>
    !events.some(event => event.details['meetingId'] !== events[0].details['meetingId']);

function showSportPage(event, sportId, typeId, isRedirect) {
    const isOutright = EventUtils.isOutrightEventType(event.eventType);
    isOutright
        ? IntentActionTrigger.showSportOutrights(sportId, typeId, isRedirect)
        : IntentActionTrigger.showSportCompetitions(sportId, typeId, isRedirect);
}

function redirectToVirtualEventPage(events) {
    const firstEvent = events[0];

    if (
        events.length === 1 ||
        areAllEventsSame(events, 'id') ||
        areAllEventsSame(events, 'sportId')
    ) {
        return IntentActionTrigger.redirectToVirtualEventPage(
            firstEvent.sportId,
            firstEvent.classId,
            firstEvent.id
        );
    }
}

function redirectToRacingPage(events) {
    const firstEvent = events[0];
    if (events.length === 1 || areAllEventsSame(events, 'id')) {
        return IntentActionTrigger.showMeetingsRaces(
            firstEvent.sportId,
            firstEvent.details.meetingId,
            firstEvent.id,
            IS_REDIRECT
        );
    }

    if (areAllEventDetailsSame(events)) {
        return IntentActionTrigger.showMeetingsRaces(
            firstEvent.sportId,
            firstEvent.details.meetingId,
            undefined,
            IS_REDIRECT
        );
    }

    if (areAllEventsSame(events, 'sportId')) {
        return IntentActionTrigger.showRaceMeetingsPage(firstEvent.sportId, IS_REDIRECT);
    }
}

function redirectToSportPage(events) {
    const firstEvent = events[0];

    if (events.length === 1 || areAllEventsSame(events, 'id')) {
        return IntentActionTrigger.showSportEvent(firstEvent.sportId, firstEvent.id, IS_REDIRECT);
    }

    if (areAllEventsSame(events, 'typeId')) {
        return showSportPage(firstEvent, firstEvent.sportId, firstEvent.typeId, IS_REDIRECT);
    }

    if (areAllEventsSame(events, 'classId')) {
        return showSportPage(firstEvent, firstEvent.sportId, undefined, IS_REDIRECT);
    }

    if (areAllEventsSame(events, 'sportId')) {
        return IntentActionTrigger.showSport(firstEvent.sportId, IS_REDIRECT);
    }

    return IntentActionTrigger.showHomePage(IS_REDIRECT);
}
