/**
 * @class SportGroupsListTypes
 * @memberof Mojito.Modules
 */
export default class SportGroupsListTypes {}

/**
 * Types of sport groups list.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Modules.SportGroupsListTypes
 */
SportGroupsListTypes.TYPE = {
    ALL: 'all',
    TOP: 'top',
};

SportGroupsListTypes.GROUP_ID = {
    TOP_LEAGUES: '-top-leagues',
    TOP_OUTRIGHTS: '-top-outrights',
};
