import FlexPane from 'presentation/components/flex-pane/index.jsx';
import Text from 'presentation/components/text/index.jsx';
import ChangedValueIndicator from 'presentation/components/changed-value-indicator/index.jsx';

export default function FormattedLabelWithTotalOdds(props) {
    const { mojitoTools, odds, isTotalOddsApplicable, baseOdds } = props;
    const { config, stringResolver } = mojitoTools;

    const oddsString =
        odds ||
        stringResolver.resolveString('$FORMATTED_LABEL_WITH_TOTAL_ODDS.TOTAL_ODDS_NOT_APPLICABLE');

    if (!isTotalOddsApplicable) {
        return null;
    }

    return (
        <FlexPane config={config.container}>
            <Text config={config.label}>
                {stringResolver.resolveString('$FORMATTED_LABEL_WITH_TOTAL_ODDS.TOTAL_ODDS')}
            </Text>
            {baseOdds ? (
                <ChangedValueIndicator
                    class={'ta-totalPriceBoostedOddsIndicator'}
                    config={config.priceBoostIndicator}
                    previousValue={baseOdds}
                    newValue={oddsString}
                />
            ) : (
                <Text class={'ta-Odds'} config={config.value}>
                    {oddsString}
                </Text>
            )}
        </FlexPane>
    );
}
