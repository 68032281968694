import { useCallback, useEffect, useState } from 'react';
import MojitoCore from 'mojito/core';
import TimerTypes from './types';
import FixedWidthTime from 'presentation/components/fixed-width-time/index.jsx';
import FlexPane from 'presentation/components/flex-pane/index.jsx';
import Text from 'presentation/components/text/index.jsx';
import DateTimeFormatter from 'presentation/formatters/date-time/index.js';

const clockTicker = MojitoCore.Base.clockTicker;

const { TIMER_DIRECTION } = TimerTypes;

const shifts = {
    [TIMER_DIRECTION.FORWARD]: 1,
    [TIMER_DIRECTION.BACKWARD]: -1,
};
function getSeconds(startTime, initialSeconds, direction) {
    const delta = Math.round((Date.now() - startTime) / 1000);
    const seconds = initialSeconds + delta * shifts[direction];
    return seconds > 0 ? seconds : 0;
}
function Timer(props) {
    const { time, direction, onTimerExpired, running, startTimeLabel, format, mojitoTools } = props;
    const { config, appContext } = mojitoTools;
    const [startTime, setStartTime] = useState(Date.now());
    const [initialSeconds, setInitialSeconds] = useState(time);
    const [seconds, setSeconds] = useState(initialSeconds);
    const timerStyle = seconds < 100 * 60 ? config.timerFourDigits : config.timerFiveDigits;

    /*
     * Timer component algorithm description.
     *
     * Initially timer receives the number of seconds and direction to tick.
     * Example: time = 0, direction = 'F' (forward). Or time = 15, direction = 'B' (backward).
     * We keep the initial number of seconds separately in the state,
     * later we will need it when we put timer on pause and after some time letting timer resume ticking.
     * Also, we keep in the state 'startTime' the full time when timer starts or resumes ticking, we will need it to calculate delta time by subtracting startTime from Date.now().
     * Having delta time we add it or subtract it from initial seconds depending on direction (forward or backward).
     * Timer update happens by listening ClockTicker sigleton from MojitoCore. The purpose of this class is to call the callback function at a certain interval (1000 ms for more smooth ticking).
     * The callback function calculates a new timer value (seconds) and updates its state.
     *
     * */
    const onClockTick = useCallback(() => {
        const newSeconds = getSeconds(startTime, initialSeconds, direction);
        setSeconds(newSeconds);
    }, [startTime, initialSeconds, direction]);

    useEffect(() => {
        clockTicker.removeListener(onClockTick);
        running && clockTicker.addListener(onClockTick); // Register listener for updates
        return () => {
            clockTicker.removeListener(onClockTick); // Unregister on component unmount
        };
    }, [running, onClockTick]);
    // Updating timer when time prop changed.
    // In the case when time prop was changed from parent component for example time expiration was prolonged for 30 secs more we need to update
    // the initial seconds, seconds and start time values to allow timer continue calculating delta time properly.
    useEffect(() => {
        setInitialSeconds(time);
        setSeconds(time);
        setStartTime(Date.now());
    }, [time]);

    // Updating timer when it resumes ticking.
    useEffect(() => {
        if (running) {
            setStartTime(Date.now());
        } else {
            setInitialSeconds(seconds);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [running]);

    // Invoke a callback when timer expired.
    useEffect(() => {
        if (seconds === 0 && direction === TIMER_DIRECTION.BACKWARD) {
            onTimerExpired();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [seconds]);

    if (time < 0) {
        return null;
    }

    return (
        <FlexPane config={config.timerWrapper}>
            {startTimeLabel && <Text config={config.startTimeLabel}>{startTimeLabel}</Text>}
            <FlexPane config={config.timeString} class={'ta-timerTime'}>
                {config.isExtendedFormat ? (
                    <ExtendedTimeString {...props} time={seconds} />
                ) : (
                    <FixedWidthTime
                        config={timerStyle}
                        time={DateTimeFormatter.formatDuration(
                            seconds,
                            appContext.userSettings(),
                            appContext.systemSettings().language,
                            format
                        )}
                    />
                )}
            </FlexPane>
        </FlexPane>
    );
}
export default Timer;

const ExtendedTimeString = props => {
    const { time, mojitoTools } = props;
    const { config, stringResolver } = mojitoTools;

    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time - hours * 3600) / 60);
    const seconds = time - hours * 3600 - minutes * 60;

    const hoursLabel = getNumberNoun(stringResolver, '$TIMER.HOUR', hours);
    const minutesLabel = getNumberNoun(stringResolver, '$TIMER.MINUTE', minutes);
    const secondsLabel = getNumberNoun(stringResolver, '$TIMER.SECOND', seconds);

    return (
        <>
            {!!hours && (
                <Text config={config.hoursWrapper}>
                    <span style={config.style.extendedTime}>{hours}</span> {hoursLabel}
                </Text>
            )}
            {(!!minutes || !!hours) && (
                <Text config={config.minutesWrapper}>
                    <span style={config.style.extendedTime}>
                        {minutes < 10 ? `0${minutes}` : minutes}
                    </span>{' '}
                    {minutesLabel}
                </Text>
            )}
            <Text config={config.secondsWrapper}>
                <span style={config.style.extendedTime}>
                    {seconds < 10 ? `0${seconds}` : seconds}
                </span>{' '}
                {secondsLabel}
            </Text>
        </>
    );
};

function getNumberNoun(stringResolver, id, value) {
    return stringResolver.resolveString(`${id}${value === 1 ? '' : 'S'}`);
}
