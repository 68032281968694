import MojitoServices from 'mojito/services';
import MojitoCore from 'mojito/core';

const { DateTimeUtils } = MojitoCore.Base;
const { selectSport } = MojitoServices.SportsContent.Sports.selectors;
const { selectItems: selectContainerItems } = MojitoServices.SportsContent.ContainerItems.selectors;
const configRegistry = MojitoCore.Services.Config.configRegistry;
const configFactory = MojitoCore.Services.Config.configFactory;
const EventTypes = MojitoServices.SportsContent.Events.types;

// Hack to manually register VirtualsEndpoint config as long as getVirtualSport is using it.
// getVirtualSport should be removed from code in favour to normal selection process from Redux store.
import virtualsEndpointDefaultConfig from 'mojito/core/generated/configs/_virtuals-endpoint.js';
configRegistry.add(virtualsEndpointDefaultConfig);

const HORSE_RACING = ['horse_racing', 'virtual_horse_racing'];
const GREYHOUND_RACING = ['greyhound_racing', 'virtual_greyhound_racing'];

/**
 * RacingHelper offers some helpers for racing components.
 *
 * @class RacingHelper
 * @memberof Mojito.Presentation.Utils
 */
export default class RacingHelper {
    /**
     * Returns an array with all races sorted by the meeting's starting time.
     *
     * @param {Array} meetings - The meetings to retrieve the races from.
     * @returns {Array} Races sorted by meetings' starting time.
     * @function Mojito.Presentation.Utils.RacingHelper.getSortedRacesFromMeetings
     */
    static getSortedRacesFromMeetings(meetings) {
        const sortedMeetings = RacingHelper.sortMeetingsByStartTime(meetings);
        return sortedMeetings.flatMap(meeting => meeting.races);
    }

    /**
     * Sorts list of meetings by the meeting's starting time.
     *
     * @param {Array} meetings - The meetings to sort by starting time.
     * @returns {Array} Meetings sorted by starting time.
     * @function Mojito.Presentation.Utils.RacingHelper.sortMeetingsByStartTime
     */
    static sortMeetingsByStartTime(meetings) {
        return meetings.sort((meeting1, meeting2) =>
            DateTimeUtils.diffInMilliseconds(
                new Date(meeting1.firstEventStartTime),
                new Date(meeting2.firstEventStartTime)
            )
        );
    }

    /**
     * Determines whether the provided sportId corresponds to horse racing.
     *
     * **Note:** If the sport is available in the Sports store, the 'canonicalType' property will be used, otherwise the 'sportId' will be used.
     *
     * @param {string} sportId - The sportId to verify.
     * @returns {boolean} Returns `true` if the sportId corresponds to horse racing, `false` otherwise.
     * @function Mojito.Presentation.Utils.RacingHelper#isHorseRacing
     */
    static isHorseRacing(sportId) {
        const sport = selectSport(sportId);
        const canonicalType = sport?.canonicalType || sportId;
        return HORSE_RACING.includes(canonicalType);
    }

    /**
     * Checks if the provided sportId corresponds to greyhound racing.
     *
     * **Note:** If the sport is available in the Sports store, the 'canonicalType' property will be used, otherwise the 'sportId' will be used!
     *
     * @param {string} sportId - The sportId to verify.
     * @returns {boolean} Returns true if the sportId corresponds to greyhound racing; otherwise, returns false.
     * @function Mojito.Presentation.Utils.RacingHelper#isGreyhoundRacing
     */
    static isGreyhoundRacing(sportId) {
        const sport = selectSport(sportId);
        const canonicalType = sport?.canonicalType || sportId;
        return GREYHOUND_RACING.includes(canonicalType);
    }

    /**
     * Determines whether the provided sportId corresponds to a racing sport.
     *
     * **Note:** The 'isRacing' property is used to identify racing sports!
     *
     * @param {string} sportId - The sportId to verify.
     * @returns {boolean} Returns `true` if the sportId corresponds to a racing sport, `false` otherwise.
     * @function Mojito.Presentation.Utils.RacingHelper#isRacingSport
     */
    static isRacingSport(sportId) {
        const sport = selectSport(sportId)?.id
            ? selectSport(sportId)
            : RacingHelper.getVirtualSport(sportId);

        return Boolean(sport?.isRacing);
    }

    /**
     * Determines whether the provided event is a racing event.
     *
     * @param {object} event - The event object to verify.
     * @returns {boolean} Returns true if the event is a racing event; otherwise, returns false.
     * @function Mojito.Presentation.Utils.RacingHelper#isRacingEvent
     */
    static isRacingEvent(event) {
        return event.eventType === EventTypes.EVENT_TYPE.RACE;
    }

    /**
     * Returns virtual sport.
     *
     * @param {string} sportId - Sport ID.
     * @returns {{id: string, name: string, isRacing: boolean, isVirtual: boolean}} Virtual Sport.
     * @function Mojito.Presentation.Utils.RacingHelper#getVirtualSport
     */
    static getVirtualSport(sportId) {
        const { containerId } = configFactory.getConfig('VirtualsEndpoint');
        const virtualsSports = selectContainerItems(containerId);
        return virtualsSports && virtualsSports.find(({ id }) => id === sportId);
    }

    /**
     * Check if the current time + countdownSeconds + minutesToAdd is bigger than start time in seconds.
     *
     * @param {Date} startTime - A date and time string in ISO 8601 format.
     * @param {number} countdownSeconds - Seconds that should be added to the current time.
     * @param {number} minutesToAdd - Minutes that should be added to the current time.
     *
     * @returns {boolean} True if criteria for countdown is met. False otherwise.
     * @function Mojito.Presentation.Utils.RacingHelper#isCountdownCriteriaMet
     */
    static isCountdownCriteriaMet(startTime, countdownSeconds, minutesToAdd) {
        const startTimeInSeconds = Math.floor(new Date(startTime).getTime() / 1000);
        const timeNowInSeconds = Math.floor(new Date().getTime() / 1000);
        const criteriaForCountdownInSeconds =
            timeNowInSeconds + Math.floor(countdownSeconds + minutesToAdd * 60);

        return criteriaForCountdownInSeconds > startTimeInSeconds;
    }

    /**
     * Get seconds to start of the race.
     *
     * @param {Date} startTime - A date and time string in ISO 8601 format.
     *
     * @returns {number} Seconds to start of the race.
     * @function Mojito.Presentation.Utils.RacingHelper#getSecondsToStart
     */
    static getSecondsToStart(startTime) {
        return Math.floor(DateTimeUtils.diffInSeconds(new Date(startTime), new Date()));
    }

    /**
     * Check if race is forthcoming.
     *
     * @param {object} raceEvent - Race event data.
     *
     * @returns {boolean} True if race is yet to come. False otherwise.
     * @function Mojito.Presentation.Utils.RacingHelper#isForthcoming
     */
    static isForthcoming(raceEvent) {
        const { startTime } = raceEvent;
        const { raceStatus } = raceEvent.details;

        const secondsToStart = RacingHelper.getSecondsToStart(startTime);
        const isUpcoming = raceStatus === EventTypes.RACE_STATUS.UPCOMING;

        return isUpcoming && secondsToStart > 0;
    }

    static isAbandoned(raceEvent) {
        return raceEvent.details.raceState === EventTypes.RACE_STATE.ABANDONED;
    }
    /**
     * Start timer if condition for it is met.
     *
     * @param {object} raceEvent - Race event data.
     * @param {number} countdownMinutes - Indicates how many minutes should be left to show the timer.
     * @param {startTimerCallback} callback - Callback to start the timer.
     *
     * @returns {number|void} If time to show timer hasn't come yet returns timeoutID. Void otherwise.
     * @function Mojito.Presentation.Utils.RacingHelper#raceEventStartupTimer
     */
    static raceEventStartupTimer(raceEvent, countdownMinutes, callback) {
        const { startTime } = raceEvent;

        const secondsToStart = RacingHelper.getSecondsToStart(startTime);
        const countdownSeconds = countdownMinutes * 60;
        const isCountdownCriteriaMet = RacingHelper.isCountdownCriteriaMet(
            startTime,
            countdownSeconds,
            30
        );

        if (RacingHelper.isForthcoming(raceEvent) && isCountdownCriteriaMet) {
            if (secondsToStart > countdownSeconds) {
                const msToSetShowCountdown = (secondsToStart - countdownSeconds) * 1000;

                return setTimeout(() => callback(countdownSeconds), msToSetShowCountdown);
            }

            callback(secondsToStart);
        }
    }
}

/**
 * This callback type is called when the timer should be started.
 *
 * @callback startTimerCallback
 * @param {number} secondsToStart - Seconds to start of the race.
 *
 * @memberof Mojito.Presentation.Utils.RacingHelper
 */
