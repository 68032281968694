import BetsTypes from 'services/bets/types.js';
import { keyBy, mapValues } from 'mojito/utils';

/**
 * Open Bets action types. Exposes the map of stringify action types used within open bets slice.
 * This is a private module used to decouple open bets slice from other slices and listeners across mojito that can be interested
 * in certain open bets actions. This kind of decoupling is mainly useful to unblock tree-shaking and prevent redundant modules to be added in a bundle during application build.
 * Example: sportsbook application has feature to present open bets view for a punter, hence open bets slice and all its dependencies are useful and should be part of the bundle.
 * On the other side, lotto application doesn't have such a feature, so no open bets slice or other related modules should be part of the bundle.
 * Apart from this lotto also has user info slice to handle user specific flows (which is perfectly fine). The problem is that user info slice is listening to cashoutSuccess action from open bets slice to fetch the balance.
 * Open bets actions exported from open bets slice so importing them in user info slice automatically includes the whole open bets slice in a bundle for a lotto.
 * The aim of OpenBetsActionTypes is to prevent this from happening.
 *
 * @module OpenBetsActionTypes
 * @private
 * @memberof Mojito.Services.Bets
 */

export const ACTION_NAMES = keyBy(['cashoutSuccess'], name => name);

export default mapValues(ACTION_NAMES, action => `${BetsTypes.OPEN_BETS_STORE_KEY}/${action}`);
