import {mergeToHierarchy} from '#core/utils/config-utils.js';

import {AbstractFeature, allFeatures} from '#core/application/abstract-feature.js';

import SCHEMA from './currencies.schema.yaml';

class CurrenciesFeature extends AbstractFeature {
    get configSchema() {
        return SCHEMA;
    }

    beforeMojitoConfigBuild(mojitoConfig) {
        mergeToHierarchy(mojitoConfig, 'services.configurations.Currencies', this.config);
        return super.beforeMojitoConfigBuild(mojitoConfig);
    }
}

new CurrenciesFeature(allFeatures);
