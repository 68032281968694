import MojitoCore from 'mojito/core';
import CountryIconImpl from './country-icon';
import PropTypes from 'prop-types';

const { UIView } = MojitoCore.Presentation;

/**
 * `CountryIcon` is a component used to render a country icon.
 *
 * @class CountryIcon
 * @memberof Mojito.Presentation.Components
 */

/**
 * `CountryIcon` component prop types.
 *
 * @property {string} sportId - ID of the sport.
 * @property {string} countryCode - Country code value.
 * @property {string} countryName - Country name value.
 *
 * @memberof Mojito.Presentation.Components.CountryIcon
 */
const propTypes = {
    sportId: PropTypes.string,
    countryCode: PropTypes.string,
    countryName: PropTypes.string,
};

export default UIView('CountryIcon', CountryIconImpl, propTypes);
