import MojitoCore from 'mojito/core';
import SportGroupsListImpl from './sport-groups-list-controller.jsx';
import PropTypes from 'prop-types';
import SingleLeagueCouponTypes from 'modules/single-league-coupon/types.js';

const UIControllerView = MojitoCore.Presentation.UIControllerView;
const { COUPONS } = SingleLeagueCouponTypes;

/**
 * SportGroupsListController is a component that renders sport group as a region with flag and sport items inside.
 *
 * @class SportGroupsListController
 * @memberof Mojito.Modules.SportGroupsList
 */

/**
 * SportGroupsListController prop types.
 *
 * @property {string} sportId - The sport ID of the currently selected sport.
 * @property {string} [groupId] - ID to populate data with (top leagues or all leagues).
 * @property {string} type - Type of leagues to be rendered, one of Mojito.Modules.LeaguesTypes.
 * @property {Function} [onDataLoad] - Callback to get state of child element loading.
 * @property {Mojito.Modules.SingleLeagueCoupon.SingleLeagueCouponTypes.COUPONS} pageType - Tells which type of sport page to display content for.
 * @memberof Mojito.Modules.SportGroupsListController
 */

const propTypes = {
    sportId: PropTypes.string.isRequired,
    groupId: PropTypes.string,
    type: PropTypes.string.isRequired,
    pageType: PropTypes.oneOf(Object.values(COUPONS)).isRequired,
    onDataLoad: PropTypes.func,
};

const defaultProps = {
    groupId: '',
};

export default UIControllerView(
    'SportGroupsListController',
    SportGroupsListImpl,
    propTypes,
    defaultProps
);
