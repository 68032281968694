import {ensureHierarchy} from '#core/utils/config-utils.js';

import {AbstractFeature, allFeatures} from '#core/application/abstract-feature.js';

import {ExternalNavigationService} from './external-navigation-service.js';

import SCHEMA from './external-navigation-service.schema.yaml';

class ExternalNavigationFeature extends AbstractFeature {
    get name() {
        return 'External Navigation';
    }

    get configSchema() {
        return SCHEMA;
    }

    beforeMojitoConfigBuild(mojitoConfig) {
        const externalNavigationService = ensureHierarchy(mojitoConfig, 'services.externalNavigationService');
        externalNavigationService.service = new ExternalNavigationService(this.config);

        return super.beforeMojitoConfigBuild(mojitoConfig);
    }
}

new ExternalNavigationFeature(allFeatures);
