import MojitoCore from 'mojito/core';
const UIView = MojitoCore.Presentation.UIView;
import OutrightsViewImpl from './outrights-view.jsx';

/**
 * OutrightsView - renders outrights content inside SportPage.
 *
 * @class OutrightsView
 * @memberof Mojito.Applications.Sports.Pages
 */
export default UIView('OutrightsView', OutrightsViewImpl);
