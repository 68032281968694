import { useSearchParams, Navigate, createSearchParams } from 'react-router';
import { parsePath } from 'history';
import PropTypes from 'prop-types';

/**
 * This component is used to preserve search parameters while navigating between pages.
 *
 * @example <caption>Render a Custom Redirect component.</caption>
 *
 * const path = '/sports/football';
 * render() {
 *   return <CustomRedirect to={path} />
 * }
 * // Redirects to /sports/football while keeping the URL query/search parameters.
 *
 *
 * @example <caption>Another example</caption>
 *
 * <CustomRedirect
 *  to={"/home"}
 * />
 *
 * // If the path matches exactly "/index.html", the page will be redirected to /home while keeping URL query/search parameters.
 *
 *
 * @class CustomRedirect
 * @memberof Mojito.Presentation.Components
 */

export default function CustomRedirect({ to, isReplace = false }) {
    const [search] = useSearchParams();
    const { pathname, hash = '' } = parsePath(to);
    let searchParams = `${createSearchParams(search)}`;
    searchParams = searchParams !== '' ? `?${searchParams}` : '';

    return <Navigate to={`${pathname}${searchParams}${hash}`} replace={isReplace} />;
}

/**
 * CustomRedirect prop types.
 *
 * @property {string} to - Navigation url.
 * @property {boolean} [isReplace = false] - Replaces the url in history instead of adding a new one.
 * @memberof Mojito.Presentation.Components.CustomRedirect
 */
CustomRedirect.propTypes = {
    to: PropTypes.string.isRequired,
    isReplace: PropTypes.bool,
};
