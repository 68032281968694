import MojitoCore from 'mojito/core';
const UIPage = MojitoCore.Presentation.UIPage;
import HomePageImpl from './home-page.jsx';

/**
 * @namespace HomePage
 * @memberof Mojito.Applications.Sports.Pages
 */

/**
 * The home page.
 *
 * @class HomePage
 * @extends Mojito.Core.Presentation.UIPage
 * @memberof Mojito.Applications.Sports.Pages.HomePage
 */

export default UIPage('HomePage', HomePageImpl);
