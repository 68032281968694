import PropTypes from 'prop-types';
import MojitoCore from 'mojito/core';
import ActiveBonusesImpl from './active-bonuses.jsx';

const UIView = MojitoCore.Presentation.UIView;

/**
 * Component responsible for presenting the view of active bonuses.
 *
 * @name View
 * @class ActiveBonuses
 * @memberof Mojito.Modules.ActiveBonuses
 */

/**
 * `ActiveBonuses` prop types.
 *
 * @property {Mojito.Services.Bonus.types.Campaign[]} campaigns - An array of campaigns.
 * @property {Mojito.Services.Bonus.types.FreeBet[]} freeBets - An array of free bets.
 * @property {Mojito.Services.Bonus.types.OddsBoost[]} oddsBoosts - An array of odds boosts.
 * @property {string} [currencyCode] - The currency code.
 * @property {boolean} [showPrewager = true] - Indicates whether the prewager should be displayed or not.
 * @property {boolean} [showOddsBoosts = true] - Indicates whether the odds boosts should be displayed or not.
 *
 * @memberof Mojito.Modules.ActiveBonuses.View
 */
const propTypes = {
    campaigns: PropTypes.array.isRequired,
    freeBets: PropTypes.array.isRequired,
    oddsBoosts: PropTypes.array,
    currencyCode: PropTypes.string,
    showPrewager: PropTypes.bool,
    showOddsBoosts: PropTypes.bool,
};

const defaultProps = {
    showPrewager: true,
    showOddsBoosts: true,
    oddsBoosts: [],
};

export default UIView('ActiveBonuses', ActiveBonusesImpl, propTypes, defaultProps);
