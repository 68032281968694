/**
 * Sport coupon types.
 *
 * @class SportCouponTypes
 * @memberof Mojito.Applications.Sports.Pages
 */
export default class SportCouponTypes {}

/**
 * A type representing SportCouponTypes CMS_COUPON_PREFIX, used to indicate the CMS coupon prefix.
 *
 * @typedef CMS_COUPON_PREFIX
 * @memberof Mojito.Applications.Sports.Pages.SportCouponTypes
 */
SportCouponTypes.CMS_COUPON_PREFIX = 'cms-coupons-';
