import MojitoCore from 'mojito/core';

import {isMobile} from '#core/utils/context-utils.js';
import {borderStyle, icon} from '#core/utils/style-utils.js';
import {resolveBackground} from '#core/utils/color-utils.js';

import {ApplicationConfig} from '#core/config/application-config.js';

import {generateSecondaryButton} from '#core/application/modules/tiles/button/index.js';
import {generateInfoNotification} from '#core/application/modules/tiles/notification/index.js';
import {generateBetBaseInfo} from '#core/application/modules/tiles/bet-base-info/index.js';
import {generateBetStatusIcon} from '#core/application/modules/tiles/bet-status-icon/index.js';
import {generateSecondaryDropdown} from '#core/application/modules/tiles/dropdown/index.js';

import {BetHistory} from './index.js';

const merge = MojitoCore.Presentation.uiStyle.merge;

export function betHistoryFactory(PALETTE) {
    const getBetHistoryView =
        isMobile() || (ApplicationConfig.isEmbedded && BetHistory.widgetDesktopLayout === 'compact')
            ? getCompactBetHistoryView
            : getDesktopBetHistoryView;

    return {
        ...getBetHistoryView(PALETTE),
        ...getBetHistoryDetails(PALETTE),
    };
}

const BONUS_ICON_COMMON = {
    minWidth: '12px',
    minHeight: '12px',
    width: '12px',
};

function getDesktopBetHistoryView(PALETTE) {
    const {FONT} = PALETTE;

    return {
        BetHistoryView: {
            style: {
                emptyContent: {
                    ...resolveBackground(PALETTE.BET_HISTORY_EMPTY_CONTENT_CONTAINER_BG),
                },
            },
            filters: {
                filterContainer: {
                    itemSpacing: '8px',
                    style: {
                        ...resolveBackground(PALETTE.BET_HISTORY_CONTAINER_BG),
                        flexDirection: 'row-reverse',
                        justifyContent: 'flex-end',
                        padding: '0px 0px 16px 0px',
                    },
                },
                filterRangeRowContainer: {
                    style: {
                        maxWidth: '165px',
                    },
                },
                filterStatusRowContainer: {
                    style: {
                        maxWidth: '165px',
                    },
                },
                dropdown: merge(generateSecondaryDropdown(PALETTE.BET_HISTORY_FILTERS_DROPDOWN), {
                    menuItem: {
                        style: {
                            base: {
                                justifyContent: 'start',
                                minHeight: '40px',
                            },
                        },
                    },
                }),
            },
            // table
            tableContainer: {
                style: {
                    ...resolveBackground(PALETTE.BET_HISTORY_TABLE_CONTAINER_BG),
                    maxHeight: '600px',
                },
            },
            betsList: {
                style: {
                    minWidth: '914px',
                    gridAutoRows: 'minmax(64px, auto) 0',
                    gridTemplateColumns: '3fr 3fr 6fr 3fr 2fr 3fr 3fr', // fix mojito
                },
            },
            headerItemsContainer: {
                style: {
                    minHeight: '34px', // according to RBM
                    height: '34px',
                    maxHeight: '34px',
                    minWidth: '914px',
                    fontSize: FONT.SIZE_SMALL,
                    fontWeight: FONT.WEIGHT_SEMI_BOLD,
                    gridTemplateColumns: '3fr 3fr 6fr 3fr 2fr 3fr 3fr', // fix mojito
                    ...resolveBackground(PALETTE.BET_HISTORY_TABLE.TABLE_HEADER_BG),
                    ...PALETTE.BET_HISTORY_TABLE.TABLE_HEADER_TEXT,
                },
            },
            headerItem: {
                base: {
                    style: {
                        padding: 0,
                    },
                },
                date: {
                    style: {
                        padding: '0 0 0 8px',
                    },
                },
                status: {
                    style: {
                        padding: '0 0 0 28px',
                    },
                },
            },
            rowItem: {
                base: {
                    style: {
                        ...resolveBackground(PALETTE.BET_HISTORY_TABLE.TABLE_ITEM_BG),
                        fontSize: FONT.SIZE_MEDIUM,
                        gap: 4,
                        ...PALETTE.BET_HISTORY_TABLE.TABLE_ITEM_TEXT,
                    },
                },
                status: {
                    style: {
                        padding: '0 0 0 28px',
                    },
                },
            },
            selectedRowItem: {
                base: {
                    style: {
                        ...resolveBackground(PALETTE.BET_HISTORY_TABLE.TABLE_ITEM_SELECTED_BG),
                        fontSize: FONT.SIZE_MEDIUM,
                        gap: 4,
                        ...PALETTE.BET_HISTORY_TABLE.TABLE_ITEM_SELECTED_TEXT,
                    },
                },
            },
            typeText: {
                container: {
                    padding: '0 8px 0 0',
                },
            },
            selectionNameContainer: {
                itemSpacing: 0,
            },
            selectionName: {
                container: {
                    wordBreak: 'break-word',
                    padding: '0 8px 0 0',
                },
            },
            stakeInfo: {
                style: {
                    padding: 0,
                },
            },
            stakeText: {
                container: {
                    padding: 0,
                },
            },
            returnsText: {
                container: {
                    padding: 0,
                },
            },
            timeText: {
                style: {
                    fontSize: 12,
                },
            },
            betStatusContainer: {
                style: {
                    padding: 0,
                },
            },
            betStatusIcon: generateBetStatusIcon(PALETTE.BET_HISTORY_BET_STATUS_ICON),
            betStatusText: {
                style: {
                    textTransform: 'initial',
                    ...PALETTE.BET_HISTORY_TABLE.TABLE_ITEM_TEXT,
                },
            },
            bonusIcon: merge(icon(PALETTE.BET_HISTORY_TABLE.TABLE_ITEM_ICON_COLOR), {
                style: {
                    ...BONUS_ICON_COMMON,
                },
            }),
            freeBetIcon: merge(icon(PALETTE.BET_HISTORY_TABLE.TABLE_ITEM_ICON_COLOR), {
                style: {
                    ...BONUS_ICON_COMMON,
                },
            }),
            accaBoostIcon: merge(icon(PALETTE.BET_HISTORY_TABLE.TABLE_ITEM_ICON_COLOR), {
                style: {
                    ...BONUS_ICON_COMMON,
                },
            }),
            oddsBoostIcon: merge(icon(PALETTE.BET_HISTORY_TABLE.TABLE_ITEM_ICON_COLOR), {
                style: {
                    ...BONUS_ICON_COMMON,
                },
            }),
            gridSeparator: {
                bottomSeparator: {
                    style: {
                        height: '1px',
                        backgroundColor: PALETTE.BET_HISTORY_TABLE.TABLE_ITEM_DIVIDER_COLOR,
                    },
                },
            },
            // error
            errorContainer: {
                style: {
                    ...resolveBackground(PALETTE.BET_HISTORY_ERROR_CONTAINER_BG),
                    padding: 16,
                    fontSize: FONT.SIZE_MEDIUM,
                    ...PALETTE.BET_HISTORY_ERROR_TEXT,
                },
            },
            tryAgainButton: generateSecondaryButton(PALETTE.BET_HISTORY_ERROR_TRY_AGAIN_BUTTON),
            // empty content
            statusLabel: {
                style: {
                    fontSize: FONT.SIZE_MEDIUM,
                    ...PALETTE.BET_HISTORY_EMPTY_CONTENT_MESSAGE.EMPTY_CONTENT_MESSAGE_TEXT,
                },
            },
            spinner: {
                image: icon(PALETTE.BET_HISTORY_EMPTY_CONTENT_MESSAGE.EMPTY_CONTENT_MESSAGE_SPINNER_ICON_COLOR),
            },
        },
    };
}

function getCompactBetHistoryView(PALETTE) {
    const {FONT} = PALETTE;

    const BET_HISTORY_MOBILE_BET_BASE_INFO = generateBetBaseInfo(PALETTE.BET_HISTORY_MOBILE_BET_BASE_INFO);
    const BET_HISTORY_MOBILE_STAKE_BONUS_ICON = merge(BET_HISTORY_MOBILE_BET_BASE_INFO.betFundsInfo.medium.icon, {
        style: {
            ...BONUS_ICON_COMMON,
        },
    });

    return {
        BetHistoryView: {
            style: {
                emptyContent: {
                    ...resolveBackground(PALETTE.BET_HISTORY_MOBILE_EMPTY_CONTENT_CONTAINER_BG),
                },
                itemContainer: {
                    ...resolveBackground(PALETTE.BET_HISTORY_MOBILE_ITEM_BG),
                    _mobile_margin: 0, // override mojito's default
                },
                itemRow: {
                    margin: 0,
                },
                selectionNames: {
                    margin: 0,
                    maxWidth: '190px',
                },
            },
            filters: {
                filterContainer: {
                    itemSpacing: '8px',
                    style: {
                        ...resolveBackground(PALETTE.BET_HISTORY_MOBILE_CONTAINER_BG),
                        flexDirection: 'row-reverse',
                        justifyContent: 'flex-end',
                        padding: '8px',
                    },
                },
                filterRangeRowContainer: {
                    style: {
                        flexBasis: 0,
                    },
                },
                filterStatusRowContainer: {
                    style: {
                        flexBasis: 0,
                    },
                },
                dropdown: merge(generateSecondaryDropdown(PALETTE.BET_HISTORY_MOBILE_FILTERS_DROPDOWN), {
                    menuItem: {
                        style: {
                            base: {
                                justifyContent: 'start',
                                minHeight: '40px',
                            },
                        },
                    },
                }),
            },
            item: {
                style: {
                    base: {
                        _mobile_padding: '0 16px',
                    },
                },
            },
            itemHeader: {
                style: {
                    padding: '8px 0',
                    alignItems: 'flex-start',
                },
            },
            itemSeparator: {
                style: {
                    color: PALETTE.BET_HISTORY_MOBILE_ITEM_SEPARATOR_COLOR,
                },
            },
            priceInfoContainer: {
                style: {
                    flexDirection: 'column',
                    _mobile_padding: '8px 0 16px 0',
                },
            },
            stakeContainer: {
                style: {
                    flexDirection: 'row',
                    padding: '0 0 4px',
                },
            },
            typeText: {
                style: BET_HISTORY_MOBILE_BET_BASE_INFO.title,
            },
            betStatusContainer: {
                style: {
                    padding: '0 0 0 28px',
                },
            },
            betStatusIcon: BET_HISTORY_MOBILE_BET_BASE_INFO.betStatusIcon,
            betStatusText: {
                style: BET_HISTORY_MOBILE_BET_BASE_INFO.betStatus,
            },
            dateText: {
                style: BET_HISTORY_MOBILE_BET_BASE_INFO.date,
            },
            // selection info (name and stake)
            selectionNameContainer: {
                itemSpacing: 4,
            },
            selectionName: {
                style: {
                    wordBreak: 'break-word',
                    ...BET_HISTORY_MOBILE_BET_BASE_INFO.selectionName,
                },
            },
            stakeText: {
                style: BET_HISTORY_MOBILE_BET_BASE_INFO.odds,
            },
            // stake info (label, value and icons)
            stakeLabel: BET_HISTORY_MOBILE_BET_BASE_INFO.betFundsInfo.medium.label,
            stakeValue: BET_HISTORY_MOBILE_BET_BASE_INFO.betFundsInfo.medium.value,
            // return info (label and value)
            returnsLabel: BET_HISTORY_MOBILE_BET_BASE_INFO.betFundsInfo.medium.label,
            returnsValue: BET_HISTORY_MOBILE_BET_BASE_INFO.betFundsInfo.medium.value,
            // bonus icons
            bonusIcon: BET_HISTORY_MOBILE_STAKE_BONUS_ICON,
            freeBetIcon: BET_HISTORY_MOBILE_STAKE_BONUS_ICON,
            accaBoostIcon: BET_HISTORY_MOBILE_STAKE_BONUS_ICON,
            oddsBoostIcon: BET_HISTORY_MOBILE_STAKE_BONUS_ICON,
            // error
            errorContainer: {
                style: {
                    ...resolveBackground(PALETTE.BET_HISTORY_MOBILE_ERROR_CONTAINER_BG),
                    padding: 16,
                    fontSize: FONT.SIZE_MEDIUM,
                    ...PALETTE.BET_HISTORY_MOBILE_ERROR_TEXT,
                },
            },
            tryAgainButton: generateSecondaryButton(PALETTE.BET_HISTORY_MOBILE_ERROR_TRY_AGAIN_BUTTON),
            // empty content
            statusLabel: {
                container: {
                    padding: '72px 16px 16px',
                },
                style: {
                    fontSize: FONT.SIZE_MEDIUM,
                    textAlign: 'center',
                    ...PALETTE.BET_HISTORY_MOBILE_EMPTY_CONTENT_MESSAGE.EMPTY_CONTENT_MESSAGE_TEXT,
                },
            },
            spinner: {
                image: icon(PALETTE.BET_HISTORY_MOBILE_EMPTY_CONTENT_MESSAGE.EMPTY_CONTENT_MESSAGE_SPINNER_ICON_COLOR),
            },
            priceBoostIndicator: merge(BET_HISTORY_MOBILE_BET_BASE_INFO.priceBoostValue, {
                container: {
                    style: {
                        alignItems: 'flex-start',
                    },
                },
            }),
            oddsBoostNotification: BET_HISTORY_MOBILE_BET_BASE_INFO.oddsBoostLabel,
        },
    };
}

function getBetHistoryDetails(PALETTE) {
    const {FONT} = PALETTE;

    const BET_STATUS_ICON = {
        style: {
            minWidth: '12px',
            minHeight: '12px',
        },
    };
    const BET_HISTORY_DETAILS_BET_BASE_INFO = generateBetBaseInfo(PALETTE.BET_HISTORY_DETAILS_BET_BASE_INFO);
    const BET_HISTORY_DETAILS_PROMOTIONS_NOTIFICATION = generateInfoNotification(
        PALETTE.BET_HISTORY_DETAILS_NOTIFICATION
    );
    const BET_STATUS_ICON_STYLES = merge(BET_HISTORY_DETAILS_BET_BASE_INFO.betStatusIcon, {
        won: BET_STATUS_ICON,
        lost: BET_STATUS_ICON,
        void: BET_STATUS_ICON,
        open: BET_STATUS_ICON,
        cashedOut: BET_STATUS_ICON,
        cancelled: BET_STATUS_ICON,
    });

    return {
        BetHistoryDetailsController: {
            loadingLabel: {
                container: {
                    textAlign: 'center',
                },
                style: {
                    fontSize: FONT.SIZE_MEDIUM,
                    ...PALETTE.BET_HISTORY_DETAILS_LOADING_TEXT,
                },
            },
        },
        BetHistoryDetails: {
            style: {
                headerScrolled: {
                    boxShadow: '1px 2px 0 rgba(0, 0, 0, 0.2)',
                    padding: 8,
                    ...resolveBackground(PALETTE.BET_HISTORY_DETAILS_BG),
                },
            },
            // header
            header: {
                style: {
                    ...resolveBackground(PALETTE.BET_HISTORY_DETAILS_BG),
                    _desktop_padding: 8, // override Moj default
                    padding: 8,
                },
            },
            betTypeContainer: {
                style: {
                    padding: '0 8px 0 0',
                    _desktop_padding: '0 8px 0 0',
                },
            },
            betTypeText: {
                style: BET_HISTORY_DETAILS_BET_BASE_INFO.title,
            },
            dateText: {
                style: BET_HISTORY_DETAILS_BET_BASE_INFO.date,
            },
            betStatusContainer: {
                style: {
                    height: 20,
                    alignItems: 'center',
                },
            },
            betStatusText: {
                style: BET_HISTORY_DETAILS_BET_BASE_INFO.betStatus,
            },
            legStatusIcon: BET_STATUS_ICON_STYLES,
            betStatusIcon: BET_STATUS_ICON_STYLES,
            closeButton: {
                style: {
                    base: {
                        padding: 0,
                    },
                },
            },
            closeIcon: icon(PALETTE.BET_HISTORY_DETAILS_CLOSE_BUTTON.CLOSE_BUTTON_ICON_COLOR, 'close'),
            // content
            content: {
                style: {
                    ...resolveBackground(PALETTE.BET_HISTORY_DETAILS_BG),
                    fontSize: FONT.SIZE_MEDIUM,
                    _desktop_maxHeight: '546px',
                    _desktop_width: 'auto', // reset fixed width set in MOJ-10670 in favor of flex property
                    flexBasis: '51%',
                    padding: 0,
                },
            },
            legInfo: {
                style: {
                    padding: 0,
                },
            },
            legs: {
                separator: {
                    style: {
                        color: PALETTE.BET_HISTORY_DETAILS_SEPARATOR_COLOR,
                        size: PALETTE.BET_HISTORY_DETAILS_LEGS_WITH_SEPARATOR ? 1 : 0,
                    },
                },
                style: {
                    padding: '8px 16px',
                },
            },
            betInfo: {
                style: {
                    borderWidth: '0 0 1px 0',
                    borderStyle: 'solid',
                    borderColor: PALETTE.BET_HISTORY_DETAILS_SEPARATOR_COLOR,
                    // need to remove margin from here when it will be possible to somehow make border shorter
                    padding: '8px 0',
                    margin: '0 8px',
                },
            },
            bestOddsIcon: icon(PALETTE.BET_HISTORY_BEST_ODDS_GUARANTEED_ICON_COLOR),
            receiptTextInfo: {
                style: {
                    fontSize: 13,
                    ...PALETTE.BET_HISTORY_DETAILS_RECEIPT_INFO_TEXT,
                },
            },
            settleBetInfoContainer: {
                style: {
                    fontSize: 13,
                    ...PALETTE.BET_HISTORY_DETAILS_RECEIPT_INFO_TEXT,
                },
            },
            channelLabel: {
                style: {
                    ...(GENBET_BASED ? {display: 'none'} : {}), // Mojito like
                },
            },
            channelValue: {
                style: {
                    ...(GENBET_BASED ? {display: 'none'} : {}),
                },
            },
            betHeaderInfoContainer: {
                style: {
                    padding: '0px 0px 4px 0px',
                },
            },
            betLabel: {
                style: {
                    fontSize: 13,
                    fontWeight: 'inherits', // Override mojito's one
                    ...PALETTE.BET_HISTORY_DETAILS_RECEIPT_INFO_TEXT,
                },
            },
            totalPayable: {
                betInfo: BET_HISTORY_DETAILS_BET_BASE_INFO.betFundsInfo.emphasized,
            },
            primaryTotalStake: {
                betInfo: BET_HISTORY_DETAILS_BET_BASE_INFO.betFundsInfo.emphasized,
            },
            secondaryTotalStake: {
                betInfo: BET_HISTORY_DETAILS_BET_BASE_INFO.betFundsInfo.normal,
            },
            prewager: {
                cash: BET_HISTORY_DETAILS_BET_BASE_INFO.betFundsInfo.normal,
                bonus: BET_HISTORY_DETAILS_BET_BASE_INFO.betFundsInfo.normal,
            },
            freebetStake: {
                betInfo: merge(BET_HISTORY_DETAILS_BET_BASE_INFO.betFundsInfo.normal, {
                    icon: {
                        style: {
                            display: 'none',
                        },
                    },
                }),
            },
            cashStake: {
                betInfo: BET_HISTORY_DETAILS_BET_BASE_INFO.betFundsInfo.normal,
            },
            stakeTax: {
                betInfo: BET_HISTORY_DETAILS_BET_BASE_INFO.betFundsInfo.normal,
            },
            totalPayoutInfo: {
                container: {
                    itemSpacing: 0,
                },
                payout: {
                    betInfo: BET_HISTORY_DETAILS_BET_BASE_INFO.betFundsInfo.emphasized,
                },
                prewager: {
                    cash: BET_HISTORY_DETAILS_BET_BASE_INFO.betFundsInfo.normal,
                    bonus: BET_HISTORY_DETAILS_BET_BASE_INFO.betFundsInfo.normal,
                },
                tax: {
                    betInfo: BET_HISTORY_DETAILS_BET_BASE_INFO.betFundsInfo.normal,
                },
                payoutAfterTax: {
                    betInfo: BET_HISTORY_DETAILS_BET_BASE_INFO.betFundsInfo.emphasized,
                },
            },
            oddsBoostInfo: {
                betInfo: BET_HISTORY_DETAILS_BET_BASE_INFO.betFundsInfo.normal,
            },
            betBonusPromotions: {
                accaInsuranceNotification: BET_HISTORY_DETAILS_PROMOTIONS_NOTIFICATION,
                accaBoostNotification: BET_HISTORY_DETAILS_PROMOTIONS_NOTIFICATION,
                etbNotification: BET_HISTORY_DETAILS_PROMOTIONS_NOTIFICATION,
            },
            detailsLabel: {
                container: {
                    ...resolveBackground(PALETTE.BET_HISTORY_DETAILS_LEGS_HEADER_BG),
                    padding: 8,
                },
                style: {
                    fontSize: FONT.SIZE_MEDIUM,
                    textTransform: 'uppercase',
                    ...PALETTE.BET_HISTORY_DETAILS_LEGS_HEADER_TEXT,
                },
            },
            priceInfoContainer: {
                style: {
                    alignItems: 'flex-start',
                },
            },
            selectionName: {
                style: BET_HISTORY_DETAILS_BET_BASE_INFO.selectionName,
            },
            oddsLabel: {
                style: BET_HISTORY_DETAILS_BET_BASE_INFO.odds,
            },
            priceBoostIndicator: BET_HISTORY_DETAILS_BET_BASE_INFO.priceBoostValue,
            marketName: {
                style: BET_HISTORY_DETAILS_BET_BASE_INFO.marketName,
            },
            eventName: {
                style: BET_HISTORY_DETAILS_BET_BASE_INFO.eventName,
            },
            legDateTimeContainer: {
                style: {
                    padding: '4px 0 0 20px',
                },
            },
            legDateInfoText: {
                style: {
                    fontSize: FONT.SIZE_XSMALL,
                    ...PALETTE.BET_HISTORY_DETAILS_LEG_DATE_INFO_TEXT,
                },
            },
            totalOdds: BET_HISTORY_DETAILS_BET_BASE_INFO.betTotalOdds,
            // matchAcca
            matchAccaLeg: {
                style: {
                    alignItems: 'baseline',
                },
            },
            matchAccaHeaderText: {
                style: BET_HISTORY_DETAILS_BET_BASE_INFO.betBuilderSelectionName,
            },
            matchAccaEventName: {
                style: BET_HISTORY_DETAILS_BET_BASE_INFO.betBuilderMarketName,
            },
            matchAccaSelectionItem: BET_HISTORY_DETAILS_BET_BASE_INFO.betBuilderSelectionItem,
            downloadBetPdfButton: {
                button: {
                    style: {
                        base: {
                            alignSelf: 'center',
                            alignItems: 'center',
                            fontSize: FONT.SIZE_MEDIUM,
                            fontWeight: FONT.WEIGHT_SEMI_BOLD,
                            height: '40px',
                            width: '200px',
                            ...PALETTE.BET_HISTORY_DETAILS_DOWNLOAD_PDF_BUTTON.TERTIARY_BUTTON_TEXT,
                        },
                    },
                },
                icon: {
                    style: {
                        fill: PALETTE.BET_HISTORY_DETAILS_DOWNLOAD_PDF_BUTTON.TERTIARY_BUTTON_ICON_COLOR,
                    },
                },
            },
            errorContainer: {
                style: {
                    ...resolveBackground(PALETTE.BET_HISTORY_DETAILS_NOTIFICATION.ERROR_BG),
                    ...borderStyle(
                        PALETTE.BET_HISTORY_DETAILS_NOTIFICATION.ERROR_BORDER_RADIUS,
                        PALETTE.BET_HISTORY_DETAILS_NOTIFICATION.ERROR_BORDER_COLOR
                    ),
                },
            },
            errorLabel: {
                style: {
                    fontSize: FONT.SIZE_MEDIUM,
                    ...PALETTE.BET_HISTORY_DETAILS_NOTIFICATION.ERROR_TEXT,
                },
            },
            earlyPayoutIndicator: BET_HISTORY_DETAILS_BET_BASE_INFO.earlyPayoutIndicator,
            oddsBoostNotification: BET_HISTORY_DETAILS_BET_BASE_INFO.oddsBoostLabel,
            matchAccaOddsBoostNotification: merge(BET_HISTORY_DETAILS_BET_BASE_INFO.oddsBoostLabel, {
                container: {
                    style: {
                        padding: '0 0 4px 20px',
                    },
                },
            }),
        },
    };
}
