import React from 'react';
import {connect} from 'react-redux';

import MojitoCore from 'mojito/core';
import MojitoModules from 'mojito/modules';
import MojitoServices from 'mojito/services';

import {MobileContext} from '#core/utils/context-utils.js';
import {generateUniqueId} from '#core/utils/utils.js';
import {getResettingCSSForContainerId} from '#core/utils/embedding-utils.js';

import AbstractWidget from '#core/widgets/AbstractWidget.js';

import {StoreProviderWrapper} from './wrappers/index.js';
import {NotAuthenticatedMessage, BonusesAreLoadingMessage} from './components/message.jsx';

const InProgressBonuses = MojitoModules.InProgressBonuses;

const AnalyticsContextExtender = MojitoCore.Presentation.AnalyticsContext.ContextExtender;
const AnalyticsContextBuilder = MojitoCore.Presentation.AnalyticsContext.ContextBuilder;

const {BONUS_FILTER} = MojitoServices.Bonus.types;
const {selectors: authenticationSelectors} = MojitoServices.Authentication;
const {selectors: campaignSelectors, actions: campaignActions} = MojitoServices.BonusCampaign;
const {selectors: userInfoSelectors} = MojitoServices.UserInfo;

class WidgetInProgressBonusesImpl extends AbstractWidget {
    constructor(props) {
        super(props);

        this.widgetId = 'widget-in-progress-bonuses-' + generateUniqueId();

        this.analyticsContext = new AnalyticsContextBuilder().withAnalyticsPath('WidgetInProgressBonuses').build();
    }

    componentDidMount() {
        this.props.dispatch(campaignActions.getCampaigns(BONUS_FILTER.ACTIVE));
    }

    render() {
        const {isLoggedIn, isLoading, currencyCode, inProgressBonuses} = this.props;

        if (!isLoggedIn) return <NotAuthenticatedMessage />;

        return (
            <AnalyticsContextExtender value={this.analyticsContext}>
                <MobileContext>
                    <style
                        type="text/css"
                        dangerouslySetInnerHTML={{__html: getResettingCSSForContainerId(this.widgetId)}}
                    ></style>
                    <div id={this.widgetId}>
                        {isLoading ? (
                            <BonusesAreLoadingMessage />
                        ) : (
                            <InProgressBonuses currencyCode={currencyCode} inProgressBonuses={inProgressBonuses} />
                        )}
                    </div>
                </MobileContext>
            </AnalyticsContextExtender>
        );
    }
}

const mapStateToProps = state => ({
    isLoggedIn: authenticationSelectors.isLoggedIn(state),
    inProgressBonuses: campaignSelectors.selectInprogressBonusCampaigns(state),
    currencyCode: userInfoSelectors.selectCurrency(state),
    isLoading: campaignSelectors.selectIsPending(state),
});

export const WidgetInProgressBonuses = StoreProviderWrapper(
    'WidgetInProgressBonuses',
    connect(mapStateToProps)(WidgetInProgressBonusesImpl)
);
