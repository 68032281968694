import MojitoPresentation from 'mojito/presentation';
import MojitoServices from 'mojito/services';

import { useSelector } from 'react-redux';
const { selectAllowEarlyPayout } = MojitoServices.UserInfo.selectors;

const { Text, FlexPane, EarlyPayoutIndicator } = MojitoPresentation.Components;

const EarlyPayoutOffers = props => {
    const {
        eventGroup,
        mojitoTools: { config, stringResolver },
    } = props;
    const allowEarlyPayout = useSelector(selectAllowEarlyPayout);

    if (!eventGroup.earlyPayout || !allowEarlyPayout) {
        return null;
    }

    return (
        <FlexPane config={config.offersContainer}>
            <Text config={config.offersText}>
                {stringResolver.resolveString('$EARLY_PAYOUT_OFFERS.OFFERS')}
            </Text>
            <EarlyPayoutIndicator
                rules={eventGroup.earlyPayout.rules}
                config={config.earlyPayoutIndicator}
            />
        </FlexPane>
    );
};

export default EarlyPayoutOffers;
