import {resolveBackground} from '#core/utils/color-utils.js';
import {borderStyle} from '#core/utils/style-utils.js';

import {registerTileSchema} from '#core/application/schema-registry.js';

import {constructTilePalette} from '#core/application/modules/tiles-cache.js';

import {generateDropdownMenu} from '../dropdown-menu/index.js';

import PRIMARY_DROPDOWN from './primary-dropdown.palette-schema.yaml';

registerTileSchema(PRIMARY_DROPDOWN);

export function generatePrimaryDropdown(TILE) {
    const {FONT} = TILE;

    const DROPDOWN_MENU_TILE = constructTilePalette('DROPDOWN.MENU');

    return {
        style: {
            rootContainer: {
                height: 40,
            },
        },
        controlButton: {
            style: {
                base: {
                    borderWidth: 0,
                    _mobile_maxWidth: 'calc((100vw - 40px) / 2)',
                    fontSize: FONT.SIZE_MEDIUM,
                    justifyContent: 'space-between',
                    ...borderStyle(TILE.PRIMARY_DROPDOWN_BORDER_RADIUS, TILE.PRIMARY_DROPDOWN_BORDER_COLOR),
                    ...resolveBackground(TILE.PRIMARY_DROPDOWN_BG),
                    gap: 8,
                    ...TILE.PRIMARY_DROPDOWN_TEXT,
                    fill: TILE.PRIMARY_DROPDOWN_ICON_COLOR,
                },
                disabled: {
                    ...resolveBackground(TILE.PRIMARY_DROPDOWN_DISABLED_BG),
                    ...TILE.PRIMARY_DROPDOWN_DISABLED_TEXT,
                    fill: TILE.PRIMARY_DROPDOWN_DISABLED_ICON_COLOR,
                    borderWidth: 0,
                },
            },
        },
        controlText: {
            style: {
                fontSize: 'inherit',
                fontWeight: FONT.WEIGHT_NORMAL,
                color: 'inherit', // It is workaround, because mojito don't support text/icon styles for disabled state
            },
        },
        rotation: {
            image: {
                style: {
                    padding: 0,
                    minHeight: 8,
                    minWidth: 8,
                    width: 8,
                    fill: 'inherit', // It is workaround, because mojito don't support text/icon styles for disabled state
                },
            },
        },
        ...generateDropdownMenu(DROPDOWN_MENU_TILE),
    };
}
