import { useCallback, useDeferredValue, useEffect, useMemo, useRef } from 'react';
import MojitoPresentation from 'mojito/presentation';
import { debounce } from 'mojito/utils';
import MojitoCore from 'mojito/core';
import awaitingClientsRegistry from 'modules/performance/awaiting-clients-registry.js';
const { useAppContext } = MojitoCore.Presentation.Hooks;
const { PAGE_READY, PAGE_INTERACTED } = MojitoCore.Services.Performance.types.TIMELINE_RECORD_TYPES;
const { useComponentVisibility } = MojitoPresentation.Hooks;

/**
 * Contains custom hooks for performance monitoring and reporting.
 *
 * @class Hooks
 * @memberof Mojito.Modules.Performance
 */

/**
 * Hook keeps track on clients (components) that are still in pending state and are waiting for a data to be rendered.
 * It will return function that should be called in order to sync (add) clients in awaiting registry once data has been arrived.
 *
 * @function useAwaitingClientsRegistry
 *
 * @param {string} name - Name of the component.
 * @param {string} [instanceId] - The id of component instance.
 *
 * @returns {Function} Function should be called to sync awaiting clients in registry. It accepts load done flag as an argument.
 * @memberof Mojito.Modules.Performance.Hooks
 */
export const useAwaitingClientsRegistry = (name, instanceId) => {
    useEffect(() => {
        return () => awaitingClientsRegistry.remove(instanceId);
    }, [instanceId]);

    return useCallback(
        loadDone => {
            if (instanceId && !loadDone) {
                awaitingClientsRegistry.add(instanceId, name);
            }
        },
        [name, instanceId]
    );
};

/**
 * A custom React hook that logs global user interactions with the application. Quasi singleton.
 *
 * This hook sets up event listeners for common user interactions (touch, scroll)
 * and logs the first interaction that occurs. After the first interaction is logged, all event listeners
 * are removed to prevent further logging.
 *
 * @function useGlobalInteractionMetric
 *
 * @param {boolean} eventsToRegister - Indicates when loading is done with true, otherwise false.
 *
 * @memberof Mojito.Modules.Performance.Hooks
 */
export const useGlobalInteractionMetric = eventsToRegister => {
    const abortControllerRef = useRef(new AbortController());
    const { performanceEmitter } = useAppContext();

    const onInteraction = useCallback(
        event => {
            abortControllerRef.current.abort();
            performanceEmitter.emitPerformanceMetric(PAGE_INTERACTED, { eventType: event.type });
        },
        [performanceEmitter]
    );

    useMemo(() => {
        eventsToRegister.forEach(eventType => {
            const abortSignal = abortControllerRef.current.signal;
            window.addEventListener(eventType, onInteraction, {
                passive: true,
                signal: abortSignal,
            });
        });
    }, [eventsToRegister, onInteraction]);

    useEffect(() => () => abortControllerRef.current.abort(), []);
};

/**
 * Deletes an entry of the list of loading components if available and does reporting.
 *
 * @function useAwaitingClientsLoadDone
 *
 * @param {boolean} loadDone - Indicates when loading is done with true, otherwise false.
 * @param {string} [instanceId] - Component instance id.
 *
 * @memberof Mojito.Modules.Performance.Hooks
 */
export const useAwaitingClientsLoadDone = (loadDone, instanceId) => {
    const { performanceEmitter, pathname } = useAppContext();
    const loadDoneDeferred = useDeferredValue(loadDone);
    useEffect(() => {
        if (loadDoneDeferred && awaitingClientsRegistry.has(instanceId)) {
            awaitingClientsRegistry.remove(instanceId);
            if (awaitingClientsRegistry.isEmpty()) {
                scheduleEmitPageReady(performanceEmitter, pathname, performance.now());
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadDoneDeferred, instanceId]);
};

/**
 * The hook will report Manage client visibility and load completion.
 *
 * @function useLoadingStatusMetric
 *
 * @param {boolean} loadDone - Indicates when loading is done with true, otherwise false.
 * @param {string} instanceId - Component instance id.
 * @param {string} name - Name of the component.
 *
 * @returns {React.RefObject} Reference to the client element.
 * @memberof Mojito.Modules.Performance.Hooks
 */
export const useLoadingStatusMetric = (loadDone, instanceId, name) => {
    const syncAwaitingClients = useAwaitingClientsRegistry(name, instanceId);

    const doClientsSync = useCallback(
        () => syncAwaitingClients(loadDone),
        [loadDone, syncAwaitingClients]
    );

    const elementRef = useComponentVisibility(doClientsSync);
    useAwaitingClientsLoadDone(loadDone, instanceId);
    return elementRef;
};

// We need to slightly debounce PAGE_READY metric emit in order to make sure that no new awaiting clients
// will be added to the awaitingClientsRegistry due to layout shift. For example if event cards or pre-built bets are not available
// then their skeletons will be eventually unmounted and top sport coupons will be shown. In this case top sport coupons and events will be added
// to awaitingClientsRegistry and we need to wait until they are loaded to report PAGE_READY. The actual PAGE_READY metric will be reported with the original timestamp
// to disregard debounce time shift in analytic reports.
const scheduleEmitPageReady = debounce((performanceEmitter, pathname, timestamp) => {
    if (awaitingClientsRegistry.isEmpty()) {
        performanceEmitter.emitPerformanceMetric(PAGE_READY, { pathname, timestamp });
    }
}, 100);
