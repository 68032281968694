const collatorMap = new Map();

/**
 * The SortingHelper offers utility functions for sorting.
 *
 * @class SortingHelper
 * @memberof Mojito.Presentation.Utils
 */
export default class SortingHelper {
    /**
     * Performs a language-sensitive comparison between two strings.
     *
     * For performance reasons, this method should be used instead of {@link String.prototype.localeCompare}. It creates and reuses one collator per language.
     *
     * @param {string} a - The first string to be compared.
     * @param {string} b - The second string to be compared.
     * @param {string} language - The language to be used when comparing the two strings. The format should adhere to BCP 47 language tags.
     * @returns {number} A number indicating the order of the strings:
     * - Less than 0 if 'a' should be sorted before 'b'.
     * - Greater than 0 if 'b' should be sorted before 'a'.
     * - 0 if 'a' and 'b' are equal.
     * @function Mojito.Presentation.Utils.SortingHelper.compare
     */
    static compare(a, b, language) {
        if (window.Intl && typeof window.Intl === 'object' && window.Intl.Collator) {
            if (!collatorMap.has(language)) {
                collatorMap.set(language, new Intl.Collator(language));
            }
            return collatorMap.get(language).compare(a, b);
        }
        return a.localeCompare(b, language);
    }

    /**
     * Sorts objects by 'displayOrder' and then by 'name'.
     *
     * This function is used to sort arrays of objects based on their 'displayOrder' and 'name' properties.
     * With this function, you can sort objects efficiently in a specified language.
     *
     * @param {object} a - The first object to compare.
     * @param {number} a.displayOrder - The display order of the first object.
     * @param {string} a.name - The name of the first object.
     * @param {object} b - The second object to compare.
     * @param {number} b.displayOrder - The display order of the second object.
     * @param {string} b.name - The name of the second object.
     * @param {string} language - The language to use for comparing names.
     * @returns {number} Returns less than 0 if 'a' should be sorted before 'b', greater than 0 if 'b' should be sorted before 'a', and 0 if 'a' and 'b' should have the same sort order.
     * @function Mojito.Presentation.Utils.SortingHelper.byDisplayOrderAndName
     */
    static byDisplayOrderAndName(a, b, language) {
        return a.displayOrder - b.displayOrder || SortingHelper.compare(a.name, b.name, language);
    }

    /**
     * A function for sorting objects by 'displayOrder', then 'handicap', and finally by 'id'.
     *
     * @param {object} a - The first object to compare.
     * @param {number} a.displayOrder - The display order of the first object.
     * @param {number} a.handicap - The handicap of the first object.
     * @param {string} a.id - The id of the first object.
     * @param {object} b - The second object to compare.
     * @param {number} b.displayOrder - The display order of the second object.
     * @param {number} b.handicap - The handicap of the second object.
     * @param {string} b.id - The id of the second object.
     * @returns {number} Returns less than 0 if 'a' should be sorted before 'b', greater than 0 if 'b' should be sorted before 'a', and 0 if both 'a' and 'b' should have the same sort order.
     * @function Mojito.Presentation.Utils.SortingHelper.byDisplayOrderAndHandicapAndId
     */
    static byDisplayOrderAndHandicapAndId(a, b) {
        const handicapA = a.handicap || 0;
        const handicapB = b.handicap || 0;
        return a.displayOrder - b.displayOrder || handicapA - handicapB || a.id - b.id;
    }
}
