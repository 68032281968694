import PropTypes from 'prop-types';
import MojitoCore from 'mojito/core';
import FiltersBarImpl from './filters-bar';
import { noop } from 'mojito/utils';

const UIView = MojitoCore.Presentation.UIView;

/**
 * A component responsible for rendering a filters bar.
 * Filters may include either base filters (i.e. 'TODAY', 'Tomorrow', 'Streaming', 'Inplay', '%1 hours', and '%1 days'), or day filters (e.g. 'TODAY+1', 'TODAY+2', etc.).
 *
 * @class FiltersBar
 * @memberof Mojito.Modules
 */

/**
 * FiltersBar prop types.
 *
 * @property {string} sportId - The sport id.
 * @property {Array<object>} filters - An array of objects representing the filters to be displayed.
 * @property {string} [selectedFilterKey] - The id of the selected filter.
 * @property {Function} [onFilterClicked = () => {}] - A callback triggered when a filter item is clicked. It receives the filter 'id' as a parameter.
 * @property {Function} [getItemHrefLink = () => {}] - Callback function to obtain the hrefLink property for SEO purposes.
 *
 * @memberof Mojito.Modules.FiltersBar
 */

const propTypes = {
    sportId: PropTypes.string.isRequired,
    filters: PropTypes.arrayOf(PropTypes.object).isRequired,
    selectedFilterKey: PropTypes.string,
    onFilterClicked: PropTypes.func,
    getItemHrefLink: PropTypes.func,
};

const defaultProps = {
    onFilterClicked: noop,
    getItemHrefLink: noop,
};

export default UIView('FiltersBar', FiltersBarImpl, propTypes, defaultProps);
