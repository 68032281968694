import MojitoCore from 'mojito/core';
import AbstractNotificationEventResolverService from './abstract-notification-event-resolver-service';

const { RequestFactory } = MojitoCore.Services.Transactions;
const logger = MojitoCore.logger.get('NotificationEventResolverService');

const RESOLVER_BASE_URL = '/resolver';

/**
 * NotificationEventResolverService config.
 *
 * @typedef NotificationEventResolverServiceConfig
 * @type {object}
 * @property {string} serviceUrl - URL that will be used to perform API calls.
 *
 * @memberof Mojito.Services.SportsContent.NotificationEvent
 */

/**
 * Service providing method to receive event ID by notifications ID.
 *
 * @class NotificationEventResolverService
 * @name notificationEventResolverService
 * @extends Mojito.Services.SportsContent.NotificationEvent.NotificationEventResolverService
 * @memberof Mojito.Services.SportsContent.NotificationEvent
 */
class NotificationEventResolverService extends AbstractNotificationEventResolverService {
    /**
     * Configure service.
     *
     * @param {Mojito.Services.SportsContent.NotificationEvent.NotificationEventResolverServiceConfig} config - Service configuration object.
     * @returns {Mojito.Services.SportsContent.NotificationEvent.AbstractNotificationEventResolverService} Instance of the service.
     *
     * @function Mojito.Services.SportsContent.NotificationEvent.NotificationEventResolverService#configure
     */
    configure({ serviceUrl }) {
        this.requestFactory = new RequestFactory(logger);
        this.apiUrl = `${serviceUrl}${RESOLVER_BASE_URL}`;
        return this;
    }

    /**
     * Get event ID by notifications ID.
     *
     * @param {string} notificationsId - Notifications ID.
     * @returns {Promise} Promise object.
     * @function Mojito.Services.SportsContent.NotificationEvent.NotificationEventResolverService#getEventId
     */
    getEventId(notificationsId) {
        return this.requestFactory
            .post(`${this.apiUrl}/event`)
            .withCredentials()
            .withDescription('getEventId')
            .send({ notificationsId });
    }
}

export default new NotificationEventResolverService();
