import AbstractBetsService from './abstract-bets-service.js';
import MojitoCore from 'mojito/core';
import ApiRequestProvider from 'services/common/api-request/request-provider.js';

const BASE_URL = '/bets';
const logger = MojitoCore.logger.get('BetsService');

/**
 * Bets service config.
 *
 * @typedef BetsServiceConfig
 * @type {object}
 * @property {string} serviceUrl - Bets API endpoint URL.
 *
 * @memberof Mojito.Services.Bets
 */

/**
 * Class offering the possibility to interact with the mojito bets API.
 *
 * @class BetsService
 * @name service
 * @extends Mojito.Services.Bets.AbstractBetsService
 * @memberof Mojito.Services.Bets
 */
class BetsService extends AbstractBetsService {
    constructor() {
        super();
    }

    /**
     * Configure service.
     *
     * @param {Mojito.Services.Bets.BetsServiceConfig} config - Service configuration object.
     * @function Mojito.Services.Bets.service#confgure
     */
    configure(config) {
        this.pendingRequest = null;
        this.serviceUrl = config.serviceUrl;
        this.requestFactory = new ApiRequestProvider(logger);
        this.apiUrl = `${this.serviceUrl + BASE_URL}`;
    }

    abortPendingRequest() {
        if (this.pendingRequest) {
            this.pendingRequest.abort();
            this.pendingRequest = null;
        }
    }

    getBets(payload) {
        if (!this.validateServiceUrl()) {
            return Promise.reject();
        }

        this.abortPendingRequest();

        this.pendingRequest = this.requestFactory
            .post(`${this.apiUrl}`)
            .withDescription('get bets')
            .withCredentials();

        return this.pendingRequest.send(payload);
    }

    getBet(id, oddsFormat) {
        if (!this.validateServiceUrl()) {
            return Promise.reject();
        }

        return this.requestFactory
            .post(`${this.apiUrl}/bet`)
            .withDescription('get bet')
            .withCredentials()
            .send({ id, oddsFormat });
    }

    refreshBets(payload) {
        if (!this.validateServiceUrl()) {
            return Promise.reject();
        }

        return this.requestFactory
            .post(`${this.apiUrl}/refresh-bets`)
            .withDescription('refresh bets')
            .withCredentials()
            .send(payload);
    }

    getOpenBetCount(payload) {
        if (!this.validateServiceUrl()) {
            return Promise.reject();
        }

        return this.requestFactory
            .post(`${this.apiUrl}/open-bets-count`)
            .withDescription('get open bets count')
            .withCredentials()
            .send(payload);
    }

    /**
     * Validate service url.
     *
     * @private
     * @returns {boolean} True if service url exists, else false.
     */
    validateServiceUrl() {
        if (!this.serviceUrl) {
            logger.warn('serviceUrl is missing.');
            return false;
        }
        return true;
    }
}

export default new BetsService();
