import {ensureHierarchy} from '#core/utils/config-utils.js';

import {AbstractFeature, allFeatures} from '#core/application/abstract-feature.js';

import GaToGtmService from './service.js';

import SCHEMA from './gtm.schema.yaml';

class GTMFeature extends AbstractFeature {
    get name() {
        return 'Gtm';
    }

    get configSchema() {
        return SCHEMA;
    }

    get isThirdParty() {
        return true;
    }

    get isTracker() {
        return true;
    }

    afterMojitoConfigBuild(mojitoConfig) {
        const services = ensureHierarchy(mojitoConfig, 'services.analytics.services');

        services.default = services.default || [];
        services.default.push(new GaToGtmService(this.config));

        return super.afterMojitoConfigBuild(mojitoConfig);
    }
}

export const GTM = new GTMFeature(allFeatures);
