import {resolveBackground} from '#core/utils/color-utils.js';

export function inProgressBonusesFactory(PALETTE, BONUS_ITEM) {
    const {FONT} = PALETTE;

    return {
        InProgressBonuses: {
            bonusContainer: {
                style: {
                    ...BONUS_ITEM.container,
                    borderWidth: 0,
                },
            },
            noBonusesLabel: {
                container: {
                    ...BONUS_ITEM.container,
                    padding: '16px 8px',
                },
                style: {
                    fontSize: FONT.SIZE_MEDIUM,
                    textAlign: 'left',
                    ...PALETTE.BONUSES_NOT_FOUND_TEXT,
                },
            },
            bonusNameContainer: {
                itemSpacing: 0,
            },
            bonusNameLabel: {
                style: {
                    ...BONUS_ITEM.title,
                },
            },
            prewagerIcon: {
                style: BONUS_ITEM.icon,
            },
            freeBetIcon: {
                style: BONUS_ITEM.icon,
            },
            cashIcon: {
                style: BONUS_ITEM.icon,
            },
            bonusPriceLabel: {
                style: {
                    fontSize: FONT.SIZE_MEDIUM,
                    ...PALETTE.BONUS_ITEM_AMOUNT_TEXT,
                },
            },
            bonusDescription: {
                style: {
                    ...BONUS_ITEM.description,
                },
            },
            stepSubContainerBorder: {
                borderColor: PALETTE.BONUS_ITEM_STEP_SEPARATOR_COLOR,
            },
            stepNumber: {
                style: {
                    ...resolveBackground(PALETTE.BONUS_ITEM_STEP_NUMBER_LABEL_BG),
                    borderRadius: '50%',
                    alignItems: 'center',
                    justifyContent: 'center',
                    boxSizing: 'border-box',
                    borderColor: PALETTE.BONUS_ITEM_STEP_NUMBER_LABEL_BORDER_COLOR,
                },
            },
            stepNumberLabel: {
                style: {
                    fontSize: 10,
                    fontWeight: FONT.WEIGHT_BOLD,
                    ...PALETTE.BONUS_ITEM_STEP_NUMBER_LABEL_TEXT,
                },
            },
            stepNameLabel: {
                style: {
                    fontSize: FONT.SIZE_MEDIUM,
                    fontWeight: FONT.WEIGHT_BOLD,
                    ...PALETTE.BONUS_ITEM_STEP_TITLE_TEXT,
                },
            },
            stepStatus: {
                style: {
                    fontSize: FONT.SIZE_MEDIUM,
                    fontWeight: FONT.WEIGHT_SEMI_BOLD,
                    ...PALETTE.BONUS_ITEM_STEP_STATUS_TEXT,
                },
            },
            stepDescription: {
                style: {
                    fontSize: FONT.SIZE_MEDIUM,
                    ...PALETTE.BONUS_ITEM_STEP_DESCRIPTION_TEXT,
                },
            },
            progressBar: BONUS_ITEM.progressBar,
            stepInfo: BONUS_ITEM.progressBar.limitLabel,
        },
    };
}
