import PropTypes from 'prop-types';
import PopupImpl from './popup.jsx';
import MojitoCore from 'mojito/core';
import { noop } from 'mojito/utils';

/**
 * A component representing an absolutely positioned container which is designed to contain content that is in direct
 * relation to the popup's parent. It can be configured to behave like a modal dialog.
 *
 * @class Popup
 * @memberof Mojito.Presentation.Components
 */

/**
 * Popup prop types.
 *
 * @property {boolean} [visible = false] - Flag controlling whether the component's container and content should be visible or not.
 * @property {boolean} [captureOutsideClicks] - Flag controlling whether all clicks outside the open popup should be captured or not. Overrides the config parameter with the same name.
 * @property {boolean} [onOutsideClick = ()=>{}] - Callback, triggered when the user clicks outside the open popup, if captureOutsideClicks is true.
 * @property {string} [class] - Class name applied to the component's outermost container.
 * @property {object | Function} [elementRef] - A ref to the rendered element.
 * @property {object | Function} [arrowRef] - A ref to the rendered element.
 *
 * @memberof Mojito.Presentation.Components.Popup
 */
const propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    visible: PropTypes.bool,
    captureOutsideClicks: PropTypes.bool,
    onOutsideClick: PropTypes.func,
    class: PropTypes.string,
    elementRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    arrowRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

const defaultProps = {
    visible: false,
    onOutsideClick: noop,
};

export default MojitoCore.Presentation.UIView('Popup', PopupImpl, propTypes, defaultProps);
