import { memo, useContext, useEffect } from 'react';
import MojitoPresentation from 'mojito/presentation';
import MojitoCore from 'mojito/core';
import { range } from 'mojito/utils';
import EventListMarketsSection from 'modules/event-list/event-list-markets-section.jsx';
import EventListUtils from 'modules/event-list/utils';
import EarlyPayoutOffers from 'modules/event-list/early-payout-offers/index.jsx';

const AnalyticsContext = MojitoCore.Presentation.AnalyticsContext.Context;
const { Dropdown, Text, FlexPane, Button, Image } = MojitoPresentation.Components;

const log = MojitoCore.logger.get('EventListHeader');
const EventListHeader = props => {
    const {
        mojitoTools: { config },
        titleText,
        isGameLineMode,
        isInPlay,
        marketOptions,
        sportId,
        marketSectionRef,
        eventGroups,
        onSortingClicked,
        showEarlyPayoutOffers,
        enableSorting,
        sortingTitle,
    } = props;

    const { hasTitle, hasMarketSwitchers, hasGameLineSwitcher, nothingToShow } =
        EventListUtils.resolveHeaderAppearance(
            config,
            titleText,
            marketOptions,
            isGameLineMode,
            isInPlay,
            enableSorting
        );

    return nothingToShow ? null : (
        <FlexPane config={config.container} class="ta-EventList-header">
            {hasTitle && <Text config={config.title}>{titleText}</Text>}
            {showEarlyPayoutOffers && eventGroups && (
                <EarlyPayoutOffers eventGroup={eventGroups[0]} />
            )}
            {!isInPlay && enableSorting && (
                <Button config={config.sortEventsToggle} onClick={onSortingClicked}>
                    <FlexPane config={config.sortingButtonContainer}>
                        <Image config={config.sortingIcon} />
                        <Text config={config.sortEventsText}>{sortingTitle}</Text>
                    </FlexPane>
                </Button>
            )}
            {(hasMarketSwitchers || hasGameLineSwitcher) && config.showMarketSelectors && (
                <EventListMarketsSection
                    elementRef={marketSectionRef}
                    config={config.marketOptionsContainer}
                    sportId={sportId}
                >
                    {<MarketSwitcher {...props} />}
                </EventListMarketsSection>
            )}
        </FlexPane>
    );
};

export default memo(EventListHeader);

const MarketSwitcher = props => {
    const {
        mojitoTools: { config, stringResolver, appContext },
        marketOptions,
        selectedMarketOptions,
        numberOfMarketSwitchers,
        sportId,
        onSelectedMarketOptionChange,
        isGameLineMode,
    } = props;
    const { emitAnalytics } = appContext.analyticsEmitter;

    useMarketOptionsValidation(marketOptions, sportId, stringResolver);
    const { data: analyticsData, marketChangeAnalyticsType } = useContext(AnalyticsContext) || {};

    const onSwitcherChange = (selectedOption, switcherId, label) => {
        onSelectedMarketOptionChange(selectedOption, Number(switcherId));
        const analyticsType = marketChangeAnalyticsType || 'eventListMarketSelectorValueChanged';
        emitAnalytics(analyticsType, { sportId, marketId: label, ...analyticsData });
    };

    const resolveLabel = isGameLineMode
        ? option => resolveGameLineOptionString(option, stringResolver)
        : option => resolveMarketOptionString(option, sportId, stringResolver);

    const switcherItems = marketOptions.map(option => ({
        value: option,
        label: resolveLabel(option),
    }));

    if (!numberOfMarketSwitchers) {
        return null;
    }

    const switchersCount = Math.min(numberOfMarketSwitchers, marketOptions.length);
    return (
        <>
            {range(switchersCount).map(index => {
                const switcherId = String(index);
                const defaultSelected = switcherItems[index].value;
                const hasSwitchers = marketOptions.length > numberOfMarketSwitchers;

                return hasSwitchers || config.disableMarketHeader ? (
                    <Dropdown
                        id={switcherId}
                        key={switcherId}
                        items={switcherItems}
                        selectedItemValue={selectedMarketOptions[index] || defaultSelected}
                        cbOnChange={onSwitcherChange}
                        config={config.marketSwitcher}
                    />
                ) : (
                    <Text key={switcherId} config={config.marketHeader}>
                        {switcherItems[index].label}
                    </Text>
                );
            })}
        </>
    );
};

const useMarketOptionsValidation = (marketOptions, sportId, stringResolver) => {
    useEffect(
        () =>
            marketOptions.forEach(option => {
                const hasString =
                    resolveMarketOptionString(option, sportId, stringResolver) ||
                    resolveGameLineOptionString(option, stringResolver);
                if (!hasString) {
                    log.warn('Translation is missing for market option ', option);
                }
            }),
        [marketOptions, stringResolver, sportId]
    );
};

const resolveMarketOptionString = (option, sportId, stringResolver) => {
    // Each option might have overrides per sport so the valid keys could be e.g.
    // MARKET_OPTIONS.MATCH_WINNER_HDA or MATCH_WINNER_HDA.MATCH_WINNER_HDA.tennis or MATCH_WINNER_HDA.MATCH_WINNER_HDA.DEFAULT.
    const l10nBaseKey = `$EVENT_LIST_HEADER.MARKET_OPTIONS.${option}`;
    return (
        EventListUtils.resolveComplexTranslationString(l10nBaseKey, sportId, stringResolver) ||
        option
    );
};

const resolveGameLineOptionString = (option, stringResolver) =>
    stringResolver.resolveString(`$EVENT_LIST_HEADER.GAME_LINE_OPTIONS.${option}`, true) || option;
