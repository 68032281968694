import MojitoCore from 'mojito/core';
const UIView = MojitoCore.Presentation.UIView;
import PriceBoostsViewImpl from './price-boosts-view.jsx';

/**
 * PriceBoostsView - renders price boosts content inside SportPage.
 *
 * @class PriceBoostsView
 * @memberof Mojito.Applications.Sports.Pages
 */
export default UIView('PriceBoostsView', PriceBoostsViewImpl);
