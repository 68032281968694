import EventCardSkeletonImpl from './event-card-skeleton.jsx';
import MojitoCore from 'mojito/core';
import PropTypes from 'prop-types';

const { UIView } = MojitoCore.Presentation;

/**
 * Event card skeleton component used to represent loading event card.
 *
 * @function EventCardSkeleton
 * @memberof Mojito.Modules.EventCards
 */

/**
 * `EventCardSkeleton` component prop types.
 *
 * @property {object | Function} [elementRef] - A ref to the rendered element.
 *
 * @memberof Mojito.Modules.EventCards.EventCardSkeleton
 */
const propTypes = {
    elementRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

export default UIView('EventCardSkeleton', EventCardSkeletonImpl, propTypes);
