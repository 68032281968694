import MojitoPresentation from 'mojito/presentation';
const { NAVIGATION, PARAMETER } = MojitoPresentation.Base.Navigation.types;
const {
    SPORT_ID,
    EVENT_ID,
    CLASS_ID,
    TYPE_ID,
    COUPON_ID,
    CATEGORY,
    MEETING_ID,
    RACE_ID,
    ITEM_ID,
    PART_ID,
    FILTER,
} = PARAMETER;

/**
 * An object representing routing paths.
 *
 * Each key is a {@link MojitoPresentation.Base.Navigation.types.NAVIGATION}
 * and its corresponding value is a string or an object that represents the route path.
 *
 * @name PATHS
 * @readonly
 * @enum {string|object}
 * @memberof Mojito.Applications.Sports
 */
export default {
    /** Home path. */
    [NAVIGATION.HOME]: {
        path: '',
        nested: {
            [NAVIGATION.QUICK_START_GUIDE]: 'start-guide',
        },
    },

    /** Path for home redirection. */
    [NAVIGATION.REDIRECT_HOME_1]: 'home',
    [NAVIGATION.REDIRECT_HOME_2]: 'index.html',

    /** Path for the inplay sport page. */
    [NAVIGATION.INPLAY_PAGE]: `inplay`,

    /** Path for the inplay sport page.
     * Utilizes optional {@link Mojito.Presentation.Base.Navigation.types.PARAMETER.SPORT_ID|SPORT_ID}
     * for navigating to the appropriate inplay sport page. */
    [NAVIGATION.INPLAY_SPORT_PAGE]: `inplay/:${SPORT_ID}`,

    /** Path for the inplay event page.
     * Needs a {@link Mojito.Presentation.Base.Navigation.types.PARAMETER.EVENT_ID|EVENT_ID}
     * to route to the desired inplay event page. */
    [NAVIGATION.INPLAY_EVENT_PAGE]: `inplay/events/:${EVENT_ID}`,

    /** Path for the sport coupon page.
     * The {@link Mojito.Presentation.Base.Navigation.types.PARAMETER.SPORT_ID|SPORT_ID} and
     * {@link Mojito.Presentation.Base.Navigation.types.PARAMETER.COUPON_ID|COUPON_ID} segments are both required.
     * Used for navigation to the desired sport and coupon page. */
    [NAVIGATION.SPORTS_COUPON_PAGE]: `sports/:${SPORT_ID}/coupons/:${COUPON_ID}`,

    /** Path for sport page.
     * The {@link Mojito.Presentation.Base.Navigation.types.PARAMETER.SPORT_ID|SPORT_ID} segment is mandatory
     * and corresponds to the specific sport.
     * The nested page path 'sports/:sportId/competitions' will be resolved by the route resolver. */
    [NAVIGATION.SPORT_PAGE]: {
        path: `sports/:${SPORT_ID}`,
        nested: {
            [NAVIGATION.COMPETITIONS]: 'competitions',
            [NAVIGATION.MATCHES]: {
                path: 'matches',
                nested: {
                    [NAVIGATION.EVENTS_COUPON]: `:${FILTER}`,
                },
            },
            [NAVIGATION.PRICE_BOOSTS]: 'price_boosts',
            [NAVIGATION.OUTRIGHTS]: 'outrights',
            [NAVIGATION.HIGHLIGHTS]: 'highlights',
            [NAVIGATION.MEETINGS]: {
                path: 'meetings',
                nested: {
                    [NAVIGATION.MEETINGS_COUPON]: `:${FILTER}`,
                },
            },
            [NAVIGATION.FUTURE]: 'future',
        },
    },

    /** Path for competitions league page.
     * The segments {@link Mojito.Presentation.Base.Navigation.types.PARAMETER.SPORT_ID|SPORT_ID} and
     * {@link Mojito.Presentation.Base.Navigation.types.PARAMETER.TYPE_ID|TYPE_ID} are both required.
     * `SPORT_ID` corresponds to the desired sport, and `TYPE_ID` corresponds to the specific type within that sport. */
    [NAVIGATION.COMPETITIONS_LEAGUE]: `sports/:${SPORT_ID}/competitions/:${TYPE_ID}`,

    /** Path for outrights league page.
     * Both {@link Mojito.Presentation.Base.Navigation.types.PARAMETER.SPORT_ID|SPORT_ID} and
     * {@link Mojito.Presentation.Base.Navigation.types.PARAMETER.TYPE_ID|TYPE_ID} segments are required.
     * SPORT_ID corresponds to the desired sport, whereas TYPE_ID corresponds to a particular type within that sport. */
    [NAVIGATION.OUTRIGHTS_LEAGUE]: `sports/:${SPORT_ID}/outrights/:${TYPE_ID}`,

    /** Path for the sport category page.
     * Both {@link Mojito.Presentation.Base.Navigation.types.PARAMETER.SPORT_ID|SPORT_ID} and {@link Mojito.Presentation.Base.Navigation.types.PARAMETER.CATEGORY|CATEGORY} segments are required,
     * corresponding to the chosen sport and page category respectively.
     * Typically, the category value is one of the {@link Mojito.Presentation.Base.Navigation.types.MENUS|sport context items}. */
    [NAVIGATION.SPORT_CATEGORY_PAGE]: `sports/:${SPORT_ID}/:${CATEGORY}`,

    /** Path for the sport event page. Both {@link Mojito.Presentation.Base.Navigation.types.PARAMETER.SPORT_ID|SPORT_ID} and
     * {@link Mojito.Presentation.Base.Navigation.types.PARAMETER.EVENT_ID|EVENT_ID} segments are required.
     * This is used for navigation to a specific event within a chosen sport. */
    [NAVIGATION.SPORT_EVENT_PAGE]: `sports/:${SPORT_ID}/events/:${EVENT_ID}`,

    /** Specific meeting page path for racing sport. The {@link Mojito.Presentation.Base.Navigation.types.PARAMETER.SPORT_ID|SPORT_ID} and
     * {@link Mojito.Presentation.Base.Navigation.types.PARAMETER.MEETING_ID|MEETING_ID} segments are mandatory. */
    [NAVIGATION.RACE_MEETING_PAGE]: `sports/:${SPORT_ID}/meetings/:${MEETING_ID}/races`,

    /** Specific race page path within defined meeting for racing sport. The {@link Mojito.Presentation.Base.Navigation.types.PARAMETER.SPORT_ID|SPORT_ID},
     * {@link Mojito.Presentation.Base.Navigation.types.PARAMETER.MEETING_ID|MEETING_ID} and {@link Mojito.Presentation.Base.Navigation.types.PARAMETER.RACE_ID|RACE_ID} segments are mandatory. */
    [NAVIGATION.RACE_PAGE]: `sports/:${SPORT_ID}/meetings/:${MEETING_ID}/races/:${RACE_ID}`,

    /** Specific future race page path within defined racing sport. The {@link Mojito.Presentation.Base.Navigation.types.PARAMETER.SPORT_ID|SPORT_ID} and
     * {@link Mojito.Presentation.Base.Navigation.types.PARAMETER.RACE_ID|RACE_ID} segments are mandatory. */
    [NAVIGATION.FUTURE_RACE_PAGE]: `sports/:${SPORT_ID}/future/:${RACE_ID}`,

    /** General virtual sports page path. */
    [NAVIGATION.VIRTUAL_SPORTS_PAGE]: 'virtuals',

    /** Specific virtual sport page path using SportId. */
    /* {@link Mojito.Presentation.Base.Navigation.types.PARAMETER.SPORT_ID|SPORT_ID} segments are mandatory. */
    [NAVIGATION.VIRTUAL_SPORT_PAGE]: `virtuals/:${SPORT_ID}`,

    /** Specific virtual sport page path using SportId and ClassId. The {@link Mojito.Presentation.Base.Navigation.types.PARAMETER.SPORT_ID|SPORT_ID} segment is mandatory. */
    [NAVIGATION.VIRTUAL_SPORT_CLASS_PAGE]: `virtuals/:${SPORT_ID}/:${CLASS_ID}`,

    /** Specific virtual sport event page path. The {@link Mojito.Presentation.Base.Navigation.types.PARAMETER.SPORT_ID|SPORT_ID} and
     * {@link Mojito.Presentation.Base.Navigation.types.PARAMETER.EVENT_ID|EVENT_ID} segments are mandatory. */
    [NAVIGATION.VIRTUAL_SPORT_EVENT_PAGE]: `virtuals/:${SPORT_ID}/:${CLASS_ID}/events/:${EVENT_ID}`,

    /** Account page path. The {@link Mojito.Presentation.Base.Navigation.types.PARAMETER.ITEM_ID|ITEM_ID} segment is optional.
     * If specified, the navigation can be done to a concrete account sub-page.
     * The {@link Mojito.Presentation.Base.Navigation.types.PARAMETER.PART_ID|PART_ID} segment is optional.
     * If specified, the navigation can be done to the defined part within the sub-page. */
    [NAVIGATION.ACCOUNT_PAGE]: `account/:${ITEM_ID}/:${PART_ID}`,

    /** Path for the account page. The {@link Mojito.Presentation.Base.Navigation.types.PARAMETER.ITEM_ID|ITEM_ID} segment is optional.
     * If specified, this allows for navigation to a specific account sub-page. */
    [NAVIGATION.ACCOUNT_ITEM_PAGE]: `account/:${ITEM_ID}`,

    /** Base path of account page. */
    [NAVIGATION.ACCOUNT_MAIN_PAGE]: `account`,

    /** Path to the page with price boosted markets. */
    [NAVIGATION.PRICE_BOOST_PAGE]: 'price-boost',

    /** Path used to deep link betslip. Typically to populate betslip with selections and stakes. */
    [NAVIGATION.DEEP_LINKS_ADD_SELECTIONS]: 'add-selns',

    /** Bad route page path. Usually shown when route did not match to any of the page. */
    [NAVIGATION.BAD_REQUEST_PAGE]: 'not-found',
};
