import { createRef, Children } from 'react';
import MojitoCore from 'mojito/core';
import { Transition } from 'react-transition-group';
import { pick, omitBy } from 'mojito/utils';

const log = MojitoCore.logger.get('HideablePane');
const { hasRenderedSomething } = MojitoCore.Base.ReactUtils;
const { cssTimeToMs } = MojitoCore.Base.NumberUtils;

const numberRegex = /\D/g;

export default class HideablePane extends MojitoCore.Presentation.UIViewImplementation {
    constructor(...args) {
        super(...args);

        this.state = {
            hasRenderedViewportEl: false,
        };

        this.contentWrapperRef = createRef();
        this.transitionRef = createRef();

        this.onViewportRefChanged = this.onViewportRefChanged.bind(this);

        this.onViewportRendered = () => this.setState({ hasRenderedViewportEl: true });
    }

    onViewportRefChanged(el) {
        this.viewportEl = el;
        if (!el) {
            this.setState({ hasRenderedViewportEl: false });
        }
    }

    getSnapshotBeforeUpdate(prevProps) {
        if (
            this.viewportEl &&
            this.config.style.visible.height &&
            this.props.hidden &&
            !prevProps.hidden
        ) {
            // Make sure we explicitly set the viewport element's height before collapsing it, otherwise the CSS
            // transition will not work. We do it before the props has been received, in order to handle the case
            // where the HideablePane's children are explicitly removed by HideablePane's parent when it is in
            // collapsed state, e.g:
            // <HideablePane hidden={someFlag}>{!someFlag && <ChildComponent>}</HideablePane>
            const offsetHeight = this.viewportEl.offsetHeight;
            return `${offsetHeight}px`;
        }

        return null;
    }

    componentWillUnmount() {
        cancelAnimationFrame(this.firstRenderAnimationFrameRequest);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (snapshot) {
            this.viewportEl.style.height = snapshot;
            this.viewportEl.offsetHeight; // NOSONAR - Read the value again in order to force a reflow
        }
    }

    getViewportStyle(state) {
        const style = {
            ...this.style.states[state],
        };

        if (style.height === 'auto') {
            const height =
                this.contentWrapperRef.current &&
                (this.contentWrapperRef.current.scrollHeight ||
                    // We need to consider children heights when the wrapper does not have a valid height.
                    // (it usually happens when wrapping children with "overflow:hidden", e.g. ScrollPane)
                    [].reduce.call(
                        this.contentWrapperRef.current.children,
                        (acc, item) => acc + item.scrollHeight,
                        0
                    ));

            style.height = `${
                style.maxHeight
                    ? Math.min(height, style.maxHeight.replace(numberRegex, ''))
                    : height
            }px`;
        }

        return style;
    }

    getTransitionDuration(isHidden) {
        const cssTimeStr = this.style.states[isHidden ? 'exited' : 'entered'].transitionDuration;
        return cssTimeToMs(cssTimeStr);
    }

    renderTransitionContent(state) {
        const viewportStyle = this.getViewportStyle(state);

        if (this.viewportEl && !this.state.hasRenderedViewportEl) {
            // We have to make sure that the initial (unmounted) style has been rendered to the DOM before we can move
            // on to the next transition state, otherwise the CSS transition might not be triggered.
            this.viewportEl.offsetHeight; // NOSONAR - Force a reflow (i.e. "render")
            if (this.firstRenderAnimationFrameRequest) {
                cancelAnimationFrame(this.firstRenderAnimationFrameRequest);
            }
            this.firstRenderAnimationFrameRequest = requestAnimationFrame(this.onViewportRendered);
        }

        return (
            <div style={viewportStyle} ref={this.onViewportRefChanged}>
                <div ref={this.contentWrapperRef} style={this.style.contentWrapper}>
                    {this.props.children}
                </div>
            </div>
        );
    }

    renderContent() {
        if (this.props.hidden && !this.config.renderHiddenContent) {
            return null;
        }

        const viewportStyle = this.style.states[this.props.hidden ? 'hidden' : 'visible'];
        return <div style={viewportStyle}>{this.props.children}</div>;
    }

    render() {
        if (MojitoCore.Utils.isDebugMode() && this.config.warnAboutUnconditionallyRenderedContent) {
            Children.forEach(this.props.children, child => {
                if (hasRenderedSomething(child)) {
                    log.warn(
                        'The following child executes rendering code, even when it is hidden.' +
                            'Consider changing the unconditionally invoked function call(s) to a ' +
                            'Renderable component, to avoid the overhead. Alternatively, disable ' +
                            'the warning by setting the "warnAboutUnconditionallyRenderedContent" ' +
                            'config value to false for this case.',
                        child
                    );
                }
            });
        }

        return this.config.enableTransitions ? (
            <Transition
                nodeRef={this.transitionRef}
                in={!this.props.hidden}
                appear={this.config.initialAnimation}
                timeout={this.getTransitionDuration(this.props.hidden)}
                mountOnEnter={!this.config.renderHiddenContent}
                unmountOnExit={this.config.unmountOnExit}
            >
                {state => this.renderTransitionContent(state)}
            </Transition>
        ) : (
            this.renderContent()
        );
    }
}

HideablePane.getStyle = config => {
    const { createStackingContext, style } = config;
    const { base, visible, toVisibleTransition, hidden, toHiddenTransition } = style;

    const baseStyle = {
        ...omitBy(base, val => val === undefined),
        // Only hide overflowing content if we are animating the bounds of the pane
        overflow: hidden.height === undefined ? undefined : 'hidden',
        position: createStackingContext ? 'relative' : undefined,
    };

    const visibleTransitionStyle = {
        visibility: 'visible',
        ...visible,
    };

    Object.assign(visibleTransitionStyle, {
        ...baseStyle,
        ...visible,
        ...toVisibleTransition,
        transitionProperty: Object.keys(visibleTransitionStyle).join(', '),
        pointerEvents: 'initial',
    });

    const enteringTransitionStyle = { ...visibleTransitionStyle };

    // We don't want to explicitly set the height once the transition to visible is complete.
    // Doing so would cause issues when the content height changes.
    if (visibleTransitionStyle.height) {
        visibleTransitionStyle.height = undefined;
    }

    const hiddenTransitionStyle = {
        visibility: 'hidden',
        ...hidden,
    };

    // We want to delay the transition of "visibility" until all other properties are done transitioning,
    // otherwise the whole element will instantly disappear when transitioning to hidden
    const getToHiddenTransitionDelay = prop =>
        prop === 'visibility' ? toHiddenTransition.transitionDuration : '0s';

    const hiddenStyleKeys = Object.keys(hiddenTransitionStyle);

    Object.assign(hiddenTransitionStyle, {
        ...baseStyle,
        ...toHiddenTransition,
        transitionProperty: hiddenStyleKeys.join(', '),
        transitionDelay: hiddenStyleKeys.map(getToHiddenTransitionDelay).join(', '),
        ...(config.absolutePositionWhenHidden && {
            position: 'absolute',
            overflow: 'hidden',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
        }),
    });

    return {
        states: {
            // react-transition-group state styles
            unmounted: hiddenTransitionStyle,
            entering: enteringTransitionStyle,
            entered: visibleTransitionStyle,
            exiting: hiddenTransitionStyle,
            exited: hiddenTransitionStyle,
            // non-transition state styles
            hidden: { ...baseStyle, ...hidden, visibility: 'hidden' },
            visible: { ...baseStyle, ...visible, visibility: 'visible' },
        },
        contentWrapper: pick(
            baseStyle,
            'flexGrow',
            'flexShrink',
            'flexBasis',
            'flexDirection',
            'display',
            'justifyContent',
            'maxHeight',
            'minHeight',
            'maxWidth',
            'minWidth'
        ),
    };
};
