// This module should not have any dependencies on mojito
// Please try to keep it pure, without any unnecessary imports

export const getCookie = name => {
    const cookie = document.cookie.split('; ').find(row => row.startsWith(`${name}=`));
    if (!cookie) {
        return -1;
    }
    return cookie.split('=')[1];
};

/**
 * Set cookie
 * @param name {string} Name of the cookie to set
 * @param value {string} Value of the cookie
 * @param params {object} set of parameters
 */
export const setCookie = (name, value, params = {}) => {
    let expires = 'Fri, 31 Dec 9999 23:59:59 GMT';
    if (params.expireInDays) {
        // Get unix milliseconds at current time plus number of days
        const date = new Date(Date.now() + params.expireInDays * 86400000); //24 * 60 * 60 * 1000
        expires = date.toUTCString();
    }
    const encodedValue = params.strictEncoding ? encodeURIComponent(value) : encodeURI(value).replace(/;/g, '%3B');
    const domain = params.domain || window.location.hostname;
    const strSameSite = params.sameSite ? `SameSite=${params.sameSite};` : '';
    const strSecure = 'Secure=true;';
    const strPath = `path=${params.path || '/'};`;

    document.cookie = `${name}=${encodedValue};domain=${domain};expires=${expires};${strSameSite}${strSecure}${strPath}`;
};

export const removeCookie = (name, domain) => {
    document.cookie = `${name}=;domain=${domain};path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
};
