import {registerTileSchema} from '#core/application/schema-registry.js';

import SECONDARY_LINK from './secondary-link.palette-schema.yaml';

registerTileSchema(SECONDARY_LINK);

export function generateSecondaryLink(TILE) {
    const {FONT} = TILE;

    return {
        style: {
            base: {
                backgroundImage: 'none', // Reset background set by base style
                backgroundColor: 'transparent',
                fontSize: FONT.SIZE_MEDIUM,
                fontWeight: FONT.WEIGHT_NORMAL,
                ...TILE.SECONDARY_LINK_TEXT,
            },
            active: TILE.SECONDARY_LINK_TEXT,
            hover: TILE.SECONDARY_LINK_TEXT,
            disabled: {
                cursor: 'not-allowed',
            },
        },
    };
}
