import MenuRowSkeletonImpl from './menu-row-skeleton.jsx';
import MojitoCore from 'mojito/core';
import PropTypes from 'prop-types';

const { UIView } = MojitoCore.Presentation;

/**
 * The component works as a preloading component for MenuRow to show while the content is loading.
 *
 * @function MenuRowSkeleton
 *
 * @memberof Mojito.Presentation.Components
 */

/**
 * `MenuRowSkeleton` component prop types.
 *
 * @property {object | Function} [elementRef] - A ref to the rendered element.
 *
 * @memberof Mojito.Presentation.Components.MenuRow
 */
const propTypes = {
    elementRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

export default UIView('MenuRowSkeleton', MenuRowSkeletonImpl, propTypes);
