import MojitoCore from 'mojito/core';

const log = MojitoCore.logger.get('AbstractCashoutService');

/**
 * Abstract cashout service to be implemented by different integrations.
 * Note: All methods must be implemented in an asynchronous way.
 *
 * @class AbstractCashoutService
 * @abstract
 * @memberof Mojito.Services.Bets
 */
export default class AbstractCashoutService {
    /**
     * Configure the service.
     * The configuration properties are specific to the concrete implementations.
     *
     * @param {{}} config - Config object.
     * @function Mojito.Services.Bets.AbstractCashoutService#configure
     */
    // eslint-disable-next-line no-unused-vars
    configure(config) {
        // To be implemented by subclasses
    }

    /**
     * Get cashout offers for corresponding bets in <code>betInfos</code> list.
     *
     * @param {Array<Mojito.Services.Bets.types.BetInfo>} betInfos - List of bet info objects.
     *
     * @returns {Promise} Get cashout offers promise.
     * @function Mojito.Services.Bets.AbstractCashoutService#getCashoutOffers
     */
    // eslint-disable-next-line no-unused-vars
    getCashoutOffers(betInfos) {
        log.error('The method `getCashoutOffers` must be implemented by a concrete subclass.');
        return Promise.reject();
    }

    /**
     * Initiates a request to retrieve cashout offers for a list of bets.
     *
     * Depending on the implementation, this method can also subscribe for updates to cashout offers,
     * using mechanisms such as polling or push notifications.
     *
     * @param {Array<Mojito.Services.Bets.types.BetInfo>} betInfos - Array of `BetInfo` objects representing the bets for which to request cashout offers.
     * @param {Mojito.Services.Bets.types.cashoutOffersSuccess} success - Callback function to be executed upon successful retrieval or update of cashout offers.
     * @param {Mojito.Services.Bets.types.cashoutValuesFail} fail - Callback function to be executed if retrieval of cashout offers fails.
     *
     * @function Mojito.Services.Bets.AbstractCashoutService#requestCashoutOffers
     */
    // eslint-disable-next-line no-unused-vars
    requestCashoutOffers(betInfos, success, fail) {
        log.error('The method `requestCashoutOffers` must be implemented by a concrete subclass.');
    }

    /**
     * Discard cashout offers request.
     *
     * @function Mojito.Services.Bets.AbstractCashoutService#discardCashoutOffers
     */
    discardCashoutOffers() {
        log.error('The method `discardCashoutOffers` must be implemented by a concrete subclass.');
    }

    /**
     * Validates service transport protocol. Typically provided in constructor.
     * Returns promise that resolves successfully if transport protocol is valid, else rejects.
     *
     * @returns {Promise} Validate cashout transport promise.
     * @function Mojito.Services.Bets.AbstractCashoutService#validateCashoutTransport
     */
    validateCashoutTransport() {
        log.error(
            'The method `validateCashoutTransport` must be implemented by a concrete subclass.'
        );
        return Promise.reject();
    }

    /**
     * Cashout bet.
     *
     * @param {Mojito.Services.Bets.types.BetInfo} betInfo - Bet info object. Describes particular bet to be cashed out.
     * @param {string} amount - Amount to cashout.
     *
     * @returns {Promise} Promise resolves on cashout response.
     * @function Mojito.Services.Bets.AbstractCashoutService#cashout
     */
    // eslint-disable-next-line no-unused-vars
    cashout(betInfo, amount) {
        log.error('The method `cashout` must be implemented by a concrete subclass.');
        return Promise.reject();
    }

    /**
     * Adds an automatic cashout rule to a specific bet.
     *
     * @param {Mojito.Services.Bets.types.BetInfo} betInfo - Represents the bet to which the auto cashout rule is to be added.
     * @param {number} amount - Defines the amount at which automatic cashout should happen.
     * @returns {Promise} Returns a Promise that will be resolved upon successful addition of the auto cashout rule.
     *
     * @function Mojito.Services.Bets.AbstractCashoutService#addAutoCashoutRule
     */
    // eslint-disable-next-line no-unused-vars
    addAutoCashoutRule(betInfo, amount) {
        log.error('The method `addAutoCashoutRule` must be implemented by a concrete subclass.');
        return Promise.reject();
    }

    /**
     * Removes the automatic cashout rule for a specific bet.
     *
     * @param {Mojito.Services.Bets.types.BetInfo} betInfo - Represents the bet from which the auto cashout rule is to be removed.
     * @returns {Promise} Returns a Promise that will be resolved upon successful removal of the auto cashout rule.
     *
     * @function Mojito.Services.Bets.AbstractCashoutService#removeAutoCashoutRule
     */
    // eslint-disable-next-line no-unused-vars
    removeAutoCashoutRule(betInfo) {
        log.error('The method `removeAutoCashoutRule` must be implemented by a concrete subclass.');
        return Promise.reject();
    }
}
