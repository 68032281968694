import MojitoCore from 'mojito/core';
import SportCouponPageImpl from './sport-coupon-page.jsx';

const UIPage = MojitoCore.Presentation.UIPage;

/**
 * @namespace SportCouponPage
 * @memberof Mojito.Applications.Sports.Pages
 */

/**
 * Page displaying a sport coupon.
 *
 * @class SportCouponPage
 * @extends Mojito.Core.Presentation.UIPage
 * @memberof Mojito.Applications.Sports.Pages.SportCouponPage
 */
export default UIPage('SportCouponPage', SportCouponPageImpl);
