import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import MojitoServices from 'mojito/services';
import MojitoPresentation from 'mojito/presentation';
import SportGroupsListTypes from 'modules/sport-groups-list/types';
import SportGroupsListSkeleton from 'modules/sport-groups-list/skeleton/index.jsx';
import SingleLeagueCouponTypes from 'modules/single-league-coupon/types.js';

const { selectItemsState } = MojitoServices.SportsContent.ContainerItems.selectors;
const { Text, FlexPane, Image, Button, LoaderSuspense, CountryIcon } =
    MojitoPresentation.Components;
const { NavigationPathBuilder } = MojitoPresentation.Base.Navigation;
const { IntentActionTrigger } = MojitoPresentation.Base.Intent;
const { SortingHelper, RacingHelper } = MojitoPresentation.Utils;
const { EventFormatter } = MojitoPresentation.Utils;

const {
    CONTENT_STATE: { UNKNOWN, PENDING },
} = MojitoServices.Common.types;
const { COUPONS } = SingleLeagueCouponTypes;

const sortSportGroups = (sportGroups, language) =>
    [...sportGroups].sort((a, b) => SortingHelper.compare(a.name, b.name, language));

const SportGroupsListView = props => {
    const { groupId, sportId, sportGroups, type, pageType, onDataLoad, mojitoTools } = props;
    const {
        config,
        appContext: { routeResolver, analyticsEmitter, systemSettings },
        stringResolver,
    } = mojitoTools;
    const isTopSportGroups = type === SportGroupsListTypes.TYPE.TOP;
    const headerText =
        stringResolver.resolveString(`$SPORT_GROUPS_LIST.${type}.${sportId}`, true) ||
        stringResolver.resolveString(`$SPORT_GROUPS_LIST.${type}.DEFAULT`);

    const [loadingAnimationDone, setLoadingAnimationDone] = useState(false);

    const createHref = typeId => {
        if (RacingHelper.isRacingSport(sportId)) {
            return routeResolver.getRoute(NavigationPathBuilder.toFutureRaceEvent(sportId, typeId));
        }
        if (pageType === COUPONS.COMPETITIONS) {
            return routeResolver.getRoute(
                NavigationPathBuilder.toSportCompetitionsLeague(sportId, typeId)
            );
        }
        if (pageType === COUPONS.OUTRIGHTS) {
            return routeResolver.getRoute(
                NavigationPathBuilder.toSportOutrightsLeague(sportId, typeId)
            );
        }
    };

    const onClick = (typeId, eventId) => {
        if (isTopSportGroups) {
            analyticsEmitter.emitAnalytics('topLeagueClick', typeId);
        }
        if (RacingHelper.isRacingSport(sportId)) {
            return IntentActionTrigger.showFutureRaceEventPage(sportId, typeId);
        }
        if (pageType === COUPONS.COMPETITIONS) {
            IntentActionTrigger.showSportCompetitions(sportId, typeId);
        } else if (pageType === COUPONS.OUTRIGHTS) {
            if (!eventId) {
                IntentActionTrigger.showSportOutrights(sportId, typeId);
            } else {
                IntentActionTrigger.showSportEvent(sportId, eventId);
            }
        }
    };

    const isContentPending = useSelector(state => {
        const loadingState = selectItemsState(groupId, state);
        return loadingState === UNKNOWN || loadingState === PENDING;
    });

    const skeleton = (
        <SportGroupsListSkeleton
            headerText={headerText}
            isTopSportGroups={isTopSportGroups}
            config={config.sportGroupsListSkeleton}
        />
    );

    const onShowContent = () => {
        setLoadingAnimationDone(true);
    };

    useEffect(() => {
        if (!isContentPending && onDataLoad && sportGroups?.length) {
            onDataLoad(sportGroups);
        }
    }, [isContentPending, onDataLoad, sportGroups]);

    return (
        <LoaderSuspense
            config={config.skeletonLoader}
            isContentPending={isContentPending}
            loader={skeleton}
            onShowContent={onShowContent}
        >
            {sportGroups.length > 0 && (
                <FlexPane config={config.container} class="ta-SportGroupsListView">
                    {!RacingHelper.isRacingSport(sportId) && (
                        <FlexPane config={config.headerContainer}>
                            <Text config={config.headerText}>{headerText}</Text>
                        </FlexPane>
                    )}
                    <FlexPane config={config.mainContainer} class="ta-SportGroupsListViewContent">
                        {isTopSportGroups ? (
                            <TopSportGroups
                                sportId={sportId}
                                sportGroups={sportGroups}
                                createHref={createHref}
                                onClick={onClick}
                                mojitoTools={mojitoTools}
                            />
                        ) : (
                            <AllSportGroups
                                sportId={sportId}
                                sportGroups={sortSportGroups(
                                    sportGroups,
                                    systemSettings().language
                                )}
                                createHref={createHref}
                                onClick={onClick}
                                viewable={loadingAnimationDone}
                                mojitoTools={mojitoTools}
                            />
                        )}
                    </FlexPane>
                </FlexPane>
            )}
        </LoaderSuspense>
    );
};

const TopSportGroups = ({ sportId, sportGroups, createHref, onClick, mojitoTools }) => {
    const { config } = mojitoTools;

    return (
        <FlexPane config={config.listContainer}>
            {sportGroups.map(({ name, id, countryCode, countryName, className, eventId }) => (
                <SportGroup
                    key={id}
                    sportId={sportId}
                    countryCode={countryCode}
                    countryName={countryName}
                    name={name}
                    classname={className}
                    onClick={() => onClick(id, eventId)}
                    hrefLink={createHref(id)}
                    mojitoTools={mojitoTools}
                />
            ))}
        </FlexPane>
    );
};

const AllSportGroups = ({ sportId, sportGroups, createHref, onClick, viewable, mojitoTools }) => {
    const { config } = mojitoTools;
    const selectedElementRef = useRef();
    const location = useLocation();

    const selectedSportGroupId = location.hash.slice(1);

    useEffect(() => {
        if (selectedSportGroupId && viewable) {
            const selectedSportGroupElement = selectedElementRef.current;
            selectedSportGroupElement?.scrollIntoView();
        }
    }, [selectedSportGroupId, viewable]);

    return sportGroups.map(({ name, id, items, countryCode, countryName, className }) => (
        <div
            id={id}
            key={id}
            ref={element => {
                if (id === selectedSportGroupId) {
                    selectedElementRef.current = element;
                }
            }}
        >
            <FlexPane config={config.listContainer}>
                <FlexPane config={config.locationContainer}>
                    <CountryIcon
                        sportId={sportId}
                        countryCode={countryCode}
                        countryName={countryName}
                        config={config.countryIcon}
                    />
                    <Text config={config.locationName}>{name}</Text>
                </FlexPane>

                {items?.map(sportGroup => (
                    <SportGroup
                        key={sportGroup.id}
                        sportId={sportId}
                        countryCode={countryCode}
                        countryName={countryName}
                        name={sportGroup.name}
                        classname={className}
                        hideIcon={true}
                        onClick={() => onClick(sportGroup.id, sportGroup.eventId)}
                        hrefLink={createHref(sportGroup.id)}
                        mojitoTools={mojitoTools}
                    />
                ))}
            </FlexPane>
        </div>
    ));
};

const SportGroup = ({
    sportId,
    countryCode,
    countryName,
    name,
    classname,
    hideIcon,
    onClick,
    hrefLink,
    mojitoTools,
}) => {
    const { config } = mojitoTools;
    const labelText = classname ? `${classname} - ${name}` : EventFormatter.formatEventName(name);

    return (
        <Button
            config={config.sportGroupButton}
            class="ta-SportGroupsListButton"
            hrefLink={hrefLink}
            onClick={onClick}
        >
            {!hideIcon && (
                <CountryIcon
                    sportId={sportId}
                    countryCode={countryCode}
                    countryName={countryName}
                    config={config.countryIcon}
                />
            )}
            <Text config={config.sportGroupName}>{labelText}</Text>
            <Image config={config.chevronIcon} />
        </Button>
    );
};

export default SportGroupsListView;
