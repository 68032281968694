/**
 * Subscription resolver utils.
 *
 * @class SubscriptionResolverUtils
 * @private
 */

const MS_IN_A_DAY = 24 * 60 * 60 * 1000;
const MS_IN_AN_HOUR = 60 * 60 * 1000;
const matchTypes = ['live-and-upcoming', 'live', 'future', 'all'];
const eventTypes = ['outright', 'match', 'virtual'];

const getStartOfDay = () => new Date().setHours(0, 0, 0, 0);
const getEndOfDay = () => new Date().setHours(23, 59, 59, 999);
const timeFilters = {
    today: isStartDate => (isStartDate ? new Date(new Date()) : new Date(getEndOfDay())),
    tomorrow: isStartDate =>
        isStartDate
            ? new Date(getStartOfDay() + MS_IN_A_DAY)
            : new Date(getEndOfDay() + MS_IN_A_DAY),
};

/**
 * Resolves the end date based on the input subscription.
 *
 * @param {string} subscription - The input subscription.
 *
 * @returns {Date|undefined} The resolved end date or undefined if not applicable.
 */
const resolveEndDate = subscription => {
    if (subscription.includes('custom-span')) {
        const timeUnits = { d: MS_IN_A_DAY, h: MS_IN_AN_HOUR };
        const match = subscription.match(/(\d+)([a-zA-Z]*)/);
        const letter = match?.[2];
        const timeUnitValue = timeUnits[letter];

        if (timeUnitValue) {
            const numericValue = parseInt(match?.[1] || '0');
            return new Date(new Date().getTime() + numericValue * timeUnitValue);
        }
    }

    const providedTimeFilter = resolveTimeFilter(subscription);

    return providedTimeFilter && timeFilters[providedTimeFilter]();
};

/**
 * Resolves the start date based on the input subscription.
 *
 * @param {string} subscription - The input subscription.
 *
 * @returns {Date|undefined} The resolved start date or undefined if not applicable.
 */
const resolveStartDate = subscription => {
    const providedTimeFilter = resolveTimeFilter(subscription);

    return providedTimeFilter && timeFilters[providedTimeFilter](true);
};

/**
 * Resolves the time filter key from the given subscription string.
 *
 * @param {string} subscription - The string containing potential time filter key.
 * @returns {string | undefined} The key of the time filter found in the subscription, or undefined if no key is found.
 */
const resolveTimeFilter = subscription => {
    return Object.keys(timeFilters).find(key => subscription.includes(key));
};

/**
 * Resolves the match type based on the input subscription.
 *
 * @param {string} subscription - The input subscription.
 *
 * @returns {string|undefined} The resolved match type or undefined if not found.
 */
const resolveMatchType = subscription => {
    return matchTypes.find(matchType => subscription.includes(matchType));
};

/**
 * Resolves the event type based on the input subscription.
 *
 * @param {string} subscription - The input subscription.
 *
 * @returns {string|undefined} The resolved event type or undefined if not found.
 */
const resolveEventsType = subscription => {
    return eventTypes.find(eventType => subscription.includes(eventType));
};

/**
 * Resolves the sport ID based on the input subscription.
 *
 * @param {string} subscription - The input subscription.
 *
 * @returns {string|null} The resolved sport ID or null if not found.
 */
const resolveSportId = subscription => {
    return !parseInt(subscription.split('-')[0]) ? subscription.split('-')[0] : null;
};

/**
 * Resolves the tournament ID based on the input subscription.
 *
 * @param {string} subscription - The input subscription.
 *
 * @returns {string|null} The resolved tournament ID or null if not found.
 */
const resolveTournamentId = subscription => {
    let tournamentId = null;
    const tournamentMatch = subscription.match(/tournament:(.*?)-/)?.[1];
    const splitInput = subscription.split('-')[0];

    if (parseInt(tournamentMatch)) {
        tournamentId = tournamentMatch;
    } else if (parseInt(splitInput)) {
        tournamentId = splitInput;
    }

    return tournamentId;
};

export {
    resolveEndDate,
    resolveStartDate,
    resolveTimeFilter,
    resolveMatchType,
    resolveEventsType,
    resolveSportId,
    resolveTournamentId,
};
