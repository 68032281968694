import {PALETTE_IDS} from '#core/application/modules/ids.js';

import {COLOR, FONT} from '../theme.js';

export function getCardsConfig() {
    return {
        palette: {
            [PALETTE_IDS.EVENT_CARDS]: {
                CARD_FALLBACK_TEXT: COLOR.BRAND.FOREGROUND1,
                CARD_SPORT_ICON_COLOR: COLOR.BRAND.FOREGROUND1,

                CARD_STREAMING_INDICATOR: {
                    INDICATOR_ICON_COLOR: COLOR.BRAND.FOREGROUND3,
                },
                CARD_TIMING_STATUS: {
                    EVENT_TIMING_LIVE_TEXT: COLOR.BRAND.FOREGROUND3,
                    EVENT_TIMING_UPCOMING_TEXT: COLOR.BRAND.FOREGROUND3,
                    EVENT_TIMING_SEPARATOR_COLOR: COLOR.BASE.DIVIDER,
                },
                CARD_MARKET_NAME_TEXT: COLOR.BRAND.FOREGROUND3,
                CARD_RACE_INFO_TEXT: COLOR.BRAND.FOREGROUND3,
                CARD_WATERMARK_ICON_COLOR: COLOR.BRAND.BACKGROUND5,
                CARD_WATERMARK_ICON_OPACITY: 0.1,

                CARD_PARTICIPANTS: {
                    PARTICIPANTS_TEXT: COLOR.BRAND.FOREGROUND6,
                },
                CARD_RUNNER_INFO: {
                    RUNNER_TRAINER_AND_JOCKEY_NAMES_TEXT: COLOR.BRAND.FOREGROUND1,
                },

                CARD_SELECTION_MARKET_TEXT: {
                    color: COLOR.BRAND.FOREGROUND1,
                    fontWeight: FONT.WEIGHT_NORMAL,
                },
                CARD_SELECTION_PRICE_BOOST_CONTAINER_BG: COLOR.BRAND.BACKGROUND7,
                CARD_MATCH_ACCA_EVENT_INFO_TEXT: COLOR.BRAND.FOREGROUND1,
                CARD_MATCH_ACCA_SELECTION_ITEM: {
                    BB_BET_SELECTION_NAME_TEXT: COLOR.BRAND.FOREGROUND1,
                    BB_BET_MARKET_TEXT: COLOR.BRAND.FOREGROUND1,
                },
                CARD_GAMELINE_MARKET_HEADER_TEXT: COLOR.BRAND.FOREGROUND3,
            },
        },
    };
}
