import MojitoCore from 'mojito/core';
import { useMemo } from 'react';

const classUtils = MojitoCore.Base.classUtils;

export default function SkeletonPart(props) {
    const {
        mojitoTools: { config },
    } = props;

    const className = useMemo(
        () => classUtils.classNames('ta-SkeletonPart', props.class),
        [props.class]
    );

    return (
        <svg fill={config.fill} stroke={config.stroke} style={config.style} className={className}>
            <use xlinkHref={config.svgSrc} />
        </svg>
    );
}
