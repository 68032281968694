import MojitoCore from 'mojito/core';
import MojitoServices from 'mojito/services';
import DateTimeFormatter from 'presentation/formatters/date-time/index.js';
import OddsUtils from 'presentation/formatters/odds/index.js';

const StringUtils = MojitoCore.Base.StringUtils;
const EventsUtils = MojitoServices.SportsContent.Events.utils;

const SET_REGEXP = /^_[1-9]\d*set$/m;
const GAME_REGEXP = /^_[1-9]\d*game$/m;

/**
 * @class EventFormatter
 * @memberof Mojito.Presentation.Utils
 */
export default class EventFormatter {
    /**
     * Determines if an event should be displayed as outright in a specific component.
     *
     * @param {object} event - The event to evaluate.
     * @returns {boolean} Returns `true` if the event is considered as outright, otherwise `false`.
     * @function Mojito.Presentation.Utils.EventFormatter#shouldDisplayAsOutright
     */
    static shouldDisplayAsOutright(event) {
        return event && EventsUtils.isOutrightEventType(event.eventType);
    }

    static formatTimeSegment(segmentNumber, segmentDisplayFormat) {
        switch (segmentDisplayFormat) {
            case EventFormatter.SEGMENT_DISPLAY_FORMAT.SETS:
                return `Set${segmentNumber}`;

            case EventFormatter.SEGMENT_DISPLAY_FORMAT.GAMES:
                return `Game${segmentNumber}`;

            case EventFormatter.SEGMENT_DISPLAY_FORMAT.QUARTERS:
                return `Q${segmentNumber}`;

            case EventFormatter.SEGMENT_DISPLAY_FORMAT.HALVES:
            default:
                return DateTimeFormatter.formatOrdinal(segmentNumber);
        }
    }

    /**
     * Formats an event scores object according to the supplied format.
     *
     * @param {object} details - Event details object.
     * @param {Mojito.Presentation.Utils.EventFormatter.SCORE_DISPLAY_FORMAT} scoreDisplayFormat - The score format to be applied to the event's scores.
     * @returns {object} The formatted scores object.
     * @function Mojito.Presentation.Utils.EventFormatter#formatEventScores
     */
    static formatScores(details, scoreDisplayFormat) {
        const scores = {
            home: [],
            away: [],
        };

        if (!details) {
            return scores;
        }

        switch (scoreDisplayFormat) {
            case EventFormatter.SCORE_DISPLAY_FORMAT.NONE:
                break;

            case EventFormatter.SCORE_DISPLAY_FORMAT.INNINGS:
                EventFormatter._formatGameScores(details, scores);
                break;

            case EventFormatter.SCORE_DISPLAY_FORMAT.SET_GAME_POINT:
                EventFormatter._formatSetGamePoint(details, scores);
                break;

            case EventFormatter.SCORE_DISPLAY_FORMAT.GAME_POINT:
            case EventFormatter.SCORE_DISPLAY_FORMAT.SET_POINT:
            case EventFormatter.SCORE_DISPLAY_FORMAT.FRAMES:
                EventFormatter._formatGamePoints(details, scores);
                break;

            case EventFormatter.SCORE_DISPLAY_FORMAT.GOALS:
            default:
                EventFormatter._formatTotalScores(details, scores);
                break;
        }

        return scores;
    }

    static _formatGameScores(details, scores) {
        if (details.gameScores) {
            scores.home.push(details.gameScores.home);
            scores.away.push(details.gameScores.away);
        } else {
            scores.home.push('0');
            scores.away.push('0');
        }
    }

    static _formatTotalScores(details, scores) {
        if (details.totalScores) {
            scores.home.push(details.totalScores.home);
            scores.away.push(details.totalScores.away);
        } else {
            scores.home.push('0');
            scores.away.push('0');
        }
    }

    static _formatDetailedScores(details, scores) {
        if (details.detailedScores && details.detailedScores.length > 0) {
            scores.home.push(details.detailedScores[details.detailedScores.length - 1].home);
            scores.away.push(details.detailedScores[details.detailedScores.length - 1].away);
        } else {
            scores.home.push('0');
            scores.away.push('0');
        }
    }

    static _formatSetGamePoint(details, scores) {
        EventFormatter._formatTotalScores(details, scores);
        EventFormatter._formatDetailedScores(details, scores);
        EventFormatter._formatGameScores(details, scores);
    }

    static _formatGamePoints(details, scores) {
        EventFormatter._formatTotalScores(details, scores);
        EventFormatter._formatGameScores(details, scores);
    }

    static getScoreSegmentIndicators(scoreDisplayFormat) {
        let indicators = [];

        switch (scoreDisplayFormat) {
            case EventFormatter.SCORE_DISPLAY_FORMAT.SET_GAME_POINT:
                indicators = ['S', 'G', 'P'];
                break;

            case EventFormatter.SCORE_DISPLAY_FORMAT.GAME_POINT:
                indicators = ['G', 'P'];
                break;

            case EventFormatter.SCORE_DISPLAY_FORMAT.SET_POINT:
                indicators = ['S', 'P'];
                break;

            case EventFormatter.SCORE_DISPLAY_FORMAT.FRAMES:
                indicators = ['F', 'P'];
                break;

            default:
                break;
        }

        return indicators;
    }

    /**
     * Formats the price string according to the odds format.
     *
     * @param {Array} prices - An array that contains pricing selections.
     * @param {string} translations - The localized (l10n) string containing one or more components that may vary.
     * @returns {string} Returns the price string formatted according to the odds format.
     * @function Mojito.Presentation.Utils.EventFormatter#getPriceFormat
     */
    static formatSelectionPrice(prices, translations) {
        const price = EventsUtils.getPrice(prices);
        if (!price) {
            return '';
        }
        return OddsUtils.getOdds(
            price.fractionalLabel,
            price.decimalLabel,
            price.americanLabel,
            translations
        );
    }

    static formatSetGameInfoText(event, segmentDisplayFormat, l10n) {
        let text;

        if (segmentDisplayFormat === EventFormatter.SEGMENT_DISPLAY_FORMAT.SETS) {
            if (
                event.details &&
                event.details.inPlay &&
                event.details.inplayDescription &&
                event.details.inplayDescription.match(SET_REGEXP)
            ) {
                text = StringUtils.resolveAndFormatString(
                    '$EVENT_FORMATTER.SETS',
                    l10n,
                    event.details.inplayDescription.match(/\d+/)[0]
                );
            }
            text = text
                ? text
                : StringUtils.resolveAndFormatString('$EVENT_FORMATTER.SETS', l10n, '1');
        }

        if (segmentDisplayFormat === EventFormatter.SEGMENT_DISPLAY_FORMAT.GAMES) {
            if (
                event.details &&
                event.details.inPlay &&
                event.details.inplayDescription &&
                event.details.inplayDescription.match(GAME_REGEXP)
            ) {
                text = StringUtils.resolveAndFormatString(
                    '$EVENT_FORMATTER.GAMES',
                    l10n,
                    event.details.inplayDescription.match(/\d+/)[0]
                );
            }
            text = text
                ? text
                : StringUtils.resolveAndFormatString('$EVENT_FORMATTER.GAMES', l10n, '1');
        }

        return text ? `(${text})` : undefined;
    }

    /**
     * Gets in-play description using the provided localization (l10n) object.
     * If the translation isn't available, it returns the provided description.
     *
     * @param {string} sportId - The ID of the sport.
     * @param {string} description - The description to be translated to the period name.
     * @param {object} l10n - The object containing the strings for translation.
     * @returns {string} The translated description or the initially provided description if translation is unavailable.
     * @function Mojito.Presentation.Utils.EventFormatter#getDescription
     */
    static getDescription(sportId, description, l10n) {
        // Do not display period if it cannot be parsed by API
        if (!description || description === '_CaneBeParsed') {
            return '';
        }

        const period = description.indexOf('_') === 0 ? description.slice(1) : description;
        const overridePeriodName = StringUtils.resolveString(
            `$EVENT_FORMATTER.SPORT_PERIOD_OVERRIDES.${sportId.replace(/\s/g, '_')}.${period}`,
            l10n,
            true
        );

        if (overridePeriodName) {
            return overridePeriodName;
        }

        return (
            StringUtils.resolveString(
                `$EVENT_FORMATTER.PERIOD_NAMES.${period.replace(/\s/g, '_')}`,
                l10n,
                true
            ) || period
        );
    }

    static formatEventName(eventName, startTime = '', startDate = '') {
        if (!eventName) {
            return eventName;
        }
        const getRegExp = template => new RegExp(`&lt;${template}&gt;|<${template}>`);
        return eventName
            .replace(getRegExp('ST'), startTime)
            .replace(getRegExp('SD'), startDate)
            .trim();
    }

    /**
     * Splits string by delimiter.
     *
     * @param {string} str - String which should be splitted.
     * @param {string} delimiter - Delimiter which will be used to split the string.
     *
     * @returns {object} Object containing name and ending that are derrived from the whole string.
     *
     * @function Mojito.Presentation.Utils.EventFormatter#splitByEndingDelimiter
     */
    static splitByEndingDelimiter(str, delimiter) {
        if (!str) {
            return {};
        }

        const mask = `(?:(\\${delimiter}.*?)$)`;
        const [firstPart, secondPart] = str.split(new RegExp(mask));

        return !firstPart && secondPart
            ? { name: secondPart?.trim() }
            : {
                  name: firstPart?.trim(),
                  ending: secondPart?.trim(),
              };
    }
}

EventFormatter.SEGMENT_DISPLAY_FORMAT = {
    HALVES: 'halves',
    SETS: 'sets',
    GAMES: 'games',
    INNINGS: 'innings',
    QUARTERS: 'quarters',
    FRAMES: 'frames',
    PERIODS: 'periods',
};

/**
 * @typedef {object} SCORE_DISPLAY_FORMAT
 * @description Represents different types of score display formats.
 * @property {string} GOALS - Represents a score format based on goals.
 * @property {string} SET_GAME_POINT - Represents a score format based on set game points.
 * @property {string} GAME_POINT - Represents a score format based on game points.
 * @property {string} SET_POINT - Represents a score format based on set points.
 * @property {string} INNINGS - Represents a score format based on innings.
 * @property {string} FRAMES - Represents a score format based on frames.
 * @property {string} NONE - Represents the lack of a defined score format.
 * @memberof Mojito.Presentation.Utils.EventFormatter
 */
EventFormatter.SCORE_DISPLAY_FORMAT = {
    GOALS: 'goals',
    SET_GAME_POINT: 'setGamePoint',
    GAME_POINT: 'gamePoint',
    SET_POINT: 'setPoint',
    INNINGS: 'innings',
    FRAMES: 'frames',
    NONE: 'none',
};

EventFormatter.TIME_DISPLAY_FORMAT = {
    PERIOD: 'period',
    TOTAL: 'total',
};
