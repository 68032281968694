import {useEffect} from 'react';

import MojitoCore from 'mojito/core';
import MojitoPresentation from 'mojito/presentation';

import {Intents} from '#core/application/intents/index.js';

const {dispatch} = MojitoCore.Services.redux.store;
const IntentActions = MojitoCore.Intents.actions;
const IntentActionTrigger = MojitoPresentation.Base.Intent.IntentActionTrigger;

export function RegistrationRoute() {
    useEffect(() => {
        IntentActionTrigger.showHomePage();

        const registerIntent = Intents.REGISTER;

        if (registerIntent) dispatch(IntentActions.publishIntent(registerIntent.type, registerIntent.data));
    }, []);

    return null;
}
