/**
 * Contains utils specially needed for working with redux and redux toolkit.
 *
 * @class ReduxUtils
 * @memberof Mojito.Core.Services
 */
export default class ReduxUtils {
    /**
     * A type guard function returns true if at least one of the provided action types match.
     *
     * @param {...string} types - Action types arguments.
     *
     * @returns {Function} Matcher function.
     * @function Mojito.Core.Services.ReduxUtils.isAnyOfType
     */
    static isAnyOfType(...types) {
        return action => types.includes(action.type);
    }
}
