import MojitoCore from 'mojito/core';
const UIView = MojitoCore.Presentation.UIView;
import PriceBoostPageViewImpl from './price-boost-page-view.jsx';

/**
 * View that displays coupons with price boosts, grouped by sport.
 *
 * @class PriceBoostPageView
 * @extends Mojito.Core.Presentation.UIPage
 * @memberof Mojito.Applications.Sports.Pages
 */

export default UIView('PriceBoostPageView', PriceBoostPageViewImpl);
