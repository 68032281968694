/**
 * Types of intentActions.
 *
 * @see Mojito.Core.Intents.actions
 * @class IntentTypes
 * @name Types
 * @memberof Mojito.Presentation.Base.Intent
 */
export default class IntentTypes {}

/**
 * Intent to inform Mojito is initialized.
 *
 * @typedef MOJITO_INITIALIZED
 * @memberof Mojito.Presentation.Base.Intent.Types
 */
IntentTypes.MOJITO_INITIALIZED = 'mojito-initialized';

/**
 * Intent to view content.
 *
 * @typedef VIEW
 * @memberof Mojito.Presentation.Base.Intent.Types
 */
IntentTypes.VIEW = 'view';

/**
 * Intent to open a specific url.
 * If windowName is specified a new window will be opened to load the url.
 *
 * @example <caption>Associated intent data example</caption>
 * intentActions.publishIntent(IntentTypes.OPEN_URL, {
 *   url: 'http://some-url.com',
 *   windowName: 'A cool window name',
 *   windowFeatures: 'scrollbars=yes'
 * })
 *
 * @typedef OPEN_URL
 * @memberof Mojito.Presentation.Base.Intent.Types
 */
IntentTypes.OPEN_URL = 'open-url';

/**
 * Intent to trigger native wrapper functionality.
 * Second parameter typically should be sent to the wrapping native application.
 *
 * @example <caption>Associated intent data example.</caption>
 * intentActions.publishIntent(IntentTypes.NATIVE, {navigateTo: 'some native wrapper page'});
 *
 * @typedef NATIVE
 * @memberof Mojito.Presentation.Base.Intent.Types
 */
IntentTypes.NATIVE = 'native';

/**
 * Intent to show sliding betslip.
 *
 * @typedef SHOW_SLIDING_BETSLIP
 * @memberof Mojito.Presentation.Base.Intent.Types
 */
IntentTypes.SHOW_SLIDING_BETSLIP = 'show-sliding-betslip';

/**
 * Intent to hide sliding betslip.
 *
 * @typedef HIDE_SLIDING_BETSLIP
 * @memberof Mojito.Presentation.Base.Intent.Types
 */
IntentTypes.HIDE_SLIDING_BETSLIP = 'hide-sliding-betslip';

/**
 * Intent to show sliding open bets view.
 *
 * @typedef SHOW_SLIDING_OPEN_BETS
 * @memberof Mojito.Presentation.Base.Intent.Types
 */
IntentTypes.SHOW_SLIDING_OPEN_BETS = 'show-sliding-open-bets';

/**
 * Intent to hide sliding open bets view.
 *
 * @typedef HIDE_SLIDING_OPEN_BETS
 * @memberof Mojito.Presentation.Base.Intent.Types
 */
IntentTypes.HIDE_SLIDING_OPEN_BETS = 'hide-sliding-open-bets';

/**
 * Intent to show sliding az menu view.
 *
 * @typedef SHOW_SLIDING_AZ_MENU
 * @memberof Mojito.Presentation.Base.Intent.Types
 */
IntentTypes.SHOW_SLIDING_AZ_MENU = 'show-sliding-az-menu';

/**
 * Intent to hide sliding az menu view.
 *
 * @typedef HIDE_SLIDING_AZ_MENU
 * @memberof Mojito.Presentation.Base.Intent.Types
 */
IntentTypes.HIDE_SLIDING_AZ_MENU = 'hide-sliding-az-menu';

/**
 * Intent to show simple toast message.
 *
 * @typedef SHOW_SIMPLE_TOAST
 * @memberof Mojito.Presentation.Base.Intent.Types
 */
IntentTypes.SHOW_SIMPLE_TOAST = 'show-simple-toast';

/**
 * Intent to show open bets.
 *
 * @typedef SHOW_OPEN_BETS
 * @memberof Mojito.Presentation.Base.Intent.Types
 */
IntentTypes.SHOW_OPEN_BETS = 'show-open-bets';

/**
 * Intent to show betslip.
 *
 * @typedef SHOW_BETSLIP
 * @memberof Mojito.Presentation.Base.Intent.Types
 */
IntentTypes.SHOW_BETSLIP = 'show-betslip';

/**
 * Intent to show my account popup. Typically, applicable for desktop.
 *
 * @typedef SHOW_ACCOUNT_POPUP
 * @memberof Mojito.Presentation.Base.Intent.Types
 */
IntentTypes.SHOW_ACCOUNT_POPUP = 'show-account-popup';

/**
 * Intent triggered on closing the my account popup.
 *
 * @typedef HIDE_ACCOUNT_POPUP
 * @memberof Mojito.Presentation.Base.Intent.Types
 */
IntentTypes.HIDE_ACCOUNT_POPUP = 'hide-my-account';

/**
 * Intent to show the app settings UI.
 *
 * @typedef APP_SETTINGS
 * @memberof Mojito.Presentation.Base.Intent.Types
 */
IntentTypes.APP_SETTINGS = 'app-settings';

/**
 * Intent to trigger back navigation.
 *
 * @typedef BACK_NAVIGATION
 * @memberof Mojito.Presentation.Base.Intent.Types
 */
IntentTypes.BACK_NAVIGATION = 'back-navigation';

/**
 * Intent to trigger quick events navigation.
 *
 * @typedef SHOW_QUICK_EVENTS_NAVIGATION
 * @memberof Mojito.Presentation.Base.Intent.Types
 */
IntentTypes.SHOW_QUICK_EVENTS_NAVIGATION = 'show-quick-events-navigation';

/**
 * Intent to trigger quick leagues navigation.
 *
 * @typedef SHOW_QUICK_LEAGUES_NAVIGATION
 * @memberof Mojito.Presentation.Base.Intent.Types
 */
IntentTypes.SHOW_QUICK_LEAGUES_NAVIGATION = 'show-quick-leagues-navigation';

/**
 * Intent to trigger the display of quick meeting's navigation.
 *
 * @typedef SHOW_QUICK_MEETINGS_NAVIGATION
 * @memberof Mojito.Presentation.Base.Intent.Types
 */
IntentTypes.SHOW_QUICK_MEETINGS_NAVIGATION = 'show-quick-meetings-navigation';

/**
 * Intent to hide quick leagues navigation.
 *
 * @typedef HIDE_QUICK_LEAGUES_NAVIGATION
 * @memberof Mojito.Presentation.Base.Intent.Types
 */
IntentTypes.HIDE_QUICK_LEAGUES_NAVIGATION = 'hide-quick-leagues-navigation';

/**
 * Intent to hide quick events navigation.
 *
 * @typedef HIDE_QUICK_EVENTS_NAVIGATION
 * @memberof Mojito.Presentation.Base.Intent.Types
 */
IntentTypes.HIDE_QUICK_EVENTS_NAVIGATION = 'hide-quick-events-navigation';

/**
 * Intent to hide quick meeting's navigation.
 *
 * @typedef HIDE_QUICK_MEETINGS_NAVIGATION
 * @memberof Mojito.Presentation.Base.Intent.Types
 */
IntentTypes.HIDE_QUICK_MEETINGS_NAVIGATION = 'hide-quick-meetings-navigation';

/**
 * Intent to indicate overlay interaction.
 *
 * @typedef OVERLAY_INTERACTION
 * @memberof Mojito.Presentation.Base.Intent.Types
 */
IntentTypes.OVERLAY_INTERACTION = 'overlay-interaction';

/**
 * Intent to show foreign window.
 *
 * @typedef SHOW_FOREIGN_WINDOW
 * @memberof Mojito.Presentation.Base.Intent.Types
 */
IntentTypes.SHOW_FOREIGN_WINDOW = 'show-foreign-window';

/**
 * Intent to hide foreign window.
 *
 * @typedef HIDE_FOREIGN_WINDOW
 * @memberof Mojito.Presentation.Base.Intent.Types
 */
IntentTypes.HIDE_FOREIGN_WINDOW = 'hide-foreign-window';
