import MojitoCore from 'mojito/core';
import MojitoServices from 'mojito/services';
import SportsMenuView from './view/index.jsx';
import SportsMenuViewTypes from './types.js';
import { isEmpty } from 'mojito/utils';
import { connect } from 'react-redux';
import { selectShowAllSports, selectFavoriteSports } from './store/selectors';
const { UIViewImplementation, UIViewUtils } = MojitoCore.Presentation;
const { selectSports } = MojitoServices.SportsContent.Sports.selectors;
const { SPORT_ID } = MojitoServices.SportsContent.Sports.types;

class SportsMenu extends UIViewImplementation {
    constructor(props) {
        super(props);

        const sports = this.getAvailableSports();
        this.state = {
            availableSports: sports,
            highlightedSports: this.getHighlightedSports(sports, props.favoriteSports),
        };
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.sports !== this.props.sports ||
            prevProps.favoriteSports !== this.props.favoriteSports
        ) {
            const availableSports = this.getAvailableSports();
            this.setState({
                availableSports: availableSports,
                highlightedSports: this.getHighlightedSports(
                    availableSports,
                    this.props.favoriteSports
                ),
            });
        }
    }

    getTopSportIds(availableSports) {
        const topSports = availableSports.sort((a, b) => a.displayOrder - b.displayOrder);
        return topSports.slice(0, this.config.numberOfTopSportsToShow).map(sport => sport.id);
    }

    getAvailableSports() {
        const sports = this.props.sports;
        return sports.map(({ id, name, displayOrder }) => ({
            id,
            name,
            type:
                id === SPORT_ID.VIRTUALS
                    ? SportsMenuViewTypes.SPORT_TYPES.VIRTUAL
                    : SportsMenuViewTypes.SPORT_TYPES.DEFAULT,
            displayOrder,
        }));
    }

    // Combines favorite and top sports
    getHighlightedSports(availableSports, favoriteSports) {
        const favorites = favoriteSports.map(sport => ({
            id: sport,
            isFavoriteSport: true,
        }));
        const isNotFavorite = sportId => !favorites.some(({ id }) => id === sportId);
        const topSports = this.getTopSportIds(availableSports)
            .filter(isNotFavorite)
            .map(sportId => ({ id: sportId, isTopSport: true }));
        return favorites.concat(topSports);
    }

    render() {
        if (isEmpty(this.state.availableSports)) {
            return null;
        }
        return (
            <SportsMenuView
                config={this.config.sportsMenuView}
                availableSports={this.state.availableSports}
                highlightedSports={this.state.highlightedSports}
                {...UIViewUtils.omitComponentOwnProps(
                    this.props,
                    'sports',
                    'dispatch',
                    'favoriteSports'
                )}
            />
        );
    }
}

const mapStateToProps = state => ({
    sports: selectSports(state) || [],
    isExpanded: selectShowAllSports(state),
    favoriteSports: selectFavoriteSports(state) || [],
});

export default connect(mapStateToProps)(SportsMenu);
