import MojitoCore from 'mojito/core';
const UIPage = MojitoCore.Presentation.UIPage;
import VirtualSportPageImpl from './virtual-sport-page.jsx';

/**
 * @namespace VirtualSportPage
 * @memberof Mojito.Applications.Sports.Pages
 */

/**
 * Page that displays a virtual events
 * <br>
 * This page expects the route params to include sport id, class id and event id.
 * If those are not provided the default behaviour will be used for sport, class and event selection.
 *
 * @class VirtualSportPage
 * @extends Mojito.Core.Presentation.UIPage
 * @memberof Mojito.Applications.Sports.Pages.VirtualSportPage
 */

export default UIPage('VirtualSportPage', VirtualSportPageImpl);
