import React from 'react';
import {connect} from 'react-redux';

import MojitoCore from 'mojito/core';
import MojitoModules from 'mojito/modules';
import MojitoServices from 'mojito/services';
import MojitoPresentation from 'mojito/presentation';

import {DesktopContext, isMobile, MobileContext} from '#core/utils/context-utils.js';
import {getResettingCSSForContainerId} from '#core/utils/embedding-utils.js';
import {bindAll, generateUniqueId} from '#core/utils/utils.js';

import AbstractWidget from '#core/widgets/AbstractWidget.js';
import {Localization} from '#core/localization.js';

import {generatePrimaryButton} from '#core/application/modules/tiles/button/index.js';
import {constructTilePalette} from '#core/application/modules/tiles-cache.js';
import {BetHistory} from '#core/application/modules/bet-history/index.js';

import {StoreProviderWrapper} from './wrappers/index.js';

const {betHistorySelectors} = MojitoServices.Bets;
const {selectors: PDFStoreSelectors} = MojitoServices.Pdf;
const authenticationSelectors = MojitoServices.Authentication.selectors;

const AnalyticsContextExtender = MojitoCore.Presentation.AnalyticsContext.ContextExtender;
const AnalyticsContextBuilder = MojitoCore.Presentation.AnalyticsContext.ContextBuilder;

const BetHistoryView = MojitoModules.BetHistory.Sports.view;
const BetHistoryDetails = MojitoModules.BetHistoryDetails;

const Button = MojitoPresentation.Components.Button;

class WidgetBetHistoryImpl extends AbstractWidget {
    constructor(props) {
        super(props);
        this.state = {
            isDetailsShown: false,
            selectedBetId: null,
        };

        this.widgetId = 'widget-bethistory-' + generateUniqueId();

        // handle edge case of "false" passed as string
        this.params.showCompactLayout =
            this.params.showCompactLayout === 'false' ? false : this.params.showCompactLayout;

        this.showCompactLayout = this.params.showCompactLayout ?? BetHistory.widgetDesktopLayout === 'compact';

        this.analyticsContext = new AnalyticsContextBuilder().withAnalyticsPath('WidgetBetHistory').build();

        bindAll(this, 'onItemClicked', 'onCloseDetailsClick');
    }

    componentDidMount() {
        this.buttonConfig = generatePrimaryButton(constructTilePalette('BUTTON.PRIMARY'));
    }

    onItemClicked(item) {
        this.setState({
            isDetailsShown: true,
            selectedBetId: item.id,
        });
    }

    onCloseDetailsClick() {
        this.setState({
            isDetailsShown: false,
            selectedBetId: null,
        });
    }

    renderTableView() {
        const {pdfData, betsData, userLoggedIn} = this.props;

        return (
            <AnalyticsContextExtender value={this.analyticsContext}>
                <DesktopContext>
                    <style
                        type="text/css"
                        dangerouslySetInnerHTML={{__html: getResettingCSSForContainerId(this.widgetId)}}
                    ></style>
                    <div id={this.widgetId}>
                        <BetHistoryView
                            pagination={betsData.pagination}
                            betHistory={betsData.betHistory}
                            isFetchingBetHistory={betsData.isFetching}
                            selectedStatusFilter={betsData.statusFilter}
                            selectedRangeFilter={betsData.rangeFilter}
                            pdfData={pdfData}
                            error={betsData.error}
                            userLoggedIn={userLoggedIn}
                        />
                    </div>
                </DesktopContext>
            </AnalyticsContextExtender>
        );
    }

    renderCompactView() {
        const {pdfData, betsData, userLoggedIn} = this.props;
        const {isDetailsShown, selectedBetId} = this.state;

        return (
            <AnalyticsContextExtender value={this.analyticsContext}>
                <MobileContext>
                    <style
                        type="text/css"
                        dangerouslySetInnerHTML={{__html: getResettingCSSForContainerId(this.widgetId)}}
                    ></style>
                    <div id={this.widgetId}>
                        <div style={{display: this.state.isDetailsShown ? 'none' : 'block'}}>
                            <BetHistoryView
                                pagination={betsData.pagination}
                                betHistory={betsData.betHistory}
                                isFetchingBetHistory={betsData.isFetching}
                                selectedStatusFilter={betsData.statusFilter}
                                selectedRangeFilter={betsData.rangeFilter}
                                pdfData={pdfData}
                                onItemClicked={this.onItemClicked}
                                error={betsData.error}
                                userLoggedIn={userLoggedIn}
                            />
                        </div>
                        <div style={{display: isDetailsShown ? 'block' : 'none'}}>
                            {isDetailsShown ? (
                                <BetHistoryDetails betId={selectedBetId} config={{_desktop_showCompactLayout: true}} />
                            ) : null}
                            <Button onClick={this.onCloseDetailsClick} config={this.buttonConfig}>
                                {Localization.getTranslation('$DBX.BET_HISTORY.BACK_BUTTON_LABEL')}
                            </Button>
                        </div>
                    </div>
                </MobileContext>
            </AnalyticsContextExtender>
        );
    }

    render() {
        return this.showCompactLayout || isMobile() ? this.renderCompactView() : this.renderTableView();
    }
}

const mapStateToProps = state => ({
    userLoggedIn: authenticationSelectors.isLoggedIn(state),
    betsData: {
        betHistory: betHistorySelectors.selectBets(),
        pagination: betHistorySelectors.selectPagination(),
        isFetching: betHistorySelectors.selectIsFetching(),
        error: betHistorySelectors.selectError(),
        rangeFilter: betHistorySelectors.selectRangeFilter(),
        statusFilter: betHistorySelectors.selectStatusFilter(),
    },
    pdfData: {
        isFetching: PDFStoreSelectors.selectIsFetching(),
        hasError: Boolean(PDFStoreSelectors.selectError()),
    },
});

export const WidgetBetHistory = StoreProviderWrapper(
    'WidgetBetHistory',
    connect(mapStateToProps)(WidgetBetHistoryImpl)
);
