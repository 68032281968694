import MojitoCore from 'mojito/core';
import ActiveBonuses from './view/index.jsx';
import MojitoServices from 'mojito/services';
import { connect } from 'react-redux';

const { actions: userBonusesActions, selectors: userBonusesSelectors } = MojitoServices.UserBonuses;
const { selectActiveFreeBets, selectActiveOddsBoosts } = userBonusesSelectors;
const { selectCurrency } = MojitoServices.UserInfo.selectors;
const { UIViewImplementation } = MojitoCore.Presentation;

class ActiveBonusesController extends UIViewImplementation {
    componentDidMount() {
        this.fetchBonuses();
    }

    fetchBonuses() {
        this.props.dispatch(userBonusesActions.fetchActiveUserBonuses());
    }

    render() {
        return (
            <ActiveBonuses
                campaigns={this.props.campaigns}
                freeBets={this.props.freeBets}
                oddsBoosts={this.props.oddsBoosts}
                currencyCode={this.props.currencyCode}
                config={this.config.view}
                showPrewager={this.props.showPrewager}
                showOddsBoosts={this.props.showOddsBoosts}
            />
        );
    }
}

const mapStateToProps = state => ({
    freeBets: selectActiveFreeBets(state),
    currencyCode: selectCurrency(state),
    oddsBoosts: selectActiveOddsBoosts(state),
});

export default connect(mapStateToProps)(ActiveBonusesController);
