import MojitoServices from 'mojito/services';
import MojitoModules from 'mojito/modules';

import {resolveBackground} from '#core/utils/color-utils.js';
import {icon} from '#core/utils/style-utils.js';

import {generatePivotButton} from '#core/application/modules/tiles/pivot/index.js';
import {generateProgressBar} from '#core/application/modules/tiles/progress-bar/index.js';

import {activeBonusesFactory} from './factory/active-bonuses.factory';
import {inProgressBonusesFactory} from './factory/in-progress-bonuses.factory';

const BonusTypes = MojitoServices.Bonus.types;
const BonusViewTypes = MojitoModules.Bonuses.types;

export function bonusesFactory(Bonuses, PALETTE) {
    const {FONT} = PALETTE;
    const BONUS_ITEM = {
        container: {
            padding: 8,
            borderRadius: 8,
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: PALETTE.BONUS_ITEM_BORDER_COLOR,
            ...resolveBackground(PALETTE.BONUS_ITEM_BG),
            boxShadow: PALETTE.BONUS_ITEM_SHADOW,
        },
        title: {
            fontSize: FONT.SIZE_LARGE,
            fontWeight: FONT.WEIGHT_BOLD,
            textTransform: 'capitalize',
            ...PALETTE.BONUS_ITEM_TITLE_TEXT,
        },
        description: {
            fontSize: FONT.SIZE_MEDIUM,
            fontStyle: 'normal',
            fontWeight: FONT.WEIGHT_NORMAL,
            ...PALETTE.BONUS_ITEM_DESCRIPTION_TEXT,
        },
        icon: {
            padding: '0 4px 0 0',
            width: 16,
            height: 16,
            fill: PALETTE.BONUS_ITEM_ICON_COLOR,
        },
        progressBar: generateProgressBar(PALETTE.BONUS_ITEM_PROGRESS_BAR),
    };

    return {
        Bonuses: {
            ...(!Bonuses.showFreeBetCode
                ? {
                      freeBetCodePosition: BonusViewTypes.FREE_BET_CODE_POSITION.HIDDEN,
                      _mobile_freeBetCodePosition: BonusViewTypes.FREE_BET_CODE_POSITION.HIDDEN,
                  }
                : {}),
            showPrewager: Bonuses.showPrewager,
            showTabSwitcher: true,
            showOddsBoosts: GENBET_BASED,
            tabs: [
                {
                    id: BonusTypes.BONUS_FILTER.ACTIVE,
                    label: '$BONUSES.ACTIVE',
                },
                ...(GENBET_BASED
                    ? [
                          {
                              id: BonusTypes.BONUS_FILTER.IN_PROGRESS,
                              label: '$BONUSES.IN_PROGRESS',
                          },
                      ]
                    : []),
                {
                    id: BonusTypes.BONUS_FILTER.HISTORIC,
                    label: '$BONUSES.HISTORY',
                },
            ],
            freeBetCode: {
                container: {
                    style: {
                        _desktop_paddingTop: 4,
                        _desktop_paddingRight: 25,
                        _mobile_padding: 8,
                    },
                },
                contentContainer: {
                    style: {
                        _mobile_flexBasis: '40px',
                    },
                },
                input: {
                    style: {
                        base: {
                            _mobile_flex: 'initial',
                        },
                    },
                },
            },
            spinnerLabel: {
                style: {
                    fontSize: FONT.SIZE_MEDIUM,
                    ...PALETTE.BONUSES_LOADING_TEXT,
                },
            },
            spinner: {
                image: icon(PALETTE.BONUSES_LOADING_SPINNER_ICON_COLOR),
            },
            tabsStyles: {
                container: {
                    style: {
                        ...resolveBackground(PALETTE.BONUSES_TAB_SELECTOR_CONTAINER_BG),
                    },
                },
                selectableButton: generatePivotButton(PALETTE.BONUSES_TAB_SELECTOR),
            },
            _mobile_tabsStyles: {
                // 🥴🥴🥴
                container: {
                    style: {
                        ...resolveBackground(PALETTE.BONUSES_TAB_SELECTOR_CONTAINER_BG),
                    },
                },
                selectableButton: generatePivotButton(PALETTE.BONUSES_TAB_SELECTOR),
            },
        },
        BonusHistory: {
            spinnerLabel: {
                style: {
                    fontSize: FONT.SIZE_MEDIUM,
                    textAlign: 'center',
                    ...PALETTE.BONUSES_LOADING_TEXT,
                },
            },
            spinner: {
                image: icon(PALETTE.BONUSES_LOADING_SPINNER_ICON_COLOR),
            },
            noBonusesText: {
                container: {
                    ...BONUS_ITEM.container,
                    padding: '16px 8px',
                },
                style: {
                    fontSize: FONT.SIZE_MEDIUM,
                    textAlign: 'left',
                    ...PALETTE.BONUSES_NOT_FOUND_TEXT,
                },
            },
        },
        // used inside BonusHistory and ActiveBonuses, so that's why decided to keep common styles here
        BonusCard: {
            container: {
                style: BONUS_ITEM.container,
            },
            prewagerIcon: {
                style: BONUS_ITEM.icon,
            },
            cashIcon: {
                style: BONUS_ITEM.icon,
            },
            oddsBoostIcon: {
                style: BONUS_ITEM.icon,
            },
            freeBetIcon: {
                style: BONUS_ITEM.icon,
            },
            headerText: {
                style: BONUS_ITEM.title,
            },
            statusText: {
                style: {
                    fontSize: FONT.SIZE_MEDIUM,
                    ...PALETTE.BONUS_ITEM_STATUS_TEXT,
                },
            },
            expireDateText: {
                style: {
                    fontSize: FONT.SIZE_SMALL,
                    fontWeight: FONT.WEIGHT_NORMAL,
                    ...PALETTE.BONUS_ITEM_EXPIRY_DATE_TEXT,
                },
            },
            bonusDescriptionText: {
                style: BONUS_ITEM.description,
            },
            amountText: {
                style: {
                    fontSize: FONT.SIZE_MEDIUM,
                    ...PALETTE.BONUS_ITEM_AMOUNT_TEXT,
                },
            },
        },
        ...activeBonusesFactory(PALETTE, BONUS_ITEM),
        ...inProgressBonusesFactory(PALETTE, BONUS_ITEM),
    };
}
