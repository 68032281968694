import PropTypes from 'prop-types';
import AbsolutePaneImpl from './absolute-pane.jsx';
import MojitoCore from 'mojito/core';

/**
 * Component that positions itself absolutely and arranges its children using CSS Flexible Box Layout.
 *
 * @class AbsolutePane
 * @memberof Mojito.Presentation.Components
 */

/**
 * AbsolutePane component prop types.
 *
 * @property {string} [class] - A CSS class name applied to the root element.
 * @property {object | Function} [elementRef] - A ref to the rendered element.
 *
 * @memberof Mojito.Presentation.Components.AbsolutePane
 */
const propTypes = {
    class: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    elementRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

export default MojitoCore.Presentation.UIView('AbsolutePane', AbsolutePaneImpl, propTypes);
