import {resolveBackground} from '#core/utils/color-utils.js';

import {registerTileSchema} from '#core/application/schema-registry.js';

import PRIMARY_LINK from './primary-link.palette-schema.yaml';

registerTileSchema(PRIMARY_LINK);

export function generatePrimaryLink(TILE) {
    const {FONT} = TILE;

    return {
        style: {
            base: {
                ...resolveBackground(TILE.PRIMARY_LINK_BG),
                padding: '8px',
                fontSize: FONT.SIZE_MEDIUM,
                alignItems: 'center',
                borderWidth: 1,
                borderRadius: TILE.PRIMARY_LINK_BORDER_RADIUS,
                height: 40,
                borderColor: TILE.PRIMARY_LINK_BORDER_COLOR,
                borderStyle: 'solid',
                _mobile_padding: '12px 8px',
                ...TILE.PRIMARY_LINK_TEXT,
            },
        },
    };
}
