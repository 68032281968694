import {PALETTE_IDS} from '#core/application/modules/ids.js';

import {COLOR} from '../theme.js';

export function getStaticPagesConfig() {
    return {
        custom: {},
        palette: {
            [PALETTE_IDS.NATIVE_HOSTING_PAGE]: {
                NATIVE_HOSTING_PAGE_HEADER_CLIENT_NAME_TEXT: COLOR.BRAND.ACCENT2,
                NATIVE_HOSTING_PAGE_STEP_INDEX_TEXT: COLOR.BRAND.FOREGROUND5,
                NATIVE_HOSTING_PAGE_STEP_INDEX_BG: COLOR.BRAND.BACKGROUND2,
            },
        },
    };
}
