import {createSlice} from '@reduxjs/toolkit';

import MojitoCore from 'mojito/core';

const reduxInstance = MojitoCore.Services.redux;

export const STORE_KEY = 'MozaicStore';

const SESSION_STORAGE_SELECTED_CONFIG_ROOT = 'dbx.mozaic.current-config-root';
const SESSION_STORAGE_SELECTED_PALETTE_ROOT = 'dbx.mozaic.current-palette-root';
const SESSION_STORAGE_SELECTED_SPRITES_ROOT = 'dbx.mozaic.current-sprites-root';
const SESSION_STORAGE_SELECTED_TRANSLATIONS_ROOT = 'dbx.mozaic.current-translations-root';

export const INITIAL_STATE = {
    userRole: null,
    isVisible: false,
    isCMS: false,
    currentConfigRootPath: sessionStorage.getItem(SESSION_STORAGE_SELECTED_CONFIG_ROOT) || 'debug',
    currentPaletteRootPath: sessionStorage.getItem(SESSION_STORAGE_SELECTED_PALETTE_ROOT) || 'palette.BASIC',
    currentTranslationsRootPath:
        sessionStorage.getItem(SESSION_STORAGE_SELECTED_TRANSLATIONS_ROOT) || 'DOCUMENT_HEAD.PAGE_TITLE',
    currentSpritesRootPath: sessionStorage.getItem(SESSION_STORAGE_SELECTED_SPRITES_ROOT) || 'sport',
    currentRootPathHighlightedItemId: null,
    isDataSending: false,
    isCtrlKeyPressed: false,

    KeysToSchemaMap: {},
    modifiedProperties: {},
    persistentConfig: {},
    actualConfig: {}, // Config that applied for all sportsbooks/users
};

export const {reducer, actions} = createSlice({
    name: 'mozaic',
    initialState: INITIAL_STATE,
    reducers: {
        updateUserRole(state, {payload: userRole}) {
            state.userRole = userRole;
        },
        updateIsVisible(state, {payload: isVisible}) {
            state.isVisible = isVisible;
        },
        isCMS(state, {payload: isCMS}) {
            state.isCMS = isCMS;
        },
        updateCurrentConfigRootPath(state, {payload: {currentRootPath, itemIdToHighlight}}) {
            state.currentConfigRootPath = currentRootPath;
            state.currentRootPathHighlightedItemId = itemIdToHighlight;
            sessionStorage.setItem(SESSION_STORAGE_SELECTED_CONFIG_ROOT, currentRootPath);
        },
        updateCurrentPaletteRootPath(state, {payload: {currentRootPath, itemIdToHighlight}}) {
            state.currentPaletteRootPath = currentRootPath;
            state.currentRootPathHighlightedItemId = itemIdToHighlight;
            sessionStorage.setItem(SESSION_STORAGE_SELECTED_PALETTE_ROOT, currentRootPath);
        },
        updateCurrentSpritesRootPath(state, {payload: {currentRootPath, itemIdToHighlight}}) {
            state.currentSpritesRootPath = currentRootPath;
            state.currentRootPathHighlightedItemId = itemIdToHighlight;
            sessionStorage.setItem(SESSION_STORAGE_SELECTED_SPRITES_ROOT, currentRootPath);
        },
        updateCurrentRootPathHighlightedItemId(state, {payload: itemIdToHighlight}) {
            state.currentRootPathHighlightedItemId = itemIdToHighlight;
        },
        updateCurrentTranslationsRootPath(state, {payload: currentTranslationsRootPath}) {
            state.currentTranslationsRootPath = currentTranslationsRootPath;
            sessionStorage.setItem(SESSION_STORAGE_SELECTED_TRANSLATIONS_ROOT, currentTranslationsRootPath);
        },
        updateDataSending(state, {payload}) {
            state.isDataSending = payload;
        },
        updateCtrlKeyPressedStatus(state, {payload}) {
            state.isCtrlKeyPressed = payload;
        },

        setKeysToSchemaMap(state, {payload}) {
            state.KeysToSchemaMap = payload;
        },

        modifyProperty(state, {payload: {key, value}}) {
            state.modifiedProperties = {
                ...state.modifiedProperties,
                [key]: value,
            };
        },
        deleteProperty(state, {payload: key}) {
            delete state.modifiedProperties[key];
        },
        setModifiedProperties(state, {payload}) {
            state.modifiedProperties = payload;
        },
        setPersistentConfig(state, {payload}) {
            state.persistentConfig = payload;
        },

        setActualConfig(state, {payload}) {
            state.actualConfig = payload;
        },
    },
});

reduxInstance.injectReducer(STORE_KEY, reducer);
