import { createContext } from 'react';
import AppContextDefinition from './app-context-definition.js';
import { noop } from 'mojito/utils';

/**
 * Application context builder class.
 * Used to build proper structured application context.
 *
 * @class AppContextBuilder
 * @memberof Mojito.Core.Presentation.AppContext
 */

/**
 * Factory function used to create application context builder.
 *
 * @function ContextBuilder
 * @returns {Mojito.Core.Presentation.AppContext.AppContextBuilder} Application context builder instance.
 * @memberof Mojito.Core.Presentation.AppContext
 */
export function ContextBuilder() {
    const context = {};
    return {
        /**
         * Assign systemSettings function to context.
         *
         * @function withSystemSettings
         * @instance
         *
         * @param {Function} value - Function that returns object with application system settings. See {@link Mojito.Core.Presentation.AppContext.ContextDefinition#systemSettings|systemSettings}.
         * @returns {Mojito.Core.Presentation.AppContext.AppContextBuilder} Application context builder instance.
         *
         * @memberof Mojito.Core.Presentation.AppContext.AppContextBuilder
         */
        withSystemSettings: function (value) {
            context.systemSettings = value;
            return this;
        },
        /**
         * Assign userSettings function to context.
         *
         * @function withUserSettings
         * @instance
         *
         * @param {Function} value - Function that returns object with application user settings. See {@link Mojito.Core.Presentation.AppContext.ContextDefinition#userSettings|userSettings}.
         * @returns {Mojito.Core.Presentation.AppContext.AppContextBuilder} Application context builder instance.
         *
         * @memberof Mojito.Core.Presentation.AppContext.AppContextBuilder
         */
        withUserSettings: function (value) {
            context.userSettings = value;
            return this;
        },
        /**
         * Assign sportName function to context.
         *
         * @function withSportName
         * @instance
         *
         * @param {Function} value - Function that returns sport name by code. See {@link Mojito.Core.Presentation.AppContext.ContextDefinition#sportName|sportName}.
         * @returns {Mojito.Core.Presentation.AppContext.AppContextBuilder} Application context builder instance.
         *
         * @memberof Mojito.Core.Presentation.AppContext.AppContextBuilder
         */
        withSportName: function (value) {
            context.sportName = value;
            return this;
        },
        /**
         * Assign getImage function to context.
         *
         * @function withGetImage
         * @instance
         *
         * @param {Function} value - Function that returns image by path. See {@link Mojito.Core.Presentation.AppContext.ContextDefinition#getImage|getImage}.
         * @returns {Mojito.Core.Presentation.AppContext.AppContextBuilder} Application context builder instance.
         *
         * @memberof Mojito.Core.Presentation.AppContext.AppContextBuilder
         */
        withGetImage: function (value) {
            context.getImage = value;
            return this;
        },
        /**
         * Assign withGetFixture function to context.
         *
         * @function withGetFixture
         * @instance
         *
         * @param {Function} value - Function that returns fixture by path. See {@link Mojito.Core.Presentation.AppContext.ContextDefinition#getFixture|getFixture}.
         * @returns {Mojito.Core.Presentation.AppContext.AppContextBuilder} Application context builder instance.
         *
         * @memberof Mojito.Core.Presentation.AppContext.AppContextBuilder
         */
        withGetFixture: function (value) {
            context.getFixture = value;
            return this;
        },
        /**
         * Assign getLoginState function to context.
         *
         * @function withGetLoginState
         * @instance
         *
         * @param {Function} value - Function that returns user login state. See {@link Mojito.Core.Presentation.AppContext.ContextDefinition#getLoginState|getLoginState}.
         * @returns {Mojito.Core.Presentation.AppContext.AppContextBuilder} Application context builder instance.
         *
         * @memberof Mojito.Core.Presentation.AppContext.AppContextBuilder
         */
        withGetLoginState: function (value) {
            context.getLoginState = value;
            return this;
        },
        /**
         * Assigns an isContentShown function to the context.
         *
         * @function withIsContentShown
         * @instance
         *
         * @param {Function} value - Function that determines whether content is shown. See {@link Mojito.Core.Presentation.AppContext.ContextDefinition#isContentShown|isContentShown}.
         * @returns {Mojito.Core.Presentation.AppContext.AppContextBuilder} An instance of the application context builder.
         *
         * @memberof Mojito.Core.Presentation.AppContext.AppContextBuilder
         */
        withIsContentShown: function (value) {
            context.isContentShown = value;
            return this;
        },
        /**
         * Assigns a uiContextPath to the context.
         *
         * @function withUiContextPath
         * @instance
         *
         * @param {string} value - Provides the current UI context path. Refer to {@link Mojito.Core.Presentation.AppContext.ContextDefinition#uiContextPath|uiContextPath}.
         * @returns {Mojito.Core.Presentation.AppContext.AppContextBuilder} Returns an instance of the application context builder.
         *
         * @memberof Mojito.Core.Presentation.AppContext.AppContextBuilder
         */
        withUiContextPath: function (value) {
            context.uiContextPath = value;
            return this;
        },
        /**
         * Attaches an analyticsEmitter instance to the context.
         *
         * @function withAnalyticsEmitter
         * @instance
         *
         * @param {Mojito.Core.Presentation.AbstractAnalyticsEmitter} value - Instance of concrete implementation of analytics emitter.
         * @returns {Mojito.Core.Presentation.AppContext.AppContextBuilder} Application context builder instance.
         *
         * @memberof Mojito.Core.Presentation.AppContext.AppContextBuilder
         */
        withAnalyticsEmitter: function (value) {
            context.analyticsEmitter = value;
            return this;
        },
        /**
         * Assign RouteResolver instance to context.
         *
         * @function withRouteResolver
         * @instance
         *
         * @param {Mojito.Core.Presentation.AbstractRouteResolver} value - Instance of concrete implementation of route resolver.
         * @returns {Mojito.Core.Presentation.AppContext.AppContextBuilder} Application context builder instance.
         *
         * @memberof Mojito.Core.Presentation.AppContext.AppContextBuilder
         */
        withRouteResolver: function (value) {
            context.routeResolver = value;
            return this;
        },
        /**
         * Assign overlayInteraction function to context.
         *
         * @function withOverlayInteraction
         * @instance
         *
         * @param {Function} value - Function that dispatches overlay interaction action. See {@link Mojito.Core.Presentation.AppContext.ContextDefinition#overlayInteraction|overlayInteraction}.
         * @returns {Mojito.Core.Presentation.AppContext.AppContextBuilder} Application context builder instance.
         *
         * @memberof Mojito.Core.Presentation.AppContext.AppContextBuilder
         */
        withOverlayInteraction: function (value) {
            context.overlayInteraction = value;
            return this;
        },
        /**
         * Assign suspense fallback component to context.
         *
         * @function withSuspenseFallback
         * @instance
         *
         * @param {Function} value - The reference to react lazy loading component suspense fallback. See {@link Mojito.Core.Presentation.AppContext.ContextDefinition#suspenseFallback|suspenseFallback} in context definition for more info.
         * @returns {Mojito.Core.Presentation.AppContext.AppContextBuilder} Application context builder instance.
         *
         * @memberof Mojito.Core.Presentation.AppContext.AppContextBuilder
         */
        withSuspenseFallback: function (value) {
            context.suspenseFallback = value;
            return this;
        },
        /**
         * Assign suspense fallback component to context.
         *
         * @function withSuspenseFallback
         * @instance
         *
         * @param {Function} value - The reference to react error boundary component fallback. See {@link Mojito.Core.Presentation.AppContext.ContextDefinition#errorFallback|errorFallback} in context definition for more info.
         * @returns {Mojito.Core.Presentation.AppContext.AppContextBuilder} Application context builder instance.
         *
         * @memberof Mojito.Core.Presentation.AppContext.AppContextBuilder
         */
        withErrorFallback: function (value) {
            context.errorFallback = value;
            return this;
        },
        // PerformanceEmitter is used only by Sportsbook, but future implementations can be integrated into the applications common functionality via abstract class.
        /**
         * Assign performance emitter to context.
         *
         * @function withPerformanceEmitter
         * @instance
         *
         * @param {Mojito.Applications.Sports.PerformanceEmitter} value - The instance of Performance emitter.
         * @returns {Mojito.Core.Presentation.AppContext.AppContextBuilder} Application context builder instance.
         *
         * @memberof Mojito.Core.Presentation.AppContext.AppContextBuilder
         */
        withPerformanceEmitter: function (value) {
            context.performanceEmitter = value;
            return this;
        },
        /**
         * Assign pathname to context.
         *
         * @function withPathname
         * @instance
         *
         * @param {string} value - Pathname of the current location.
         * @returns {Mojito.Core.Presentation.AppContext.AppContextBuilder} Application context builder instance.
         *
         * @memberof Mojito.Core.Presentation.AppContext.AppContextBuilder
         */
        withPathname: function (value) {
            context.pathname = value;
            return this;
        },
        /**
         * Assign SubscriptionResolver instance to context.
         *
         * @function withSubscriptionResolver
         * @instance
         *
         * @param {object} value - The instance of subscription resolver.
         * @returns {Mojito.Core.Presentation.AppContext.AppContextBuilder} Application context builder instance.
         *
         * @memberof Mojito.Core.Presentation.AppContext.AppContextBuilder
         */
        withSubscriptionResolver: function (value) {
            context.subscriptionResolver = value;
            return this;
        },
        /**
         * Constructs an application context object.
         *
         * @function build
         * @instance
         *
         * @returns {Mojito.Core.Presentation.AppContext.AppContextDefinition} An instance of the application context builder.
         *
         * @memberof Mojito.Core.Presentation.AppContext.AppContextBuilder
         */
        build: function () {
            return context;
        },
    };
}

/**
 * An empty application context object.
 *
 * @constant
 * @type {Mojito.Core.Presentation.AppContext.ContextDefinition}
 * @memberof Mojito.Core.Presentation.AppContext
 */
export const NULL_APP_CONTEXT = new ContextBuilder()
    .withSystemSettings(() => ({ language: 'en' }))
    .withGetImage(noop)
    .withGetFixture(noop)
    .withSportName('')
    .withIsContentShown(false)
    .withGetLoginState(noop)
    .withUiContextPath('')
    .withUserSettings(noop)
    .withAnalyticsEmitter({ emitAnalytics: (/* type, data */) => {} })
    .withRouteResolver({ getRoute: (/* payload */) => {} })
    .withSuspenseFallback(null)
    .withPerformanceEmitter({ emitPerformanceMetric: () => {} })
    .withPathname('')
    .withSubscriptionResolver({})
    .build();

const AppContext = createContext();
AppContext.Provider.propTypes = AppContextDefinition.propTypes(NULL_APP_CONTEXT);

/**
 * Mojito application context object created by <code>React.createContext()</code>.
 *
 * @typedef AppContext
 * @memberof Mojito.Core.Presentation.AppContext
 */
export default AppContext;
