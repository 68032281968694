import {Provider} from 'react-redux';
import React from 'react';

import MojitoCore from 'mojito/core';

const reduxInstance = MojitoCore.Services.redux;

/**
 * StoreProviderWrapper HoC function is used to provide widget component implementation
 * with global redux store. It's needed when we mount widgets outside Sportsbook.
 *
 * @param {string} name - Widget component name
 * @param {object} widgetImpl - Widget component implementation class
 * @param {object} [propTypes] - Widget component prop types
 * @param {object} [defaultProps] - Widget component default prop values
 *
 * @returns {Function} ComponentWrapper function
 */
export function StoreProviderWrapper(name, widgetImpl, propTypes, defaultProps = {}) {
    const WidgetImplementation = widgetImpl;

    const WrappedWidget = props => {
        const componentProps = {...defaultProps, ...props};

        return (
            <Provider store={reduxInstance.store}>
                <WidgetImplementation {...componentProps} />
            </Provider>
        );
    };

    WrappedWidget.displayName = name;
    WrappedWidget.propTypes = propTypes;

    return WrappedWidget;
}
