/**
 * User bonuses utility class.
 *
 * @class UserBonusesUtils
 * @name utils
 * @memberof Mojito.Services.UserBonuses
 */
export default class UserBonusesUtils {
    /**
     * Calculates total free bets amount.
     *
     * @param {Mojito.Services.Bonus.types.FreeBet[]} freeBets - An array of free bets.
     * @returns {number} Returns the total amount of the given free bets.
     * @function Mojito.Services.UserBonuses.utils#calculateTotalFreeBetsAmount
     * @static
     */
    static calculateTotalFreeBetsAmount(freeBets) {
        let vouchersAmount = 0;

        if (freeBets) {
            vouchersAmount = freeBets.reduce((sum, freeBet) => {
                const remainingAmount = UserBonusesUtils.getRemainingAmount(freeBet);
                return remainingAmount ? sum + Number(remainingAmount) : sum;
            }, 0);
        }
        return vouchersAmount;
    }

    /**
     * Returns the remaining amount of the freebet. If the freebet can not be split, the remaining amount is the same as the total amount.
     *
     * @param {Mojito.Services.Bonus.types.FreeBet} freeBet - Free bet.
     * @returns {number} Return 'remainingAmount' for splittable freebet and 'amount' value for non-splittable freebet.
     * @function Mojito.Services.UserBonuses.utils.getRemainingAmount
     */
    static getRemainingAmount(freeBet) {
        const { remainingAmount, amount } = freeBet;
        return remainingAmount !== undefined ? remainingAmount : amount;
    }
}
